import styled from 'styled-components'
import { Button, Row } from 'antd';

export const CustomButton = styled(Button)`
   padding : 4px 40px !important;
`
export const CustomRow = styled(Row)`
   width : 100% ;
   margin :  5px 0 5px 0 ;
`
export const HideButon = styled.div`
   padding : 2px 0 ;
   color : #b0abab;
   font-size : 12px
   cursor: pointer;
`

export const FilterWrapper = styled(Row)`
   width : 100% ;
   padding: 24px 10px 0 10px;
   background-color : #FAFAFB ;
   .ant-form{
      width : 100%;
   }
   .ant-form-item{
      margin-bottom :10px !important;
   }
`

export const LicenseTypeWrapper = styled(Row)`
   width : 100%;
   padding : 10px !important ;
   text-align: left;
   .ant-divider-horizontal{
      margin : 5px 0 !important;
   }
   .ant-form-item{
      width : 100%;
   }
   .ant-checkbox-group{
      display : flex;
      flex-flow: row wrap;
      width : 100%;
   }
`
