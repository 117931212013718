import React from 'react'
import { Drawer, Card } from 'antd'
import { inject, observer } from 'mobx-react'
import { CardViewWrapper } from './CustomStyled'
// Slick
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

const { Meta } = Card

const DrawerCaptureViews = props => {
  const { capturesStore } = props

  const { captureList } = capturesStore

  // const addViewpoint = () => {
  //   capturesStore.setClickAdd(true)
  // }

  const goViewpoint = capture => {
    capturesStore.setCameraData(capture.cameraData)
  }

  const sliderSetting = {
    dots: false,
    arrows: true,
    slidesToShow: 12,
    slidesToScroll: 12,
    infinite: false,
    centerPadding: 10,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  return (
    <Drawer
      closable={false}
      className={'views-drawer no-heading'}
      onClose={() => capturesStore.toggleDrawerCaptureVisible(false)}
      placement={'bottom'}
      keyboard={true}
      visible={capturesStore.isDrawerCaptureVisible}>
      <CardViewWrapper>
        {
          captureList.length === 0
            ? 'No view'
            : <Slider {...sliderSetting}>
              {
                captureList.map((capture, index) =>
                  <Card
                    key={index}
                    cover={
                      <img alt="example" src="/viewpoint-noimage.jpg" onClick={() => goViewpoint(capture)}/>
                    }>
                    <Meta title={capture.name}/>
                  </Card>)
              }
            </Slider>
        }
      </CardViewWrapper>
    </Drawer>
  )
}

export default inject('capturesStore')(observer(DrawerCaptureViews))
