import styled from "styled-components";
import { Modal } from 'antd'

export const DrawerContentHeader = styled.div`
  display: ${props => ((props.visible || props.visible === undefined) ? 'block' : 'none')};
  padding: 10px 10px
`

export const UserGroupWrapper = styled.div`
    .splitpanel-left {
        top: 0;
        right: 0;
        width: 10px;
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        border-top: 1px solid #ddd;
        background-color: #f2cfc4;
    }
  
    .splitpanel-mobile-left {
        top: 0;
        right: 0; 
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        .btnSplitPanel {
        position: absolute;
        top: 50%;
        margin-left: -15px;
        padding: 0 !important;
        }
    }
    .close-btn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        padding: 5px 10px;
        margin-bottom: 5px;

        .group-name{
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
        }
    }
    .action-bar{
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: end;
        padding-bottom: 10px;
    }

    .group-detail{
        border-left: 1px solid #f0f0f0;
    }
    
    .action-wapper{
        display: flex;
        gap: 5px;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }

    .user-group-header{
        display: flex;
        justify-content: end;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .group-table-wrap{
        margin-bottom: 15px;

        .ant-table-body{
            overflow: auto !important;
        }
    }

    .active-row{
        background: lightgray;
    }

    .hide-comlumn{
        display: none;
    }
`

export const HeaderDrawerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 30px;
    }
`

export const CustomUserGroupModal = styled(Modal)`
    .ant-modal-footer {
        text-align: right !important;
    }
`