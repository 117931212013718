import React, { useCallback, useEffect, useState } from 'react'
import { Button, Spin, Tree, Empty, Drawer, Pagination } from 'antd'
import { ColumnWidthOutlined, DownOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react'
import { TreePanel } from './CustomStyled'
import { cloneDeep } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import TreeUtils from '../../../../tree-utils'
import { ModelTreeDataRequest, ModelAttributeRequest } from '../../../../requests'
import uuid from 'uuid'
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

const ObjectDetail = ({ uiStore, projectStore, model }) => {
  const { t } = useTranslation();
  const isTabletOrMobileDevice = isMobile || isTablet
  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(361);

  const buildLoadMoreTree = (modelId, data) => {
    data.map(item => {
      if (item.children) {
        const pageSize = 10;
        if (item.children.length > pageSize) {
          const newChildren = item.children.slice(0, pageSize);
          const nodeData = {
            key: uuid(),
            pageSize,
            pKey: item.key,
            type: 'loadmore',
            modelId,
            isLeaf: true,
            checkable: false
          }
          newChildren.push({
            ...nodeData,
            title: <Pagination size="small" total={item.children.length} onChange={(page, pageSize) => onChangePage(page, pageSize, nodeData)} pageSize={pageSize} showSizeChanger showQuickJumper />,
          })
          item.children = newChildren;
        }
        buildLoadMoreTree(modelId, item.children);
      }
    })
    return data;
  }

  const onChangePage = (page, pageSize, { pKey, pageSize: oldPageSize, modelId }) => {
    const newPage = pageSize !== oldPageSize ? 1 : page;
    const treeData = projectStore.loadedModelTreeData[modelId];
    const node = TreeUtils.searchTreeNode(treeData, 'key', pKey);
    if (node?.children) {
      const newChildren = node.children.slice((newPage - 1) * pageSize, newPage * pageSize);
      const nodeData = {
        key: uuid(),
        pageSize,
        pKey: node.key,
        type: 'loadmore',
        modelId,
        isLeaf: true,
        checkable: false
      }
      newChildren.push({
        ...nodeData,
        title: <Pagination size="small" current={newPage} total={node.children.length} onChange={(page, pageSize) => onChangePage(page, pageSize, nodeData)} pageSize={pageSize} showSizeChanger showQuickJumper />,
      })
      const loadMoreTree = buildLoadMoreTree(modelId, cloneDeep(newChildren));
      setTreeData(origin => TreeUtils.updateTreeData(origin, pKey, loadMoreTree))
    }
  }

  useEffect(() => {
    const getTreeData = async () => {
      setIsLoading(true);
      let treeData = projectStore.loadedModelTreeData && projectStore.loadedModelTreeData[model.id];
      if (!treeData) {
        const { data: modelTreeData } = await ModelTreeDataRequest.getTreeDataByModel(model.id).finally(() => setIsLoading(false))
        treeData = modelTreeData.treeData
        projectStore.setLoadedModelTreeData({
          ...projectStore.loadedModelTreeData,
          [model.id]: treeData
        })
      }
      if (treeData) {
        const loadMoreTree = buildLoadMoreTree(model.id, cloneDeep(treeData));
        setTreeData(loadMoreTree);
      }
      setIsLoading(false);
    }
    getTreeData();
  }, [])

  const onSelect = async (selectedKeys, info) => {
    const { node: { key, type, GUID } } = info;
    if (type !== 'loadmore') {
      setSelectedKeys(selectedKeys);
      if (selectedKeys.length) { 
        uiStore.setShowAttrPanel(true);
        projectStore.setLoadingAttrData(true);
        projectStore.setSelectedAttrData({
          model3D: model.id,
          GUID
        });
        const { data: attrData } = await ModelAttributeRequest.getAttributeByPkey(key, model.id);
        projectStore.setSelectedAttrData(attrData);
      } else {
        uiStore.setShowAttrPanel(false);
      }
    }
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const handleTouchStart = e => {
    e.preventDefault();
    if (e.touches.length !== 1) return null;
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd, { passive: false });
    document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
  };

  const handleTouchMove = useCallback(e => {
    const touch = e.touches[0] || e.changedTouches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);
    const newWidth = touch.clientX + (target && target.offsetRight ? target.offsetRight : 0);
    const minDrawerWidth = 360;
    if (newWidth < 0) {
      setDrawerWidth(10);
    }
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, { passive: false });
    document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
    document.removeEventListener("touchmove", handleTouchMove, { passive: false });
  };

  const handleMouseDown = e => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(e => {
    const newWidth = e.clientX + document.body.offsetLeft;
    const minDrawerWidth = 360;
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  const onClose = () => {
    uiStore.setShowObjectInfo(false)
  }

  return (
    <Drawer
      placement="left"
      width={drawerWidth}
      closable={true}
      onClose={() => onClose()}
      visible={uiStore.showObjectInfo}
      maskClosable={false}
      mask={false}
      destroyOnClose={true}
      title={t('object-info')}
      className="drawerObjectDetail"
    >
      {isTabletOrMobileDevice ? (
        <div className="splitpanel-mobile">
          <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
            <ColumnWidthOutlined />
          </Button>
        </div>
      ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel" />)
      }
      <Spin spinning={isLoading}>
        <TreePanel>
          {treeData && treeData.length ? (
            <Tree
              checkable
              showIcon
              switcherIcon={<DownOutlined />}
              treeData={treeData}
              onSelect={onSelect}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={onExpand}
              autoExpandParent={autoExpandParent}
            />
          ) : (<Empty description={<span>{t('no-data')}</span>} />)}
        </TreePanel>

      </Spin>
    </Drawer>
  )
}

export default inject(
  'projectStore',
  'uiStore'
)(observer(ObjectDetail))