/* eslint-disable no-template-curly-in-string */
import React from 'react'
import {
  Cartesian3,
  Cartesian2,
  Matrix4,
  Matrix3,
  Math as CesiumMath,
  HeadingPitchRoll,
  TranslationRotationScale,
  Quaternion,
  Transforms,
  Cartographic,
  Cesium3DTileStyle,
  HeadingPitchRange,
  Ellipsoid,
  defined,
  Cesium3DTileFeature,
  Cesium3DTileset,
  Color,
  PolylineDashMaterialProperty,
  NearFarScalar, ColorMaterialProperty,
  EllipsoidGeodesic,
  PolylinePipeline,
  Color as CesiumColor,
  Math as MathCesium,
  PolylineGraphics as CesiumPolylineGraphics
} from 'cesium'
import { assetUrl, corsproxy } from '../../config'
import proj4 from 'proj4'
import axios from 'axios'
import projectStore from '../../stores/projectStore'
import util from '../../utils'
import * as turf from "@turf/turf";
import objectQueryStore from '../../stores/objectQueryStore'
import Utils from '../../utils'
const Cesium = require('cesium')
var ellipsoid = Ellipsoid.WGS84

export const setCZMLStyle = (entity) => {
  const AlignmentHorLine = new PolylineDashMaterialProperty({
    color: Color.fromCssColorString('#00FF00'),
  })
  const AlignmentHorCurve = new PolylineDashMaterialProperty({
    color: Color.fromCssColorString('#FF0000'),
  })
  const AlignmentHorSpiral = new PolylineDashMaterialProperty({
    color: Color.fromCssColorString('#FFFF00'),
  })
  var basicRed = new ColorMaterialProperty(Color.RED)
  if (defined(entity)) {
    if (defined(entity.polyline)) {
      const greenLine = 'rgb(0,255,0)'
      const redLine = 'rgb(255,0,0)'
      const yellow = 'rgb(0,0,255)'
      if (entity.polyline.material.getValue().color.toCssColorString() === greenLine) {
        entity.polyline.material = AlignmentHorLine;
      }
      else if (entity.polyline.material.getValue().color.toCssColorString() === redLine) {
        entity.polyline.material = AlignmentHorCurve;
      }
      else if (entity.polyline.material.getValue().color.toCssColorString() === yellow) {
        entity.polyline.material = AlignmentHorSpiral;
      }
      else {
        entity.polyline.material = basicRed
      }
    }
    else if (defined(entity.label)) {
      var number = Number(entity.label.text)
      if (number % 100 === 0) {
        entity.label.fillColor = Color.WHITE;
        entity.label.outlineColor = Color.WHITE;
        entity.label.font = '18px Helvetica';
        entity.label.translucencyByDistance = new NearFarScalar(
          1000,
          1.0,
          2000,
          0.0
        )
      }
      else {
        entity.label.fillColor = Color.WHITE;
        entity.label.outlineColor = Color.WHITE;
        entity.label.font = '12px Helvetica';
        entity.label.translucencyByDistance = new NearFarScalar(
          500,
          1.0,
          1000,
          0.0
        )
      }
    }

  }
}
export const setxDCamera = (viewer, flyOption) => {
  if (viewer && flyOption && flyOption.destination && flyOption.orientation) {
    try {
      let destination = undefined
      if (flyOption.destination.x && flyOption.destination.y) {
        destination = new Cartesian3(flyOption.destination.x, flyOption.destination.y, flyOption.destination.z)
      }
      let orientation = flyOption.orientation
      const viewOption = {
        orientation: orientation
      }
      if (destination) {
        viewOption.destination = destination
      }
      viewer.camera.setView(viewOption)
    } catch (error) {
    }
  }

}
export const transformFromInput = (trans, firstLoc, isNoTrans) => {
  let hpr = new HeadingPitchRoll(
    trans.heading * CesiumMath.RADIANS_PER_DEGREE,
    // 0,
    // 0
    trans.pitch * CesiumMath.RADIANS_PER_DEGREE,
    trans.roll * CesiumMath.RADIANS_PER_DEGREE,
  )

  var translation = null
  var scale = new Cartesian3(trans.scale, trans.scale, trans.scale)

  if (isNoTrans) {
    var t = Cartesian3.fromDegrees(trans.lng, trans.lat, firstLoc.height + trans.height)
    var firstTrans = Cartesian3.fromDegrees(firstLoc.lng, firstLoc.lat, firstLoc.height)
    translation = Cartesian3.subtract(
      t,
      firstTrans,
      new Cartesian3()
    )
    var out = Matrix4.fromTranslation(translation)
    // out = Matrix4.multiplyByMatrix3(out, Matrix3.fromHeadingPitchRoll(hpr), new Matrix3())
    // out = Matrix4.multiplyByScale(
    //   out,
    //   scale,
    //   new Matrix4()
    // )
    return out
  } else {
    // var scaleMatrix = Matrix4.fromScale(scale, new Matrix4())
    translation = Cartesian3.fromDegrees(trans.lng, trans.lat, firstLoc?.height ? firstLoc?.height : 0 + trans.height)
    var fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator('east', 'north');
    // var quaternion = Quaternion.fromHeadingPitchRoll(hpr, new Quaternion())
    var m = Transforms.headingPitchRollToFixedFrame(translation, hpr, Ellipsoid.WGS84, fixedFrameTransform)
    var out = Matrix4.multiplyByScale(
      m,
      scale,
      new Matrix4()
    )
    // out = Matrix4.multiplyByMatrix3(out,Matrix3.fromHeadingPitchRoll(hprx), new Matrix3())
    return out
  }


}

// export const getLocFromTile = tile => {
//   var tileTranslation = Matrix4.getTranslation(
//     tile.root.transform,
//     new Cartesian3()
//   )
//   // if (!initMatrix) setInitMatrix(tile.modelMatrix.clone())
//   var initScale = Matrix4.getScale(tile.root.transform, new Cartesian3())
//   var initRotation = Matrix4.getMatrix3(tile.root.transform, new Matrix3())
//   var quaternion = Quaternion.fromRotationMatrix(initRotation, new Quaternion())
//   var hpr = HeadingPitchRoll.fromQuaternion(quaternion, new HeadingPitchRoll())
//   var center = Cartographic.fromCartesian(tileTranslation)
//   var ret = {
//     lat:
//       center !== undefined
//         ? center.latitude * CesiumMath.DEGREES_PER_RADIAN
//         : CesiumMath.DEGREES_PER_RADIAN,
//     lng:
//       center !== undefined
//         ? center.longitude * CesiumMath.DEGREES_PER_RADIAN
//         : CesiumMath.DEGREES_PER_RADIAN,
//     height: center !== undefined ? center.height : 0,
//     // scale: Math.round(initScale.x),
//     scale: initScale.x,
//     heading: hpr.heading * CesiumMath.DEGREES_PER_RADIAN,
//     pitch: hpr.pitch * CesiumMath.DEGREES_PER_RADIAN,
//     roll: hpr.roll * CesiumMath.DEGREES_PER_RADIAN,
//     radius: tile.boundingSphere.radius,
//   }
//   return ret
// }


export const getLocFromMatrix = (matrix, typeOut) => {
  var tileTranslation = Matrix4.getTranslation(
    matrix,
    new Cartesian3()
  )
  var initScale = Matrix4.getScale(matrix, new Cartesian3())
  var initRotation = Matrix4.getMatrix3(matrix, new Matrix3())
  var quaternion = Quaternion.fromRotationMatrix(initRotation, new Quaternion())
  var hpr = HeadingPitchRoll.fromQuaternion(quaternion, new HeadingPitchRoll())
  var center = Cartographic.fromCartesian(tileTranslation)
  switch (typeOut) {
    case "hpr":
      return hpr
    case "cartographic":
      return center
    case "scale":
      return initScale
    case "translate":
      return tileTranslation
    case "rotation":
      return initRotation
    default:
      break
  }
  var ret = {
    lat:
      center !== undefined
        ? center.latitude * CesiumMath.DEGREES_PER_RADIAN
        : false,
    lng:
      center !== undefined
        ? center.longitude * CesiumMath.DEGREES_PER_RADIAN
        : false,
    height: (center !== undefined) ? center.height : false,
    scale: initScale.x.toFixed(10),
    heading: hpr.heading,
    pitch: hpr.pitch,
    roll: hpr.roll,
  }
  return ret
}



export const calcTransform = (m1, m2) => {
  var translate1 = Matrix4.getTranslation(m1, new Cartesian3())
  var translate2 = Matrix4.getTranslation(m2, new Cartesian3())
  var newTrans = Cartesian3.add(translate1, translate2, new Cartesian3())
  return Matrix4.setTranslation(m1, newTrans, new Matrix4())
}

export const calcMoveNewPos = (t1, loc) => {
  var translate1 = Matrix4.getTranslation(t1, new Cartesian3())
  var t2 = Transforms.eastNorthUpToFixedFrame(loc)
  var translate2 = Matrix4.getTranslation(t2, new Cartesian3())

  // var newTrans = Cartesian3.subtract(translate2, translate1, new Cartesian3())
  // return Matrix4.setTranslation(t1, translate2, new Matrix4())
  return t2
}
export const pointCloudStyleType = {
  Grey: 'grey', Classification: 'classification', RGB: 'rgb'
}

export const setColorPointCloud = (modelType, style3D, gpsMode, is3DTilesPointcloud, modelData) => {
  const transparency = modelData?.transparency ? 1 - modelData.transparency : 1
  const style = {
    //pointSize: 8
  }

  if (modelType === 'cloudpoint' || is3DTilesPointcloud || modelType === 'e57') {
    if (style3D && style3D.type === pointCloudStyleType.Grey) {
      style.color = `color('#808080', ${transparency})`
    }
    if (style3D && style3D.type === pointCloudStyleType.Classification) {
      style.color = {
        conditions: [
          ['${Classification} === 0', `color('#404040', ${transparency})`],
          //Unclassified
          ['${Classification} === 1', `color('#808080', ${transparency})`],
          //Ground
          ['${Classification} === 2', `color('#917E4D', ${transparency})`],
          //Low Vegetation
          ['${Classification} === 3', `color('#8FAC5C', ${transparency})`],
          //Medium Vegetation
          ['${Classification} === 4', `color('#5A8C4B', ${transparency})`],
          //High Vegetaiton
          ['${Classification} === 5', `color('#37683E', ${transparency})`],
          //Building
          ['${Classification} === 6', `color('#CA8459', ${transparency})`],
          //Low Point( noise)
          ['${Classification} === 7', `color('#404040', ${transparency})`],
          //Model Key-point (mass point)
          ['${Classification} === 8', `color('#FF0000', ${transparency})`],
          //Water
          ['${Classification} === 9', `color('#43A4C9', ${transparency})`],
          //Overlap points
          ['${Classification} === 12', `color('#404040', ${transparency})`],
          // Rest of points
          ["true", `color('#808080',${transparency})`],
        ]
      }
    }
  } else
    return new Cesium3DTileStyle({
      color: {
        evaluateColor: function (feature, result) {
          if (result && result.alpha !== undefined) {
            result.alpha = transparency
          } else if (!result) {
            result = {
              blue: 1,
              green: 1,
              red: 1,
              alpha: transparency
            }
          }
          return result
        }
      },
      show: true,
    })

  if (gpsMode !== 'first_person') {
    style.show = true;
  }
  return new Cesium3DTileStyle(style)
}

export const setTileConditions = (isApplyGanttModelStyle, tile, modelType, gpsMode, isVisible, style3D, hideFeatures = [], listObjectHide, listObjectColor, modelId, modelData, highlightSavedQueryObjectStyles, highlightGanttModel) => {
  const transparency = modelData?.transparency ? 1 - modelData.transparency : 1
  if (!isVisible) {
    //tileElement.style.color = Color.TRANSPARENT;
    if (gpsMode === 'first_person') {
      tile.style = new Cesium3DTileStyle({
        color: {
          evaluateColor: function (feature, result) {
            if (result && result.alpha !== undefined) {
              result.alpha = transparency
            }
            return result
          }
        },
        show: isVisible,
      })
    } else {
      tile.show = false;
    }
  } else {
    const style = {
      //pointSize: 8
    }
    if (modelType === 'ifc' || modelType === 'landxml' || modelType === 'cad' || modelType === 'landxmlBackground') {
      style.show = {
        evaluate: function (feature, result) {
          let isShow = true
          if (listObjectHide && modelId && listObjectHide[modelId] && feature?.getProperty('GUID')) {
            if (listObjectHide[modelId][feature?.getProperty('GUID')]) {
              isShow = listObjectHide[modelId][feature?.getProperty('GUID')] === 'active' ? true : false
            }
          }
          return isShow
        }
      }
      style.color = {
        evaluateColor: function (feature, result) {
          if (objectQueryStore.styleQuery?.status) {
            let colorResult = objectQueryStore.styleQuery.data.result.find(x => x.id === 'color')
            let alphaResult = objectQueryStore.styleQuery.data.result.find(x => x.id === 'alpha')
            let showResult = objectQueryStore.styleQuery.data.result.find(x => x.id === 'show')
            let finalResult = 1;
            if (showResult.checked) {
              if (showResult.value && alphaResult.checked) {
                finalResult = 1 - alphaResult.value;
              } else if (showResult.value && !alphaResult.checked) {
                finalResult = 1;
              } else if (!showResult.value) {
                finalResult = 0;
              }
            } else {
              if (alphaResult.checked) {
                finalResult = 1 - alphaResult.value;
              } else {
                finalResult = 1;
              }
            }
            let colorScene = objectQueryStore.styleQuery.data.scene.find(x => x.id === 'color')
            let alphaScene = objectQueryStore.styleQuery.data.scene.find(x => x.id === 'alpha')
            let showScene = objectQueryStore.styleQuery.data.scene.find(x => x.id === 'show')
            let finalScene = 1;
            if (showScene.checked) {
              if (showScene.value && alphaScene.checked) {
                finalScene = 1 - alphaScene.value;
              } else if (showScene.value && !alphaScene.checked) {
                finalScene = 1;
              } else if (!showScene.value) {
                finalScene = 0;
              }
            } else {
              if (alphaScene.checked) {
                finalScene = 1 - alphaScene.value;
              } else {
                finalScene = 1;
              }
            }
            if (listObjectColor && modelId && listObjectColor[modelId] && feature?.getProperty('GUID')) {
              if (listObjectColor[modelId][feature?.getProperty('GUID')]) {
                return CesiumColor.clone(Color.fromCssColorString(colorResult.checked ? colorResult.value : '#FFFFFF')
                  .withAlpha(finalResult), feature.color);
              } else {
                return CesiumColor.clone(Color.fromCssColorString(colorScene.checked ? colorScene.value : '#FFFFFF')
                  .withAlpha(finalScene), feature.color);
              }
            } else {
              return CesiumColor.clone(Color.fromCssColorString(colorScene.checked ? colorScene.value : '#FFFFFF')
                .withAlpha(finalScene), feature.color);
            }
          } else {
            let defaultColor = Color.WHITE.withAlpha(transparency)
            return CesiumColor.clone(defaultColor, feature.color);
          }
        }
      };

    }

    if (hideFeatures?.length) {
      style.show = {
        evaluate: function (feature, result) {
          return !(hideFeatures?.indexOf(feature?.getProperty('GUID')) > -1)
        }
      }
    }

    if (modelType === 'cloudpoint' || modelData?.is3DTilesPointcloud || modelType === 'e57') {
      //style.pointSize = 8
      if (style3D && style3D.type === pointCloudStyleType.Grey) {
        style.color = `color('#808080', ${transparency})`
      }
      if (style3D && style3D.type === pointCloudStyleType.Classification) {
        style.color = {
          conditions: [
            ['${Classification} === 0', `color('#404040', ${transparency})`],
            //Unclassified
            ['${Classification} === 1', `color('#808080', ${transparency})`],
            //Ground
            ['${Classification} === 2', `color('#917E4D', ${transparency})`],
            //Low Vegetation
            ['${Classification} === 3', `color('#8FAC5C', ${transparency})`],
            //Medium Vegetation
            ['${Classification} === 4', `color('#5A8C4B', ${transparency})`],
            //High Vegetaiton
            ['${Classification} === 5', `color('#37683E', ${transparency})`],
            //Building
            ['${Classification} === 6', `color('#CA8459', ${transparency})`],
            //Low Point( noise)
            ['${Classification} === 7', `color('#404040', ${transparency})`],
            //Model Key-point (mass point)
            ['${Classification} === 8', `color('#FF0000', ${transparency})`],
            //Water
            ['${Classification} === 9', `color('#43A4C9', ${transparency})`],
            //Overlap points
            ['${Classification} === 12', `color('#404040', ${transparency})`],
            // Rest of points
            ["true", `color('#808080', ${transparency})`],
          ]
        }
      }
    }
    if (!style.color) {
      style.color = {
        evaluateColor: function (feature, result) {
          if(modelData?.ext === '.fbx'){
            result = {
              blue: 1,
              green: 1,
              red: 1,
              alpha: transparency
            }
          }
          if (result && result.alpha !== undefined) {
            result.alpha = transparency
          } else if (!result) {
            result = {
              blue: 1,
              green: 1,
              red: 1,
              alpha: transparency
            }
          }
          return result;
        }
      }
    }

    if (!isApplyGanttModelStyle && style.color) {
      tile.style = new Cesium3DTileStyle(style)
    }
    if(isApplyGanttModelStyle){
      const style = {
        //pointSize: 8
      }
      if (modelType === 'ifc' || modelType === 'landxml' || modelType === 'cad' || modelType === 'landxmlBackground') {
        const handleShowSavedQuery = (feature) => {
          if (highlightSavedQueryObjectStyles && highlightSavedQueryObjectStyles?.length > 0 && modelId && feature?.getProperty('GUID')) {
            const objectStyle = highlightSavedQueryObjectStyles.filter(el => el.listNewFeature[modelId] && el.listNewFeature[modelId][feature?.getProperty('GUID')] && el.isShow === false)[0]
            if(objectStyle){
              return false
            } 
          }
          return true
        }
        style.show = {
          evaluate: function (feature, result) {
            let isShow = true
            const highlightSaveQueryColor = handleShowSavedQuery(feature)
            if(highlightGanttModel && highlightGanttModel?.length > 0){
              // cai nay la de loc ra nhung model hien dang duoc highlighting hoac la dang duoc showing
              const isExistModel = highlightGanttModel.find(m => m.id === modelId)
              if(isExistModel){
                return true
              }
              return highlightSaveQueryColor
              
            }
            return highlightSaveQueryColor
          }
        }

        const handleFilterObject = (arr=[], key='', guid) =>{
          if(!arr?.length) return
          return arr.filter(el => el.listNewFeature[key] && el.listNewFeature[key][guid]).filter(el => el?.style?.color && el?.style?.color !== "#ffffff")[0]
        }

        const handleHighlightSavedQuery = (feature) =>{
          if (highlightSavedQueryObjectStyles && highlightSavedQueryObjectStyles?.length > 0 && modelId && feature?.getProperty('GUID')) {
            const objectStyle = handleFilterObject(highlightSavedQueryObjectStyles, modelId, feature?.getProperty('GUID'))
            if(objectStyle){
              const {style} = objectStyle
              return CesiumColor.clone(
                Color.fromCssColorString(
                  style?.color ? style.color : '#FFFFFF'
                ).withAlpha(style?.alpha),
                feature.color
              )
            } 
          }
        }
        
        style.color = {
          evaluateColor: function (feature, result) {
            const highlightSaveQueryColor = handleHighlightSavedQuery(feature)
            if(highlightGanttModel && highlightGanttModel?.length > 0){
              const isExistModel = highlightGanttModel.find(m => m.id === modelId)
              if(isExistModel){
                const {highlightAlpha, highlightColor, isHighlight} = isExistModel
                if(isHighlight){
                  return CesiumColor.clone(
                    Color.fromCssColorString(
                      highlightColor ?? '#FFFFFF'
                    ).withAlpha(highlightAlpha),
                    feature?.color
                  )
                }else{
                  return highlightSaveQueryColor ||  CesiumColor.clone(
                    Color.fromCssColorString(
                      '#FFFFFF'
                    ).withAlpha(1),
                    feature?.color
                  )
                }
              }else{
                let defaultColor = Color.WHITE.withAlpha(transparency)
                return highlightSaveQueryColor || CesiumColor.clone(defaultColor, feature?.color);
              }
            }else{
              let defaultColor = Color.WHITE.withAlpha(transparency)
              return highlightSaveQueryColor || CesiumColor.clone(defaultColor, feature?.color);
            }
          }
        }
      }else{
        style.color = {
          evaluateColor: function (feature, result) {
            if(highlightGanttModel && highlightGanttModel?.length > 0){
              const isExistModel = highlightGanttModel.find(m => m.id === modelId)
              if(isExistModel){
                const {highlightAlpha, highlightColor, isHighlight} = isExistModel
                if(isHighlight){
                  
                  return CesiumColor.clone(
                    Color.fromCssColorString(
                      highlightColor ?? '#FFFFFF'
                    ).withAlpha(highlightAlpha),
                    feature?.color
                  )
                }else{
                  return CesiumColor.clone(
                    Color.fromCssColorString(
                      '#FFFFFF'
                    ).withAlpha(1),
                    feature?.color
                  )
                }
              }else{
                let defaultColor = Color.WHITE.withAlpha(transparency)
                return CesiumColor.clone(defaultColor, feature?.color);
              }
            }else{
              let defaultColor = Color.WHITE.withAlpha(transparency)
              return CesiumColor.clone(defaultColor, feature?.color);
            }
          }
        }
      }
      if (modelType !== 'cloudpoint' && !modelData?.is3DTilesPointcloud && modelType !== 'e57'){
        tile.style = new Cesium3DTileStyle(style);
      }
    }
    if (gpsMode !== 'first_person') {
      tile.show = true;
    }
  }

}

export const setColorTile = (tile, color, hideFeatures = []) => {
  return
  if (!color)
    tile.style = new Cesium3DTileStyle({
      pointSize: 4,
      ...hideFeatures?.length && {
        show: hideFeatures.map(GUID => '${GUID} !== ' + `'${GUID}'`).join('&&')
      }
    })
  else {
    tile.style = new Cesium3DTileStyle({
      pointSize: 4,
      color: color,
    })
  }
}

export const getCurrentModel = (currentModelId, modelList, tileViews) => {
  if (currentModelId) {
    let model = modelList.find(item => item._id == currentModelId)
    if (!model) return false
    if (!tileViews) return { model }
    let fkey = currentModelId + '-tile'
    let tile = tileViews.find(t => t.key == fkey)
    if (!tile) return false
    if (!tile.ref) return false
    if (!tile.ref.current) return false
    return { model, tile: tile.ref.current.cesiumElement }
  }
  return false
}

export const findModelByUrl = (url, modelList) => {
  let model = modelList.find(
    item => assetUrl + item.hash + '/tileset.json' === url
  )
  if (!model) return false
  return model
}

export const hpr4ZoomTo = (viewer, tile, keep) => {
  var range = 450
  if (tile.root.boundingSphere) range = tile.root.boundingSphere.radius * 4
  if (range > 450) range = 450
  const defautHpr = new HeadingPitchRange(0, -Math.PI / 2, range)
  if (!keep) return defautHpr
  if (viewer) {
    if (!viewer.camera) return defautHpr
    return new HeadingPitchRange(
      viewer.camera.heading,
      viewer.camera.pitch,
      range
    )
  }
}

export const metersToLongitude = (meters, latitude) => {
  return (meters * 0.000000156785) / Math.cos(latitude)
}

export const metersToLatitude = meters => {
  return meters * 0.000000157891
}

export const getCurrentCamera = viewerRef => {
  if (viewerRef.current != null) {
    let camera = viewerRef.current.cesiumElement.camera
    let camData = {}
    camData.position = {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    }
    camData.direction = {
      x: camera.direction.x,
      y: camera.direction.y,
      z: camera.direction.z,
    }
    camData.up = {
      x: camera.up.x,
      y: camera.up.y,
      z: camera.up.z,
    }
    camData.orientation = {
      heading: camera.heading,
      pitch: camera.pitch,
      roll: camera.roll
    }
    return camData
  }
  return false
}

// const findTileByModelId = (modelId) => {
//   let fkey = tileId + '-tile'
//   let tile = tileViews.find(t => t.key == fkey)
//   if (!tile) return false
//   if (!tile.ref) return false
//   if (!tile.ref.current) return false
//   return tile.ref.current.cesiumElement
// }

// const findTileByUrl = url => {
//   let tile = tileViews.find(t => {
//     console.log('t', t)
//     return t.props.url == url
//   })
//   if (!tile) return false
//   if (!tile.ref) return false
//   if (!tile.ref.current) return false
//   return tile.ref.current.cesiumElement
// }

// export const findModelByUrl = (url, modelList, tileViews) => {
//   let tile = tileViews.find(t => {
//     console.log('t', t)
//     return t.props.url == url
//   })
//   let model = modelList.find(
//     item => item.id+'-tile' === tile.key
//   )
//   if (!model) return false
//   return model
// }

export const createImageFileFromBase64 = async (
  base64Data,
  fileName,
  iscrop = true
) => {
  if (iscrop) {
    const blob = dataURItoBlob(base64Data)
    let file = null
    if (!projectStore.isEditSessionVisibleData) {
      const croppedCanvas = await crop(URL.createObjectURL(blob), (16 / 9))
      const croppedBase64Image = croppedCanvas.toDataURL('image/png')
      const croppedBlob = dataURItoBlob(croppedBase64Image)
      file = new File([croppedBlob], fileName)
    } else {
      file = new File([blob], fileName)
    }
    return file
  }

  const croppedBlob = dataURItoBlob(base64Data)
  const file = new File([croppedBlob], fileName)
  return file
}

function crop(data, aspectRatio) {
  // we return a Promise that gets resolved with our canvas element
  return new Promise(resolve => {
    // this image will hold our source image data
    const inputImage = new Image()

    // we want to wait for our image to load
    inputImage.onload = () => {
      // let's store the width and height of our image
      const inputWidth = inputImage.naturalWidth
      const inputHeight = inputImage.naturalHeight

      // get the aspect ratio of the input image
      const inputImageAspectRatio = inputWidth / inputHeight

      // if it's bigger than our target aspect ratio
      let outputWidth = inputWidth
      let outputHeight = inputHeight
      if (inputImageAspectRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio
      } else if (inputImageAspectRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio
      }

      // calculate the position to draw the image at
      const outputX = (outputWidth - inputWidth) * 0.5
      const outputY = (outputHeight - inputHeight) * 0.5

      // create a canvas that will present the output image
      const outputImage = document.createElement('canvas')

      // set it to the same size as the image
      outputImage.width = outputWidth
      outputImage.height = outputHeight

      // draw our image at position 0, 0 on the canvas
      const ctx = outputImage.getContext('2d')
      ctx.drawImage(inputImage, outputX, outputY)
      resolve(outputImage)
    }

    // start loading our image
    inputImage.src = data
  })
}

const dataURItoBlob = dataURI => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  const bb = new Blob([ab], { type: mimeString })
  return bb
}

export const reloadUrl = url => {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', url + '?v=' + Date.now(), true)
  xhr.setRequestHeader('Cache-Control', 'max-age=0')
  xhr.send()
}

export const merge_array = (array1, array2) => {
  const result_array = [];
  const arr = array1.concat(array2);
  let len = arr.length;
  const assoc = {};

  while (len--) {
    const item = arr[len];

    if (!assoc[item]) {
      result_array.unshift(item);
      assoc[item] = true;
    }
  }
  return result_array;
}

export const updateTransform = (trans, oTrans) => {
  let hpr = new HeadingPitchRoll(
    trans.heading * CesiumMath.RADIANS_PER_DEGREE,
    trans.pitch * CesiumMath.RADIANS_PER_DEGREE,
    trans.roll * CesiumMath.RADIANS_PER_DEGREE
  )
  // var h = (oTrans)?oTrans.height:0
  var quaternion = Quaternion.fromHeadingPitchRoll(hpr, new Quaternion())
  var scale = new Cartesian3(trans.scale, trans.scale, trans.scale)
  var translation = Cartesian3.fromDegrees(trans.lng, trans.lat, trans.height)
  var trs = new TranslationRotationScale(translation, quaternion, scale)
  return Matrix4.fromTranslationRotationScale(trs, new Matrix4())
}

const isUrl = urlString => {
  return (
    urlString &&
    (urlString.startsWith('https://') || urlString.startsWith('http://'))
  )
}

export const getModelUrBySrc = (src) => {
  if (!src) return false;

  if (src.includes('xd-visuals.com')) {
    return corsproxy + src;
  } else {
    return isUrl(src) ? src : 'https://' + src;
  }
}

export const geoidHeightReadFile = (url, type) => { //type: N2000, N60..
  return new Promise((resolve, reject) => {
    var m_minLat = 0.0, m_maxLat = 0.0, m_minLon = 0.0, m_maxLon = 0.0, m_resLat = 0.0, m_resLon = 0.0
    var m_value = []
    var m_nRow = 0, m_nCol = 0
    var nSize
    fetch(url)
      .then(response => {
        return response.blob();
      })
      .then(async myBlob => {
        const fileReader = new FileReader();
        fileReader.readAsText(myBlob);
        fileReader.addEventListener('loadend', async (e) => {
          const text = e.srcElement.result;
          var lines = text.split('\n');
          for (var i = 0; i < lines.length; i++) {
            var item = lines[i];
            if (i === 0) {
              if (item.replace(/^\s+|\s+$/g, '').split(/\s+/).length !== 6) {
                reject(new Error('file error'));
              }
              m_minLat = parseFloat(item.replace(/^\s+|\s+$/g, '').split(/\s+/)[0]);
              m_maxLat = parseFloat(item.replace(/^\s+|\s+$/g, '').split(/\s+/)[1]);
              m_minLon = parseFloat(item.replace(/^\s+|\s+$/g, '').split(/\s+/)[2]);
              m_maxLon = parseFloat(item.replace(/^\s+|\s+$/g, '').split(/\s+/)[3]);
              m_resLat = parseFloat(item.replace(/^\s+|\s+$/g, '').split(/\s+/)[4]);
              m_resLon = parseFloat(item.replace(/^\s+|\s+$/g, '').split(/\s+/)[5]);

              m_nCol = parseInt((m_maxLon - m_minLon) / m_resLon + 1);
              m_nRow = parseInt((m_maxLat - m_minLat) / m_resLat + 1);
              nSize = m_nCol * m_nRow;
            } else {
              let ss = item.replace(/^\s+|\s+$/g, '').split(/\s+/)
                .map(x => parseFloat(x))
              if (ss.length < m_nCol) continue
              m_value = m_value.concat(ss)
            }
          }
          var data = {
            name: url.substring(url.lastIndexOf('/') + 1),
            type: type,
            data: {
              m_minLat: m_minLat,
              m_maxLat: m_maxLat,
              m_minLon: m_minLon,
              m_maxLon: m_maxLon,
              m_resLat: m_resLat,
              m_resLon: m_resLon,
              m_nRow: m_nRow,
              m_nCol: m_nCol,
              m_value: m_value
            }
          }
          resolve(data)
        });
      })
  });
}

export const getGeoidHeight = (lat, lon, m_minLon, m_maxLon, m_minLat, m_maxLat, m_resLon, m_resLat, m_nRow, m_nCol, m_value) => {
  var v00, v01, v10, v11;
  var ix, iy;

  if ((lon < m_minLon) || (lon > m_maxLon) ||
    (lat < m_minLat) || (lat > m_maxLat))
    return false;
  var column = parseInt((lon - m_minLon) / m_resLon)
  var row = parseInt((m_maxLat - lat) / m_resLat)

  var ix = column;
  var iy = row;

  if (iy == (m_nRow - 1))
    iy -= 1;

  v00 = rawval(ix, iy, m_nCol, m_value);
  v01 = rawval(ix + 1, iy, m_nCol, m_value);
  v10 = rawval(ix, iy + 1, m_nCol, m_value);
  v11 = rawval(ix + 1, iy + 1, m_nCol, m_value);

  var vx = ((ix + 1) - column) * v00 + (column - ix) * v01;
  var vy = ((ix + 1) - column) * v10 + (column - ix) * v11;
  var geidHeight = ((iy + 1) - row) * vx + (row - iy) * vy;
  return geidHeight;
}

function rawval(col, row, m_nCol, m_value) {
  var index = row * m_nCol + col;
  return m_value[index];
}


export const getProjection = async (epsgCode) => {
  const codeList = {
    '3881':
      '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=27500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    '3877':
      '+proj=tmerc +lat_0=0 +lon_0=23 +k=1 +x_0=23500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    '3879':
      '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    '3903':
      '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs',
    '4326':
      '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees',
    '3067':
      '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    '3857': "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs",
    '3873': "+proj=tmerc +lat_0=0 +lon_0=19 +k=1 +x_0=19500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    '4978': "+proj=geocent +datum=WGS84 +units=m +no_defs +type=crs",
    '3878': "+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=24500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    '5111': "+proj=tmerc +lat_0=58 +lon_0=11.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    '5048': "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs"
  }
  const code = codeList[epsgCode.replace('EPSG:', '')]
  if (!code) {
    let getCodeUrl = 'https://epsg.io/' + epsgCode + '.proj4'
    var x = await axios.get(getCodeUrl)
    codeList[epsgCode] = x.data
  }
  proj4.defs('EPSG:' + epsgCode, codeList[epsgCode])
  proj4.defs(
    'EPSG:900913',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  )
  proj4.defs(
    'EPSG:3395',
    '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'
  )
  proj4.defs('EPSG:4979', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs')
  proj4.defs('EPSG:4978', '+proj=geocent +datum=WGS84 +units=m +no_defs')
  proj4.defs('WGS84', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");
  proj4.defs("EPSG:4258", "+proj=longlat +ellps=GRS80 +no_defs +type=crs");
  proj4.defs("EPSG:5048", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");

  return 'EPSG:' + epsgCode
}
export const getProjectionLocal = (epsgCode) => {
  const codeList = {
    '3881':
      '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=27500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    '3877':
      '+proj=tmerc +lat_0=0 +lon_0=23 +k=1 +x_0=23500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    '3879':
      '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    '3903':
      '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs',
    '4326':
      '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees',
    '3067':
      '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
    '3857': "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs",
    '3873': "+proj=tmerc +lat_0=0 +lon_0=19 +k=1 +x_0=19500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    '4978': "+proj=geocent +datum=WGS84 +units=m +no_defs +type=crs",
    '3878': "+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=24500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    '5111': "+proj=tmerc +lat_0=58 +lon_0=11.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    '5048': "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs"
  }
  proj4.defs('EPSG:' + epsgCode, codeList[epsgCode])
  proj4.defs(
    'EPSG:900913',
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs'
  )
  proj4.defs(
    'EPSG:3395',
    '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'
  )
  proj4.defs('EPSG:4979', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs')
  proj4.defs('EPSG:4978', '+proj=geocent +datum=WGS84 +units=m +no_defs')
  proj4.defs('WGS84', "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees");
  proj4.defs("EPSG:4258", "+proj=longlat +ellps=GRS80 +no_defs +type=crs");
  const code = codeList[epsgCode.replace('EPSG:', '')]
  if (!code) {
    return undefined
  }

  return 'EPSG:' + epsgCode
}
export const conversProjection = async (longitude, latitude, fromEPSPCode, toEPSGCode) => {
  const fromProjection = await getProjection(fromEPSPCode)
  const toProjection = await getProjection(toEPSGCode)
  var result = proj4(fromProjection, toProjection, { 'x': longitude, 'y': latitude });

  return result;
}
export const conversProjectionLocal = (x, y, fromEPSPCode, toEPSGCode) => {
  const fromProjection = getProjectionLocal(fromEPSPCode)
  const toProjection = getProjectionLocal(toEPSGCode)
  var result = proj4(fromProjection, toProjection, { 'x': x, 'y': y });

  return result;
}
export const convertProjectPlaneToWGS84 = (x, y, z, fromEPSPCode, toEPSGCode) => {
  const fromProjection = getProjectionLocal(fromEPSPCode)
  const toProjection = getProjectionLocal(toEPSGCode)
  var result = proj4(fromProjection, toProjection, [x, y, z]);

  return result;
}
export const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

export const getCurrentSketch = (currentSketchId, sketchesList, tileViews) => {
  if (currentSketchId) {
    let model = sketchesList.find(item => item.id == currentSketchId)
    if (!model) return false
    let fkey = currentSketchId
    let tile = tileViews.find(t => t.key == fkey)
    if (!tile) return false
    if (!tile.ref) return false
    if (!tile.ref.current) return false
    return { model, tile: tile.ref.current.cesiumElement }
  }
  return false
}
export const getCurrentSketchModel = (currentSketchId, currentSketchModelId, sketchesList, tileViews) => {
  if (currentSketchId) {
    let model = sketchesList.find(item => item.id == currentSketchId)
    if (!model) return false
    let fkey = currentSketchModelId
    let tile = tileViews.find(t => t?.key == fkey)
    if (!tile) return false
    if (!tile.ref) return false
    if (!tile.ref.current) return false
    return { model, tile: tile.ref.current.cesiumElement }
  }
  return false
}

export const calculateWH = (oW, oH, cW, cH) => {
  var w = cW
  var h = oH * w / oW
  if (oH > oW) {
    h = cW
    w = h * oW / oH
  }
  return { width: w, height: h }
}

export const extractHostname = (url) => {
  var hostname;
  if (url.indexOf("//") > -1) {
    hostname = url.split('/')[2];
  }
  else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];
  return hostname;
}


export const getAssetIdFromUrl = (url) => {
  // var base1 = "https://"+ extractHostname(url)
  var ar = url.split('/')
  if (ar.length > 3)
    return ar[3]
  return -1
}

export const rotateCamera = (viewer, angleH, angleV, tilt) => {
  if (!viewer) return

  if (!viewer.camera) return
  let opt = {
    orientation: {
      heading: viewer.camera.heading,
      pitch: viewer.camera.pitch,
      roll: viewer.camera.roll
    }
  }
  if (!isNaN(angleV)) {
    opt.orientation.pitch += CesiumMath.toRadians(angleV);
    if (opt.orientation.pitch < -CesiumMath.TWO_PI) {
      opt.orientation.pitch += CesiumMath.TWO_PI;
    }
    // opt.orientation.roll += CesiumMath.toRadians(angleV) / 2;
    // if (opt.orientation.roll < -CesiumMath.TWO_PI) {
    //   opt.orientation.roll += CesiumMath.TWO_PI;
    // }
  }
  if (!isNaN(angleH)) {
    opt.orientation.heading += CesiumMath.toRadians(angleH);
    if (opt.orientation.heading < -CesiumMath.TWO_PI) {
      opt.orientation.heading += CesiumMath.TWO_PI;
    }
  }

  if (!isNaN(tilt)) {
    opt.orientation.roll += CesiumMath.toRadians(tilt);
    if (opt.orientation.roll < -CesiumMath.TWO_PI) {
      opt.orientation.roll += CesiumMath.TWO_PI;
    }
  }
  viewer.camera.setView(opt)
}

export const calcHpr = (firstMatrix, hpr, out) => {
  if (hpr) {
    // var initRotation = Matrix4.getMatrix3(firstMatrix, new Matrix3())
    // var quaternion = Quaternion.fromRotationMatrix(initRotation, new Quaternion())
    // var hpr = HeadingPitchRoll.fromQuaternion(quaternion, new HeadingPitchRoll())
    let hprTrans = new HeadingPitchRoll(
      hpr.h * CesiumMath.RADIANS_PER_DEGREE,
      hpr.p * CesiumMath.RADIANS_PER_DEGREE,
      hpr.r * CesiumMath.RADIANS_PER_DEGREE,
    )
    var fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator('east', 'north')
    var translation = Matrix4.getTranslation(out, new Cartesian3())
    var scale = Matrix4.getScale(out, new Cartesian3())
    var m = Transforms.headingPitchRollToFixedFrame(translation, hprTrans, Ellipsoid.WGS84, fixedFrameTransform)
    return Matrix4.multiplyByScale(
      m,
      scale,
      new Matrix4()
    )
  }
  return out
}

export const calcScale = (firstMatrix, scale, out) => {
  if (scale) {
    var scale3 = Cartesian3.fromArray([scale, scale, scale])
    return Matrix4.setScale(out, scale3, new Matrix4())
  }
  return out
}

/**
 * Get Screen center coordinates
 * @param {*} viewer 
 */
export const getCenterScreenCoordinates = viewer => {
  var windowPosition = new Cartesian2(viewer.container.clientWidth / 2, viewer.container.clientHeight / 2);
  return getScreenCoordinates(viewer, windowPosition)
}
export const validDegreeBox = box => {
  function validDegree(value) {
    if (isNaN(value)) return false
    if (value > 180 || value < -180) return false
    return true
  }
  if (!validDegree(box.x1) || !validDegree(box.x2) || !validDegree(box.y1) || !validDegree(box.y2)) {
    return false
  }
  return true
}
export const getScreenCoordinates = (viewer, windowPosition) => {
  // ;
  var pickPosition = false
  var pickRay = viewer.scene.camera.getPickRay(windowPosition);
  var pickPosition = viewer.scene.globe.pick(pickRay, viewer.scene);
  if (!pickPosition) {
    let camera = viewer.camera
    let cartographic = camera.positionCartographic
    return {
      cartographic: cartographic,
      position: camera.position,
      oldPos: Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        0
      ),
      dis: cartographic.height
    }
  }

  var feature = viewer.scene.pick(windowPosition)
  if (feature && (feature instanceof Cesium3DTileFeature || feature.primitive instanceof Cesium3DTileset)) {
    const featurePosition = viewer.scene.pickPosition(windowPosition)
    pickPosition = featurePosition ? featurePosition : pickPosition
  }
  if (pickPosition) projectStore.setPickPosition(pickPosition)
  pickPosition = pickPosition || projectStore.pickPosition

  var centerCartographic = viewer.scene.globe.ellipsoid.cartesianToCartographic(pickPosition)
  if (centerCartographic.height < -2000) centerCartographic.height = 0
  var dis = Cartesian3.distance(pickPosition, viewer.camera.positionWC);
  return {
    cartographic: centerCartographic,
    position: Cartesian3.fromRadians(
      centerCartographic.longitude,
      centerCartographic.latitude,
      dis
    ),
    oldPos: pickPosition,
    dis
  }
}

/**
 * get User’s height from ground
 * @param {*} viewer 
 * @param {*} modellist 
 * @param {*} lon : type degrees
 * @param {*} lat : type degrees
 */
export const getUserHeightFromGround = async (viewer, modellist, lon, lat) => {
  var positions = [
    Cartographic.fromDegrees(lon, lat),
  ];
  return viewer.scene.sampleHeightMostDetailed(positions, modellist, 0.2);
}

/**
 * Rotate Origo
 * @param {*} heading 
 * @param {*} pitch 
 * @param {*} roll 
 * @param {*} fileOrigo Cartesian3
 */
export const Axis_Rotate = (heading, pitch, roll, fileOrigo) => {
  var headingRadian = degreesToRadians((heading));
  var pitchRadian = degreesToRadians(pitch);
  var rollRadian = degreesToRadians(roll);

  var headingQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Z, -headingRadian);
  var pitchQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Y, -pitchRadian);
  var rollQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_X, -rollRadian);

  var headingPitchQuaternion = Quaternion.multiply(headingQuaternion, pitchQuaternion, new Quaternion());
  var finalQuaternion = new Quaternion();
  Quaternion.multiply(headingPitchQuaternion, rollQuaternion, finalQuaternion);

  var rMat = new Matrix3();
  Matrix3.fromQuaternion(finalQuaternion, rMat);

  var modelMatrix = new Matrix4();
  Matrix4.fromRotationTranslation(rMat, Cartesian3.ZERO, modelMatrix);

  var eastNorthUp = Transforms.eastNorthUpToFixedFrame(fileOrigo);
  var currentRotation = new Matrix3();
  Matrix4.getMatrix3(eastNorthUp, currentRotation);

  var platelMatrix = new Matrix4();
  Matrix4.fromRotationTranslation(
    currentRotation,
    Cartesian3.ZERO,
    platelMatrix
  );

  var modelFinalMatrix = new Matrix4();
  Matrix4.multiply(platelMatrix, modelMatrix, modelFinalMatrix);

  return modelFinalMatrix;
};


/**
 * Convert Degrees to Raidans
 * @param {} val 
 */
var degreesToRadians = function (val) {
  return val * Math.PI / 180;
};

/**
 * convert cartesian3 to long, lat height
 * @param {*} cartesian3Pos 
 * @returns [long, lat, height]
 */
export const toDegrees = (cartesian3Pos) => {
  let pos = Cartographic.fromCartesian(cartesian3Pos)
  return [pos.longitude / Math.PI * 180, pos.latitude / Math.PI * 180, pos.height]
}

/**
 * Cal Rotation model around origo
 * @param {*} fileOrg : Cartesian3
 * @param {*} modelCenter:  Cartesian3
 * @param {*} heading : number
 * @param {*} pitch : number
 * @param {*} roll : number
 * @param {*} scale : number
 */
export const CalRotationModel = function (fileOrg, modelCenter, heading, pitch, roll, scale) {
  try {
    if (fileOrg && JSON.stringify(fileOrg) !== JSON.stringify({ x: 0, y: 0, z: 0 })) {
      var _fileOrgCartographic = Cartographic.fromCartesian(fileOrg)
      var fileOrgCar3 = Ellipsoid.WGS84.cartographicToCartesian(_fileOrgCartographic);

      var modelPosition = new Cartesian3();
      modelPosition.x = scale * modelCenter.x;
      modelPosition.y = scale * modelCenter.y;
      modelPosition.z = scale * modelCenter.z;

      let positionRotate = Axis_Rotate(heading, pitch, roll, fileOrgCar3);
      var xyzCenter = new Cartesian3();
      Matrix4.multiplyByPoint(positionRotate, modelPosition, xyzCenter);

      fileOrgCar3.x += xyzCenter.x;
      fileOrgCar3.y += xyzCenter.y;
      fileOrgCar3.z += xyzCenter.z;

      var headingRadian = degreesToRadians((heading));
      var pitchRadian = degreesToRadians(pitch);
      var rollRadian = degreesToRadians(roll);

      var eastNorthUp = Transforms.eastNorthUpToFixedFrame(fileOrgCar3);

      var currentRotation = new Matrix3();
      Matrix4.getMatrix3(eastNorthUp, currentRotation);

      var currentTranslation = new Cartesian3();
      Matrix4.getTranslation(eastNorthUp, currentTranslation);

      var headingQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Z, -headingRadian);
      var pitchQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Y, -pitchRadian);
      var rollQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_X, -rollRadian);

      var headingPitchQuaternion = Quaternion.multiply(headingQuaternion, pitchQuaternion, new Quaternion());
      var finalQuaternion = new Quaternion();
      Quaternion.multiply(headingPitchQuaternion, rollQuaternion, finalQuaternion);

      var rM = new Matrix3();
      Matrix3.fromQuaternion(finalQuaternion, rM);

      Matrix3.multiply(currentRotation, rM, currentRotation);

      var modelMatrix = new Matrix4();
      Matrix4.fromRotationTranslation(
        currentRotation,
        currentTranslation,
        modelMatrix
      );

      var scale_m4 = Matrix4.fromScale(new Cartesian3(scale, scale, scale));
      var xTran = new Matrix4();
      Matrix4.multiply(modelMatrix, scale_m4, xTran);
      return xTran;
    }
    return false;
  } catch (error) {
    console.log(error)
  }
};

/**
 * Get imagery layer feature info.
 *
 * @param   {Cartesian2}  position  The position for lookup.
 *
 * @return  {Array}                 The array of features.
 */
export const getImageryLayerFeatures = function (_viewer, position) {
  return new Promise(resolve => {
    // get the ray for lookup
    const pickRay = _viewer.camera.getPickRay(position)
    // look for imagery layer features at provided ray
    const featuresPromise = _viewer.imageryLayers.pickImageryLayerFeatures(
      pickRay,
      _viewer.scene
    )
    // nothing picked
    if (!defined(featuresPromise)) {
      resolve([])
    } else {
      featuresPromise.then(featuresPromiseVal => {
        // filter any found features that only include feature info properties
        const filteredFeatures = featuresPromiseVal.filter(x => {
          return x.properties && Object.getOwnPropertyNames(x.properties).length
        })
        // return the filtered features
        resolve(filteredFeatures)
      })
    }
  })
}

/**
 * Get position on mouse click
 * @param {*} viewer 
 * @param {*} clickPosition mouse position or click.position
 */
export const clickPoint = (viewer, clickPosition) => {
  var scene = viewer.scene;
  try {
    var pickedObject = scene.pick(clickPosition);
    var position;

    const isWMSTile = pickedObject && pickedObject.primitive && pickedObject.primitive._material && pickedObject.primitive._material.type == "Image"
    if (defined(pickedObject) && !isWMSTile) {
      scene.render();
      if (scene.pickPositionSupported) {
        position = scene.pickPosition(clickPosition);
      }
      if (!position) {
        var ray = scene.camera.getPickRay(clickPosition);
        position = scene.globe.pick(ray, scene);
        return {
          position: position
        }
      }
      return {
        position: position,
        pickedObject: pickedObject
      }
    } else {

      var ray = scene.camera.getPickRay(clickPosition);
      position = scene.globe.pick(ray, scene);
      return {
        position: position
      }
    }
  } catch (error) {
    console.log("clickPoint: " + error)
  }
}

/**
 * Go to viewer point
 * @param {*} viewer 
 * @param {*} capture 
 */
export const goViewpoint = (viewer, capture) => {
  if (!viewer.camera || !capture.cameraData) return
  let destination = new Cartesian3(
    capture.cameraData.position.x,
    capture.cameraData.position.y,
    capture.cameraData.position.z
  )
  let direction = new Cartesian3(
    capture.cameraData.direction.x,
    capture.cameraData.direction.y,
    capture.cameraData.direction.z
  )
  let up = new Cartesian3(
    capture.cameraData.up.x,
    capture.cameraData.up.y,
    capture.cameraData.up.z
  )
  viewer.camera.flyTo({
    duration: 1.5,
    destination,
    orientation: {
      direction,
      up,
    },
  })
}

/**
 * Get model center for model ion
 * @param {*} lon 
 * @param {*} lat 
 * @param {*} alt 
 * @param {*} computedTransform 
 * @returns array []
 */
export const getModelCenter = (lon, lat, alt, computedTransform) => {
  var matFirst = getFirstMatrixMeshModel(lon, lat, alt, computedTransform);
  var modelCenter = new Cartesian3();
  Matrix4.getTranslation(matFirst, modelCenter);
  return [modelCenter.x, modelCenter.y, modelCenter.z];
};


//////////////////// get first matrix from external mesh tileset model with REST API
export const getFirstMatrixMeshModel = (firstLon, firstLat, firstAlt, computedTransform) => {
  var matInv = new Matrix4();
  var matTran = new Matrix4();
  var firstPoint = new Cartesian3();
  Cartesian3.fromDegrees(firstLon, firstLat, firstAlt, Ellipsoid.WGS84, firstPoint);
  var matENUtoECEF = new Matrix4();
  Transforms.eastNorthUpToFixedFrame(firstPoint, Ellipsoid.WGS84, matENUtoECEF);
  Matrix4.inverse(matENUtoECEF, matInv);
  Matrix4.multiply(matInv, computedTransform, matTran);
  return matTran;
};

/**
 * get refPoint from tileset
 * @param {*} modelTileset //for .glb.., modelTileset=tileset.root.transform
 * @returns Matrix3
 */
export const getRefPoint = (modelTileset) => {
  var refPoint = new Matrix3();
  Matrix4.getTranslation(modelTileset, refPoint);
  return refPoint;
};

/**
 * Đổi tọa độ toàn cầu WGS84 ra hệ tọa độ cục bộ gốc đặt tại đểm refPoint
 * @param {*} refPoint Cartesian3
 * @param {*} pointECEF Cartesian3
 * @returns Cartesian3
 */
export const cnvECEFtoENU = (refPoint, pointECEF) => {
  var pointENU = new Cartesian3();
  var matECEFtoENU = new Matrix4();
  var matENUtoECEF = new Matrix4();
  Transforms.eastNorthUpToFixedFrame(refPoint, Ellipsoid.WGS84, matENUtoECEF);
  Matrix4.inverse(matENUtoECEF, matECEFtoENU);
  Matrix4.multiplyByPoint(matECEFtoENU, pointECEF, pointENU);
  return pointENU;
};

/**
 * Đổi hệ tọa độ cục bộ gốc đặt tại đểm refPoint ra tọa độ toàn cầu WGS84 
 * @param {*} refPoint Cartesian3
 * @param {*} pointENU Cartesian3
 * @returns Cartesian3
 */
export const cnvENUtoECEF = (refPoint, pointENU) => {
  var pointECEF = new Cartesian3();
  var matENUtoECEF = new Matrix4();
  Transforms.eastNorthUpToFixedFrame(refPoint, Ellipsoid.WGS84, matENUtoECEF);
  Matrix4.multiplyByPoint(matENUtoECEF, pointENU, pointECEF);
  return pointECEF;
};

/**
 * convert position on WGS84 to local project plane
 * @param {*} refPoint //Cartesian3 refpoint of model from tileset
 * @param {*} modelCenter //Cartesian3 modelcenter of model
 * @param {*} pointGlobal  //Cartesian3 point click
 * @returns Cartesian3
 */
export const cnvGlobalToLocal = (refPoint, modelCenter, pointGlobal) => {
  var pointENU = cnvECEFtoENU(refPoint, pointGlobal);
  pointENU.x += modelCenter.x;
  pointENU.y += modelCenter.y;
  pointENU.z += modelCenter.z;
  return pointENU;
};

/**
 * convert position on WGS84 to local project plane
 * @param {*} refPoint //Cartesian3 refpoint project
 * @param {*} refLocalProject //Cartesian3 refLocalProject
 * @param {*} pointLocal //Cartesian3
 * @returns Cartesian3
 */
export const cnvLocalToGlobal = function (refPoint, refLocalProject, pointLocal) {
  var pointENU = new Cartesian3();
  pointENU.x = pointLocal.x - refLocalProject.x;
  pointENU.y = pointLocal.y - refLocalProject.y;
  pointENU.z = pointLocal.z - refLocalProject.z;
  var pointECEF = cnvENUtoECEF(refPoint, pointENU);
  return pointECEF;
};

/**
 * distance on local project plane
 * @param {*} refPoint Cartesian3  refpoint of model from tileset
 * @param {*} modelCenter Cartesian3 modelcenter of model
 * @param {*} pointGlobal1 Cartesian3 point1 click
 * @param {*} pointGlobal2 Cartesian3 point2 click
 * @returns distance number
 */
export const distOnLocal = (refPoint, modelCenter, pointGlobal1, pointGlobal2) => {
  var pointPlane1 = cnvGlobalToLocal(refPoint, modelCenter, pointGlobal1);
  var pointPlane2 = cnvGlobalToLocal(refPoint, modelCenter, pointGlobal2);
  var distance = Cartesian3.distance(pointPlane2, pointPlane1);
  return distance;
};

const rotateRzOnPlane = function (refLocalProject, localOrg, scale, angleZ) {
  var headingRadian = degreesToRadians((angleZ));
  var headingQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Z, -headingRadian);
  var rMat = new Matrix3();
  Matrix3.fromQuaternion(headingQuaternion, rMat);
  var vecTran = new Cartesian3();
  vecTran.x = localOrg.x - refLocalProject.x;
  vecTran.y = localOrg.y - refLocalProject.y;
  vecTran.z = localOrg.z - refLocalProject.z;

  var modelRot = new Matrix4();
  Matrix4.fromRotationTranslation(rMat, Cartesian3.ZERO, modelRot);

  var xyzLocal = new Cartesian3();
  Matrix4.multiplyByPoint(modelRot, vecTran, xyzLocal);
  xyzLocal.x *= scale;
  xyzLocal.y *= scale;
  xyzLocal.z *= scale;
  xyzLocal.x += refLocalProject.x;
  xyzLocal.y += refLocalProject.y;
  xyzLocal.z += refLocalProject.z;

  return xyzLocal;
};
var mergeEditModelOnPlane2 = function (refProject, refLocalProject, localOrg, modelCenter, heading, pitch, roll, scale, angleZ) {
  var localOrgRotate = rotateRzOnPlane(refLocalProject, localOrg, scale, angleZ);

  localOrg.x = localOrgRotate.x;
  localOrg.y = localOrgRotate.y;
  localOrg.z = localOrgRotate.z;

  var headingRadian = degreesToRadians((heading));
  var pitchRadian = degreesToRadians(pitch);
  var rollRadian = degreesToRadians(roll);

  var headingQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Z, -headingRadian);
  var pitchQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Y, -pitchRadian);
  var rollQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_X, -rollRadian);

  var headingPitchQuaternion = Quaternion.multiply(headingQuaternion, pitchQuaternion, new Quaternion());
  var finalQuaternion = new Quaternion();
  Quaternion.multiply(headingPitchQuaternion, rollQuaternion, finalQuaternion);


  var rMat = new Matrix3();
  Matrix3.fromQuaternion(finalQuaternion, rMat);
  var modelRot = new Matrix4();
  Matrix4.fromRotationTranslation(rMat, Cartesian3.ZERO, modelRot);


  var vecTran = new Cartesian3();


  var xyzCenter = new Cartesian3();
  Matrix4.multiplyByPoint(modelRot, modelCenter, xyzCenter);
  xyzCenter.x *= scale;
  xyzCenter.y *= scale;
  xyzCenter.z *= scale;

  xyzCenter.x += (localOrg.x - refLocalProject.x);
  xyzCenter.y += (localOrg.y - refLocalProject.y);
  xyzCenter.z += (localOrg.z - refLocalProject.z);


  vecTran.x = localOrg.x + xyzCenter.x - refLocalProject.x;
  vecTran.y = localOrg.y + xyzCenter.y - refLocalProject.y;
  vecTran.z = localOrg.z + xyzCenter.z - refLocalProject.z;


  var modelMatrix = new Matrix4();
  Matrix4.fromRotationTranslation(rMat, xyzCenter, modelMatrix);


  var eastNorthUp = Transforms.eastNorthUpToFixedFrame(refProject);
  var modelFinalMatrix = new Matrix4();

  Matrix4.multiply(eastNorthUp, modelMatrix, modelFinalMatrix);

  var xTran = new Matrix4();
  var scale_m4 = Matrix4.fromScale(new Cartesian3(scale, scale, scale));


  Matrix4.multiply(modelFinalMatrix, scale_m4, xTran);

  //////////////////// calculate FileORG
  vecTran.x = localOrg.x - refLocalProject.x;
  vecTran.y = localOrg.y - refLocalProject.y;
  vecTran.z = localOrg.z - refLocalProject.z;

  var currentORG = cnvENUtoECEF(refProject, vecTran);
  console.log("Current FileORG" + currentORG);
  /////////////////// calculate XYZLocal
  var currentXYZ = new Cartesian3()
  currentXYZ.x = localOrg.x - refLocalProject.x;
  currentXYZ.y = localOrg.y - refLocalProject.y;
  currentXYZ.z = localOrg.z - refLocalProject.z;
  console.log("Current xyzLocal" + currentXYZ);

  return { fileOrigo: currentORG, xyzLocal: currentXYZ, modelMatrix: xTran }
};
/**
* mergeEditModelOnPlane
* @param {*} refProject Cartesian3
* @param {*} refLocalProject Cartesian3
* @param {*} localOrg Cartesian3
* @param {*} modelCenter Cartesian3
* @param {*} heading Number
* @param {*} pitch Number
* @param {*} roll Number
* @param {*} scale Number
* @param {*} isReadCoordinate bool model có tọa độ thực không
* @param {*} headingRotation Number //heading (Z) project
* @returns {fileOrigo, xyzLocal, modelMatrix}
*/
export const mergeEditModelOnPlane = (refProject, refLocalProject, localOrg, modelCenter, heading, pitch, roll, scale, isReadCoordinate = false, headingRotation = 0) => {
  var headingRadian = degreesToRadians((heading + headingRotation));
  var pitchRadian = degreesToRadians(pitch);
  var rollRadian = degreesToRadians(roll);
  var headingQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Z, -headingRadian);
  var pitchQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_Y, -pitchRadian);
  var rollQuaternion = Quaternion.fromAxisAngle(Cartesian3.UNIT_X, -rollRadian);
  var headingPitchQuaternion = Quaternion.multiply(headingQuaternion, pitchQuaternion, new Quaternion());
  var finalQuaternion = new Quaternion();
  Quaternion.multiply(headingPitchQuaternion, rollQuaternion, finalQuaternion);
  var rMat = new Matrix3();
  Matrix3.fromQuaternion(finalQuaternion, rMat);
  var modelRot = new Matrix4();
  Matrix4.fromRotationTranslation(rMat, Cartesian3.ZERO, modelRot);
  // var vecTran = new Cartesian3();
  // vecTran.x = localOrg.x + modelCenter.x - refLocalProject.x;
  // vecTran.y = localOrg.y + modelCenter.y - refLocalProject.y;
  // vecTran.z = localOrg.z + modelCenter.z - refLocalProject.z;
  // var xyzCenter = new Cartesian3();
  // Matrix4.multiplyByPoint(modelRot, vecTran, xyzCenter);
  // xyzCenter.x *= scale;
  // xyzCenter.y *= scale;
  // xyzCenter.z *= scale;
  // var vecTran = new Cartesian3();
  // var xyzCenter = new Cartesian3();
  // Matrix4.multiplyByPoint(modelRot, modelCenter, xyzCenter);
  // xyzCenter.x *= scale;
  // xyzCenter.y *= scale;
  // xyzCenter.z *= scale;

  // xyzCenter.x += (localOrg.x - refLocalProject.x);
  // xyzCenter.y += (localOrg.y - refLocalProject.y);
  // xyzCenter.z += (localOrg.z - refLocalProject.z);

  // vecTran.x = localOrg.x + xyzCenter.x - refLocalProject.x;
  // vecTran.y = localOrg.y + xyzCenter.y - refLocalProject.y;
  // vecTran.z = localOrg.z + xyzCenter.z - refLocalProject.z;
  var vecTran = new Cartesian3();
  var xyzCenter = new Cartesian3();
  if (headingRotation || isReadCoordinate) { // if model has read world coordinate (landxml or ifc has coordinate) rotate flow project refpoint
    vecTran.x = localOrg.x + modelCenter.x - refLocalProject.x;
    vecTran.y = localOrg.y + modelCenter.y - refLocalProject.y;
    vecTran.z = localOrg.z + modelCenter.z - refLocalProject.z;
    Matrix4.multiplyByPoint(modelRot, vecTran, xyzCenter);
    xyzCenter.x *= scale;
    xyzCenter.y *= scale;
    xyzCenter.z *= scale;
  } else { // if model not has real world coordinate model rotate flow origo of model
    Matrix4.multiplyByPoint(modelRot, modelCenter, xyzCenter);
    xyzCenter.x *= scale;
    xyzCenter.y *= scale;
    xyzCenter.z *= scale;
    xyzCenter.x += (localOrg.x - refLocalProject.x);
    xyzCenter.y += (localOrg.y - refLocalProject.y);
    xyzCenter.z += (localOrg.z - refLocalProject.z);
  }
  var modelMatrix = new Matrix4();
  Matrix4.fromRotationTranslation(rMat, xyzCenter, modelMatrix);
  var eastNorthUp = Transforms.eastNorthUpToFixedFrame(refProject);
  var modelFinalMatrix = new Matrix4();
  Matrix4.multiply(eastNorthUp, modelMatrix, modelFinalMatrix);
  var xTran = new Matrix4();
  var scale_m4 = Matrix4.fromScale(new Cartesian3(scale, scale, scale));
  Matrix4.multiply(modelFinalMatrix, scale_m4, xTran);

  //////////////////// calculate FileORG
  vecTran.x = localOrg.x - refLocalProject.x;
  vecTran.y = localOrg.y - refLocalProject.y;
  vecTran.z = localOrg.z - refLocalProject.z;
  var currentORG = cnvENUtoECEF(refProject, vecTran);

  /////////////////// calculate XYZLocal
  var currentXYZ = new Cartesian3()
  currentXYZ.x = localOrg.x - refLocalProject.x;
  currentXYZ.y = localOrg.y - refLocalProject.y;
  currentXYZ.z = localOrg.z - refLocalProject.z;

  return { fileOrigo: currentORG, xyzLocal: currentXYZ, modelMatrix: xTran }
};

/**
 * insert XML or ifc has coordinates Model On Plane
 * @param {*} vecTran Cartesian3
 * @param {*} refProject Cartesian3
 * @param {*} refLocalProject Cartesian3
 * @param {*} localOrg Cartesian3
 * @param {*} modelCenter Cartesian3
 * @param {*} heading Number
 * @param {*} pitch Number
 * @param {*} roll Number
 * @param {*} scale Number
 * @returns {xyzLocal: , fileOrg:, modelMatrix:}
 */
export const setXMLModelOnPlane = (vecTran, refProject, refLocalProject, localOrg, modelCenter, heading, pitch, roll, scale, isReadCoordinate = false, headingRotation = 0) => {

  var posInsertLocal = new Cartesian3();
  posInsertLocal.x = vecTran.x + localOrg.x;
  posInsertLocal.y = vecTran.y + localOrg.y;
  posInsertLocal.z = vecTran.z + localOrg.z;
  let _mergeModelOnPlane = mergeEditModelOnPlane(refProject, refLocalProject, posInsertLocal, modelCenter, heading, pitch, roll, scale, isReadCoordinate, headingRotation);
  return { fileOrigo: _mergeModelOnPlane.fileOrigo, xyzLocal: _mergeModelOnPlane.xyzLocal, modelMatrix: _mergeModelOnPlane.modelMatrix }
};

/**
 * 
 * @param {*} currentTran Cartesian3
 * @param {*} vecTran Cartesian3
 * @param {*} refProject Cartesian3
 * @param {*} refLocalProject Cartesian3
 * @param {*} localOrg Cartesian3
 * @param {*} modelCenter Cartesian3
 * @param {*} heading Number
 * @param {*} pitch Number
 * @param {*} roll Number
 * @param {*} scale Number
 * @returns {xyzLocal: , fileOrg:, modelMatrix:}
 */
export const dragXMLModelOnPlane = (currentTran, vecTran, refProject, refLocalProject, localOrg, modelCenter, heading, pitch, roll, scale, isReadCoordinate = false, headingRotation = 0) => {
  var vecDrag = new Cartesian3();
  vecDrag.x = currentTran.x + vecTran.x;
  vecDrag.y = currentTran.y + vecTran.y;
  vecDrag.z = currentTran.z + vecTran.z;
  let _XMLModelOnPlane = setXMLModelOnPlane(vecDrag, refProject, refLocalProject, localOrg, modelCenter, heading, pitch, roll, scale, isReadCoordinate, headingRotation);
  return { fileOrigo: _XMLModelOnPlane.fileOrigo, xyzLocal: _XMLModelOnPlane.xyzLocal, modelMatrix: _XMLModelOnPlane.modelMatrix }
};

/**
 * 
 * @param {*} currentTran Cartesian3
 * @param {*} vecTran Cartesian3
 * @param {*} refProject Cartesian3
 * @param {*} refLocalProject Cartesian3
 * @param {*} localOrg Cartesian3
 * @param {*} modelCenter Cartesian3
 * @param {*} heading Number
 * @param {*} pitch Number
 * @param {*} roll Number
 * @param {*} scale Number
 * @returns {xyzLocal: , fileOrg:, modelMatrix:}
 */
export const dragMeshModelOnPlane = (currentTran, vecTran, refProject, refLocalProject, modelCenter, heading, pitch, roll, scale, isReadCoordinate = false, headingRotation = 0) => {
  var vecDrag = new Cartesian3();
  vecDrag.x = currentTran.x + vecTran.x;
  vecDrag.y = currentTran.y + vecTran.y;
  vecDrag.z = currentTran.z + vecTran.z;
  let _mergeEditModelOnPlane = mergeEditModelOnPlane(refProject, refLocalProject, vecDrag, modelCenter, heading, pitch, roll, scale, isReadCoordinate, headingRotation);
  return { fileOrigo: _mergeEditModelOnPlane.fileOrigo, xyzLocal: _mergeEditModelOnPlane.xyzLocal, modelMatrix: _mergeEditModelOnPlane.modelMatrix }
};

/**
 * getXYZDialog
 * @param {*} refLocalProject Cartesian3
 * @param {*} localOrg Cartesian3
 * @param {*} xyzLocal Cartesian3
 * @returns Cartesian3
 */
export const getXYZDialog = (refLocalProject, localOrg, xyzLocal) => {
  var posXYZ = new Cartesian3();
  posXYZ.x = xyzLocal.x - (localOrg.x - refLocalProject.x)
  posXYZ.y = xyzLocal.y - (localOrg.y - refLocalProject.y)
  posXYZ.z = xyzLocal.z - (localOrg.z - refLocalProject.z)
  if (!Number.isInteger(posXYZ.x)) posXYZ.x = util.mathRound(posXYZ.x, 16);
  if (!Number.isInteger(posXYZ.y)) posXYZ.y = util.mathRound(posXYZ.y, 16);
  if (!Number.isInteger(posXYZ.z)) posXYZ.z = util.mathRound(posXYZ.z, 16);

  return posXYZ;
};

/**
 * 
 * @param {*} refLocalProject Cartesian3
 * @param {*} localOrg Cartesian3
 * @param {*} xyzInput Cartesian3
 * @returns Cartesian3
 */
export const invXYZDialog = function (refLocalProject, localOrg, xyzInput) {
  var xyzLocal = new Cartesian3();
  xyzLocal.x = xyzInput.x + (localOrg.x - refLocalProject.x);
  xyzLocal.y = xyzInput.y + (localOrg.y - refLocalProject.y);
  xyzLocal.z = xyzInput.z + (localOrg.z - refLocalProject.z);
  return xyzLocal;
};

/**
 * 
 * @param {*} refProject Cartesian3
 * @param {*} refLocalProject Cartesian3
 * @param {*} localOrg Cartesian3
 * @param {*} xyzLocal Cartesian3
 * @param {*} modelCenter Cartesian3
 * @param {*} heading Number
 * @param {*} pitch Number
 * @param {*} roll Number
 * @param {*} scale Number
 * @param {*} model Object
 * @returns modelMatrix
 */
export const loadtModelOnPlane = (refProject, refLocalProject, localOrg, xyzLocal, modelCenter, heading, pitch, roll, scale, model, headingRotation) => {
  var posCurrent2 = new Cartesian3();
  if ((model.type === 'landxml') || isCloudPointXDEnginePlane(model) || (model.crs.realWorldCoordinates && model.crs.realWorldCoordinates === 1)) {
    var xyzDialog2 = getXYZDialog(
      refLocalProject ? refLocalProject : localOrg,
      localOrg,
      xyzLocal
    );
    posCurrent2.x = localOrg.x + xyzDialog2.x;
    posCurrent2.y = localOrg.y + xyzDialog2.y;
    posCurrent2.z = localOrg.z + xyzDialog2.z;
  } else {
    posCurrent2.x = xyzLocal.x;
    posCurrent2.y = xyzLocal.y;
    posCurrent2.z = xyzLocal.z;
  }

  const t = mergeEditModelOnPlane(
    refProject,
    refLocalProject,
    posCurrent2,
    modelCenter,
    heading,
    pitch,
    roll,
    scale,
    ((model.type === 'landxml') || (model.crs.realWorldCoordinates && model.crs.realWorldCoordinates === 1)) ? true : false,
    headingRotation
  )

  return t.modelMatrix;
};

/**
 * 
 * @param {*} fileOrigo Cartesian3
 * @param {*} xyzLocal Cartesian3
 * @param {*} vecTran Cartesian3
 * @returns fileOrigo, xyzLocal, modelMatrix
 */
export const transformECFModel = (fileOrigo, xyzLocal, vecTran) => {
  if (!vecTran) {
    vecTran = xyzLocal;
  }
  var modelMatrix = new Matrix4();
  Matrix4.fromTranslation(vecTran, modelMatrix);
  fileOrigo.x = vecTran.x;
  fileOrigo.y = vecTran.y;
  fileOrigo.z = vecTran.z;

  xyzLocal.x = vecTran.x;
  xyzLocal.y = vecTran.y;
  xyzLocal.z = vecTran.z;

  return { fileOrigo: fileOrigo, xyzLocal: xyzLocal, modelMatrix: modelMatrix }
};

/**
   * Convert cartesian3 to degrees chuyển cartesian3 to độ (60., 24..)
   * @param point cartesian3
   * @return degrees
   */
export const cartesian3ToWGS84 = (point) => {
  const cartographic = Cartographic.fromCartesian(point);
  const lat = CesiumMath.toDegrees(cartographic.latitude);
  const lng = CesiumMath.toDegrees(cartographic.longitude);
  const alt = cartographic.height;
  return {
    longitude: lng,
    latitude: lat,
    height: alt,
  };
}

/**
   * Get head, pitch, roll
   * pointA: Cartesian3
   * pointB: Cartesian3
   */
export const getHeadingPitchRoll = (pointA, pointB) => {
  const transform = Transforms.eastNorthUpToFixedFrame(pointA);
  const positionvector = Cartesian3.subtract(pointB, pointA, new Cartesian3());
  const vector = Matrix4.multiplyByPointAsVector(Matrix4.inverse(transform, new Matrix4()), positionvector, new Cartesian3());
  const direction = Cartesian3.normalize(vector, new Cartesian3());
  //heading
  const heading = Math.atan2(direction.y, direction.x) - CesiumMath.PI_OVER_TWO;
  //pitch
  const pitch = CesiumMath.PI_OVER_TWO - CesiumMath.acosClamped(direction.z);
  return { heading: heading, pitch: pitch, roll: 0 }
}

/**
 * 
 * @param {*} startPoint Cartesian3
 * @param {*} endPoint Cartesian3
 * @returns Bearing
 */
export const calculateBearing = (startPoint, endPoint) => {
  const start = Cartographic.fromCartesian(startPoint);
  const end = Cartographic.fromCartesian(endPoint);

  const y = Math.sin(end.longitude - start.longitude) * Math.cos(end.latitude);
  const x =
    Math.cos(start.latitude) * Math.sin(end.latitude) -
    Math.sin(start.latitude) * Math.cos(end.latitude) *
    Math.cos(end.longitude - start.longitude);

  const bearing = Math.atan2(y, x);
  return CesiumMath.toDegrees(bearing);
}

export const getMidpoint = (cartographic1, cartographic2) => {
  // Compute vector from p1 to p2
  var p1 = cartographic1
  var p2 = cartographic2

  var p1p2 = new Cartesian3(0.0, 0.0, 0.0);

  Cartesian3.subtract(p2, p1, p1p2);

  // Compute vector to midpoint

  var halfp1p2 = new Cartesian3(0.0, 0.0, 0.0);

  Cartesian3.multiplyByScalar(p1p2, 0.5, halfp1p2);

  // Compute point half way between p1 and p2

  var p3 = new Cartesian3(0.0, 0.0, 0.0);

  p3 = Cartesian3.add(p1, halfp1p2, p3);
  var midPt = Cartographic.fromCartesian(p3);
  return p3
}

const setMiddleVirtualPoint = (firstP, secondP, length, positions) => {
  const position = getMidpoint(firstP, secondP);
  const firstIndex = positions.indexOf(firstP);
  return {
    position,
    namePoint: [firstIndex, firstIndex >= length ? 0 : firstIndex + 1],
    pointMid: firstIndex,
    positions
  };
}

export const addAllVirtualEditPoints = (positions) => {
  const currentPoints = [...positions];
  let data = [];
  currentPoints.forEach((pos, index) => {
    const currentPoint = pos;
    const nextIndex = (index + 1) % (currentPoints.length);
    const nextPoint = currentPoints[nextIndex];
    let mid = setMiddleVirtualPoint(currentPoint, nextPoint, currentPoints.length - 1, positions);
    data.push(mid);
  });
  return data;
}

export const expandBoundingBox = (minBB1, maxBB1, minBB2, maxBB2) => {
  var centerBB = new Cartesian3();
  var minBB = new Cartesian3();
  var maxBB = new Cartesian3();

  minBB.x = minBB1.x;
  if (minBB.x > minBB2.x) {
    minBB.x = minBB2.x;
  }
  minBB.y = minBB1.y;
  if (minBB.y > minBB2.y) {
    minBB.y = minBB2.y;
  }
  minBB.z = minBB1.z;
  if (minBB.z > minBB2.z) {
    minBB.z = minBB2.z;
  }

  maxBB.x = maxBB1.x;
  if (maxBB.x < maxBB2.x) {
    maxBB.x = maxBB2.x;
  }
  maxBB.y = maxBB1.y;
  if (maxBB.y < maxBB2.y) {
    maxBB.y = maxBB2.y;
  }
  maxBB.z = maxBB1.z;
  if (maxBB.z < maxBB2.z) {
    maxBB.z = maxBB2.z;
  }

  centerBB.x = 0.5 * (minBB.x + maxBB.x);
  centerBB.y = 0.5 * (minBB.y + maxBB.y);
  centerBB.z = 0.5 * (minBB.z + maxBB.z);

  return { minBB: minBB, maxBB: maxBB, centerBB: centerBB }
};




/**
 * Distance
 * @param {*} points array cartersian3
 * @returns 
 */
export const spaceDistance = (points) => {
  if (points.length >= 2) {
    var point1 = Cartographic.fromCartesian(points[points.length - 2]);
    var point2 = Cartographic.fromCartesian(points[points.length - 1]);
    const geodesic = new EllipsoidGeodesic();
    geodesic.setEndPoints(point1, point2);
    let s = geodesic.surfaceDistance;
    s = Math.sqrt(Math.pow(s, 2) + Math.pow(point2.height - point1.height, 2));
    if (s >= 1000) {
      return (s / 1000).toFixed(1) + ' km';
    }
    return s.toFixed(2) + ' m';
  }
}

export const computeArea = (points) => {
  var radiansPerDegree = Math.PI / 180.0;
  var degreesPerRadian = 180.0 / Math.PI;


  function Angle(p1, p2, p3) {
    var bearing21 = Bearing(p2, p1);
    var bearing23 = Bearing(p2, p3);
    var angle = bearing21 - bearing23;
    if (angle < 0) {
      angle += 360;
    }
    return angle;
  }

  function Bearing(from, to) {
    from = Cartographic.fromCartesian(from);
    to = Cartographic.fromCartesian(to);

    var lat1 = from.latitude;
    var lon1 = from.longitude;
    var lat2 = to.latitude;
    var lon2 = to.longitude;
    var angle = -Math.atan2(Math.sin(lon1 - lon2) * Math.cos(lat2), Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2));
    if (angle < 0) {
      angle += Math.PI * 2.0;
    }
    angle = angle * degreesPerRadian;
    return angle;
  }

  function distance(point1, point2) {
    var point1cartographic = Cartographic.fromCartesian(point1);
    var point2cartographic = Cartographic.fromCartesian(point2);
    var geodesic = new EllipsoidGeodesic();
    geodesic.setEndPoints(point1cartographic, point2cartographic);
    var s = geodesic.surfaceDistance;
    s = Math.sqrt(Math.pow(s, 2) + Math.pow(point2cartographic.height - point1cartographic.height, 2));
    return s;
  }

  var res = 0;

  for (var i = 0; i < points.length - 2; i++) {
    var j = (i + 1) % points.length;
    var k = (i + 2) % points.length;
    var totalAngle = Angle(points[i], points[j], points[k]);


    var dis_temp1 = distance(points[j], points[0]);
    var dis_temp2 = distance(points[k], points[0]);
    res += dis_temp1 * dis_temp2 * Math.sin(totalAngle) / 2;
  }
  res = Math.abs(res)
  if (res > 1000) {
    let ha = (res / 1000)
    if (ha > 100) {
      return (ha / 100).toFixed(2) + ' km\u00B2'
    }
    return ha.toFixed(2) + ' ha'
  }

  return res.toFixed(2) + ' m\u00B2';
}

const cartesian3ToPoint3D = (position) => {
  const cartographic = Cartographic.fromCartesian(position);
  const lon = CesiumMath.toDegrees(cartographic.longitude);
  const lat = CesiumMath.toDegrees(cartographic.latitude);
  return { x: lon, y: lat, z: cartographic.height };
}

/**
 * get Center Position
 * @param {*} tempPositions array cartersian3
 * @returns cartersian3
 */
export const getCenterPosition = (tempPositions, height = 0) => {
  let points = [];
  if (tempPositions.length < 3) return tempPositions[0];
  tempPositions.forEach(position => {
    const point3d = cartesian3ToPoint3D(position);
    points.push([point3d.x, point3d.y]);
  })

  let geo = turf.lineString(points);
  let bbox = turf.bbox(geo);
  let bboxPolygon = turf.bboxPolygon(bbox);
  let pointOnFeature = turf.center(bboxPolygon);
  let lonLat = pointOnFeature.geometry.coordinates;

  return Cartesian3.fromDegrees(lonLat[0], lonLat[1], height + 0.3);
}

const getPositionHeight = (position) => {
  const cartographic = Cartographic.fromCartesian(position);
  return cartographic.height;
}

export const unifiedHeight = (positions, height) => {
  if (!height) height = getPositionHeight(positions[0]);
  let point3d;
  for (let i = 0; i < positions.length; i++) {
    const element = positions[i];
    point3d = cartesian3ToPoint3D(element);
    positions[i] = Cartesian3.fromDegrees(point3d.x, point3d.y, height)
  }

  return height;
}

/**
 * 
 * @param {*} positions array cartersian3
 * @returns distance total polyline
 */
export const computePolylinelength = (positions) => {
  let hierarchy = PolylinePipeline.generateArc({
    positions: positions,
  });
  let vector = new Cartesian3();
  let distance = 0;
  for (let i = 3; i < hierarchy.length; i += 3) {
    vector.x = hierarchy[i] - hierarchy[i - 3];
    vector.y = hierarchy[i + 1] - hierarchy[i - 2];
    vector.z = hierarchy[i + 2] - hierarchy[i - 1];
    distance += Cartesian3.magnitude(vector);
  }

  if (distance > 1000) {
    return (distance / 1000).toFixed(2) + ' km';
  }

  return distance.toFixed(2) + ' m';
}

export const isCloudPointXDEnginePlane = (model) => {
  return (model?.data?.ext === '.las' || model?.data?.ext === '.laz') && model?.data?.ifcSetting?.pointCloudEngine === 'xdEngine' && model?.data?.ifcSetting?.importer === 'speed';
}

export const isCloudPointXDEngineEllipsoid = (model) => {
  return (model?.data?.ext === '.las' || model?.data?.ext === '.laz') && model?.data?.ifcSetting?.pointCloudEngine === 'xdEngine' && model?.data?.ifcSetting?.importer === 'ellipsoid';
}

export const isCloudPointCesiumION = (model) => {
  return (model?.data?.ext === '.las' || model?.data?.ext === '.laz') && (model?.data?.ifcSetting?.pointCloudEngine === 'cesiumION' || model?.data?.ifcSetting?.pointCloudEngine === undefined)
}

export const isCloudPoint = (model) => {
  return (model?.data?.ext === '.las' || model?.data?.ext === '.laz')
}

export const isE57XDEnginePlane = (model) => {
  return model?.data?.ext === '.e57' && model?.data?.ifcSetting?.pointCloudEngine === 'xdEngine' && model?.data?.ifcSetting?.importer === 'speed'
}

export const isE57XDEngineEllipsoid = (model) => {
  return model?.data?.ext === '.e57' && model?.data?.ifcSetting?.pointCloudEngine === 'xdEngine' && model?.data?.ifcSetting?.importer === 'ellipsoid'
}

export const isXMLPlane = (model) => {
  return model?.data?.ext === '.xml' && (model?.data?.ifcSetting?.importer === 'speed' || model?.data?.ifcSetting?.importer === undefined);
}

export const isXMLEllipsoid = (model) => {
  return model?.data?.ext === '.xml' && model?.data?.ifcSetting?.importer === 'ellipsoid';
}

export const isIFCPlane = (model) => {
  return model?.data?.ext === '.ifc' && (model?.data?.ifcSetting?.importer === 'speed' || model?.data?.ifcSetting?.importer === undefined);
}

export const isIFCEllipsoid = (model) => {
  return model?.data?.ext === '.ifc' && model?.data?.ifcSetting?.importer === 'ellipsoid';
}

export const isEllipsoid = (model) => {
  return model?.data?.ifcSetting?.importer === 'ellipsoid' && ['.xml', '.ifc', '.las', '.laz', '.e57'].includes(model.data?.ext)
}

export const isIFCEllipsoidRealWorldCoordinates = (model) => {
  return model?.data?.ext === '.ifc' && model?.data?.ifcSetting?.importer === 'ellipsoid' && !model?.crs?.realWorldCoordinates;
}

/**
 * @param {*} Scale_X sketch X
 * @param {*} Scale_Y sketch Y
 * @param {*} Scale_Z sketch Z
 * @param {*} rotation sketch rotaion <360deg>
 * @param {*} localFrame Transforms eastNorthUpToFixedFrame position sketch mesh model
 * @returns model maxtrix
 */
export const getSketchMatrix = (Scale_X,Scale_Y, Scale_Z, rotation, localFrame) => {
  let xTran = new Matrix4();
  var scale_m4 = Matrix4.fromScale(new Cartesian3(Scale_X, Scale_Y, Scale_Z));
  const rotationY = Matrix3.fromRotationZ(CesiumMath.toRadians(rotation));
  const rotationMatrix4 = Matrix4.fromRotationTranslation(rotationY);
  // Matrix4.multiply(localFrame, scale_m4, xTran);
  Matrix4.multiply(localFrame, rotationMatrix4, xTran);
  Matrix4.multiply(xTran, scale_m4, xTran);
  return xTran
}

export const checkSketchMeshModel = (pickObj) => {
  /**
 * 
 * @param {*} pickObj pickedObject 
 * @returns is sketch mesh model
 */
  return (typeof pickObj?.id === 'string' && pickObj?.primitive instanceof Cesium.Model)
}

/**
 * @param {*} point sketch X
 * @returns geoPosition
 */
export const pointGeoPosition = (point) => {
  var pointGeoPosition = Cartographic.fromCartesian(point.position)
  point.cartographic = ellipsoid.cartesianToCartographic(point.position)
  point.longitude = parseFloat(MathCesium.toDegrees(point.position.x))
  point.latitude = parseFloat(MathCesium.toDegrees(point.position.y))
  point.geoPosition = pointGeoPosition
  return point
}

/**
 * @param {*} point1 point1
 * @param {*} point2 point2
 * @param {*} isString isString
 * @param {*} isMeter distance metters
 * @returns geoPosition
 */
export const getVerticalDistance = (point1, point2, isString, isMeter) => {
  var heights = [point1.pointElevation[2], point2.pointElevation[2]]
  var meters = (
    Math.max.apply(Math, heights) - Math.min.apply(Math, heights)
  )
  return isString ? (isMeter ? (meters.toFixed(3) + ' m') : meters.toFixed(1)) : meters;
}

/**
 * @param {*} cartesian cartesian position
 * @param {*} feature feature pickedObject
 * @returns cartesian,cartographic
 */
export const getPointClickAndModel = (cartesian, feature) => {
  var cartographic = Cartographic.fromCartesian(cartesian)
  return { cartesian: cartesian, cartographic: cartographic }
}

/**
 * @param {*} positions line position
 * @param {*} color line colors
 * @returns CesiumPolylineGraphics
 */
export const drawLineShape = (positions, color) => {
  const cartesians = positions.map(
    position => new Cartesian3(position.x, position.y, position.z)
  )
  return new CesiumPolylineGraphics({
    positions: cartesians,
    material: Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha)),
    width: 3,
    followSurface: true
  })
}

/**
 * @param {*} point1 P1 position
 * @param {*} point2 P2 position
 * @returns Horizontal Distance (P1 to P2)
 */
export const getHorizontalDistance = (point1, point2, iSString) => {
  var point1GeoPosition = Cartographic.fromCartesian(point1.position)
  var point2GeoPosition = Cartographic.fromCartesian(point2.position)
  var p1 = new Cartesian3.fromRadians(
    point1GeoPosition.longitude,
    point1GeoPosition.latitude,
    point1GeoPosition.height
  );
  var p2 = new Cartesian3.fromRadians(
    point2GeoPosition.longitude,
    point2GeoPosition.latitude,
    point1GeoPosition.height
  )
  var dx = p1.x - p2.x;
  var dy = p1.y - p2.y;
  var dz = p1.z - p2.z;
  var meters = Math.sqrt(dx * dx + dy * dy + dz * dz);
  return iSString ? meters.toFixed(1) : meters;
}

export const  pointGeoPositionV2 = (point) => {
  var pointGeoPosition = Cartographic.fromCartesian(point)
  point.cartographic = ellipsoid.cartesianToCartographic(point)
  point.longitude = parseFloat(CesiumMath.toDegrees(point.x))
  point.latitude = parseFloat(CesiumMath.toDegrees(point.y))
  point.geoPosition = pointGeoPosition
  return point
}


/**
 * @param {*} point1 P1 position
 * @param {*} point2 P2 position
 * @returns Vertical Distance (P1 to P2)
 */
export const getVerticalPointClick = (point1, point2) => {
  let point1Click = getPointClickAndModel(point1)
  let point2Click = getPointClickAndModel(point2)
  let _pointElevation
  if (point1Click && point2Click) {
    let _p1 = point1Click.cartesian ? [point1Click.cartesian.y, point1Click.cartesian.x, point1Click.cartographic.height] : [point1Click.cartesian.y, point1Click.cartesian.x, point1Click.cartographic.height]
    let _p2 = point2Click.cartesian ? [point2Click.cartesian.y, point2Click.cartesian.x, point2Click.cartographic.height] : [point2Click.cartesian.y, point2Click.cartesian.x, point1Click.cartographic.height]

    _pointElevation = {
      Status: 'OK',
      Points: [..._p1, ..._p2]
    }
  }
  if (_pointElevation) {
    if (_pointElevation && _pointElevation.Status === 'OK') {
      point1.pointElevation = [_pointElevation.Points[0], _pointElevation.Points[1], _pointElevation.Points[2]]
      point2.pointElevation = [_pointElevation.Points[3], _pointElevation.Points[4], _pointElevation.Points[5]]
    }
    let vertical = getVerticalDistance(pointGeoPositionV2(point1), pointGeoPositionV2(point2), true)
    return vertical
  }
} 