import { Cartesian3 } from 'cesium'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { BillboardGraphics, Entity } from 'resium'
import feedbackQuestionIcon from '../../../../assets/form-elems/Feedbackquestionwithlocation.png'

const FeedbackformVisualization = props => {
    const { feedbackStore, feedbackData, viewer, projectId, userId } = props
    const [feedbackformLocationViews, setFeedbackformLocationViews] = useState([])

    const goViewpoint = camdata => {
        if (!camdata) return
        let destination = new Cartesian3(
            camdata.position.x,
            camdata.position.y,
            camdata.position.z
        )
        let direction = new Cartesian3(
            camdata.direction.x,
            camdata.direction.y,
            camdata.direction.z
        )
        let up = new Cartesian3(
            camdata.up.x,
            camdata.up.y,
            camdata.up.z
        )

        viewer.current.cesiumElement.camera.setView({
            destination,
            orientation: {
                direction,
                up,
            },
        })
    }
    const handleClickViewFeedback = (obj) => {
        feedbackStore.setLoadingProgress(true)
        feedbackStore.clearFeedbackFormDetail()
        feedbackStore.clearShowFeedbackAnswer();
        feedbackStore.setShowFeedbackAnswer(true, false, 'add', obj)
        goViewpoint(obj.viewLocation.camData)
        // feedbackStore.getFeedbackAnserList(projectId, 'normal').then(res => {
        //     let _fbAnswer = res.data.find(f => f.feedbackform === obj.id && f.user === userId)
        //     if (_fbAnswer) { //if answered then view
        //         feedbackStore.clearFeedbackFormDetail()
        //         feedbackStore.setShowFeedbackAnswer(true, false, 'view', _fbAnswer)
        //     } else {
        //         feedbackStore.clearFeedbackFormDetail()
        //         feedbackStore.setShowFeedbackAnswer(true, false, 'add', obj)
        //     }
        // })
    }

    const addSmileyIconLocation = data => {
        let cartesians = new Cartesian3(data.viewLocation.location.x, data.viewLocation.location.y, data.viewLocation.location.z)
        const ref = React.createRef()
        let show = true;
        let x = feedbackStore.visibleFeedbackForm.find(x => x.controlName === data.id)
        if (x) {
            show = data.isPublic ? x.isShow : false
        }
        setFeedbackformLocationViews(feedbackformLocationViews => [
            ...feedbackformLocationViews,
            <Entity
                key={data.id}
                name={data.title}
                show={show}
                ref={ref}
                onClick={() => { handleClickViewFeedback(data) }}
                position={cartesians}
            >
                <BillboardGraphics
                    image={feedbackQuestionIcon}
                    disableDepthTestDistance={Number.POSITIVE_INFINITY}
                />
            </Entity>
        ])
        const rfRequestRender = () => {
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
        const requestRender = setTimeout(rfRequestRender, 200)

    }

    useEffect(() => {
        setFeedbackformLocationViews([])
        feedbackData.map(data => {
            if (data.viewLocation && data.viewLocation.location) {
                addSmileyIconLocation(data)
            }
        })
        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        return () => {
            setFeedbackformLocationViews([])
        }
    }, [feedbackData, feedbackStore.visibleFeedbackForm])

    return <>{feedbackformLocationViews.map(feedbackformLocationView => feedbackformLocationView)}</>
}

export default inject('feedbackStore')(observer(FeedbackformVisualization))
