import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
    PolygonHierarchy,
    ScreenSpaceEventType,
    Color,
    ClassificationType,
    Cartesian3,
    CallbackProperty,
    Math as MathCesium,
    BoundingSphere
} from 'cesium'
import { v4 as uuidv4 } from 'uuid';
import DynamicProperty from './DynamicProperty';
import CesiumDrawing from './CesiumDrawing';
import { message } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import * as turf from "@turf/turf";
import { toJS } from 'mobx'
import { clickPoint } from '../../../helper/CesiumUtils';
import { HiddenAreaUtils } from './HiddenAreaUtils';
import { useMediaQuery } from 'react-responsive';

const DrawHiddenArea = props => {
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
      })
    const { viewer, handler, polylineCutToolStore, projectStore,uiStore } = props
    const { t } = useTranslation();
    var customLine = new CustomLine();
    /**
     * 
     * @param {*} mode //mode type: draw-polygon
     */
    function finishDrawing(mode) {
        if (polylineCutToolStore.drawEditor.currentEditEntity) {
            // debugger
            if (polylineCutToolStore.drawEditor.currentEditEntity._originalPoints && polylineCutToolStore.drawEditor.currentEditEntity._originalPoints.length > 2 && !polylineCutToolStore.drawEditor.currentEditEntity.isCancel) {
                let polyarr = polylineCutToolStore.drawEditor.currentEditEntity._originalPoints.map(item => [item.x, item.y])
                polyarr.push(polyarr[0]) //validates if the polygon is closing properly (start point equals end point)

                var kinkedPoly = turf.polygon([polyarr]);
                var unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
                if (unkinkedPoly.features.length > 1) { //check polygon interaction if features > 1
                    if(!polylineCutToolStore.closeHideTool){
                        message.info(t('Please draw polyline that doesn\'\t intersect itself'), 5)
                    }
                    resetDrawPolygon()
                } else {
                    let points = polylineCutToolStore.drawEditor.currentEditEntity._originalPoints.map(item => [item.x, item.y, item.z])
                    polylineCutToolStore.setHideArea({ points: points, visibilitySource: toJS(projectStore.modelList).map(x => x._id) })
                    polylineCutToolStore.setShowForm(true)
                    polylineCutToolStore.drawEditor.startEditing(polylineCutToolStore.drawEditor.currentEditEntity);
                }
            } else {
                if (!polylineCutToolStore.drawEditor.currentEditEntity.isCancel && !polylineCutToolStore.closeHideTool) {
                    message.info(t('Please draw area with minimum 3 points'), 5)
                }
                resetDrawPolygon()
            }
        } else {
            if (polylineCutToolStore.drawEditor) polylineCutToolStore.drawEditor.currentEditEntity = undefined;
        }
    }

    //Function Create line mouse move
    function CustomLine() {
        this.positions = [];
        this.markers = [];
        var that = this;
    }

    //Function add point click
    CustomLine.prototype.addPoint = function (position) {
        var that = this;
        var n = this.positions.length;
        this.positions[n] = position;

        var marker = viewer.entities.add({
            position: new CallbackProperty(function () {
                return that.positions[n];
            }, false),
            point: {
                pixelSize: 5,
                color: Color.RED,
                outlineColor: Color.WHITE,
                outlineWidth: 2,
            },
            key: 'previewMarker-' + uuidv4(),
        })
        this.markers.push(marker);
    };

    //function remove point and line
    CustomLine.prototype.removeAll = function () {
        if (this.markers && this.markers.length > 0) {
            for (var i = 0; i < this.markers.length; i++) {
                if (viewer)
                    viewer.entities.remove(this.markers[i]);
            }
            this.markers = [];
            this.positions = [];
        }
    };

    CustomLine.prototype.removePreviewPoints = function() {
        var that = this;
        var entities = viewer.entities.values;
        entities.forEach(function(entity) {
          if (entity.key && (entity.key.includes('previewMarker') || entity.key.includes('dragger'))) {
            viewer.entities.remove(entity);
            uiStore.setRequestRender(true)
          }
        });
      };

    const resetDrawPolygon = () => {
      if (polylineCutToolStore.drawEditor.currentEditEntity) {
        if (polylineCutToolStore.drawEditor.currentEditEntity.onCancel)
          polylineCutToolStore.drawEditor.currentEditEntity.onCancel()
        viewer.entities.remove(
          polylineCutToolStore.drawEditor.currentEditEntity
        )
      }
      if (projectStore.projectDetail.tilesetData?.hiddenArea) {
        polylineCutToolStore.setHideArea(
          projectStore.projectDetail.tilesetData?.hiddenArea
        )
      } else {
        //clear clipping 3dtile before call
        if (
          viewer.scene &&
          !viewer.scene.isDestroyed() &&
          viewer.scene.primitives
        ) {
          for (var i = 0; i < viewer.scene.primitives.length; i++) {
            var primitive = viewer.scene.primitives.get(i)
            // remove clipping
            if (primitive.clippingPlanes) {
              primitive.clippingPlanes.removeAll()
            }
          }
        }
        //clear clipping terrain
        if (viewer.scene.globe.clippingPlanes) {
          viewer.scene.globe.clippingPlanes.enabled = !1
          viewer.scene.globe.clippingPlanes.removeAll()
        }

        polylineCutToolStore.setHideArea(false)
      }

      polylineCutToolStore.setDrawEditor(false)
      polylineCutToolStore.setDrawMode(false) // reset drawmode
      polylineCutToolStore.setShowForm(false)
      polylineCutToolStore.setCloseHideTool(false);
    }

    useEffect(() => {
        if (!polylineCutToolStore.drawMode) {
            // resetDrawPolygon()
            if(customLine){
                customLine.removeAll()
                customLine.removePreviewPoints()
            }
            if (handler) {
                handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
                handler.removeInputAction(ScreenSpaceEventType.RIGHT_CLICK)
            }
        }
      }, [polylineCutToolStore.drawMode])

    useEffect(() => {
        viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
            ScreenSpaceEventType.LEFT_DOUBLE_CLICK
        )
        let editor = new CesiumDrawing.Editor(viewer)
        let entity = null;
        let a = new PolygonHierarchy([]);

        // case exist hiddenArea then edit
        if (projectStore.projectDetail.tilesetData?.hiddenArea) {
            let _area = projectStore.projectDetail.tilesetData.hiddenArea
            const cesiumPoints = [];
            for (let i = 0; i < _area.points.length - 1; i++) {
                cesiumPoints.push(new Cartesian3(_area.points[i][0], _area.points[i][1], _area.points[i][2]))
            }

            var cen = BoundingSphere.fromPoints(cesiumPoints).center; //Center point
            if (!cen || Math.abs(cen.x) < 1000) {
                return;
            }

            a = new PolygonHierarchy(cesiumPoints);
            entity = viewer.entities.add({
                polygon: {
                    hierarchy: new DynamicProperty(a),
                    material: new Color.fromCssColorString('#ffffff').withAlpha(0),
                    outline: true,
                    outlineColor: Color.RED,
                    outlineWidth: 2,
                    perPositionHeight: true,
                    show: new CallbackProperty(function () {
                        return true
                    }, true),
                    width: 2,
                    classificationType: ClassificationType.BOTH,
                },
                drawData: 'draw-polygon',
                id: _area._id,
                //polygonData: item,
                position: new CallbackProperty(function () {
                    var polyCenter = BoundingSphere.fromPoints(
                        entity._originalPoints,
                    ).center; //Center point
                    polyCenter.z += 1;
                    return polyCenter;
                }, true),
                polyline: {
                    positions: new CallbackProperty(function () {
                        const line = [];
                        for (let i = 0; i < cesiumPoints.length; i++) {
                            const p = cesiumPoints[i];
                            line.push(p);
                        }
                        line.push(cesiumPoints[0]);
                        return line;
                    }, true),
                    material: new Color.fromCssColorString('#000000').withAlpha(0.01),
                    clampToGround: true,
                    classificationType: ClassificationType.CESIUM_3D_TILE,
                    show: new CallbackProperty(function () {
                        return true;
                    }, false),
                    width: 2,
                },
                _originalPoints: cesiumPoints
            });
            editor.currentEditEntity = entity;
            CesiumDrawing.extendEntity(entity); // Extend the Entity so that it has the events and functions for editing.
            editor.startEditing(entity)
            polylineCutToolStore.setDrawEditor(editor)
            polylineCutToolStore.setShowForm(true)
            viewer.scene.requestRender();
        } else {
            entity = viewer.entities.add({
                name: 'Entity ' + viewer.entities.values.length,
                polygon: {
                    hierarchy: new DynamicProperty(a),
                    material: new Color.fromCssColorString('#ffffff').withAlpha(0),
                    outline: true,
                    outlineColor: Color.RED,
                    outlineWidth: 2,
                    perPositionHeight: true,
                    classificationType: ClassificationType.BOTH,
                },
                polyline: {
                    positions: new CallbackProperty(function () {
                        const line = [];
                        for (let i = 0; i < a.length; i++) {
                            const p = a[i];
                            line.push(p);
                        }
                        if (a.length) line.push(a[0]);
                        return line;
                    }, false),
                    material: new Color.fromCssColorString('#000000').withAlpha(1),
                    clampToGround: true,
                    classificationType: ClassificationType.CESIUM_3D_TILE,
                    show: new CallbackProperty(function () {
                        return true;
                    }, false),
                    width: 2,
                },
                drawData: 'draw-polygon',
                id: uuidv4()
            });
            editor.currentEditEntity = entity;
            CesiumDrawing.extendEntity(entity); // Extend the Entity so that it has the events and functions for editing.            
            const { onFinish, onCancel } = editor.createPositionsHandler(
                entity,
                entity.polygon.hierarchy._value.positions,
                false,
            );
            entity.onFinish = onFinish;
            entity.onCancel = onCancel;
            entity.startEdit.addEventListener(function (params) { });
            entity.stopEdit.addEventListener(function (params) {
                finishDrawing('draw-polygon');
            });
            entity.edited.addEventListener(function (params) { });
            polylineCutToolStore.setDrawEditor(editor)
        }

        // Right click to end drawing shape
        handler.setInputAction(function (event) {
            if (customLine) {
                customLine.removeAll()
            }
            if (!polylineCutToolStore.drawMode) return

        }, ScreenSpaceEventType.RIGHT_CLICK)
        handler.setInputAction(function (event) {
            let resultClick = clickPoint(viewer, event.endPosition)
            if (resultClick && resultClick.position) {
                if (customLine) {
                    customLine.removeAll()
                }
                if (polylineCutToolStore.drawMode && !polylineCutToolStore.cancelDrawArea) {
                    customLine.addPoint(resultClick.position);
                }
            }
        }, ScreenSpaceEventType.MOUSE_MOVE);

        return () => {
            if (handler) {
                handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
            }
        }
    }, [])

    useEffect(() => {
      if(!projectStore.showHiddenDrawer && polylineCutToolStore.drawMode && isTabletOrMobileDevice){
        let hideArea = polylineCutToolStore.hideArea
        if (projectStore.clippingMode) {
            polylineCutToolStore.setHideAreaTemp(hideArea)
            HiddenAreaUtils.cancelState(viewer, polylineCutToolStore, false);
        } else if (!projectStore.clippingMode) {
            let hiddenAreaDraw = polylineCutToolStore.hideAreaTemp || hideArea
            if (viewer && hiddenAreaDraw?.points?.length > 0 && polylineCutToolStore.drawEditor) {
                HiddenAreaUtils.cancelState(viewer, polylineCutToolStore, true);
                HiddenAreaUtils.drawArea(viewer, hiddenAreaDraw, false);
            }
            //polylineCutToolStore.setHideAreaTemp(false)
        }
      }
    }, [projectStore.showHiddenDrawer, polylineCutToolStore.drawMode, projectStore.clippingMode, polylineCutToolStore.hideArea, polylineCutToolStore.showForm])
    
    return <></>
}

export default inject('projectStore', 'polylineCutToolStore','uiStore')(observer(DrawHiddenArea))
