import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { basicFields } from './ControlProperties'
import { ToolboxWrapper, ToolboxList, ToolboxFieldsTitle, ToolDecidim } from './style'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Tooltip, message, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import SVGIcon from '../../elements/SVGIcon'
import { ReactComponent as DecidimIcon } from '../../../assets/svgs/decidim-logo-topbar.svg'
import { withRouter } from 'react-router-dom';
import validator from '../../../validator';
import { isMobile, isTablet } from 'react-device-detect';

const Toolsbox = ({ onDrag, onClick, feedbackStore, projectStore, usersStore , onTouch }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);

  const showModalDecidimConnector = () => {
    setIsModalVisible(true);
    form.resetFields()
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  }

  useEffect(() => {
    if (isModalVisible && projectStore.projectDetail.decidimConnector && projectStore.projectDetail.decidimConnector.projectId === projectStore.projectDetail._id) {
      form.setFieldsValue({
        url: projectStore.projectDetail.decidimConnector.url,
        email: projectStore.projectDetail.decidimConnector.email,
        password: projectStore.projectDetail.decidimConnector.password
      })
      setDisabledSave(false);
    }
  }, [isModalVisible])

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTest = async () => {
    if (form.getFieldValue('password') === undefined) {
      form.setFields([
        {
          name: 'password',
          errors: [t('please-input-your-password')]
        },
      ]);
    }

    form.validateFields().then(async res => {
      feedbackStore.setLoadingProgress(true)
      feedbackStore.decidimConnect({
        url: res.url,
        email: res.email,
        password: res.password
      }).then(async (response) => {
        const data = {
          url: res.url,
          jwt_token: response.data.jwt_token,
          content: 'This is a test comment from 6DPlanner'
        }
        await feedbackStore.decidimAddComment(data).then(async (response) => {
          notification.info({
            message: t('connection-confirmed'),
            description: t('message-test-decidim-connector'),
            icon: <InfoCircleOutlined style={{ color: '#228B22' }} />,
            placement: 'top'
          })
        }).catch(err => {
          message.error(t('add-comment-to-decidim-faild'))
        })

        feedbackStore.setLoadingProgress(false)
      }).catch(err => {
        feedbackStore.setLoadingProgress(false)
        message.error(t('invalid-email-or-password'))
      })
    }).catch(err => {
      console.log(err)
      return;
    })
  };

  const handleActivate = async () => {
    if (form.getFieldValue('password') === undefined) {
      form.setFields([
        {
          name: 'password',
          errors: [t('please-input-your-password')]
        },
      ]);
      return
    }

    form.validateFields().then(async res => {
      feedbackStore.setLoadingProgress(true)
      feedbackStore.decidimConnect({
        url: res.url,
        email: res.email,
        password: res.password
      }).then(async (response) => {
        const data = {
          userId: usersStore.currentUser._id,
          projectId: projectStore.projectDetail._id,
          url: res.url,
          email: res.email,
          password: res.password,
          isActived: true,
          jwt_token: response.data.jwt_token
        }
        await saveProjectDecidim(data).then(res => {
          projectStore.projectDetail.decidimConnector = res.decidimConnector
          message.success(t('save-connector-successful'))
          setIsModalVisible(false)
        }).catch(error => {
          message.success(t('save-connector-failed'))
        })
        feedbackStore.setLoadingProgress(false)
      }).catch(err => {
        feedbackStore.setLoadingProgress(false)
        message.error(t('invalid-email-or-password'))
      })
    }).catch(err => {
      console.log(err)
    })
  };

  const handleDeactivate = async () => {
    form.validateFields().then(async res => {
      feedbackStore.setLoadingProgress(true)
      const data = {
        userId: usersStore.currentUser._id,
        projectId: projectStore.projectDetail._id,
        url: res.url,
        email: res.email,
        password: res.password ? res.password : projectStore.projectDetail.decidimConnector.password,
        isActived: false
      }
      await saveProjectDecidim(data).then(res => {
        projectStore.projectDetail.decidimConnector = res.decidimConnector
        message.success(t('save-connector-successful'))
        setIsModalVisible(false)
      }).catch(error => {
        message.error(t('save-connector-failed'))
      })
      feedbackStore.setLoadingProgress(false)

    }).catch(err => {
      console.log(err)
    })
  };

  const saveProjectDecidim = async (data) => {
    let decidimConnector = {};
    if (projectStore.projectDetail.decidimConnector && projectStore.projectDetail.decidimConnector.projectId === projectStore.projectDetail._id) {
      if (data.userId) decidimConnector.userId = data.userId
      if (data.projectId) decidimConnector.projectId = data.projectId
      if (data.url) decidimConnector.url = data.url
      if (data.email) decidimConnector.email = data.email
      if (data.password) decidimConnector.password = data.password
      decidimConnector.isActived = (data.isActived == true)
      if (data.jwt_token) decidimConnector.jwt_token = data.jwt_token
    } else {
      decidimConnector = {
        userId: data.userId,
        projectId: data.projectId,
        url: data.url,
        email: data.email,
        password: data.password,
        isActived: data.isActived,
        jwt_token: data.jwt_token
      }
    }

    return new Promise(async (resolve, reject) => {
      await projectStore.updateProjectData({ decidimConnector: decidimConnector, store: 'project' }).then((res) => {
        resolve(res)
      }).catch(err => {
        reject(err)
        console.log(err)
      })
    })
  };

  return (
    <>
      <ToolboxWrapper width="100%">
        <ToolboxFieldsTitle>{t('drag-and-drop-questions')}</ToolboxFieldsTitle>
        <ToolboxList>
          {
            basicFields.map(elem => {
              return (
                <li key={elem.title} draggable
                  onTouchStart={() =>  onTouch(elem)}
                  onDragStart={() => onDrag(elem)} onClick={() => onClick()}>
                  <div className="img">
                    <img src={elem.icon.component} alt="" width={elem.icon.width} height={elem.icon.height} />
                  </div>
                  {elem.title}
                </li>
              )
            })
          }
        </ToolboxList>
      </ToolboxWrapper>
      <ToolDecidim>
        <Tooltip title={t('decidim-connector')} placement="top" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <Button
            className={projectStore.projectDetail.decidimConnector && projectStore.projectDetail.decidimConnector.projectId === projectStore.projectDetail._id && projectStore.projectDetail.decidimConnector.isActived === true ? 'ant-btn-green' : ''}
            style={{ padding: '0 10px' }}
            onClick={showModalDecidimConnector}
            icon={<SVGIcon content={<DecidimIcon />} width={13} height={11} color={'#ff0000'} />} >
            <span style={{ marginLeft: '5px' }}>{t('decidim-connector')}</span>
          </Button>
        </Tooltip>
        <div className="modal-decidim" />
        <Modal
          zIndex={9999}
          title={t('decidim-connector')}
          visible={isModalVisible}
          // onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          className='modal-connector-dicidim'
          footer={
            <div style={{ textAlign: 'right' }}  >
              <Button htmlType="button" onClick={handleTest}>
                {t('test')}
              </Button>
              <Button htmlType="button" disabled={disabledSave} onClick={handleActivate}>
                {t('activate')}
              </Button>
              <Button htmlType="button" disabled={disabledSave} onClick={handleDeactivate}>
                {t('deactivate')}
              </Button>
            </div>
          }
          getContainer={() => {
            return document.getElementsByClassName("modal-decidim")[0];
          }}
        >
          <Col span={24}>
            <Form onFieldsChange={handleFormChange} form={form} layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} >
              <Form.Item
                label={
                  <div>
                    <span style={{ marginRight: '5px' }}>{t('URL')}</span>
                    <Tooltip 
                      overlayStyle={(isMobile || isTablet) ? { display: 'none' } :{ whiteSpace: 'pre-line' }} 
                      title="Full url to article ex: https://xxxxx.xx/processes/xxx/f/4/posts/2">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                name="url"
                rules={[
                  {
                    validator(_, value) {
                      if (!value) return Promise.reject(new Error(t('please-input-your-url')));
                      var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                      if (res == null) {
                        return Promise.reject(new Error(t('Url is not valid')));
                      } else {
                        let domain = (new URL(value));
                        const pathsplit = domain.pathname.split("/")
                        const _type = pathsplit[pathsplit.length - 2]
                        const _id = pathsplit[pathsplit.length - 1]
                        const includearr = ['proposals', 'projects', 'results', 'debates', 'posts']
                        if (_id === '' || isNaN(_id) || !includearr.includes(_type)) {
                          return Promise.reject(new Error(t('Url is not valid')));
                        }
                        return Promise.resolve();
                      }
                    },
                  }
                ]}
              >
                <Input type='text' placeholder={t('your-url')} />
              </Form.Item>
              <Form.Item
                label={t('email')}
                name="email"
                rules={[
                  {
                    validator(_, value) {
                      if (!value) return Promise.reject(new Error(t('please-input-your-email')));
                      const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm
                      if (value && !emailRegex.test(value)) {
                        return Promise.reject(new Error(t('not-a-valid-email')));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }
                ]}
              >
                <Input type='email' autoComplete="off" placeholder={t('your-email')} />
              </Form.Item>
              <Form.Item
                label={t('password')}
                name="password"
                autoComplete="off"
              >
                <Input onChange={(v) => {
                  if (v !== '' && v !== undefined) form.setFields([{ name: 'password', errors: [] }]);
                }}
                  type='password'
                  autoComplete="new-password"
                  placeholder={t('your-password')} />
              </Form.Item>
            </Form>
          </Col>
        </Modal>
      </ToolDecidim>
    </>
  )
}

export default withRouter(inject('feedbackStore', 'projectStore', 'usersStore')(observer(Toolsbox)))