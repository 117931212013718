
import { CloseCircleOutlined, LeftOutlined, MinusOutlined, PlusOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Popconfirm, Row, Select, Skeleton, Spin, Tooltip } from 'antd';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import uuid from 'uuid';
import TreeUtils from '../../../../tree-utils';
import Utils from '../../../../utils';
import { ActionTool, ColumnTools, FirstTableTR, ModalAddAttribute, RuleContainer, RuleWrapper, SheetEditorContainer, SheetReport, SheetReportTool, SheetReportToolWrapper, TdCustom } from './style';
const { Option } = Select
var cancelToken
function SheetReportEditor({ projectStore, reportStore, sheetIndex, sheet, setSheetReports, sheetReports, folderList, treeData, loadingPercent }) {
  const { t } = useTranslation()
  const [formAddColumn] = Form.useForm()
  const [formAddRowRule] = Form.useForm()
  const [addColumnModal, setAddColumnModal] = useState(false)
  const [addRowRuleModal, setAddRowRuleModal] = useState(false)
  const [operator, setOperator] = useState(false)
  const [rowruletype, setRowRuleType] = useState(false)
  const [isLoading, setLoadingProgress] = useState(false)
  const { projectId } = useParams();
  const filteredOptions = projectStore.prjCustomAttributeList ? projectStore.prjCustomAttributeList.filter(o => !o.isDeleted) : [];
  Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
  };

  Array.prototype.deleteIndex = function (index) {
    this.splice(index, 1);
  };

  const DebounceObjectInfo = ({ }) => {
    const { t } = useTranslation();
    const [listProjectOption, setListProjectOption] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    let lastFetchId = 0;

    const handleSearchObjectInfo = value => {
      setListProjectOption([])
      if (value && value !== "") {
        lastFetchId += 1;
        const fetchId = lastFetchId;
        setListProjectOption([])
        setFetching(true)
        projectStore.searchObjectInfor(projectId, value)
          .then(res => {
            if (fetchId !== lastFetchId) {
              return;
            }
            setListProjectOption(res)
            setFetching(false)
          });
      }
    };

    const handleClick = () => {
      setDropdown(!dropdown);
    }

    return (
      <Form.Item
        label={t('attribute')}
        name="attribute"
        rules={[
          {
            required: true,
            message: t('cannot-be-emptied', { text: 'Attribute' }),
          },
        ]}>
        <Select
          allowClear
          labelInValue={true}
          showSearch
          optionFilterProp="children"
          placeholder={t('select-an-attribute')}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={debounce(handleSearchObjectInfo, 500)}
          style={{ width: '100%' }}
          open={dropdown}
          onFocus={() => setDropdown(true)}
          onBlur={() => setDropdown(false)}
          onDropdownVisibleChange={handleClick}
        >
          {listProjectOption &&
            listProjectOption.map(d => (
              <Option label={Utils.getObjectInfoV2Label(d.inf)} key={d._id} value={Utils.getObjectInfoV2Label(d.inf)}>
                <Tooltip
                  title={Utils.getObjectInfoV2Label(d.inf)}
                  placement="left"
                  overlayStyle={
                    isMobile || isTablet ? { display: 'none' } : undefined
                  }>
                  <div style={{ width: '100%' }}>
                    <div>{Utils.getObjectInfoV2Label(d.inf)}</div>
                  </div>
                </Tooltip>
              </Option>
            ))}
        </Select>
      </Form.Item>
    )
  }

  const handleDeleteSheet = () => {
    const temp = [...sheetReports]
    temp.splice(sheetIndex, 1)
    setSheetReports(temp)
    message.success(t("delete-successfully"))
    saveLastSetting(temp)
  }

  const openModalAddRowRule = () => {
    setAddRowRuleModal(true)
  }

  const onCancelAddNewColumn = () => {
    formAddColumn.resetFields()
    setAddColumnModal(false)
    setOperator(false)
    setRowRuleType(false)
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
  }

  const onCancelAddRowRule = () => {
    formAddRowRule.resetFields()
    setAddRowRuleModal(false)
    setRowRuleType(false)
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
  }

  const findOperator = (type) => {
    let options = [
      { type: 'summary', name: 'Summary' },
      { type: 'quantity', name: 'Quantity' }
    ]
    let operator = options.find(c => c.type === type)
    return operator
  }

  const findProjectCustomAttribute = (id) => {
    const temp = [...projectStore.prjCustomAttributeList]
    let _attribute = temp.find(c => c._id === id)
    return toJS(_attribute)
  }

  /**
* Add new sheet column
* @param {*} rules list row rules sheet report
* @param {*} columns list columns sheet report
*/
  const handleAddNewColumn = async () => {
    const { rules } = sheet;
    formAddColumn.validateFields().then(async response => {
      if (!rules || rules.length <= 0) {
        message.warning(t("please-insert-row-rule-to-create-your-report"))
        return
      }
      const newColumn = {
        name: response.name,
        operator: findOperator(response.operator),
        key: uuid(),
      }
      if (response.attribute) {

        let attribute
        if (sheet?.level === 'file') {
          attribute = findProjectCustomAttribute(response.attribute)
        }
        if (sheet?.level === 'object') {
          attribute = {
            type: "attribute",
            name: response.attribute?.label?.props?.title,
            id: response.attribute?.value
          }
        }

        newColumn.attribute = {
          id: attribute?.id,
          name: attribute?.name
        }
      }
      const temp = [...sheetReports]
      const newColumns = [...temp[sheetIndex].columns] || []
      newColumns.push(newColumn)
      const queryParams = {
        rowrules: rules,
        columnrules: newColumns,
        level: sheet?.level
      }
      if (sheet?.level === 'file') {
        setLoadingProgress(true)
        await reportStore.calculateReportingTool(projectStore.projectDetail?.id, queryParams).then(res => {
          const temp = [...sheetReports]
          temp[sheetIndex].columns = newColumns
          temp[sheetIndex].sheetdata = res
          setSheetReports(temp)
          message.success(t("add-rule-successfully"))
          saveLastSetting()
          onCancelAddNewColumn()
          setLoadingProgress(false)
        })
          .catch(err => {
            if (err?.status === 400) {
              message.error(err?.data?.message || t('an-error-occurred'))
              setLoadingProgress(false)
              return
            }
            message.error(t('an-error-occurred'))
            setLoadingProgress(false)
          })
      }
      if (sheet?.level === 'object') {
        setLoadingProgress(true)
        cancelToken = axios.CancelToken.source()
        await reportStore.calculateObjectInfo(projectStore.projectDetail?.id, queryParams, cancelToken.token).then(res => {
          const temp = [...sheetReports]
          temp[sheetIndex].columns = newColumns
          temp[sheetIndex].sheetdata = res
          setSheetReports(temp)
          message.success(t("add-rule-successfully"))
          saveLastSetting()
          onCancelAddNewColumn()
          setLoadingProgress(false)
        })
          .catch(err => {
            if (err?.status === 400) {
              message.error(err?.data?.message || t('an-error-occurred'))
              setLoadingProgress(false)
              return
            }
            if (err?.status) {
              message.error(t('an-error-occurred'))
            }
            setLoadingProgress(false)
          })
      }
    })
  }

  const rootFolder = {
    key: "mainfolder",
    title: "All",
    isDeleted: false,
    parentKey: undefined,
    type: "FOLDER",
    children: []
  }

  const addFileRowRule = async (queryParams, newRules, isDeleteAttribute) => {
    isDeleteAttribute ? reportStore.setLoadingProgress(true) : setLoadingProgress(true)
    await reportStore
      .calculateReportingTool(projectStore.projectDetail?.id, queryParams)
      .then(res => {
        const temp = [...sheetReports]
        temp[sheetIndex].rules = newRules
        temp[sheetIndex].sheetdata = res
        setSheetReports(temp)
        message.success(isDeleteAttribute ? t("remove-column-successfully") : t('add-rule-successfully'))
        saveLastSetting()
        onCancelAddRowRule()
        isDeleteAttribute ? reportStore.setLoadingProgress(false) : setLoadingProgress(false)
      })
      .catch(err => {
        if (err?.status === 400) {
          message.error(err?.data?.message || t('an-error-occurred'))
          isDeleteAttribute ? reportStore.setLoadingProgress(false) : setLoadingProgress(false)
          return
        }
        message.error(t('an-error-occurred'))
        isDeleteAttribute ? reportStore.setLoadingProgress(false) : setLoadingProgress(false)
      })
  }

  const addObjectRowRule = async (queryParams, newRules, isDeleteAttribute) => {
    isDeleteAttribute ? reportStore.setLoadingProgress(true) : setLoadingProgress(true)
    cancelToken = axios.CancelToken.source()
    await reportStore
      .calculateObjectInfo(
        projectStore.projectDetail?.id,
        queryParams,
        cancelToken.token
      )
      .then(res => {
        const temp = [...sheetReports]
        temp[sheetIndex].rules = newRules
        temp[sheetIndex].sheetdata = res
        setSheetReports(temp)
        message.success(isDeleteAttribute ? t("remove-column-successfully") : t('add-rule-successfully'))
        saveLastSetting()
        onCancelAddRowRule()
        isDeleteAttribute ? reportStore.setLoadingProgress(false) : setLoadingProgress(false)
      })
      .catch(err => {
        if (err?.status === 400) {
          message.error(err?.data?.message || t('an-error-occurred'))
          isDeleteAttribute ? reportStore.setLoadingProgress(false) : setLoadingProgress(false)
          return
        }
        if (err?.status) {
          message.error(t('an-error-occurred'))
        }
        isDeleteAttribute ? reportStore.setLoadingProgress(false) : setLoadingProgress(false)
      })
  }

  const handleAddRowRule = () => {
    const { columns } = sheet;
    formAddRowRule.validateFields().then(async response => {
      const temp = [...sheetReports]
      const newRules = [...temp[sheetIndex].rules] || []
      const ruleIndex = findIndexRule(response.ruleType)
      if (response.attribute) {
        let attribute
        if (sheet?.level === 'file') {
          attribute = findProjectCustomAttribute(response.attribute)
        }
        if (sheet?.level === 'object') {
          attribute = {
            type: "attribute",
            name: response.attribute?.label?.props?.title,
            _id: response.attribute?.value
          }
        }

        let newRule = {
          type: "attribute",
          name: attribute?.name,
          attributeId: attribute._id
        }
        if (attribute && ruleIndex > -1) {
          newRules[ruleIndex] = newRule
        } else {
          newRules.push(newRule)
        }
      }
      if (response.folder) {
        let folder = response.folder === 'mainfolder' ? rootFolder : toJS(TreeUtils.searchTreeNode(treeData, 'key', response.folder))
        let newRule = {
          type: "folder",
          name: folder?.title,
          folderKey: folder?.key
        }
        if (folder && ruleIndex > -1) {
          newRules[ruleIndex] = newRule
        } else {
          newRules.push(newRule)
        }
      }
      const queryParams = {
        rowrules: newRules,
        columnrules: columns,
        level: sheet?.level
      }

      if (!columns || columns.length <= 0) {
        if (response.folder) {
          if (sheet?.level === 'file') {
            addFileRowRule(queryParams, newRules)
          }
          if (sheet?.level === 'object') {
            addObjectRowRule(queryParams, newRules)
          }
        } else if (response.attribute) {
          if (sheet?.level === 'file') {
            addFileRowRule(queryParams, newRules)
          }
          if (sheet?.level === 'object') {
            addObjectRowRule(queryParams, newRules)
          }
        } else {
          const temp = [...sheetReports]
          temp[sheetIndex].rules = newRules
          setSheetReports(temp)
          message.success(t("add-rule-successfully"))
          saveLastSetting()
        }
      } else {
        if (sheet?.level === 'file') {
          addFileRowRule(queryParams, newRules)
        }
        if (sheet?.level === 'object') {
          addObjectRowRule(queryParams, newRules)
        }
      }
    })
  }

  const addNewColumn = () => {
    setAddColumnModal(true)
  }

  const handleRemoveRowRule = (ruleIndex) => {
    const temp = [...sheetReports]
    let rules = temp[sheetIndex].rules
    let removeRule = temp[sheetIndex].rules[ruleIndex]
    // if(removeRule && rules && rules.length ===2 &&removeRule.type ==="attribute" ){
    if (removeRule && rules && rules.length === 2) {
      // let newRules = temp[sheetIndex].rules.filter(c => c.type !=='attribute')
      let newRules = temp[sheetIndex].rules.filter(c => c.type !== removeRule.type)
      const queryParams = {
        rowrules: newRules,
        columnrules: sheet?.columns || [],
        level: sheet?.level
      }
      temp[sheetIndex].rules.splice(ruleIndex, 1)
      if (sheet?.level === 'file') {
        addFileRowRule(queryParams, newRules, true)
      }
      if (sheet?.level === 'object') {
        addObjectRowRule(queryParams, newRules, true)
      }
    } else {
      temp[sheetIndex].rules.splice(ruleIndex, 1)
      setSheetReports(temp)
      message.success(t("remove-rule-successfully"))
      saveLastSetting()
    }
  }

  const handleRemoveColumn = (columnIndex) => {
    const temp = [...sheetReports]
    temp[sheetIndex].columns.splice(columnIndex, 1)
    let _sheetdata = [...temp[sheetIndex].sheetdata]
    _sheetdata.map(c => {
      if (c.cells) {
        c.cells.deleteIndex(columnIndex + 1)
      }
    })
    temp[sheetIndex].sheetdata = _sheetdata
    setSheetReports(temp)
    message.success(t("remove-column-successfully"))
    saveLastSetting()
  }


  const handleSortColumnToLeft = (columnIndex) => {
    const temp = [...sheetReports]
    temp[sheetIndex].columns.move(columnIndex, columnIndex - 1)
    let _sheetdata = [...temp[sheetIndex].sheetdata]
    _sheetdata.map(c => { c.cells.move(columnIndex + 1, columnIndex) })
    temp[sheetIndex].sheetdata = _sheetdata
    setSheetReports(temp)
    saveLastSetting()
  }

  const handleSortColumnToRight = (columnIndex) => {
    const temp = [...sheetReports]
    temp[sheetIndex].columns.move(columnIndex, columnIndex + 1)
    let _sheetdata = [...temp[sheetIndex].sheetdata]
    _sheetdata.map(c => { c.cells.move(columnIndex + 1, columnIndex + 2) })
    temp[sheetIndex].sheetdata = _sheetdata
    setSheetReports(temp)
    saveLastSetting()
  }


  const RenderRule = ({ icon, title, onClick, background = '#38a8d7', color = 'white', width = 18, height = 18 }) => {
    return (
      <RuleContainer className={sheet?.isFetching ? 'readOnly' : ''} title={title} background={background} color={color} width={width} height={height}>
        {
          icon && <span onClick={onClick} className='rule-icon'>{icon}</span>
        }
        <span className='rule-title'>{title}</span>
      </RuleContainer>
    )
  }

  const RenderFirstTR = ({ data = [], sheet }) => {
    return (
      <FirstTableTR>
        <td className='tr-sheet-name'>{sheet?.name}</td>
        {
          data.map(column => {
            return (
              <td key={uuid()} className='tr-column-name'>{column?.name}</td>
            )
          })
        }
      </FirstTableTR>
    )
  }

  const checkingTableLevel = (level) => {
    return sheet?.level === level ? true : false
  }

  const findIndexRule = (type) => {
    const isExist = sheet?.rules.findIndex(c => c.type === type)
    return isExist
  }

  const validAddColumnRule = () => {
    return sheet?.rules && sheet?.rules?.length > 0
  }

  const validAddRowRule = () => {
    return sheet?.rules && sheet?.rules?.length < 2
  }

  const validReLoadTable = () => {
    // return sheet?.rules && sheet?.rules?.length === 2 && sheet?.columns?.length > 0 && findIndexRule('folder') > -1 && findIndexRule("attribute") > -1
    return sheet?.rules && findIndexRule('folder') > -1
  }

  const splitTextObjectInfo = (text) => {
    const value = Utils.splitAtFirstSpecialCharacter(text, '=');
    return value ? value[0] : ''
  }

  const saveLastSetting = (data = sheetReports) => {
    if (projectStore.isShowGenericReport && data) {
      const reportSettings = data.map(element => {
        return {
          columns: element.columns,
          key: element.key,
          level: element.level,
          name: element.name,
          rules: element.rules,
          isFetching: false,
          sheetdata: []
        }
      })
      reportStore
        .updateProjectData(projectId, { reportSetting: reportSettings })
    }
  }

  const RenderTheadSheet = ({ data, visible }) => {

    const renderRuleTitle = (rule) => {
      const title = rule?.type === 'folder' ? `Folder : ${rule.name}`
        : rule?.type === 'attribute' ? `Attribute : ${rule.name}`
          : ''
      return title
    }

    return (
      <thead>
        {visible && (
          <tr>
            <th>
              <div>
                <SheetReportTool>
                  <RuleWrapper>
                    {sheet?.rules &&
                      sheet?.rules.map((rule, ruleIndex) => {
                        return (
                          <RenderRule
                            onClick={() => handleRemoveRowRule(ruleIndex)}
                            key={uuid()}
                            icon={<MinusOutlined />}
                            title={`${ruleIndex + 1}. ${renderRuleTitle(rule)}`}
                          />
                        )
                      })}
                    {validAddRowRule() && (
                      <RenderRule
                        onClick={openModalAddRowRule}
                        icon={<PlusOutlined />}
                        title={t("add-row-rules")}
                      />
                    )}
                  </RuleWrapper>
                </SheetReportTool>
              </div>
            </th>
            {data &&
              data?.columns.map((column, columnIndex) => {
                return (
                  <th className="tr-flex" key={uuid()}>
                    <span>
                      {
                        column?.operator ? (
                          <div>[Operator : {column?.operator?.name}]</div>
                        ) : (
                          <div> <br /> </div>
                        )
                      }
                      {
                        column?.operator?.type === 'summary' ? (
                          <div>[Attribute : {column?.attribute?.name}]</div>
                        ) : (
                          <div><br /> </div>
                        )
                      }
                      <ColumnTools>
                        <RenderRule
                          onClick={() => handleSortColumnToLeft(columnIndex)}
                          icon={columnIndex === 0 ? false : <LeftOutlined />}
                        />
                        <Popconfirm
                          title={t("are-you-sure-delete-this-column")}
                          placement="rightTop"
                          onConfirm={() => handleRemoveColumn(columnIndex)}
                          okText={t('commons.ok')}
                          cancelText={t('commons.cancel')}
                        >
                          <RenderRule
                            icon={<MinusOutlined />}
                          />
                        </Popconfirm>
                        <RenderRule
                          onClick={() => handleSortColumnToRight(columnIndex)}
                          icon={
                            columnIndex === data.columns?.length - 1 ||
                              data.columns?.length === 1 ? (
                              false
                            ) : (
                              <RightOutlined />
                            )
                          }
                        />
                      </ColumnTools>
                    </span>
                  </th>
                )
              })}
          </tr>
        )}
      </thead>
    )
  }

  const percents = loadingPercent.filter((percent) => percent.index === sheetIndex);
  const currentPercent = percents[percents.length - 1] || { loading: 0, index: sheetIndex }

  return (
    <SheetReport>
      {!reportStore.showReportRules && (
        <ActionTool>
          <Popconfirm
            className={sheet?.isFetching ? 'readOnly' : ''}
            title={t("are-you-sure-delete-this-report")}
            placement="rightTop"
            okText={t('commons.ok')}
            cancelText={t('commons.cancel')}
            onConfirm={handleDeleteSheet}>
            <Button className={sheet?.isFetching ? 'readOnly' : ''} type="primary">{t('commons.delete')}</Button>
          </Popconfirm>
        </ActionTool>
      )}
      <SheetReportToolWrapper>
        <SheetEditorContainer>
          <table id={sheet?.key}>
            <RenderTheadSheet
              visible={!reportStore.showReportRules}
              data={sheet}
            />
            <tbody>
              <RenderFirstTR sheet={sheet} data={sheet?.columns} />
              {sheet?.sheetdata?.length > 0 &&
                !sheet?.isFetching &&
                sheet?.sheetdata.map((row, rowIndex) => {
                  return (
                    <tr key={`row-${rowIndex}`}>
                      {row?.cells?.length > 0 &&
                        row?.cells.map((cl, clIndex) => {
                          return (
                            <TdCustom
                              colspan={
                                cl?.colspan ? cl?.colspan.toString() : '1'
                              }
                              indent={cl?.indent || cl.indent === 0 ? cl?.indent : clIndex > 0 ? 0 : 1}
                              type={
                                cl?.type === 'folder' ? 'bolder' : 'normal'
                              }
                              key={`row-${rowIndex}-cell-${clIndex}`}>
                              {/* {(cl.value === 0 || cl.value) ? (cl.value + (cl.unit ? cl.unit :''))  : ''} */}
                              <Tooltip
                                title={
                                  cl.value === 0 || cl.value ? cl.value : ''
                                }>
                                <div className="text">
                                  {cl.value === 0 || cl.value ? cl.value : ''}
                                </div>
                              </Tooltip>
                            </TdCustom>
                          )
                        })}
                    </tr>
                  )
                })}
              {sheet?.isFetching && validReLoadTable() && (
                <>
                  <tr>
                    <td
                      colSpan={sheet?.columns ? sheet?.columns?.length + 1 : 1}>
                      <Skeleton active />
                    </td>
                  </tr>
                  {/* <tr>
                    <td colSpan={sheet?.columns ? sheet?.columns?.length + 1 : 1}>
                      <Progress
                        status='active'
                        type='line'
                        percent={currentPercent.loading}
                        strokeColor={{
                          from: '#108ee9',
                          to: '#87d070'
                        }}
                      />
                    </td>
                  </tr> */}
                </>
              )}
            </tbody>
          </table>
        </SheetEditorContainer>
        {!reportStore.showReportRules && validAddColumnRule() && (
          <div className="sheet-add-column">
            <RenderRule
              onClick={addNewColumn}
              icon={<PlusOutlined />}
              title={t('add-new-column')}
            />
          </div>
        )}
      </SheetReportToolWrapper>

      {/*Modal add new column */}
      <ModalAddAttribute
        className="Model"
        title={t('add-new-column')}
        visible={addColumnModal}
        zIndex={9999}
        onCancel={onCancelAddNewColumn}
        maskClosable={false}
        footer={
          <Row justify="end">
            <Button
              key="back"
              icon={<CloseCircleOutlined />}
              onClick={onCancelAddNewColumn}>
              {t('commons.cancel')}
            </Button>
            <Button
              loading={isLoading}
              form="add-column"
              type="primary"
              key="save"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => handleAddNewColumn()}>
              {t('commons.save')}
            </Button>
          </Row>
        }>
        <Form
          form={formAddColumn}
          name="add-new-report-form"
          layout="vertical">
          <Form.Item
            label={t('name')}
            name="name"
            initialValue={''}
            rules={[
              {
                required: true,
                message: t('column-name-cannot-be-emptied'),
              },
            ]}>
            <Input placeholder={t('please-input-column-name')} />
          </Form.Item>
          <Form.Item
            label={t('operator')}
            name="operator"
            initialValue={''}
            rules={[
              {
                required: true,
                message: t('cannot-be-emptied', { text: 'Operator' }),
              },
            ]}>
            <Select
              showSearch
              placeholder={t("select-an-operator")}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'summary',
                  label: 'Summary',
                },
                {
                  value: 'quantity',
                  label: 'Quantity',
                },
              ]}
              onChange={e => setOperator(e)}
            />
          </Form.Item>
          {operator && operator === 'summary' && (
            <>
              {checkingTableLevel('file') && (
                <Form.Item
                  label={t('attribute')}
                  name="attribute"
                  rules={[
                    {
                      required: true,
                      message: t('cannot-be-emptied', { text: 'Attribute' }),
                    },
                  ]}>
                  <Select
                    showSearch
                    placeholder={t("select-an-attribute")}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={filteredOptions.map(item => ({
                      value: item._id,
                      label: item.name,
                    }))}
                  />
                </Form.Item>
              )}
              {checkingTableLevel('object') && <DebounceObjectInfo />}
            </>
          )}
        </Form>
      </ModalAddAttribute>

      {/*Modal add new row */}
      <ModalAddAttribute
        className="Model"
        title={t('add-row-rule')}
        visible={addRowRuleModal}
        zIndex={9999}
        onCancel={onCancelAddRowRule}
        maskClosable={false}
        footer={
          <Row justify="end">
            <Button
              key="back"
              icon={<CloseCircleOutlined />}
              onClick={onCancelAddRowRule}>
              {t('commons.cancel')}
            </Button>
            <Button
              loading={isLoading}
              form="add-column"
              type="primary"
              key="save"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={handleAddRowRule}>
              {t('commons.save')}
            </Button>
          </Row>
        }>
        <Form
          form={formAddRowRule}
          name="add-new-report-form"
          layout="vertical">
          <Form.Item
            label={t('rule-type')}
            name="ruleType"
            rules={[
              {
                required: true,
                message: t('cannot-be-emptied', { text: 'Type' }),
              },
            ]}>
            <Select
              showSearch
              placeholder={t('select-rule-type')}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'folder',
                  label: 'Folder',
                },
                {
                  value: 'attribute',
                  label: 'Attribute',
                },
              ]}
              onChange={e => setRowRuleType(e)}
            />
          </Form.Item>
          {rowruletype === 'folder' && (
            <Form.Item
              label={t('folder')}
              name="folder"
              rules={[
                {
                  required: true,
                  message: t('cannot-be-emptied', { text: 'Operator' }),
                },
              ]}>
              <Select
                showSearch
                placeholder={t("select-an-operator")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={folderList.map(item => ({
                  value: item.key,
                  label: item.title,
                }))}
              />
            </Form.Item>
          )}
          {rowruletype === 'attribute' && (
            <>
              {checkingTableLevel('file') && (
                <Form.Item
                  label={t('attribute')}
                  name="attribute"
                  rules={[
                    {
                      required: true,
                      message: t('cannot-be-emptied', { text: 'Attribute' }),
                    },
                  ]}>
                  <Select
                    showSearch
                    placeholder={t("select-an-attribute")}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={filteredOptions.map(item => ({
                      value: item._id,
                      label: item.name,
                    }))}
                  />
                </Form.Item>
              )}
              {checkingTableLevel('object') && <DebounceObjectInfo />}
            </>
          )}
        </Form>
      </ModalAddAttribute>
    </SheetReport>
  )
}

export default inject(
  'projectStore',
  'usersStore',
  'commonStore',
  'reportStore',
  'projectTeamsStore'
)(observer(SheetReportEditor))