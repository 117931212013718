
import { message, Tabs, Upload, Steps, notification, Spin, Form, Input, Button, Select, InputNumber, Checkbox, Progress, DatePicker, Row, Col, Slider, Tooltip, Popconfirm, Divider, Switch, Collapse } from 'antd'
import { BuildOutlined, FileZipOutlined, InboxOutlined, LoadingOutlined, InfoCircleOutlined, CodeSandboxOutlined, GlobalOutlined, SketchOutlined, SaveOutlined, ReloadOutlined, CloseOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { UploadWrapper, StepContainer, UpdateWmsPanel, LocationPanelHeader } from '../CustomStyled'
import { getOriginPublicJsonLink, getMimeOfFile, updateProjectModel, mergeAllModels, create_ifcengineusage, updateProjectTreeData, updateContenModelIon, convertExtToLowerCase } from '../../../../lib/projectLib'
import { apiUrl, apiNcWmsUrl } from '../../../../config'
import TreeUtils from '../../../../tree-utils'
import Utils from '../../../../utils'
import uuid from 'uuid'
import axios from 'axios'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import "moment/locale/es";
import en from "antd/lib/date-picker/locale/en_US";
import fi from "antd/lib/date-picker/locale/fi_FI";
import sv from "antd/lib/date-picker/locale/sv_SE";
import es from "antd/lib/date-picker/locale/es_ES";
import vi from "antd/lib/date-picker/locale/vi_VN";
import { getCenterScreenCoordinates, validDegreeBox } from '../../../helper/CesiumUtils'
import { isMobile, isTablet } from 'react-device-detect'
import { Cartesian3 } from 'cesium'
import { Trans, useTranslation } from 'react-i18next';
import { useIsMounted } from '../../../helper/useIsMounted';
import GML3 from 'ol/format/GML3.js';
import GML2 from 'ol/format/GML2.js';
import GML32 from 'ol/format/GML32.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import WMSCapabilities from 'ol/format/WMSCapabilities.js';
import { toJS } from 'mobx'

const dateFormat = 'YYYY-MM-DD';
const { Dragger } = Upload
const { TabPane } = Tabs
const { Step } = Steps
const { Option } = Select
const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />
const isUrl = urlString => {
  return (
    urlString &&
    (urlString.startsWith('https://') || urlString.startsWith('http://'))
  )
}
const ASSET_URL = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/`

//WMS component
const WMSForm = ({ projectStore, editTreeNode, onCancel, startDate, endDate, setEndDate, setStartDate, startString, endString, setEndString, setStartString }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [layerData, setLayerData] = useState([])
  const [xmlData, setXmlData] = useState()
  const [loadingXml, setLoadingXml] = useState(false)
  const [epsg, setEpsg] = useState()
  const [isNcWms, setIsNcWms] = useState(false)
  const [isUseCredential, setUseCredentialization] = useState(false)
  const [palettes, setPalettes] = useState([])
  const [metaDatas, setMetaDatas] = useState([])
  const [availableDates, setAvailableDates] = useState([])
  const [selectedDate, setSelectedDate] = useState()
  const [timeSteps, setTimeSteps] = useState([])
  const [showPlaneForm, setShowPlaneForm] = useState(false)
  const [resetState, setResetState] = useState({ loading: false, isDisabled: false });
  const [firstLayerValue, setFirstLayerValue] = useState()
  const [secondLayerValue, setSecondLayerValue] = useState()
  const [terrainValue, setTerrainValue] = useState('TERRAIN')
  const [planeValue, setPlaneValue] = useState('PLANE')
  const [subLayerData, setSubLayerData] = useState()
  const [authType, setAuthType] = useState() //authtype server, layer
  const [version, setVersion] = useState('')
  const [loading, setLoading] = useState(false)
  const isMounted = useIsMounted()

  const onChangeClassificationType = (value, key) => {
    if (value === 'CESIUM_3D_TILE' || value === 'BOTH' || key.key === 'TERRAIN') {
      setShowPlaneForm(false)
    }
    if (key.key === 'PLANE') {
      setShowPlaneForm(true)
    }
    if (value !== "TERRAIN") {
      form.setFieldsValue({ height: 0 })
    }
  }

  useEffect(() => {
    if (terrainValue === "TERRAIN") {
      form.setFieldsValue({ height: 0 })
    }
  }, [terrainValue])

  const handleTerrain = () => {
    setShowPlaneForm(false)
    setPlaneValue('2')
    setTerrainValue('TERRAIN')
  }

  const handlePlane = () => {
    setShowPlaneForm(true)
    setPlaneValue('TERRAIN')
    setTerrainValue('1')
  }

  const initData = async () => {
    const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
    if (model) {
      if (model?.data?.presentation === 'PLANE' && model?.data?.classificationType === 'TERRAIN') {
        setPlaneValue('TERRAIN')
        setTerrainValue('1')
        setShowPlaneForm(true)
      }
      if (model.startDate) {
        const localstartDate = moment.utc(model.startDate).local().format()
        setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
      }

      if (model.endDate) {
        const localendDate = moment.utc(model.endDate).local().format()
        setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
      }
      setStartString(model.startDate)
      setEndString(model.endDate)
      setIsNcWms(model.data.isNcWms);
      setUseCredentialization(model.data.isUseCredential !== undefined ? model.data.isUseCredential : false)
      setAuthType(model.data.authType !== undefined ? model.data.authType : '')
      setVersion(model.data.version ? model.data.version : '')
      form.setFieldsValue({
        name: model.name,
        src: model.src,
        classificationType: model.data.classificationType,
        useWMSProvider: model.data.useWMSProvider,
        useProxy: model.data.useProxy !== undefined ? model.data.useProxy : false,
        height: model.data.height,
        alpha: isNaN(model.data.alpha) ? 1 : model.data.alpha,
        username: model.data.username ? model.data.username : '',
        password: model.data.password ? model.data.password : '',
      });


      if (projectStore.fileEdit) {
        form.setFieldsValue({ name: projectStore.fileEdit.title });
      }
      await fetchLayersWMS(model.src, model.data.useProxy !== undefined ? model.data.useProxy : false, model)
      if (model.data.isNcWms) {
        await getMetadata(model.src, model.data.layers);
      }
      await checkAuthLayer({ src: model.src, layer: model.data.layers, bbox: model.data.bbox, username: model.data.username, password: model.data.password, version: model.data.version })

      if (model.data.time && model.data.time.length > 23) {
        const timeStep = model.data.time.slice(11, 24)
        const date = moment(model.data.time.slice(0, 10))
        if (date.isValid()) {
          form.setFieldsValue({ timeStep: timeStep })
          form.setFieldsValue({ date: date })
          setSelectedDate(moment(model.data.time))
        }

      }
      setEpsg(`EPSG:${model.data.epsg}`);
    }
  }

  useEffect(() => {
    if (projectStore.selectedNode && projectStore.showUpdateResourceModel) {
      initData();
    }
    return () => {
      form.resetFields()
      setLayerData([])
      setXmlData()
      setLoadingXml(false)
      setEpsg()
      setUseCredentialization(false)
      setVersion('')
      setAuthType()
    }
  }, [])

  const onReset = async () => {
    setResetState({ loading: true, isDisabled: true }) // disable button reset
    setShowPlaneForm(false)
    if (projectStore.selectedNode) {
      await initData();
    }
    setResetState({ loading: false, isDisabled: false }) //enable button reset
  }


  const onBlur = async (src) => {
    if (src && (src.trim() === `${apiNcWmsUrl}/wms` || src.trim() === `${apiNcWmsUrl}/wms/`)) {
      setIsNcWms(true);
    } else {
      setIsNcWms(false);
    }
    let urlSrc, prevSrvUrl
    try {
      urlSrc = new URL(src);
      if (firstLayerValue)
        prevSrvUrl = new URL(firstLayerValue)
    } catch (error) {
      notification.open({
        message: t('invalid-url'),
        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
        duration: 5,
      })
      return
    }
    if (prevSrvUrl && prevSrvUrl.origin !== urlSrc.origin) {
      form.setFieldsValue({
        useProxy: false
      })
      form.setFieldsValue({ username: undefined });
      form.setFieldsValue({ layers: undefined });
      form.setFieldsValue({ password: undefined });
    }
    if (firstLayerValue !== src) {
      const urlParams = src ? Utils.getAllUrlParams(src) : {}
      try {
        if (urlParams?.request) {
          let baseUrl = src.split('?')[0]
          baseUrl += '?'
          for (const key in urlParams) {
            if (key.toLowerCase() != 'request')
              baseUrl += `${key}=${urlParams[key]}`
          }
          src = baseUrl
          form.setFieldsValue({ src: src });
        }
      } catch (error) {

      }
      setSecondLayerValue(src)
      form.setFieldsValue({
        username: '',
        password: ''
      })
      setUseCredentialization(false)
      fetchLayersWMS(src, form.getFieldValue('useProxy'), undefined, isUseCredential)
    }
  }

  useEffect(() => {
    if (form.getFieldValue('src')) {
      setFirstLayerValue(form.getFieldValue('src'))
    }
  }, [secondLayerValue])

  const fetchLayersWMS = async (src, retry = false, model = undefined, credentials = false) => {
    if (!src) return;
    setUseCredentialization(model?.data.isUseCredential !== undefined ? model.data.isUseCredential : credentials)
    if (src) {
      if (form.getFieldValue('useProxy')) {
        src = `${apiUrl}/wms/${encodeURIComponent(src)}`
      }

      let requestOption = {}
      const username = form.getFieldValue('username')
      const password = form.getFieldValue('password')
      if (username && password && isUseCredential) {
        const Authorization = btoa(`${username.trim()}:${password}`)
        requestOption.headers = {
          Authorization: `Basic ${Authorization}`
        }
      }

      if (isMounted()) setLoadingXml(true)
      const parser = new WMSCapabilities();
      let res = await fetch(`${src}?request=GetCapabilities&service=WMS`, requestOption).then(function (response) {
        if (!response.ok) {
          if (response.status === 401) {
            setAuthType('server')
            setUseCredentialization(true)
            form.setFieldsValue({
              useProxy: false
            })
            notification.open({
              message: t('invalid-credentials'),
              description: t('data-needs-authentication'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          } else {
            if (isUseCredential) {
              notification.open({
                message: t('invalid-credentials'),
                description: t('authentication-failed'),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 5,
              })
            } else if (!retry) {
              form.setFieldsValue({
                useProxy: true
              })
              fetchLayersWMS(form.getFieldValue('src'), true);
            }
          }
        } else {
          return response.text();
        }
      }).then(function (text) {
        if (text) {
          const result = parser.read(text);
          if (isMounted()) setVersion(result ? result.version : '')
        }
        return text
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        if (isMounted()) setLoadingXml(false)
      });

      let xml = res ? Utils.parseXml(res) : false;
      if (xml) {
        const layerData = xml.querySelectorAll('Layer > Name');
        if (isMounted()) setXmlData(xml.getElementsByTagName("Layer"))
        if (isMounted()) setLayerData(Array.prototype.map.call(layerData, element => element.innerHTML));
        model = model ? model : projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model?.data?.layers) {
          form.setFieldsValue({
            layers: model.data.layers,
            palette: model.data.palette,
            bbox: { x1: model.data.bbox ? model.data.bbox[0] : '', y1: model.data.bbox ? model.data.bbox[1] : '', x2: model.data.bbox ? model.data.bbox[2] : '', y2: model.data.bbox ? model.data.bbox[3] : '' },
          });
        }
      } else {
        form.setFieldsValue({ layers: undefined });
        setLayerData([]);
        form.setFieldsValue({ bbox: { x1: '', y1: '', x2: '', y2: '' } });
      }
    }
  }

  const getMetadata = async (src, layerName) => {
    if (src) {
      const res = await axios({
        method: 'get',
        url: src,
        params: {
          request: 'GetMetadata',
          item: 'layerDetails',
          layerName,
        }
      })
      if (res?.data.palettes?.length) {
        const { palettes } = res.data;
        setMetaDatas(res.data)
        setPalettes(palettes);

        form.setFieldsValue({
          palette: palettes[0]
        })
      }
    }
  }

  const extractAvailableDate = () => {
    if (metaDatas?.nearestTimeIso) {
      const defaultDate = metaDatas.nearestTimeIso.slice(0, 10)

      const getStartTime = metaDatas.datesWithData
      const availableDate = []
      for (let year in getStartTime) {
        let months = getStartTime[year]
        for (let month in months) {
          const realMonth = Number(month) + 1;
          const formattedMonth = realMonth < 10 ? '0' + realMonth : realMonth
          let days = months[month]
          for (const element of days) {
            const day = element;
            const formattedDate = day < 9 ? '0' + day : day
            availableDate.push(`${year}-${formattedMonth}-${formattedDate}`)
          }
        }
      }

      setAvailableDates(availableDate)
      form.setFieldsValue({ date: moment(defaultDate) })

    }
  }

  // Datepicker
  useEffect(() => {
    extractAvailableDate()
  }, [metaDatas, layerData])


  useEffect(() => {
    if (selectedDate && form.getFieldValue('layers'))
      getTimeSteps(form.getFieldValue('src'), form.getFieldValue('layers'))
  }, [selectedDate, form.getFieldValue('layers')])

  const onChangeDateSerial = (date) => {
    setSelectedDate(date)
    form.setFieldsValue({ date: moment(date) });
  }
  const onChangeTimeSerial = (time) => {
    form.setFieldsValue({ timeStep: time });
  }

  // disable date
  const disabledDate = (current) => {
    let condition = false

    const formatedDate = moment(current).format(dateFormat)
    if (availableDates && availableDates.length > 0) {
      return availableDates.indexOf(formatedDate) === -1
    }
    return condition
  }

  const getTimeSteps = async (src, layerName) => {
    if (selectedDate && layerName) {
      const res = await axios({
        method: 'get',
        url: src,
        params: {
          request: 'GetMetadata',
          item: 'timesteps',
          layerName,
          day: moment(selectedDate).format(dateFormat),
        }
      })
      if (res.data) {
        setTimeSteps(res.data.timesteps)
      }
    }
  }

  const onChangeLayer = async (value) => {
    if (value) {
      if (isNcWms) {
        setSelectedDate(undefined)
        form.setFieldsValue({ timeStep: [] });
        form.setFieldsValue({ date: undefined });
        getMetadata(form.getFieldValue('src'), value)
      }
      let box = { x1: undefined, x2: undefined, y1: undefined, y2: undefined };

      form.setFieldsValue({ bbox: box });
      for (const element of xmlData) {
        const isGroup = element.getElementsByTagName('Layer').length > 0
        if (isGroup) continue
        const tagName = element.getElementsByTagName('Name')[0];
        if (tagName.innerHTML === value) {
          const geographicBoundingBox = element.getElementsByTagName('EX_GeographicBoundingBox');
          if (geographicBoundingBox?.length) {
            let x1, x2, y1, y2
            // eslint-disable-next-line no-unused-expressions
            geographicBoundingBox[0]?.childNodes.forEach(x => {
              if (x.localName === 'westBoundLongitude') {
                x1 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'eastBoundLongitude') {
                x2 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'southBoundLatitude') {
                y1 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'northBoundLatitude') {
                y2 = +x.childNodes[0]?.nodeValue
              }
            });
            box = { x1, y1, x2, y2 }
          } else {
            const boundingBoxs = element.getElementsByTagName('BoundingBox');
            if (boundingBoxs?.length) {
              for (const _boundingBoxs of boundingBoxs) {
                if (
                  _boundingBoxs.getAttribute('SRS')?.toUpperCase() === 'EPSG:4326' ||
                  _boundingBoxs.getAttribute('srs')?.toLowerCase() === 'EPSG:4326' ||
                  _boundingBoxs.getAttribute('CRS')?.toLowerCase() === 'EPSG:4326' ||
                  _boundingBoxs.getAttribute('crs')?.toLowerCase() === 'EPSG:4326'
                ) {
                  const x1 = +_boundingBoxs.getAttribute('minx')
                  const x2 = +_boundingBoxs.getAttribute('maxx')
                  const y1 = +_boundingBoxs.getAttribute('miny')
                  const y2 = +_boundingBoxs.getAttribute('maxy')
                  box = { x1, y1, x2, y2 }
                }
              }
            }
            else {
              const latlngBoxs = element.getElementsByTagName('LatLonBoundingBox')[0];
              if (latlngBoxs) {
                const x1 = +latlngBoxs.getAttribute('minx')
                const x2 = +latlngBoxs.getAttribute('maxx')
                const y1 = +latlngBoxs.getAttribute('miny')
                const y2 = +latlngBoxs.getAttribute('maxy')
                box = { x1, y1, x2, y2 }
              }
            }
          }
          try {
            if (!validDegreeBox(box)) throw box;
            form.setFieldsValue({ bbox: box });
            setEpsg('EPSG:4326');

            //check auth layer
            await checkAuthLayer({ src: form.getFieldValue('src'), layer: form.getFieldValue('layers'), bbox: form.getFieldValue('bbox'), username: form.getFieldValue('username'), password: form.getFieldValue('password'), version: version })
          } catch (error) {
            box = undefined
            notification.open({
              message: t('wms-layer-coordinates-do-not-match'),
              description: t('xd-twin-cannot-import-this-layer'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          }
        }
      }
    }
  }

  /**
   * Check Authen layer
   * @param {*} data { src: src, layer: layer, bbox: bbox, username, password }
   * @returns 
   */
  const checkAuthLayer = async (data) => {
    let { src, layer, bbox, username, password, version } = data
    let _bbox = [bbox.x1, bbox.y1, bbox.x2, bbox.y2].join(',')
    if (!bbox.x1) {
      if (bbox.length === 4) {
        _bbox = bbox.join(',')
      }
    }
    if (form.getFieldValue('useProxy')) {
      src = `${apiUrl}/wms/${encodeURIComponent(src)}`
    }
    let requestOption = {
      method: 'get',
      url: src,
      responseType: 'document',
      params: {
        service: 'wms',
        request: 'GetMap',
        crs: "EPSG:4326",
        format: "image/png",
        layers: layer,
        width: 256,
        height: 256,
        bbox: _bbox,
        version: '1.1.1',
        styles: ''
      }
    }

    if (version) {
      requestOption.params.version = version
    }

    if (username && password && isUseCredential) {
      const Authorization = btoa(`${username.trim()}:${password}`)
      requestOption.headers = {
        'Authorization': `Basic ${Authorization}`,
        'Access-Control-Allow-Origin': '*',
      }
    } else {
      if (isMounted()) setUseCredentialization(false)
      form.resetFields(["username", "password"]);
    }
    return await axios(requestOption).then(res => {
      return true
    }).catch(e => {
      if (e?.status === 401 || e?.status === 403) {
        setAuthType('layer')
        setUseCredentialization(true)
        if (isUseCredential) {
          notification.open({
            message: t('invalid-credentials'),
            description: t('authentication-failed'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 5,
          })
        } else {
          notification.open({
            message: t('invalid-credentials'),
            description: t('data-needs-authentication'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 5,
          })
        }
        return false
      } else {
        // notification.open({
        //   message: t('invalid-credentials'),
        //   description: t('Layer is not valid'),
        //   icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
        //   duration: 5,
        // })
        return false
      }
    })
  }

  function subStringURL(value) {
    const subServer = value.split("?")[0];
    let cutSubServer = value.replace(subServer, '')
    let layer = ''
    let subLayer = null
    if (cutSubServer)
      layer = cutSubServer.split("layers=")[1];
    if (layer)
      subLayer = layer.split("&")[0];
    if (subServer) {
      form.setFieldsValue({ src: subServer })
      onBlur(subServer)
    }
    if (subLayer) {
      setSubLayerData(subLayer)
    }
  }

  useEffect(() => {
    if (xmlData && subLayerData) {
      form.setFieldsValue({ layers: subLayerData })
      onChangeLayer(subLayerData)
    }
  }, [xmlData])

  useEffect(() => {
    let count = 0;
    if (subLayerData) {
      for (const element of layerData) {
        if (element === subLayerData) {
          count++;
        }
      }
      if (count > 0) {
        form.setFieldsValue({ layers: subLayerData })
      }
      else {
        form.setFieldsValue({ layers: '' })
      }
    }
  })

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      if (endString && startString) {
        if (moment(endString).isBefore(startString)) {
          alert(t('you-have-to-set-start-date-before-end-date'))
          return
        }
      }
      setLoading(true)
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {
          layers: values.layers,
          parameters: {
            transparent: true,
            format: "image/png",
          }
        },
        project: projectStore.projectDetail._id,
        sourceType: 'WMS',
        src: values.src,
      }
      if (!values.bbox || !values?.bbox.x1) {
        return
      }
      projectStore.setLoadingProgress(true)
      newModel.startDate = startDate
      newModel.endDate = endDate
      newModel.data.bbox = [values.bbox.x1, values.bbox.y1, values.bbox.x2, values.bbox.y2]
      newModel.data.epsg = +epsg.split(':')[1];
      newModel.data.classificationType = values.classificationType || null;
      newModel.data.useWMSProvider = values.useWMSProvider;
      newModel.data.useProxy = values.useProxy !== undefined ? values.useProxy : false;
      newModel.data.height = values.height;
      newModel.data.alpha = isNaN(values.alpha) ? 1 : values.alpha;
      newModel.data.version = version ? version : '';
      newModel.data.authType = authType !== undefined ? authType : '';
      newModel.data.isUseCredential = isUseCredential !== undefined ? isUseCredential : false;
      newModel.data.username = values.username ? values.username : '';
      newModel.data.password = values.password ? values.password : '';
      if (isUseCredential && authType === 'layer') {
        let resp = await checkAuthLayer({ src: values.src, layer: values.layers, bbox: values.bbox, username: values.username, password: values.password, version: values.version })
        if (!resp) {
          projectStore.setLoadingProgress(false)
          setLoading(false)
          return
        }
      }

      newModel.data.presentation = (planeValue === 'TERRAIN') ? 'PLANE' : ''
      let _updateModel = projectStore.visibleTilesets.find(c => c.modelId === projectStore.selectedNode.modelId)
      const updateModel = async (newModel) => {
        await projectStore
          .updateVersion(projectStore.selectedNode.modelId, newModel)
          .then(async (model) => {
            // Add to tree
            const newNode = {
              title: model.name,
              key: uuid(),
              type: 'FILE',
              modelId: model._id,
              sourceType: model.sourceType,
              modelType: model.type,
              useService: model.useService,
              hash: model.hash,
              src: model.src,
              endDate: model.endDate,
              startDate: model.startDate
            }
            await editTreeNode(newNode, projectStore.projectDetail.treeData);
            projectStore.setCurrentModelId(false)
            const index = projectStore.modelList.findIndex(x => x._id === projectStore.selectedNode.modelId)
            const temp = [...projectStore.modelList]
            temp.splice(index, 1)
            projectStore.setModelList(temp)
            // projectStore.setDeleteIds([projectStore.selectedNode.modelId])
            model.ref = false;
            model.isUpdate = true;
            model.isVisible = _updateModel.isVisible;
            temp.map(item => {
              delete item.isUpdate
              return item
            })
            // temp.push(model)
            temp.splice(index, 0, model)
            projectStore.setModelList(temp)
            projectStore.setNewModelId(model._id)
            projectStore.setSelectedModel(
              projectStore.modelList[projectStore.modelList.length - 1]
            )
            projectStore.setRerenderWMS(!projectStore.rerenderWMS)
          })
          .then(() => {
            projectStore.setLoadingProgress(false)
            setLoading(false)
            message.success(t('tileset-uploaded-successfully'), 5)
          })
          .catch(err => {
            setLoading(false)
            projectStore.setLoadingProgress(false)
            console.log('err', err)
            notification.open({
              message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
              description: t('something-went-wrong-when-creating-model'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          }).finally(() => {
            onCancel();
          })
      }
      if (isNcWms) {
        newModel.data.isNcWms = true;
        newModel.data.palette = values.palette;
        newModel.data.time = `${moment(values.date).format(dateFormat)}T${values.timeStep || '00:00:00.000Z'}`

        await axios({
          method: 'get',
          url: values.src,
          params: {
            request: 'GetMetadata',
            item: 'minmax',
            layers: values.layers,
            styles: 'default-scalar',
            bbox: newModel.data.bbox.join(','),
            srs: 'EPSG:4326',
            version: '1.1.1',
            height: 100,
            width: 100,
            time: newModel.data.time
          }
        }).then(async res => {
          newModel.data.scaleRange = res.data;
          await updateModel(newModel);
        }).catch(e => {
          projectStore.setLoadingProgress(false)
          setLoading(false)
          notification.open({
            message: t('an-error-occurred-when-creating-model-data'),
            description: t('something-went-wrong-when-extracting-scale-range'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
      } else {
        await updateModel(newModel);
      }
    })
  }

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Form.Item
        label={t('name')}
        name="name"
        rules={[
          {
            required: true,
            message: t('please-input-name'),
          },
        ]}>
        <Input placeholder={t('name')} />
      </Form.Item>
      <Form.Item
        label={t('server')}
        name="src"
        rules={[
          {
            required: true,
            message: t('please-input-url'),
          },
        ]}>
        <Input onChange={(e) => { subStringURL(e.target.value) }} placeholder="URL" />
      </Form.Item>
      <Form.Item name="useProxy" valuePropName="checked">
        <Checkbox onChange={() => fetchLayersWMS(form.getFieldValue('src'))}>{t('proxy-select-if-cannot-load-any-layers')}</Checkbox>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12} className="gutter-row">
          <Form.Item
            label={t('username')}
            autoComplete="off"
            name="username"
            rules={[
              {
                required: isUseCredential,
                message: t('please-input-your-username'),
              },
            ]}
          >
            <Input.Password visibilityToggle={false} autoComplete="new-password" placeholder={t('username')} onBlur={e => {
              if (e.target.value && form.getFieldValue('password') && authType === 'server') {
                fetchLayersWMS(form.getFieldValue('src'), form.getFieldValue('useProxy'), undefined, isUseCredential)
              }
            }} />
          </Form.Item>
        </Col>
        <Col span={12} className="gutter-row">
          <Form.Item
            label={t('password')}
            autoComplete="off"
            name="password"
            rules={[
              {
                required: isUseCredential,
                message: t('please-input-your-password'),
              },
            ]}
          >
            <Input.Password visibilityToggle={false} autoComplete="new-password" placeholder={t('password')} onBlur={e => {
              if (e.target.value && form.getFieldValue('username') && authType === 'server') {
                fetchLayersWMS(form.getFieldValue('src'), form.getFieldValue('useProxy'), undefined, isUseCredential)
              }
            }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={(
          <>
            {t('layer')}
            {loadingXml && <Spin indicator={loadingIcon} />}
          </>
        )}
        name="layers"
        rules={[
          {
            required: true,
            message: t('please-select-layers'),
          },
        ]}>
        <Select allowClear showSearch placeholder={t('select-a-option')} onChange={onChangeLayer}>
          {loadingXml ?
            (<Option><Spin tip={t('loading-layers')} spinning={true} /></Option>) :
            (
              layerData.map(layer => (
                <Option key={layer} value={layer}>{layer}</Option>
              ))
            )
          }
        </Select>
      </Form.Item>
      <Form.Item label={`${t('coordinates')} ${epsg ? `(${epsg})` : ''}`}>
        <Form.Item
          name={['bbox', 'x1']}
          style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginBottom: '5px' }}
          rules={[
            {
              required: true,
              message: t('please-set-coordinates')
            },
          ]}>
          <InputNumber placeholder="x1" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['bbox', 'y1']}
          style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: '5px', marginBottom: '5px' }}
          rules={[
            {
              required: true,
              message: t('please-set-coordinates')
            },
          ]}>
          <InputNumber placeholder="y1" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['bbox', 'x2']}
          style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginBottom: '5px' }}
          rules={[
            {
              required: true,
              message: t('please-set-coordinates')
            },
          ]}>
          <InputNumber placeholder="x2" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['bbox', 'y2']}
          style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: '5px', marginBottom: '5px' }}
          rules={[
            {
              required: true,
              message: t('please-set-coordinates')
            },
          ]}>
          <InputNumber placeholder="y2" style={{ width: '100%' }} />
        </Form.Item>
      </Form.Item>
      {(isNcWms) && (
        <Form.Item label={t('date-and-time')}>
          <Row>
            <Form.Item
              name="date"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              initialValues={selectedDate}
              rules={[
                {
                  required: true,
                  message: t('please-select-time'),
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={disabledDate}
                format="YYYY-MM-DD"
                inputReadOnly={true}
                onChange={onChangeDateSerial}
              />
            </Form.Item>
            <Form.Item
              name="timeStep"
              style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
              rules={[
                {
                  required: true,
                  message: t('please-select-time'),
                },
              ]}
            >

              <Select
                style={{ width: '100%' }}
                onChange={onChangeTimeSerial}
              >
                {
                  timeSteps.length > 0 &&
                  timeSteps.map(time =>
                    <Option value={time} key={time}>{time}</Option>
                  )}
              </Select>
            </Form.Item>
          </Row>
        </Form.Item>
      )}
      <Form.Item
        label={t('presentation-style')}
        name="classificationType"
      >
        <Select onChange={onChangeClassificationType} allowClear placeholder={t('select-a-option')}>
          <Option key="TERRAIN" value={terrainValue} onMouseDown={handleTerrain}>{t('background-map')}</Option>
          <Option key="CESIUM_3D_TILE" value={'CESIUM_3D_TILE'}>{t('drape-to-models')}</Option>
          <Option key="BOTH" value={'BOTH'}>{t('background-and-models')}</Option>
          <Option key="PLANE" value={planeValue} onMouseDown={handlePlane}>{t('plane-with-elevation')}</Option>
        </Select>
      </Form.Item>
      {
        showPlaneForm &&
        <Form.Item
          label={t('elevation')}
          name="height"
        >
          <InputNumber placeholder={t('elevation')} style={{ width: '100%' }} />
        </Form.Item>
      }
      {isNcWms && (
        <Form.Item
          label={t('colour-palette')}
          name="palette"
          rules={[
            {
              required: true,
              message: t('please-select-palette'),
            },
          ]}>
          <Select allowClear showSearch placeholder={t('select-a-option')}>
            {loadingXml ?
              (<Option><Spin tip={t('loading-palettes')} spinning={true} /></Option>) :
              (
                palettes.map(palette => (
                  <Option key={palette} value={palette}>
                    <img src={`${form.getFieldValue('src')}?request=GetLegendGraphic&height=20&width=500&numcolorbands=250&colorbaronly=true&vertical=false&palette=${palette}`} />
                  </Option>
                ))
              )
            }
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label={(
          <>
            {t('transparency')}
          </>
        )}
        name="alpha">
        <Slider
          style={{ margin: '10px' }}
          min={0.0}
          max={1.0}
          step={0.1}
        />
      </Form.Item>
      {
        projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager") &&
        <Button
          type="primary"
          disabled={loadingXml}
          loading={loading}
          icon={<SaveOutlined />}
          htmlType='submit'
          style={{ position: 'absolute', top: '10px' }}
        >
          {t('commons.save')}
        </Button>
      }
      <Button
        loading={resetState.loading}
        disabled={resetState.isDisabled}
        type="default"
        shape="circle"
        onClick={onReset}
        style={{ position: 'absolute', left: (projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager")) ? '99px' : '10px', top: '10px' }}
        icon={<ReloadOutlined />}></Button>
    </Form>
  )
}

//WFS component
const WFSForm = ({ projectStore, editTreeNode, onCancel, startDate, endDate, setEndDate, setStartDate, startString, endString, setEndString, setStartString }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [layerData, setLayerData] = useState([])
  const [xmlData, setXmlData] = useState()
  const [loadingXml, setLoadingXml] = useState(false)
  const [epsg, setEpsg] = useState()
  const [isUseCredential, setUseCredentialization] = useState(false)
  const [resetState, setResetState] = useState({ loading: false, isDisabled: false });
  const [outputFormat, setOutputFormat] = useState('application/json')
  const [version, setVersion] = useState('')
  const [pointStyle, setPointStyle] = useState('Billboard')
  const isMounted = useIsMounted()

  useEffect(() => {
    if (projectStore.selectedNode && projectStore.showUpdateResourceModel) {
      const initData = async () => {
        const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model) {
          setUseCredentialization(model.data.isUseCredential !== undefined ? model.data.isUseCredential : false)
          setOutputFormat(model.data.outputFormat ? model.data.outputFormat : 'application/json')
          setVersion(model.data.version ? model.data.version : '')
          if (model.startDate) {
            const localstartDate = moment.utc(model.startDate).local().format()
            setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
          }

          if (model.endDate) {
            const localendDate = moment.utc(model.endDate).local().format()
            setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
          }
          setStartString(model.startDate)
          setEndString(model.endDate)
          form.setFieldsValue({
            name: model.name,
            src: model.src,
            clampToGround: model.data?.clampToGround,
            username: model.data.username ? model.data.username : '',
            password: model.data.password ? model.data.password : '',
          });

          if (projectStore.fileEdit) {
            form.setFieldsValue({ name: projectStore.fileEdit.title });
          }

          await fetchLayersWFS(model.src, false, model)
          if (isMounted()) setLoadingXml(false)
          form.setFieldsValue({ layers: model.data.layers });
          form.setFieldsValue({ bbox: { x1: model.data.bbox[0], y1: model.data.bbox[1], x2: model.data.bbox[2], y2: model.data.bbox[3] } });
          if (model.data?.style.point) {
            form.setFieldsValue({
              isShowPointAs: model.data.style.point.isShowPointAs ? model.data.style.point.isShowPointAs : 'Billboard', //Billboard; Point
              pointColor: model.data.style.point.color || '#ffffff',
              pointAlpha: model.data.style.point.alpha,
              pointPixelSize: model.data.style.point.pixelSize || '',
              pointOutlineColor: model.data.style.point.outlineColor || '#000000',
              pointOutlineWidth: model.data.style.point.outlineWidth || 0,
            })
            setPointStyle(model.data.style.point.isShowPointAs ? model.data.style.point.isShowPointAs : 'Billboard')
          }
          if (model.data?.style.line) {
            form.setFieldsValue({
              lineWidth: model.data.style.line.width || 1,
              lineMaterial: model.data.style.line.color || '#ffffff',
              lineAlpha: model.data.style.line.alpha
            })
          }
          if (model.data?.style.polygon) {
            form.setFieldsValue({
              polygonMaterial: model.data.style.polygon.color || '#ffffff',
              polygonAlpha: model.data.style.polygon.alpha,
              polygonHeight: model.data.style.polygon.height || '',
              polygonOutlineColor: model.data.style.polygon.outlineColor || '#000000',
              polygonExtrudedHeight: model.data.style.polygon.extrudedHeight || ''
            })
          }

          if (isMounted()) setEpsg(model.data.epsg);
        }
      }

      initData();
    }
    return () => {
      form.resetFields()
      setLayerData([])
      setXmlData()
      setLoadingXml(false)
      setEpsg()
      setUseCredentialization(false)
      setVersion('')
      setPointStyle('Billboard')
    }
  }, [])

  const onReset = async () => {
    const initData = async () => {
      setResetState({ loading: true, isDisabled: true })
      const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
      if (model) {
        setUseCredentialization(model.data.isUseCredential !== undefined ? model.data.isUseCredential : false)
        setOutputFormat(model.data.outputFormat ? model.data.outputFormat : 'application/json')
        setVersion(model.data.version ? model.data.version : '')
        form.setFieldsValue({
          name: model.name,
          src: model.src,
          clampToGround: model.data?.clampToGround,
          username: model.data.username ? model.data.username : '',
          password: model.data.password ? model.data.password : '',
        });
        if (model.startDate) {
          const localstartDate = moment.utc(model.startDate).local().format()
          setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
        }

        if (model.endDate) {
          const localendDate = moment.utc(model.endDate).local().format()
          setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
        }
        setStartString(model.startDate)
        setEndString(model.endDate)
        if (projectStore.fileEdit) {
          form.setFieldsValue({ name: projectStore.fileEdit.title });
        }
        await fetchLayersWFS(model.src, false, model)
        form.setFieldsValue({ layers: model.data.layers });
        form.setFieldsValue({ bbox: { x1: model.data.bbox[0], y1: model.data.bbox[1], x2: model.data.bbox[2], y2: model.data.bbox[3] } });
        if (model.data?.style.point) {
          form.setFieldsValue({
            isShowPointAs: model.data.style.point.isShowPointAs ? model.data.style.point.isShowPointAs : 'Billboard', //Billboard; Point
            pointColor: model.data.style.point.color || '#ffffff',
            pointAlpha: model.data.style.point.alpha,
            pointPixelSize: model.data.style.point.pixelSize || 5,
            pointOutlineColor: model.data.style.point.outlineColor || '#000000',
            pointOutlineWidth: model.data.style.point.outlineWidth || 0,
          })
          setPointStyle(model.data.style.point.isShowPointAs ? model.data.style.point.isShowPointAs : 'Billboard')
        }
        if (model.data?.style.line) {
          form.setFieldsValue({
            lineWidth: model.data.style.line.width || 2,
            lineMaterial: model.data.style.line.color || '#ffffff',
            lineAlpha: model.data.style.line.alpha
          })
        }
        if (model.data?.style.polygon) {
          form.setFieldsValue({
            polygonMaterial: model.data.style.polygon.color || '#ffffff',
            polygonAlpha: model.data.style.polygon.alpha,
            polygonHeight: model.data.style.polygon.height || '',
            polygonOutlineColor: model.data.style.polygon.outlineColor || '#000000',
            polygonExtrudedHeight: model.data.style.polygon.extrudedHeight || ''
          })
        }

        setEpsg(model.data.epsg);
      }
      setResetState({ loading: false, isDisabled: false }) //enable button reset
    }

    await initData();
  }

  const subStringURL = (value) => {
    const subServer = value.split("?")[0];
    let cutSubServer = value.replace(subServer, '')
    let layer = ''
    let subLayer = null
    if (cutSubServer)
      layer = cutSubServer.split("layers=")[1];
    if (layer)
      subLayer = layer.split("&")[0];
    if (subServer) {
      form.setFieldsValue({ src: subServer })
      onBlur(subServer)
    }
  }

  const onBlur = async (src) => {
    let urlSrc, prevSrvUrl
    try {
      urlSrc = new URL(src);
    } catch (error) {
      notification.open({
        message: t('invalid-url'),
        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
        duration: 5,
      })
      return
    }
    if (prevSrvUrl && prevSrvUrl.origin !== urlSrc.origin) {
      form.setFieldsValue({ username: undefined });
      form.setFieldsValue({ layers: undefined });
      form.setFieldsValue({ password: undefined });
    }

    const urlParams = src ? Utils.getAllUrlParams(src) : {}
    try {
      if (urlParams?.request) {
        let baseUrl = src.split('?')[0]
        baseUrl += '?'
        for (const key in urlParams) {
          if (key.toLowerCase() != 'request')
            baseUrl += `${key}=${urlParams[key]}`
        }
        src = baseUrl
        form.setFieldsValue({ src: src });
      }
    } catch (error) {

    }
    form.setFieldsValue({
      username: '',
      password: ''
    })
    setUseCredentialization(false)
    fetchLayersWFS(src)
  }

  const fetchLayersWFS = async (src, retry = false, model = undefined) => {
    setUseCredentialization(false)
    form.setFieldsValue({ layers: undefined });
    form.setFieldsValue({ bbox: { x1: '', y1: '', x2: '', y2: '' } });
    setLayerData([]);
    if (src) {
      let requestOption = {
        method: 'get',
        url: src,
        responseType: 'document',
        params: {
          request: 'GetCapabilities',
          service: 'WFS',
        }
      }
      setUseCredentialization(model?.data.isUseCredential !== undefined ? model.data.isUseCredential : false)

      const username = form.getFieldValue('username')
      const password = form.getFieldValue('password')
      if (username && password && isUseCredential) {
        const Authorization = btoa(`${username.trim()}:${password}`)
        requestOption.headers = {
          Authorization: `Basic ${Authorization}`
        }
      }
      if (isMounted()) setLoadingXml(true)
      const res = await axios(requestOption).catch(e => {
        if (e?.status === 401 || e?.status === 403) {
          setUseCredentialization(true)
          form.setFieldsValue({
            useProxy: false
          })
          notification.open({
            message: t('invalid-credentials'),
            description: t('this-resource-require-credentials'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 5,
          })
        } else {
          if (isUseCredential) {
            notification.open({
              message: t('invalid-credentials'),
              description: t('this-resource-require-credentials'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          } else if (!retry) {
            fetchLayersWFS(form.getFieldValue('src'), true);
          }
        }
      }).finally(() => {
        if (isMounted()) setLoadingXml(false)
      })

      if (res?.data) {
        let _capabilities = res.data.getElementsByTagName('wfs:WFS_Capabilities')[0] ? res.data.getElementsByTagName('wfs:WFS_Capabilities')[0] : res.data.getElementsByTagName('WFS_Capabilities')[0]
        if (_capabilities) {
          if (isMounted()) setVersion(_capabilities.getAttribute('version'))
        }
        const layerData = res.data.querySelectorAll('FeatureTypeList > FeatureType > Name');
        if (isMounted()) setXmlData(res.data.querySelectorAll('FeatureType'))
        if (isMounted()) setLayerData(Array.prototype.map.call(layerData, element => element.innerHTML));
        if (model?.data?.layers) {
          form.setFieldsValue({
            layers: model.data.layers,
            bbox: { x1: model.data.bbox ? model.data.bbox[0] : '', y1: model.data.bbox ? model.data.bbox[1] : '', x2: model.data.bbox ? model.data.bbox[2] : '', y2: model.data.bbox ? model.data.bbox[3] : '' },
          });
        }
      }
    }
  }

  const onChangeLayer = async (value) => {
    setUseCredentialization(false)
    if (value) {
      let box = { x1: undefined, x2: undefined, y1: undefined, y2: undefined };

      form.setFieldsValue({ bbox: box });
      for (const element of xmlData) {
        const isGroup = element.getElementsByTagName('FeatureTypeList').length > 0
        if (isGroup) continue
        const tagName = element.querySelectorAll('FeatureTypeList > FeatureType > Name')[0];
        if (tagName && tagName.innerHTML === value) {
          // get boudingbox
          let x1, x2, y1, y2
          if (element.getElementsByTagName('ows:WGS84BoundingBox') && element.getElementsByTagName('ows:WGS84BoundingBox').length) {
            element.getElementsByTagName('ows:WGS84BoundingBox')[0].childNodes.forEach(x => {
              let str = x.childNodes[0]?.nodeValue.split(" ")
              if (str && x.localName === 'LowerCorner') {
                x1 = +str[0]
                y1 = +str[1]
              }
              if (str && x.localName === 'UpperCorner') {
                x2 = +str[0]
                y2 = +str[1]
              }
            });

            box = { x1, y1, x2, y2 }
          } else if (element.getElementsByTagName('LatLongBoundingBox') && element.getElementsByTagName('LatLongBoundingBox').length) {
            x1 = +element.getElementsByTagName('LatLongBoundingBox')[0].getAttribute('minx')
            x2 = +element.getElementsByTagName('LatLongBoundingBox')[0].getAttribute('maxx')
            y1 = +element.getElementsByTagName('LatLongBoundingBox')[0].getAttribute('miny')
            y2 = +element.getElementsByTagName('LatLongBoundingBox')[0].getAttribute('maxy')
            box = { x1, y1, x2, y2 }
          } else if (element.getElementsByTagName('BoundingBox') && element.getElementsByTagName('BoundingBox').length) {
            const boundingBoxs = element.getElementsByTagName('BoundingBox');
            for (const _boundingBoxs of boundingBoxs) {
              if (
                _boundingBoxs.getAttribute('SRS')?.toUpperCase() === 'EPSG:4326' ||
                _boundingBoxs.getAttribute('srs')?.toLowerCase() === 'EPSG:4326' ||
                _boundingBoxs.getAttribute('CRS')?.toLowerCase() === 'EPSG:4326' ||
                _boundingBoxs.getAttribute('crs')?.toLowerCase() === 'EPSG:4326'
              ) {
                x1 = +_boundingBoxs.getAttribute('minx')
                x2 = +_boundingBoxs.getAttribute('maxx')
                y1 = +_boundingBoxs.getAttribute('miny')
                y2 = +_boundingBoxs.getAttribute('maxy')
                box = { x1, y1, x2, y2 }
              }
            }
          }

          // get SRS name
          let srsName = 'EPSG:4326'
          if (element.getElementsByTagName('DefaultCRS') && element.getElementsByTagName('DefaultCRS').length) {
            srsName = element.getElementsByTagName('DefaultCRS')[0].innerHTML
          } else if (element.getElementsByTagName('wfs:DefaultCRS') && element.getElementsByTagName('wfs:DefaultCRS').length) {
            srsName = element.getElementsByTagName('wfs:DefaultCRS')[0].innerHTML
          } else if (element.getElementsByTagName('SRS') && element.getElementsByTagName('SRS').length) {
            srsName = element.getElementsByTagName('SRS')[0].innerHTML
          } else if (element.getElementsByTagName('DefaultSRS') && element.getElementsByTagName('DefaultSRS').length) {
            srsName = element.getElementsByTagName('DefaultSRS')[0].innerHTML
          } else if (element.getElementsByTagName('OtherSRS') && element.getElementsByTagName('OtherSRS').length) {
            srsName = element.getElementsByTagName('OtherSRS')[0].innerHTML
          }

          try {
            if (!x1 || !y1 || !x2 || !y2) return
            form.setFieldsValue({ bbox: box });
            setEpsg(Number(srsName.match(/\d+$/)[0]));

            //check valid wfs outputformat
            let rescheck = await checkValidGeoURL({ src: form.getFieldValue('src'), outputFormat: ['application/json', version === '1.0.0' ? 'GML2' : version === '2.0.0' ? 'text/xml; subtype=gml/3.2.1' : 'GML3'], layer: form.getFieldValue('layers'), bbox: form.getFieldValue('bbox'), username: form.getFieldValue('username'), password: form.getFieldValue('password'), version: version })
            if (rescheck.status !== 200) {
              if (rescheck.status === 401) {
                setUseCredentialization(true)
              } else {
                form.setFieldsValue({ layers: undefined });
                form.setFieldsValue({ bbox: { x1: undefined, x2: undefined, y1: undefined, y2: undefined } });
              }
              notification.open({
                message: t('error'),
                description: rescheck.message,
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />
              })
            }
          } catch (error) {
            notification.open({
              message: t('wfs-server-error'),
              description: t('request-wfs-not-supported'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          }
          break;
        }
      }
    }
  }

  const gml2geojson = (gml, version) => {
    let features
    if (version === '1.0.0') {
      features = new GML2().readFeatures(gml);
    } else if (version === '2.0.0') {
      features = new GML32().readFeatures(gml);
    } else {
      features = new GML3().readFeatures(gml);
    }
    let geoJsonStr = new GeoJSON().writeFeatures(features);
    return JSON.parse(geoJsonStr)
  }

  /**
   * check valid GetFeature
   * @param {*} data { src: src, outputFormat: outputFormat, layer: layer, bbox: bbox, username, password, version }
   * @returns {status, data, message} //status: 200, 401, 400, 404
   */
  const checkValidGeoURL = async (data) => {
    let { src, outputFormat, layer, bbox, username, password, version } = data
    let _bbox = [bbox.x1, bbox.y1, bbox.x2, bbox.y2].join(',')

    let resl
    for (const _outputformat of outputFormat) {
      setOutputFormat(_outputformat)
      let requestOption = {
        method: 'get',
        url: src,
        params: {
          service: 'WFS',
          request: 'GetFeature',
          outputFormat: _outputformat,
          typeName: layer,
          bbox: _bbox,
          maxFeatures: 1,
        }
      }

      if (version) {
        requestOption.params.version = version
      }

      if (_outputformat === 'text/xml; subtype=gml/3.2.1') {
        requestOption.params.bbox = requestOption.params.bbox + ',EPSG:4326'
        requestOption.params.srsName = 'EPSG:4326'
        requestOption.params.version = '1.1.0'
      }

      if (username && password && isUseCredential) {
        const Authorization = btoa(`${username.trim()}:${password}`)
        requestOption.headers = {
          'Authorization': `Basic ${Authorization}`,
          'Access-Control-Allow-Origin': '*',
        }
      }
      projectStore.setLoadingProgress(true)
      let res = await axios(requestOption).then((_res) => {
        return { status: 200, data: _res.data, message: 'success' }
      }).catch(e => {
        if (e?.status === 401 || e?.status === 403) {
          let message = 'data-needs-authentication.'
          if (username && password) {
            message = 'authentication-failed'
          }
          return { status: 401, data: undefined, message: message }
        }
        // debugger
        let message = 'Layer is not valid';
        if (e?.data) {
          message = Utils.strip_html_tags(e.data)
        }

        return { status: 400, data: undefined, message: message }
      }).finally(() => {
        projectStore.setLoadingProgress(false)
      })

      if (res && res.status === 200 && res.data) {
        try {
          let wfs_data
          if (['GML2', 'GML3', 'text/xml; subtype=gml/3.2.1'].includes(_outputformat)) {
            wfs_data = gml2geojson(res.data, version)
          } else {
            let features = new GeoJSON().readFeatures(res.data)
            wfs_data = new GeoJSON().writeFeatures(features);
          }
          res.data = wfs_data
          resl = res
          break; //exit loop
        } catch (error) {
          res.message = 'Requested outputFormat not supported'
          res.status = 404
        }
      } else {
        resl = res
        if (res?.status === 401) {
          break; //exit loop
        }
      }
    }

    return resl
  }

  const handleSubmit = async () => {
    form.validateFields().then(async res => {
      if (endString && startString) {
        if (moment(endString).isBefore(startString)) {
          alert(t('you-have-to-set-start-date-before-end-date'))
          return
        }
      }
      projectStore.setLoadingProgress(true)
      let newModel = {
        name: res.name ? res.name : 'Unnamed',
        hash: '',
        data: {
          layers: res.layers
        },
        project: projectStore.projectDetail._id,
        sourceType: 'WFS',
        src: res.src,
      }
      if (!res?.bbox.x1) {
        return
      }
      newModel.data.outputFormat = outputFormat
      newModel.data.bbox = [res.bbox.x1, res.bbox.y1, res.bbox.x2, res.bbox.y2]
      newModel.data.epsg = +epsg;
      newModel.data.clampToGround = res.clampToGround;
      newModel.data.style = {
        point: {
          isShowPointAs: res.isShowPointAs, //Billboard; Point
          color: res.pointColor,
          alpha: res.pointAlpha,
          pixelSize: res.pointPixelSize ?? 5,
          outlineColor: res.pointOutlineColor ? res.pointOutlineColor : '#000000',
          outlineWidth: res.pointOutlineWidth ?? 0,
        },
        line: {
          width: res.lineWidth ?? 2,
          color: res.lineMaterial,
          alpha: res.lineAlpha,
        },
        polygon: {
          color: res.polygonMaterial,
          alpha: res.polygonAlpha,
          height: res.polygonHeight,
          outlineColor: res.polygonOutlineColor,
          extrudedHeight: res.polygonExtrudedHeight,
        }
      }
      newModel.data.version = version ? version : '';
      newModel.data.isUseCredential = isUseCredential !== undefined ? isUseCredential : false;
      newModel.data.username = isUseCredential && res.username ? res.username : '';
      newModel.data.password = isUseCredential && res.password ? res.password : '';
      newModel.startDate = startDate
      newModel.endDate = endDate
      // need convert to 4326
      if (!validDegreeBox(res.bbox)) {
        const _bbox = []
        let _convertPLToWGS841 = await projectStore.convertProjectPlaneToWGS84(res.bbox.y1, res.bbox.x1, 0, epsg, 'None')
        if (_convertPLToWGS841.Status === 'OK') {
          _bbox.push(_convertPLToWGS841.Point[1])
          _bbox.push(_convertPLToWGS841.Point[0])
        }
        let _convertPLToWGS842 = await projectStore.convertProjectPlaneToWGS84(res.bbox.y2, res.bbox.x2, 0, epsg, 'None')
        if (_convertPLToWGS842.Status === 'OK') {
          _bbox.push(_convertPLToWGS842.Point[1])
          _bbox.push(_convertPLToWGS842.Point[0])
        }
        if (_bbox.length === 4) {
          newModel.data.projectPlaneToWGS84 = { bbox: _bbox, epsg: 4326 }
        }
      } else {
        newModel.data.projectPlaneToWGS84 = null
      }

      if (isUseCredential) {
        let resp = await checkValidGeoURL({ src: res.src, outputFormat: [outputFormat], layer: res.layers, bbox: res.bbox, username: res.username, password: res.password, version: version })
        if (!resp || resp?.status !== 200) {
          notification.open({
            message: t('error'),
            description: t(resp.message),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />
          })
          return
        }
      }

      await updateModel(projectStore.selectedNode.modelId, newModel);
      projectStore.setLoadingProgress(false)

    }).catch(error => {
      console.log(error)
    })
  }

  const updateModel = async (modelId, newModel) => {
    await projectStore.updateVersion(modelId, newModel).then(async (model) => {
      let _updateModel = projectStore.visibleTilesets.find(c => c.modelId === modelId)
      // Add to tree
      const newNode = {
        title: model.name,
        key: uuid(),
        type: 'FILE',
        modelId: model._id,
        sourceType: model.sourceType,
        modelType: model.type,
        useService: model.useService,
        hash: model.hash,
        src: model.src,
        endDate: model.endDate,
        startDate: model.startDate
      }
      await editTreeNode(newNode, projectStore.projectDetail.treeData);
      projectStore.setCurrentModelId(false)
      const index = projectStore.modelList.findIndex(x => x._id === modelId)
      const temp = [...projectStore.modelList]
      temp.splice(index, 1)
      projectStore.setModelList(temp)
      // projectStore.setDeleteIds([modelId])
      model.ref = false;
      model.isUpdate = true;
      model.isVisible = _updateModel.isVisible;
      temp.map(item => {
        delete item.isUpdate
        return item
      })
      temp.push(model)
      projectStore.setModelList(temp)
      projectStore.setNewModelId(model._id)
      projectStore.setSelectedModel(
        projectStore.modelList[projectStore.modelList.length - 1]
      )
      // projectStore.setRerenderWMS(!projectStore.rerenderWMS)
    }).then(() => {
      onCancel();
      message.success(t('tileset-uploaded-successfully'), 5)
    }).catch(err => {
      console.log('err', err)
      notification.open({
        message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
        description: t('something-went-wrong-when-creating-model'),
        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
        duration: 0,
      })
    })
  }

  const handleChangePointStyle = (value) => {
    setPointStyle(value)
  };

  return (
    <Form name="wfsform" layout="vertical" form={form} onFinish={handleSubmit}>
      <Form.Item
        label={t('name')}
        name="name"
        rules={[
          {
            required: true,
            message: t('please-input-name'),
          },
        ]}>
        <Input placeholder={t('name')} />
      </Form.Item>
      <Form.Item
        label={t('server')}
        name="src"
        rules={[
          {
            required: true,
            message: t('please-input-url'),
          },
        ]}>
        <Input onChange={(e) => { subStringURL(e.target.value) }} placeholder="URL" />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12} className="gutter-row">
          <Form.Item
            label={t('username')}
            autoComplete="off"
            name="username"
            rules={[
              {
                required: isUseCredential,
                message: t('please-input-your-username'),
              },
            ]}
          >
            <Input.Password visibilityToggle={false} autoComplete="new-password" placeholder={t('username')} />
          </Form.Item>
        </Col>
        <Col span={12} className="gutter-row">
          <Form.Item
            label={t('password')}
            autoComplete="off"
            name="password"
            rules={[
              {
                required: isUseCredential,
                message: t('please-input-your-password'),
              },
            ]}
          >
            <Input.Password visibilityToggle={false} autoComplete="new-password" placeholder={t('password')} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={(
          <>
            {t('layer')}
            {loadingXml && <Spin indicator={loadingIcon} />}
          </>
        )}
        name="layers"
        rules={[
          {
            required: true,
            message: t('please-select-layers'),
          },
        ]}>
        <Select allowClear showSearch placeholder={t('select-a-option')} onChange={onChangeLayer}>
          {loadingXml ?
            (<Option><Spin tip={t('loading-layers')} spinning={true} /></Option>) :
            (
              layerData.map(layer => (
                <Option key={layer} value={layer}>{layer}</Option>
              ))
            )
          }
        </Select>
      </Form.Item>
      <Form.Item label={`${t('coordinates')} ${epsg ? `(${epsg})` : ''}`}>
        <Form.Item
          name={['bbox', 'x1']}
          style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginBottom: '5px' }}
          rules={[
            {
              required: true,
              message: t('please-set-coordinates')
            },
          ]}>
          <InputNumber placeholder="x1" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['bbox', 'y1']}
          style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: '5px', marginBottom: '5px' }}
          rules={[
            {
              required: true,
              message: t('please-set-coordinates')
            },
          ]}>
          <InputNumber placeholder="y1" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['bbox', 'x2']}
          style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginBottom: '5px' }}
          rules={[
            {
              required: true,
              message: t('please-set-coordinates')
            },
          ]}>
          <InputNumber placeholder="x2" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['bbox', 'y2']}
          style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: '5px', marginBottom: '5px' }}
          rules={[
            {
              required: true,
              message: t('please-set-coordinates')
            },
          ]}>
          <InputNumber placeholder="y2" style={{ width: '100%' }} />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={t('clamp-to-ground')}
        name="clampToGround"
        valuePropName="checked"
        className="form-horizontal">
        <Switch
          checkedChildren={t('commons.on')}
          unCheckedChildren={t('commons.off')}
          style={{ marginLeft: '5px' }}
        />
      </Form.Item>
      <Divider orientation="left" orientationMargin="0">
        {t('point-style')}
      </Divider>
      <Form.Item style={{ marginBottom: 0 }}>
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={t('point-style')}
              className="form-horizontal"
              name="isShowPointAs"
              initialValue={'Billboard'}
            >
              <Select
                placeholder={t('select-filter')}
                onChange={handleChangePointStyle}
              >
                <Option value="Billboard">{t('shows-points-as-billboards')}</Option>
                <Option value="Point">{t('shows-points-as-point-graphics')}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t('color')}
              className="form-horizontal"
              name="pointColor"
              initialValue={'#ffffff'}
            >
              <input type="color" className='custom-input-color' />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            {pointStyle === 'Point' ?
              (<Form.Item
                label={t('outline-color')}
                className="form-horizontal"
                initialValue={'#ffffff'}
                name="pointOutlineColor">
                <input type="color" className='custom-input-color' />
              </Form.Item>) : ''
            }
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={t('transparency')}
              name="pointAlpha">
              <Slider
                style={{ marginRight: '7px' }}
                min={0.0}
                max={1.0}
                step={0.1}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={`${t('size')} (px)`}
              className="form-horizontal"
              name="pointPixelSize">
              <InputNumber min={1} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            {pointStyle === 'Point' ?
              (<Form.Item
                label={t('outline-width')}
                className="form-horizontal"
                name="pointOutlineWidth">
                <InputNumber min={0} step={1} />
              </Form.Item>) : ''
            }
          </Col>
        </Row>
      </Form.Item>
      <Divider orientation="left" orientationMargin="0">
        {t('line-style')}
      </Divider>
      <Form.Item style={{ marginBottom: 0 }}>
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={t('color')}
              className="form-horizontal"
              initialValue={'#ffffff'}
              name="lineMaterial">
              <input type="color" className='custom-input-color' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={t('transparency')}
              name="lineAlpha">
              <Slider
                style={{ marginRight: '7px' }}
                min={0}
                max={1.0}
                step={0.1}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={`${t('width')} (px)`}
              className="form-horizontal"
              name="lineWidth">
              <InputNumber min={1} step={1} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Divider orientation="left" orientationMargin="0">
        {t('polygon-style')}
      </Divider>
      <Form.Item>
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t('color')}
              className="form-horizontal"
              initialValue={'#ffffff'}
              name="polygonMaterial">
              <input type="color" className='custom-input-color' />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t('outline-color')}
              className="form-horizontal"
              initialValue={'#ffffff'}
              name="polygonOutlineColor">
              <input type="color" className='custom-input-color' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              label={t('transparency')}
              name="polygonAlpha">
              <Slider
                style={{ marginRight: '7px' }}
                min={0.0}
                max={1.0}
                step={0.1}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t('elevation')}
              className="form-horizontal"
              name="polygonHeight">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t('extrusion')}
              className="form-horizontal"
              name="polygonExtrudedHeight">
              <InputNumber min={0} step={1} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      {
        projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager") &&
        <Button
          title=''
          type="primary"
          icon={<SaveOutlined />}
          htmlType='submit'
          style={{ position: 'absolute', top: '10px' }}
        >
          {t('commons.save')}
        </Button>
      }
      <Tooltip title={t('commons.reset')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
        <Button
          title=''
          loading={resetState.loading}
          disabled={resetState.isDisabled}
          type="default"
          shape="circle"
          onClick={onReset}
          style={{ position: 'absolute', left: (projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager")) ? '99px' : '10px', top: '10px' }}
          icon={<ReloadOutlined />}></Button>
      </Tooltip>
    </Form>
  )
}
const { Panel } = Collapse
const ModalUpdateResources = ({ projectStore, commonStore, viewer, organizationStore, projectSettingStore, usersStore }) => {
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  const [fileList, setFileList] = useState([])
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false) // loading used when click button save model
  const [uploadTab, setUploadTab] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [startString, setStartString] = useState(false)
  const [endDate, setEndDate] = useState(null)
  const [endString, setEndString] = useState(false)
  const onChangeTab = activeKey => {
    projectStore.setCurrentAddTab(activeKey)
  }

  useEffect(() => {
    if (projectStore.showUpdateResourceModel) { // get center screen for process upload (model position)      
      if (!viewer) return
      let centerScreen = getCenterScreenCoordinates(viewer)
      if (!centerScreen || !viewer.camera) return
      projectStore.setCenterData(centerScreen)
    }
    setFileList([])
    return () => {
      setFileList([])
      projectStore.setProgressUploadFile(0)
      projectStore.setProgressFileOnS3Ion(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStore.showUpdateResourceModel])

  useEffect(() => {
    return () => {
      setStartDate(null)
      setEndDate(null)
      setStartString('')
      setEndString('')
    }
  }, [projectStore.selectedNode])

  const handleChangeFile = e => {
    setFileList(e.fileList)
  }


  /**
   * Add 3Dtile
   * @returns 
   */
  const ExternalForm = () => {
    const [form] = Form.useForm();
    const [resetState, setResetState] = useState({ loading: false, isDisabled: false });
    const [isPointcloud, set3DtileIsPointcloud] = useState(false)
    const [validJson, setValidJson] = useState(false)

    const onReset = async () => {
      setResetState({ loading: true, isDisabled: true }) // disable button reset
      if (projectStore.selectedNode) {
        const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model) {
          if (model.startDate) {
            const localstartDate = moment.utc(model.startDate).local().format()
            setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
          }

          if (model.endDate) {
            const localendDate = moment.utc(model.endDate).local().format()
            setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
          }
          setStartString(model.startDate)
          setEndString(model.endDate)
          form.setFieldsValue({ name: '' });
          form.setFieldsValue({ src: '' });
          form.setFieldsValue({ detailLevel: 16 });

          setTimeout(() => {
            form.setFieldsValue({ name: model.name });
            form.setFieldsValue({ src: `${model.src}` });
            form.setFieldsValue({ detailLevel: model.data?.detailLevel || 16 });
            set3DtileIsPointcloud(model.data.is3DTilesPointcloud ? model.data.is3DTilesPointcloud : false)
            setResetState({ loading: false, isDisabled: false })
          }, 500); // disable button reset
        }
      }
    }

    useEffect(() => {
      if (projectStore.selectedNode) {
        const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model) {
          setValidJson(true)
          form.setFieldsValue({ name: model.name });
          form.setFieldsValue({ src: `${model.src}` });
          form.setFieldsValue({ detailLevel: model.data?.detailLevel || 16 });
          set3DtileIsPointcloud(model.data.is3DTilesPointcloud ? model.data.is3DTilesPointcloud : false)
        }
      }
    }, [projectStore.selectedNode])

    function roundNumber(value) {
      if (!isNaN(value)) {
        return Math.round(value)
      }
    }

    const handleSubmit = async values => {
      if (endString && startString) {
        if (moment(endString).isBefore(startString)) {
          alert(t('you-have-to-set-start-date-before-end-date'))
          return
        }
      }
      projectStore.setLoadingProgress(true)
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {},
        project: projectStore.projectDetail._id,
        sourceType: 'external',
      }
      newModel.data.detailLevel = values.detailLevel
      newModel.data.is3DTilesPointcloud = isPointcloud
      newModel.startDate = startDate
      newModel.endDate = endDate
      if (!isUrl(values.src)) {
        newModel.data.ionAssetId = parseInt(values.src)
      } else {
        // newModel.src = values.src.replace(/^https?\:\/\//i, '')
        if (values.src.includes('xd-visuals.com')) {
          newModel.src = values.src.replace(/^https?\:\/\//i, '')
        } else if (Utils.getDomain(values.src, true) === window.location.host) {
          // case check if url same with domain (copy public json in edit model)
          let projectId = values.src.split('/')[3];
          let hash = values.src.split('/')[4];
          let modelname = values.src.split("/").pop();
          let jsonlink = await getOriginPublicJsonLink(projectStore, commonStore.token, projectId, hash, modelname)
          newModel.src = jsonlink
        } else {
          newModel.src = values.src
        }
      }
      await projectStore
        .updateVersion(projectStore.selectedNode.modelId, newModel)
        .then(async (model) => {
          // Add to tree
          const newNode = {
            title: model.name,
            key: uuid(),
            type: 'FILE',
            modelId: model._id,
            sourceType: model.sourceType,
            modelType: model.type,
            useService: model.useService,
            hash: model.hash,
            src: model.src,
            isDeleted: false,
            endDate: model.endDate,
            startDate: model.startDate
          }
          await editTreeNode(newNode, projectStore.projectDetail.treeData);
          // End Add to tree
          projectStore.setCurrentModelId(false)
          const index = projectStore.modelList.findIndex(x => x._id === projectStore.selectedNode.modelId)
          const temp = [...projectStore.modelList]
          temp.splice(index, 1)
          projectStore.setModelList(temp)
          projectStore.setDeleteIds([projectStore.selectedNode.modelId])
          model.ref = false;
          temp.push(model)
          projectStore.setModelList(temp)
          projectStore.setNewModelId(model._id)
          projectStore.setSelectedModel(
            projectStore.modelList[projectStore.modelList.length - 1]
          )
        })
        .then(() => {
          projectStore.setLoadingProgress(false)
          onCancel();
          message.success(t('data-added-to-project'), 5)
        })
        .catch(err => {
          projectStore.setLoadingProgress(false)
          console.log('err', err)
          notification.open({
            message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
            description: t('something-went-wrong-when-creating-model'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }

    useEffect(() => {
      form.setFieldsValue({ detailLevel: 16 })
      return () => {
        set3DtileIsPointcloud(false)
        setValidJson(false)
      }
    }, [])

    const onChangeLink = (src) => {
      if (!src) return
      fetch(src,
        {
          headers: {
            // 'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*'
          }
        }
      ).then(function (response) {
        return response.json();
      }).then(function (myJson) {
        setValidJson(true)
        if (myJson.root && myJson.root.content) {
          let key = Object.keys(myJson.root.content)[0];
          let value = myJson.root.content[key];
          if (value?.split) {
            let ext = value.split('.').pop();
            if (ext === 'pnts') {
              set3DtileIsPointcloud(true)
            }
          }
        }
      }).catch(function (error) {
        setValidJson(false)
        notification.open({
          message: t('invalid-url'),
          icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          duration: 5,
        })
        return
      });
    }

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={<span>{t('name')} {resetState.loading && <Spin indicator={loadingIcon} />}</span>}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-external-tileset-or-ion-asset-id'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={<span>Link {resetState.loading && <Spin indicator={loadingIcon} />}</span>}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-external-link'),
            },
          ]}>
          <Input onChange={(e) => { onChangeLink(e.target.value) }} />
        </Form.Item>
        <Form.Item
          label={t('detail-level-multiplier')}
          name="detailLevel"
        >
          <InputNumber min={0.025} max={128} step={0.025} placeholder={t('detail-level-multiplier')} style={{ width: '100%' }} />
        </Form.Item>
        <Button
          disabled={!validJson}
          type="primary"
          loading={loading}
          icon={<SaveOutlined />}
          htmlType='submit'
          style={{ position: 'absolute', top: '10px' }}
        >
          {t('commons.save')}
        </Button>
        <Button
          loading={resetState.loading}
          disabled={resetState.isDisabled}
          type="default"
          shape="circle"
          onClick={onReset}
          style={{ position: 'absolute', left: '99px', top: '10px' }}
          icon={<ReloadOutlined />}></Button>
      </Form>
    )
  }

  const CesiumIonForm = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [resetState, setResetState] = useState({ loading: false, isDisabled: false });

    function roundNumber(value) {
      if (!isNaN(value)) {
        return Math.round(value)
      }
    }

    const onReset = () => {
      setResetState({ loading: true, isDisabled: true })
      if (projectStore.selectedNode) {
        const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model) {
          if (model.startDate) {
            const localstartDate = moment.utc(model.startDate).local().format()
            setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
          }

          if (model.endDate) {
            const localendDate = moment.utc(model.endDate).local().format()
            setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
          }
          setStartString(model.startDate)
          setEndString(model.endDate)
          form.setFieldsValue({ name: '' });
          form.setFieldsValue({ src: '' });
          form.setFieldsValue({ cesiumToken: '' });
          form.setFieldsValue({ detailLevel: 16 });
          setTimeout(() => {
            form.setFieldsValue({ name: model.name });
            form.setFieldsValue({ src: `${model.data.ionAssetId}` });
            form.setFieldsValue({ cesiumToken: model.data.cesiumToken });
            form.setFieldsValue({ detailLevel: model.data?.detailLevel || 1 });
            setResetState({ loading: false, isDisabled: false })
          }, 500);
        }
      }
    }
    useEffect(() => {
      if (projectStore.selectedNode) {
        const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model) {
          form.setFieldsValue({ name: model.name });
          form.setFieldsValue({ src: `${model.data.ionAssetId}` });
          form.setFieldsValue({ cesiumToken: model.data.cesiumToken });
          form.setFieldsValue({ detailLevel: model.data?.detailLevel || 1 });
        }
      }
    }, [projectStore.selectedNode])

    const handleSubmit = async values => {
      if (endString && startString) {
        if (moment(endString).isBefore(startString)) {
          alert(t('you-have-to-set-start-date-before-end-date'))
          return
        }
      }
      projectStore.setLoadingProgress(true)
      setLoading(true)
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {
          cesiumToken: values.cesiumToken
        },
        project: projectStore.projectDetail._id,
        sourceType: 'external',
      }
      newModel.startDate = startDate
      newModel.endDate = endDate
      newModel.data.detailLevel = values.detailLevel
      if (!isUrl(values.src)) {
        newModel.data.ionAssetId = parseInt(values.src)
      } else {
        // newModel.src = values.src.replace(/^https?\:\/\//i, '')
        if (values.src.includes('xd-visuals.com')) {
          newModel.src = values.src.replace(/^https?\:\/\//i, '')
        } else if (Utils.getDomain(values.src, true) === window.location.host) {
          // case check if url same with domain (copy public json in edit model)
          let projectId = values.src.split('/')[3];
          let hash = values.src.split('/')[4];
          let modelname = values.src.split("/").pop();
          let jsonlink = await getOriginPublicJsonLink(projectStore, commonStore.token, projectId, hash, modelname)
          newModel.src = jsonlink
        } else {
          newModel.src = values.src
        }
      }
      projectStore
        .updateVersion(projectStore.selectedNode.modelId, newModel)
        .then(async (model) => {
          // Add to tree
          const newNode = {
            title: model.name,
            key: uuid(),
            type: 'FILE',
            modelId: model._id,
            sourceType: model.sourceType,
            modelType: model.type,
            useService: model.useService,
            hash: model.hash,
            src: model.src,
            endDate: model.endDate,
            startDate: model.startDate
          }
          await editTreeNode(newNode, projectStore.projectDetail.treeData);
          // End Add to tree
          projectStore.setCurrentModelId(false)
          const index = projectStore.modelList.findIndex(x => x._id === projectStore.selectedNode.modelId)
          const temp = [...projectStore.modelList]
          temp.splice(index, 1)
          projectStore.setModelList(temp)
          projectStore.setDeleteIds([projectStore.selectedNode.modelId])
          model.ref = false;
          temp.push(model)
          projectStore.setModelList(temp)
          projectStore.setNewModelId(model._id)
          projectStore.setSelectedModel(
            projectStore.modelList[projectStore.modelList.length - 1]
          )
        })
        .then(() => {
          projectStore.setLoadingProgress(false)
          setLoading(false)
          onCancel();
          message.success(t('data-added-to-project'), 5)
        })
        .catch(err => {
          projectStore.setLoadingProgress(false)
          setLoading(false)
          console.log('err', err)
          notification.open({
            message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
            description: t('something-went-wrong-when-creating-model'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }
    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={<span>{t('name')} {resetState.loading && <Spin indicator={loadingIcon} />}</span>}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={<span>{t('cesium-ion-asset-id')} {resetState.loading && <Spin indicator={loadingIcon} />}</span>}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-ion-asset-id'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={<span>{t('cesium-ion-access-token')} {resetState.loading && <Spin indicator={loadingIcon} />}</span>}
          name="cesiumToken"
          rules={[
            {
              required: true,
              message: t('please-input-ion-access-token'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('detail-level-multiplier')}
          name="detailLevel"
        >
          <InputNumber min={0.025} max={128} step={0.025} placeholder={t('detail-level-multiplier')} style={{ width: '100%' }} />
        </Form.Item>
        <Button
          type="primary"
          loading={loading}
          icon={<SaveOutlined />}
          htmlType='submit'
          style={{ position: 'absolute', top: '10px' }}
        >
          {t('commons.save')}
        </Button>
        <Button
          loading={resetState.loading}
          disabled={resetState.isDisabled}
          type="default"
          shape="circle"
          onClick={onReset}
          style={{ position: 'absolute', left: '99px', top: '10px' }}
          icon={<ReloadOutlined />}></Button>
      </Form>
    )
  }

  const City3DForm = () => {
    const [form] = Form.useForm();
    const [resetState, setResetState] = useState({ loading: false, isDisabled: false });

    const onReset = async () => {
      setResetState({ loading: true, isDisabled: true }) // disable button reset
      const initData = async () => {
        const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model) {
          if (model.startDate) {
            const localstartDate = moment.utc(model.startDate).local().format()
            setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
          }

          if (model.endDate) {
            const localendDate = moment.utc(model.endDate).local().format()
            setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
          }
          setStartString(model.startDate)
          setEndString(model.endDate)
          form.setFieldsValue({
            name: model.name,
            src: model.src,
          });
        }
      }

      await initData();
      setResetState({ loading: false, isDisabled: false }) //enable button reset
    }

    const handleSubmit = async values => {
      if (endString && startString) {
        if (moment(endString).isBefore(startString)) {
          alert(t('you-have-to-set-start-date-before-end-date'))
          return
        }
      }
      projectStore.setLoadingProgress(true)
      setLoading(true)
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        project: projectStore.projectDetail._id,
        hash: '',
        data: {},
        sourceType: 'City3DDB',
        src: values.src,
        startDate: startDate,
        endDate: endDate
      }

      let _updateModel = projectStore.visibleTilesets.find(c => c.modelId === projectStore.selectedNode.modelId)

      const model = await projectStore.updateVersion(projectStore.selectedNode.modelId, newModel);

      const newNode = {
        title: model.name,
        key: uuid(),
        type: 'FILE',
        modelId: model._id,
        sourceType: model.sourceType,
        modelType: model.type,
        useService: model.useService,
        hash: model.hash,
        src: model.src,
        isDeleted: false,
        endDate: model.endDate,
        startDate: model.startDate
      }

      await editTreeNode(newNode, projectStore.projectDetail.treeData);

      projectStore.setCurrentModelId(false)


      model.ref = false;
      model.isUpdate = true;
      model.isVisible = _updateModel.isVisible

      const index = projectStore.modelList.findIndex(x => x._id === model._id)
      const temp = [...projectStore.modelList]
      temp.map(item => {
        delete item.isUpdate
        return item
      })
      temp[index] = model;

      projectStore.setModelList(temp)
      projectStore.setNewModelId(model._id)
      projectStore.setSelectedModel(projectStore.modelList[projectStore.modelList.length - 1])

      message.success(t('tileset-uploaded-successfully'))
      projectStore.setShowEditLocation(false, false)
      projectStore.setDisplayPanel(true)
      projectStore.setChangeModelStyle()
      projectStore.setLoadingProgress(false)
      setLoading(false)
      onCancel();
    }

    useEffect(() => {
      if (projectStore.selectedNode) {
        const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model) {
          form.setFieldsValue({
            name: model.name,
            src: model.src,
          });
        }
      }
    }, [projectStore.selectedNode])

    return (
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          style={{ display: 'block' }}
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input placeholder={t('name')} />
        </Form.Item>
        <Form.Item
          label="URL"
          name="src"
          style={{ display: 'block' }}
          rules={[
            {
              required: true,
              message: t('please-input-url'),
            },
          ]}>
          <Input placeholder="URL" />
        </Form.Item>
        {
          projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager") &&
          <Button
            type="primary"
            loading={loading}
            icon={<SaveOutlined />}
            htmlType='submit'
            style={{ position: 'absolute', top: '10px' }}
          >
            {t('commons.save')}
          </Button>
        }
        <Button
          loading={resetState.loading}
          disabled={resetState.isDisabled}
          type="default"
          shape="circle"
          onClick={onReset}
          style={{ position: 'absolute', left: (projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager")) ? '99px' : '10px', top: '10px' }}
          icon={<ReloadOutlined />}></Button>
      </Form>
    )
  }

  const FileForm = () => {
    const [form] = Form.useForm();
    const [resetState, setResetState] = useState({ loading: false, isDisabled: false });
    const onReset = async () => {
      setResetState({ loading: true, isDisabled: true }) // disable button reset
      const initData = async () => {
        const model = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
        if (model) {
          if (model.startDate) {
            const localstartDate = moment.utc(model.startDate).local().format()
            setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
          }

          if (model.endDate) {
            const localendDate = moment.utc(model.endDate).local().format()
            setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
          }
          setStartString(model.startDate)
          setEndString(model.endDate)
        }
      }

      await initData();
      setResetState({ loading: false, isDisabled: false })
    }


    const handleSubmit = async () => {
      if (endString && startString) {
        if (moment(endString).isBefore(startString)) {
          alert(t('you-have-to-set-start-date-before-end-date'))
          return
        }
      }
      projectStore.setLoadingProgress(true)
      setLoading(true)
      let newModel = {
        project: projectStore.projectDetail._id,
      }
      newModel.startDate = startDate
      newModel.endDate = endDate
      const updateModel = (newModel) => {
        projectStore
          .updateVersion(projectStore.selectedNode.modelId, newModel)
          .then(async (model) => {
            // Add to tree
            const newNode = {
              title: model.name,
              key: uuid(),
              type: 'FILE',
              modelId: model._id,
              sourceType: model.sourceType,
              modelType: model.type,
              src: model.src,
              endDate: model.endDate,
              startDate: model.startDate
            }
            await editTreeNode(newNode, projectStore.projectDetail.treeData);
            projectStore.setCurrentModelId(false)
            const index = projectStore.modelList.findIndex(x => x._id === projectStore.selectedNode.modelId)
            const temp = [...projectStore.modelList]
            temp.splice(index, 1)
            projectStore.setModelList(temp)
            // projectStore.setDeleteIds([projectStore.selectedNode.modelId])
            model.ref = false;
            temp.push(model)
            projectStore.setModelList(temp)
            projectStore.setNewModelId(model._id)
            projectStore.setSelectedModel(
              projectStore.modelList[projectStore.modelList.length - 1]
            )
          })
          .then(() => {
            projectStore.setLoadingProgress(false)
            setLoading(false)
            onCancel();
            message.success(t('data-added-to-project'), 5)
          })
          .catch(err => {
            setLoading(false)
            projectStore.setLoadingProgress(false)
            console.log('err', err)
            notification.open({
              message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
              description: t('something-went-wrong-when-creating-model'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          })
      }

      updateModel(newModel);

    }


    return (
      <Form form={form} onFinish={handleSubmit}>
        {!projectStore.isEditModel && <UploadWrapper>
          <Dragger
            fileList={fileList}
            multiple={false}
            accept=".xml,.ifc,.gltf,.glb,.zip,.obj,.dae,.fbx,.laz,.las,.kmz,.kml,.tiff,.tif,.geojson"
            onChange={e => handleChangeFile(e)}
            beforeUpload={beforeUpload}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t('click-or-drag-file-to-this-area-to-upload')}
            </p>
            <p className="ant-upload-hint" style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
              {t('file-support')}: .xml,.ifc,.gltf,.glb,.zip,.obj,.dae,.fbx, .laz,.las,.kmz,.kml,.tiff,.tif,.geojson
            </p>
          </Dragger>
          <StepContainer>
            <Steps
              direction="vertical"
              size="small"
              current={step}
              progressDot={true}>
              <Step title={t('select-file-from-computer')} />
              <Step
                title={
                  <div>
                    {t('upload-to-server')} &nbsp;{' '}
                    {step === 1 ? <Spin indicator={loadingIcon} /> : ''}
                  </div>
                }
                description={step === 1 ? <>
                  <div className='colorStyle'>{t('the-file-is-currently-being-uploaded')}</div>
                  <Progress percent={projectStore.progressUploadFile} />
                </> : step === 2 ? t('the-file-is-already-uploaded') : t('file-will-be-uploaded-to-our-server')}
              />
              <Step
                title={
                  <div>
                    {t('data-processing')} &nbsp;{' '}
                    {step === 2 ? <Spin indicator={loadingIcon} /> : ''}
                  </div>
                }
                description={<>
                  <div className='colorStyle'>
                    {projectStore.progressFileOnS3Ion.message ? projectStore.progressFileOnS3Ion.message : t('wait-for-the-server-to-process-the-file')}
                  </div>
                  {projectStore.progressFileOnS3Ion.done > -1 ? <Progress percent={projectStore.progressFileOnS3Ion.done} /> : ''}
                </>}
              />
              <Step title={t('finish')} description={<div className='colorStyle'>{t('back-to-model-listing-page')}</div>} />
            </Steps>
          </StepContainer>
        </UploadWrapper>}
        {
          projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager") &&
          <Button
            type="primary"
            loading={loading}
            icon={<SaveOutlined />}
            htmlType='submit'
            style={{ position: 'absolute', top: '10px' }}
          >
            {t('commons.save')}
          </Button>
        }
        <Button
          loading={resetState.loading}
          disabled={resetState.isDisabled}
          type="default"
          shape="circle"
          onClick={onReset}
          style={{ position: 'absolute', left: (projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager")) ? '99px' : '10px', top: '10px' }}
          icon={<ReloadOutlined />}></Button>
      </Form>
    )
  }

  const FolderForm = () => {
    const [form] = Form.useForm();
    const [resetState, setResetState] = useState({ loading: false, isDisabled: false });

    const initData = async () => {
      const node = TreeUtils.searchTreeNode(projectStore.projectDetail.treeData, 'key', projectStore.selectedNode.key);
      if (node) {
        if (node.startDate) {
          const localstartDate = moment.utc(node.startDate).local().format()
          setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
        }

        if (node.endDate) {
          const localendDate = moment.utc(node.endDate).local().format()
          setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
        }
        setStartString(node.startDate)
        setEndString(node.endDate)
      }
    }

    const onReset = async () => {
      setResetState({ loading: true, isDisabled: true })
      await initData();
      setResetState({ loading: false, isDisabled: false })
    }


    const handleSubmit = async () => {
      if (endString && startString) {
        if (moment(endString).isBefore(startString)) {
          alert(t('you-have-to-set-start-date-before-end-date'))
          return
        }
      }
      projectStore.setLoadingProgress(true)
      setLoading(true)
      const newNode = {
        ...projectStore.selectedNode,
        startDate,
        endDate
      }
      await editTreeNode(newNode, projectStore.projectDetail.treeData);
      projectStore.setLoadingProgress(false)
      setLoading(false)
      onCancel();
      projectStore.setSelectedNode(newNode)
    }
    return (
      <Form form={form} onFinish={handleSubmit}>
        {
          projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager") &&
          <Button
            type="primary"
            loading={loading}
            icon={<SaveOutlined />}
            htmlType='submit'
            style={{ position: 'absolute', top: '10px' }}
          >
            {t('commons.save')}
          </Button>
        }
        <Button
          loading={resetState.loading}
          disabled={resetState.isDisabled}
          type="default"
          shape="circle"
          onClick={onReset}
          style={{ position: 'absolute', left: (projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager")) ? '99px' : '10px', top: '10px' }}
          icon={<ReloadOutlined />}></Button>
      </Form>
    )
  }

  useEffect(() => {
  }, [projectStore.progressFileOnS3Ion])

  useEffect(() => {
    if (projectStore.selectedNode && projectStore.showUpdateResourceModel) {
      const { sourceType, type, key } = projectStore.selectedNode;
      if (sourceType === 'local') {
        setUploadTab(true)
      } else {
        setUploadTab(false)
      }
      let node = null
      if (type === 'FOLDER') {
        node = TreeUtils.searchTreeNode(projectStore.projectDetail.treeData, 'key', key);
      } else {
        node = projectStore.modelList.find(model => model._id === projectStore.selectedNode.modelId)
      }
      if (node) {
        if (node.startDate) {
          const localstartDate = moment.utc(node.startDate).local().format()
          setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
        }

        if (node.endDate) {
          const localendDate = moment.utc(node.endDate).local().format()
          setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
        }
        setStartString(node.startDate)
        setEndString(node.endDate)
      } else {
        setStartDate(null)
        setEndDate(null)
        setStartString('')
        setEndString('')
      }
    }
  }, [projectStore.selectedNode,projectStore.showUpdateResourceModel])

  function onChangeStart(date, dateString) {
    setStartDate(date)
    setStartString(dateString)
  }

  function onChangeEnd(date, dateString) {
    setEndDate(date)
    setEndString(dateString)
  }

  const beforeUpload = async (files) => {
    let _modelId = projectStore.selectedNode.modelId
    let _title = projectStore.selectedNode?.title
    let fileUploadSize = parseFloat(files.size) / 1024 / 1024
    const formData = new FormData()
    formData.append('files', files)
    setStep(1)
    projectStore.setLoadingProgress(true)
    projectStore.setProgressUploadFile(0);
    projectStore.setProgressFileOnS3Ion(false)
    const organization = await organizationStore.getCurrentOrganizationById(projectStore.projectDetail.organization._id)
    const licenseInfos = projectStore.getCurrentProjectStorageQuota(organization)
    const presignedS3Url = await projectStore.generatePreSignedPutUrl({ fileName: convertExtToLowerCase(files.name), projectId: projectStore.projectDetail._id })
    let config = {
      method: 'put',
      url: presignedS3Url.url,
      headers: {
        'Content-Type': getMimeOfFile(files.name)
      },
      data: files,
      onUploadProgress: event => projectStore.setProgressUploadFile(Number(((100 * event.loaded) / event.total).toFixed(2)))
    };
    let ext = presignedS3Url.file.split('.').pop()
    files.hash = presignedS3Url.file.split('.').shift()
    files.ext = `.${ext}`
    files.url = `${ASSET_URL}${presignedS3Url.file}`
    files.id = `${ASSET_URL}${presignedS3Url.file}`
    if (licenseInfos.totalQuota) {
      if (fileUploadSize <= Number.MAX_VALUE) {
        axios(config)
          .then(async (f) => {
            setStep(2)
            projectStore.setProgressUploadFile(0);
            projectStore.setProgressFileOnS3Ion({ message: t('wait-for-the-server-to-process-the-file'), done: -1 })
            const file = files
            const newModel = {
              name: _title ? _title : (file.name ? file.name : "Unnamed"),
              src: file.url,
              hash: file.hash,
              data: {
                ext: file.ext,
                id: file.id,
                size: file.size,
              },
              project: projectStore.projectDetail._id,
              sourceType: 'local',
            }

            // we will use ifcSetting as tiling setting for both landxml and ifc
            if (newModel.data.ext && ['.ifc','.xml','.las','.laz'].includes(newModel.data.ext.toLowerCase())) {
              newModel.data.ifcSetting = projectSettingStore.systemProjectSetting.ifcSetting
            }

            // license type convert IFCEngineUsage
            if (projectStore.projectDetail.organization) {
              newModel.org = projectStore.projectDetail.organization.id
            }
            await projectStore
              .updateVersion(_modelId, newModel)
              .then(async (model) => {                
                if (model?.data?.ionAssetId) {
                  let isUpdateConten = await updateContenModelIon(model, file)
                  if (!isUpdateConten) {
                    notification.open({
                      message: t('an-error-occurred-when-creating-model-data'),
                      description: (
                        <>
                          `${t('file')}`: <b>{newModel.name}</b><br />
                          `${t('unable-update-ion-model-content')}`
                        </>
                      ),
                      icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                      duration: 0,
                    })
                    setStep(0)
                    setFileList([])
                    projectStore.setLoadingProgress(false)
                    projectStore.setProgressUploadFile(0)
                    projectStore.setProgressFileOnS3Ion(false)
                    return false
                  }
                }
                projectStore.setDeleteIds([model._id]) // remove old model
           
                if (model.crs['Use IFCEngine'] !== undefined && model.crs['Use IFCEngine'] === 'True') {
                  await create_ifcengineusage(projectStore.projectDetail.organization._id, projectStore.projectDetail._id, model, usersStore.currentUser._id)
                }

                // Update Project tilesetdata and Model data return current model
                let rsp_model = await updateProjectModel(model.project, model)
                let _project = rsp_model.project

                // merge All model
                if (_project?.tilesetData.RefPoint && _project.tilesetData.coordinateSystem && (!['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'metre')) {
                  let _model3ds = _project.model3DS.map(c => {
                    let u = { ...c }
                    if (c.id === model.id) {
                      u.newUpdate = true
                    }
                    return u;
                  })
                  let modelmerge = await mergeAllModels(
                    _model3ds,
                    Cartesian3.fromDegrees(_project.tilesetData.RefPoint[1], _project.tilesetData.RefPoint[0], _project.tilesetData.RefPoint[2]),
                    _project.tilesetData.refLocalProject ? Cartesian3.fromArray(_project.tilesetData.refLocalProject) : false,
                    _project.tilesetData.georeferenced !== undefined ? _project.tilesetData.georeferenced : false,
                    _project.headingRotation,
                    _project.tilesetData.coordinateSystem.code,
                    _project.elevationSystem ? _project.elevationSystem : 'None'
                  );
                  _project.model3DS = modelmerge // reassign list model to project
                }

                // update project TreeData
                let _treeData = await updateProjectTreeData(_project._id, model, projectStore.projectDetail.treeData, projectStore.selectedNode?.accesscontrols ? projectStore.selectedNode?.accesscontrols : false)
                _project.treeData = _treeData

                /**Update FE projectStore.ProjectDetail, projectStore.modelList */
                await projectStore.updateProjectRefPoint(_project)

                // effect projectStore.modelList only load model no model.ref => incorrect
                // after merge all models => position of model change => need set setNewModelId to effect updatePlaneMatix
                projectStore.setCurrentModelId(false)
                projectStore.setNewModelId(model._id)
                message.success(t('tileset-uploaded-successfully'), 5)

                projectStore.setLoadingProgress(false)
              }).catch(err => {
                if (err?.data?.message) {
                  notification.open({
                    message: t('an-error-occurred-when-creating-model-data'),
                    description: t(err.data.message),
                    icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                    duration: 0,
                  })
                } else {
                  notification.open({
                    message: t('an-error-occurred-when-creating-model-data'),
                    description: t('something-went-wrong-when-update-model'),
                    icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                    duration: 0,
                  })
                }
                setStep(0)
                setFileList([])
                projectStore.setLoadingProgress(false)
                projectStore.setProgressUploadFile(0)
                projectStore.setProgressFileOnS3Ion(false)
              }).finally(() => {
                setStep(3)
                setFileList([])
                projectStore.setProgressUploadFile(0)
                projectStore.setProgressFileOnS3Ion(false)
                onCancel();
              });
          }).catch(err => {
            notification.open({
              message: t('an-error-occurred-when-upload-file'),
              description: t('something-went-wrong-when-uploading-file'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
            setStep(0)
            setFileList([])
            projectStore.setLoadingProgress(false)
            projectStore.setProgressUploadFile(0)
          })
      } else {
        projectStore.setLoadingProgress(false)
        setStep(0)
        setFileList([])
        projectStore.setProgressUploadFile(0)
        projectStore.setProgressFileOnS3Ion(false)
        onCancel();
        message.error(t('file-size-is-too-large'))
      }

    } else {
      message.error(t('your-license-is-expried-please-update-your-license'))
    }

    return false
  }

  const editTreeNode = async (child, data) => {
    let toNode;
    if (projectStore.selectedNode.parentKey) {
      const parrentNode = TreeUtils.searchTreeNode(data, 'key', projectStore.selectedNode.parentKey);
      toNode = parrentNode.children;
    } else {
      toNode = data;
    }
    // need for update treedata
    const node = toNode.find(child => child.key === projectStore.selectedNode.key);
    if (node) {
      node.title = child.title;
      node.key = child.key;
      node.type = child.type;
      node.modelId = child.modelId;
      node.sourceType = child.sourceType;
      node.modelType = child.modelType;
      node.useService = child.useService;
      node.hash = child.hash;
      node.endDate = child.endDate;
      node.startDate = child.startDate;
    }

    projectStore.setLoadingProgress(true);
    await projectStore.updateProjectTreeData({ treeData: data, store: 'treedata' }).then((res) => {
      projectStore.projectDetail.treeData = res.treeData;
    }).catch(err => {
      console.log(err)
      message.error(t('edit-file-failed'))
    }).finally(() => {
      projectStore.setLoadingProgress(false);
    })
  }

  const renderTab = () => {
    const { sourceType, modelId, type } = projectStore.selectedNode
    const model = projectStore.modelList.find(model => model._id === modelId)
    if (type === 'FOLDER') {
      return (
        <TabPane
          className="tab-local"
          tab={
            <span>
              <FileZipOutlined />
              {t('folder')}
            </span>
          }
          key="folder"
        >
          <FolderForm />
        </TabPane>
      )
    }
    if (sourceType === 'local') {
      return (
        <TabPane
          className="tab-local"
          tab={
            <span>
              <FileZipOutlined />
              {t('file')}
            </span>
          }
          key="uploadTab"
        >
          <FileForm />
        </TabPane>
      )
    } else if (sourceType === 'external' && !model?.data?.cesiumToken) {
      return (
        <TabPane
          tab={
            <span>
              <CodeSandboxOutlined />
              3DTiles
            </span>
          }
          key="externalLink"
        >
          <ExternalForm />
        </TabPane>
      )
    } else if (sourceType === 'external' && model?.data?.cesiumToken) {
      return (
        <TabPane
          tab={
            <span>
              <GlobalOutlined />
              Cesium Ion
            </span>
          }
          key="cesiumIon"
        >
          <CesiumIonForm />
        </TabPane>
      )
    } else if (sourceType === 'WMS') {
      return (
        <TabPane
          tab={
            <span>
              <SketchOutlined />
              WMS
            </span>
          }
          key="WMS"
        >
          <WMSForm projectStore={projectStore} editTreeNode={editTreeNode} onCancel={onCancel} startDate={startDate}
            endDate={endDate}
            setStartDate={(e) => setStartDate(e)}
            setEndDate={(e) => setEndDate(e)}
            startString={startString}
            endString={endString}
            setStartString={(e) => setStartString(e)}
            setEndString={(e) => setEndString(e)} />
        </TabPane>
      )
    } else if (sourceType === 'WFS') {
      return (
        <TabPane
          tab={
            <span>
              <SketchOutlined />
              WFS
            </span>
          }
          key="WFS"
        >
          <WFSForm projectStore={projectStore} editTreeNode={editTreeNode} onCancel={onCancel} startDate={startDate}
            endDate={endDate}
            setStartDate={(e) => setStartDate(e)}
            setEndDate={(e) => setEndDate(e)}
            startString={startString}
            endString={endString}
            setStartString={(e) => setStartString(e)}
            setEndString={(e) => setEndString(e)} />
        </TabPane>
      )
    } else if (sourceType === 'City3DDB') {
      return (
        <TabPane
          tab={
            <span>
              <SketchOutlined />
              {t('3d-city-db')}
            </span>
          }
          key="City3DDB"
        >
          <City3DForm />
        </TabPane>
      )
    }
  }

  const onCancel = () => {
    projectStore.setShowUpdateResourceModel(false)
    projectStore.setDisplayPanel(true)
    setStartDate(null)
    setEndDate(null)
    setStartString('')
    setEndString('')
  }
  const HeaderPanel = ({ text }) => (
    <LocationPanelHeader>{text}</LocationPanelHeader>
  )
  return (
    <UpdateWmsPanel
      width={460}
      centered={true}
      // onCancel={onCancel}
      visible={projectStore.showUpdateResourceModel}
      title={t('update-resources')}
      icon={<BuildOutlined />}
      iconType="build"
    >
      <Popconfirm
        title={
          <span>
            <Trans i18nKey="are-you-sure-you-want-to-leave" components={[<br />]} />
          </span>
        }
        onConfirm={onCancel}
        okText={t('commons.yes')}
        cancelText={t('commons.no')}
      >
        <Tooltip title={t('commons.exit')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <Button
            type="default"
            style={uploadTab ? { position: 'absolute', right: 5, top: 5, zIndex: 1 } : { marginBottom: 10, marginLeft: projectStore.currentUserRoleInProject && (projectStore.currentUserRoleInProject === "project_owner" || projectStore.currentUserRoleInProject === "project_manager") ? 129 : 40, marginTop: 2 }}
            shape="circle"
            icon={<CloseOutlined />}>
          </Button>
        </Tooltip>
      </Popconfirm>
      <Tabs activeKey={projectStore.currentAddTab} onChange={onChangeTab}>
        {projectStore.selectedNode && projectStore.showUpdateResourceModel ? renderTab() : null}
      </Tabs>
      {
        projectStore.isEditModel && <Collapse
          defaultActiveKey={['4d-scheduling']}
          expandIconPosition={'right'}>
          <Panel header={<HeaderPanel text={t('4d-scheduling')} />} key="4d-scheduling">
            <Row>
              <Col span={6} style={{ paddingTop: 10 }}>
                {t('start')}
              </Col>
              <Col span={18}>
                <DatePicker
                  locale={{
                    ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en),
                    lang: {
                      ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en).lang,
                      now: t('now'),
                      ok: t('commons.ok'),
                    }
                  }}
                  showTime
                  placeholder={t('select-start-time')}
                  value={startDate}
                  onChange={onChangeStart}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ paddingTop: 10 }}>
                {t('end')}
              </Col>
              <Col span={18} style={{ paddingTop: 10 }}>
                <DatePicker
                  locale={{
                    ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en),
                    lang: {
                      ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en).lang,
                      now: t('now'),
                      ok: t('commons.ok'),
                    }
                  }}
                  showTime
                  placeholder={t('select-end-time')}
                  value={endDate}
                  onChange={onChangeEnd}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      }
    </UpdateWmsPanel>
  )

}

export default withRouter(inject('projectStore', 'commonStore', 'organizationStore', 'projectSettingStore', 'usersStore')(observer(ModalUpdateResources)))