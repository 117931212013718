import styled from 'styled-components'

export const TableWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px; 
`
export const TableHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .title { 
    font-size: 22px;
    color: #000;
    font-weight: 500;
    text-align: center;
  }
  .action {
    display: flex;
    .ant-btn {
      height: 32px;
    }
  }
`
export const CardFooter = styled.footer`
  padding: 15px 15px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .text {
    font-size: 30px;
    line-height: 33px;
    text-align: center;    
    margin-top: 20%;
  }
`

export const Container = styled.div`
  max-width: 1140px;  
  padding: 0px 15px;
  margin-right: auto;
  margin-left: auto;
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-bottom: 90px;
`