import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Table } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ChangeRoleUserInOrganization from './ChangeRoleUserInOrganization';
import InviteMemberToOganizationModal from './InviteMemberToOganizationModal';
import { TableHeading, TableWrapper, Toolsbox } from './style';
import { toJS } from 'mobx';

const OrganizationUsersTab = props => {
  const { t } = useTranslation();
  const { organizationStore, usersBelongOrganizationData, licensesBelongOrganizationData, history, match, adminStore, commonStore, sketchingStore } = props;
  const [usersBelongOrganizationList, setUsersBelongOrganizationList] = useState(usersBelongOrganizationData)
  const organizationId = organizationStore.paramsOrganizationDetail
  moment.locale(`${commonStore.language}`)

  useEffect(() => {
    organizationStore.getOrganizationRoles()
    adminStore.getRoleCurrentUserInOrganization(organizationId)
    setUsersBelongOrganizationList(usersBelongOrganizationData)
  }, [usersBelongOrganizationData])

  const handleRemoveUserFromOrganization = (projectuserrolesId) => {
    adminStore.setLoadingProgress(true)
    organizationStore.removeUserFromOrganization(projectuserrolesId).then(res => {
      adminStore.setLoadingProgress(false)
      if (res?.data?.status === 404) {
        message.error(t(res?.data?.message) || "something-went-wrong")
      } else {
        organizationStore.setForceReload(1)
        message.success(t('remove-user-from-organization-succcess'))
      }
    }).catch(error => {
      adminStore.setLoadingProgress(false)
      if (error.data && error?.data?.statusCode === 403) {
        message.error(t('you-do-not-have-permission'))
        history.push(`/organizations`)
      } else {
        message.error(t('an-error-occurred'))
      }
    })
  }

  const handleClickShowInviteUserToOrganizationModel = () => {
    organizationStore.setLoadingProgress(true)
    organizationStore.getOrganizationRoles().then(() => {
      organizationStore.setLoadingProgress(false)
    }).catch((error) => {
      organizationStore.setLoadingProgress(false)
    })
    organizationStore.toggleShowInviteUserToOrganizationModal(true)
  }

  const getNameUser = (obj) => {
    let _v = obj?.user
    if (!_v) {
      return obj.email ? obj.email : ''
    } else {
      return (
        _v && _v.firstName && _v.lastName ?
          `${_v.firstName} ${_v.lastName}` : _v.firstName && !_v.lastName ?
            _v.firstName : !_v.firstName && _v.lastName ?
              _v.lastName : obj.email
      )
    }
  }

  const getJoined = (record) => {
    return record.inviteStatus === 'accepted' ? (
      record.acceptedDate ? record.acceptedDate : record.createdAt
    ) : ''
  }
  
  const getUserName = (record, obj) => {
    return record && record.username ? record.username : obj ? obj.email : "user-not-found"
  }

  const renderMessagePopupConfirm = (record) => {
    if (record?.organizationrole?.type === 'org_admin') {
      let arrAdmins = usersBelongOrganizationList.filter(c => c?.organizationrole?.type === 'org_admin');
      return arrAdmins?.length <= 1 ? t('delete-last-admin') : t('are-you-sure-you-want-to-remove-this-user-from-organization')
    } else {
      return t('are-you-sure-you-want-to-remove-this-user-from-organization')
    }
  }

  const tableColumns = [
    {
      title: t('username'),
      dataIndex: 'user',
      key: 'username',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a && b ? getUserName(a.user, a).localeCompare(getUserName(b.user, b)) : true,
      render: (record, obj) => {
        return getUserName(record, obj) === "user-not-found" ? <span style={{ color: '#ff0000' }}>{t("user-not-found")}</span> : getUserName(record, obj)
      }
    },
    {
      title: t('name'),
      dataIndex: 'user',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a && b ? getNameUser(a).localeCompare(getNameUser(b)) : true,
      render: (record, obj) => {
        return (<span>{getNameUser(obj)}</span>)
      }
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: "email",
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (record, obj) => {
        return (record && obj.user ? obj.user.email : <span>{record}</span>)
      }
    },
    {
      title: t('role'),
      dataIndex: 'organizationrole',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.organizationrole.name.localeCompare(b.organizationrole.name),
      render: record => {
        return (record && record.name ? record.name : <span style={{ color: '#ff0000' }}>Role not found</span>)
      }
    },
    {
      title: t('status'),
      dataIndex: 'inviteStatus',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.inviteStatus.localeCompare(b.inviteStatus),
      render: record => {
        return (t(record))
      }
    },
    {
      title: t('joined'),
      width: 150,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = getJoined(a) ? new Date(getJoined(a)) : distantFuture
        let dateB = getJoined(b) ? new Date(getJoined(b)) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      key: "createdAt",
      render: record => {
        return (
          <div className={'tileset-name'}>
            {getJoined(record) ? moment(getJoined(record)).format('MMMM DD, YYYY') : ''}
          </div>
        )
      }
    },
    {
      title: t('last-logged-in'),
      width: 150,
      key: "Lastloggedin",
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = a?.user?.lastLoggin ? new Date(a?.user?.lastLoggin) : distantFuture
        let dateB = b?.user?.lastLoggin ? new Date(b?.user?.lastLoggin) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: (record, obj) => {
        let _v = obj?.user
        if (!_v) {
          return <span></span>
        } else {
          return (_v.lastLoggin ? <div className={'tileset-name'}>{moment(_v.lastLoggin).format('MMMM DD, YYYY')}</div> : '')
        }
      }
    },
    {
      title: t('action'),
      key: 'action',
      align: 'center',
      render: (record) => {
        // return (record.user && record.user.id === record.organization.user || (adminStore.roleCurrentUserInOrganization && adminStore.roleCurrentUserInOrganization.role !== "Admin") ? '' : (
        return ((adminStore.roleCurrentUserInOrganization && adminStore.roleCurrentUserInOrganization.role !== "Admin") ? '' : (
          <>
            <Button
              onClick={() => adminStore.setShowChangeRoleUserModal(true)}
              icon={<EditOutlined />} style={{ margin: '2px' }} />
            <Popconfirm
              title={renderMessagePopupConfirm(record)}
              onConfirm={() => handleRemoveUserFromOrganization(record.id)}
              okText={t('commons.yes')}
              cancelText={t('commons.no')}
            >
              <Button type="danger" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
        )
      }
    }
  ]
  return (
    <TableWrapper>
      <TableHeading>
        <div className="title">{t('users-belong-to-organization')}</div>
        {
          adminStore.roleCurrentUserInOrganization && adminStore.roleCurrentUserInOrganization.role === "Admin" ? (
            <Toolsbox>
              <Button type={'primary'} onClick={handleClickShowInviteUserToOrganizationModel}>
                <PlusOutlined />{t('invite-member')}
              </Button>
            </Toolsbox>
          ) : ''
        }

      </TableHeading>
      <Table
        pagination={false}
        rowKey={record => record.id}
        columns={tableColumns}
        scroll={{ x: 600 }}
        dataSource={usersBelongOrganizationList}
        onRow={(record) => ({
          onClick: (event) => {
            return adminStore.setCurrentUserRoleDetailInOrganization(record)
          },
        })}
        locale={{ emptyText: t('no-data') }}
      />
      <InviteMemberToOganizationModal licensesBelongOrganizationData={licensesBelongOrganizationData} organizationId={organizationId} organizationRoles={organizationStore.organizationRoles} />
      <ChangeRoleUserInOrganization />
    </TableWrapper>
  )
}
export default withRouter(inject('organizationStore', 'adminStore', 'sketchingStore', 'commonStore')(observer(OrganizationUsersTab)))