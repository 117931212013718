import React, { useState } from 'react';
import TopDashBoardItem from './TopDashBoardItem';
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'antd';
import { toJS } from 'mobx'
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const TopDashBoard = (props) => {
    const { t } = useTranslation();
    const { projectStore, dashboardStore, usersStore } = props
    const [viewusers, setViewusers] = useState(0);
    const [models, setModels] = useState({ model3dUploaded: 0, model3dUpdated: 0 });
    const [topics, setTopics] = useState({ topicUploaded: 10, topicUpdated: 20 });
    const [averageTime, setAverageTime] = useState(0);

    useEffect(() => {
        if (dashboardStore.projectDetail.viewusers) {
            let userIds = []
            let listUsers = dashboardStore.projectDetail.viewusers
            listUsers.map(v => userIds.push(v.id))
            setViewusers(listUsers.length)
            if (usersStore.currentUser) {
                let found = listUsers.find(el => el.id === usersStore.currentUser.id)
                if (!found) {
                    setViewusers(listUsers.length + 1)
                    userIds.push(usersStore.currentUser.id)
                    dashboardStore.updateProjectData(dashboardStore.projectDetail.id, { viewusers: userIds })
                }
            }
        }
        if (dashboardStore.projectDetail.model3DS) {
            let model3dUploaded = dashboardStore.projectDetail.model3DS
            let model3dUpdated = model3dUploaded.filter(el => el.isUpdate === true)
            setModels({
                model3dUploaded: model3dUploaded.length,
                model3dUpdated: model3dUpdated.length
            })
        }

        if (dashboardStore.topicListBelongProject) {
            let topicUploaded = dashboardStore.topicListBelongProject
            let topicUpdated = topicUploaded.filter(el => el.isUpdate === true)
            let totalTime
            let _curTime = 0
            let hourMilliseconds = 60 * 60 * 1000
            setTopics({
                topicUploaded: topicUploaded.length,
                topicUpdated: topicUpdated.length
            })
            topicUploaded.map(elm => {
                if (elm.topicphases) {
                    elm.topicphases.map(v => {
                        let startDate = v.startDate ? v.startDate : 0
                        let completedDate = v.completedDate ? v.completedDate : 0
                        let r = startDate !== 0 && completedDate !== 0 ? Math.abs(new Date(startDate).getTime() - new Date(completedDate).getTime()) : 0
                        _curTime += r
                    })
                }
            })
            totalTime = Number(((_curTime / hourMilliseconds) / topicUploaded.length).toFixed(2))
            setAverageTime(totalTime)
        }

        return () => {
            setViewusers(0)
            setModels({ model3dUploaded: 0, model3dUpdated: 0 })
            setTopics({ topicUploaded: 0, topicUpdated: 0 })
            setAverageTime(0)
        }
    }, [dashboardStore.projectDetail, dashboardStore.topicListBelongProject])


    return (
        <>
            <Row gutter={[48, 24]} >
                <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="topdashboard-item" >
                    <TopDashBoardItem
                        count={viewusers}
                        title={t('views')}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="topdashboard-item" >
                    <TopDashBoardItem
                        count={` ${models.model3dUploaded}/${models.model3dUpdated} `}
                        title={t('models')}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="topdashboard-item" >
                    <TopDashBoardItem
                        count={`${topics.topicUploaded}/${topics.topicUpdated}`}
                        title={t('topics')}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={6} xxl={6} className="topdashboard-item" >
                    <TopDashBoardItem
                        count={averageTime}
                        title={t('speed')}
                    />
                </Col>
            </Row>
        </>
    );
}

export default withRouter(
    inject(
        'projectStore',
        'dashboardStore',
        "usersStore"
    )(observer(TopDashBoard))
)