import { CloseCircleOutlined, FileTextOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import '../style.css';

const ChangeRoleName = (props) => {
    const { hanldeReloadFromModal, handleCloseModal, adminStore } = props;
    const { t } = useTranslation();
    let CusmtomFormAddUser = () => {
        const [form] = Form.useForm();
        const [isLoading, setLoading] = useState(false);

        useEffect(() => {
            form.setFieldsValue({
                description: adminStore.currentProjectRole.roleDescription,
                roleName: adminStore.currentProjectRole.roleName
            })
            return () => {
                form.resetFields()
            }
        }, [])

        const handleChangeProjectRole = (v) => {
            setLoading(true)
            adminStore.updateProjectRole(adminStore.currentProjectRole.id, v)
                .then(res => {
                    message.success(t('change-project-role-successfully'))
                    form.resetFields()
                    hanldeReloadFromModal(true)
                    handleCloseModal(false)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    message.error(t('an-error-occurred'))
                    setLoading(false)
                })
        }
        return (
            <>
                <Form
                    form={form}
                    layout="vertical"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={value => handleChangeProjectRole(value)}
                >
                    <Row
                        justify="end"
                    >
                        <Button
                            onClick={() => handleCloseModal(false)}
                            icon={<CloseCircleOutlined />}
                        >
                            {t('commons.cancel')}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: "10px" }}
                            loading={isLoading}
                            icon={<SaveOutlined />}
                        >
                            {t('commons.save')}
                        </Button>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={t('role-name')}
                                name="roleName"
                                rules={[
                                    {
                                        required: true,
                                        message: t('please-input-role-name')
                                    }
                                ]}
                            >
                                <Input
                                    prefix={<FileTextOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="description" label={t('description')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('please-input-role-description')
                                    }
                                ]}>
                                <Input
                                    prefix={<FileTextOutlined className="site-form-item-icon" />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </>
        )
    }
    CusmtomFormAddUser = inject("adminStore")(observer(CusmtomFormAddUser));
    return (
        <Fragment>
            <CusmtomFormAddUser></CusmtomFormAddUser>
        </Fragment>
    )
}
export default inject("adminStore")(observer(ChangeRoleName));
