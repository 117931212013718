import styled from 'styled-components'

export const TableWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px; 
`
export const TableHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  .title { 
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
  .action {
    display: flex;
    .ant-btn {
      height: 32px;
    }
  }
`
export const CardFooter = styled.footer`
  padding: 15px 15px 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  &:before{ 
    display: block;
    content: '';
    height: 1px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    transform: translateY(-100%);
  }
  &:after {
    display: block;
    content: '';
    height: 1px;
    background-color: #E3E5E5;  
    position: absolute;
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
  }
`