import styled from 'styled-components'

export const VRContainer = styled.div`
  .ant-btn {
    border: 1px solid #000;
    width: 32px !important;
    height: 32px !important;
    margin-right: 5px;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`