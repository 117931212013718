import { ArrowLeftOutlined, CloseOutlined, ColumnWidthOutlined } from '@ant-design/icons';
import { Button, Drawer, message, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import ModalChangePasswordUser from './ModalChangePasswordUser';
import './style.css';
import UserSettingsForm from './UserSettingsForm';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import HelpButton from '../../elements/HelpButton';
import { HeaderProfileWrapper } from './UserSettingsPageStyled';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../elements/LoadingSpinner/LoadingSpinner';
import { useIsMounted } from '../../helper/useIsMounted'

const UserSettingsPage = (props) => {
  const { commonStore, usersStore } = props;

  const { t } = useTranslation();
  const isTabletOrMobile = isMobile || isTablet
  const [drawerWidth, setDrawerWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0)
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const { token } = useParams()
  const history = useHistory()
  const isMounted = useIsMounted()

  useEffect(() => {
    const getUserDetail = async () => {
      if (isMounted()) setIsLoading(true)
      try {
        await usersStore.getCurrentUser().then(() => {
          if (isMounted()) setIsLoading(false)
        })
      } catch {
        if (isMounted()) setIsLoading(false)
      }
    }
    if (token) {
      commonStore.setToken(token)
      getUserDetail()
    } else {
      // getUserDetail()
    }
  }, [token])

  useEffect(() => {
    if (usersStore.currentUser) {
      usersStore.setUserAvatar(usersStore.currentUser.avatar)
    }
  }, [usersStore.currentUser])

  useEffect(() => {
    if (commonStore.showUserSettingPageDrawer) {
      setDrawerWidth(isTabletOrMobile ? 320 : 600)
      usersStore.getCurrentUser()
    }
    return () => {
      usersStore.setUserAvatar({})
    }
  }, [commonStore.showUserSettingPageDrawer])

  //#region event for mouse and touch to resize drawer panel
  const handleTouchStart = e => {
    e.preventDefault();
    if (e.touches.length !== 1) return null;
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd, { passive: false });
    document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
  };


  const handleTouchMove = useCallback(e => {
    let touch = e.touches[0] || e.changedTouches[0];
    let target = document.elementFromPoint(touch.clientX, touch.clientY);
    let newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
    let minDrawerWidth = 360;
    if (newWidth < 0) {
      setDrawerWidth(10);
    }
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, { passive: false });
    document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
    document.removeEventListener("touchmove", handleTouchMove, { passive: false });
  };

  const handleMouseDown = e => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(e => {
    let offsetRight = e.clientX - document.body.offsetLeft;
    let minDrawerWidth = 360;
    if (offsetRight > minDrawerWidth) {
      setDrawerWidth(offsetRight);
    }
  }, []);
  //#endregion

  // (changeSize) change drawerWidth when drawerWidth > screenWidth
  const handleChangeScreenSize = () => {
    setScreenWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleChangeScreenSize) // force reload when screenWidth change

  useEffect(() => {
    if (drawerWidth > screenWidth) {
      setDrawerWidth(screenWidth)
    }
  }, [screenWidth])
  // end changeSize

  const hiddenDrawer = () => {
    commonStore.setShowUserSettingPageDrawer(false)
  };

  const handleChangePassword = (data) => {
    usersStore.setLoadingProgress(true)
    usersStore.changePasswordUser(data).then(res => {
      usersStore.setLoadingProgress(false)
      if (res.data.status === "200") {
        setShowModalChangePassword(false)
        message.success(t("password-change-success"))
      } else {
        message.error(t(res.data.message))
      }
    })
      .catch(error => {
        console.log(error)
      })
  }


  return (
    <div>
      <Drawer
        getContainer={false}
        title={commonStore.showUserSettingPageDrawer ?
          <HeaderProfileWrapper>
            <div className="help-btn-wrapper">
              <ArrowLeftOutlined className="arrow-back" onClick={() => commonStore.setShowUserSettingPageDrawer(false)} />
              {t('profile')}
              <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <div>
                  <HelpButton helppage={'profile'} />
                </div>
              </Tooltip>
            </div>
            <CloseOutlined
              style={{ top: "19px", paddingRight: "10px" }}
              className="closeOrganizationButton"
              onClick={() => hiddenDrawer()}
            />
          </HeaderProfileWrapper>
          : ""
        }
        width={drawerWidth}
        placement="left"
        closable={false}
        visible={commonStore.showUserSettingPageDrawer}
        destroyOnClose={true}
        className={
          commonStore.showUserSettingPageDrawer ?
            "organization-drawer  custom-wraper-splitPanel"
            : ""
        }
      >
        {
          isTabletOrMobile ? (

            <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnOrganizationPanel btnSplitPanel" >
              <ColumnWidthOutlined />
            </Button>

          ) : (<div onMouseDown={e => handleMouseDown(e)} className="organizationPanel" />)
        }
        {
          commonStore.showUserSettingPageDrawer && (
            <UserSettingsForm setShowModalChangePassword={v => setShowModalChangePassword(v)} hiddenDrawer={() => hiddenDrawer()} userAvatar={usersStore.userAvatar} currentUser={usersStore.currentUser} />
          )
        }
      </Drawer >
      <ModalChangePasswordUser handleChangePassword={v => handleChangePassword(v)} cancelModal={() => setShowModalChangePassword(false)} showModalChangePassword={showModalChangePassword} />
      {history?.location?.pathname?.includes('myprofile') && !isLoading ? <UserSettingsForm setShowModalChangePassword={v => setShowModalChangePassword(v)} hiddenDrawer={() => hiddenDrawer()} userAvatar={usersStore.userAvatar} currentUser={usersStore.currentUser} paddingForm={40} /> : <div></div>}
      {isLoading ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} /> : <div></div>}
    </div>
  );
}
export default withRouter(inject('commonStore', 'usersStore')(observer(UserSettingsPage)))