import {
  DownOutlined,
  LeftOutlined,
  LoginOutlined,
  SettingOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import { Button, Col, Dropdown, Menu } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  TopMenuWrapper,
  ButtonDownAdminHeader,
  MenuIconMobile,
  CustomMenuIconMobile,
} from '../style'
import { Trans, useTranslation } from 'react-i18next'
import { UserOutlined } from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'

const TopMenu = ({
  usersStore,
  commonStore,
  adminStore,
  onCollapse,
  triggerSider,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { currentPage } = commonStore
  const handleLogout = () => {
    usersStore.userLogout().then(() => history.push('/auth/login'))
  }
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const clearStatusRedirect = () => {
    adminStore.setRiderect(false)
    adminStore.setCreateMode(false)
    adminStore.setEditMode(false)
  }
  const handleRedirectLayout = () => {
    clearStatusRedirect()
    switch (currentPage) {
      case 'admin/organization':
        return history.push(
          `/admin/organizations/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      case 'organizationusersrole':
        return history.push('/admin/organizationusersrole')
      case 'editfeatureroles':
        return history.push('/admin/featureroles')
      case 'admin/licenses':
        return history.push(`/admin/licenses/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`)
      case 'admin/users':
        return history.push(
          `/admin/users/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      case 'admin/licensetypes':
        return history.push(`/admin/licensetypes/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`)
      case 'notification':
        return history.push('/admin/notification')
      case 'generic-email-notification':
        return history.push('/admin/generic-email-notification')
      case 'ifcengineusage':
        return history.push(
          `/admin/ifcengineusage/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      case 'legal':
        return history.push('/admin/legal')
      default:
        return
    }
  }

  const menu = (
    <Menu style={{ marginTop: '-3.85px' }}>
      <Menu.Item key="account" icon={<UserOutlined />}>
        {t('account')}
      </Menu.Item>
      <Menu.Item key="user" icon={<LoginOutlined />}>
        {usersStore.currentUser &&
        usersStore.currentUser.provider === 'cognito' ? (
          <a
            href={`${process.env.REACT_APP_COGNITO_DOMAIN}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URL}`}
            rel="noopener noreferrer">
            {t('logout')}
          </a>
        ) : (
          <a onClick={() => handleLogout()} rel="noopener noreferrer">
            {t('logout')}
          </a>
        )}
      </Menu.Item>
    </Menu>
  )
  const checkMobile = () => {
    if (isMobile) {
      return adminStore.isToggleMenuMobile ? (onCollapse ? 80 : 200) : 80
    } else {
      return onCollapse ? 80 : 200
    }
  }
  const toggle = () => {
    let _collapsed = !adminStore.collapsed
    adminStore.setcollapsed(_collapsed)
    triggerSider(_collapsed)
  }

  return (
    <TopMenuWrapper left={checkMobile()}>
      <Col style={{ display: 'flex' }}>
        {!isMobile && (
          adminStore.collapsed && (<CustomMenuIconMobile onClick={toggle}>
            <MenuOutlined style={{ margin: '16px 18px' }} />
          </CustomMenuIconMobile>)
        )}

        {adminStore.isRiderect ? (
          <Button
            style={{
              height: '100%',
              border: 'none',
            }}
            icon={<LeftOutlined />}
            onClick={() => handleRedirectLayout()}>
            {t('back')}
          </Button>
        ) : null}
      </Col>

      <Col>
        <Dropdown overlay={menu}>
          <ButtonDownAdminHeader className="button-down-header">
            {usersStore.currentUser?.avatar?.url ? (
              <img
                src={usersStore.currentUser?.avatar?.url}
                className="image-user-header-top"
              />
            ) : (
              <UserOutlined />
            )}
            {usersStore.currentUser?.username && usersStore.currentUser.username}
            <DownOutlined className="icon-down-header" />
          </ButtonDownAdminHeader>
        </Dropdown>
      </Col>
    </TopMenuWrapper>
  )
}
export default inject(
  'usersStore',
  'commonStore',
  'adminStore'
)(observer(TopMenu))
