import React from 'react'
import styled from 'styled-components'

export const OrganizationEditWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 15px;
 .ant-upload.ant-upload-drag{
    width: 150px;
    min-height: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
 }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    min-height : 150px;
    padding : 0 ;
  }
`
export const OrganizationViewWrapper = styled.div`
.contact-information{
  .ant-row{
    width : 100%;
  }
  .contact-title{
    width : 100%;
    color: '#000';
    font-size: 14;
    font-weight: 500;
    margin: 10px 0 5px 0 ;
  }
  text-align : left ;
}
`

export const CardHeader = styled.header`
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  align-items: center;
  padding: 20px 15px 23px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .avatar {
    width: 95px;
    img {
      width: 100%;
    }
  }
  .info {
    width: calc(100% - 95px);
    padding-left: 10px;
    .name {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      position: relative;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .action {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        line-height: 22px;
        font-size: 10px;
        text-align: center;
        color: white;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .org-image {
    .image-wrapper{
      width : 150px ;
      height : 150px;
      img{
        width : 150px;
        height : 150px;
        object-fit : cover;
      }
  }
 .fl1{
  flex-grow : 1;
  padding-left : 15px;
}
  }
`
export const CardFooter = styled.footer`
  padding: 15px 15px 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  &:before{ 
    display: block;
    content: '';
    height: 1px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    transform: translateY(-100%);
  }
  &:after {
    display: block;
    content: '';
    height: 1px;
    background-color: #E3E5E5;  
    position: absolute;
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
  }
`
export const List = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  dt {
    width: 130px;
    position: relative;
    padding-left: 0px;
    .color-svg {
      position: absolute;
      left: 0;
    }
  }
  dd {
    width: calc(100% - 130px);
    padding-left: 15px;
    span {
      overflow: hidden;
      display: block;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
  dt, dd {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const LogoWrapper = styled.div`
position: relative;
  .delete-icon{
    display: none;
    cursor: pointer;
    position: absolute;
    top: 3px;
    left: 120px;
    z-index: 999;
    background: none;
  }
  &:hover .delete-icon{
    display: block;
  }
`