import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, InputNumber, message, Row, Slider } from 'antd';
import { Math as CesiumMath } from 'cesium';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { SettingRenderResolutionContainer } from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
import HelpButton from '../elements/HelpButton';
import { toJS } from 'mobx';

const SettingRenderResolutionControl = ({ projectStore, projectSettingStore, usersStore, viewer }) => {
  const { t } = useTranslation();
  const [browserRecommendedResolution, setBrowserRecommendedResolution] = useState(true)
  const [renderResolutionValue, setInputValue] = useState(1.0)
  const [maximumScreenSpaceError, setMaximumScreenSpaceError] = useState(16)
  const [gmlmaximumScreenSpaceError, setgmlMaximumScreenSpaceError] = useState(100)
  const [maxCountOfVisibleTiles, setMaxCountOfVisibleTiles] = useState(200)
  const [geometricErrorScale, setGeometricErrorScale] = useState(1)
  const [maximumAttenuation, setMaximumAttenuation] = useState(1)
  const [loading, setLoading] = useState(false)
  const [_maximumScreenSpaceError, _setMaximumScreenSpaceError] = useState()
  const [_gmlmaximumScreenSpaceError, _setgmlMaximumScreenSpaceError] = useState()
  const [_maxCountOfVisibleTiles, _setmaxCountOfVisibleTiles] = useState()
  const [_geometricErrorScale, _setGeometricErrorScale] = useState(1) 
  const [_maximumAttenuation, _setMaximumAttenuation] = useState(5)

  const [eyeDomeLightingStrength, setEyeDomeLightingStrength] = useState(1)
  const [_eyeDomeLightingStrength, _setEyeDomeLightingStrength] = useState(1)

  const [eyeDomeLightingRadius, setEyeDomeLightingRadius] = useState(1)
  const [_eyeDomeLightingRadius, _setEyeDomeLightingRadius] = useState(1)

  const onChange = value => {
    setInputValue(value)
    projectSettingStore.setRenderResolution('resolutionScale', value)
  }

  const onChangemaximumScreenSpaceError = value => {
    setMaximumScreenSpaceError(value)
    projectStore.setMaximumScreenSpaceError(value)
    projectSettingStore.setRenderResolution('maximumScreenSpaceError', value)
    //debounceonChangemaximumScreenSpaceError(value)
  }
  const ongmlChangemaximumScreenSpaceError = value => {
    setgmlMaximumScreenSpaceError(value)
    projectStore.setgmlMaximumScreenSpaceError(value)
    projectSettingStore.setRenderResolution('gmlmaximumScreenSpaceError', value)
    //debounceonChangemaximumScreenSpaceError(value)
  }

  // setMaxCountOfVisibleTiles
  const onChangemaxCountOfVisibleTiles = value => {
    setMaxCountOfVisibleTiles(value)
    projectStore.setMaxCountOfVisibleTiles(value)
    projectSettingStore.setRenderResolution('maxCountOfVisibleTiles', value)
  }
  const onChangeMaximumAttenuation = value => {
    setMaximumAttenuation(value)
    projectStore.setMaximumAttenuation(value)
    projectSettingStore.setRenderResolution('maximumAttenuation', value)
  }
  const onChangeGeometricErrorScale = value => {
    setGeometricErrorScale(value)
    projectStore.setGeometricErrorScale(value)
    projectSettingStore.setRenderResolution('geometricErrorScale', value)
  }
  const onChangeCheck = (e) => {
    setBrowserRecommendedResolution(e.target.checked)
    projectSettingStore.setRenderResolution('useBrowserRecommendedResolution', e.target.checked)
  }

  const onChangeEyeDomeLightingStrength = value => {
    setEyeDomeLightingStrength(value)
    projectStore.setEyeDomeLightingStrength(value)
    projectSettingStore.setRenderResolution('eyeDomeLightingStrength', value)
  }
  const onChangeEyeDomeLightingRadius = value => {
    setEyeDomeLightingRadius(value)
    projectStore.setEyeDomeLightingRadius(value)
    projectSettingStore.setRenderResolution('eyeDomeLightingRadius', value)
  }

  useEffect(() => {
    if (projectStore.eyeDomeLightingRadius) {
      //_setMaximumScreenSpaceError(projectStore.maximumScreenSpaceError)
      setEyeDomeLightingRadius(projectStore.eyeDomeLightingRadius ? projectStore.eyeDomeLightingRadius : 1)
    }
  }, [eyeDomeLightingRadius])

  useEffect(() => {
    if (projectStore.eyeDomeLightingStrength) {
      //_setMaximumScreenSpaceError(projectStore.maximumScreenSpaceError)
      setEyeDomeLightingStrength(projectStore.eyeDomeLightingStrength ? projectStore.eyeDomeLightingStrength : 1)
    }
  }, [eyeDomeLightingStrength])


  useEffect(() => {
    if (projectStore.maximumAttenuation) {
      //_setMaximumScreenSpaceError(projectStore.maximumScreenSpaceError)
      setMaximumAttenuation(projectStore.maximumAttenuation ? projectStore.maximumAttenuation : 5)
    }
  }, [maximumAttenuation])

  useEffect(() => {
    if (projectStore.geometricErrorScale) {
      //_setMaximumScreenSpaceError(projectStore.maximumScreenSpaceError)
      setGeometricErrorScale(projectStore.geometricErrorScale ? projectStore.geometricErrorScale : 1)
    }
  }, [geometricErrorScale])

  useEffect(() => {
    if (viewer.current.cesiumElement) {
      var resolutionScale = Number(renderResolutionValue);
      resolutionScale = !isNaN(resolutionScale) ? resolutionScale : 1.0;
      resolutionScale = CesiumMath.clamp(resolutionScale, 0.1, 2.0);
      viewer.current.cesiumElement.resolutionScale = resolutionScale;
    }
  }, [renderResolutionValue])

  useEffect(() => {
    if (viewer.current.cesiumElement) {
      viewer.current.cesiumElement.useBrowserRecommendedResolution = browserRecommendedResolution
    }
  }, [browserRecommendedResolution])

  useEffect(() => {
    if (projectStore.maximumScreenSpaceError) {
      //_setMaximumScreenSpaceError(projectStore.maximumScreenSpaceError)
      setMaximumScreenSpaceError(projectStore.maximumScreenSpaceError ? projectStore.maximumScreenSpaceError : 16)
    }
  }, [maximumScreenSpaceError])

  useEffect(() => {
    if (projectStore.gmlmaximumScreenSpaceError) {
      //_setMaximumScreenSpaceError(projectStore.maximumScreenSpaceError)
      setgmlMaximumScreenSpaceError(projectStore.gmlmaximumScreenSpaceError ? projectStore.gmlmaximumScreenSpaceError : 100)
    }
  }, [gmlmaximumScreenSpaceError])

  useEffect(() => {

    let data = projectSettingStore.systemProjectSetting
    setBrowserRecommendedResolution(data.renderResolution.useBrowserRecommendedResolution)
    setInputValue(data.renderResolution.resolutionScale)

    setMaximumScreenSpaceError(data.renderResolution.maximumScreenSpaceError)
    _setMaximumScreenSpaceError(projectStore.maximumScreenSpaceError)

    setgmlMaximumScreenSpaceError(data.renderResolution.gmlmaximumScreenSpaceError)
    _setgmlMaximumScreenSpaceError(projectStore.gmlmaximumScreenSpaceError)

    setMaxCountOfVisibleTiles(data.renderResolution.maxCountOfVisibleTiles)
    _setmaxCountOfVisibleTiles(projectStore.maxCountOfVisibleTiles)

    setMaximumAttenuation(data.renderResolution.maximumAttenuation)
    _setMaximumAttenuation(projectStore.maximumAttenuation)

    setGeometricErrorScale(data.renderResolution.geometricErrorScale)
    _setGeometricErrorScale(projectStore.geometricErrorScale)

    setEyeDomeLightingRadius(data.renderResolution.eyeDomeLightingRadius)
    _setEyeDomeLightingRadius(projectStore.eyeDomeLightingRadius)
    setEyeDomeLightingStrength(data.renderResolution.eyeDomeLightingStrength)
    _setEyeDomeLightingStrength(projectStore.eyeDomeLightingStrength)

  }, [projectSettingStore.systemProjectSetting])

  // useEffect(() => {
  //   if (maximumScreenSpaceError && typeof maximumScreenSpaceError === 'number') {
  //     projectStore.setMaximumScreenSpaceError(maximumScreenSpaceError)
  //   }
  // }, [maximumScreenSpaceError])

  const onSave = () => {
    setLoading(true)
    let _projectSetting = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    let _projectMetadata = projectStore.projectDetail.metadata
    _projectMetadata.renderResolution = {
      resolutionScale: renderResolutionValue,
      useBrowserRecommendedResolution: browserRecommendedResolution,
      maximumScreenSpaceError: maximumScreenSpaceError,
      gmlmaximumScreenSpaceError: gmlmaximumScreenSpaceError,
      maxCountOfVisibleTiles: maxCountOfVisibleTiles,
      maximumAttenuation: maximumAttenuation,
      eyeDomeLightingRadius: eyeDomeLightingRadius,
      eyeDomeLightingStrength: eyeDomeLightingStrength,
      geometricErrorScale: geometricErrorScale,
    }
    if (usersStore.currentUser?.id) {
      let metadata = Object.assign(_projectSetting, _projectMetadata)
      projectStore.updateProjectMetadata({ metadata }).then(() => {
        message.success(t('updated-successfully'))
        setLoading(false)
        projectStore.setCleanMode(false)
        projectStore.setShowRenderResolutionControl(false)
        projectStore.setMaximumScreenSpaceError(maximumScreenSpaceError)
        projectStore.setgmlMaximumScreenSpaceError(gmlmaximumScreenSpaceError)
        projectStore.setMaximumAttenuation(maximumAttenuation)
        projectStore.setGeometricErrorScale(geometricErrorScale)
        projectStore.setEyeDomeLightingRadius(eyeDomeLightingRadius)
        projectStore.setEyeDomeLightingStrength(eyeDomeLightingStrength)



        // projectStore.projectDetail.metadata.renderResolution = {
        //   ...projectStore.projectDetail.metadata.renderResolution,
        //   maximumScreenSpaceError: maximumScreenSpaceError,
        //   gmlmaximumScreenSpaceError: gmlmaximumScreenSpaceError,
        //   maxCountOfVisibleTiles: maxCountOfVisibleTiles,
        //   maximumAttenuation: maximumAttenuation,
        //   geometricErrorScale: geometricErrorScale,
        //   eyeDomeLightingRadius: eyeDomeLightingRadius,
        //   eyeDomeLightingStrength: eyeDomeLightingStrength,

        // }
      }).catch(err => {
        message.error(t('updated-failed'))
        setLoading(false)
        if (projectStore.projectDetail.metadata.renderResolution) {
          viewer.current.cesiumElement.resolutionScale = projectStore.projectDetail.metadata.renderResolution.resolutionScale
          viewer.current.cesiumElement.useBrowserRecommendedResolution = projectStore.projectDetail.metadata.renderResolution.useBrowserRecommendedResolution
          if (viewer.current.cesiumElement?.shadowMap) {
            viewer.current.cesiumElement.shadowMap.softShadows = projectStore.projectDetail.metadata?.renderResolution?.softShadows
            viewer.current.cesiumElement.shadowMap.size = projectStore.projectDetail.metadata?.renderResolution?.shadowAccuracy
          }


        } else {
          viewer.current.cesiumElement.resolutionScale = 1.0
          viewer.current.cesiumElement.useBrowserRecommendedResolution = false
          if (viewer.current.cesiumElement?.shadowMap) {
            viewer.current.cesiumElement.shadowMap.softShadows = false
            viewer.current.cesiumElement.shadowMap.size = 4096
          }
        }
        projectStore.setCleanMode(false)
        projectStore.setShowRenderResolutionControl(false)
      })
    } else {
      message.success(t('updated-successfully'))
      setLoading(false)
      projectStore.setCleanMode(false)
      projectStore.setShowRenderResolutionControl(false)
      projectStore.setMaximumScreenSpaceError(maximumScreenSpaceError)
      projectStore.setgmlMaximumScreenSpaceError(gmlmaximumScreenSpaceError)
      projectStore.setMaximumAttenuation(maximumAttenuation)
      projectStore.setGeometricErrorScale(geometricErrorScale)
      projectStore.setEyeDomeLightingRadius(eyeDomeLightingRadius)
      projectStore.setEyeDomeLightingStrength(eyeDomeLightingStrength)

      projectStore.projectDetail.metadata.renderResolution = {
        ...projectStore.projectDetail.metadata.renderResolution,
        maximumScreenSpaceError: maximumScreenSpaceError,
        gmlmaximumScreenSpaceError: gmlmaximumScreenSpaceError,
        maxCountOfVisibleTiles: maxCountOfVisibleTiles,
        maximumAttenuation: maximumAttenuation,
        geometricErrorScale: geometricErrorScale,
        eyeDomeLightingRadius: eyeDomeLightingRadius,
        eyeDomeLightingStrength: eyeDomeLightingStrength,
      }
      if (viewer.current.cesiumElement && viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }

  const onCancel = () => {
    projectStore.setMaximumScreenSpaceError(_maximumScreenSpaceError)
    projectStore.setgmlMaximumScreenSpaceError(_gmlmaximumScreenSpaceError)
    projectStore.setMaxCountOfVisibleTiles(_maxCountOfVisibleTiles)
    projectStore.setMaximumAttenuation(_maximumAttenuation)
    projectStore.setGeometricErrorScale(_geometricErrorScale)

    projectStore.setEyeDomeLightingRadius(_eyeDomeLightingRadius)
    projectStore.setEyeDomeLightingStrength(_eyeDomeLightingStrength)

    setMaximumScreenSpaceError(_maximumScreenSpaceError)
    setgmlMaximumScreenSpaceError(_gmlmaximumScreenSpaceError)
    setMaxCountOfVisibleTiles(_maxCountOfVisibleTiles)
    setMaximumAttenuation(_maximumAttenuation)
    setGeometricErrorScale(_geometricErrorScale)

    setEyeDomeLightingRadius(_eyeDomeLightingRadius)
    setEyeDomeLightingStrength(_eyeDomeLightingStrength)

    projectStore.setCleanMode(false)
    projectStore.setShowRenderResolutionControl(false)
    projectSettingStore.setRenderResolution('maximumScreenSpaceError', _maximumScreenSpaceError)
    projectSettingStore.setRenderResolution('gmlmaximumScreenSpaceError', _gmlmaximumScreenSpaceError)

    projectSettingStore.setRenderResolution('maxCountOfVisibleTiles', _maxCountOfVisibleTiles)
    projectSettingStore.setRenderResolution('maximumAttenuation', _maximumAttenuation)
    projectSettingStore.setRenderResolution('eyeDomeLightingRadius', _eyeDomeLightingRadius)
    projectSettingStore.setRenderResolution('eyeDomeLightingStrength', _eyeDomeLightingStrength)
    projectSettingStore.setRenderResolution('geometricErrorScale', _geometricErrorScale)

  }

  return (
    <SettingRenderResolutionContainer>
      <div className='help-btn-wrap'>
        <HelpButton helppage={"system_settings_render_setting"} />
      </div>
      <Row>
        <Col span={24}>
          <strong>{t('render-settings')}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={23}>
          {t('device-optimized')}
        </Col>
        <Col span={1}>
          <Checkbox checked={browserRecommendedResolution} onChange={onChangeCheck} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {t('render-resolution')}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Slider
            min={0.1}
            max={2.01}
            onChange={onChange}
            value={typeof renderResolutionValue === 'number' ? renderResolutionValue : 0.1}
            step={0.1}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            style={{ width: 'auto' }}
            min={0.1}
            max={2.0}
            value={renderResolutionValue}
            onChange={onChange}
            step={0.1}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {t('3dTile-detail-level')}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Slider
            min={1}
            max={128}
            reverse={true}
            onChange={onChangemaximumScreenSpaceError}
            value={typeof maximumScreenSpaceError === 'number' ? maximumScreenSpaceError : 16}
            step={1}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            style={{ width: 'auto' }}
            min={1}
            max={128}
            value={maximumScreenSpaceError}
            onChange={onChangemaximumScreenSpaceError}
            step={1}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {t('citygml-detail-level')}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Slider
            min={10}
            max={200}
            reverse={true}
            onChange={ongmlChangemaximumScreenSpaceError}
            value={typeof gmlmaximumScreenSpaceError === 'number' ? gmlmaximumScreenSpaceError : 100}
            step={10}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            style={{ width: 'auto' }}
            min={10}
            max={200}
            value={typeof gmlmaximumScreenSpaceError === 'number' ? gmlmaximumScreenSpaceError : 100}
            onChange={ongmlChangemaximumScreenSpaceError}
            step={10}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {t('citygml-object-limit')}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Slider
            min={10}
            max={5000}
            reverse={true}
            onChange={onChangemaxCountOfVisibleTiles}
            value={typeof maxCountOfVisibleTiles === 'number' ? maxCountOfVisibleTiles : 200}
            step={10}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            style={{ width: 'auto' }}
            min={10}
            max={5000}
            value={typeof maxCountOfVisibleTiles === 'number' ? maxCountOfVisibleTiles : 200}
            onChange={onChangemaxCountOfVisibleTiles}
            step={10}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {t('point-size')}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Slider
            min={1}
            max={32}
            onChange={onChangeMaximumAttenuation}
            value={typeof maximumAttenuation === 'number' ? maximumAttenuation : 5}
            step={1}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            style={{ width: 'auto' }}
            min={1}
            max={32}
            value={typeof maximumAttenuation === 'number' ? maximumAttenuation : 5}
            onChange={onChangeMaximumAttenuation}
            step={1}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {t('point-attenuation')}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Slider
            min={0}
            max={1}
            onChange={onChangeGeometricErrorScale}
            value={typeof geometricErrorScale === 'number' ? geometricErrorScale : 1}
            step={0.05}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            style={{ width: 'auto' }}
            min={0}
            max={1}
            value={typeof geometricErrorScale === 'number' ? geometricErrorScale : 1}
            onChange={onChangeGeometricErrorScale}
            step={0.05}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {t('point-contrast')}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Slider
            min={0}
            max={4}
            onChange={onChangeEyeDomeLightingStrength}
            value={typeof eyeDomeLightingStrength === 'number' ? eyeDomeLightingStrength : 1}
            step={0.05}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            style={{ width: 'auto' }}
            min={0}
            max={4}
            value={typeof eyeDomeLightingStrength === 'number' ? eyeDomeLightingStrength : 1}
            onChange={onChangeEyeDomeLightingStrength}
            step={0.05}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {t('point-edge')}
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Slider
            min={0}
            max={4}
            onChange={onChangeEyeDomeLightingRadius}
            value={typeof eyeDomeLightingRadius === 'number' ? eyeDomeLightingRadius : 1}
            step={0.05}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            style={{ width: 'auto' }}
            min={0}
            max={4}
            value={typeof eyeDomeLightingRadius === 'number' ? eyeDomeLightingRadius : 1}
            onChange={onChangeEyeDomeLightingRadius}
            step={0.05}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            icon={<CloseOutlined />}
            type="default"
            style={{ marginBottom: 10, marginRight: 5 }}
            onClick={onCancel}>
            {t('commons.cancel')}
          </Button>
          <Button
            loading={loading}
            type="primary"
            style={{ marginBottom: 10 }}
            icon={<SaveOutlined />}
            onClick={onSave}>
            {t('commons.save')}
          </Button>
        </Col>
      </Row>
    </SettingRenderResolutionContainer>
  )
}
export default inject('projectStore', 'projectSettingStore', 'usersStore')(observer(SettingRenderResolutionControl))