import styled from 'styled-components'

export const TeamsPageWrapper = styled.div`
  margin-top: 75px;
  display: block;
  padding: 15px;
  background-color: #fff;
`
export const TeamsPageHeading = styled.h1`
  font-size: 24px;
  color: #ff8d4f;
  text-align: center;
  margin-bottom: 60px;
`
export const ProjectMemberList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`
export const ProjectMemberCard = styled.li`
  display: block;
  padding: 15px;
  background-color: #f3f3f3;
  position: relative;
  transition: ease .3s;
  @media screen and (min-width: 769px) {
    width: 18%;
    margin-right: 2.5%;
    margin-bottom: 40px;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 40px;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    transition: ease .3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  }
  &:before {
    display: inline-block;
    content: '${props => props.badge}';
    position: absolute;
    bottom: 100%;
    left: 30px;
    background-color: ${props => props.currentUser ? '#f26524' : '#d0d0d7'};
    color: ${props => props.currentUser ? 'white' : '#191919'};
    font-size: 12px;
    text-transform: capitalize;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .card-avatar {
    text-align: center;
    position: relative;
    img {
      border-radius: 50%;
      width: 220px;
      max-height: 220px;
      object-fit: cover;
      opacity: ${props => props.status === 'user_not_exist' || props.status === 'waiting_accept' ? .2 : 1};
    }
    &:after {
      display: ${props => props.status === 'user_not_exist' || props.status === 'waiting_accept' ? 'block' : 'none'};
      content: '${props => props.status === 'user_not_exist' ? 'Not register' : props.status === 'waiting_accept' ? 'Pending' : null}';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      text-shadow: 0 0 15px rgba(0, 0, 0, .3), 0 0 15px rgba(0, 0, 0, .3), 0 0 15px rgba(0, 0, 0, .3), 0 0 15px rgba(0, 0, 0, .3);
      color: white;
    }
  }
  .card-title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #191919;
    padding: 10px 0;
  }
  .card-subtitle {
    text-align: center;
    font-size: 14px;
    color: #999999;
    p {
      margin-bottom: 0;
      word-break: break-all;
    }
  }
`