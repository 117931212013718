import styled from 'styled-components'
import { Drawer, Row, Modal, Col } from 'antd'
export const DrawerWrapper = styled(Drawer)`
  padding-right: 12px 24px;
  .genericReportTable td,
  .genericReportTable th {
    padding: 10px;
    font-family: Calibri, sans-serif;
    word-break: break-all;
    border: 1px solid black;
    border-collapse: collapse;
  }

  .genericReportTable {
    width: 100%;
  }
  .genericReportTable td {
    border: 1px solid #000;
  }

  .genericReportTable {
    padding: 0 20px 0 0;
  }

  .genericReportTable table {
    margin-bottom: 40px;
    width: 100%;
  }

  .splitpanel-horizontal {
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
  }

  .splitpanel-horizontal-mobile {
    top: 0;
    left: 50%;
    width: 100%;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
  }

  .splitpanel-horizontal-mobile .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    padding: 0 !important;
  }

  #export-button {
    margin-left: 5px;
    background: none;
    outline: none;
    border: none;
  }

  .toggleScreen {
    margin-right: 56px;
    position: absolute;
    right: 0;
    width: 30px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    position: absolute;
    top: 10px;
    display: block;
    width: 30px;
    height: 56px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
  }

  .toggleScreen:hover,
  .toggleScreen:focus {
    color: rgba(0, 0, 0, 0.75);
  }

  .genericReport-spiner {
    position: fixed;
    top: 50%;
    left: 50%;
  }

  .genericReport-checkList {
    margin-right: 8px;
  }

  .topicPhase-table-outer {
    display: flex;
  }

  .genericReport-projectName {
    font-size: 2.1rem;
    font-family: Calibri, sans-serif;
  }

  .genericReport-projectName span {
    font-weight: bold;
    font-size: 2.3rem;
  }

  .genericReport-projectName span,
  .special-link .link-to-project {
    font-family: Calibri, sans-serif;
  }

  .tableTopicReport {
    position: relative;
  }

  .export-btn {
    color: #000;
    z-index: 9;
    background-color: #95de64;
  }

  .genericReport-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .genericReport-btn-wrapper{
    display: flex;
    margin-right: 50px;
    align-items: center;
  }

  .ios-device{
      margin-right: 60px !important;
  }

  .genericReport-btn-outer {
    text-align: right;
    .ant-btn.ant-btn-default {
      ${'' /* width: 100px; */}
    }
  }

  .right-80 {
    margin-right: 0px;
  }

  .genericReport-btn-outer button {
    margin: 5px;
    width: 85px;
  }
  .minimize button {
    width: 32px !important;
}

  .genericReport-btn-outer .print-btn {
    background-color: #38a5ce;
  }
  .genericReport-btn-outer button {
    width: auto;
  }

  .print-btn:hover,
  .print-btn:focus {
    color: #fff;
  }

  .genericReport-header {
  }

  .special-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-drawer-body {
    padding: 12px;
  }
  .ant-divider-horizontal,
  .ant-divider-horizontal.ant-divider-with-text {
    margin: 0px;
  }
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 5px;
  }
  .ant-drawer-header {
    ${props =>
      props.place ? 'padding: 10px;' : 'padding: 16px 12px 5px 12px;'}
  }
  @media (max-width: 576px) {
    .ant-drawer-body {
      padding: 5px 10px;
    }
    .ant-form-item {
      margin-bottom: 5px;
    }
    .ant-form-item.checked-mode {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 390px) {
    .custom-select {
      width: 100% !important;
    }
    .col-filter {
      flex: 0 0 100% !important;
      max-width: 100%;
    }
    .ant-collapse-content .ant-collapse-content-box {
      padding: 0px;
    }
  }
`

export const ActionTool = styled(Row)`
  display: flex;
  button{
    margin: 5px 2.5px;
  }
  padding-top: 20px;
  button:first-child {
    margin-left: 0;
  }
`
export const ActionToolBackGround = styled.div`
  width: 100%;
  position: absolute;
  top: 64px;
  left: 0;
  background-color: #FFF;
`

export const ActionToolNewTable = styled(Row)`
  button{
    margin: 5px 2.5px;
  }
  button:first-child {
    margin-left: 0;
  }
  width: 100%;
  padding: 0 12px;
  background-color: #FFF;
`

export const ModalAddAttribute = styled(Modal)`
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-modal-body {
    padding: 16px 24px;
  }
`

export const CheckBoxRules = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SheetReportWrapper = styled.div`
  .sheet-empty{
    padding : 20px 0 ;
    padding-top: 40px;
    .ant-empty{
      padding : 0;
      margin: 0;
    }
  }
`

export const SheetReport = styled.div`
  padding: 10px 0;
  .sheet-label {
    font-weight: bold;
    font-size: 16px;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    padding: 12px 16px;
    min-width: 262px;
  }
  th:not(:first-child){
    /* text-align: center; */
  }
  thead{
    tr{
      th:not(:first-child){
        background: #fafafa;
        color: rgba(0,0,0,.85);
        font-weight: 500
      }
    }
  }
  tr:hover{
    box-shadow: 0 0 10px rgb(0 0 0 /15%);
  }
`

export const SheetReportTool = styled.div`
  /* padding : 20px 0; */
`

export const SheetReportToolWrapper = styled.div`
  display: flex;
  min-width: 1000px;
  padding: 20px 0 10px 0;
  overflow: auto;
  .sheet-add-column{
    display: flex;
    padding: 0 0 0 10px;
    min-width: 150px;
  }
`

export const RuleWrapper = styled.div`
`

export const RuleContainer = styled.div`
  display: flex;
  padding : 2px 0;
  cursor: pointer;
  .rule-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props =>props.width && props.width +'px' };
    height: ${props =>props.height && props.height + 'px' };
    border: 1px solid ${props =>props.background && props.background };
    background:  ${props =>props.background && props.background };
    color:  ${props =>props.color && props.color };
  }
  .rule-title{
    ${props =>props.title && 'padding: 0 5px' };
    font-weight: bold;
  }
`
export const SheetEditorContainer = styled(Row)`
  display: inline-flex;
  /* width: 100%; */
`

export const SheetItemContainerStyle = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;  
  display: inline-table;
    flex-direction: column;
    margin: 0;
    background-color: #fff;
    width: 250px;
    height: fit-content;
    &:first-child{
      margin-left: 0px;             
    }
    .ant-form-item{
        margin-bottom: 10px;
    }
`
export const SheetsContainerStyle = styled.div`
  display: flex;
  padding: grid;
  display: inline-flex;
  .icon-drag path {
    fill: #cec7c7;
  }
`

export const FirstTableTR = styled.tr`
  .tr-column-name{
    font-weight: bold;
  }
  .tr-sheet-name{
    font-weight: bolder;
    font-size: 16px;
  }
`

export const ColumnToolWrapper = styled(Row)`
  width: 100%;
  display: flex;
  align-items: flex-end;
`

export const ColumnTools = styled.div`
  display: flex;
  justify-content: space-between; !important
`

export const TdCustom = styled.td`
  font-weight: ${props =>props.type && props.type};
  .text{
    padding-left:  ${props =>props.indent && props.indent > 0 ? `${props.indent*10 + 5}` + 'px !important' : '0px !important'};
    max-width: 400px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
`