import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next';

const ActiveStatusIndicator = ({ status, type }) => {
  return (
    <div style={{
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      borderRadius: 40,
      padding: type === 'button' ? '5px 15px' : '0',
      backgroundColor: type === 'button'
        ? status ? '#f8f2ef' : 'rgb(251 249 248'
        : 'none',
      color: status ? 'rgb(242 101 36)' : 'rgb(244, 67, 54)',
    }}>
      <span style={{
        display: 'inline-block',
        content: '',
        width: 8,
        height: 8,
        borderRadius: '50%',
        marginRight: 4,
        backgroundColor: status ? 'rgb(242 101 36)' : 'rgb(244, 67, 54)',
      }}/>
      {status ? i18n.t('active') : i18n.t('inactive')}
    </div>
  )
}

ActiveStatusIndicator.propTypes = {
  status: PropTypes.bool,
  type: PropTypes.oneOf([
    'inline',
    'button',
  ]),
}

export default ActiveStatusIndicator