import React from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { Card, Divider } from 'antd'
import { DemoCard } from './CustomStyled'
import { Trans, useTranslation } from 'react-i18next';
const { Meta } = Card

const CityDemoPanel = ({ commonStore, history }) => {
  const { t } = useTranslation();
  return (
    <>
      <DemoCard
        id="demo2"
        hoverable
        cover={<img alt={t('helsinki-demo')} src="/helsinki.jpg" />}
        onClick={() => {
          commonStore.setShowMainDrawer(false)
          history.push('/helsinki')
        }}>
        <Meta title={t('helsinki-demo')} description="https://www.xd-visuals.fi/" />
      </DemoCard>
      <Divider />
      <DemoCard
        id="demo1"
        hoverable
        cover={<img alt={t('tampere-demo')} src="/tempere.jpg" />}
        onClick={() => {
          commonStore.setShowMainDrawer(false)
          history.push('/tampere')
        }}>
        <Meta title={t('tampere-demo')} description="https://www.xd-visuals.fi/" />
      </DemoCard>
    </>
  )
}

export default withRouter(inject('commonStore')(observer(CityDemoPanel)))
