import { observable, action, decorate } from 'mobx'
import { PointsceneRequest } from '../requests'

class PointsceneStore {
    pointsceneJWT = false

    async pointsceneAuthentication() {
        return new Promise((resolve, reject) => {
            PointsceneRequest.pointsceneAuthentication()
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    }

    setPointsceneJWT = data => {
        this.pointsceneJWT = data
    }

    async createWorkflow(data) {
        return new Promise((resolve, reject) => {
            PointsceneRequest.createWorkflow(data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    }
    async createWorkflowZipResource(data) {
        return new Promise((resolve, reject) => {
            PointsceneRequest.createWorkflowZipResource(data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    }
    async uploadFromUrlToS3(data) {
        return new Promise((resolve, reject) => {
            PointsceneRequest.uploadFromUrlToS3(data)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    }
}

decorate(PointsceneStore, {
    pointsceneAuthentication: action,
    pointsceneJWT: observable,
    setPointsceneJWT: action,
    createWorkflow: action,
    createWorkflowZipResource: action,
    uploadFromUrlToS3: action
})

export default new PointsceneStore()
