import { Button, Form, message, Modal, Popconfirm, Select } from 'antd';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { List } from './style';
import './style.css';
import { Trans, useTranslation } from 'react-i18next';
const { Option } = Select

const ChangeRoleUserInProject = props => {
    const { t } = useTranslation();
    const { projectTeamsStore, projectStore, adminStore ,userDataList } = props
    const [form] = Form.useForm();
    const roles = projectTeamsStore.projectRoles
    const { currentUserRoleDetailInProject, currentOrganizationView, curentProjectInOrganizationDetail } = adminStore

    useEffect(() => {
        if (adminStore.showChangeRoleUserModal) {
            form.resetFields();
        }
    }, [adminStore.showChangeRoleUserModal])

    const handleChangeRoleUser = () => {
        form.validateFields().then(async values => {
            let submitValue = { projectrole: values.role }
            projectStore.setLoadingProgress(true)
            await adminStore.updateProjectUserRole(currentUserRoleDetailInProject._id, submitValue)
                .then(async res => {
                    message.success(t('change-user-role-successfully'))
                    await adminStore.getAllProjectTeamsInOrganization(currentOrganizationView.id, curentProjectInOrganizationDetail.id)
                    projectStore.setLoadingProgress(false)
                    adminStore.setShowChangeRoleUserModal(false)
                }).catch(err => {
                    projectStore.setLoadingProgress(false)
                })
        })
    }

    const checkLastManager = () => {
        let warning = false;
        const preRole = currentUserRoleDetailInProject?.projectrole;
        const userList = userDataList.filter(c => c.email !== "maintenance@xd-visuals.fi" && c?.projectrole?.role ==='project_owner')
        if(preRole?.role === 'project_owner'&& (!userList || userList?.length < 2)  ) {
            warning =  true;
        }
        return warning ;
    }

    return (
        <Modal
            className="modal-addUser"
            title={t('change-user-role-in-project')}
            onCancel={() => adminStore.setShowChangeRoleUserModal(false)}
            okText={t('send-role')}
            cancelText={t('commons.cancel')}
            visible={adminStore.showChangeRoleUserModal}
            footer={
                <>
                    <Button key="back" onClick={() => adminStore.setShowChangeRoleUserModal(false)}>
                        {t('commons.cancel')}
                    </Button>
                    {
                        checkLastManager() ? (
                            <Popconfirm placement="top" title={t('confirm-update-last-user')} onConfirm={handleChangeRoleUser} okText={t('commons.yes')} cancelText={t('commons.no')}>
                                <Button key="submit" type="primary">
                                    {t('commons.save')}
                                </Button>
                            </Popconfirm>
                            
                        ) : (
                            <Button key="submit" type="primary" onClick={e => handleChangeRoleUser()}>
                                {t('commons.save')}
                            </Button>
                        )
                    }
                </>
            }
            zIndex={9999}
        >
            <Form
                form={form}
                layout="vertical"
                id={'invite-member-form'}
            >
                <div style={{ padding: "20px 0" }} >
                    <List>
                        <dt>{t('username')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInProject.user && currentUserRoleDetailInProject.user.username ? currentUserRoleDetailInProject.user.username : t("user-not-found")
                            }</span>
                        </dd>
                        <dt>{t('user-email')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInProject.email ? currentUserRoleDetailInProject.email : " "
                            }</span>
                        </dd>
                        <dt>{t('current-role')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInProject.projectrole && currentUserRoleDetailInProject.projectrole.roleName ? currentUserRoleDetailInProject.projectrole.roleName : ""
                            }</span>
                        </dd>
                    </List>
                </div>
                <Form.Item
                    label={t('project-roles')}
                    name="role"
                    rules={[
                        {
                            required: true,
                            message: t('please-select-role'),
                        },
                    ]}
                    initialValue={currentUserRoleDetailInProject.projectrole ? currentUserRoleDetailInProject.projectrole._id : ''}
                >
                    <Select
                        placeholder={t('select-role')}>
                        {
                            Object.keys(roles).map((key) => {
                                return (
                                    <Option key={key} value={key}>{roles[key].roleName}</Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default inject('projectTeamsStore', 'projectStore', 'adminStore')(observer(ChangeRoleUserInProject))