import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, message, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingControlContainer } from './CustomStyled';

const ImporterSetting = ({ projectStore, uiStore, projectSettingStore, usersStore }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [importerVersion, setImporterVersion] = useState(false);
    const [form] = Form.useForm();
    const { Option } = Select;

    useEffect(() => {
        let fetchData = async () => {
            let data = {}
            if (projectStore.projectDetail && usersStore.currentUser.id) {
                let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
                if (_systemProjectSetting.length > 0) {
                    let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)
                    if (!_currentUserSystemSetting) return
                    data = Object.assign(projectSettingStore.systemProjectSetting, _currentUserSystemSetting.projectSetting)
                } else {
                    data = projectSettingStore.systemProjectSetting
                }
                form.setFieldsValue({ importer: data.importerSetting })

            } else {
                data = projectSettingStore.systemProjectSetting
                form.setFieldsValue({ importer: data.importerSetting })
            }
        }
        fetchData()
    }, [])

    const onSubmit = () => {
        form.validateFields().then(res => {

            projectSettingStore.setImporterSetting(res.importer)

            if (usersStore.currentUser?.id) {
                let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
                setLoading(true)
                projectStore.setLoadingProgress(true)
                projectStore.updateProjectMetadata({ metadata }).then(res => {
                    projectStore.projectDetail.metadata = res.metadata
                    setLoading(false)
                    projectStore.setLoadingProgress(false)
                    uiStore.setShowImporterSetting(false)
                    projectStore.setCleanMode(false)
                    message.success(t('updated-successfully'))
                }).catch(err => {
                    setLoading(false)
                    projectStore.setLoadingProgress(false)
                    message.error(t('updated-failed'))
                    projectStore.setCleanMode(false)
                    uiStore.setShowImporterSetting(false)
                })
            } else {
                uiStore.setShowImporterSetting(false)
                projectStore.setCleanMode(false)
                message.success(t('updated-successfully'))
            }
        })
    }

    const onCancel = () => {
        uiStore.setShowImporterSetting(false)
        projectStore.setCleanMode(false)

        let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
        if (_systemProjectSetting.length > 0) {
            let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)

            if (_currentUserSystemSetting?.projectSetting && _currentUserSystemSetting?.projectSetting.importerSetting) {
                projectSettingStore.setImporterSetting(_currentUserSystemSetting.projectSetting.importerSetting)
            } else {
                projectSettingStore.setImporterSetting("speed")
            }
        } else {
            projectSettingStore.setImporterSetting('speed')
        }
    }

    const handleChangeImporterSetting = (value) => {
        setImporterVersion(value)
        // projectSettingStore.setImporterSetting(value)
    };

    return (
        <SettingControlContainer>
            <Form form={form} layout='vertical' className='fogsetting'>
                <p><strong>{t('Importer Settings')}</strong></p>
                <Form.Item
                    label={t('Importer versions')}
                    name="importer">
                    <Select
                        onChange={handleChangeImporterSetting}
                    >
                        <Option value="speed">Speed</Option>
                        <Option value="ellipsoid">Ellipsoid</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 20, marginTop: 20 }}>
                    <Button style={{ marginRight: 5 }}
                        type="default"
                        icon={<CloseOutlined />}
                        onClick={onCancel}>
                        {t('commons.cancel')}
                    </Button>
                    <Button
                        loading={loading}
                        type="primary"
                        onClick={onSubmit}
                        icon={<SaveOutlined />}

                    >
                        {t('commons.save')}
                    </Button>
                </Form.Item>
            </Form>
        </SettingControlContainer>
    )
}
export default inject('projectStore', 'uiStore', 'projectSettingStore', 'usersStore')(observer(ImporterSetting))
