import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'antd'
import { Topic3DObjectsStyle } from './style'
import {
  Color,
  ScreenSpaceEventType,
  ScreenSpaceEventHandler,
  Cesium3DTileFeature,
  Cesium3DTileStyle
} from 'cesium'
import { checkSketchMeshModel, clickPoint } from '../../../helper/CesiumUtils'
import uuid from 'uuid'
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx'

const Cesium = require('cesium')


const Topic3DObjectsControl = ({ topicStore, projectStore, viewer ,sketchingStore }) => {
  const { t } = useTranslation();
  let topic3DObjtects = []
  const [folderPaths] = useState([])
  const handleCancel3DObjects = () => {
    unselectFeatures(topic3DObjtects)
    if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    projectStore.setCleanMode(false)
    topicStore.setShowTopicEditor(true, true)
    topicStore.toggleTopic3DObjectsVisible(false)
    projectStore.setLoadingProgress(false)
  }

  const handleClickSaveObjects = async () => {
    const topicObjects = [];
    projectStore.setLoadingProgress(true)
    let folders = projectStore.projectDetail.treeData
    let getNestedPath = (items, currentPath = '', store = [{ id: '', path: '' }]) => {
      const isArray = Array.isArray(items);
      if (isArray) {
        items.forEach((item) => {
          if (!item.children) {
            if (currentPath) {
              store.push(
                { id: item.modelId || item.sketchId , path: currentPath }
              );
            }
            return
          }
          let path = currentPath
          path += '/' + item.title
          getNestedPath(item.children, path, store)
        });
      }
    }
    getNestedPath(folders, '', folderPaths)
    topic3DObjtects.map(obj => {
      if (obj?.sourceType === 'file') {
        let model = projectStore.modelList.find(c => c.id === obj.id || c.id === obj.modelId)
        if (!model) return;
        const modelID = model.id;
        let folderTree = folderPaths.find(f => f.id === model.id && f.path)?.path;
        if (
          !topicStore.list3DObjectsTopic.find(x => x.GUID === modelID && (x.model3D.id === model.id || x.model3D === model.id)) &&
          !topicObjects.find(x => x.model3D === model.id)
        ) {
          topicObjects.push({
            GUID: modelID,
            model3D: model.id,
            sketch: undefined,
            fileName: model.name,
            folderTree,
            topic: topicStore.viewingTopicForm.id,
            id: uuid(),
            type: 'file',
          })
          topicStore.setTopic3DObject(topicObjects)
        }
      } else if(obj?.sourceType === 'sketch') {
        if (topicStore.viewingTopicForm.isEdit) {
          if (!topicStore.list3DObjectsTopic.find(x => x?.sketch?.id === obj.id) &&
            !topicObjects.find(x => x?.sketch === obj.id)
          ) {
            let folderTree = folderPaths.find(f => f.id === obj.id && f.path)?.path;
            topicObjects.push({
              GUID: obj.id,
              model3D: undefined,
              sketch: obj.id,
              fileName: obj.name,
              folderTree,
              topic: topicStore.viewingTopicForm.id,
              id: uuid(),
              type: 'sketch',
            })
            topicStore.setTopic3DObject(topicObjects)
          }
        } else {
          if(!topicStore.list3DObjectsTopic.find(x => x?.sketch === obj.id) && !topicObjects.find(x => x?.sketch === obj.id)){
            let folderTree = folderPaths.find(f => f.id === obj.id && f.path)?.path;
            topicObjects.push({
              GUID: obj.id,
              model3D: undefined,
              sketch: obj.id,
              fileName: obj.name,
              folderTree,
              topic: topicStore.viewingTopicForm.id,
              id: uuid(),
              type: 'sketch',
            })
            topicStore.setTopic3DObject(topicObjects)
          }
        }
      }else if(obj?.sourceType === "mesh-model"){
        const sketch_id = obj?.props?.sketch_id;
        if(!sketch_id) return ;
        const _sketch = sketchingStore.arrSketches.find(c =>c.id === sketch_id);
        if(!_sketch) return ;
        if (topicStore.viewingTopicForm.isEdit) {
          if (!topicStore.list3DObjectsTopic.find(x => x?.sketch?.id === _sketch.id) &&
            !topicObjects.find(x => x?.sketch === _sketch.id)
          ) {
            let folderTree = folderPaths.find(f => f.id === _sketch.id && f.path)?.path;
            topicObjects.push({
              GUID: obj?.key,
              model3D: undefined,
              sketch: _sketch.id,
              fileName: _sketch.name,
              folderTree,
              topic: topicStore.viewingTopicForm.id,
              id: uuid(),
              type: 'sketch',
            })
            topicStore.setTopic3DObject(topicObjects)
          } else {
            let t1 = topicStore.list3DObjectsTopic.find(x => x?.sketch?.id === _sketch.id && x?.GUID === obj?.key);
            let t2 = topicObjects.find(x => x?.sketch === _sketch.id && x?.GUID === obj?.key);
            if (t1) return;
            if (t2) return;
            let folderTree = folderPaths.find(f => f.id === _sketch.id && f.path)?.path;
            topicObjects.push({
              GUID: obj?.key,
              model3D: undefined,
              sketch: _sketch.id,
              fileName: _sketch.name,
              folderTree,
              topic: topicStore.viewingTopicForm.id,
              id: uuid(),
              type: 'sketch',
            })
            topicStore.setTopic3DObject(topicObjects)
          }
        } else {
          if(!topicStore.list3DObjectsTopic.find(x => x?.sketch === _sketch.id) && !topicObjects.find(x => x?.sketch === _sketch.id)){
            let folderTree = folderPaths.find(f => f.id === _sketch.id && f.path)?.path;
            topicObjects.push({
              GUID: obj?.key,
              model3D: undefined,
              sketch: _sketch.id,
              fileName: _sketch.name,
              folderTree,
              topic: topicStore.viewingTopicForm.id,
              id: uuid(),
              type: 'sketch',
            })
            topicStore.setTopic3DObject(topicObjects)
          }else{
            let t1 = topicStore.list3DObjectsTopic.find(x => x?.sketch?.id === _sketch.id && x?.GUID === obj?.key);
            let t2 = topicObjects.find(x => x?.sketch === _sketch.id && x?.GUID === obj?.key);
            if (t1) return;
            if (t2) return;
            let folderTree = folderPaths.find(f => f.id === _sketch.id && f.path)?.path;
            topicObjects.push({
              GUID: obj?.key,
              model3D: undefined,
              sketch: _sketch.id,
              fileName: _sketch.name,
              folderTree,
              topic: topicStore.viewingTopicForm.id,
              id: uuid(),
              type: 'sketch',
            })
            topicStore.setTopic3DObject(topicObjects)
          }
        }
      }
      else {
        if (!obj || !obj.content || !obj.content.tileset) return
        const model = projectStore.findModelByUrl(obj.content.tileset.url || obj.content.tileset._url)
        if (!obj.getProperty) return;
        const GUID = obj?.getProperty('GUID');
        if (!model || !GUID) return
        if (topicStore.viewingTopicForm.isEdit) {
          if (
            !topicStore.list3DObjectsTopic.find(x => x.GUID === GUID && x.model3D.id === model.id) &&
            !topicObjects.find(x => x.GUID === GUID && (x.model3D.id === model.id || x.model3D === model.id))
            // !topicObjects.find(x => x.GUID === GUID && x.model3D === model.id)
          ) {
            let folderTree = folderPaths.find(f => f.id === model.id && f.path)?.path;
            topicObjects.push({
              GUID,
              model3D: model.id,
              sketch: undefined,
              fileName: model.name,
              folderTree,
              topic: topicStore.viewingTopicForm.id,
              id: uuid(),
              type: 'object'
            })
            topicStore.setTopic3DObject(topicObjects)
          }
        } else {
          if (
            !topicStore.list3DObjectsTopic.find(x => x.GUID === GUID && x.model3D === model.id) &&
            !topicObjects.find(x => x.GUID === GUID && (x.model3D.id === model.id || x.model3D === model.id))
          ) {
            let folderTree = folderPaths.find(f => f.id === model.id && f.path)?.path;
            topicObjects.push({
              GUID,
              model3D: model.id,
              sketch: undefined,
              fileName: model.name,
              folderTree,
              topic: topicStore.viewingTopicForm.id,
              id: uuid(),
              type: 'object'
            })
            topicStore.setTopic3DObject(topicObjects)
          }
        }
      }
    })
    if (topicObjects.length) {
      await topicStore.addTopic3DObjects({ topicObjects });
    }
    topicStore.setShowTopicEditor(true, true)
    handleCancel3DObjects()
  }

  const unselectFeatures = features => {
    if (!features || !features.length) {
      return;
    }
    resetTilesetColor(features)
    setFeaturesColor(features, Color.WHITE)
    setColorEntitiesValue(features, true)
    if (features === topic3DObjtects) {
      topic3DObjtects = []
    }
  }

  const selectFeatures = features => {
    setFeaturesColor(features, Color.RED)
    topic3DObjtects = [...topic3DObjtects, ...features]
  }

  const setFeaturesColor = (features, color) => {
    features.map(feature => {
      if (!feature?.isColorEntitiesValue) {
        feature.color = Color.clone(color, feature.color);
      }
    })
    if (viewer.current.cesiumElement.scene.requestRenderMode) {
      viewer.current.cesiumElement.scene.requestRender();
    }
  }

  const resetTilesetColor = (tilesets) => {
    if (tilesets?.length > 0) {
      tilesets.map(tileset => {
        if (tileset?.sourceType === 'file' && !tileset?.isColorEntitiesValue) {
          tileset.style = new Cesium3DTileStyle();
        }
      })
      if (viewer.current.cesiumElement.scene.requestRenderMode) {
        viewer.current.cesiumElement.scene.requestRender();
      }
    }
  }

  const setTilesetColor = (tilesets) => {
    if (tilesets?.length > 0) {
      tilesets.map(tileset => {
        if (tileset?.sourceType === 'file' && !tileset?.isColorEntitiesValue) {
          tileset.style = new Cesium3DTileStyle({
            color: {
              conditions: [
                ['true', 'color("red")'],
              ],
            },
          });
        }
      })
      if (viewer.current.cesiumElement.scene.requestRenderMode) {
        viewer.current.cesiumElement.scene.requestRender();
      }
    }
  }

  const setColorEntitiesValue = (features, isReset) => {
    if (!isReset) {
      features.map(feature => {
        const tileView = feature?.entities?.values
        if (tileView) {
          tileView.map(feature => {
            if (feature?.polygon?.material) {
              feature.polygon.material = Color.clone(Color.RED, feature.color);
            } else if (feature?.polyline?.material) {
              feature.polyline.material = Color.clone(Color.RED, feature.color);
            } else if (feature?.point?.color) {
              feature.point.color = Color.clone(Color.RED, feature.color);
            } else if (feature?.billboard?.color) {
              feature.billboard.color = Color.clone(Color.RED, feature.color);
            }
          })
        }
      })
    } else {
      features.map(feature => {
        const tileView = feature?.entities?.values
        if (tileView && feature?.isColorEntitiesValue) {
          tileView.map(feature => {
            if (feature?.polygon?.material) {
              feature.polygon.material = Color.clone(Color.WHITE, feature.color);
            } else if (feature?.polyline?.material) {
              feature.polyline.material = Color.clone(Color.WHITE, feature.color);
            } else if (feature?.point?.color) {
              feature.point.color = Color.clone(Color.WHITE, feature.color);
            } else if (feature?.billboard?.color) {
              feature.billboard.color = Color.clone(Color.WHITE, feature.color);
            }
          })
        }
      })
    }
    if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }
  const findTile = (tileId, tileViews) => {
    let fkey = tileId + '-tile'
    let tile = tileViews.find(t => t.key == fkey)
    if (!tile) return false
    if (!tile.ref) return false
    if (!tile.ref.current) return false
    return tile.ref.current.cesiumElement
  }

  useEffect(() => {
    const handler = new ScreenSpaceEventHandler(
      viewer.current.cesiumElement.canvas
    )
    if (topicStore.isTopic3DObjectsVisible) {
      if (topicStore.list3DObjectsTopic?.length) {
        const sketchObjects = []
        topicStore.list3DObjectsTopic.map(item => {
          const modelId = item?.model3D?.id || item?.model3D
          if (item.GUID && projectStore.modelFeatures[modelId]) {
            const features = projectStore.modelFeatures[modelId][item.GUID];
            let arraySelect = []
            if (Array.isArray(features)) {
              features.map(feature => {
                if (feature && feature instanceof Cesium3DTileFeature) {
                  feature.isColorEntitiesValue = false
                  arraySelect.push(feature)
                }
              })
            } else {
              if (features && features instanceof Cesium3DTileFeature) {
                features.isColorEntitiesValue = false
                arraySelect.push(features)
              }
            }
            selectFeatures(arraySelect);
          }
          if (item.type === 'file') {
            let _model = findTile(item.GUID, projectStore.tileViews)
            if(!_model) return ;
            _model.modelId = item?.model3D?.id
            _model.isColorEntitiesValue = false
            if (_model) {
              _model.sourceType = 'file'
              if (_model?.entities?.values) {
                _model.isColorEntitiesValue = true
                topic3DObjtects.push(_model)
                setColorEntitiesValue([_model])
              } else {
                setTilesetColor([_model])
                selectFeatures([_model]);
              }
            }
          }
          if (item.type === 'sketch' && item.sketch) {
            // check case sketch has mesh model
            let _skt = item.sketch?.id === item?.GUID ? item.sketch : {
              type : "sketch",
              id : item?.GUID
            }
            sketchObjects.push(_skt)
          }
        })
        topicStore.setSketchObjectHighLights(sketchObjects)
      }
      handler.setInputAction(function (movement) {
        if (!viewer.current.cesiumElement.scene) return
        const resultClick = clickPoint(viewer.current.cesiumElement, movement.position)
        if (!resultClick || !resultClick.pickedObject) return
        if (resultClick.pickedObject instanceof Cesium3DTileFeature) {
          resultClick.pickedObject.isColorEntitiesValue = false
          const modelId = resultClick.pickedObject?.tileset?.id
          if (modelId) {
            const obj = resultClick.pickedObject
            const GUID = obj?.getProperty('GUID');
            const features = projectStore.modelFeatures[modelId][GUID];
            let arraySelect = []
            if (Array.isArray(features)) {
              features.map(feature => {
                if (feature && feature instanceof Cesium3DTileFeature) {
                  feature.isColorEntitiesValue = false
                  arraySelect.push(feature)
                }
              })
            } else {
              if (features && features instanceof Cesium3DTileFeature) {
                features.isColorEntitiesValue = false
                arraySelect.push(features)
              }
            }
            selectFeatures(arraySelect);
          } else {
            selectFeatures([resultClick.pickedObject])
          }
        } else {
          const { pickedObject } = resultClick;
          const primitiveId = pickedObject?.primitive?.id
          if (primitiveId) {
            const id = primitiveId;
            let _model = findTile(id, projectStore.tileViews)
            if (_model) {
              _model.isColorEntitiesValue = false
              _model.sourceType = 'file'
              selectFeatures([_model])
              setTilesetColor([_model])
            } else {
              // check sketch has models
              const isExist = sketchingStore.sketchMeshModelViews.find(c => c.key === primitiveId);
              if (isExist) {
                const u = topicStore.sketchObjectHighLights.find(c => c.id === isExist?.key);
                if (!u) {
                  topicStore.setSketchObjectHighLights([
                    ...topicStore.sketchObjectHighLights,
                    {
                      id: isExist.key,
                      type: isExist?.props?.type,
                    }
                  ])
                  isExist.sourceType = 'mesh-model'
                  topic3DObjtects.push(toJS(isExist));
                }
              }
            }
          } 
          else if (pickedObject?.id?.modelType === 'kml' || pickedObject?.id?.modelType === 'geojson') {
            const modelId = pickedObject?.id?.modelId;
            let _model = findTile(modelId, projectStore.tileViews)
            _model = findTile(modelId, projectStore.tileViews)
            if (_model) {
              _model.sourceType = 'file'
              _model.modelId = modelId
              _model.isColorEntitiesValue = true
              topic3DObjtects.push(_model)
              setColorEntitiesValue([_model])
            }
          }else{
            const pickedObjectID = checkSketchMeshModel(pickedObject) ? pickedObject.id : pickedObject.id.id
            const sketch = sketchingStore.arrSketches.find(x => x._id === pickedObject.id._id || pickedObjectID.indexOf(x.id) === 0);
            if(!sketch) return;
            sketch.sourceType = 'sketch'
            topic3DObjtects.push(toJS(sketch))
            const isExist = topicStore.sketchObjectHighLights.find(c => c.id ===sketch.id)
            if(!isExist){
              topicStore.setSketchObjectHighLights([
                ...topicStore.sketchObjectHighLights,
                {
                  id :sketch.id,
                  name :sketch.name,
                  project :sketch.project,
                  type :sketch.type,
                }
              ])
            }
          }
        }
      }, ScreenSpaceEventType.LEFT_CLICK)
    }
    return () => {
      if (handler) handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
      unselectFeatures(topic3DObjtects)
      topic3DObjtects = []
      topicStore.toggleTopic3DObjectsVisible(false)
    }
  }, [])

  return (
    <>
      <Topic3DObjectsStyle>
        <div>{t('topic-objects')}</div>
        <div>
          <Button loading={topicStore.isLoading} onClick={handleClickSaveObjects} type="primary">
            {t('commons.save')}
          </Button>
          <Button onClick={handleCancel3DObjects} style={{ marginLeft: 8 }}>{t('commons.cancel')}</Button>
        </div>
      </Topic3DObjectsStyle>
    </>
  )
}
export default inject('topicStore', 'projectStore','sketchingStore')(observer(Topic3DObjectsControl))