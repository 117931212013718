import styled from 'styled-components'
import { Spin } from 'antd';
export const ProjectLinkPageWrapper = styled.div`
.splitpanel-left {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
}

.splitpanel-mobile-left {
    top: 0;
    right: 0; 
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-left: -15px;
    padding: 0 !important;
    }
}
`
export const SearchWrapper = styled.div`
    display : flex ;
    width : 100%;
    border: 1px solid #d9d9d9;
`
export const CustomSearch = styled.div`
    flex-grow : 1;
    .ant-input-affix-wrapper{
        flex-grow : 1;
        height : 28px ;
        border : none ;
        background : none ;
        outline : none ;
        input {
        border : none ;
        background : none ;
        outline : none ;
        height : 100%;
      }
    }
    .ant-input-affix-wrapper:hover{
        border : none;
    }
    .ant-input-affix-wrapper-focused{
        border : none;
        background : none ;
        outline : none ;
        box-shadow : none;
    }
    .ant-form-item-control-input{
        min-height: auto !important;
    }
    
`
export const CustomButton = styled.div`
    display : flex;
    justify-content : center ;
    align-items : center ;
    padding : 0 5px;
    font-size : 11px
`
export const SpinDrawer = styled.div`
    .ant-spin-container .splitpanel-left {
        width: 0;
    }
`

