import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import axios from 'axios'
import * as serviceWorker from './serviceWorker'
import i18n from './i18n/config'
import { I18nextProvider, withNamespaces } from 'react-i18next';

axios.interceptors.response.use(response => response, error => Promise.reject(error.response))

ReactDOM.render((
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
  ), document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
