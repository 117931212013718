import styled from 'styled-components'
import { Avatar } from 'antd';

export const ToolbarTopContainer = styled.div`
  display: block;
  position: absolute;
  right: ${props => props.right ==='pc' ? (props.isHover ? '325px' : '105px')  : (props.isHover ? '320px' : '95px')};
  top: 0;
  padding: 7px;
  z-index: 999;
`

export const ToolbarTopCustom = styled.div`
  float:left;
  margin-bottom: 4px;
  color: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  } 
  .camera, .no-padding {
    padding:0 !important;
  }
  .no-padding .icon3D path{
    fill: #F26524 !important;
  }
  .ant-btn {
    border: 1px solid #000;
    width: 32px !important;
    height: 32px !important;
    margin-right: 5px;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`