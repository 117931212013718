import styled from 'styled-components'
import { Avatar } from 'antd';

export const ToolbarLeftContainer = styled.div`
  display: block;
  position: absolute;
  left: 0;
  /* top: 50%; */
  top: ${props => props?.distanceTop || '50%' };
  ${props => props?.distanceTop === '50%' ? 'transform: translateY(-50%);' : ''};
  padding: 5px 7px 10px;
  z-index: 999;
  background: none;
  @media(max-height: 420px) {
    .leftIcon-group.ant-avatar-group{
      // position: relative;
      // top: 38px;
    }
  }
  .ant-avatar-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .ant-avatar {
      border: 1px solid #000;
    }
  }

  .leftIcon-group{
    display: grid !important;
    /* grid-template-columns: auto auto; */
  }
`

export const ToolbarLeftItem = styled.div`
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
  color: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  }
  // &:hover {
  //   color: #ff8d4f !important;
  //   path {
  //     fill: #ff8d4f !important;
  //   }
  // }
  .ant-btn {
    width: 32px !important;
    // height: 32px !important;
    // line-height: 32px;
    margin-bottom: 5px;
    border: 1px solid #000;
    border-radius: 5px !important;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`

export const AvatarCustom = styled(Avatar)`
  border-radius: 5px !important;
  margin-bottom: 5px !important;
  cursor: pointer; 
  background: #fff !important;
  line-height: inherit !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  &:last-child {
    margin-right: 0 !important;
  }  
  &:not(:first-child) {
    margin-left: 0px !important;
  }
  &:hover, &:focus {     
      border: 1px solid #ff8d4f !important;
  }
  color: ${props => props.activated === 1 ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated === 1 ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  }
`

export const ToolbarLeftCustom = styled.div`
  float:left;
  margin-bottom: 4px;
  color: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  } 
  .camera, .no-padding {
    padding:0 !important;
  }
  .no-padding .icon3D path{
    fill: #F26524 !important;
  }
  .ant-btn {
    border: 1px solid #000;
    width: 32px !important;
    height: 32px !important;
    margin-right: 5px;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`