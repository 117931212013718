import styled from 'styled-components'
import { Avatar } from 'antd';

export const ToolbarTopContainer = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding: 7px;
  z-index: 999;
  .ant-avatar-group {
    .ant-avatar {
      border: 1px solid #000;
    }
  }
  .custom-system-setting{
    position: fixed !important;
    top: 30px;
  }
  .mobile-portrait {
    display: grid;
    .ant-avatar {
      margin-bottom: 5px;
    }       
  }
  .mobile-landscape{    
    display: inline-flex;
  }
  .desktop-style {
    display: grid;
    .ant-avatar {
      margin-bottom: 5px;
    }
    &.css-public {
      position: absolute;
      margin-top: 15px;
    }
  }
  .mobile-portrait2 {
    display: grid;
    margin-top: 35px;
    .ant-avatar {
      margin-bottom: 5px;
    }       
  }
  .mobile-portrait3 {
    display: inline-grid;
    position: absolute;
    left: 6px;
    margin-top: 36px;
  }
  .data-attribtuon-outer{
    position: fixed;
    bottom: 45px;
    left: 10px; 
    text-decoration: underline;
    cursor: pointer;
    color: #FFF;
  }
  .data-attribtuon-outer:hover{
    color: #2089cb;
  }
  .help-button-outer{
    position: fixed;
    right: 80px;
    top: 5px; 
  }
  

  .help-btn-outer-mb{
    position: fixed;
    right: 85px;
    top: 15px; 
    text-decoration: underline;
    cursor: pointer;
    color: #FFF;

    :global(.ant-dropdown) my-dropdown-menu{
      left: 10% !important;
    }
  }
  .help-btn-outer-mb:hover{
    color: #2089cb;
  }
`
export const ToolbarTopItem = styled.div`
  height: 32px;
  margin-right: 5px;
  float: left;
  .ant-btn-icon-only.ant-btn-lg {
    width: 32px;
    // height: 32px;
    // line-height: 32px;
  }
  .ant-btn {
    border: 1px solid #000;
    border-radius: 5px !important;
    // height: 32px;
    // line-height: 32px;
  }
`
export const ToolbarTopRightContainer = styled.div`
  display: block;
  position: absolute;
  right: 32px;
  top: 0;
  padding: 7px;
  z-index: 999;
  .ant-btn-lg {
    width: 32px;
    // height: 32px;
    // line-height: 32px;
    padding: 0;  
    border-radius: 4px;
  }
`
export const AvatarCustom = styled(Avatar)`
  border-radius: 5px !important;
  margin-right: 5px !important;
  cursor: pointer; 
  background: #fff !important;
  line-height: inherit !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  &:not(:first-child) {
    margin-left: 0px !important;
  }
  &:hover, &:focus {     
      border: 1px solid #ff8d4f !important;
  }
  color: ${props => props.activated === 1 ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated === 1 ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  }
`

export const ToolbarTopCustom = styled.div`
  float:left;
  margin-bottom: 4px;
  color: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  } 
  .camera, .no-padding {
    padding:0 !important;
  }
  .no-padding .icon3D path{
    fill: #F26524 !important;
  }
  .ant-btn {
    border: 1px solid #000;
    width: 32px !important;
    height: 32px !important;
    margin-right: 5px;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .end-sketch-btn {width: auto !important}
`

export const HelpButtonCustom = styled.div`
  .help-button-outer{
    position: fixed;
    /* right: 200px; */
    right: ${props => props.isResize ? "300px" : "80px"} ;
    top: 5px; 
  }
  .help-btn-outer-mb{
    position: fixed;
    /* right: 115px; */
    right: ${props => props.isResize ? "305px" : "85px"} ;
    top: 15px; 
    text-decoration: underline;
    cursor: pointer;
    color: #FFF;

    :global(.ant-dropdown) my-dropdown-menu{
      left: 10% !important;
    }
  }
  .help-btn-outer-mb:hover{
    color: #2089cb;
  }
`

export const ProjectSettingGroup = styled(Avatar.Group)`
    position: absolute;
    left: 7px;
    top: ${props => props.distanceTop && (props.distanceTop + "px")} ;
`

export const ManagementGroup = styled.div`
    position: absolute;
    top: ${props => props.wrap ? (props.distanceTop ? (props.distanceTop + 'px') : "47px" ) : '7px'} ;
    ${props => props.distanceLeft ? `left: ${props.distanceLeft}px` : ''};
`