import { observable, action, decorate } from 'mobx'

class MeasureStore {
    isLoading = false
    measureToolVisible = false
    measureMode = undefined
    endDrawing = false
    removeShape = false

    setMeasureToolVisible = (value) => {
        this.measureToolVisible = !this.measureToolVisible
        if (value !== undefined) {
            this.measureToolVisible = value
        }
    }

    setMeasureMode = mode => {
        this.measureMode = mode //model: point; distance, area, polyline
    }

    setEndDrawing = v => {
        this.endDrawing = v
    }

    setRemovingShape = v => {
        this.removeShape = v
    }
}

decorate(MeasureStore, {
    isLoading: observable,
    measureToolVisible: observable,
    setMeasureToolVisible: action,
    measureMode: observable,
    setMeasureMode: action,
    endDrawing: observable,
    setEndDrawing: action,
    removeShape: observable,
    setRemovingShape: action,
})

export default new MeasureStore()
