import {
  BoundingSphere,
  CallbackProperty,
  Cartesian2,
  Cartesian3,
  Cartographic,
  CorridorGraphics as CesiumCorridorGraphics,
  Math as CesiumMath,
  PolylineGraphics as CesiumPolylineGraphics,
  PolylineVolumeGraphics as CesiumPolylineVolumeGraphics,
  WallGraphics as CesiumWallGraphics,
  ClassificationType,
  Color,
  CornerType,
  DistanceDisplayCondition,
  HorizontalOrigin,
  ShadowMode,
  Transforms,
  VerticalOrigin
} from 'cesium'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CorridorGraphics,
  CylinderGraphics,
  Entity,
  LabelGraphics,
  Model,
  PolygonGraphics,
  PolylineGraphics,
  PolylineVolumeGraphics,
  WallGraphics
} from 'resium'
import TreeUtils from '../../../../tree-utils'
import Utils from '../../../../utils'
import { drawLineShape, getCurrentSketch, getCurrentSketchModel, getSketchMatrix, getVerticalPointClick } from '../../../helper/CesiumUtils'
import CesiumDrawing from './CesiumDrawing'

var Cesium = require('cesium')

const SketchView = props => {
  const { t } = useTranslation();
  const { viewer, sketches, projectStore, sketchingStore,userGroupStore, projectGanttStore,topicStore } = props
  const [sketchViews, setSketchViews] = useState([])
  const labelDefault = {
    font: '12px monospace',
    showBackground: true,
    horizontalOrigin: HorizontalOrigin.CENTER,
    verticalOrigin: VerticalOrigin.CENTER,
    pixelOffset: new Cartesian2(0, 0),
    fillColor: Color.WHITE,
  }
  const [checkedKeysModelOld, setCheckedKeysModelOld] = useState([])
  const [sketchModelDelete, setSketchModelDelete] = useState([])

  const getSketchLibraryModel = (sketchId) => {
    if (sketchId) {
      let hasSketchTypeModel = false
      let sketchesList = sketchingStore.arrSketches
      let tileViews = sketchViews
      let model = sketchesList.find(item => item.id == sketchId)
      if (!model) return false
      let fkey = sketchId
      let tile = tileViews.find(t => t.key == fkey)
      if (!tile) return false
      if (!tile.ref) return false
      if (!tile.ref.current) return false
      if (sketchingStore.sketchLibraryElements && model.typeLibrary && model.typeLibrary.checkedKeys && model.typeLibrary.checkedKeys.length > 0) {
        if (sketchingStore.sketchLibraryElements.length > 0) {
          let data = sketchingStore.sketchLibraryElements
          let result = []
          let _checkedKey = model.typeLibrary.checkedKeys
          _checkedKey.map(key => {
            let node = TreeUtils.searchTreeNode(data, 'key', key);
            if (node && node.model) {
              result.push(toJS(node))
            }
          })
          hasSketchTypeModel = result.length > 0 ? result : false
        }
      }
      return hasSketchTypeModel
    }
    return false
  }

  function drawResetLineShape(positions, color, statusCallback) {
    let statusCB = statusCallback !== undefined ? statusCallback : true
    const cartesians = positions.map(
      position => new Cartesian3(position.x, position.y, position.z)
    )
    return new CesiumPolylineGraphics({
      positions:
        new CallbackProperty(function () {
          if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
            let _positionEntity = sketchingStore.sketch?.pointDragger.map(
              position => new Cartesian3(position.x, position.y, position.z)
            )
            return _positionEntity
          } else {
            return cartesians
          }
        }, statusCB)
      ,
      material: Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha)),
      width: 3,
      followSurface: true
    })

  }

  function drawResetLineHorizontal(_points, oColor, _width, extrudedHeight, height, statusCallback) {
    let statusCB = statusCallback !== undefined ? statusCallback : true
    let arr = []
    for (let i = 0; i < _points.length; i++) {
      let point = _points[i];
      let cartographic = Cartographic.fromCartesian(point)
      let picklong = cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN
      let picklat = cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN
      let height = cartographic.height
      arr.push(picklong, picklat, height)
    }
    const color = Color.fromCssColorString(oColor && oColor.color ? oColor.color : '#fff').withAlpha(oColor && oColor.alpha ? oColor.alpha : 1);
    const cartesians = _points.map(position => new Cartesian3(position.x, position.y, position.z))
    const cartographics = cartesians.map(cartesian => new Cartographic.fromCartesian(cartesian))
    let lineShape = []

    lineShape.push(
      new Cartesian2(-_width / 2, -extrudedHeight / 2),
      new Cartesian2(_width / 2, -extrudedHeight / 2),
      new Cartesian2(_width / 2, extrudedHeight / 2),
      new Cartesian2(-_width / 2, extrudedHeight / 2))

    const corridor = new CesiumCorridorGraphics({
      positions: new CallbackProperty(function () {
        if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
          let _pointArrs = []
          for (let i = 0; i < sketchingStore.sketch?.pointDragger.length; i++) {
            let point = sketchingStore.sketch?.pointDragger[i];
            let cartographic = Cartographic.fromCartesian(point)
            let picklong = cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN
            let picklat = cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN
            let height = cartographic.height
            _pointArrs.push(picklong, picklat, height)
          }
          return Cartesian3.fromDegreesArrayHeights(_pointArrs)
        } else {
          return Cartesian3.fromDegreesArrayHeights(arr)
        }
      }, statusCB),
      height: height,
      extrudedHeight: height + extrudedHeight,
      width: _width,
      // width: _width / 2,
      cornerType: CornerType.MITERED,
      material: color
    });

    const polylineVolume = new CesiumPolylineVolumeGraphics({
      positions: new CallbackProperty(function () {
        if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {

          let _pointArrs = []
          for (let i = 0; i < sketchingStore.sketch?.pointDragger.length; i++) {
            let point = sketchingStore.sketch?.pointDragger[i];
            let cartographic = Cartographic.fromCartesian(point)
            let picklong = cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN
            let picklat = cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN
            let height = cartographic.height
            _pointArrs.push(picklong, picklat, height)
          }
          return Cartesian3.fromDegreesArrayHeights(_pointArrs)
        } else {
          return Cartesian3.fromDegreesArrayHeights(arr)
        }
      }, statusCB),
      shape: new CallbackProperty(function () {
        return lineShape
      }, statusCB),
      cornerType: CornerType.MITERED,
      material: color
      // outline: true, // height or extrudedHeight must be set for outlines to display
      // outlineColor: Color.WHITE
    })

    const wallVolume = new CesiumWallGraphics({
      positions: new CallbackProperty(function () {
        if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
          return sketchingStore.sketch.pointDragger
        } else {
          return toJS(_points)
        }
      }, statusCB),
      maximumHeights: new CallbackProperty(function () {
        if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
          let _cartesians = sketchingStore.sketch.pointDragger.map(position => new Cartesian3(position.x, position.y, position.z))
          let _cartographics = _cartesians.map(cartesian => new Cartographic.fromCartesian(cartesian))
          return _cartographics.map(cat => cat.height + extrudedHeight)
        } else {
          return cartographics.map(cat => cat.height + extrudedHeight)
        }
      }, statusCB),
      minimumHeights: new CallbackProperty(function () {
        if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
          let _cartesians = sketchingStore.sketch.pointDragger.map(position => new Cartesian3(position.x, position.y, position.z))
          let _cartographics = _cartesians.map(cartesian => new Cartographic.fromCartesian(cartesian))
          return _cartographics.map(cat => cat.height)
        } else {
          return cartographics.map(cat => cat.height)
        }
      }, statusCB),
      material: color,
    })
    if (extrudedHeight && _width) return polylineVolume
    if (_width) return corridor
    if (extrudedHeight) return wallVolume
  }
  
  function drawLineHorizontal(_points, oColor, _width, extrudedHeight, height) {
    let arr = []
    let arr2 = []
    for (let i = 0; i < _points.length; i++) {
      let point = _points[i];
      let cartographic = Cartographic.fromCartesian(point)
      let picklong = cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN
      let picklat = cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN
      let height = cartographic.height
      arr.push(picklong, picklat, height)
      arr2.push(picklong, picklat)
    }
    const color = Color.fromCssColorString(oColor && oColor.color ? oColor.color : '#fff').withAlpha(oColor && oColor.alpha ? oColor.alpha : 1);
    const cartesians = _points.map(position => new Cartesian3(position.x, position.y, position.z))
    const cartographics = cartesians.map(cartesian => new Cartographic.fromCartesian(cartesian))
    let lineShape = []

    lineShape.push(
      new Cartesian2(-_width / 2, -extrudedHeight / 2),
      new Cartesian2(_width / 2, -extrudedHeight / 2),
      new Cartesian2(_width / 2, extrudedHeight / 2),
      new Cartesian2(-_width / 2, extrudedHeight / 2))

    const corridor = new CesiumCorridorGraphics({
      positions: Cartesian3.fromDegreesArrayHeights(arr),
      height: height,
      extrudedHeight: height + extrudedHeight,
      // width: _width / 2,
      width: _width,
      cornerType: CornerType.MITERED,
      material: color
    });

    const polylineVolume = new CesiumPolylineVolumeGraphics({
      positions: Cartesian3.fromDegreesArrayHeights(arr),
      shape: lineShape,
      cornerType: CornerType.MITERED,
      material: color
      // outline: true, // height or extrudedHeight must be set for outlines to display
      // outlineColor: Color.WHITE
    })

    const wallVolume = new CesiumWallGraphics({
      positions: _points,
      maximumHeights: cartographics.map(
        cat => cat.height + extrudedHeight
      ),
      minimumHeights: cartographics.map(cat => cat.height),
      material: color
    })
    if (extrudedHeight && _width) return polylineVolume
    if (_width) return corridor
    if (extrudedHeight) return wallVolume
  }
  var count = 0

  const addSketchView = sketch => {
    const { id, type, color, height, extrudedHeight, positions, isShowName, isShowDescription, width, readonlyHeight, font, sizeLabel } = sketch
    if (sketch.positions && sketch.positions.length > 0) {
      const ref = React.createRef()
      let hasSketchTypeModel
      if (sketch && sketch.typeLibrary && sketch.typeLibrary.checkedKeys.length > 0) {
        hasSketchTypeModel = ckeck3dMesh(sketch.typeLibrary.checkedKeys)
      }
      const cartesians = positions.map(
        position => new Cartesian3(position.x, position.y, position.z)
      )
      let _boundingSphereCenter = BoundingSphere.fromPoints(cartesians).center
      let _refcartographic = Cartographic.fromCartesian(_boundingSphereCenter)
      let _boundingSphereCenterLabel = BoundingSphere.fromPoints(cartesians).center
      let _refcartographicLabel = Cartographic.fromCartesian(_boundingSphereCenterLabel)
      if (!readonlyHeight) {
        _refcartographic.height = height + extrudedHeight + 0.1
      }
      else {
        _refcartographic.height += 0.2
      }
      let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
      let _positionEntityLabel = Cartesian3.fromDegrees(_refcartographicLabel.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographicLabel.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographicLabel.height)
      let w = 1
      let h = 1;
      if (hasSketchTypeModel && type === "point") {
        const point0 = Cartographic.fromCartesian(cartesians[0].clone())
        const point00 = point0.clone()
        point0.height += sketch.extrudedHeight / 2
        let verticalP1P2 = 0
        if (cartesians.length > 1 && type === 'point') {
          if (cartesians[0].z > cartesians[1].z) {
            let point1 = cartesians[0]
            let point2 = cartesians[1] || cartesians[0]
            let vertical = getVerticalPointClick(point1, point2)
            verticalP1P2 = vertical
            point00.height = point00.height - vertical
            point0.height = point0.height - vertical
          }
        }
        let _positionModel = Cartesian3.fromDegrees(point00.longitude * CesiumMath.DEGREES_PER_RADIAN, point00.latitude * CesiumMath.DEGREES_PER_RADIAN, point00.height)
        let _position = Cartesian3.fromDegrees(point0.longitude * CesiumMath.DEGREES_PER_RADIAN, point0.latitude * CesiumMath.DEGREES_PER_RADIAN, point0.height)
        let _model = hasSketchTypeModel.map((_sketchType) => {
          var localFrame = Transforms.eastNorthUpToFixedFrame(_positionModel);
          const {width, height} = sketch ;
          const { Scale_X, Scale_Y, Scale_Z } = Utils.getSketchScale(_sketchType.defaultLength, _sketchType.defaultWidth, _sketchType.defaultHeight, width, extrudedHeight);
          if (w < width) {
            w = width
          }
          if (h < height) {
            h = height
          }
          const rotation = sketch.rotation || 1;
          const xTran = getSketchMatrix(Scale_X, Scale_Y, Scale_Z, rotation, localFrame);
          let refModel = React.createRef()
          let newModel = (
            <Model
              type="sketch"
              url={_sketchType.model.src}
              id={`${id}-${_sketchType.model.hash}`}
              sketch_id={id}
              key={`${id}-${_sketchType.model.hash}`}
              modelMatrix={xTran}
              ref={refModel}
            />
          )
          sketchingStore.setSketchMeshModelViews(newModel)
          return newModel ;
        })
        var getSketchAreaLocation = new Cesium.CallbackProperty(function () {
          if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 && id === sketchingStore.currentSketchId) {
            let cartesians = sketchingStore.sketch?.pointDragger.map(
              position => new Cartesian3(position.x, position.y, position.z)
            )
            let _refcartographic = Cartographic.fromCartesian(cartesians[0])
            _refcartographic.height += sketch.extrudedHeight / 2
            let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
            return _positionEntity
          } else {
            return _position
          }
        }, true);
        const refLabel = React.createRef()
        setSketchViews(sketchViews => [
          ...sketchViews,
          <Entity id={id} key={id} show={true} ref={ref} position={getSketchAreaLocation} type="sketch" userData={{ point: cartesians }}>
            {<CylinderGraphics
              material={Color.WHITE.withAlpha(0)}
              topRadius={w / 2}
              bottomRadius={w / 2}
              show={true}
              length={h}
            />}
          </Entity>,
          <Entity id={`${id}-label`} key={`${id}-label`} show={true} ref={refLabel}
            position={new CallbackProperty(function () {
              const height = ref?.current?.cesiumElement?.cylinder?.length ? ref?.current?.cesiumElement?.cylinder?.length : sketch.extrudedHeight
              if (height) {
                const pointLabel = Cartographic.fromCartesian(cartesians[0].clone())
                pointLabel.height = pointLabel.height - verticalP1P2
                pointLabel.height += height
                let _positionLabel = Cartesian3.fromDegrees(pointLabel.longitude * CesiumMath.DEGREES_PER_RADIAN, pointLabel.latitude * CesiumMath.DEGREES_PER_RADIAN, pointLabel.height)
                return _positionLabel
              }
              else {
                return cartesians[0]
              }
            }, true)
            }
            type="sketch" userData={{ point: cartesians }}>
            <LabelGraphics
              {...labelDefault}
              show={isShowName || isShowDescription}
              text={(isShowName ? sketch.name : '') + (isShowDescription ? '\n' : '') + (isShowDescription ? sketch.description : '')}
              disableDepthTestDistance={Number.POSITIVE_INFINITY}
              distanceDisplayCondition={new DistanceDisplayCondition(0.0, 2000)}
            />
          </Entity>,
          ..._model
        ])
        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        function requestRenderBug() {
          count += 1
          if (!viewer.current.cesiumElement.scene.isDestroyed()) {
            viewer.current.cesiumElement.scene.requestRender();
            count > 5 ? clearTimeout(timeout) : setTimeout(requestRenderBug, 1000);
          }
        }
        let timeout = sketchingStore.arrSketches?.length === 1 ? setTimeout(requestRenderBug, 1000) : false;

      } else {
        if (type === 'line') {
          let shape;
          const getPositions = () => {
            let l = undefined
            if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 && id === sketchingStore.currentSketchId) {
              let line = (extrudedHeight > 0 || width > 0) ?
                drawLineHorizontal(sketchingStore.sketch.pointDragger, color, width, extrudedHeight, height) :
                drawLineShape(sketchingStore.sketch.pointDragger, color)
              l = toJS(line.positions._value)
            } else {
              let line = (extrudedHeight > 0 || width > 0) ?
                drawLineHorizontal(positions, color, width, extrudedHeight, height) :
                drawLineShape(positions, color)
              l = toJS(line.positions._value)
            }
            if (l) {
              return l
            }
            return []
          }
          const getShapes = () => {
            let l = undefined
            if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 && id === sketchingStore.currentSketchId) {
              let line = (extrudedHeight > 0 || width > 0) ?
                drawLineHorizontal(sketchingStore.sketch.pointDragger, color, width, extrudedHeight, height) :
                drawLineShape(sketchingStore.sketch.pointDragger, color)
              l = toJS(line.shape._value)
            } else {
              let line = (extrudedHeight > 0 || width > 0) ?
                drawLineHorizontal(positions, color, width, extrudedHeight, height) :
                drawLineShape(positions, color)
              l = toJS(line.shape._value)
            }
            if (l) {
              return l
            }
            return []
          }

          const getMaximumHeights = () => {
            if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 && id === sketchingStore.currentSketchId) {
              let line = (extrudedHeight > 0 || width > 0) ?
                drawLineHorizontal(sketchingStore.sketch.pointDragger, color, width, extrudedHeight, height) :
                drawLineShape(sketchingStore.sketch.pointDragger, color)
              return line.maximumHeights._value
            } else {
              let line = (extrudedHeight > 0 || width > 0) ?
                drawLineHorizontal(positions, color, width, extrudedHeight, height) :
                drawLineShape(positions, color)
              return toJS(line.maximumHeights._value)
            }
          }

          const getMinimumHeights = () => {
            if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 && id === sketchingStore.currentSketchId) {
              let line = (extrudedHeight > 0 || width > 0) ?
                drawLineHorizontal(sketchingStore.sketch.pointDragger, color, width, extrudedHeight, height) :
                drawLineShape(sketchingStore.sketch.pointDragger, color)
              return line.minimumHeights._value
            } else {
              let line = (extrudedHeight > 0 || width > 0) ?
                drawLineHorizontal(positions, color, width, extrudedHeight, height) :
                drawLineShape(positions, color)
              return toJS(line.minimumHeights._value)
            }
          }
          const LineHorizontalShape = (extrudedHeight > 0 || width > 0) ?
            drawLineHorizontal(positions, color, width, extrudedHeight, height) :
            drawLineShape(positions, color)

          if (LineHorizontalShape) {
            if (LineHorizontalShape instanceof CesiumCorridorGraphics) {
              shape = <CorridorGraphics
                positions={new CallbackProperty(getPositions, true)}
                height={LineHorizontalShape.height}
                extrudedHeight={LineHorizontalShape.extrudedHeight}
                width={LineHorizontalShape.width}
                shadows={ShadowMode.ENABLED}
                cornerType={CornerType.MITERED}
                material={Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha))}
              />
            }
            if (LineHorizontalShape instanceof CesiumPolylineVolumeGraphics) {
              shape = <PolylineVolumeGraphics
                shape={new CallbackProperty(getShapes, true)}
                positions={new CallbackProperty(getPositions, true)}
                cornerType={CornerType.MITERED}
                shadows={ShadowMode.ENABLED}
                material={Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha))}
              />
            }
            if (LineHorizontalShape instanceof CesiumPolylineGraphics) {
              shape = <PolylineGraphics
                positions={new Cesium.CallbackProperty(function () {
                  if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 && id === sketchingStore.currentSketchId) {
                    let _positionEntity = sketchingStore.sketch?.pointDragger.map(
                      position => new Cartesian3(position.x, position.y, position.z)
                    )
                    return _positionEntity
                  } else {
                    let _positionEntity = positions.map(
                      position => new Cartesian3(position.x, position.y, position.z)
                    )
                    return _positionEntity
                  }
                }, true)
                }
                material={LineHorizontalShape.material}
                width={LineHorizontalShape.width}
                followSurface={LineHorizontalShape.followSurface}
                shadows={ShadowMode.ENABLED}
              />
            }
            if (LineHorizontalShape instanceof CesiumWallGraphics) {
              shape = <WallGraphics
                positions={new CallbackProperty(getPositions, true)}
                maximumHeights={new CallbackProperty(getMaximumHeights, true)}
                minimumHeights={new CallbackProperty(getMinimumHeights, true)}
                material={Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha))}
                shadows={ShadowMode.ENABLED}
              />
            }
          }
          setSketchViews(sketchViews => [
            ...sketchViews,
            <Entity id={id} key={id} show={true} ref={ref}
              position={new CallbackProperty(function () {
                const height = sketch.extrudedHeight

                if (height) {
                  let _boundingSphereCenter = BoundingSphere.fromPoints(cartesians).center
                  let _refcartographic = Cartographic.fromCartesian(_boundingSphereCenter)
                  _refcartographic.height += height
                  let _positionLabel = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
                  return _positionLabel
                }
                else {
                  return cartesians[0]
                }
              }, true)}
              type="sketch">
              <>
                {shape}
                <LabelGraphics
                  {...labelDefault}
                  show={isShowName || isShowDescription}
                  text={(isShowName ? sketch.name : '') + (isShowDescription ? '\n' : '') + (isShowDescription ? sketch.description : '')}
                  disableDepthTestDistance={Number.POSITIVE_INFINITY}
                  distanceDisplayCondition={new DistanceDisplayCondition(0.0, 2000)}
                />
              </>
            </Entity>,
          ])
        } else if (type === 'area') {
          var getSketchAreaLocation = new Cesium.CallbackProperty(function () {
            if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 && id === sketchingStore.currentSketchId) {
              const cartesians = sketchingStore.sketch?.pointDragger.map(
                position => new Cartesian3(position.x, position.y, position.z)
              )
              let _cartesians = new Cesium.PolygonHierarchy(cartesians)
              return _cartesians
            } else {
              let _cartesians = new Cesium.PolygonHierarchy(cartesians)
              return _cartesians
            }

          }, true);
          let refLabel = React.createRef()
          setSketchViews(sketchViews => [
            ...sketchViews,
            <Entity id={id} key={id} show={true} ref={ref} position={_positionEntity} type="sketch">
              <>
                {extrudedHeight > 0 && !readonlyHeight ? (
                  <PolygonGraphics
                    hierarchy={getSketchAreaLocation}
                    material={Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha))}
                    height={height}
                    extrudedHeight={height + extrudedHeight}
                    perPositionHeight={true}
                    shadows={ShadowMode.ENABLED}
                  />
                ) : (
                  <PolygonGraphics
                    hierarchy={getSketchAreaLocation}
                    material={Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha))}
                    classificationType={ClassificationType.BOTH}
                    perPositionHeight={false}
                    shadows={ShadowMode.ENABLED}
                  />
                )}
                <LabelGraphics
                  {...labelDefault}
                  show={isShowName || isShowDescription}
                  text={(isShowName ? sketch.name : '') + (isShowDescription ? '\n' : '') + (isShowDescription ? sketch.description : '')}
                  disableDepthTestDistance={Number.POSITIVE_INFINITY}
                  distanceDisplayCondition={new DistanceDisplayCondition(0.0, 2000)}
                />
              </>
            </Entity>

          ])
        }
        else if (type === 'label') {
          let refLabel = React.createRef()
          setSketchViews(sketchViews => [
            ...sketchViews,
            <Entity id={id} key={id} show={true} ref={ref} position={_positionEntityLabel} type="sketch">
              <>
                <LabelGraphics
                  show={isShowName || isShowDescription}
                  text={(isShowName ? sketch.name : '') + (isShowDescription ? '\n' : '') + (isShowDescription ? sketch.description : '')}
                  font={`${sizeLabel}px ${font}`}
                  fillColor={Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha))}
                />
              </>
            </Entity>

          ])
        }
        else if (type === 'point' || sketchingStore.checkedKeysModel.length == 0) {
          const refLabel = React.createRef()
          const point0 = Cartographic.fromCartesian(cartesians[0].clone())
          let verticalP1P2 = 0
          if (cartesians.length > 1) {
            if (cartesians[0].z > cartesians[1].z) {
              let point1 = cartesians[0]
              let point2 = cartesians[1] || cartesians[0]
              let vertical = getVerticalPointClick(point1, point2)
              verticalP1P2 = vertical
              point0.height = point0.height - vertical + (sketch.extrudedHeight / 2)
            } else {
              point0.height += sketch.extrudedHeight / 2
            }
          } else {
            point0.height += sketch.extrudedHeight / 2
          }
          let _position = Cartesian3.fromDegrees(point0.longitude * CesiumMath.DEGREES_PER_RADIAN, point0.latitude * CesiumMath.DEGREES_PER_RADIAN, point0.height)
          var getSketchAreaLocation = new Cesium.CallbackProperty(function () {
            if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 && id === sketchingStore.currentSketchId) {
              let cartesians = sketchingStore.sketch?.pointDragger.map(
                position => new Cartesian3(position.x, position.y, position.z)
              )
              let _refcartographic = Cartographic.fromCartesian(cartesians[0])
              _refcartographic.height += sketch.extrudedHeight / 2

              let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
              return _positionEntity
            } else {
              return _position
            }
          }, true);
          setSketchViews(sketchViews => [
            ...sketchViews,
            <Entity id={id} key={id} show={true} ref={ref} position={getSketchAreaLocation} type="sketch" userData={{ point: cartesians }}>
              <CylinderGraphics
                material={Color.fromCssColorString(color && color.color ? color.color : '#fff').withAlpha(Utils.checkAlphaSketch(color?.alpha))}
                topRadius={sketch.width / 2}
                bottomRadius={sketch.width / 2}
                length={sketch.extrudedHeight}
                shadows={ShadowMode.ENABLED}
              />
            </Entity>,
            <Entity id={`${id}-label`} key={`${id}-label`} show={true} ref={refLabel}
              position={new CallbackProperty(function () {
                const height = ref?.current?.cesiumElement?.cylinder?.length ? ref?.current?.cesiumElement?.cylinder?.length : sketch.extrudedHeight
                if (height) {
                  const pointLabel = Cartographic.fromCartesian(cartesians[0].clone())
                  pointLabel.height = pointLabel.height - verticalP1P2
                  pointLabel.height += height
                  let _positionLabel = Cartesian3.fromDegrees(pointLabel.longitude * CesiumMath.DEGREES_PER_RADIAN, pointLabel.latitude * CesiumMath.DEGREES_PER_RADIAN, pointLabel.height)
                  return _positionLabel
                }
                else {
                  return cartesians[0]
                }
              }, true)
              } type="sketch" userData={{ point: cartesians }}>
              <LabelGraphics
                {...labelDefault}
                show={isShowName || isShowDescription}
                text={(isShowName ? sketch.name : '') + (isShowDescription ? '\n' : '') + (isShowDescription ? sketch.description : '')}
                disableDepthTestDistance={Number.POSITIVE_INFINITY}
                distanceDisplayCondition={new DistanceDisplayCondition(0.0, 2000)}
              />
            </Entity>,
          ])
          if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
      }
    }
  }

  const editNewEnitity = (cm, oldType, oldPositions, isResetSketch) => {
    if (cm?.editor) {
      cm.editor.destroy()
    }
    if (cm?.position) {
      cm.position.setCallback(cm.position._callback, true);
    }

    if (cm?.polygon?.hierarchy) {
      cm.polygon.hierarchy.setCallback(cm.polygon.hierarchy._callback, true);
    }

    if (cm) {
      let entity = cm
      if (cm?.polyline?.positions) {
        entity.polyline.positions.setCallback(entity.polyline.positions._callback, true);
      }
      if (cm?.polylineVolume?.positions) {
        entity.polylineVolume.positions.setCallback(entity.polylineVolume.positions._callback, true);
        entity.polylineVolume.shape.setCallback(entity.polylineVolume.shape._callback, true);
      }
      if (cm?.wall?.positions) {
        entity.wall.positions.setCallback(entity.wall.positions._callback, true);
        entity.wall.maximumHeights.setCallback(entity.wall.maximumHeights._callback, true);
        entity.wall.minimumHeights.setCallback(entity.wall.minimumHeights._callback, true);
      }
      if (cm?.corridor?.positions) {
        entity.corridor.positions.setCallback(entity.corridor.positions._callback, true);
      }
    }
    // Don't delete blocked code below 
    // sketchingStore.setCurrentEditEntity(false)
    if (!isResetSketch) {
      if (cm.polyline || cm.corridor || cm.polylineVolume || cm.wall) {
        CesiumDrawing.extendEntity(cm)
        const newEdit = new CesiumDrawing.Editor(viewer.current.cesiumElement)
        sketchingStore.setCurrentEditEntity(newEdit)
        cm.drawData = oldType
        cm._originalPoints = oldPositions.map(position => new Cartesian3(position.x, position.y, position.z))
        if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch?.pointDragger?.length > 0) {
          cm._originalPoints = sketchingStore.sketch.pointDragger.map(position => new Cartesian3(position.x, position.y, position.z))
        }
        newEdit.startEditing(cm)
      }
    }
  }

  function replaceNewSketchShape(o, newShape, cm) {
    

    if (sketchingStore.currentEditEntity?.currentEditEntity && sketchingStore.currentEditSketch.type === "line") {
      let entity = sketchingStore.currentEditEntity.currentEditEntity
      if (sketchingStore.currentEditEntity.currentEditEntity?.polyline) {
        entity.polyline.positions.setCallback(entity.polyline.positions._callback, true);
      }
      if (sketchingStore.currentEditEntity.currentEditEntity?.polylineVolume) {
        entity.polylineVolume.positions.setCallback(entity.polylineVolume.positions._callback, true);
        entity.polylineVolume.shape.setCallback(entity.polylineVolume.shape._callback, true);
      }
      if (sketchingStore.currentEditEntity.currentEditEntity?.wall) {
        entity.wall.positions.setCallback(entity.wall.positions._callback, true);
        entity.wall.maximumHeights.setCallback(entity.wall.maximumHeights._callback, true);
        entity.wall.minimumHeights.setCallback(entity.wall.minimumHeights._callback, true);
      }
      if (sketchingStore.currentEditEntity.currentEditEntity?.corridor) {
        entity.corridor.positions.setCallback(entity.corridor.positions._callback, true);
      }
    }

    // Don't delete blocked code below 
    // if (sketchingStore.currentEditEntity?.currentEditEntity?.position && sketchingStore.currentEditSketch.type === "point") {
    //   sketchingStore.currentEditEntity.currentEditEntity.position.setCallback(sketchingStore.currentEditEntity.currentEditEntity.position._callback, true);
    // }

    // if (sketchingStore.currentEditEntity?.currentEditEntity?.polygon?.hierarchy && sketchingStore.currentEditSketch.type === "area") {
    //   sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy.setCallback(sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy._callback, true);
    // }

    if (newShape instanceof CesiumPolylineVolumeGraphics) {
      o.ref.current.cesiumElement.polylineVolume = newShape
      if (o.ref.current.cesiumElement.polyline)
        o.ref.current.cesiumElement.polyline.show = false
      if (o.ref.current.cesiumElement.corridor)
        o.ref.current.cesiumElement.corridor.show = false
      if (o.ref.current.cesiumElement.wall)
        o.ref.current.cesiumElement.wall.show = false
      if (cm) {
        editNewEnitity(o.ref.current.cesiumElement, cm.model.type, cm.model.positions)
      }
    }
    if (newShape instanceof CesiumCorridorGraphics) {
      o.ref.current.cesiumElement.corridor = newShape
      if (o.ref.current.cesiumElement.polyline)
        o.ref.current.cesiumElement.polyline.show = false
      if (o.ref.current.cesiumElement.polylineVolume) {
        o.ref.current.cesiumElement.polylineVolume.show = false
      }
      if (o.ref.current.cesiumElement.wall) {
        o.ref.current.cesiumElement.wall.show = false
      }
      if (cm) {
        editNewEnitity(o.ref.current.cesiumElement, cm.model.type, cm.model.positions)
      }
    }
    if (newShape instanceof CesiumPolylineGraphics) {
      o.ref.current.cesiumElement.polyline = newShape
      if (o.ref.current.cesiumElement.corridor)
        o.ref.current.cesiumElement.corridor.show = false
      if (o.ref.current.cesiumElement.polylineVolume) {
        o.ref.current.cesiumElement.polylineVolume.show = false
      }
      if (o.ref.current.cesiumElement.wall) {
        o.ref.current.cesiumElement.wall.show = false
      }
      if (cm) {
        editNewEnitity(o.ref.current.cesiumElement, cm.model.type, cm.model.positions)
      }
    }
    if (newShape instanceof CesiumWallGraphics) {
      o.ref.current.cesiumElement.wall = newShape
      if (o.ref.current.cesiumElement.polyline)
        o.ref.current.cesiumElement.polyline.show = false
      if (o.ref.current.cesiumElement.corridor)
        o.ref.current.cesiumElement.corridor.show = false
      if (o.ref.current.cesiumElement.polylineVolume) {
        o.ref.current.cesiumElement.polylineVolume.show = false
      }
      if (cm) {
        editNewEnitity(o.ref.current.cesiumElement, cm.model.type, cm.model.positions)
      }
    }
  }
  
  useEffect(() => {
    setSketchViews([])
    sketches.map(sketch => addSketchView(sketch))
    if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    return () => {
      setSketchViews([])
    }
  }, [sketches, sketchingStore.visibleSketches, sketchingStore.arrSketches.length])

  useEffect(() => {
    if (sketchViews.length > 0) {
      // this Effect fix case after save edit DistanceDisplayCondition not apply
      sketchViews.forEach(element => {
        if (element?.ref?.current?.cesiumElement?.label) {
          element.ref.current.cesiumElement.label.distanceDisplayCondition = new DistanceDisplayCondition(0, 2000)
        }
      });
    }
    if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }, [sketchViews])

  useEffect(() => {
    if (sketchingStore.zoomToSketch) {
      projectStore.setLoadingProgress(false)
      let cm = getCurrentSketch(
        sketchingStore.currentSketchId,
        sketchingStore.arrSketches,
        sketchViews
      )

      if (cm && cm.tile) {
        const classificationType = cm.tile.polygon?.classificationType?.getValue()
        if (classificationType === ClassificationType.BOTH) {
          let _boundingSphere = BoundingSphere.fromPoints(cm.model.positions.map(a => new Cartesian3(a.x, a.y, a.z)))
          viewer.current.cesiumElement.camera.flyToBoundingSphere(_boundingSphere, { duration: 0 });
        }
        else {
          viewer.current.cesiumElement.zoomTo(cm.tile)
        }
      }
      sketchingStore.setZoomToSketch(false)
    }
  }, [sketchingStore.zoomToSketch])

  const changeSketchColor = (sketchId, sketchColor, alpha, isHighlight) =>{
    if (sketchId) {
      let cm = getCurrentSketch(
        sketchId,
        sketchingStore.arrSketches,
        sketchViews
      )
      let _alpha =  ((1 - alpha) || (1 - alpha) ===0) ? 1 - alpha : 1 ;
      if (cm && !getSketchLibraryModel(sketchId)) {
        const color = new Color.fromCssColorString(sketchColor).withAlpha(_alpha)
        if (cm.model.type === 'line') {
          if (cm.tile.polyline) {
            cm.tile.polyline.material.color.setValue(color)
          }
          if (cm.tile.corridor) {
            cm.tile.corridor.material.color.setValue(color)
          }
          if (cm.tile.wall) {
            cm.tile.wall.material.color.setValue(color)
          }
          if (cm.tile.polylineVolume) {
            cm.tile.polylineVolume.material.color.setValue(color)
          }
        }
        if (cm.model.type === 'area') {
          if (cm.tile.polygon) {
            cm.tile.polygon.material = color
          }
        }
        if (cm.model.type === 'point' && (!getSketchLibraryModel(sketchId) || (sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length === 0))) {
          if(isHighlight){
            if (cm.tile.cylinder) {
              cm.tile.cylinder.material = color
            }
          }else{
            if (sketchingStore.checkedKeysModel.length === 0) {
              if (cm.tile.cylinder) {
                cm.tile.cylinder.material = color
              }
            }
          }
        }
        if (cm.model.type === 'label') {
          if (cm.tile.label) {
            let fontLable = cm.tile.label.font._value.split(" ")
            sketchingStore.setSketchProps({ font: fontLable[1] })
            let sizeLabel = fontLable[0].slice(0, -2)
            sketchingStore.setSketchProps({ sizeLabel: Number(sizeLabel) })
            cm.tile.label.fillColor = color
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }

  useEffect(() => {
    //if(!projectGanttStore.isShowGanttPanel){
      changeSketchColor(sketchingStore.currentSketchId, sketchingStore.sketch.color.color, sketchingStore.sketch.color.alpha, false)
    //}
  }, [sketchingStore.sketch.color])

  useEffect(() => {
    if (sketchingStore.currentSketchId && !getSketchLibraryModel(sketchingStore.currentSketchId)) {
      let cm = getCurrentSketch(
        sketchingStore.currentSketchId,
        sketchingStore.arrSketches,
        sketchViews
      )

      if (cm) {
        const font = `${sketchingStore.sketch.sizeLabel}px ${sketchingStore.sketch.font}`
        if (cm.model.type === 'label') {
          if (cm.tile.label) {
            cm.tile.label.font._value = font
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.sketch.font])

  useEffect(() => {
    if (sketchingStore.currentSketchId && !getSketchLibraryModel(sketchingStore.currentSketchId)) {
      let cm = getCurrentSketch(
        sketchingStore.currentSketchId,
        sketchingStore.arrSketches,
        sketchViews
      )

      if (cm) {
        const size = `${sketchingStore.sketch.sizeLabel}px ${sketchingStore.sketch.font}`
        if (cm.model.type === 'label') {
          if (cm.tile.label) {
            cm.tile.label.font._value = size
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.sketch.sizeLabel])

  useEffect(() => {
    if (sketchingStore.geometrySketch?.id) {
      let cm = getCurrentSketch(
        sketchingStore.geometrySketch?.id,
        sketchingStore.arrSketches,
        sketchViews
      )
      if (cm) {
        if (cm.model.type === 'line') {
          if (cm.tile.polyline || cm.tile.corridor || cm.tile.polylineVolume || cm.tile.wall) {
            CesiumDrawing.extendEntity(cm.tile)
            const newEdit = new CesiumDrawing.Editor(viewer.current.cesiumElement)
            sketchingStore.setCurrentEditEntity(newEdit)
            cm.tile.drawData = cm.model.type
            cm.tile._originalPoints = cm.model.positions.map(position => new Cartesian3(position.x, position.y, position.z))
            newEdit.startEditing(cm.tile)
          }
        }
        if (cm.model.type === 'area') {
          if (cm.tile.polygon) {
            CesiumDrawing.extendEntity(cm.tile)
            const newEdit = new CesiumDrawing.Editor(viewer.current.cesiumElement)
            sketchingStore.setCurrentEditEntity(newEdit)
            cm.tile.drawData = cm.model.type
            cm.tile._originalPoints = cm.model.positions.map(position => new Cartesian3(position.x, position.y, position.z))
            newEdit.startEditing(cm.tile)
          }
        }
        if (cm.model.type === 'point' && !getSketchLibraryModel(sketchingStore.geometrySketch?.id)) {
          if (cm.tile.cylinder) {
            CesiumDrawing.extendEntity(cm.tile)
            const newEdit = new CesiumDrawing.Editor(viewer.current.cesiumElement)
            sketchingStore.setCurrentEditEntity(newEdit)
            cm.tile.drawData = cm.model.type
            const cartesians = cm.model.positions.map(
              position => new Cartesian3(position.x, position.y, position.z)
            )
            const point0 = Cartographic.fromCartesian(cartesians[0].clone())
            let verticalP1P2 = 0
            if (cartesians.length > 1) {
              if (cartesians[0].z > cartesians[1].z) {
                let point1 = cartesians[0]
                let point2 = cartesians[1] || cartesians[0]
                let vertical = getVerticalPointClick(point1, point2)
                verticalP1P2 = vertical
                // point0.height = point0.height - vertical + (cm.model.extrudedHeight / 2)
                point0.height = point0.height - vertical
                // point0.height += cm.model.extrudedHeight / 2
              }
            } else {
              // point0.height += cm.model.extrudedHeight / 2
            }
            let _position = Cartesian3.fromDegrees(point0.longitude * CesiumMath.DEGREES_PER_RADIAN, point0.latitude * CesiumMath.DEGREES_PER_RADIAN, point0.height)
            cm.tile._originalPoints = [_position]
            newEdit.startEditing(cm.tile)
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.geometrySketch?.id])

  useEffect(() => {
    if (sketchingStore.currentSketchId && typeof sketchingStore.sketch.width === 'number') {
      let cm = getCurrentSketch(
        sketchingStore.currentSketchId,
        sketchingStore.arrSketches,
        sketchViews
      )
      if (cm) {
        if (!getSketchLibraryModel(sketchingStore.currentSketchId)) {
          if (cm.model) {
            if (cm.model.type === 'line') {
              const extrudedShape = (sketchingStore.sketch.extrudedHeight > 0 || sketchingStore.sketch.width > 0) ?
                drawResetLineHorizontal(cm.model.positions, sketchingStore.sketch.color, sketchingStore.sketch.width, sketchingStore.sketch.extrudedHeight, cm.model.height, false) :
                drawResetLineShape(cm.model.positions, sketchingStore.sketch.color, false)
              setSketchViews(sketchViews.map((o) => {
                if (o.key === sketchingStore.currentSketchId) {
                  replaceNewSketchShape(o, extrudedShape, cm)
                }
                return o;
              }))
              if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
            } else if (cm.model.type === 'point') {
              const width = sketchingStore.sketch.width
              if (cm.tile.cylinder) {
                cm.tile.cylinder.topRadius = width / 2
                cm.tile.cylinder.bottomRadius = width / 2
              }
              let hasSketchTypeModel
              if (cm.model && sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
                hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
              }
              if (hasSketchTypeModel) {
                hasSketchTypeModel.map(item => {
                  let cmModel = getCurrentSketchModel(
                    sketchingStore.currentSketchId,
                    `${sketchingStore.currentSketchId}-${item.model.hash}`,
                    sketches,
                    sketchViews
                  )
                  if (cmModel.tile?.modelMatrix) {
                    let xTran = setSketch3DMesh(cmModel, item, false)
                    cmModel.tile.modelMatrix = xTran
                  }
                })
              }
            }
          }
          if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
        else {
          if (cm.model.type === 'line') {
            const extrudedShape = (sketchingStore.sketch.extrudedHeight > 0 || sketchingStore.sketch.width > 0) ?
              drawResetLineHorizontal(cm.model.positions, sketchingStore.sketch.color, sketchingStore.sketch.width, sketchingStore.sketch.extrudedHeight, cm.model.height, false) :
              drawResetLineShape(cm.model.positions, sketchingStore.sketch.color, false)
            setSketchViews(sketchViews.map((o) => {
              if (o.key === sketchingStore.currentSketchId) {
                replaceNewSketchShape(o, extrudedShape, cm)
              }
              return o;
            }))
          }
          if (cm.model && cm.model.type === 'point') {
            const width = sketchingStore.sketch.width
            if (cm.tile.cylinder) {
              cm.tile.cylinder.topRadius = width / 2
              cm.tile.cylinder.bottomRadius = width / 2
            }
            let hasSketchTypeModel
            if (cm.model && sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
            }
            if (hasSketchTypeModel) {
              hasSketchTypeModel.map(item => {
                let cmModel = getCurrentSketchModel(
                  sketchingStore.currentSketchId,
                  `${sketchingStore.currentSketchId}-${item.model.hash}`,
                  sketches,
                  sketchViews
                )
                if (cmModel.tile?.modelMatrix) {
                  let xTran = setSketch3DMesh(cmModel, item, false)
                  cmModel.tile.modelMatrix = xTran
                }
              })
            }
          }
          if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
      }
    }
  }, [sketchingStore.sketch.width])

  useEffect(() => {
    if (sketchingStore.currentSketchId && typeof sketchingStore.sketch.extrudedHeight === 'number') {
      let cm = getCurrentSketch(
        sketchingStore.currentSketchId,
        sketchingStore.arrSketches,
        sketchViews
      )
      if (cm) {
        if (!getSketchLibraryModel(sketchingStore.currentSketchId)) {
          if (cm.model) {
            if (cm.model.type === 'line') {
              const extrudedShape = (sketchingStore.sketch.extrudedHeight > 0 || sketchingStore.sketch.width > 0) ?
                drawResetLineHorizontal(cm.model.positions, sketchingStore.sketch.color, sketchingStore.sketch.width, sketchingStore.sketch.extrudedHeight, cm.model.height, false) :
                drawResetLineShape(cm.model.positions, sketchingStore.sketch.color, false)
              setSketchViews(sketchViews.map((o) => {
                if (o.key === sketchingStore.currentSketchId) {
                  replaceNewSketchShape(o, extrudedShape, cm)
                }
                return o;
              }))
            }
            if (cm.model.type === 'area') {
              if (cm.tile.polygon && !cm.model.readonlyHeight && sketchingStore.sketch.extrudedHeight > 0) {
                sketchingStore.setSketchProps({ height: cm.model.height })
                cm.tile.polygon.height = sketchingStore.sketch.height
                cm.tile.polygon.extrudedHeight = sketchingStore.sketch.height + sketchingStore.sketch.extrudedHeight
              }
            }
            if (cm.model.type === 'label') {
              if (cm.tile.label && !cm.model.readonlyHeight) {
                sketchingStore.setSketchProps({ sizeLabel: cm.model.sizeLabel })
              }
            }
            if (cm.model.type === 'point') {
              if (cm.tile.cylinder) {
                if (cm.model.positions && cm.model.positions.length) {
                  let activeShapePointsClone = Cartographic.fromCartesian(new Cartesian3(cm.model.positions[0].x, cm.model.positions[0].y, cm.model.positions[0].z))
                  activeShapePointsClone.height += sketchingStore.sketch.extrudedHeight / 2
                  if (cm.model.positions && cm.model.positions.length === 2 && cm.model.positions[0].z > cm.model.positions[1].z) {
                    let point1 = cm.model.positions[0]
                    let point2 = cm.model.positions[1] || cm.model.positions[0]
                    let vertical = getVerticalPointClick(point1, point2)
                    activeShapePointsClone.height = activeShapePointsClone.height - vertical
                  }
                  let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)
                  let getSketchSingleLocation = new Cesium.CallbackProperty(function () {
                    // console.log("callback height")
                    if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
                      let cartesians = sketchingStore.sketch?.pointDragger.map(
                        position => new Cartesian3(position.x, position.y, position.z)
                      )
                      let _refcartographic = Cartographic.fromCartesian(cartesians[0])
                      _refcartographic.height += sketchingStore.sketch.extrudedHeight / 2
                      let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
                      return _positionEntity
                    } else {
                      return activeShapeClone
                    }
                  }, !sketchingStore.sketchFormVisible);
                  cm.tile.position = getSketchSingleLocation
                }
                cm.tile.cylinder.length = sketchingStore.sketch.extrudedHeight
              }
            }
            let hasSketchTypeModel
            if (cm.model && sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
            }
            if (hasSketchTypeModel) {
              hasSketchTypeModel.map(item => {
                let cmModel = getCurrentSketchModel(
                  sketchingStore.currentSketchId,
                  `${sketchingStore.currentSketchId}-${item.model.hash}`,
                  sketches,
                  sketchViews
                )
                if (cmModel.tile?.modelMatrix) {
                  let xTran = setSketch3DMesh(cmModel, item, false)
                  cmModel.tile.modelMatrix = xTran
                }
              })
            }
          }
          if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
        else {
          if (cm.model.type === 'line') {
            const extrudedShape = (sketchingStore.sketch.extrudedHeight > 0 || sketchingStore.sketch.width > 0) ?
              drawResetLineHorizontal(cm.model.positions, sketchingStore.sketch.color, sketchingStore.sketch.width, sketchingStore.sketch.extrudedHeight, cm.model.height, false) :
              drawResetLineShape(cm.model.positions, sketchingStore.sketch.color, false)
            setSketchViews(sketchViews.map((o) => {
              if (o.key === sketchingStore.currentSketchId) {
                replaceNewSketchShape(o, extrudedShape, cm)
              }
              return o;
            }))
          }
          if (cm.model.type === 'area') {
            if (cm.tile.polygon && !cm.model.readonlyHeight && sketchingStore.sketch.extrudedHeight > 0) {
              sketchingStore.setSketchProps({ height: cm.model.height })
              cm.tile.polygon.height = sketchingStore.sketch.height
              cm.tile.polygon.extrudedHeight = sketchingStore.sketch.height + sketchingStore.sketch.extrudedHeight
            }
          }
          if (cm.model.type === 'label') {
            if (cm.tile.label && !cm.model.readonlyHeight) {
              sketchingStore.setSketchProps({ sizeLabel: cm.model.sizeLabel })
            }
          }
          if (cm.model && cm.model.type === 'point') {
            if (cm.tile.cylinder) {
              if (cm.model.positions && cm.model.positions.length) {
                let activeShapePointsClone = Cartographic.fromCartesian(new Cartesian3(cm.model.positions[0].x, cm.model.positions[0].y, cm.model.positions[0].z))
                activeShapePointsClone.height += sketchingStore.sketch.extrudedHeight / 2
                if (cm.model.positions && cm.model.positions.length === 2 && cm.model.positions[0].z > cm.model.positions[1].z) {
                  let point1 = cm.model.positions[0]
                  let point2 = cm.model.positions[1] || cm.model.positions[0]
                  let vertical = getVerticalPointClick(point1, point2)
                  activeShapePointsClone.height = activeShapePointsClone.height - vertical
                }
                let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)
                let getSketchSingleLocation = new Cesium.CallbackProperty(function () {
                  // console.log("callback height")
                  if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
                    let cartesians = sketchingStore.sketch?.pointDragger.map(
                      position => new Cartesian3(position.x, position.y, position.z)
                    )
                    let _refcartographic = Cartographic.fromCartesian(cartesians[0])
                    _refcartographic.height += sketchingStore.sketch.extrudedHeight / 2
                    let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
                    return _positionEntity
                  } else {
                    return activeShapeClone
                  }
                }, !sketchingStore.sketchFormVisible);
                cm.tile.position = getSketchSingleLocation
                // cm.tile.position = activeShapeClone
              }
              cm.tile.cylinder.length = sketchingStore.sketch.extrudedHeight
            }
            let hasSketchTypeModel
            if (cm.model && sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
            }
            if (hasSketchTypeModel) {
              hasSketchTypeModel.map(item => {
                let cmModel = getCurrentSketchModel(
                  sketchingStore.currentSketchId,
                  `${sketchingStore.currentSketchId}-${item.model.hash}`,
                  sketches,
                  sketchViews
                )
                if (cmModel.tile?.modelMatrix) {
                  let xTran = setSketch3DMesh(cmModel, item, false)
                  cmModel.tile.modelMatrix = xTran
                }
              })
            }
          }
          if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
      }
    }
  }, [sketchingStore.sketch.extrudedHeight])

  useEffect(() => {
    if (sketchingStore.currentSketchId && typeof sketchingStore.sketch.rotation === 'number') {
      let cm = getCurrentSketch(
        sketchingStore.currentSketchId,
        sketchingStore.arrSketches,
        sketchViews
      )
      if (cm) {
        if (getSketchLibraryModel(sketchingStore.currentSketchId)) {
          if (cm.model && cm.model.type === 'point') {
            if (cm.tile.cylinder) {
              if (cm.model.positions && cm.model.positions.length) {
                let activeShapePointsClone = Cartographic.fromCartesian(new Cartesian3(cm.model.positions[0].x, cm.model.positions[0].y, cm.model.positions[0].z))
                activeShapePointsClone.height += sketchingStore.sketch.extrudedHeight / 2
                if (cm.model.positions && cm.model.positions.length === 2 && cm.model.positions[0].z > cm.model.positions[1].z) {
                  let point1 = cm.model.positions[0]
                  let point2 = cm.model.positions[1] || cm.model.positions[0]
                  let vertical = getVerticalPointClick(point1, point2)
                  activeShapePointsClone.height = activeShapePointsClone.height - vertical
                }
                let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)
                let getSketchSingleLocation = new Cesium.CallbackProperty(function () {
                  if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
                    let cartesians = sketchingStore.sketch?.pointDragger.map(
                      position => new Cartesian3(position.x, position.y, position.z)
                    )
                    let _refcartographic = Cartographic.fromCartesian(cartesians[0])
                    _refcartographic.height += sketchingStore.sketch.extrudedHeight / 2
                    let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
                    return _positionEntity
                  } else {
                    return activeShapeClone
                  }
                }, !sketchingStore.sketchFormVisible);
                cm.tile.position = getSketchSingleLocation
                // cm.tile.position = activeShapeClone
              }
              cm.tile.cylinder.length = sketchingStore.sketch.extrudedHeight
            }
            let hasSketchTypeModel
            if (cm.model && sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
            }
            if (hasSketchTypeModel) {
              hasSketchTypeModel.map(item => {
                let cmModel = getCurrentSketchModel(
                  sketchingStore.currentSketchId,
                  `${sketchingStore.currentSketchId}-${item.model.hash}`,
                  sketches,
                  sketchViews
                )
                if (cmModel.tile?.modelMatrix) {
                  let xTran = setSketch3DMesh(cmModel, item, false)
                  cmModel.tile.modelMatrix = xTran
                }
              })
            }
          }
          if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
      }
    }
  }, [sketchingStore.sketch.rotation])

  const ckeck3dMesh = (checkedKeys) => {
    let hasSketchTypeModel
    if (sketchingStore.sketchLibraryElements && checkedKeys && checkedKeys.length > 0) {
      if (sketchingStore.sketchLibraryElements.length > 0) {
        let data = sketchingStore.sketchLibraryElements
        let result = []
        let _checkedKey = checkedKeys
        _checkedKey.map(key => {
          let node = TreeUtils.searchTreeNode(data, 'key', key);
          if (node && node.model) {
            result.push(toJS(node))
          }
        })
        hasSketchTypeModel = result.length > 0 ? result : false
      }
    }
    return hasSketchTypeModel
  }
  const setSketch3DMesh = (cmModel, item, status) => {
    const cartesians = cmModel.model.positions.map(
      position => new Cartesian3(position.x, position.y, position.z)
    )
    const point0 = Cartographic.fromCartesian(cartesians[0].clone())
    const point00 = point0.clone()
    if (status) {
      point0.height += (cmModel.model.extrudedHeight / 2);
    } else {
      point0.height += (sketchingStore.sketch.extrudedHeight / 2);
    }
    let verticalP1P2
    if (cartesians.length > 1 && cmModel.model.type === 'point') {
      if (cartesians[0].z > cartesians[1].z) {
        let point1 = cartesians[0]
        let point2 = cartesians[1] || cartesians[0]
        let vertical = getVerticalPointClick(point1, point2)
        verticalP1P2 = vertical
        point00.height = point00.height - vertical
        point0.height = point0.height - vertical
      }
    }
    let _positionModel = Cartesian3.fromDegrees(point00.longitude * CesiumMath.DEGREES_PER_RADIAN, point00.latitude * CesiumMath.DEGREES_PER_RADIAN, point00.height)
    let localFrame
    if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
      let _cartesians = sketchingStore.sketch?.pointDragger.map(
        position => new Cartesian3(position.x, position.y, position.z)
      )
      let _refcartographic = Cartographic.fromCartesian(_cartesians[0])
      let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
      localFrame = Transforms.eastNorthUpToFixedFrame(_positionEntity);
    } else {
      localFrame = Transforms.eastNorthUpToFixedFrame(_positionModel);
    }
    const width = status ? cmModel.model.width : sketchingStore.sketch.width;
    const extrudedHeight = status ? cmModel.model.extrudedHeight : sketchingStore.sketch.extrudedHeight;
    const rotation = status ? cmModel.model.rotation : sketchingStore.sketch.rotation;
    const { Scale_X, Scale_Y, Scale_Z } = Utils.getSketchScale(item.defaultLength, item.defaultWidth, item.defaultHeight, width, extrudedHeight);
    const xTran = getSketchMatrix(Scale_X, Scale_Y, Scale_Z, rotation, localFrame);
    return xTran
  }

  useEffect(() => {
    if (sketchingStore.resetSketch) {
      let cm = getCurrentSketch(
        sketchingStore.resetSketch,
        sketchingStore.arrSketches,
        sketchViews
      )
      if (cm) {
        // if (!getSketchLibraryModel(sketchingStore.resetSketch)) {
        if (cm.model) {
          if (cm.model.type === 'line') {
            const extrudedShape = (cm.model.extrudedHeight > 0 || cm.model.width > 0) ?
              drawResetLineHorizontal(cm.model.positions, cm.model.color, cm.model.width, cm.model.extrudedHeight, cm.model.height) :
              drawResetLineShape(cm.model.positions, cm.model.color)
            setSketchViews(sketchViews.map((o) => {
              if (o.key === sketchingStore.resetSketch) {
                replaceNewSketchShape(o, extrudedShape)
                editNewEnitity(o.ref.current.cesiumElement, cm.model.type, cm.model.positions, true)
              }
              return o;
            }))
          }
          if (cm.model.type === 'area') {
            if (cm.tile.polygon) {
              cm.tile.polygon.material = new Color.fromCssColorString(cm.model.color.color).withAlpha(Utils.checkAlphaSketch(cm.model.color.alpha))
              if (!cm.model.readonlyHeight && cm.model.extrudedHeight > 0) {
                cm.tile.polygon.height = cm.model.height
                cm.tile.polygon.extrudedHeight = cm.model.height + cm.model.extrudedHeight
              }
            }
          }
        }
        // }
        // else {
        if (cm.model && cm.model.type === 'point') {
          if (!getSketchLibraryModel(sketchingStore.resetSketch)) {
            setSketchViews(deleteSketchModel())
            if (cm.tile.cylinder) {
              if (cm.model.positions && cm.model.positions.length) {
                let activeShapePointsClone = Cartographic.fromCartesian(new Cartesian3(cm.model.positions[0].x, cm.model.positions[0].y, cm.model.positions[0].z))
                activeShapePointsClone.height += cm.model.extrudedHeight / 2
                if (cm.model.positions && cm.model.positions.length === 2 && cm.model.positions[0].z > cm.model.positions[1].z) {
                  let point1 = cm.model.positions[0]
                  let point2 = cm.model.positions[1] || cm.model.positions[0]
                  let vertical = getVerticalPointClick(point1, point2)
                  activeShapePointsClone.height = activeShapePointsClone.height - vertical
                }
                let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)
                let getSketchAreaLocation = new Cesium.CallbackProperty(function () {
                  if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
                    let cartesians = sketchingStore.sketch?.pointDragger.map(
                      position => new Cartesian3(position.x, position.y, position.z)
                    )
                    let _refcartographic = Cartographic.fromCartesian(cartesians[0])
                    _refcartographic.height += cm.model.extrudedHeight / 2
                    let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
                    return _positionEntity
                  } else {
                    return activeShapeClone
                  }
                }, true);
                cm.tile.position = getSketchAreaLocation
              }
              cm.tile.cylinder.material = new Color.fromCssColorString(cm.model.color.color).withAlpha(Utils.checkAlphaSketch(cm.model.color.alpha))
              cm.tile.cylinder.topRadius = cm.model.width / 2
              cm.tile.cylinder.bottomRadius = cm.model.width / 2
              cm.tile.cylinder.length = cm.model.extrudedHeight
            }
          } else {
            setSketchViews(deleteSketchModel())
            if (cm.tile.cylinder) {
              if (cm.model.positions && cm.model.positions.length) {
                let activeShapePointsClone = Cartographic.fromCartesian(new Cartesian3(cm.model.positions[0].x, cm.model.positions[0].y, cm.model.positions[0].z))
                activeShapePointsClone.height += cm.model.extrudedHeight / 2
                if (cm.model.positions && cm.model.positions.length === 2 && cm.model.positions[0].z > cm.model.positions[1].z) {
                  let point1 = cm.model.positions[0]
                  let point2 = cm.model.positions[1] || cm.model.positions[0]
                  let vertical = getVerticalPointClick(point1, point2)
                  activeShapePointsClone.height = activeShapePointsClone.height - vertical
                }
                let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)
                let getSketchAreaLocation = new Cesium.CallbackProperty(function () {
                  if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
                    let cartesians = sketchingStore.sketch?.pointDragger.map(
                      position => new Cartesian3(position.x, position.y, position.z)
                    )
                    let _refcartographic = Cartographic.fromCartesian(cartesians[0])
                    _refcartographic.height += cm.model.extrudedHeight / 2
                    let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
                    return _positionEntity
                  } else {
                    return activeShapeClone
                  }
                }, true);
                cm.tile.position = getSketchAreaLocation
              }
              cm.tile.cylinder.material = new Color.fromCssColorString(cm.model.color.color).withAlpha(0)
              cm.tile.cylinder.topRadius = cm.model.width / 2
              cm.tile.cylinder.bottomRadius = cm.model.width / 2
              cm.tile.cylinder.length = cm.model.extrudedHeight
            }
            let hasSketchTypeModel
            if (cm.model && cm.model.typeLibrary && cm.model.typeLibrary.checkedKeys.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(cm.model.typeLibrary.checkedKeys)
            }
            if (hasSketchTypeModel) {
              let _model = hasSketchTypeModel.map(item => {
                let cmModel = getCurrentSketchModel(
                  sketchingStore.resetSketch,
                  `${sketchingStore.resetSketch}-${item.model.hash}`,
                  sketches,
                  sketchViews
                )
                if (!cmModel && sketchViews.filter(x => x.key == `${sketchingStore.resetSketch}-${item.model.hash}`).length == 0) {
                  let xTran = setSketch3DMesh(cm, item, true)
                  let refModel = React.createRef()
                  return (
                    <Model
                      type="sketch"
                      url={item.model.src}
                      id={`${cm.model.id}-${item.model.hash}`}
                      sketch_id={cm.model.id}
                      key={`${cm.model.id}-${item.model.hash}`}
                      modelMatrix={xTran}
                      ref={refModel}
                    />
                  )
                } else {
                  if (cmModel.tile) {
                    let xTran = setSketch3DMesh(cmModel, item, true)
                    cmModel.tile.modelMatrix = xTran
                  }
                }
              })
              var _modelFilter = _model.filter(x => x)
              if (_modelFilter) {
                setSketchViews(sketchViews => [
                  ...sketchViews, ..._modelFilter
                ])
              }
              if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
              function requestRenderBug() {
                count += 1
                if (!viewer.current.cesiumElement.scene.isDestroyed()) {
                  viewer.current.cesiumElement.scene.requestRender();
                  count > 5 ? clearTimeout(timeout) : setTimeout(requestRenderBug, 1000);
                }
              }
              let timeout = setTimeout(requestRenderBug, 1000);
            }
          }
        }
        // }
      }
      sketchingStore.setResetSketch(false)
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.resetSketch])

  const [sketchTypeLibrary, setSketchTypeLibrary] = useState([])

  useEffect(() => {
    if (sketchingStore.currentSketchId) {
      let cm = getCurrentSketch(
        sketchingStore.currentSketchId,
        sketchingStore.arrSketches,
        sketchViews
      )
      let hasSketchTypeModel
      if (cm.model && cm.model.typeLibrary && cm.model.typeLibrary.checkedKeys.length > 0) {
        hasSketchTypeModel = ckeck3dMesh(cm.model.typeLibrary.checkedKeys)
      }
      if (hasSketchTypeModel) {
        hasSketchTypeModel.map(item => {
          setSketchTypeLibrary(prev => [...prev, `${cm.model.id}-${item.model.hash}`])
        })
      }
    }
  }, [sketchingStore.currentSketchId])

  const deleteSketchModel = () => {
    let sketc3dview = sketchViews
    sketchModelDelete.map((item) => {
      if (!sketchTypeLibrary.includes(item)) {
        return sketc3dview = sketc3dview.filter(x => x.key !== item)
      }
    })
    return sketc3dview
  }

  useEffect(() => {
    if (sketchingStore.resetGeometrySketch) {
      let cm = getCurrentSketch(
        sketchingStore.resetGeometrySketch.id,
        sketchingStore.arrSketches,
        sketchViews
      )
      if (cm) {
        // if (!getSketchLibraryModel(sketchingStore.resetGeometrySketch.id)) {
        if (cm.model) {
          if (cm.model.type === 'line') {
            const extrudedShape = (sketchingStore.resetGeometrySketch.extrudedHeight > 0 || sketchingStore.resetGeometrySketch.width > 0) ?
              drawResetLineHorizontal(cm.model.positions, cm.model.color, cm.model.width, cm.model.extrudedHeight, cm.model.height) :
              drawResetLineShape(cm.model.positions, cm.model.color)
            setSketchViews(sketchViews.map((o) => {
              if (o.key === sketchingStore.resetGeometrySketch.id) {
                replaceNewSketchShape(o, extrudedShape)
                editNewEnitity(o.ref.current.cesiumElement, cm.model.type, cm.model.positions, true)
              }
              return o;
            }))
          }
          if (cm.model.type === 'area') {
            if (cm.tile.polygon) {
              cm.tile.polygon.material = new Color.fromCssColorString(cm.model.color.color).withAlpha(Utils.checkAlphaSketch(cm.model.color.alpha))
              if (!cm.model.readonlyHeight && cm.model.extrudedHeight > 0) {
                cm.tile.polygon.height = cm.model.height
                cm.tile.polygon.extrudedHeight = cm.model.height + cm.model.extrudedHeight
              }
            }
          }
          if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
        // }
        // else {
        if (cm.model && cm.model.type === 'point') {
          if (!getSketchLibraryModel(sketchingStore.resetGeometrySketch.id)) {
            setSketchViews(deleteSketchModel())
            if (cm.tile.cylinder) {
              if (cm.model.positions && cm.model.positions.length) {
                let activeShapePointsClone = Cartographic.fromCartesian(new Cartesian3(cm.model.positions[0].x, cm.model.positions[0].y, cm.model.positions[0].z))
                activeShapePointsClone.height += cm.model.extrudedHeight / 2
                if (cm.model.positions && cm.model.positions.length === 2 && cm.model.positions[0].z > cm.model.positions[1].z) {
                  let point1 = cm.model.positions[0]
                  let point2 = cm.model.positions[1] || cm.model.positions[0]
                  let vertical = getVerticalPointClick(point1, point2)
                  activeShapePointsClone.height = activeShapePointsClone.height - vertical
                }
                let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)
                let getSketchAreaLocation = new Cesium.CallbackProperty(function () {
                  if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
                    let cartesians = sketchingStore.sketch?.pointDragger.map(
                      position => new Cartesian3(position.x, position.y, position.z)
                    )
                    let _refcartographic = Cartographic.fromCartesian(cartesians[0])
                    _refcartographic.height += cm.model.extrudedHeight / 2
                    let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
                    return _positionEntity
                  } else {
                    return activeShapeClone
                  }
                }, true);
                cm.tile.position = getSketchAreaLocation
              }
              cm.tile.cylinder.material = new Color.fromCssColorString(cm.model.color.color).withAlpha(Utils.checkAlphaSketch(cm.model.color.alpha))
              cm.tile.cylinder.topRadius = cm.model.width / 2
              cm.tile.cylinder.bottomRadius = cm.model.width / 2
              cm.tile.cylinder.length = cm.model.extrudedHeight
            }
          } else {
            setSketchViews(deleteSketchModel())
            if (cm.tile.cylinder) {
              if (cm.model.positions && cm.model.positions.length) {
                let activeShapePointsClone = Cartographic.fromCartesian(new Cartesian3(cm.model.positions[0].x, cm.model.positions[0].y, cm.model.positions[0].z))
                activeShapePointsClone.height += cm.model.extrudedHeight / 2
                if (cm.model.positions && cm.model.positions.length === 2 && cm.model.positions[0].z > cm.model.positions[1].z) {
                  let point1 = cm.model.positions[0]
                  let point2 = cm.model.positions[1] || cm.model.positions[0]
                  let vertical = getVerticalPointClick(point1, point2)
                  activeShapePointsClone.height = activeShapePointsClone.height - vertical
                }
                let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)
                let getSketchAreaLocation = new Cesium.CallbackProperty(function () {
                  if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
                    let cartesians = sketchingStore.sketch?.pointDragger.map(
                      position => new Cartesian3(position.x, position.y, position.z)
                    )
                    let _refcartographic = Cartographic.fromCartesian(cartesians[0])
                    _refcartographic.height += cm.model.extrudedHeight / 2
                    let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
                    return _positionEntity
                  } else {
                    return activeShapeClone
                  }
                }, true);
                cm.tile.position = getSketchAreaLocation
              }
              cm.tile.cylinder.topRadius = cm.model.width / 2
              cm.tile.cylinder.bottomRadius = cm.model.width / 2
              cm.tile.cylinder.length = cm.model.extrudedHeight
              cm.tile.cylinder.material = new Color.fromCssColorString(cm.model.color.color).withAlpha(0)
            }
            let hasSketchTypeModel
            if (cm.model && cm.model.typeLibrary && cm.model.typeLibrary.checkedKeys.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(cm.model.typeLibrary.checkedKeys)
            }
            if (hasSketchTypeModel) {
              let _model = hasSketchTypeModel.map(item => {
                let cmModel = getCurrentSketchModel(
                  sketchingStore.resetGeometrySketch.id,
                  `${sketchingStore.resetGeometrySketch.id}-${item.model.hash}`,
                  sketches,
                  sketchViews
                )
                if (sketchViews.filter(x => x.key == `${sketchingStore.resetGeometrySketch.id}-${item.model.hash}`).length == 0) {
                  let xTran = setSketch3DMesh(cm, item, true)
                  let refModel = React.createRef()
                  return (
                    <Model
                      type="sketch"
                      url={item.model.src}
                      id={`${cm.model.id}-${item.model.hash}`}
                      sketch_id={cm.model.id}
                      key={`${cm.model.id}-${item.model.hash}`}
                      modelMatrix={xTran}
                      ref={refModel}
                    />
                  )
                } else {
                  if (cmModel.tile?.modelMatrix) {
                    let xTran = setSketch3DMesh(cmModel, item, true)
                    cmModel.tile.modelMatrix = xTran
                  }
                }
              })
              var _modelFilter = _model.filter(x => x)
              if (_modelFilter) {
                setSketchViews(sketchViews => [
                  ...sketchViews, ..._modelFilter
                ])
                if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
              }
              function requestRenderBug() {
                count += 1
                if (!viewer.current.cesiumElement.scene.isDestroyed()) {
                  viewer.current.cesiumElement.scene.requestRender();
                  count > 5 ? clearTimeout(timeout) : setTimeout(requestRenderBug, 1000);
                }
              }
              let timeout = setTimeout(requestRenderBug, 1000);
            }
          }
          if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
        // }
      }
    }
  }, [sketchingStore.resetGeometrySketch])

  useEffect(() => {
    if (sketchingStore.currentSketchId && sketchingStore.checkedKeysModel) {
      let cm = getCurrentSketch(
        sketchingStore.currentSketchId,
        sketchingStore.arrSketches,
        sketchViews
      )
      const removeOldPointDragger = () => {
        if (sketchingStore?.currentEditEntity?.currentEditEntity?.editor) {
          sketchingStore.currentEditEntity.currentEditEntity.editor.destroy()
          sketchingStore.setCurrentEditEntity(false)
        }
      }

      if (cm && cm.model.type === 'point') {
        //remove current point dragger when user seleted 3dmesh model
        if (sketchingStore.checkedKeysModel.length > 0) {
          removeOldPointDragger()
        } else {
          removeOldPointDragger()
          // remove 3dmesh and draw new point dragger to edit original cylinder
          CesiumDrawing.extendEntity(cm.tile)
          const newEdit = new CesiumDrawing.Editor(viewer.current.cesiumElement)
          sketchingStore.setCurrentEditEntity(newEdit)
          cm.tile.drawData = cm.model.type
          const cartesians = cm.model.positions.map(
            position => new Cartesian3(position.x, position.y, position.z)
          )
          let point0 = Cartographic.fromCartesian(cartesians[0].clone())
          let verticalP1P2 = 0
          if (sketchingStore.sketch?.pointDragger && sketchingStore.sketch.pointDragger?.length > 0) {
            const cartesianDragger = sketchingStore.sketch?.pointDragger
            point0 = Cartographic.fromCartesian(cartesianDragger[0].clone())
          }
          if (cartesians.length > 1) {
            //switch point if P1.Z > P2.Z
            if (cartesians[0].z > cartesians[1].z) {
              let point1 = cartesians[0]
              let point2 = cartesians[1] || cartesians[0]
              let vertical = getVerticalPointClick(point1, point2)
              verticalP1P2 = vertical
              if (sketchingStore.sketch.pointDragger.length === 0) {
                point0.height = point0.height - vertical
              }
            }
          }
          let _position = Cartesian3.fromDegrees(point0.longitude * CesiumMath.DEGREES_PER_RADIAN, point0.latitude * CesiumMath.DEGREES_PER_RADIAN, point0.height)
          cm.tile._originalPoints = [_position]
          newEdit.startEditing(cm.tile)
        }
        if (sketchingStore.checkedKeysModel.length > 0) {
          if (cm.model.type === 'point') {
            const color = new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(0)
            if (cm.tile.cylinder) {
              cm.tile.cylinder.material = color
            }
            let hasSketchTypeModel
            let deleteSketch = []
            checkedKeysModelOld.map((item) => {
              if (!sketchingStore.checkedKeysModel.includes(item)) {
                deleteSketch.push(item)
              }
            })
            let hasSketch = ckeck3dMesh(deleteSketch)
            if (hasSketch) {
              hasSketch.map((item) => {
                setSketchModelDelete(sketchModelDelete.filter(x => x && x !== `${sketchingStore.currentSketchId}-${item.model.hash}`))
                setSketchViews(sketchViews.filter(x => x && x.key !== `${sketchingStore.currentSketchId}-${item.model.hash}`))
                if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
              })
            }
            if (sketchingStore.sketchLibraryElements && sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
            }
            if (hasSketchTypeModel) {
              setCheckedKeysModelOld(sketchingStore.checkedKeysModel)
              let _model = hasSketchTypeModel.map((item) => {
                let cmModel = getCurrentSketchModel(
                  sketchingStore.currentSketchId,
                  `${sketchingStore.currentSketchId}-${item.model.hash}`,
                  sketches,
                  sketchViews
                )
                if (!cmModel) {
                  let xTran = setSketch3DMesh(cm, item, false)
                  let refModel = React.createRef()
                  setSketchModelDelete(prev => [...prev, `${cm.model.id}-${item.model.hash}`])
                  return (
                    <Model
                      type="sketch"
                      url={item.model.src}
                      id={`${cm.model.id}-${item.model.hash}`}
                      sketch_id={cm.model.id}
                      key={`${cm.model.id}-${item.model.hash}`}
                      modelMatrix={xTran}
                      ref={refModel}
                    />
                  )
                }
              })
              var _modelFilter = _model.filter(x => x)
              if (_modelFilter) {
                setSketchViews(sketchViews => [
                  ...sketchViews, ..._modelFilter
                ])
                if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
              }
              function requestRenderBug() {
                count += 1
                if (!viewer.current.cesiumElement.scene.isDestroyed()) {
                  viewer.current.cesiumElement.scene.requestRender();
                  count > 5 ? clearTimeout(timeout) : setTimeout(requestRenderBug, 1000);
                }
              }
              let timeout = setTimeout(requestRenderBug, 1000);
            }
          }
        } else {
          let _alpha = Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)
          const color = new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(_alpha)
          let deleteSketch = []
          checkedKeysModelOld.map((item) => {
            if (!sketchingStore.checkedKeysModel.includes(item)) {
              deleteSketch.push(item)
            }
          })
          let hasSketch = ckeck3dMesh(deleteSketch)
          if (hasSketch) {
            hasSketch.map((item) => {
              setSketchModelDelete(sketchModelDelete.filter(x => x && x !== `${sketchingStore.currentSketchId}-${item.model.hash}`))
              setSketchViews(sketchViews.filter(x => x && x.key !== `${sketchingStore.currentSketchId}-${item.model.hash}`))
              if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
            })
          }
          setCheckedKeysModelOld([])
          if (cm.model.type === 'point') {
            if (cm.tile.cylinder) {
              cm.tile.cylinder.material = color
            }
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.checkedKeysModel])

  const isIdInList = (id, list) => {
    return list.some(obj => obj.sketchId=== id);
  }

  useEffect(() => {
    if (sketchingStore.visibleSketches?.length > 0) {
      const visibleSketchViewIds = sketchingStore.visibleSketches.map(item => {
        if (item.isVisible) {
          return item.sketchId
        }
      })
      sketchViews.forEach(sketchView => {
        if (sketchView.ref) {
          const sketchElement = sketchView.ref.current.cesiumElement
          if(!sketchElement) return ;
          // tileElement.show = visibleTileViewIds.indexOf(tileView.key) > -1  
          let isShow = false;
          // if (!(visibleSketchViewIds.indexOf(sketchView.key) > -1)) {
            if(isIdInList(sketchView.key,userGroupStore.nodeNoAccessRights)){
              isShow = false;
            }else{
              isShow = visibleSketchViewIds.find(x => x === sketchView.key || sketchView.key.indexOf(x) > -1);
            }
          if (sketchElement.show instanceof CallbackProperty) {
            //skip callback entity
          }
          else {
            if (isShow) {
              sketchElement.show = true
            } else {
              sketchElement.show = false
            }
          }
        }
      })
      sketchingStore.setSketchView(sketchViews)
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.visibleSketches,userGroupStore.nodeNoAccessRights])

  useEffect(() => {
    if(topicStore.isTopic3DObjectsVisible) return ;
    if (projectGanttStore.isShowGanttPanel) {
      if (projectGanttStore.listHighlightSketch?.length > 0) {
        projectGanttStore.listHighlightSketch.forEach(sketch =>
          changeSketchColor(
            sketch.id,
            sketch.highlightColor || '#ff0000',
            sketch.highlightAlpha || 0.5,
            true
          )
        )
      } else {
        sketchingStore.arrSketches?.length > 0 &&
          sketchingStore.arrSketches.forEach(sketch =>
            changeSketchColor(
              sketch.id,
              sketch.color.color,
              sketch.color.alpha,
              false
            )
          )
      }
      return
    }
    if (!projectGanttStore.isShowGanttPanel) {
      if (sketchingStore.arrSketches?.length) {
        if (projectGanttStore.listHighlightSketch?.length > 0) {
          projectGanttStore.listHighlightSketch.forEach(sketch => {
            const matchingSketch = sketchingStore.arrSketches.find(
              s => s.id === sketch.id
            )
            if (matchingSketch) {
              changeSketchColor(
                matchingSketch.id,
                matchingSketch.color.color,
                matchingSketch.color.alpha,
                false
              )
            }
          })
          projectGanttStore.setListHighlightSketch([])
        } else {
          sketchingStore.arrSketches.forEach(sketch =>
            changeSketchColor(
              sketch.id,
              sketch.color.color,
              sketch.color.alpha,
              false
            )
          )
        }
      }
    }
  }, [
    projectGanttStore.listHighlightSketch,
    sketchViews,
    projectGanttStore.isShowGanttPanel,
    topicStore.isTopic3DObjectsVisible
  ])

  //highlight sketch object link to topic
  useEffect(() => {
    if(projectGanttStore.isShowGanttPanel) return ;
    if (topicStore.isTopic3DObjectsVisible) {
      if (topicStore.sketchObjectHighLights?.length > 0) {
        topicStore.sketchObjectHighLights.forEach(sketch =>
          changeSketchColor(
            sketch.id,
            '#ff0000',
            0,
            true
          )
        )
      } else {
        sketchingStore.arrSketches?.length > 0 &&
          sketchingStore.arrSketches.forEach(sketch =>
            changeSketchColor(
              sketch.id,
              sketch.color.color,
              sketch.color.alpha,
              false
            )
          )
      }
      return
    }
    if (!topicStore.isTopic3DObjectsVisible) {
      if (sketchingStore.arrSketches?.length) {
        if (topicStore.sketchObjectHighLights?.length > 0) {
          topicStore.sketchObjectHighLights.forEach(sketch => {
            const matchingSketch = sketchingStore.arrSketches.find(
              s => s.id === sketch.id
            )
            if (matchingSketch) {
              changeSketchColor(
                matchingSketch.id,
                matchingSketch.color.color,
                matchingSketch.color.alpha,
                false
              )
            }
          })
          topicStore.setSketchObjectHighLights([])
        } else {
          sketchingStore.arrSketches.forEach(sketch =>
            changeSketchColor(
              sketch.id,
              sketch.color.color,
              sketch.color.alpha,
              false
            )
          )
        }
      }
    }
  }, [
    topicStore.sketchObjectHighLights,
    sketchViews,
    topicStore.isTopic3DObjectsVisible,
    projectGanttStore.isShowGanttPanel
  ])

  const setFeaturesColor = (features, color) => {
    features.map(feature => {
      if (!feature?.isColorEntitiesValue) {
        feature.color = Color.clone(color, feature.color);
      }
    })
    if (viewer.current.cesiumElement.scene.requestRenderMode) {
      viewer.current.cesiumElement.scene.requestRender();
    }
  }

  //highlight sketch mesh object link to topic
  useEffect(() => {
    if (projectGanttStore.isShowGanttPanel) return;
    if (topicStore.isTopic3DObjectsVisible) {
      if (topicStore.sketchObjectHighLights?.length > 0) {
        topicStore.sketchObjectHighLights.map(sketch => {
          if (sketch?.type === 'sketch') {
            let model = sketchViews.find(c => c.key === sketch.id)
            if (model) {
              let _model = model?.ref?.current?.cesiumElement ;
              if(_model){
                setFeaturesColor([_model], Color.RED)
              }
            }
          }
        }
        )
      }else{
        sketchViews.map(c => {
          let _model = c?.ref?.current?.cesiumElement ;
          if(!_model) return ;
          // if (_model instanceof Cesium.ModelNode){
            setFeaturesColor([_model], Color.WHITE)
          // }
        })
      }
    }
    if (!topicStore.isTopic3DObjectsVisible) {
      if (topicStore.sketchObjectHighLights?.length > 0) {
        topicStore.sketchObjectHighLights.map(sketch => {
          if (sketch?.type === 'sketch') {
            let model = sketchViews.find(c => c.key === sketch.id)
            if (model) {
              let _model = model?.ref?.current?.cesiumElement ;
              if(_model){
                setFeaturesColor([_model], Color.RED)
              }
            }
          }
        }
        )
      }else{
        sketchViews.map(c => {
          let _model = c?.ref?.current?.cesiumElement ;
          if(!_model) return ;
          // if (_model instanceof Cesium.ModelNode){
            setFeaturesColor([_model], Color.WHITE)
          // }
        })
      }
    }
  }, [
    topicStore.sketchObjectHighLights,
    sketchViews,
    topicStore.isTopic3DObjectsVisible,
    projectGanttStore.isShowGanttPanel
  ])
  
  return <>{sketchViews.map(sketchView => sketchView)}</>
}

export default inject('projectStore', 'sketchingStore','userGroupStore', 'projectGanttStore','topicStore')(observer(SketchView))
