import { Button, Row, Col, Modal } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import './style.js'
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom';
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { TableLicenses } from './style.js'


function LicenseExpirationNotificationModal({ projectStore, commonStore }) {
    const { t } = useTranslation();
    const [dontShowAgain, setDontShowAgain] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })
    const isFooterOrMobile = useMediaQuery({ query: '(max-width: 450px)' })
    const { projectId } = useParams()
    moment.locale(`${commonStore.language}`)

    const onChange = () => {
        setDontShowAgain(!dontShowAgain)
    }

    const handleCloseTwinGuide = () => {
        projectStore.setShowDialogExpirationLicense(false)
    }

    const handleOk = () => {
        localStorage.setItem(`show_expiration_notification-${projectId}`, JSON.stringify(dontShowAgain));
        projectStore.setShowDialogExpirationLicense(false)
    }

    return (
        <Modal
            width={800}
            className={'expiration-modal'}
            maskClosable={false}
            visible={projectStore.showDialogExpirationLicense}
            onOk={handleOk}
            centered
            title="License Expiration Notification"
            onCancel={handleCloseTwinGuide}
            footer={
                <div className={isFooterOrMobile ? '' : 'footer-modal'} >
                    <Checkbox onChange={onChange} style={isTabletOrMobile ? {} : { marginTop: "5px" }}>{t('do-not-display-this-again')}</Checkbox>
                    <div style={isTabletOrMobile ? { textAlign: 'right' } : {}} >
                        <Button key="back" onClick={handleCloseTwinGuide} size={isTabletOrMobile ? 'small' : 'default'}>
                            {t('commons.cancel')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleOk}
                            size={isTabletOrMobile ? 'small' : 'default'}
                        >
                            {t('commons.ok')}
                        </Button>
                    </div>
                </div>
            }
        >
            <Row gutter={[8, 8]}>
                <Col span={24}>Some of your licenses will expire in :</Col>
                <Col span={24}>
                    {
                        projectStore.expirationLicenses.length > 0 && (
                            <TableLicenses>
                                <tbody>
                                    <tr>
                                        <th>{t('type')}</th>
                                        <th>{t('activation')}</th>
                                        <th>{t('expiration')}</th>
                                    </tr>
                                    {
                                        projectStore.expirationLicenses.map((_licen, index) => (
                                            <tr key={index}>
                                                <td> {_licen.licensetype ? _licen.licensetype.name : _licen.licenceKey}</td>
                                                <td>{moment(_licen.activated).format('YYYY-MM-DD')}</td>
                                                <td>{moment(_licen.expiration).format('YYYY-MM-DD')}</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </TableLicenses>
                        )
                    }
                </Col>
                <Col span={24}>Please renew your licenses before they expire. When licenses expire you will still be able to view the projects but not any more edit them. Please contact 6DPlanner sales to renew license.</Col>
            </Row>
        </Modal>
    )
}

export default withRouter(inject('projectStore', 'commonStore')(observer(LicenseExpirationNotificationModal)))
