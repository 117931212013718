import React from 'react'
import { Layout } from 'antd'
import './PageTemplate.css'

import ToolbarLeft from '../Toolbar/ToolbarLeft'
import ToolbarTop from '../Toolbar/ToolbarTop'
import DrawerCityDemo from '../MainDrawer'

const { Content } = Layout

const CityDemoTemplate = ({ children }) => {
  return (
    <Layout>
      <Layout style={{
        background: '#eee',
        padding: 0,
      }}>
        <ToolbarTop currentPage="cityDemo"/>
        <ToolbarLeft currentPage="cityDemo"/>
        <DrawerCityDemo/>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  )
}

export default CityDemoTemplate
