import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  FileSyncOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Layout,
  message,
  Popover,
  Popconfirm,
  Row,
  Table,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import AdminTemplate from '../../../layout/AdminTemplate'
import { CustomTopTitle } from './CustomStyled'
import { useTranslation } from 'react-i18next'
import ModalAddEditGEOID from './ModalAddEditGEOID'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CustomContentAdminWrapper, HeaderAdmin ,PaginationAdmin} from '../style'
import settings from '../../../../siteConfig'
const { Content } = Layout

const GEOIDModels = props => {
  const { commonStore, adminStore } = props
  const { t } = useTranslation()
  const [isLoadingTable, setisLoadingTable] = useState(false)
  const [reLoad, setReload] = useState(false)
  const [geoidIniS3Url, setGeoidIniS3Url] = useState()
  const [isLoadingGenerate, setLoadingGenerate] = useState(false)
  const isMobile = useMediaQuery({query: '(max-width: 600px)'})
  useEffect(() => {
    adminStore.clearStatus()
    commonStore.setCurrentPage('admin/geoid-models')
    getListGEOID()

    return () => {
      adminStore.clearListGEOID()
      setReload(false)
      setGeoidIniS3Url()
      setLoadingGenerate(false)
    }
  }, [])

  // effect for case add, edit, delete reload list geoid
  useEffect(() => {
    if (reLoad) {
      getListGEOID()
      setReload(false)
    }
  }, [reLoad])

  const getListGEOID = () => {
    setisLoadingTable(true)
    adminStore
      .getListGEOID()
      .then(() => {
        setisLoadingTable(false)
      })
      .catch(err => {
        message.error(t(err.data.message|| 'an-error-occurred'))
        setisLoadingTable(false)
      })
  }

  const handleGenerateFileGeoIni = () => {
    setLoadingGenerate(true)
    adminStore
      .generateFileGeoIni()
      .then(res => {
        if (res.data.status === 'OK') {
          setGeoidIniS3Url(res.data.fileurl)
        } else {
          message.error(t(res.data.message || 'an-error-occurred'))
        }
      })
      .catch(() => {
        message.error(t('generate-file-failed'))
      })
      .finally(() => {
        setLoadingGenerate(false)
      })
  }

  const handleModeAddNew = () => {
    adminStore.setShowAddEditGEOID(true, false)
  }

  const handleEditGEOID = record => {
    adminStore.setShowAddEditGEOID(true, record)
  }

  const handleDeleteGEOID = async id => {
    setisLoadingTable(true)
    adminStore
      .deleteGEOID(id)
      .then(() => {
        message.success(t('delete-license-successfully'))
      })
      .catch(() => {
        setisLoadingTable(false)
        message.error(t('delete-license-failed'))
      })
      .finally(() => {
        setReload(true)
      })
  }

  const hidePopoverGenerateFile = () => {
    setGeoidIniS3Url()
  }

  const columns = [
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      width: 120,
      render: (record, obj) => {
        return (
          <span style={{ textTransform: 'uppercase' }}>
            {' '}
            {record ? record : t('no-name')}
          </span>
        )
      },
    },
    {
      title: t('geoid-code'),
      key: 'geoidCode',
      dataIndex: 'geoidCode',
      width: 120,
      render: record => {
        return <div>{record ? record : ''}</div>
      },
    },
    {
      title: t('epsg-code'),
      key: 'epsgCode',
      dataIndex: 'epsgCode',
      width: 120,
      render: record => {
        return <div>{record ? record : ''}</div>
      },
    },
    {
      title: t('file-name'),
      key: 'fileName',
      dataIndex: 'fileName',
      render: record => {
        return <div>{record ? record : ''}</div>
      },
    },
    {
      title: t('file-format'),
      key: 'ext',
      width: 120,
      dataIndex: 'ext',
      align: 'center',
      render: record => {
        return <div>{record ? record.toUpperCase() : ''}</div>
      },
    },
    {
      title: t('url'),
      key: 'url',
      dataIndex: 'url',
      render: record => {
        return <div>{record ? record : ''}</div>
      },
    },
    {
      title: t('projects'),
      key: 'numberProject',
      //width: 50,
      dataIndex: 'numberProject',
      align: 'center',
      render: (record, obj) => {
        return <span> {obj.totalProject ? obj.totalProject : 0}</span>
      },
    },
    {
      title: t('action'),
      dataIndex: 'id',
      key: 'event',
      fixed: 'center',
      width: 110,
      render: (record, obj) => {
        return (
          <Row type="flex" justify="center" key="action">
            <Button
              onClick={() => handleEditGEOID(obj)}
              icon={<EditOutlined />}
              style={{ marginRight: '5px' }}
            />
            {obj.type === 'custom' && (
              <Popconfirm
                placement="topRight"
                title={
                  <div style={{ maxWidth: '350px' }}>
                    <p
                      style={{
                        fontSize: '16px',
                        marginTop: '-2px',
                        marginBottom: '0',
                      }}>
                      {t('are-you-sure-you-want-to-delete-this-geoid')}
                    </p>
                    {obj.totalProject > 0 && (
                      <p style={{ marginTop: '5px' }}>
                        {t('this-geoid-also-contains-projects')}
                      </p>
                    )}
                  </div>
                }
                onConfirm={() => handleDeleteGEOID(record)}
                okText={t('commons.yes')}
                cancelText={t('commons.no')}>
                <Button
                  type="danger"
                  style={{ marginLeft: 10, padding: '3px 7px' }}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </Row>
        )
      },
    },
  ]

  return (
    <AdminTemplate title={t('geoid-models')}>
      <CustomContentAdminWrapper
        nodata={adminStore.listGEOID.length ? false : true}>
        <Content style={{ margin: '16px 16px 0', overflow: 'initial' }}>
          <HeaderAdmin>
            <Row
              style={{
                // padding: '24px 24px 0',
                overflow: 'initial',
                backgroundColor: '#fff',
              }}>
              <Row
                style={{ width: '100%' }}
                justify="space-between"
                align="middle">
                <Col xs={24} sm={12}>
                  <CustomTopTitle>
                    <div className="title">{t('geoid-models')}</div>
                    <div className="count">
                      {t('total-records-found', {
                        length: adminStore.listGEOID.length,
                      })}
                    </div>
                  </CustomTopTitle>
                </Col>
                <Col xs={24} sm={12}>
                  <div style={{ float: isMobile?'left':'right', textAlign: 'right' }}>
                    <Button
                      style={{ marginRight: '5px' }}
                      type="primary"
                      onClick={() => handleModeAddNew()}>
                      <PlusOutlined style={{ marginRight: '5px' }} />
                      {!isMobile && t('add-new-geoid')}
                    </Button>
                    <Popover
                      content={
                        <div style={{ padding: '10px' }}>
                          <p>
                            <strong>Url:</strong>
                            <span
                              style={{
                                border: '1px solid #ddd',
                                backgroundColor: '#fafafa',
                                padding: '5px',
                              }}>
                              {geoidIniS3Url}
                            </span>
                            <CopyToClipboard
                              text={geoidIniS3Url}
                              onCopy={() => setGeoidIniS3Url()}>
                              <CopyOutlined
                                style={{ fontSize: '20px', padding: '0 5px' }}
                              />
                            </CopyToClipboard>
                          </p>
                          <div style={{ textAlign: 'right' }}>
                            <Button onClick={hidePopoverGenerateFile}>
                              {t('commons.close')}
                            </Button>
                          </div>
                        </div>
                      }
                      placement="bottomRight"
                      title={t('generate-file-geoid.ini-successfully')}
                      trigger="click"
                      visible={geoidIniS3Url !== undefined}>
                      <Button
                        className="btn-info"
                        onClick={() => handleGenerateFileGeoIni()}
                        disabled={adminStore.listGEOID.length === 0}
                        loading={isLoadingGenerate}>
                        <FileSyncOutlined style={{ marginRight: '5px' }} />
                        {!isMobile && t('generate-file-geoid.ini')}
                      </Button>
                    </Popover>
                  </div>
                </Col>
              </Row>
            </Row>
          </HeaderAdmin>
          <PaginationAdmin>
            <Table
              rowKey={record => record.id}
              size="default"
              bordered
              scroll={{ x: 1000 }}
              loading={isLoadingTable}
              columns={columns}
              dataSource={adminStore.listGEOID}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: settings.adminPageSizeOptions,
                locale: { items_per_page: '' },
              }}
              locale={{ emptyText: t('no-data') }}
              onRow={(record, rowIndex) => {}}
            />
          </PaginationAdmin>
        </Content>
        <ModalAddEditGEOID setReload={setReload}></ModalAddEditGEOID>
      </CustomContentAdminWrapper>
    </AdminTemplate>
  )
}

export default inject('commonStore', 'adminStore')(observer(GEOIDModels))
