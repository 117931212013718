import styled from 'styled-components'
export const Topic3DLocationStyle = styled.div`
  background-color: rgba(222, 222, 222, 0.75);
  padding: 10px;
  position: absolute;
  top: 45px;
  left: 45px;
  border-radius: 2px;
  @media screen and (max-width: 768px) {
    padding: 8px;
  }
`