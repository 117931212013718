import { ColumnWidthOutlined } from '@ant-design/icons'
import { Button, Drawer } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getCurrentModel } from '../../../helper/CesiumUtils'
import EditPositionPanel from './EditPositionPanel'
import EditSketchPanel from './EditSketchPanel'
import DataTreePanel from './DataTreePanel'
import { DrawerTilesetWrapper } from './CustomStyled'
import { isMobile, isTablet } from 'react-device-detect'

const DrawerTilesetExplorer = props => {
  const { projectStore, sketchingStore, viewer, getCurrent3DViewSetting, syncLastSessionSettings } = props
  const isTabletOrMobileDevice = isMobile || isTablet
  const [drawerWidth, setDrawerWidth] = useState(410)
  const [CurrentModel, setCurrentModel] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const DrawerHeader = () => {
    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
        </div>
      </Fragment>
    )
  }

  useEffect(() => {
    if (projectStore.showEditLocation) {
      let cm = getCurrentModel(
        projectStore.currentModelId,
        projectStore.modelList,
        false
      )
      let _model = {
        ...cm.model,
        name: projectStore.selectedNode ? projectStore.selectedNode?.title : cm.model?.name
      }
      setCurrentModel(_model)
    } else {
      setCurrentModel(false)
    }
  }, [projectStore.showEditLocation])

  const handleTouchStart = e => {
    e.preventDefault();
    if (e.touches.length !== 1) return null;
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd, { passive: false });
    document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
  };

  const handleTouchMove = useCallback(e => {
    const touch = e.touches[0] || e.changedTouches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);
    const newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
    const minDrawerWidth = 360;
    if (newWidth < 0) {
      setDrawerWidth(10);
    }
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth);
    }
    if (newWidth > window.innerWidth) {
      setDrawerWidth(window.innerWidth);
    }
  }, []);

  const handleChangeScreenSize = () => {
    setScreenWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleChangeScreenSize)

  useEffect(() => {
    if (drawerWidth > screenWidth) {
      setDrawerWidth(screenWidth)
    }
  }, [screenWidth])

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, { passive: false });
    document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
    document.removeEventListener("touchmove", handleTouchMove, { passive: false });
  };

  const handleMouseDown = e => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(e => {
    let offsetRight = e.clientX - document.body.offsetLeft;
    let minDrawerWidth = 410;
    if (offsetRight > minDrawerWidth) {
      setDrawerWidth(offsetRight);
    }
    if (offsetRight > window.innerWidth) {
      setDrawerWidth(window.innerWidth);
    }
  }, []);

  return (
    <>
      <Drawer
        className={projectStore.displayPanel ? " pageDetailDrawer custom-wraper-splitPanel " : " pageDetailDrawer "}
        mask={false}
        id="pageDetailDrawer"
        width={drawerWidth}
        onClose={() => projectStore.setDisplayPanel(false)}
        visible={projectStore.displayPanel}
        title={<DrawerHeader />}
        placement="left">
        <DrawerTilesetWrapper>
          {isTabletOrMobileDevice ? (
            <div className="splitpanel-mobile-left">
              <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                <ColumnWidthOutlined />
              </Button>
            </div>
          ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-left" />)
          }
          {<DataTreePanel getCurrent3DViewSetting={getCurrent3DViewSetting} viewer={viewer} />}
        </DrawerTilesetWrapper>
      </Drawer>

      {sketchingStore.sketchFormVisible ? <EditSketchPanel syncLastSessionSettings={syncLastSessionSettings} viewer={viewer} /> : null}
      {projectStore.showEditLocation && CurrentModel ? <EditPositionPanel syncLastSessionSettings={syncLastSessionSettings} visible={!sketchingStore.drawMode} viewer={viewer} model={CurrentModel} /> : null}
    </>
  )
}

export default withRouter(
  inject(
    'projectStore',
    'sketchingStore'
  )(observer(DrawerTilesetExplorer))
)
