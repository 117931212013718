import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { inject, observer } from 'mobx-react'
import CityDemoTemplate from '../../../layout/CityDemoTemplate'
import ModelDemoViewer from '../ModelDemoViewer'

const ModelHelsinki = ({ commonStore, history, ...props }) => {
  let p = props.location.pathname.substr(1 - props.location.pathname.length)
  return (
    <HelmetProvider>
      <CityDemoTemplate>
        <Helmet>
          <title>Helsinki | 6DPlanner Viewer</title>
        </Helmet>
        <ModelDemoViewer modelSlug={p} />
      </CityDemoTemplate>
    </HelmetProvider>
  )
}

export default inject('commonStore')(observer(ModelHelsinki))
