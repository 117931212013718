import { QuestionCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, message, Popconfirm, Select } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { ReactComponent as ViewpointDelete } from '../../../../../assets/svgs/viewpoint-delete.svg';
import validator from '../../../../../validator';
import SVGIcon from '../../../../elements/SVGIcon';
import UploadButton from '../../../../elements/UploadButton';
import { apiUrl } from './../../../../../config';
import { LogoWrapper, OrganizationEditWrapper } from './style';

const { Option } = Select

const OrganizationEdit = ({ organizationData, onSubmit, onCancel, commonStore, organizationStore }) => {
  const { t } = useTranslation();
  const OrganizationForm = () => {
    const handleSubmit = values => {
      let _logo = organizationStore?.logoUpload[0]
      values.logo = (_logo && _logo.id) ? _logo.id : null
      onSubmit(organizationData.id, values)
    }

    const optionCountries = useMemo(() => countryList().getData(), [])
    const [imageUrl, setimageUrl] = useState(null);

    useEffect(() => {
      if (organizationData.logo) {
        setimageUrl(organizationData.logo.url)
        organizationStore.setCurrentLogoUpload([organizationData.logo]);
      }
      return () => {
        organizationStore.setCurrentLogoUpload({})
      }
    }, [organizationData.logo])

    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      if (e?.fileList?.length > 1) {
        e.fileList.shift()
      }
      return e && e.fileList;
    };

    const propsUpload = {
      name: 'file',
      multiple: false,
      listType: "picture",
      fileList: [],
      // fileList,
      beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error(t('you-can-only-upload-jpg-png-file'));
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error(t('image-must-smaller-than-2MB'));
        }
        return isJpgOrPng && isLt2M;
      },
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          // console.log("info.file", info.file);
        }
        if (status === 'done') {
          // message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} ${t('file-upload-failed')}`);
        }
      },

      onRemove() {
        organizationStore.setCurrentLogoUpload({});
        setimageUrl()
      }
      ,
      customRequest: ({ onSuccess, onError, file, onProgress }, event) => {
        const formData = new FormData()
        formData.append('files', file, file.name);
        axios({
          async: true,
          crossDomain: true,
          processData: false,
          contentType: false,
          mimeType: 'multipart/form-data',
          method: 'POST',
          url: `${apiUrl}/upload`,
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          onUploadProgress: event => {
            onProgress({ percent: (event.loaded / event.total) * 100 }, file);
          },
          data: formData,

        }).then(res => {
          onSuccess(res => normFile(res.data))
          // normFile(res.data);
          message.success(t('upload-image-successfully'));
          organizationStore.setCurrentLogoUpload(res.data);
          setimageUrl(res.data[0].url);
        })
          .catch(err => message.error(t('has-an-error-please-try-again')))
      }
      ,

    };

    const removeLogo = () => {
      setimageUrl(null)
      organizationStore.setCurrentLogoUpload({})
    }

    return (
      <Form
        layout="vertical"
        name="update-company"
        className={'update-company-form'}
        onFinish={handleSubmit}>
        <Form.Item
          label={t('logo')}
          name="dragger"
          getValueFromEvent={normFile}
          valuePropName="fileList">
          <LogoWrapper>
            {imageUrl && (
              <Popconfirm
                okText={t('commons.delete')}
                cancelText={t('commons.cancel')}
                onConfirm={removeLogo}
                okType={'danger'}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title={t('are-you-sure')}>
                <Avatar
                  className="delete-icon"
                  key="delete"
                  shape="square"
                  icon={
                    <SVGIcon
                      color={'#F26524'}
                      content={<ViewpointDelete />}
                      width={18}
                      height={18}
                    />
                  }
                />
              </Popconfirm>
            )}
            <Dragger {...propsUpload}>
              {imageUrl ? (
                <img src={imageUrl} className="input-upload-image-square" />
              ) : (
                <UploadButton />
              )}
            </Dragger>
          </LogoWrapper>
        </Form.Item>
        <Form.Item
          label={t('name')}
          name="name"
          initialValue={organizationData.name}
          rules={[
            {
              required: true,
              message: t('please-input-organization-name'),
            },
            { validator: validator.validateEmptyString },
          ]}>
          <Input placeholder={t('input-organization-name')} />
        </Form.Item>
        <Form.Item
          label={t('address')}
          name="address"
          initialValue={organizationData.address}
          rules={[
            {
              required: true,
              message: t('please-input-organization-address'),
            },
            { validator: validator.validateEmptyString },
          ]}>
          <Input placeholder={t('input-organization-address')} />
        </Form.Item>
        <Form.Item
          label={t('city')}
          name="city"
          initialValue={organizationData.city ? organizationData.city : ''}
          rules={[
            {
              required: true,
              message: t('please-input-organization-city'),
            },
          ]}>
          <Input placeholder={t('enter-city')} />
        </Form.Item>
        <Form.Item
          label={t('country')}
          name="country"
          initialValue={
            organizationData.country ? organizationData.country : ''
          }
          rules={[
            {
              required: true,
              message: t('please-input-organization-country'),
            },
          ]}>
          <Select
            placeholder={t('enter-country')}
            allowClear={true}
            showArrow={true}
            showSearch={true}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp="children"
            notFoundContent={t('no-data')}>
            {optionCountries ? (
              optionCountries.map((v, i) => {
                return (
                  <Option key={v.value} value={v.label}>
                    {v.label}
                  </Option>
                )
              })
            ) : (
              <Option>{t('no-country-found')}</Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('zip-code')}
          name="zipCode"
          initialValue={
            organizationData.zipCode ? organizationData.zipCode : ''
          }
          rules={[
            {
              required: true,
              message: t('please-input-organization-zip-code'),
            },
          ]}>
          <Input placeholder={t('enter-zip-code')} />
        </Form.Item>
        <Button
          block
          type={'primary'}
          size="large"
          style={{
            marginBottom: 15,
          }}
          loading={organizationData.isLoading}
          htmlType="submit">
          {t('commons.save')}
        </Button>
        <Button block size="large" onClick={onCancel}>
          {t('commons.cancel')}
        </Button>
      </Form>
    )
  }

  return (
    <OrganizationEditWrapper>
      <OrganizationForm />
    </OrganizationEditWrapper>
  )
}

export default withRouter(inject('commonStore', 'organizationStore',)(observer(OrganizationEdit)))
