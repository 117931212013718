import styled from 'styled-components'

export const DrawerHeading = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
    margin-right: 30px;
  }
  span {
    margin-right: 10px;
    color: #fff;
  }
`
export const CardViewWrapper = styled.div`
.ant-avatar{
    background: none;
  }
  .ant-card {
    background-color: transparent;
    border: none;
    .ant-card-body {
      padding: 10px;
    }
    .ant-card-cover {
      position: relative;
      .anticon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: auto;
        color: white;
        font-size: 12px;
        opacity: 0;
        transition: ease .3s;
        color: #F26524;
        &:hover {
          transition: ease .3s;
          opacity: 1;
          color: #F26524;
        }
      }
      .save-action{
        position: absolute;
        top: 10px;
        right: 30px;
        width: auto;
        color: white;
        font-size: 16px;
        opacity: 0;
        transition: ease .3s;
        color: #F26524;
        &:hover {
          transition: ease .3s;
          opacity: 1;
          color: #F26524;
        }
      }
      &:hover {
        cursor: pointer;
        .anticon {
          transition: ease .3s;
          opacity: 1;
        }
        .save-action {
          transition: ease .3s;
          opacity: 1;
        }
      }
    }
    .ant-card-meta-title {
      color: white;
      font-size: 12px;
      text-align: center;
    }
  }
`

export const ViewPointImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ViewPointImage = styled.img`
  margin: 0 auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
`