import { Layout } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import LoadingSpinner from '../../elements/LoadingSpinner'
import SidebarNavigation from './SidebarNavigation/SidebarNavigation'
import { MainContentWrapper } from './style'
import {
  CustomContentAdmin,
  CustomContentAdminWrapper,
} from '../../pages/AdminPage/style'
import TopMenu from './TopMenu'
import { useMediaQuery } from 'react-responsive'

const AdminTemplate = ({
  commonStore,
  adminStore,
  projectStore,
  usersStore,
  organizationStore,
  children,
  history,
  title,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const isOpenSideNav = useMediaQuery({ query: '(max-width: 585px)' })
  useEffect(() => {
    if (isOpenSideNav) {
      adminStore.setcollapsed(true)
      adminStore.triggerSider(true)
    } else {
      adminStore.setcollapsed(false)
      adminStore.triggerSider(false)
    }
  }, [isOpenSideNav])
  const checkLoading = () =>
    !!(
      commonStore.isLoading ||
      projectStore.isLoading ||
      usersStore.isLoading ||
      organizationStore.isLoading ||
      adminStore.isLoading
    )
  const triggerSider = v => {
    adminStore.triggerSider(v)
  }
  const checkMobile = () => {
    if (isMobile) {
      return adminStore.isToggleMenuMobile
        ? adminStore.isTrigger
          ? 80
          : 200
        : 0
    } else {
      return adminStore.isTrigger ? 80 : 200
    }
  }
  return (
    <HelmetProvider>
      <MainContentWrapper
        className={
          !adminStore.isToggleMenuMobile && isMobile
            ? 'scroll-bars-menu-mobile'
            : ''
        }
        top={60}
        left={checkMobile()}>
        <Helmet>
          <title>{title} | 6DPlanner</title>
        </Helmet>
        <SidebarNavigation triggerSider={v => triggerSider(v)} />
        <Layout>
          <TopMenu
            onCollapse={adminStore.isTrigger}
            triggerSider={v => triggerSider(v)}
          />
          <CustomContentAdmin>{children}</CustomContentAdmin>
        </Layout>
        {checkLoading() ? (
          <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
        ) : null}
      </MainContentWrapper>
    </HelmetProvider>
  )
}

export default inject(
  'commonStore',
  'projectStore',
  'usersStore',
  'organizationStore',
  'adminStore'
)(observer(AdminTemplate))
