import {  Table } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx'
import { TableHeading, TableWrapper } from './style';
import AddLicenseToOrganizationModal from './AddLicenseToOrganizationModal';
import { useTranslation } from 'react-i18next';

const OrganizationLicensesTab = props => {
    const { t } = useTranslation();
    const { listLicenseTab, adminStore, organizationStore, licenseStore, commonStore } = props;
    moment.locale(`${commonStore.language}`)
    const [licensesBelongOrganizationList, setLicensesBelongOrganizationList] = useState(listLicenseTab)

    useEffect(() => {
        setLicensesBelongOrganizationList(listLicenseTab)
    }, [listLicenseTab])

    const tableColumns = [
        {
            title: t('type-name'),
            dataIndex: 'typeName',
            key: 'typeName',
            render: (record, obj) => {
                return <span style={{ textTransform: 'uppercase' }}> {record ? record : t('no-type')}</span>
            }
        },
        {
            title: t('activated'),
            dataIndex: 'activatedTab',
            key: 'activated',
            render: (record, obj) => {
                return <span>{record ? moment(record).format('MMMM Do YYYY, h:mm:ss a') : ''}</span>
            }
        },
        {
            title: t('expire'),
            dataIndex: 'expireTimeTab',
            key: 'expireTimeTab',
            render: (record, obj) => {
                return <span>{record ? moment(record).format('MMMM Do YYYY, h:mm:ss a') : ''}</span>
            }
        },
        {
            title: t('total-storage'),
            dataIndex: 'licenseQuotaTab',
            key: 'licenseQuotaTab',
            render: (record, obj) => {
                return <span> {record ? t(record) : 0}</span>
            }
        },
        {
            title: t('total-projects'),
            dataIndex: 'licensePrjTab',
            key: 'licensePrjTab',
            render: (record, obj) => {
                let projectsLeft = record - obj.projects?.length
                return <span> {record ? t(record) : 0}</span>
            }
        },
        {
            title: t('total-users'),
            dataIndex: 'licenseUserTab',
            key: 'licenseUserTab',
            render: (record, obj) => {
                let projectsLeft = record - obj.projects?.length
                return <span> {record ? t(record) : 0}</span>
            }
        }
    ]

    const handleClickAddLicenseToOraganization = () => {
        organizationStore.setLoadingProgress(true)
        licenseStore.getLicenseTypes().then(() => {
            organizationStore.setLoadingProgress(false)
        }).catch((error) => {
            organizationStore.setLoadingProgress(false)
        })
        organizationStore.toggleShowAddLicenseToOrganizationModal(true)

    }
    return (
        <TableWrapper>
            <TableHeading>
                <div className="title">{t('license-belong-to-organization')}</div>
                {/* {
                    adminStore.roleCurrentUserInOrganization && adminStore.roleCurrentUserInOrganization.role === "Admin" ? (
                        <Toolsbox>
                            <Button type={'primary'} onClick={handleClickAddLicenseToOraganization}>
                                <PlusOutlined /> Add License
                            </Button>
                        </Toolsbox>
                    ) : ''
                } */}
            </TableHeading>
            <Table
                pagination={false}
                rowKey={record => record.typeName}
                columns={tableColumns}
                scroll={{ x: 600 }}
                dataSource={licensesBelongOrganizationList}
                onRow={(record) => ({
                    onClick: (event) => {
                        // return somthing
                    },
                })}
                locale={{ emptyText: t('no-data') }}
            />
            <AddLicenseToOrganizationModal
                organizationId={adminStore.currentOrganizationView.id} licenseTypes={licenseStore.licenseTypes}
            />
        </TableWrapper>
    )
}
export default inject('organizationStore', 'commonStore', 'adminStore', 'licenseStore')(observer(OrganizationLicensesTab))