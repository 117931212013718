import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import settings from '../../../siteConfig'
// import Utils from '../../../utils'

const SEO = props => {

  const {
    title,
    description,
    lang,
    contentType,
    imageUrl,
  } = props

  return (
    <HelmetProvider>
      <Helmet
        title={title}
        titleTemplate={`%s | ${settings.defaultSiteSettings.siteTitle}`}
        meta={
          [
            { name: 'description', content: description }, // Page description
            /* Open Graph */
            { property: 'og:title', content: title },
            { property: 'og:type', content: contentType || 'website' },
            // { property: 'og:url', content: pageUrl },
            { property: 'og:description', content: description },
            { property: 'og:image', content: imageUrl },
            // { property: 'og:image:alt', content: description },
            { property: 'og:site_name', content: settings.defaultSiteSettings.siteTitle },
            { property: 'og:locale', content: lang || 'en_US' },
            /* Twitter card */
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: title },
            { name: 'twitter:description', content: description },
            { name: 'twitter:image', content: imageUrl },
            // { name: 'twitter:image:alt', content: settings.defaultSiteSettings.description },
            // { name: 'twitter:site', content: settings.defaultSiteSettings.author },
            // { name: 'twitter:creator', content: settings.defaultSiteSettings.author },
          ]
          // .concat(metaKeywords) // Keywords
          // .concat(meta || []) // Other provided metadata
        }
        link={[
          // { rel: 'canonical', href: pageUrl }, // Canonical url
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap&subset=vietnamese',
          }, // Roboto Mono
        ]}
      />
    </HelmetProvider>
  )
}

export default SEO