import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import { Modal, Button, Row, Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const ModalChangePasswordUser = (props) => {
    const {
        showModalChangePassword,
        cancelModal,
        handleChangePassword
    } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [confirmDirty, setConfirm] = useState(false)

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback(t('passwords-do-not-match'))
        } else {
            callback()
        }
    }

    useEffect(() => {
        if(showModalChangePassword) {
            form.resetFields()
        }
    }, [showModalChangePassword])

    const handleConfirmBlur = (e) => {
        const value = e.target.value
        setConfirm(confirmDirty || !!value)
    }

    const handleSubmit = () => {
        form.validateFields().then((valueForm) => {
            handleChangePassword(valueForm)
        })
    }

    const onCancel = () => {
        cancelModal(false)
        form.resetFields()
    }

    return (
        <Modal
            getContainer={false}
            maskClosable={false}
            zIndex={9999}
            title={t('change-your-account-password')}
            visible={showModalChangePassword}
            onCancel={onCancel}
            footer={[
                <Row key='btn-group' justify='end'>
                    <Button onClick={onCancel} >{t('commons.cancel')}</Button>
                    <Button onClick={handleSubmit} type="primary" >{t('commons.save')}</Button>
                </Row>
            ]}
        >
            <Form
                form={form}
                name="changepassword-form"
                layout="vertical"
            >
                <Form.Item label={t('old-password')} name="oldpassword" rules={[
                    {
                        required: true,
                        message: t('please-input-old-password'),
                    },
                ]}>
                    <Input.Password
                        placeholder={t('enter-old-password')}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item label={t('password')} name="password" rules={[
                    {
                        required: true,
                        message: t('please-input-your-password'),
                    },
                ]}>
                    <Input.Password
                        placeholder={t('enter-your-new-password')}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item label={t('confirm-password')} name="confirm" rules={[
                    {
                        required: true,
                        message: t('please-re-type-your-password'),
                    },
                    { validator: compareToFirstPassword },
                ]}>
                    <Input.Password
                        placeholder={t('re-type-password')}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onBlur={handleConfirmBlur}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
export default withRouter(
    inject(
        'commonStore'
    )(observer(ModalChangePasswordUser))
)

