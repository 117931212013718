import { Col, Row, Tooltip, Image } from 'antd'
import { toJS } from 'mobx';
import React from 'react'
import { isMobile, isTablet } from 'react-device-detect';
import { } from './CustomStyled'
function AttributeTable({ data, attrTitle, attrVersion, objectAttributes, objectData }) {
    let showVersion = 0
    return (
        <div className='attributeTable'>
            {
                attrTitle &&
                <>
                    {
                        objectAttributes?.map((obj, i) => {
                            showVersion = i
                            return obj &&
                                <React.Fragment key={`objectAttributes${i}`}>
                                    <div className="attribute__firstLine attribute__line">
                                        <span className="attribute__firstLine__name">
                                            {obj.attrTitle}
                                        </span>{
                                            showVersion < 1 &&
                                            <span span={12} className="attribute__firstLine__version">{attrVersion}</span>
                                        }

                                    </div>
                                    <Row className="childAttribute">
                                        {
                                            // not have children
                                            obj?.filter(att => !att.children).map((att, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <Col className="headingAttribute__item" span={8}>
                                                            <Tooltip title={att.attr} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{att.attr}</Tooltip>
                                                        </Col>
                                                        <Col className="headingAttribute__item" span={10}>
                                                            <Tooltip title={att.value} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{att.value}</Tooltip>
                                                        </Col>
                                                        <Col className="headingAttribute__item" span={6}>
                                                            <Tooltip title={att.unit} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{att.unit}</Tooltip>
                                                        </Col>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        {
                                            // have children
                                            obj?.filter(att => att.children).map((att, i) => {
                                                let leftTitle = att.attr.indexOf('(') > 0 ? att.attr.split("(")[0] : att.attr.split("'")[0]
                                                let rightTitle = att.attr.indexOf('(') > 0 ? att.attr.split("(")[1] : att.attr.split("'")[1]
                                                let spliceRightTitle = rightTitle.indexOf(')') > 0 ? (rightTitle?.slice(0, rightTitle.length - 1).replaceAll("'", "")) : rightTitle.replaceAll("'", "")
                                                return (
                                                    <React.Fragment key={i}>
                                                        <Col className="headingAttribute__title" span={8}>
                                                            <Tooltip title={leftTitle.replaceAll("'", "")} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                {leftTitle.replaceAll("'", "")}
                                                            </Tooltip>
                                                        </Col>
                                                        <Col className="headingAttribute__title" style={{ paddingLeft: 35 }} span={16}>
                                                            <Tooltip title={spliceRightTitle} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                {spliceRightTitle}
                                                            </Tooltip>
                                                        </Col>
                                                        {
                                                            att.children.map((c, i) => {
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        <Col className="headingAttribute__item" span={8}>
                                                                            <Tooltip title={c.attr} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{c.attr}</Tooltip>
                                                                        </Col>
                                                                        <Col className="headingAttribute__item" span={10}>
                                                                            <Tooltip title={c.value} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{c.value}</Tooltip>
                                                                        </Col>
                                                                        <Col className="headingAttribute__item" span={6}>
                                                                            <Tooltip title={c.unit} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{c.unit}</Tooltip>
                                                                        </Col>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </Row>

                                </React.Fragment>
                        })
                    }

                    <div className="attribute__thirdLine attribute__line">
                        <span className="attribute__firstLine__name">{attrTitle}</span>
                        {
                            !objectAttributes?.children && showVersion < 1 &&
                            <span span={12} className="attribute__firstLine__version">{attrVersion}</span>
                        }
                    </div>
                    <Row className="childAttribute">
                        {
                            objectData && objectData.type === 'sketch' ? (
                                // sketch library multi selected
                                objectData.objectData.map((element, i) => {
                                    return (
                                        <React.Fragment key={element.key}>
                                            <Col span={24} className="attribute__thirdLine attribute__line">
                                                <span className="attribute__firstLine__name">{element.title}</span>
                                            </Col>
                                            {
                                                element.children.map((att, k) => {
                                                    return (
                                                        <Row style={{ width: '100%', padding: '8px 0' }} key={att.key} className="childAttribute">
                                                            <Col className="headingAttribute__item" span={12}>
                                                                <Tooltip title={att.title} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{att.title}</Tooltip>
                                                            </Col>
                                                            <Col style={{ padding: ' 0 15px 0 25px' }} span={12}>
                                                                <Tooltip title={att.value} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                    {
                                                                        att.title !== 'Image' ?
                                                                            <Col span={24}>
                                                                                {att.value.length > 174 ? `${att.value.slice(0, 174)}...` : att.value}
                                                                            </Col> :
                                                                            (
                                                                                <Col span={24}>
                                                                                    {att.value ?
                                                                                        (
                                                                                            <Image className="selectedNode-img"
                                                                                                src={att.value}
                                                                                            />
                                                                                        ) : '<No Image>'}
                                                                                </Col>
                                                                            )
                                                                    }
                                                                </Tooltip>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }

                                        </React.Fragment>
                                    )
                                })
                            ) : (
                                // not have children
                                data.filter(att => !att.children).map((att, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Col className="headingAttribute__item" span={8}>
                                                <Tooltip title={att.attr} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{att.attr}</Tooltip>
                                            </Col>
                                            <Col className="headingAttribute__item" span={10}>
                                                <Tooltip title={att.value} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{att.value}</Tooltip>
                                            </Col>
                                            <Col className="headingAttribute__item" span={6}>
                                                <Tooltip title={att.unit} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{att.unit}</Tooltip>
                                            </Col>
                                        </React.Fragment>
                                    )
                                })
                            )
                        }
                        {
                            // have children
                            data.filter(att => att.children).map((att, i) => {
                                let leftTitle = att.attr.indexOf('(') > 0 ? att.attr.split("(")[0] : att.attr.split("'")[0]
                                let rightTitle = att.attr.indexOf('(') > 0 ? att.attr.split("(")[1] : att.attr.split("'")[1]
                                let spliceRightTitle = rightTitle.indexOf(')') > 0 ? (rightTitle?.slice(0, rightTitle.length - 1).replaceAll("'", "")) : rightTitle.replaceAll("'", "")
                                return (
                                    <React.Fragment key={i}>
                                        <Col className="headingAttribute__title" span={8}>
                                            <Tooltip title={leftTitle.replaceAll("'", "")} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                {leftTitle.replaceAll("'", "")}
                                            </Tooltip>
                                        </Col>
                                        <Col className="headingAttribute__title" style={{ paddingLeft: 35 }} span={16}>
                                            <Tooltip title={spliceRightTitle} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                {spliceRightTitle}
                                            </Tooltip>
                                        </Col>
                                        {
                                            att.children.map((c, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <Col className="headingAttribute__item" span={8}>
                                                            <Tooltip title={c.attr} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{c.attr}</Tooltip>
                                                        </Col>
                                                        <Col className="headingAttribute__item" span={10}>
                                                            <Tooltip title={c.value} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{c.value}</Tooltip>
                                                        </Col>
                                                        <Col className="headingAttribute__item" span={6}>
                                                            <Tooltip title={c.unit} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>{c.unit}</Tooltip>
                                                        </Col>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </Row>
                </>
            }
        </div>
    )
}

export default AttributeTable
