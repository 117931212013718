import { Typography } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link, useHistory } from 'react-router-dom'
import LoadingSpinner from '../../elements/LoadingSpinner'
import {
  AuthPageWrapper, FormSubtitle, FormTitle, FormWrapper
} from './CustomStyled'
import ForgotPasswordForm from './ForgotPasswordForm'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import LogoutForm from './LogoutForm'
import { assetUrl } from '../../../config'
import { Trans, useTranslation } from 'react-i18next';


const AuthPage = ({ match, authStore, commonStore, usersStore, projectStore }) => {
  const { t } = useTranslation();
  const authType = match.params.authType
  const [isEmailSended, setisEmailSended] = useState(false);
  const { Text } = Typography;
  const history = useHistory();

  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const checkAppLoading = () => {
    return !!(
      commonStore.isLoading ||
      authStore.isLoading ||
      usersStore.isLoading
    )
  }


  useEffect(() => {

  }, [])

  const authFormInput = () => {
    switch (authType) {
      case 'login':
        return (
          <Fragment>
            <FormTitle>{t('welcome-back')}!</FormTitle>
            <FormSubtitle>{t('signin-text')}</FormSubtitle>
            <LoginForm />
          </Fragment>
        )
      case 'register':
        return (
          <Fragment>
            <FormTitle>{t('register')}</FormTitle>
            <FormSubtitle>{t('register-text')}</FormSubtitle>
            <RegisterForm />
          </Fragment>
        )
      case 'forgot-password':
        return (
          isEmailSended ? (
            <Fragment>
              <FormTitle> <Text type="success">{t('success')}</Text></FormTitle>
              <FormSubtitle>{t('verify-your-email-text')}</FormSubtitle>
              <Link onClick={() => setisEmailSended(false)} to={'/auth/login'} style={{ color: '#ff8d4f' }}>
                {t('back-to-login-page')}
              </Link>
            </Fragment>
          ) : (
            <Fragment>
              <FormTitle>{t('forgot-password')}</FormTitle>
              <FormSubtitle>{t('you-can-reset-your-password-here')}</FormSubtitle>
              <ForgotPasswordForm setisEmailSended={v => setisEmailSended(v)} />
            </Fragment>
          )
        )
        case 'logout':
          return (
            <Fragment>
              <FormTitle>{t('logout')}</FormTitle>
              <FormSubtitle>{t('logout')}</FormSubtitle>
              <LogoutForm />
            </Fragment>
          )
      default:
        break
    }
  }

  const authPageTitle = () => {
    switch (authType) {
      case 'login':
        return 'Login'
      case 'register':
        return 'Register'
      case 'forgot-password':
        return 'Forgot Password'
      default:
        return 'Undefined'
    }
  }

  return (
    <HelmetProvider>
      <AuthPageWrapper>
        <Helmet>
          <title>{authPageTitle()}</title>
        </Helmet>
        <FormWrapper style={(authType === 'register') ? { marginTop: 150 } : {}}>
          {authFormInput()}
        </FormWrapper>
        {
          checkAppLoading()
            ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
            : null
        }
      </AuthPageWrapper>
    </HelmetProvider>
  )

}

export default inject('authStore', 'commonStore', 'usersStore', 'projectStore')(observer(AuthPage))