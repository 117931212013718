import styled from 'styled-components'

export const ProgressBodyStype = styled.div`
    max-height: 200px;
    overflow-y: scroll;
    margin-bottom: 20px;
    .flex-row{display:flex;flex-flow:row nowrap}
    .row-center, .row-distribute {
        justify-content: space-between;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .flex-1 {
        flex-shrink: 1;
        flex-basis: auto;
        flex-grow: 1;
    }
    .text-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 29.5rem;
    }
    .text-muted {
        color: #8c8b96;
        font-size: 12px;
        text-align: center;
    }
    .flex-1 p {
        margin: 0;
    }
    .pr-1 {
      padding-right: 0.5rem!important;
    }
    .small {
      font-size: 1.2rem;
    }
    .text-bold {
      font-weight: 700;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
    .row-body{
      min-height: 5rem;
      border-bottom: 1px solid #e2e2e7;
      padding-top: 1.5rem!important;
      padding-bottom: 0.5rem!important;
      padding-left: 1rem!important;
      padding-right: 0.5rem!important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {    
      line-height: 2;     
    }
    .ant-collapse-extra {
      border-radius: 50%;
      padding: 0;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-top: -2px;
    }
    .ant-collapse-extra:hover {
      background-color: #e2e2e7;
    }

    .ant-btn-text:hover {
      background: #e2e2e7;
      border-color: #e2e2e7;
    }
`
export const UploadWrapper = styled.div`
  max-height: 500px;
  .ant-upload.ant-upload-drag .ant-upload {
      padding: 16px;
  }
`
export const ProjectLinkPageWrapper = styled.div`
.splitpanel-left {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
}

.splitpanel-mobile-left {
    top: 0;
    right: 0; 
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-left: -15px;
    padding: 0 !important;
    }
}
`
export const SearchWrapper = styled.div`
    display : flex ;
    width : 100%;
    border: 1px solid #d9d9d9;
`
export const CustomSearch = styled.div`
    flex-grow : 1;
    .ant-input-affix-wrapper{
        flex-grow : 1;
        height : 28px ;
        border : none ;
        background : none ;
        outline : none ;
        input {
        border : none ;
        background : none ;
        outline : none ;
        height : 100%;
      }
    }
    .ant-input-affix-wrapper:hover{
        border : none;
    }
    .ant-input-affix-wrapper-focused{
        border : none;
        background : none ;
        outline : none ;
        box-shadow : none;
    }
    .ant-form-item-control-input{
        min-height: auto !important;
    }
    
`
export const CustomButton = styled.div`
    display : flex;
    justify-content : center ;
    align-items : center ;
    padding : 0 5px;
    font-size : 11px
`
export const SpinDrawer = styled.div`
    .ant-spin-container .splitpanel-left {
        width: 0;
    }
`
