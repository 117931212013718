import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
    ScreenSpaceEventType,
    Color,
    PolylineGraphics,
    ClassificationType,
    VerticalOrigin,
    Math as CesiumMath,
    Cartesian2,
    CallbackProperty,
    LabelStyle
} from 'cesium'
import { clickPoint, spaceDistance, computePolylinelength } from '../helper/CesiumUtils'

const MeasurePolyline = props => {
    const { viewer, handler, measureStore } = props

    const [activeShape, setActiveShape] = useState() // preview shape when mouse move
    const [activeLabel, setActiveLabel] = useState() // preview label distance when mouse move

    const [previousPoint, setPreviousPoint] = useState()
    const [currentShapes, setCurrentShapes] = useState([])
    const [activeShapePoints, setActiveShapePoints] = useState([])
    const [vertexEntities, setVertexEntities] = useState([])
    const [customLine] = useState(new CustomLine())

    const labelDefault = {
        scale: 0.5,
        font: '26px sans-serif',
        verticalOrigin: VerticalOrigin.BOTTOM,
        style: LabelStyle.FILL_AND_OUTLINE,
        outlineWidth: 9,
        outlineColor: Color.WHITE
    }

    function CustomLine() {
        this.positions = [];
        this.markers = [];
    }

    //Function add point click
    CustomLine.prototype.addPoint = function (resultClick) {
        var that = this;
        var pickedObject = resultClick.pickedObject
        var position = resultClick.position
        var n = this.positions.length;
        this.positions[n] = {
            pickedObject: pickedObject,
            position: position
        };

        var marker = viewer.current.cesiumElement.entities.add({
            position: new CallbackProperty(function () {
                if (that.positions && that.positions[n] && that.positions[n].position) {
                    return that.positions[n].position;
                }
            }, false),
            point: {
                pixelSize: 5,
                color: Color.RED,
                outlineColor: Color.WHITE,
                outlineWidth: 2
            },
        })
        this.markers.push(marker);
    };

    //function remove point and line
    CustomLine.prototype.removeAll = function () {
        if (this.markers && this.markers.length > 0) {
            for (var i = 0; i < this.markers.length; i++) {
                if (viewer && viewer.current && viewer.current.cesiumElement)
                    viewer.current.cesiumElement.entities.remove(this.markers[i]);
            }
            this.markers = [];
            this.positions = [];
        }
    };

    const drawShape = (positionData) => {
        if (positionData.length === 0) return
        let polyline = new PolylineGraphics({
            positions: positionData,
            clampToGround: false,
            width: 2,
            material: Color.WHITE,
            depthFailMaterial: Color.WHITE,
            destroyPrimitives: false,
            classificationType: ClassificationType.BOTH,
        })
        let shape = viewer.current.cesiumElement.entities.add({
            polyline: polyline,
        })

        return shape
    }

    /**
     * Add label when mouse move
     * @param {*} points 
     * @returns 
     */
    const drawActiveLabel = (points) => {
        let shape = viewer.current.cesiumElement.entities.add({
            position: points[points.length - 1],
            label: {
                ...labelDefault,
                text: points.length > 2 ? 'Segment: ' + spaceDistance(points) + '\n Total: ' + computePolylinelength(points) : 'Segment: ' + spaceDistance(points),
                pixelOffset: new Cartesian2(30, -10),
                showBackground: true,
                backgroundColor: Color.BLACK.withAlpha(0.8),
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
        })

        return shape
    }

    // Redraw the shape so it's not dynamic and remove the dynamic shape.
    function terminateShape() {
        if (activeShapePoints.length > 1) {
            setCurrentShapes(currentShapes => [...currentShapes, drawShape(activeShapePoints)])
        }

        // if click one point then end draw => delete vertext
        if (activeShapePoints.length === 1) {
            let _entities = viewer.current.cesiumElement.entities.getById("MeasureDistanceVertex" + activeShapePoints[0])
            if (_entities) {
                viewer.current.cesiumElement.entities.remove(_entities);
                setVertexEntities(prevState => prevState.filter(p => p._id !== _entities._id))
            }
        }

        viewer.current.cesiumElement.entities.remove(activeShape)
        viewer.current.cesiumElement.entities.remove(activeLabel)
        setActiveShape()
        setActiveLabel()
        setPreviousPoint() // clear previous point
        setActiveShapePoints([])
    }

    /** Effect mouse right end draw */
    useEffect(() => {
        if (measureStore.endDrawing) {
            terminateShape()

            measureStore.setEndDrawing(false)
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }, [measureStore.endDrawing])

    /**  
     * Effect remove shape measure
     */
    useEffect(() => {
        if (measureStore.removeShape) {
            terminateShape() // for the case where the user clicks the end draw not right click

            // Remove vertex entities
            vertexEntities.forEach(item => {
                viewer.current.cesiumElement.entities.remove(item);
            });
            setVertexEntities([]);

            // Remove currentShapes
            if (currentShapes.length > 0) {
                for (var i = 0; i < currentShapes.length; i++) {
                    if (currentShapes[i]) {
                        var _entities = viewer.current.cesiumElement.entities.getById(currentShapes[i].id)
                        if (_entities) {
                            viewer.current.cesiumElement.entities.remove(_entities);
                        }
                    }
                }
            }
            customLine.removeAll()
            measureStore.setRemovingShape(false)
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }, [measureStore.removeShape])

    /**
    * Effect after mouse click will add marker and label
    */
    useEffect(() => {
        if (activeShapePoints.length > 0) {
            let _entities = viewer.current.cesiumElement.entities.getById("MeasureDistanceVertex" + activeShapePoints[activeShapePoints.length - 1])
            if (!_entities) {
                let _vertexEntity = viewer.current.cesiumElement.entities.add({
                    position: activeShapePoints[activeShapePoints.length - 1],
                    id: "MeasureDistanceVertex" + activeShapePoints[activeShapePoints.length - 1],
                    type: "MeasureDistanceVertex",
                    label: {
                        ...labelDefault,
                        text: spaceDistance(activeShapePoints),
                        pixelOffset: new Cartesian2(0, -30),
                        disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    },
                    point: {
                        color: Color.WHITE,
                        outlineColor: new Color.fromCssColorString('#3388ff').withAlpha(1),
                        outlineWidth: 2,
                        pixelSize: 8,
                    },
                });
                setVertexEntities(prevState => [...prevState, _vertexEntity])
            }
        }
    }, [activeShapePoints])

    useEffect(() => {
        if (previousPoint) {
            const points = JSON.parse(JSON.stringify(activeShapePoints))
            if (!measureStore.endDrawing) {
                points.push(previousPoint)
            }

            // set active shapre when mouse move
            viewer.current.cesiumElement.entities.remove(activeShape)
            setActiveShape(drawShape(points))

            // when points > 1 then mouse move will active label show distance
            if (points.length > 1) {
                viewer.current.cesiumElement.entities.remove(activeLabel)
                setActiveLabel(drawActiveLabel(points))
            }

            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }, [previousPoint])

    useEffect(() => {
        viewer.current.cesiumElement.cesiumWidget.screenSpaceEventHandler.removeInputAction(
            ScreenSpaceEventType.LEFT_DOUBLE_CLICK
        )
        handler.setInputAction(function (event) {
            try {
                if (customLine.positions && customLine.positions.length) {
                    let resultClick = customLine.positions[customLine.positions.length - 1]
                    if (resultClick && resultClick.position) {
                        const position = resultClick.position.clone();
                        setPreviousPoint()
                        setActiveShapePoints(point => [...point.filter(p => p.x !== position.x && p.y !== position.y && p.z !== position.z), position])
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }, ScreenSpaceEventType.LEFT_CLICK)

        // click to select draw points
        handler.setInputAction(function (event) {
            try {
                if (measureStore.endDrawing) return
                let resultClick = clickPoint(viewer.current.cesiumElement, event.endPosition)

                if (resultClick && resultClick.position) {
                    var n = customLine.positions.length;
                    if (n === 0) {
                        customLine.addPoint(resultClick);
                    } else {
                        customLine.positions[n - 1].position = resultClick.position;
                        customLine.positions[n - 1].pickedObject = resultClick.pickedObject
                    }
                    setPreviousPoint(resultClick.position)
                }
            } catch (error) { }
        }, ScreenSpaceEventType.MOUSE_MOVE)


        // Right click to end drawing shape
        handler.setInputAction(function (event) {
            setPreviousPoint()
            measureStore.setEndDrawing(true)
        }, ScreenSpaceEventType.RIGHT_CLICK)

        return () => {
            if (handler) {
                handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
                handler.removeInputAction(ScreenSpaceEventType.RIGHT_CLICK)
            }
            setActiveShapePoints([])
            setActiveShape()
            setActiveLabel()
            setCurrentShapes([])
            setVertexEntities([])
        }
    }, [])

    return (<></>)
}

export default inject('measureStore')(observer(MeasurePolyline))
