import styled, {createGlobalStyle} from 'styled-components'
import {Popconfirm} from 'antd'

export const WorkflowWrapper = styled.div`
    .splitpanel-left {
        top: 0;
        right: 0;
        width: 10px;
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        border-top: 1px solid #ddd;
        background-color: #f2cfc4;
    }
  
    .splitpanel-mobile-left {
        top: 0;
        right: 0; 
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        .btnSplitPanel {
        position: absolute;
        top: 50%;
        margin-left: -15px;
        padding: 0 !important;
        }
    }
`
export const WorkflowContent = styled.div`
   
`

export const ButtonGroupStyle = styled.div`
.ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
    min-width: 80px;
  }
`
export const HeadingPhase = styled.div`
  line-height: 30px;
  margin: -8px;
  margin-bottom: 0;
  padding: 10px;
  &:hover {
    background: lightgrey;
  }
 .ant-btn {
    float: right;    
  }
`
export const PhaseItemContainerStyle = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;  
  display: inline-table;
    flex-direction: column;
    margin: 0 8px 8px;
    background-color: #fff;
    width: 250px;
    height: fit-content;
    &:first-child{
      margin-left: 0px;             
    }
    .ant-form-item{
        margin-bottom: 10px;
    }
`
export const PhasesContainerStyle = styled.div`
  display: flex;
  padding: grid;
  display: inline-flex;
  .icon-drag path {
    fill: #cec7c7;
  }
`

export const WorkflowChecklistContainer = styled.div`
  .ant-form-item{
    margin: 0;
    width: 100%;
  }
  .ant-form-item-control { 
    line-height: inherit;
  }
  .ant-form-item-control-input-content {
    display: flex;
  } 
`

export const GlobalStylePopconfirm = createGlobalStyle`
  .ant-popconfirm{
    max-width: 300px !important;
  }
  ${props => props.ismobile? `.ant-tooltip{display: none !important;}`:``}
`
export const HeaderWorkflowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 30px;
    }
`