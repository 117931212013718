import styled from "styled-components";

export const CollapseBody = styled.div`
    .flex-row{display:flex;flex-flow:row nowrap}
    .row-center, .row-distribute {
        justify-content: space-between;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .flex-1 {
        flex-shrink: 1;
        flex-basis: auto;
        flex-grow: 1;
    }
    .text-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 29.5rem;
    }
    .text-muted {
        color: #8c8b96;
        font-size: 12px;
        text-align: center;
    }
    .flex-1 p {
        margin: 0;
    }
    .pr-1 {
      padding-right: 0.5rem!important;
    }
    .small {
      font-size: 1.2rem;
    }
    .text-bold {
      font-weight: 700;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
    .row-body{
      min-height: 5rem;
      border-bottom: 1px solid #e2e2e7;
      padding-top: 1.5rem!important;
      padding-bottom: 0.5rem!important;
      padding-left: 1rem!important;
      padding-right: 0.5rem!important;

      .row-content{
        display: flex;
        justify-content: space-between;

        .cancel-btn{
          cursor: pointer;
        }

        .text-canceled{
          color: red;
        }
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {    
      line-height: 2;     
    }
    .ant-collapse-extra {
      border-radius: 50%;
      padding: 0;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-top: -2px;
    }
    .ant-collapse-extra:hover {
      background-color: #e2e2e7;
    }

    .ant-btn-text:hover {
      background: #e2e2e7;
      border-color: #e2e2e7;
    }
    // .ant-collapse-content {
    //   max-height: 300px;
    //   overflow: auto;
    // }
`