import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { inject, observer } from 'mobx-react'
import DefaultTemplate from '../../layout/DefaultTemplate'
import { ConnectPageWrapper } from './CustomStyled'
import Container from '../../layout/Container'
import { message } from 'antd'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next';
import { toJS } from 'mobx'

const ConfirmActiveAccount = props => {
    const { t } = useTranslation();
    const {
        commonStore, usersStore, match, history
    } = props
    const { userId } = useParams()

    useEffect(() => {
        commonStore.setCurrentPage('confirm-active-account')
        if (userId) {
            usersStore.verifyAccount(userId).then((res) => {
                if (res.status === 'blocked') {
                    message.error(t(res.message))
                    history.push(`/auth/login`)
                } else if (res.status === 'actived') {
                    message.success(t(res.message))
                    history.push(`/auth/login`)
                } else if (res.status === 'verify-success') {
                    message.success(t('your-account-active-success-sign-in-to-access-website'))
                    history.push(`/auth/login`)
                }
            })
                .catch(error => {
                    message.error(t('has-an-error'))
                    history.push(`/auth/login`)
                })
        } else {
            history.push(`/auth/login`)
        }
    }, [])

    return (
        <HelmetProvider>
            <DefaultTemplate>
                <Helmet>
                    <title>{t('confirm-active-account')} | 6DPlanner</title>
                </Helmet>
                <ConnectPageWrapper>
                    <Container>
                    </Container>
                </ConnectPageWrapper>
            </DefaultTemplate >
        </HelmetProvider>
    )
}

export default inject('commonStore', 'usersStore')(observer(ConfirmActiveAccount))
