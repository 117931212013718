import styled from 'styled-components'
export const CustomTopTitle = styled.div`
float:left;
.title{
  font-weight:300;
  font-size:20px;
  color: #000000;
  text-align: start;
}
.count{
  font-size: 13px;
  color: #787e8f;
}

`

export const UploadWrapper = styled.div`
  max-height: 500px;
`
export const StepContainer = styled.div`
  margin-top: 20px;
  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 30px);
    padding-right: calc(2em + 30px);    
  }
  .colorStyle {
    color: rgb(0 0 0 / 45%);
  }
`