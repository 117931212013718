import styled from "styled-components";

export const SketchToolContainer = styled.div`
  position: absolute;
  left: 52%;
  bottom: 0;
  margin: 0px 0px 50px 0px;
  color: red;
  z-index: 9999;
`

export const SketchItem = styled.span`
  margin: 0px 1px;
  .ant-btn {
    padding : 1px 0 ;
  }
`