import React, {
  useEffect,
  useState
} from 'react'
import {
  inject,
  observer
} from 'mobx-react'
import uuid from 'uuid'
import {
  Color,
  PinBuilder,
  ScreenSpaceEventType,
  HorizontalOrigin,
  VerticalOrigin,
  Cartographic,
  Math as CesiumMath,
  Cartesian3, Matrix4, CallbackProperty
} from 'cesium'
import {
  Label,
  Entity,
  LabelCollection
} from 'resium'
import {
  clickPoint,
  cnvGlobalToLocal,
  getRefPoint
} from '../helper/CesiumUtils'
import { Trans, useTranslation } from 'react-i18next';
import { toJS } from 'mobx'

const MeasurePoint = ({
  projectStore,
  viewer,
  handler
}) => {
  const { t } = useTranslation();
  const [measurePoints, setMeasurePoints] = useState([])
  const labelDefault = {
    font: '12px sans-serif',
    showBackground: true,
    horizontalOrigin: HorizontalOrigin.CENTER,
    verticalOrigin: VerticalOrigin.TOP,
    disableDepthTestDistance: Number.POSITIVE_INFINITY
  }
  var customLine = new CustomLine();
  function CustomLine() {
    this.positions = [];
    this.markers = [];
  }

  //Function add point click
  CustomLine.prototype.addPoint = function (position) {
    var that = this;
    var n = this.positions.length;
    this.positions[n] = position;

    var marker = viewer.current.cesiumElement.entities.add({
      position: new CallbackProperty(function () {
        return that.positions[n];
      }, false),
      point: {
        pixelSize: 5,
        color: Color.RED,
        outlineColor: Color.WHITE,
        outlineWidth: 2,
      },
    })
    this.markers.push(marker);
  };

  //function remove point and line
  CustomLine.prototype.removeAll = function () {
    if (this.markers && this.markers.length > 0) {
      for (var i = 0; i < this.markers.length; i++) {
        if (viewer && viewer.current && viewer.current.cesiumElement)
          viewer.current.cesiumElement.entities.remove(this.markers[i]);
      }
      this.markers = [];
      this.positions = [];
    }
  };
  function clearAll() {
    customLine.removeAll();
    setMeasurePoints([])
  }

  /**
   * Get position mouse click
   * @param {*} viewer 
   * @param {*} movement mouse positon
   */
  async function annotate(viewer, movement) {
    let resultClick = clickPoint(viewer, movement.position)
    if (resultClick) {
      let _id = uuid()
      var cartesian = resultClick.position
      if (cartesian) {
        // //this function will make change cartesian
        // await viewer.scene.clampToHeightMostDetailed([cartesian]).then(function (result) {
        //   return result
        // })

        var cartographic = Cartographic.fromCartesian(cartesian)
        var picklong = CesiumMath.toDegrees(cartographic.longitude)
        var picklat = CesiumMath.toDegrees(cartographic.latitude)

        // var _sampleheight = viewer.scene.sampleHeight(cartographic, [], 0.1)
        // cartographic.height = _sampleheight ? _sampleheight : cartographic.height
        const coordinateSystemCode = (projectStore.projectDetail.tilesetData.coordinateSystem && projectStore.projectDetail.tilesetData.coordinateSystem.code) || projectStore.projectDetail.coordinateSystem
        var content =
          `${t('global')}: WGS84` +
          `\n${t('longitude')}  : ` + picklong.toFixed(5) + ' °' +
          `\n${t('latitude')}   : ` + picklat.toFixed(5) + ' °' +
          `\n               ${t('height')}     : ` + cartographic.height.toFixed(2) + ' m                     '
        setMeasurePoints(prevState => [
          ...prevState,
          {
            label: (<
              Label {
              ...labelDefault
              }
              id={
                _id
              }
              key={
                uuid()
              }
              position={
                cartesian
              }
              text={
                content
              }
            />
            ),
            pin: (<
              Entity key={
                uuid()
              }
              name="Blank red pin"
              position={
                cartesian
              }
              billboard={
                {
                  image: new PinBuilder().fromColor(Color.RED, 24).toDataURL(),
                  verticalOrigin: VerticalOrigin.BOTTOM,
                  disableDepthTestDistance: Number.POSITIVE_INFINITY
                }
              }
            />
            ),
          },
        ])
        if (true) {
          let headtile = ''
          let xlb = ''
          let ylb = ''
          let zlb = ''
          let model = undefined
          if (resultClick.pickedObject && resultClick.pickedObject.content && resultClick.pickedObject.content.tileset) {
            model = projectStore.findModelByUrl(resultClick.pickedObject.content.tileset?.resource?.url)
          }
          if (true) {
            let epsgCode = ''
            let epsgName = ''
            if (projectStore.projectDetail.tilesetData && projectStore.projectDetail.tilesetData.coordinateSystem) {
              epsgCode = coordinateSystemCode
              epsgName = projectStore.projectDetail.tilesetData.coordinateSystem.name
            } else {
              epsgCode = '4326'
              epsgName = 'WGS 84'
            }
            var refPoint = projectStore?.projectDetail?.tilesetData?.RefPoint
            if (!refPoint || !model) {
              refPoint = [picklat, picklong, cartographic.height]
            }

            let projectElevation = projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
            if (model && model.elevationSystem) projectElevation = model.elevationSystem

            projectStore.setLoadingProgress(true)
            if ((['4326', '4756'].includes(epsgCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree') && model) {
              const rootTranform = resultClick.pickedObject.content.tileset.root.transform
              const modelCenter = Cartesian3.fromArray(model.crs?.ModelCenter || [0, 0, 0])
              let _positiononWGS84 = cnvGlobalToLocal(
                getRefPoint(rootTranform),
                modelCenter,
                cartesian
              )

              let cartographicPoint = Cartographic.fromCartesian(_positiononWGS84)
              if (rootTranform.equals(Matrix4.IDENTITY) && modelCenter.equals(new Cartesian3(0, 0, 0))) {
                cartographicPoint = cartographic
              }
              headtile = `\n ${t('project')}: ` + epsgName
              xlb = '\nX:' + (CesiumMath.toDegrees(cartographicPoint.longitude)).toFixed(5)
              ylb = '\nY:' + CesiumMath.toDegrees(cartographicPoint.latitude).toFixed(5)
              zlb = '\nZ:' + cartographicPoint.height.toFixed(2)

              var primitives = viewer.scene.primitives._primitives.filter(function (value, index, arr) {
                return value._labels && value._labels.length > 0 && value._labels.find(x => x.id === _id)
              });
              if (primitives.length > 0) {
                var label = primitives[0]._labels.find(x => x.id === _id)
                label.text = label.text + '\n' + headtile + xlb + ylb + zlb
              }
              projectStore.setLoadingProgress(false)
            } else {
              projectStore.getHeightWGS84ToLocal(refPoint[0], refPoint[1], refPoint[2], picklat, picklong, cartographic.height, epsgCode, projectElevation ? projectElevation : 'None').then(async res => {
                if (res.Status === 'OK') {
                  if (res.Point.length > 0) {

                    const toFixed = epsgCode === '4326' ? 5 : 2
                    headtile = `\n ${t('project')}: ` + ((epsgName !== undefined) ? epsgName : 'EPSG:' + epsgCode)
                    xlb = '\nX:' + res.Point[1].toFixed(toFixed)
                    ylb = '\nY:' + res.Point[0].toFixed(toFixed)
                    zlb = '\nZ:' + (projectElevation ? res.Point[2].toFixed(toFixed) : cartographic.height.toFixed(2))

                    if (projectElevation !== 'None') {
                      await projectStore.calculateHeighCenterModelFlowGeoid(refPoint[0], refPoint[1], refPoint[2], projectElevation ? projectElevation : 'None').then(async response => {
                        if (response.Status === "OK") {
                          let hGeoid = response.Heigh - res.Point[2]
                          zlb = '\nZ:' + (res.Point[2] - hGeoid).toFixed(toFixed)
                        }
                      }).catch(error => {
                        console.log(error)
                      })
                    }

                    var primitives = viewer.scene.primitives._primitives.filter(function (value, index, arr) {
                      return value._labels && value._labels.length > 0 && value._labels.find(x => x.id === _id)
                    });
                    if (primitives.length > 0) {
                      var label = primitives[0]._labels.find(x => x.id === _id)
                      label.text = label.text + '\n' + headtile + xlb + ylb + zlb
                    }
                    if (viewer.scene.requestRenderMode) {
                      viewer.scene.requestRender();
                    }
                  }
                }
                projectStore.setLoadingProgress(false)
              }).catch(error => {
                projectStore.setLoadingProgress(false)
              })
            }
          }
        }
      }

      if (viewer.scene.requestRenderMode) {
        viewer.scene.requestRender();
      }
    }
  }


  useEffect(() => {
    handler.setInputAction(function (movement) {
      if (!viewer.current.cesiumElement.scene) return
      annotate(viewer.current.cesiumElement, movement)
    }, ScreenSpaceEventType.LEFT_CLICK)
    handler.setInputAction(function (event) {
      let resultClick = clickPoint(viewer.current.cesiumElement, event.endPosition)
      if (resultClick && resultClick.position) {
        const cartographic = Cartographic.fromCartesian(
          resultClick.position
        );
        // if (cartographic.height >= -0.1) {
          var n = customLine.positions.length;
          if (n === 0) {
            customLine.addPoint(resultClick.position);
          } else {
            customLine.positions[n - 1] = resultClick.position;
          }
        // }
      }
    }, ScreenSpaceEventType.MOUSE_MOVE);
    return () => {
      if (handler) handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
      if (handler) handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
      clearAll()
    }
  }, [])

  return (
    <>
      <LabelCollection>{measurePoints.map(p => p.label)}</LabelCollection>
      {measurePoints.map(p => p.pin)}
    </>
  )
  // Mouse over the globe to see the cartographic positions
}
export default inject('projectStore')(observer(MeasurePoint))
