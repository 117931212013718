import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { message, Button } from 'antd'
import { Topic3DLocationStyle } from './style'
import {
    Color,
    PinBuilder,
    ScreenSpaceEventType,
    HorizontalOrigin,
    VerticalOrigin,
    Cartesian3,
    ScreenSpaceEventHandler,
    CallbackProperty, Cartographic
} from 'cesium'
import { Label, Entity, LabelCollection } from 'resium'
import uuid from 'uuid'
import { clickPoint } from '../../../helper/CesiumUtils'
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect'

const Topic3DLocationControl = ({ topicStore, projectStore, viewer }) => {
    const { t } = useTranslation();
    const [topic3DLocation, setTopic3DLocation] = useState(null)
    const [customLine] = useState(new CustomLine())
    function CustomLine() {
        this.positions = [];
        this.markers = [];
    }

    //Function add point click
    CustomLine.prototype.addPoint = function (resultClick) {
        var that = this;
        var pickedObject = resultClick.pickedObject
        var position = resultClick.position
        var n = this.positions.length;
        this.positions[n] = {
            pickedObject: pickedObject,
            position: position
        };

        var marker = viewer.current.cesiumElement.entities.add({
            position: new CallbackProperty(function () {
                if (that.positions && that.positions[n] && that.positions[n].position) {
                    return that.positions[n].position;
                }
            }, false),
            point: {
                pixelSize: 5,
                color: Color.RED,
                outlineColor: Color.WHITE,
                outlineWidth: 2,
            },
        })
        this.markers.push(marker);
    };

    //function remove point and line
    CustomLine.prototype.removeAll = function () {
        if (this.markers && this.markers.length > 0) {
            for (var i = 0; i < this.markers.length; i++) {
                if (viewer && viewer.current && viewer.current.cesiumElement)
                    viewer.current.cesiumElement.entities.remove(this.markers[i]);
            }
            this.markers = [];
            this.positions = [];
        }
    };
    const labelDefault = {
        font: '12px monospace',
        showBackground: true,
        horizontalOrigin: HorizontalOrigin.CENTER,
        verticalOrigin: VerticalOrigin.TOP,
        disableDepthTestDistance: Number.POSITIVE_INFINITY
    }

    const handleCancel3DLocation = () => {
        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        projectStore.setCleanMode(false)
        topicStore.setShowTopicEditor(true, true)
        topicStore.toggleTopic3DLocationVisible(false)
    }

    const handleClickSaveLocation = (e) => {
        e.preventDefault()
        let camera = viewer.current.cesiumElement.camera
        let camData = {}
        camData.position = {
            x: camera.position.x,
            y: camera.position.y,
            z: camera.position.z,
        }
        camData.direction = {
            x: camera.direction.x,
            y: camera.direction.y,
            z: camera.direction.z,
        }
        camData.up = {
            x: camera.up.x,
            y: camera.up.y,
            z: camera.up.z,
        }
        camData.orientation = {
            heading: viewer.current.cesiumElement.camera.heading,
            pitch: viewer.current.cesiumElement.camera.pitch,
            roll: viewer.current.cesiumElement.camera.roll
        }

        let _location3D = {
            location: topic3DLocation.pin.props.position,
            camData: camData
        }

        if (topicStore.viewingTopicForm.isEdit) {
            topicStore.setLoadingProgress(true)
            topicStore.updateTopic3Dlocation(topicStore.viewingTopicForm.id, { location3D: _location3D }).then((res) => {
                topicStore.getLocationTopicsByProject(topicStore.viewingTopicForm.project)

                topicStore.viewingTopicForm.location3D = res.location3D
                topicStore.setLoadingProgress(false)
                message.success(t('add-topic-location-successfull'))
                topicStore.setShowTopicEditor(true, true)
                topicStore.toggleTopic3DLocationVisible(false)
                projectStore.setCleanMode(false)
            }).catch(error => {
                topicStore.setLoadingProgress(false)
                if (error.data) message.error(t('error-add-topic-3d-location') + t(error.data.message))
                topicStore.setShowTopicEditor(true, true)
                topicStore.toggleTopic3DLocationVisible(false)
                projectStore.setCleanMode(false)
            })
        } else {
            topicStore.setViewingTopicForm({
                ...topicStore.viewingTopicForm,
                location3D: _location3D
            })
            message.success(t('add-topic-3d-location-successfull'))
            topicStore.setShowTopicEditor(true, true)
            topicStore.toggleTopic3DLocationVisible(false)
            projectStore.setCleanMode(false)
        }
    }

    async function add3DLocation(posObj) {
        var cartesian = posObj.position
        setTopic3DLocation({
            label: (
                <Label
                    {...labelDefault}
                    key={uuid()}
                    position={cartesian}
                    text={topicStore.viewingTopicForm.name}
                />
            ),
            pin: (
                <Entity
                    key={uuid()}
                    name="Blank red pin"
                    position={cartesian}
                    billboard={{
                        image: new PinBuilder().fromColor(Color.RED, 24).toDataURL(),
                        verticalOrigin: VerticalOrigin.BOTTOM,
                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                    }}
                />
            ),
        })
        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }

    const showLocation = () => {
        let cartesian = new Cartesian3(
            topicStore.viewingTopicForm.location3D.location.x,
            topicStore.viewingTopicForm.location3D.location.y,
            topicStore.viewingTopicForm.location3D.location.z
        )
        let pBuilder = new PinBuilder()
        setTopic3DLocation({
            label: (
                <Label
                    {...labelDefault}
                    key={uuid()}
                    position={cartesian}
                    text={topicStore.viewingTopicForm.name}
                />
            ),
            pin: (
                <Entity
                    key={uuid()}
                    name="Blank red pin"
                    position={cartesian}
                    billboard={{
                        image: pBuilder.fromColor(Color.RED, 24).toDataURL(),
                        verticalOrigin: VerticalOrigin.BOTTOM,
                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                    }}
                />
            ),
        })

        if (topicStore.viewingTopicForm.location3D.camData) {
            viewer.current.cesiumElement.camera.setView({
                destination: topicStore.viewingTopicForm.location3D.camData.position,
                orientation: {
                    direction: topicStore.viewingTopicForm.location3D.camData.direction,
                    up: topicStore.viewingTopicForm.location3D.camData.up
                }
            })
        } else {
            viewer.current.cesiumElement.camera.flyTo({
                destination: cartesian
            });
        }
    }

    useEffect(() => {
        var handler = new ScreenSpaceEventHandler(
            viewer.current.cesiumElement.canvas
        )
        if (topicStore.isTopic3DLocationVisible) {
            if (topicStore.viewingTopicForm && topicStore.viewingTopicForm.location3D && topicStore.viewingTopicForm.location3D.location) {
                showLocation()
            }
            handler.setInputAction(function (event) {
                try {

                    let resultClick = clickPoint(viewer.current.cesiumElement, event.endPosition)

                    if (resultClick && resultClick.position) {
                        const cartographic = Cartographic.fromCartesian(
                            resultClick.position
                          );
                        // if (cartographic.height >= -0.1) {
                            var n = customLine.positions.length;
                            if (n === 0) {
                                customLine.addPoint(resultClick);
                            } else {
                                customLine.positions[n - 1].position = resultClick.position;
                                customLine.positions[n - 1].pickedObject = resultClick.pickedObject
                            }
                        // }
                    }
                } catch (error) { }
            }, ScreenSpaceEventType.MOUSE_MOVE)
            handler.setInputAction(function (movement) {
                if (!viewer.current.cesiumElement.scene) return
                if(isMobile || isTablet){
                    let resultClick = clickPoint(viewer.current.cesiumElement, movement.position)
                    if (resultClick && resultClick.position) {
                        var n = customLine.positions.length;
                        if (n === 0) {
                            customLine.addPoint(resultClick);
                        } else {
                            customLine.positions[n - 1].position = resultClick.position;
                            customLine.positions[n - 1].pickedObject = resultClick.pickedObject
                        }
                    }
                }
                if (customLine.positions && customLine.positions.length) {

                    let resultClick = customLine.positions[customLine.positions.length - 1]
                    if (resultClick && resultClick.position) {
                        add3DLocation(resultClick)
                    }
                }
            }, ScreenSpaceEventType.LEFT_CLICK)
        }
        return () => {
            if (handler) {
                handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
            }
            customLine.removeAll()
            setTopic3DLocation(null)
            topicStore.toggleTopic3DLocationVisible(false)
        }
    }, [])

    return (
        <>
            <Topic3DLocationStyle>
                <div>{t('topic-location')}</div>
                <div>
                    <Button loading={topicStore.isLoading} onClick={(e) => handleClickSaveLocation(e)} type="primary" disabled={topic3DLocation ? false : true}>
                        {t('commons.save')}
                </Button>
                    <Button onClick={handleCancel3DLocation} style={{ marginLeft: 8 }}>{t('commons.cancel')}</Button>
                </div>
            </Topic3DLocationStyle>
            <LabelCollection>{topic3DLocation ? topic3DLocation.label : ""}</LabelCollection>
            {topic3DLocation ? topic3DLocation.pin : ""}
        </>
    )
}
export default inject('topicStore', 'projectStore')(observer(Topic3DLocationControl))