import { observable, action, decorate } from 'mobx'
const STEP_SIZE = [
    { key: 1, value: '1m' },
    { key: 10, value: '10m' },
    { key: 20, value: '20m' },
    { key: 50, value: '50m' },
    { key: 100, value: '100m' },
    { key: 200, value: '200m' },
    { key: 500, value: '500m' }
]

class AlignmentStore {
    alignmentTimeSliderVisible = false // show hide view clipping plane alignment tool
    stepSizes = STEP_SIZE // array steps through values
    currentStep = 0 // step current
    orientationClippingPlane = false // direction plane
    changeSlider = false // status change slider
    step = 10 // step default 10m
    alignmentTicks = [] // all tick
    stepTwoPoint = 0 // step between two point
    alignmentChangeWidth = null;
    setAlignmentChangeWidth = (f) => {
        this.alignmentChangeWidth = f
    }
    toggleAlignmentTimeSliderVisible = (f) => {
        if (f !== undefined) {
            this.alignmentTimeSliderVisible = f
        } else {
            this.alignmentTimeSliderVisible = !this.alignmentTimeSliderVisible
        }
    }

    setCurrentStep = (value) => {
        this.currentStep = value
    }

    setOrientationClippingPlane = (value) => {
        this.orientationClippingPlane = value
    }

    setChangeSlider = (value) => {
        this.changeSlider = value
    }

    setStep = (value) => {
        this.step = value
    }

    setAlignmentTicks = (value) => {
        this.alignmentTicks = value
    }

    setStepTwoPoint = (value) => {
        this.stepTwoPoint = value
    }
}

decorate(AlignmentStore, {
    toggleAlignmentTimeSliderVisible: action,
    alignmentTimeSliderVisible: observable,
    currentStep: observable,
    setCurrentStep: action,
    setOrientationClippingPlane: action,
    orientationClippingPlane: observable,
    setChangeSlider: action,
    changeSlider: observable,
    setStep: action,
    step: observable,
    setAlignmentTicks: action,
    alignmentTicks: observable,
    setStepTwoPoint: action,
    stepTwoPoint: observable,
    alignmentChangeWidth: observable,
    setAlignmentChangeWidth: action
})

export default new AlignmentStore()
