import styled from 'styled-components'
export const SearchProjectWrapper = styled.div`
    display : flex ;
    margin-top : 5px;
    width : 100%;
    max-width : 400px;
    height : 32px;
    border: 1px solid #d9d9d9;
`
export const CustomSearch = styled.div`
    flex-grow : 1;
    .ant-input-affix-wrapper{
        flex-grow : 1;
        height : 32px ;
        border : none ;
        background : none ;
        outline : none ;
        input {
        border : none ;
        background : none ;
        outline : none ;
        height : 100%;
      }
    }
    .ant-input-affix-wrapper:hover{
        border : none;
    }
    .ant-input-affix-wrapper-focused{
        border : none;
        background : none ;
        outline : none ;
        box-shadow : none;
    }
    
`
export const CustomButton = styled.div`
    display : flex;
    justify-content : center ;
    align-items : center ;
    padding : 0 5px;
    font-size : 11px
`
