
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, message, Row, Switch } from 'antd'
import en from "antd/lib/date-picker/locale/en_US"
import es from "antd/lib/date-picker/locale/es_ES"
import fi from "antd/lib/date-picker/locale/fi_FI"
import sv from "antd/lib/date-picker/locale/sv_SE"
import vi from "antd/lib/date-picker/locale/vi_VN"
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const EditLicenseModal = ({ adminStore, commonStore, handleUpdateLicense, handleCloseModal }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false);
    moment.locale(`${commonStore.language}`)
    useEffect(() => {
        if (adminStore.licenseOrgEdit) {
            let _activated = moment
                .utc(adminStore.licenseOrgEdit.activated)
                .local()
                .format()

            let _expiration = moment
                .utc(adminStore.licenseOrgEdit.expiration)
                .local()
                .format()

            form.setFieldsValue({
                activated: moment(_activated, 'YYYY-MM-DD'),
                expiration: moment(_expiration, 'YYYY-MM-DD'),
                isActive: adminStore.licenseOrgEdit?.isActive
            });
        }
    }, [adminStore.licenseOrgEdit])

    const onCancelModal = () => {
        handleCloseModal()
        form.resetFields()
    }

    const updateLicense = async (id, info) => {
        setisLoadingFormEdit(true)
        adminStore.updateLicense(id, info)
            .then((res) => {
                setisLoadingFormEdit(false);
                handleUpdateLicense(res.data, adminStore.licenseOrgEdit.index)
                message.success(t('license-updated-successfully'));
                onCancelModal()
            })
            .catch((err) => {
                setisLoadingFormEdit(false);
                message.error(t('an-error-occurred'))
            })
    }

    const onSaveModal = (v) => {
        form.validateFields().then(values => {
            if (values.expiration && values.activated) {
                if (moment(values.expiration).isBefore(values.activated)) {
                    alert(t('You have to set activation date before expiration date'))
                    return
                }
            }
            updateLicense(adminStore.licenseOrgEdit.id, values)
        })
    }

    return (
        <Form
            id={'edit-duration-license-form'}
            onFinish={v => onSaveModal(v)}
            form={form}
            name="edit-license"
            layout="vertical"
        >
            <Form.Item label={t('activation')} name="activated" rules={[
                {
                    required: true,
                    message: t('Please input the activation date!'),
                }
            ]}>
                <DatePicker
                    locale={{
                        ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en),
                        lang: {
                            ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en).lang,
                            now: t('now'),
                            ok: t('commons.ok'),
                        }
                    }}
                    style={{ width: '100%' }}
                    inputReadOnly={true}
                    placeholder={t('select-time')}
                />
            </Form.Item>
            <Form.Item label={t('expiration')} name="expiration" rules={[
                {
                    required: true,
                    message: t('Please input the expiration date!'),
                }
            ]}>
                <DatePicker
                    locale={{
                        ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en),
                        lang: {
                            ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en).lang,
                            now: t('now'),
                            ok: t('commons.ok'),
                        }
                    }}
                    style={{ width: '100%' }}
                    inputReadOnly={true}
                    placeholder={t('select-time')}
                />
            </Form.Item>
            <Form.Item
                label={t('active')}
                name="isActive"
                valuePropName="checked"
                className="checkbox-status"
            >
                <Switch checkedChildren={t('active')} unCheckedChildren={t('non-active')} />
            </Form.Item>
            <Row
                justify="end"
            >
                <Button
                    onClick={onCancelModal}
                    icon={<CloseCircleOutlined />}
                >
                    {t('commons.cancel')}
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "10px" }}
                    loading={isLoadingFormEdit}
                    icon={<SaveOutlined />}
                >
                    {t('commons.save')}
                </Button>
            </Row>
        </Form>
    )
}

export default inject('adminStore', 'commonStore')(observer(EditLicenseModal))