import { ColumnWidthOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Layout, Row, Select, Tooltip} from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams, withRouter } from 'react-router-dom'
import ProjectCompleteness from './ProjectCompleteness'
import { CustomSplitMobile, ProjectDashBoardHeader, TopDashBoardWraper } from './style.js'
import TimeLineChart from './TimeLineChart'
import TopDashBoard from './TopDashBoard/TopDashBoard '
import TopicChart from './TopicChart'
import TopicTypeTable from './TopicTypeTable'
import WorkFlowChart from './WorkFlowChart'
import settings from './../../../../siteConfig';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect'
import HelpButton from '../../../elements/HelpButton'

const { Content } = Layout;
const { Option } = Select;
const DrawerProjectDashBoard = props => {
    const { t } = useTranslation();
    var widthDevice = window.innerWidth
    var ElementQueries = require('css-element-queries/src/ElementQueries');
    ElementQueries.init();

    const { projectStore, dashboardStore, commonStore } = props
    const isTabletOrMobileDevice = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(widthDevice)
    const { projectId } = useParams();
    const { curentTypeTopicPie } = dashboardStore;
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const today = new Date();
    const currentDay = today.getUTCDate() < 10 ? ('0' + today.getUTCDate()) : '' + today.getUTCDate();
    const currentMonth = (today.getMonth() + 1) < 10 ? ('0' + (today.getMonth() + 1)) : '' + (today.getMonth() + 1);
    const currentYear = today.getUTCFullYear();
    const timezone = (today.getTimezoneOffset()) / -60
    const hourMilliseconds = 60 * 60 * 1000
    const dayMilliseconds = 24 * hourMilliseconds
    const minuteMilliseconds = 60 * 1000
    const { curentQueryDate } = dashboardStore
    moment.locale(`${commonStore.language}`)

    const paramColumnChart = {
        dayNames,
        monthNames,
        today,
        currentDay,
        currentMonth,
        currentYear,
        timezone,
        dayMilliseconds,
        hourMilliseconds,
        minuteMilliseconds,
        projectId,
        timeScale: dashboardStore.curentTimeScale,
        queryDateParam: dashboardStore.curentQueryDate,
        curentCategories: dashboardStore.curentCategories,
        topicStatuses: Object.keys(settings.commonConfig.TOPICS.STATUS).map(key => ({ id: Number(key), name: settings.commonConfig.TOPICS.STATUS[key] }))
    }

    useEffect(() => {
        async function fetch() {
            dashboardStore.setLoadingProgress(true);
            hanldeChangeTimeScale(dashboardStore.curentTimeScale)
            await dashboardStore.getProjectById(projectId);
            await dashboardStore.getTopicListBelongProject(projectId);
            await dashboardStore.setTopicTypes();
            await dashboardStore.setTopicStatuses();
            await dashboardStore.setTopicPriorities();
            await dashboardStore.getTopicTags(projectId);
            if (curentQueryDate && Array.isArray(curentQueryDate) && curentQueryDate.length > 0) {
                dashboardStore.getWorkflowDataChart(paramColumnChart)
                dashboardStore.getDataTopicTimeLineChart(paramColumnChart)
            }
            if (curentTypeTopicPie) {
                let result = dashboardStore.topicListBelongProject.filter(v => v.topicstatus)
                dashboardStore.setListTopicFilterByType(result)
                dashboardStore.setListTopicTypeSelected(dashboardStore.topicStatuses)
            }
            dashboardStore.setLoadingProgress(false);
        }

        if (projectStore.showProjectDashBoard) {
            fetch();
        }
        return () => {
            dashboardStore.clearProjectDetail();
            dashboardStore.clearTopicListBelongProject();
            dashboardStore.clearTopicStatuses();
            dashboardStore.clearTopicPriorities();
            dashboardStore.clearTopicTypes();
            dashboardStore.clearTopicTags();
            dashboardStore.setListTopicTypeSelected([])
            dashboardStore.setCurentTypeTopicPie("topicstatus")
            dashboardStore.clearListTopicFilterByType()
            // dashboardStore.setCurentTimeScale("year")
        }
    }, [projectStore.showProjectDashBoard])

    useEffect(() => {
        async function fetch() {
            if (projectStore.showProjectDashBoard) {
                dashboardStore.setLoadingProgress(true);
                await dashboardStore.getWorkflowDataChart(paramColumnChart)
                await dashboardStore.getDataTopicTimeLineChart(paramColumnChart)
                dashboardStore.setLoadingProgress(false);
            }
        }
        fetch()
    }, [dashboardStore.curentTimeScale, dashboardStore.curentQueryDate])

    //#region event for mouse and touche split panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (document.body.offsetWidth ? document.body.offsetWidth : 0) - (touch.clientX - (target && target.offsetLeft ? target.offsetLeft : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if(newWidth > window.innerWidth){
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        childRef.current.handleReflowChart()
        const newWidth = document.body.scrollWidth - e.clientX; //  e.clientX + document.body.offsetLeft;
        const minDrawerWidth = 375;
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if(newWidth > window.innerWidth){
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize)

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])

    const childRef = useRef();

    const handleChangeTopicType = (value) => {
        dashboardStore.setCurentTypeTopicPie(value)
        let result;
        switch (value) {
            case "topicstatus":
                result = dashboardStore.topicListBelongProject.filter(v => v.topicstatus)
                dashboardStore.setListTopicTypeSelected(dashboardStore.topicStatuses)
                break;
            case "topictype":
                result = dashboardStore.topicListBelongProject.filter(v => v.topictype)
                dashboardStore.setListTopicTypeSelected(dashboardStore.topicTypes)
                break;
            case "topicpriority":
                result = dashboardStore.topicListBelongProject.filter(v => v.topicpriority)
                dashboardStore.setListTopicTypeSelected(dashboardStore.topicPriorities)
                break;
            default:
                return;
        }
        dashboardStore.setListTopicFilterByType(result)
    }

    const calculatorWeek = (currentDate) => {
      const startDate = new Date(currentDate.getFullYear(), 0, 1)
      const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000))
      const weekNumber = Math.ceil(days / 7)
      return weekNumber
    }

    const hanldeChangeTimeScale = timeScale => {
        dashboardStore.setCurentTimeScale(timeScale)
        let queryDate = []
        let categories = []
        switch (timeScale) {
            case "day":
                let rangeDay = 7;
                let resDay = []

                for (var i = 0; i < rangeDay; i++) {
                    let curr_Day = new Date(new Date((currentYear + '-' + currentMonth + '-' + currentDay + 'T23:59:59.000Z')).getTime() - (i * dayMilliseconds) - timezone * hourMilliseconds)
                    let before_Day = new Date(new Date((currentYear + '-' + currentMonth + '-' + currentDay + 'T23:59:59.000Z')).getTime() - ((i + 1) * dayMilliseconds) - timezone * hourMilliseconds)
                    let d = new Date(curr_Day)
                    let curr_Name = dayNames[d.getDay()];
                    resDay.push({
                        curr_Day,
                        before_Day
                    })
                    categories.push(curr_Name)
                }
                queryDate = [...resDay]
                break;
            case "week":
                let resWeek = []
                let rangeWeek = 4;
                let scale = 7
                for (var i = 0; i < rangeWeek; i++) {
                    let curr_Day = new Date(new Date((currentYear + '-' + currentMonth + '-' + currentDay + 'T23:59:59.000Z')).getTime() - (i * scale * dayMilliseconds) - timezone * hourMilliseconds)
                    let before_Day = new Date(new Date((currentYear + '-' + currentMonth + '-' + currentDay + 'T23:59:59.000Z')).getTime() - ((i + 1) * scale * dayMilliseconds) - timezone * hourMilliseconds)
                    resWeek.push({
                        curr_Day,
                        before_Day
                    })
                    queryDate = [...resWeek]
                    categories.push(`Wk ${calculatorWeek(curr_Day)}`)
                }
                break;
            case "month":
                let resMonth = []
                let rangeMonth = 12;
                let decreaseDay = 0
                let increaseDay = 0
                for (var i = 0; i < rangeMonth; i++) {
                    let totalDayInMonth = new Date(currentYear, (currentMonth - i), 0).getDate()
                    let totalDayInMonthBefore = new Date(currentYear, (currentMonth - (i + 1)), 0).getDate()
                    let fD = new Date(today.getFullYear(), today.getMonth(), 1).getDate();
                    let firstDay = fD < 10 ? ("0" + fD) : fD
                    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
                    let curr_Day = moment(new Date(new Date((currentYear + "-" + currentMonth + "-" + lastDay)).getTime() + 17 * hourMilliseconds - 1 - increaseDay * dayMilliseconds - timezone * hourMilliseconds)).format('YYYY-MM-DDTHH:mm:ss[Z]')
                    let before_Day = moment(new Date(new Date((currentYear + "-" + currentMonth + "-" + firstDay)).getTime() + 17 * hourMilliseconds - 1 - decreaseDay * dayMilliseconds - timezone * hourMilliseconds)).format('YYYY-MM-DDTHH:mm:ss[Z]')
                    let d = new Date(curr_Day)
                    let curr_Name = monthNames[d.getMonth()]
                    increaseDay += totalDayInMonth
                    decreaseDay += totalDayInMonthBefore

                    resMonth.push({
                        curr_Day,
                        before_Day
                    })
                    queryDate = [...resMonth]
                    categories.push(curr_Name)
                }
                break;
            case "year":
                let resYear = []
                let rangeYear = 5;
                for (var i = 0; i < rangeYear; i++) {
                    let curr_Day = new Date((currentYear - i) + "-" + "12" + "-" + (new Date((currentYear - i), currentMonth, 0).getDate()) + "T23:59:59.000Z")
                    let before_Day = new Date((currentYear - (i + 1)) + "-" + "12" + "-" + (new Date((currentYear - (i + 1)), currentMonth, 0).getDate()) + "T23:59:59.000Z")
                    let d = new Date(curr_Day)
                    let curr_Year = d.getUTCFullYear();
                    resYear.push({
                        curr_Day,
                        before_Day
                    })
                    queryDate = [...resYear]
                    categories.push(curr_Year)
                }
                break;
            default:
                return;
        }
        dashboardStore.setCurentCategories(categories)
        dashboardStore.setCurentQueryDate(queryDate)
    }

    const CloseIcon = () => {
        return (
          <Row style={{marginRight: 10}}>
            <Col>
              <Tooltip title={(isMobile || isTablet) ? '' : t('commons.close')} placement="bottom">
                <div>
                  <CloseOutlined onClick={() => projectStore.setShowProjectDashBoard(false)} />
                </div>
              </Tooltip>
            </Col>
          </Row>
        )
      }

    return (
        <Drawer
            title={
                <div style={{display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'start'}}>
                    <Tooltip title={t('commons.help')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={'project_dashboard'} hovered={'black'}/>
                        </div>
                    </Tooltip>
                </div>
            }
            closeIcon={<CloseIcon />}
            className="projectDashBoardPage"
            mask={false}
            id="projectDashBoardPage"
            width={drawerWidth}
            visible={projectStore.showProjectDashBoard}
            placement="right"
            destroyOnClose={true}
        >
            {
                isTabletOrMobileDevice ? (
                    projectStore.showProjectDashBoard ? (
                        <CustomSplitMobile >
                            <Button
                                onTouchStart={e => handleTouchStart(e)}
                                type="dashed" shape="circle" className="split-panel-mobile">
                                <ColumnWidthOutlined />
                            </Button>
                        </CustomSplitMobile>
                    ) : ''
                ) : (
                    <div onMouseDown={e => handleMouseDown(e)} className="split-panel" />
                )
            }
            <Row align="middle" justify="space-between" >
                <Content >
                    <ProjectDashBoardHeader>
                        <Col className="project-title" >
                            <div className="title_txt" >{t('project-dashBoard')}: </div>
                            <div className="title_name" >{dashboardStore.projectDetail.name} </div>
                        </Col>
                        <Col >
                            <Select defaultValue={dashboardStore.curentTimeScale} onChange={v => hanldeChangeTimeScale(v)} style={{ width: 120 }} >
                                <Option value="day">{t('day')}</Option>
                                <Option value="week">{t('week')}</Option>
                                <Option value="month">{t('month')}</Option>
                                <Option value="year">{t('year')}</Option>
                            </Select>
                        </Col>
                    </ProjectDashBoardHeader>

                </Content>
            </Row>
            <Layout>
                <TopDashBoardWraper >
                    <TopDashBoard />
                </TopDashBoardWraper>
                <Content className="chart-container" >
                    <Row gutter={[32, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16} className="contain-left" >
                            <Row gutter={[16, 16]} >
                                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} className="topicChart-wraper" >
                                    <Row justify="space-between" className="topic-status-option"  >
                                        <Col className="topic-title" >{t('topics-pie')}</Col>
                                        <Col>
                                            <Select defaultValue={dashboardStore.curentTypeTopicPie} onChange={v => handleChangeTopicType(v)} style={{ width: 120 }} >
                                                <Option value="topicstatus">Status</Option>
                                                <Option value="topictype">Type</Option>
                                                <Option value="topicpriority">Priority</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="topic-chart" span={22}>
                                            <TopicChart ref={childRef} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} className="topicType-wraper" >
                                    <Row >
                                        <Col className="topic-title">
                                          {`Topic ${dashboardStore.curentTypeTopicPie ? dashboardStore.curentTypeTopicPie.slice(5) : ''}`}
                                        </Col>
                                    </Row>
                                    <Row className="topic-container" >
                                        <TopicTypeTable />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="timeline-chart-wraper" >
                                <Col className="timeline-chart" span={24} >
                                    <TimeLineChart />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={8} className="contain-right" >
                            <Row className="contain-right-wraper" >
                                <Row className="workflow-wraper" >
                                    <Col span={24} className="workflow-title" >{t('speed')}</Col>
                                </Row>
                                <Row className="workflow-chart-container"  >
                                    <Col>
                                        <WorkFlowChart />
                                    </Col>
                                </Row>
                            </Row>
                            <Row className="project-complete-wraper" >
                                <Row >
                                    <Col className="title" >{t('project-completeness')}</Col>
                                </Row>
                                <Row className="container-chart"  >
                                    <Col className="project-complete">
                                        <ProjectCompleteness />
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Drawer>
    )
}

export default withRouter(
    inject(
        'projectStore',
        'dashboardStore',
        'commonStore'
    )(observer(DrawerProjectDashBoard))
)
