import { observable, action, decorate } from 'mobx'
import i18n from "../i18n/config"
import moment from 'moment'

class CalculationStore {
  isLoading = false
  sheetDatas = []
  calculationsSettings = []
  drawerWidth = 0
  rememberCalculations = []
  calculationEditor = false
  selectedCalculation = false
  operator = false
  additionCells = {}
  spreadsheet = false

  setSpreadsheet = data => {
    this.spreadsheet = data
  }

  setOperator = state => {
    this.operator = state
  }

  setAdditionCells = state => {
    this.additionCells = state
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }

  setSheetDatas = data => {
    this.sheetDatas = data
  }

  setDrawerWidth = data => {
    this.drawerWidth = data
  }

  setCalculationsSettings = data => {
    this.calculationsSettings = data
  }

  setRememberCalculations = data => {
    this.rememberCalculations = data
  }

  setCalculationEditor = data => {
    this.calculationEditor = data
  }

  setSelectedCalculation = state => {
    this.selectedCalculation = state
  }

}

decorate(CalculationStore, {
  isLoading: observable,
  setLoadingProgress: action,
  sheetDatas: observable,
  setSheetDatas: action,
  setDrawerWidth: action,
  drawerWidth: observable,
  calculationsSettings: observable,
  setCalculationsSettings: action,
  setRememberCalculations : action,
  rememberCalculations : observable,
  setCalculationEditor : action,
  calculationEditor : observable,
  selectedCalculation : observable,
  setSelectedCalculation : action,
  setOperator : action,
  operator : observable,
  setAdditionCells : action ,
  additionCells : observable,
  setSpreadsheet : action,
  spreadsheet : observable
})

export default new CalculationStore()
