import styled from 'styled-components'

export const TodosPageWrapper = styled.div`
  margin-top: 75px;
  display: block;
  padding: 15px;
  background-color: #fff;
`
export const TodosPageHeading = styled.h1`
  font-size: 24px;
  color: #ff8d4f;
  text-align: center;
  margin-bottom: 60px;
`
export const FormStyled = styled.div`
  max-width: 640px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`
export const ProjectCoverWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .img {
    img {
      height: 150px;
    }
  }
  .uploader {
    margin-left: 15px;
    .ant-form-item-control {
      line-height: 1;
    }
  }
`