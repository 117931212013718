import styled, { css } from 'styled-components'
import { Avatar } from 'antd';

export const TreeNodeTitle = styled.span`
  ${props => props.hide && css`
    color: rgb(151, 160, 175);
  `}
`
export const Container = styled.div`
  padding : 30px 0 ;
  background : #fff;
  text-align : left ;
  .ant-row-table .ant-table-cell{
    align-items: center;
    text-align: center;
}
.ant-form-item-table{
    margin-bottom: 0px !important;
}
.status-table{
    font-weight: bold;
}
  .ant-form{
    width : 100%;
  }
  .ant-form-item{
    margin-bottom : 12px;
  }
  .title{
     text-align : left ;
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 500;
    padding-bottom: 10px;
  }
  .ml-5{
    margin-left : 5px;
  }
  .ant-upload.ant-upload-drag{
    width: 150px;
    min-height: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
 }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    min-height : 150px;
    padding : 0 ;
  }
  .ant-upload-list.ant-upload-list-picture {
    display: none;
}
`

export const AttributeWrapper = styled.div`
  background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 10px;
    border-radius: 10px;
    padding: 15px;
`

export const TreeDataFolder = styled.div`
  word-break: break-word;
  div.treeData-panel div.ant-tree-treenode.empty-file {
    display: none;
    position: fixed;
    z-index: -1;
    top: -10%;
  }
 
  .drag-over .ant-tree-node-content-wrapper, .drag-over.ant-tree-treenode-switcher-close, .drag-over.ant-tree-treenode-switcher-open {
    color:#fff;
    background-color:#f26524;
    opacity:.8
  }
`
export const IconWrapper = styled.div`
    width :32px;
    height :32px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
    .ant-avatar{
      background: rgb(255, 255, 255) !important;
    }
`
export const UploadWrapper = styled.div`
  max-height: 500px;
`
export const StepContainer = styled.div`
  margin-top: 20px;
  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 30px);
    padding-right: calc(2em + 30px);    
  }
  .colorStyle {
    color: rgb(0 0 0 / 45%);
  }
`
export const HeaderLibraryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 20px;
    }
`