import React, { useEffect, useState } from 'react';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx'
import { Trans, useTranslation } from 'react-i18next';
highchartsMore(Highcharts);
solidGauge(Highcharts);

const WorkFlowChart = ({ projectStore, dashboardStore }) => {
    const { t } = useTranslation();
    const [dataChart, setDataChart] = useState(dashboardStore.dataWorkflowChart.workflowDataChart);
    const [maxY, setMaxY] = useState(dashboardStore.dataWorkflowChart.maxYWorkflow);
    const [categories, setCategories] = useState(dashboardStore.curentCategories);

    useEffect(() => {
        let _categories = dashboardStore.curentCategories.reverse()
        setDataChart(dashboardStore.dataWorkflowChart.workflowDataChart)
        setMaxY(dashboardStore.dataWorkflowChart.maxYWorkflow)
        setCategories(_categories)
    }, [dashboardStore.dataWorkflowChart])

    useEffect(() => {
        if (projectStore.showProjectDashBoard) {
            // setDataTable(dashboardStore.listTopicFilterByType)
        }
    }, [dashboardStore.curentTypeTopicPie, dashboardStore.listTopicFilterByType, dashboardStore.listTopicTypeSelected])

    const colors = ['#55D8FE', '#FF8373', "#FFDA83", '#A3A0FB', "#e74c3c", "#7f8c8d", "#2c3e50", "#00008b", "#008b8b", "#a9a9a9", "#8b008b", "#556b2f", "#ff8c00", "#9932cc", "#8b0000", "#e9967a", "#9400d3", "#bdb76b", "#ff00ff", "#ffd700", "#008000", "#4b0082", "#f0e68c", "#add8e6", "#e0ffff", "#90ee90", "#d3d3d3", "#ffb6c1", "#ffffe0", "#00ff00", "#ff00ff", "#800000", "#000080", "#808000", "#ffa500", "#ffc0cb", "#800080", "#800080", "#ff0000", "#c0c0c0", "#ffffff", "#ffff00", "#006400",
    ]
    for( var i = 0 ; i< 7 ; i++){
        categories[i] = t(categories[i])
    }
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories
        },
        yAxis: {
            min: 0,
            max: maxY,
            title: {
                text: t('total-workflow-time-usage')
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y} hrs <br/>Total: {point.stackTotal} hrs'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: dataChart,

        credits: {
            enabled: false
        },
        colors,

    }
    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
}

export default inject("projectStore", "dashboardStore")(observer(WorkFlowChart));

