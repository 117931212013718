import styled from 'styled-components'
import { Layout} from 'antd'
import AdminTemplate from '../../layout/AdminTemplate'

export const LogoWraper = styled.div`
  ${'' /* width: 120px; */}
  margin: 16px 18px;
  text-align: center;
`

export const CustomLayout = styled.div`
  .ant-layout-header {
    background: #001529;
  }
`

export const UpdateButton = styled.div`
  button {
    background-color: #4cae4c;
    color: #fff;
    border: none;
  }
  button:hover {
    background-color: #449d44;
    color: #fff;
  }
`

export const CustomButtonBack = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  span {
    padding: 20px;
  }
`

export const CustomTopTitle = styled.div`
  float: left;
  .title {
    font-weight: 300;
    font-size: 20px;
    color: #000000;
    text-align: start;
  }
  .count {
    font-size: 13px;
    color: #787e8f;
  }
`

export const ContentAdmin = styled.div`
  flex: auto;
  min-height: 0;
  background: #fff;
  text-align: center;
  height: calc(100vh - 60px);
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`
export const HeaderAdmin = styled.div`
  position: sticky;
  padding: 24px 24px 0px 24px;
  overflow: initial;
  background-color: ${props => (props.transparent ? 'transparent' : '#fff')};

}
`
export const PaginationAdmin = styled.div`
  padding: 10px; 
  padding-top: 0;
  background: #fff;
  text-align: center;
}
`
export const CustomContentAdmin = styled(ContentAdmin)`
  background: #f5f6f8;
  padding: 15px;
`

export const CustomContentAdminWrapper = styled.div`
  padding: 15px;
  overflow: initial;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgb(40 42 49 / 16%) 1px 2px 6px 1px;
  min-height: calc(100vh - 90px);

  ${props =>
    props.nodata === true
      ? 'height: 100%'
      : ''} // @media only screen and (min-width: 1200px) {
  //   min-height: 800px;
  // }

  ${HeaderAdmin} {
    top: -24px;
    z-index: 3;
    margin-top: 0;
    padding: 10px;
  }
`