import { CloseOutlined, ColumnWidthOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, Modal, Spin, Tooltip, Tabs, message } from 'antd';
import { Cesium3DTileFeature, Color } from 'cesium';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as DataTreeIcon } from '../../../../assets/svgs/data-tree.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svgs/edit.svg';
import { ReactComponent as EyeInvisibleIcon } from '../../../../assets/svgs/eye-invisible.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/svgs/eye.svg';
import GroupUtils from '../../../../group-utils';
import { ModelAttributeRequest } from '../../../../requests';
import TreeUtils from '../../../../tree-utils';
import Utils from '../../../../utils'
import HelpButton from '../../../elements/HelpButton';
import SVGIcon from '../../../elements/SVGIcon';
import CustomAttributes from '../../../layout/CustomAttributes/CustomAttributes';
import AttributeTable from './AttributeTable';
import AttributeTableV1 from './AttributeTableV1';
import AttributeTableV2 from './AttributeTableV2';
import { AttrPanel, AttrPanelTitle, CustomAttributesContainer, AttrPaneWrapper, PanelHeading, RightPanelActions } from "./CustomStyled";
import './style.css';
import { saveCurrentViewpoint } from '../../../../lib/projectLib';

const AttributesPanel = ({ viewer, uiStore, projectStore, sketchingStore, adminStore, usersStore, modelStore,userGroupStore ,getCurrent3DViewSetting }) => {
  const { t } = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
  const [disabled, setDisabled] = useState(true);
  const [attrTitle, setAttrTitle] = useState("");
  const [attrVersion, setAttrVersion] = useState("");
  const [modelId, setModelId] = useState();
  const [refId, setRefId] = useState();
  const [sketchId, setSketchId] = useState();
  const [data, setData] = useState([]);
  const [modalWidth, setModalWidth] = useState(500)
  const [objectAttributes, setObjectAttributes] = useState()

  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
    const temp = toJS(projectStore.modelList.filter(model => (model && !model.isDeleted))).map(item => {
      let visible4DModels = retrievedObject?.visible4DModels || []
      let isExist4D = visible4DModels && visible4DModels.length > 0 ? visible4DModels.find(c => c.type4D === 'model' && c.modelId === item._id) : false
      let visibleClipModels = retrievedObject?.visibleClipModels || []
      let isExistClip = visibleClipModels && visibleClipModels.length > 0 ? visibleClipModels.find(c => c.modelId === item._id) : false
      let _tem = {
        modelId: item._id,
        isRender: item.isRender ? item.isRender : false,
        style: item.style,
        type: item.type,
        sourceType: item.sourceType,
        isVisible4D: (isExist4D && isExist4D.isVisible4D ? isExist4D.isVisible4D : true),
        isVisibleClip: (isExistClip ? isExistClip.isVisibleClip : true),
        data: item.data,
        endDate: item.endDate,
        startDate: item.startDate
      }
      if (item.isUpdate && item.hasOwnProperty('isUpdate')) {
        const rebuildModelState = toJS(projectStore.stateBeforeRebuild)
        if (rebuildModelState) {
          if (rebuildModelState?.modelId === item._id) {
            _tem = {
              ..._tem,
              isRender: rebuildModelState.isRender,
              isVisible: rebuildModelState.isVisible,
              isVisible4D: rebuildModelState.isVisible4D,
              isVisibleClip: rebuildModelState.isVisibleClip,
            }
            projectStore.setStateBeforeRebuild({})
          } else _tem.isVisible = true
        } else _tem.isVisible = true
      } else {
        _tem.isVisible = true
      }
      return _tem
    })
    if (projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0) {
      let _visibletileset = temp.filter(function (newData) {
        return projectStore.visibleTilesets.filter(function (oldData) {
          if (newData.modelId === oldData.modelId && newData.isVisible !== oldData.isVisible) {
            newData.isVisible = oldData.isVisible
          }
          if (newData.modelId === oldData.modelId && newData.isVisibleClip !== oldData.isVisibleClip) {
            newData.isVisibleClip = oldData.isVisibleClip
          }
        })
      });
      projectStore.setVisibleTilesets(_visibletileset)
    } else {
      projectStore.setVisibleTilesets(temp)
    }
  }, [projectStore.modelList.length])

  const checkingFeatureRole = (type) => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
}

  function mergeArrays(array1, array2) {
    const merged = {};

    array1.forEach(obj => {
      merged[obj.id] = obj;
    });

    array2.forEach(obj => {
      if (!merged[obj.id]) {
        merged[obj.id] = obj;
      }
    });

    return Object.values(merged);
  }

  const mergeAccessTreeNode = (currentNode, parentNode) => {
    let result = currentNode || [];
    if (!parentNode && currentNode) {
      result = currentNode || []
    }
    if (!currentNode && parentNode) {
      result = parentNode || []
    }
    if (parentNode && currentNode) {
      result = mergeArrays(currentNode, parentNode)
    }
    return result;
  }

  const recursiveAccess = (node,groups,rights = []) => {
    let avaiableGroups = GroupUtils.checkAvaiableGroups(node, groups)
    let settings = mergeAccessTreeNode(rights,avaiableGroups)
    if(node.parentKey){
      let tTree = projectStore.projectDetail?.treeData || []
      let parrentNode = TreeUtils.searchTreeNode(tTree, 'key', node.parentKey);
      if(parrentNode){
        settings = recursiveAccess(parrentNode,groups,settings)
      }
    }
    return settings;
  }

  function checkAccessRightsAtTreeNode(rights){
    let currentGroup = userGroupStore.checkCurrentUserGroup(usersStore.currentUser,userGroupStore.groups);
    if (projectStore.currentUserRoleInProject === "project_owner") {
      return true;
    }else{
      let pTreeData = projectStore.projectDetail?.treeData || []
      let node;
      if(projectStore.selectedAttrData && modelStore.modelAttribute?.version !== 1 && pTreeData?.length){
        const modelId = typeof projectStore.selectedAttrData?.model3D === "string" ? projectStore.selectedAttrData?.model3D : projectStore.selectedAttrData?.model3D?.id;
        const sketchId = projectStore.selectedAttrData?.sketchId;
        node = TreeUtils.searchTreeNode(traversalTree(pTreeData), (modelId && 'modelId') || (sketchId && 'sketchId'), modelId || sketchId);
      }  
      if(modelStore.modelAttribute?.version === 1 && pTreeData?.length) {
         node = TreeUtils.searchTreeNode(traversalTree(pTreeData), 'modelId', modelStore.modelAttribute.id);
      }
      if(node){
        let isShow = false;
        let userRights = GroupUtils.checkingUserRightsAtTreeNode(node,usersStore.currentUser,pTreeData) // user rights at treenode
        if(currentGroup && currentGroup?.length > 0){
          let avaiableGroups = recursiveAccess(node,userGroupStore.groups);
          let isExist = userGroupStore.findMostPowerfulGroup(currentGroup, avaiableGroups);
          if(isExist){
            let combineRights = userRights ? userRights : isExist;
            isShow = combineRights.rights === rights ? true : false;
          }else{
            if(userRights){
              isShow =  userRights.rights ===rights ?  true : false;
            }else{
              if (node?.type === "FILE") {
                isShow = (node.indent ===0 || !node.indent) ? true : true;
              }
            }
          }
        }else{
          if(userRights){
            isShow =  userRights.rights ===rights ?  true : false;
          }else{
            if (node?.type === "FILE") {
              isShow = (node.indent ===0 || !node.indent) ? true : true;
            }
          }
        }
        return isShow;
      } else return false;
    }
  }


  // Sketch
  useEffect(() => {
    var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail._id}-${usersStore.currentUser._id}`));
    let temp = toJS(sketchingStore.arrSketches).map(item => {
      let visible4DModels = retrievedObject?.visible4DModels || []
      let isExist4D = visible4DModels.find(c => c.type4D === 'sketch' && c.sketchId === item._id)
      return { sketchId: item._id, isVisible: true, isVisible4D: (isExist4D && isExist4D.isVisible4D ? isExist4D.isVisible4D : true), endDate: item.endDate, startDate: item.startDate }
    })
    if (sketchingStore.visibleSketches.length > 0) {
      let _visibleSketch = temp.filter(function (newData) {
        return sketchingStore.visibleSketches.filter(function (oldData) {
          if (
            newData.sketchId === oldData.sketchId &&
            newData.isVisible !== oldData.isVisible
          ) {
            newData.isVisible = oldData.isVisible
          }
        })
      })
      sketchingStore.setVisibleSketches(_visibleSketch)
    } else {
      sketchingStore.setVisibleSketches(temp)
    }
  }, [sketchingStore.arrSketches])

  // Custom attributes
  useEffect(() => {
    if (usersStore.currentUser) {
      if (projectStore.attributesTab && projectStore.attributesTab === 'custom-attributes') {
        const populateData = async () => {
          projectStore.setLoadingAttrData(true)
          if (projectStore.selectedAttrData) {
            const objectId = projectStore.selectedAttrData.type === "sketch" ? projectStore.selectedAttrData?.sketchId : (projectStore.selectedAttrData?.model3D ? projectStore.selectedAttrData?.model3D : modelStore?.modelAttribute?.id)
            await projectStore.getModelAttributes(objectId, { project: projectStore.projectDetail._id , guid: projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID}).then(res => {
              projectStore.setObjectCustomAttributes(toJS(res))
              projectStore.setLoadingAttrData(false)
            })
              .catch(err => {
                console.log(err)
                projectStore.setLoadingAttrData(false)
              })
          }
        }
        populateData()
      }
    }
  }, [projectStore.attributesTab])

  useEffect(() => {
    if (projectStore.selectedAttrData && modelStore.modelAttribute?.version !== 1) {
      const modelId = typeof projectStore.selectedAttrData?.model3D === "string" ? projectStore.selectedAttrData?.model3D : projectStore.selectedAttrData?.model3D?.id;
      const sketchId = projectStore.selectedAttrData?.sketchId;
      const GUID = projectStore.selectedAttrData?.GUID;
      const refId = projectStore.selectedAttrData?.refId
      setAttrTitle('');
      setAttrVersion('');
      setData([])
      setObjectAttributes()
      setModelId(modelId || null)
      setSketchId(sketchId || null)
      setRefId(refId || null)

      const model = projectStore.modelList.find(model => model._id === modelId)
      const populateData = async (uid) => {

        const modelTreeDataAttributes = await projectStore.waitUntilDataTreeReady(modelId);
        const parentNodes = TreeUtils.searchTreeNodeForParents(modelTreeDataAttributes.treeData, 'GUID', uid)
        const treeDataAttributesData = new Array(parentNodes.length)
        function formatAttr(n) {
          const title = n.title || n.pTitle;
          const titleSplit = title?.split(" ")
          let splitTitle = title;
          let splitChildTitle = title
          if (titleSplit.length > 0) {
            splitTitle = titleSplit.shift()
            splitChildTitle = titleSplit.join(' ');

          }
          else {
            return
          }
          if (splitChildTitle?.trim() === title?.trim() || !splitChildTitle?.trim().length) {
            return
          }
          splitChildTitle = `Name=${splitChildTitle}`;
          const treeDataAttribute = {
            title: splitChildTitle,
            skipUnit: true
          }

          const attrDataTable = toAttrDataTable([treeDataAttribute], model ? model.type : null);
          attrDataTable.attrTitle = splitTitle
          return attrDataTable
        }
        for (let index = 0; index < parentNodes.length; index++) {
          const node = parentNodes[index]
          if (node && node.GUID) {
            const { data: attrData } = await ModelAttributeRequest.getAttributByGUID(node.GUID, modelId);
            if (attrData && attrData.children) {
              const properties = [...attrData.children]
              const attrDataTable = toAttrDataTable(properties, model ? model.type : null);
              if (attrData.title) {
                attrDataTable.attrTitle = attrData.pTitle || attrData.title;
              }
              treeDataAttributesData[index] = attrDataTable
            }
            else if (attrData) {

              treeDataAttributesData[index] = formatAttr(node)
            }
          }
          if (node && !node.GUID) {

            treeDataAttributesData[index] = formatAttr(node)
          }
        }

        let onlyTake2 = []
        for (let i = 0; i < treeDataAttributesData.length; i++) {
          if (treeDataAttributesData[i]) {
            onlyTake2.push(treeDataAttributesData[i])
          }

          if (onlyTake2.length === 2) {
            break
          }
        }
        onlyTake2 = onlyTake2.reverse();
        setObjectAttributes(onlyTake2)
        projectStore.setLoadingAttrData(false)
      }
      projectStore.setLoadingAttrData(true)
      if (GUID) {
        populateData(projectStore.selectedAttrData.GUID)
      }
      else {
        projectStore.setLoadingAttrData(false)
      }
      if (GUID && projectStore.modelFeatures[modelId]) {
        const features = projectStore.modelFeatures[modelId][GUID]
        let arraySelect = []
        if (Array.isArray(features)) {
          features.map(feature => {
            if (feature && feature instanceof Cesium3DTileFeature) {
              arraySelect.push(feature)
            }
          })
        } else {
          if (features && features instanceof Cesium3DTileFeature) {
            arraySelect.push(features)
          }
        }
        if (arraySelect.length > 0) {
          selectFeatures(arraySelect);
        }
      }
      let surfaceTitle = projectStore.selectedAttrData?.pTitle?.split("'")[0]
      setAttrTitle(surfaceTitle);
      setAttrVersion(projectStore.selectedAttrData?.version || '')
      if (projectStore.selectedAttrData?.children) {

        const sketch = sketchingStore.arrSketches.find(sketch => sketch._id === sketchId);
        const isImageInfo = projectStore.selectedAttrData?.isImageInfo
        const isGeoJsonDataSource = projectStore.selectedAttrData?.geoJsonDataSource
        if (model || sketch || isImageInfo || isGeoJsonDataSource) {
          const properties = [...projectStore.selectedAttrData.children]
          if (projectStore.selectedAttrData.Name) {

            const splitTitle = `Name=${projectStore.selectedAttrData.Name}`;
            properties.unshift({
              title: splitTitle,
              skipUnit: true
            })
          }
          const attrDataTable = toAttrDataTable(properties, model ? model.type : null);
          setData(attrDataTable)
        }
      }

    } else if (modelStore.modelAttribute?.version === 1) {
      projectStore.setLoadingAttrData(false)
      setAttrTitle('');
      setAttrVersion('');
      setData([])
      setObjectAttributes()
      setSketchId(null)
      setRefId(null)
      setModelId(modelStore.modelAttribute.id || null)
      const modelId = modelStore.modelAttribute.id
      if (modelId) {
        const GUID = modelStore.modelAttribute?.GUID;
        if (GUID && projectStore.modelFeatures[modelId]) {
          const features = projectStore.modelFeatures[modelId][GUID]
          let arraySelect = []
          if (Array.isArray(features)) {
            features.map(feature => {
              if (feature && feature instanceof Cesium3DTileFeature) {
                arraySelect.push(feature)
              }
            })
          } else {
            if (features && features instanceof Cesium3DTileFeature) {
              arraySelect.push(features)
            }
          }
          if (arraySelect.length > 0) {
            selectFeatures(arraySelect);
          }
        }
      }
    }
    return () => {
      projectStore.changeAttributesTab('attributes')
    }
  }, [projectStore.selectedAttrData])

  const toAttrDataTable = (data, modelType, level = 1) => {
    return data.map(item => {
      const splitTitle = item.title && Utils.splitAtFirstSpecialCharacter(item.title, '=');
      let value = '';
      let unit = '';
      if (modelType === 'landxml' || modelType === 'landxmlBackground') {
        value = splitTitle[1] || '';
        unit = item.unit || '';
      } else if (modelType === 'ifc' ||modelType === 'cad') {
        const valueUnit = (splitTitle[1] || '').trim();
        value = valueUnit;

        if (valueUnit.indexOf(" ") > -1 && !item.skipUnit) {
          value = valueUnit.slice(0, valueUnit.indexOf(" "));
          unit = valueUnit.slice(valueUnit.indexOf(" "));
        }
      } else {
        value = splitTitle[1] || '';
      }
      if (item.children) {
        return {
          key: item.key,
          attr: splitTitle[0] || '',
          value,
          unit,
          children: toAttrDataTable(item.children, modelType, level + 1),
          level
        };
      }
      return {
        key: item.key,
        attr: splitTitle[0] || '',
        value,
        unit,
        level
      };
    })
  }

  const toggleVisibleFeature = (GUID) => {
    projectStore.setIsRerenderFeatureTile(true)
    let newHideFeatures = projectStore.hideFeatures[modelId] || [];
    const { hideFeatures, allHide } = checkVisibleFeature(GUID);
    if (!allHide) {
      hideFeatures.map(feature => {
        if (!newHideFeatures.includes(feature)) {
          newHideFeatures = [...newHideFeatures, feature];
        }
      })
    } else {
      hideFeatures.map(feature => {
        newHideFeatures = newHideFeatures.filter(f => f !== feature)
      })
    }
    projectStore.setHideFeatures({
      ...projectStore.hideFeatures,
      [modelId]: newHideFeatures
    })
  }

  const checkVisibleFeature = (GUID) => {
    if (modelId && GUID && projectStore.loadedModelTreeData && projectStore.loadedModelTreeData[modelId]) {
      const treeData = projectStore.loadedModelTreeData[modelId];
      const node = TreeUtils.searchTreeNode(treeData, 'GUID', GUID);
      if (node) {
        const features = findAllGUID([node]);
        const hideFeatures = projectStore.hideFeatures[modelId] || [];
        const check = features.map(feature => !hideFeatures.includes(feature))
        const allHide = check.every(x => !x)
        return {
          allHide,
          hideFeatures: features,
        }
      }
    }
    return {
      allHide: projectStore.hideFeatures[modelId]?.includes(GUID),
      hideFeatures: [GUID]
    }
  }

  const findAllGUID = (data, GUID = []) => {
    data.map(item => {
      if (item.children) {
        findAllGUID(item.children, GUID)
      }
      if (item.GUID) {
        GUID.push(item.GUID)
      }
    })
    return GUID;
  }

  const onSavePriviousCameraData = () =>{
    if (usersStore.currentUser && usersStore.currentUser._id) {
      saveCurrentViewpoint(usersStore.currentUser._id, viewer, projectStore);
    }
  }

  const clickEditLocation = modelId => {
    onSavePriviousCameraData()
    clickClose()
    let temp = {}
    temp[modelId] = false
    // setTilesetSettingVisible(temp)
    projectStore.setCurrentModelId(modelId)
    const selectedNode = TreeUtils.searchTreeNode(traversalTree(projectStore.projectDetail.treeData), 'modelId', modelId);
    if (selectedNode?.sourceType === 'City3DDB') {
      projectStore.setShowUpdateResourceModel(true);
      projectStore.setFileEdit(selectedNode);
      projectStore.setCurrentAddTab('City3DDB');
    }else projectStore.setShowEditLocation(projectStore.modelList.find(model => model._id === modelId), true);
    projectStore.setDisplayPanel(false);
    projectStore.setSelectedNode(selectedNode);
    //Show again model if it hide for case click edit model
    const v = projectStore.visibleTilesets ? projectStore.visibleTilesets : []
    const _visiblemodel = v.map(item => {
      if (item.modelId === modelId && item.isVisible === false) {
      }
      return item
    })
    projectStore.setVisibleTilesets(_visiblemodel)
  }

  const clickShowExplorer = () => {
    uiStore.setAutoSelectedNode(true)
    if (sketchId) {
      uiStore.setSketchIdFromAttribute(sketchId)
    }
    if (modelId) {
      uiStore.setModelIdFromAttribute(modelId)
    } else {
      if (refId) {
        uiStore.setRefIdFromAttribute(refId)
      }
    }

    clickClose()
    projectStore.setDisplayPanel(true)
  }

  const selectFeatures = features => {
    setFeaturesColor(features, Color.YELLOW)
    projectStore.setSelectedFeature([...features]);
  }

  const unselectFeatures = features => {

    if (!features || !features.length) {
      return;
    }
    setFeaturesColor(features, Color.WHITE)
    if (features === projectStore.selectedFeature) {
      projectStore.setSelectedFeature(undefined);
    }
  }

  const setFeaturesColor = (features, color) => {
    features.map(feature => {
      if(!feature || Array.isArray(feature)) return ;
      feature.color = Color.clone(color, feature.color);
    })
    if (viewer?.scene?.requestRenderMode) {
      viewer.scene.requestRender();
    }
  }

  const clickClose = () => {
    uiStore.setShowAttrPanel(false)
    projectStore.setClickModelLink(false)
    modelStore.setModelAttribute({ id: null, version: 0 , GUID: null , modelType: null })
    projectStore.setSelectedAttrData(false)
  }

  const afterClose = () => {
    unselectFeatures(projectStore.selectedFeature)
    projectStore.setSelectedAttrData()
  }

  const handleTouchStart = e => {
    e.preventDefault();
    if (e.touches.length !== 1) return null;
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd, { passive: false });
    document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
  };

  const handleTouchMove = useCallback(e => {
    const touch = e.touches[0] || e.changedTouches[0];
    const modalElement = document.getElementsByClassName('attributeModal');
    const target = document.elementFromPoint(touch.clientX, touch.clientY);
    const newWidth = touch.clientX + (target && target.offsetLeft ? target.offsetLeft : 0) - modalElement[0].offsetLeft;
    const minDrawerWidth = 500;
    if (newWidth < 0) {
      setModalWidth(10);
    }
    if (newWidth > minDrawerWidth) {
      setModalWidth(newWidth);
    }
  }, []);

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, { passive: false });
    document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
    document.removeEventListener("touchmove", handleTouchMove, { passive: false });
  };

  const handleMouseDown = e => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(e => {
    const modalElement = document.getElementsByClassName('attributeModal');
    const antModalContent = document.getElementsByClassName('ant-modal-content react-draggable');
    const residual = +antModalContent[0].style.transform.match(/\(([+-]?\d+)/)[1]
    const newWidth = (e.clientX - residual) + document.body.offsetLeft - modalElement[0].offsetLeft;
    const minDrawerWidth = 450;
    if (newWidth > minDrawerWidth) {
      setModalWidth(newWidth);
    }
  }, []);

  const isVisibleModel = (modelId) => {
    return projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0 && projectStore.visibleTilesets.some(el => el.modelId === modelId && el.isVisible === true)
  }

  const isModel = (node) => {
    return !node ? false : (node && node.modelId) ? true : false;
  }

  const is3DCityDB = (modelId) => {
    const selectedNode = TreeUtils.searchTreeNode(traversalTree(projectStore.projectDetail.treeData || []), 'modelId', modelId);
    if (!selectedNode) return false
    return isModel(selectedNode) && selectedNode.sourceType === 'City3DDB';
  }

  const toggleVisibleTileset = tilesetId => {
    const _visiblemodel = projectStore.visibleTilesets.map(item => {
      if (item.modelId === tilesetId) {
        item.isVisible = !item.isVisible
      }
      return item
    })
    projectStore.setVisibleTilesets(_visiblemodel)
    projectStore.setIsRerenderFeatureTile(true)
  }

  const clickEditSketch = sketchId => {
    projectStore.setGlobeBehind(false)
    if (sketchingStore?.currentEditEntity?.currentEditEntity?.editor) {
      sketchingStore.setSketchProps({ pointDragger: [] })
      sketchingStore.currentEditEntity.currentEditEntity.editor.destroy()
      sketchingStore.setCurrentEditEntity(false)
    }
    sketchingStore.setGeometrySketch(false)

    clickClose()
    projectStore.setLoadingProgress(true)
    sketchingStore.setZoomToSketch(sketchId)
    let temp = {}
    temp[sketchId] = false
    sketchingStore.setSketchFormVisible(true)
    sketchingStore.setCurrentEditSketch(sketchingStore.arrSketches.find(sketch => sketch._id === sketchId))
    sketchingStore.setCurrentSketchId(sketchId)

    projectStore.setDisplayPanel(false)
    const selectedNode = TreeUtils.searchTreeNode(traversalTree(projectStore.projectDetail.treeData), 'sketchId', sketchId);
    projectStore.setSketchEdit(selectedNode);
  }
  const onCancel = () => {
    sketchingStore.setSketchFormVisible(false)
  }

  //new function combine edit geometry and edit sketch dimensions
  const combineClickEditSketch = sketchId => {
    onSavePriviousCameraData();
    //reset point dragger and editor
    if (sketchingStore.currentSketchId !== sketchId) {
      if (sketchingStore.currentSketchId) {
        sketchingStore.setResetSketch(sketchingStore.currentSketchId)
      }
      sketchingStore.setSketchProps({ pointDragger: [] })
      if (sketchingStore?.currentEditEntity?.currentEditEntity?.editor) {
        sketchingStore.currentEditEntity.currentEditEntity.editor.destroy()
      }
      if (sketchingStore.currentEditEntity?.currentEditEntity?.position && sketchingStore.currentEditEntity?.currentEditEntity?.drawData === "point") {
        sketchingStore.currentEditEntity.currentEditEntity.position.setCallback(sketchingStore.currentEditEntity.currentEditEntity.position._callback, true);
      }

      if (sketchingStore.currentEditEntity?.currentEditEntity?.polygon?.hierarchy && sketchingStore.currentEditEntity?.currentEditEntity?.drawData === "area") {
        sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy.setCallback(sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy._callback, true);
      }

      if (sketchingStore.currentEditEntity?.currentEditEntity && sketchingStore.currentEditSketch?.drawData === "line") {
        let entity = sketchingStore.currentEditEntity.currentEditEntity
        if (sketchingStore.currentEditEntity.currentEditEntity?.polyline) {
          entity.polyline.positions.setCallback(entity.polyline.positions._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.polylineVolume) {
          entity.polylineVolume.positions.setCallback(entity.polylineVolume.positions._callback, true);
          entity.polylineVolume.shape.setCallback(entity.polylineVolume.shape._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.wall) {
          entity.wall.positions.setCallback(entity.wall.positions._callback, true);
          entity.wall.maximumHeights.setCallback(entity.wall.maximumHeights._callback, true);
          entity.wall.minimumHeights.setCallback(entity.wall.minimumHeights._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.corridor) {
          entity.corridor.positions.setCallback(entity.corridor.positions._callback, true);
        }
      }
      sketchingStore.setCurrentEditEntity(false)
    }
    //reset olg sketchgeometry
    if (sketchingStore.currentEditSketch && sketchId != sketchingStore.currentEditSketch.id) {
      sketchingStore.setResetGeometrySketch(sketchingStore.currentEditSketch)
    }
    //close attribute,open edit dialog
    projectStore.setGlobeBehind(false)
    sketchingStore.setGeometrySketch(false)
    sketchingStore.setSketchFormVisible(true)
    projectStore.setDisplayPanel(false)
    clickClose()
    //zoom and save current edit sketch information
    sketchingStore.setZoomToSketch(sketchId)
    sketchingStore.setCurrentEditSketch(sketchingStore.arrSketches.find(sketch => sketch._id === sketchId))
    sketchingStore.setCurrentSketchId(sketchId)
    const selectedNode = TreeUtils.searchTreeNode(traversalTree(projectStore.projectDetail.treeData), 'sketchId', sketchId);
    projectStore.setSketchEdit(selectedNode);
    sketchingStore.setGeometrySketch(sketchingStore.arrSketches.find(sketch => sketch._id === sketchId))
  }

  const clickEditGeometrySketch = sketchId => {
    projectStore.setGlobeBehind(false)
    if (sketchingStore?.currentEditEntity?.currentEditEntity?.editor && sketchingStore.currentSketchId !== sketchId) {
      sketchingStore.setSketchProps({ pointDragger: [] })
      sketchingStore.currentEditEntity.currentEditEntity.editor.destroy()
      if (sketchingStore.currentEditEntity?.currentEditEntity?.position && sketchingStore.currentEditEntity?.currentEditEntity?.drawData === "point") {
        sketchingStore.currentEditEntity.currentEditEntity.position.setCallback(sketchingStore.currentEditEntity.currentEditEntity.position._callback, true);
      }

      if (sketchingStore.currentEditEntity?.currentEditEntity?.polygon?.hierarchy) {
        // if (sketchingStore.currentEditEntity?.currentEditEntity?.polygon?.hierarchy && sketchingStore.currentEditEntity?.currentEditEntity?.drawData === "area") {
        sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy.setCallback(sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy._callback, true);
      }

      // if (sketchingStore.currentEditEntity?.currentEditEntity && sketchingStore.currentEditSketch?.drawData === "line") {
      if (sketchingStore.currentEditEntity?.currentEditEntity) {
        let entity = sketchingStore.currentEditEntity.currentEditEntity
        if (sketchingStore.currentEditEntity.currentEditEntity?.polyline) {
          entity.polyline.positions.setCallback(entity.polyline.positions._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.polylineVolume) {
          entity.polylineVolume.positions.setCallback(entity.polylineVolume.positions._callback, true);
          entity.polylineVolume.shape.setCallback(entity.polylineVolume.shape._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.wall) {
          entity.wall.positions.setCallback(entity.wall.positions._callback, true);
          entity.wall.maximumHeights.setCallback(entity.wall.maximumHeights._callback, true);
          entity.wall.minimumHeights.setCallback(entity.wall.minimumHeights._callback, true);
        }
        if (sketchingStore.currentEditEntity.currentEditEntity?.corridor) {
          entity.corridor.positions.setCallback(entity.corridor.positions._callback, true);
        }
      }
      sketchingStore.setCurrentEditEntity(false)
    }

    if (sketchingStore.sketchFormVisible) {
      // sketchingStore.setResetSketch(sketchingStore.currentSketchId)
      onCancel()
    }
    if (sketchingStore.currentEditSketch && sketchId != sketchingStore.currentEditSketch.id) {
      sketchingStore.setResetGeometrySketch(sketchingStore.currentEditSketch)
    }
    clickClose()
    projectStore.setLoadingProgress(true)
    sketchingStore.setZoomToSketch(sketchId)
    // sketchingStore.setSketchFormVisible(true)
    sketchingStore.setCurrentEditSketch(sketchingStore.arrSketches.find(sketch => sketch._id === sketchId))
    sketchingStore.setCurrentSketchId(sketchId)
    projectStore.setDisplayPanel(false)
    const selectedNode = TreeUtils.searchTreeNode(traversalTree(projectStore.projectDetail.treeData), 'sketchId', sketchId);
    projectStore.setSketchEdit(selectedNode);
    sketchingStore.setGeometrySketch(sketchingStore.arrSketches.find(sketch => sketch._id === sketchId))
  }

  const traversalTree = (data, parentKey,indent = 0) => {
    return data.map(item => {
      if (item.children) {
        return {
          ...item,
          children: traversalTree(item.children, item.key,indent +1),
          parentKey,
          indent
        };
      }
      return {
        ...item,
        parentKey,
        indent
      };
    });
  }

  const isVisibleSketch = (sketchId) => {
    return sketchingStore.visibleSketches.length > 0 && sketchingStore.visibleSketches.some(el => el.sketchId === sketchId && el.isVisible === true)
  }

  const toggleVisibleSketch = sketchId => {
    const _visibleSketch = sketchingStore.visibleSketches.map(item => {
      if (item.sketchId === sketchId) {
        item.isVisible = !item.isVisible
      }
      return item
    })
    sketchingStore.setVisibleSketches(_visibleSketch)
  }

  /**
         * Save new project custom attribute
         * @param {*} data
         */
  const handleAddAttribute = (data) => {
    projectStore.setLoadingProgress(true)
    projectStore.createModelCustomAttributes(data).then(res => {
      if(res.level === "file") {
        projectStore.setObjectCustomAttributes({...projectStore.objectCustomAttributes, fileLevel : [ ...projectStore.objectCustomAttributes.fileLevel , res]})
      } else if (res.level === "object") {
        projectStore.setObjectCustomAttributes({...projectStore.objectCustomAttributes, objectLevel : [ ...projectStore.objectCustomAttributes.objectLevel , res]})
      }
      projectStore.setLoadingProgress(false)
      message.success(t('add-attribute-successfully'))
      projectStore.setVisibleModalObjectAtt(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
  * Update project custom attribute
  * @param {*} attribute id, data
  */
  const handleUpdateAttribute = (id, data) => {
    projectStore.setLoadingProgress(true)
    projectStore.updateModelCustomAttributes(id, data).then(res => {
      projectStore.setLoadingProgress(false)
      message.success(t('update-attribute-successfully'))
      if(res.level === "file") {
        let index = projectStore.objectCustomAttributes.fileLevel.findIndex(c => c.id === res.id)
        if (index !== -1) {
          let _objectCustomAttributes = [...projectStore.objectCustomAttributes.fileLevel]
          _objectCustomAttributes[index] = res
          projectStore.setObjectCustomAttributes({...projectStore.objectCustomAttributes , fileLevel :_objectCustomAttributes })
        }
      } else if (res.level === "object"){
        let index = projectStore.objectCustomAttributes.objectLevel.findIndex(c => c.id === res.id)
        if (index !== -1) {
          let _objectCustomAttributes = [...projectStore.objectCustomAttributes.objectLevel]
          _objectCustomAttributes[index] = res
          projectStore.setObjectCustomAttributes({...projectStore.objectCustomAttributes , objectLevel :_objectCustomAttributes })
        }
      }
      projectStore.setVisibleModalObjectAtt(false)
      projectStore.setCurrentObjectAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
  * Delete project custom attribute
  * @param {*} attribute id 
  */
  const handleDeleteAttribute = (id) => {
    projectStore.setLoadingProgress(true)
    projectStore.deleteModelCustomAttributes(id).then(res => {
      projectStore.setLoadingProgress(false)
      message.success(t('delete-attribute-successfully'))
      let index = projectStore.objectCustomAttributes.findIndex(c => c.id === res.id)
      if (index !== -1) {
        let _objectCustomAttributes = [...projectStore.objectCustomAttributes]
        _objectCustomAttributes.splice(index, 1)
        projectStore.setObjectCustomAttributes(_objectCustomAttributes)
      }
      projectStore.setVisibleModalObjectAtt(false)
      projectStore.setCurrentObjectAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
  * Update model attribute table
  * @param {*} projectId,payload, customAttributeDatas
  */
  const updateModelAttributes = (data) => {
    projectStore.setLoadingProgress(true)
    projectStore.updateModelAttributes(data).then(res => {
      projectStore.setObjectCustomAttributes(res)
      projectStore.setLoadingProgress(false)
      message.success(t('update-attribute-successfully'))
      projectStore.setIsEditingModelAttribute(false)
      projectStore.setIsEditingObjectAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => setDisabled(true)}
        >
          <PanelHeading>
            <AttrPanelTitle
              active={projectStore.attributesTab === 'attributes' ? true : false}
              marginLeft={10}
              onClick={() => projectStore.changeAttributesTab('attributes')}
            >
              {t('attributes')}
            </AttrPanelTitle>
            {
              !projectStore.clickModelLink && checkAccessRightsAtTreeNode('modify') && (checkingFeatureRole('feature_custom_attribute_view') || checkingFeatureRole('feature_custom_attribute_edit')) && (
                <AttrPanelTitle
                  active={projectStore.attributesTab === 'custom-attributes' ? true : false}
                  marginLeft={118}
                  onClick={() => projectStore.changeAttributesTab('custom-attributes')}
                >
                  {t('Custom *')}
                </AttrPanelTitle>
              )
            }
            <RightPanelActions>
              {
                !projectStore.clickModelLink && !projectStore.loadingAttrData && (projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID) && modelId && !is3DCityDB(modelId) && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (
                   checkAccessRightsAtTreeNode('modify') || checkAccessRightsAtTreeNode('view')
                ) && (
                  <Tooltip
                    title={!checkVisibleFeature(projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID)?.allHide ? t('hide') : t('show')}
                    overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                    {!checkVisibleFeature(projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID)?.allHide ? (
                      <Avatar
                        shape="square"
                        icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                        onClick={() => toggleVisibleFeature(projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID, false)}
                      />
                    ) : (
                      <Avatar
                        shape="square"
                        icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                        onClick={() => toggleVisibleFeature(projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID, true)}
                      />
                    )}
                  </Tooltip>
                )
              }
              {
                sketchId && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (
                  checkAccessRightsAtTreeNode('modify') ||  checkAccessRightsAtTreeNode('view')
                ) && (
                  <Tooltip
                    title={isVisibleSketch(sketchId) ? t('hide') : t('show')}
                    overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                    {isVisibleSketch(sketchId) ? (
                      <Avatar
                        shape="square"
                        icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                        onClick={() => toggleVisibleSketch(sketchId)}
                      />
                    ) : (
                      <Avatar
                        shape="square"
                        icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                        onClick={() => toggleVisibleSketch(sketchId)}
                      />
                    )}
                  </Tooltip>
                )
              }
              {
                !is3DCityDB(modelId) && !projectStore.clickModelLink && !projectStore.selectedAttrData?.GUID && !modelStore.modelAttribute.GUID && modelId && (checkingFeatureRole("feature_data_tree_edit") || checkingFeatureRole("feature_data_tree_view")) && (
                  checkAccessRightsAtTreeNode('modify') || checkAccessRightsAtTreeNode('view')
                ) && (
                  <Tooltip
                    title={isVisibleModel(modelId) ? t('hide') : t('show')}
                    overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                    {isVisibleModel(modelId) ? (
                      <Avatar
                        shape="square"
                        icon={<SVGIcon content={<EyeIcon />} width={32} height={32} />}
                        onClick={() => toggleVisibleTileset(modelId)}
                      />
                    ) : (
                      <Avatar
                        shape="square"
                        icon={<SVGIcon content={<EyeInvisibleIcon />} width={32} height={32} />}
                        onClick={() => toggleVisibleTileset(modelId)}
                      />
                    )}
                  </Tooltip>
                )
              }
              {!projectStore.clickModelLink && !projectStore.visitedMode && modelId && projectStore.modelList.find(model => model._id === modelId)?.type !== 'landxmlBackground' && checkingFeatureRole("feature_data_tree_edit") && checkAccessRightsAtTreeNode('modify') && (
                <Tooltip
                  title={t('data-settings')}
                  overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <Avatar
                    shape="square"
                    icon={<SVGIcon content={<EditIcon />} width={32} height={32} />}
                    onClick={() => {
                      getCurrent3DViewSetting(true)
                      clickEditLocation(modelId)
                    }}
                  />
                </Tooltip>
              )}
              {!projectStore.clickModelLink && !projectStore.visitedMode && sketchId && checkingFeatureRole("feature_sketch") && checkAccessRightsAtTreeNode('modify') && (
                <Tooltip
                  title={t('data-settings')}
                  overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <Avatar
                    shape="square"
                    icon={<SVGIcon content={<EditIcon />} width={32} height={32} />}
                    onClick={() => {
                      getCurrent3DViewSetting(true)
                      combineClickEditSketch(sketchId)
                    }}
                  />
                </Tooltip>
              )}
              {!projectStore.clickModelLink && (checkAccessRightsAtTreeNode('modify') || checkAccessRightsAtTreeNode('view')) ? <Tooltip title={t('show-in-data-tree')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Avatar
                  shape="square"
                  icon={<SVGIcon content={<DataTreeIcon />} width={32} height={32} />}
                  onClick={clickShowExplorer}
                />
              </Tooltip> : ''}
              <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Avatar
                    shape="square"
                    icon={<HelpButton fontsize={20} 
                    helppage={'attributes_panel'}
                    styles={`
                          padding: 5px;
                          border-radius: 3px;
                          .question-icon{
                            position: relative;
                            top: -3px;
                          }
                          `}
                    />}
                  />
              </Tooltip>
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => clickClose()}
              />
            </RightPanelActions>
          </PanelHeading>
        </div>
      }
      width={modalWidth}
      mask={false}
      wrapClassName="wrapAttributeModal"
      className="attributeModal"
      closeIcon={<></>}
      visible={uiStore.showAttrPanel}
      onOk={() => { }}
      onCancel={() => clickClose()}
      afterClose={() => afterClose()}
      modalRender={modal => <Draggable disabled={isTabletOrMobileDevice ? true : disabled}>{modal}</Draggable>}
      maskClosable={false}
      footer={false}
    >
      <AttrPaneWrapper>
        {isTabletOrMobileDevice ? (
          <div className="splitpanel-mobile">
            <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
              <ColumnWidthOutlined />
            </Button>
          </div>
        ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel" />)
        }
        {
          projectStore.attributesTab === 'attributes' ?
            modelStore.modelAttribute.version !== 1 && modelStore.modelAttribute.version !== 2 ? (
              <Spin tip={t('loading')} spinning={projectStore.loadingAttrData}>
                {projectStore.selectedAttrData?.status === 'PENDING' ? (
                  <Alert style={{ margin: '0 10px 32px' }} message={t('object-info-is-extracting-please-come-back-later')} type="warning" showIcon />
                ) : (
                  !projectStore.loadingAttrData ?
                    <AttrPanel>
                      <AttributeTable
                        data={data}
                        attrTitle={attrTitle}
                        attrVersion={attrVersion}
                        //treeDataAttributes={treeDataAttributes}
                        objectAttributes={objectAttributes}
                        objectData={projectStore.selectedAttrData}
                      />
                    </AttrPanel>
                    : <Alert style={{ margin: '0 10px 32px' }} message={t('loading-object-info')} type="warning" showIcon />
                )
                }
              </Spin>
            ) :
              (
                <Spin tip={t('loading')} spinning={projectStore.loadingAttrData}>
                  {
                    !projectStore.loadingAttrData ?
                      <AttrPanel>
                        {
                          modelStore.modelAttribute.version === 2 ? (
                            <AttributeTableV2
                              objectData={projectStore.selectedAttrData}
                              modelType={modelStore.modelAttribute?.modelType}
                            />
                          ) : (
                            <AttributeTableV1
                              objectData={projectStore.selectedAttrData}
                              modelType={modelStore.modelAttribute?.modelType}
                            />
                          )
                        }
                      </AttrPanel>
                      : <Alert style={{ margin: '0 10px 32px' }} message={t('loading-object-info')} type="warning" showIcon />
                  }
                </Spin>
              )
            : null
        }
        {
          projectStore.attributesTab === 'custom-attributes' && (checkingFeatureRole('feature_custom_attribute_view') || checkingFeatureRole('feature_custom_attribute_edit')) && (
            <Spin tip={t('loading')} spinning={projectStore.loadingAttrData}>
              {
                !projectStore.loadingAttrData ?
                  <div className='custom-attr-box'>
                    <CustomAttributesContainer>
                      {projectStore.objectCustomAttributes?.fileLevel?.length > 0 ? <CustomAttributes
                        place='attributes-panel'
                        zIndex={9999}
                        titleLevel={t('file-level')}
                        customAttributeDatas={projectStore.objectCustomAttributes?.fileLevel?.filter(c => !c.isDeleted)}
                        txtEditModal={'Edit object custom attribute'}
                        txtAddMoal={'Add new object custom attribute'}
                        isProjectCustomAtt={false}
                        handleUpdateAttribute={handleUpdateAttribute}
                        handleAddAttribute={handleAddAttribute}
                        handleDeleteAttribute={handleDeleteAttribute}
                        updateModelAttributes={updateModelAttributes}
                        setIsEditingModelAttribute={e => {
                          projectStore.setIsEditingModelAttribute(e) 
                          projectStore.setIsEditingObjectAttribute(false)
                        }}
                        isEditingModelAttribute={projectStore.isEditingModelAttribute}
                        visibleModal={projectStore.visibleModalObjectAtt}
                        setVisibleModalAttribute={e => projectStore.setVisibleModalObjectAtt(e)}
                        setCurrentEditAttribute={e => projectStore.setCurrentObjectAttribute(e)}
                        currentAttribute={projectStore.currentObjectAttribute}
                        prjAttributeDatas={projectStore.prjCustomAttributeList.filter(c => !c.isDeleted && c.level === "file")}
                        selectedAttrData={{
                          modelId: projectStore.selectedAttrData?.type === "sketch" ? projectStore.selectedAttrData?.sketchId : projectStore.selectedAttrData?.model3D ? projectStore.selectedAttrData?.model3D :modelStore.modelAttribute.id,
                          guidLoad:  projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID, 
                          objectType: projectStore.selectedAttrData?.type === "sketch" ? 'sketch' : 'model'
                        }}
                      >
                      </CustomAttributes> : null}
                      {(projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID) && projectStore.objectCustomAttributes?.objectLevel?.length > 0  ? <CustomAttributes
                        place='attributes-panel'
                        zIndex={9999}
                        titleLevel={t('object-level')}
                        customAttributeDatas={projectStore.objectCustomAttributes?.objectLevel?.filter(c => !c.isDeleted)}
                        txtEditModal={'Edit object custom attribute'}
                        txtAddMoal={'Add new object custom attribute'}
                        isProjectCustomAtt={false}
                        handleUpdateAttribute={handleUpdateAttribute}
                        handleAddAttribute={handleAddAttribute}
                        handleDeleteAttribute={handleDeleteAttribute}
                        updateModelAttributes={updateModelAttributes}
                        setIsEditingModelAttribute={e => {
                          projectStore.setIsEditingModelAttribute(false) 
                          projectStore.setIsEditingObjectAttribute(e)
                        }}
                        isEditingModelAttribute={projectStore.isEditingObjectAttribute}
                        visibleModal={projectStore.visibleModalObjectAtt}
                        setVisibleModalAttribute={e => projectStore.setVisibleModalObjectAtt(e)}
                        setCurrentEditAttribute={e => projectStore.setCurrentObjectAttribute(e)}
                        currentAttribute={projectStore.currentObjectAttribute}
                        prjAttributeDatas={projectStore.prjCustomAttributeList.filter(c => !c.isDeleted && c.level === "object")}
                        selectedAttrData={{
                          modelId: projectStore.selectedAttrData?.type === "sketch" ? projectStore.selectedAttrData?.sketchId : projectStore.selectedAttrData?.model3D ? projectStore.selectedAttrData?.model3D :modelStore.modelAttribute.id,
                          GUID: projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID, 
                          guidLoad:  projectStore.selectedAttrData?.GUID || modelStore.modelAttribute.GUID,
                          objectType: projectStore.selectedAttrData?.type === "sketch" ? 'sketch' : 'model'
                        }}
                      ></CustomAttributes> : null}
                    </CustomAttributesContainer>
                  </div>
                  : <Alert style={{ margin: '0 10px 32px' }} message={t('loading-custom-attributes-info')} type="warning" showIcon />
              }
            </Spin>
          )
        }
      </AttrPaneWrapper>
    </Modal >

  )
}

export default inject('uiStore', 'projectStore', 'sketchingStore', 'adminStore', 'usersStore', 'modelStore','userGroupStore')(observer(AttributesPanel))
