import { Button, Col, Form, message, Modal, Row, Select } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import validator from '../../../../validator'
import { generateUsername } from '../../../../lib/projectLib'

const { Option } = Select;

const ModalAddAccessControl = ({ projectStore, userGroupStore, projectTeamsStore, usergroups, setUserGroups }) => {
  const { t } = useTranslation();
  const [formAddGroup] = Form.useForm();
  const { processedUserGroups } = userGroupStore;

  const onCancel = () => {
    formAddGroup.resetFields();
    userGroupStore.setShowAddNewGroupToTreeData(false)
  }

  const checkExistGroup = (id, lists) => {
    let isExist = lists.find(c => c.id === id)
    return isExist ? 1 : 0
  }

  const onAddNewUserGroupToTreeData = () => {
    formAddGroup.validateFields().then(values => {
      let newData = {
        rights: values.rights || "view",
      }
      // let group = userGroupStore.groups.find(c => c.id === values.group)
      let group = processedUserGroups.find(c => c.id === values.group)
      let isExistGroup = usergroups.find(c => c.id === values.group)
      if (isExistGroup) {
        message.error(t('group-already-exist'))
      } else {
        if (group) {
          newData.id = values.group
          newData.name = group.name
        }
        setUserGroups(usergroups => [
          ...usergroups,
          newData
        ])
        message.success(t('add-group-successfully'))
        onCancel()
      }
    });
  }

  return (
    <Modal
      visible={userGroupStore.showAddNewGroupToTreeData}
      title={t('add-group-or-user')}
      okText={t('commons.create')}
      cancelText={t('commons.cancel')}
      onCancel={onCancel}
      onOk={onAddNewUserGroupToTreeData}
      forceRender={false}
      maskClosable={false}
      destroyOnClose
      zIndex={9999}
      className="ant-modal-body-12"
      footer={
        <Row justify="end">
          <Button key="back" onClick={onCancel}>
            {t('commons.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={userGroupStore.isAppLoading}
            onClick={onAddNewUserGroupToTreeData}>
            {t('commons.add')}
          </Button>
        </Row>
      }>
      <Row>
        <Col span={24}>
          <Form form={formAddGroup} name="Add-group" layout="vertical">
            <Form.Item
              label={t('group-or-user')}
              name={'group'}
              rules={[
                {
                  required: true,
                  message: t('please-select-group-or-user'),
                },
                { validator: validator.validateEmptyString },
              ]}>
              <Select
                showSearch={true}
                placeholder={t("select-a-group-or-user")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {processedUserGroups &&
                  processedUserGroups.filter(c => c.email !== "maintenance@xd-visuals.fi" || c?.username !== "maintenance")
                    .filter(c => !checkExistGroup(c.id, usergroups))
                    .map((v, i) => {
                      return (
                        <Option key={v.id} value={v.id}>
                          {v.name}
                        </Option>
                      )
                    })}
              </Select>
            </Form.Item>
            <Form.Item
              name={'rights'}
              label={t("access-right")}
              rules={[
                {
                  required: true,
                  message: t('please-select-rights-access'),
                },
                { validator: validator.validateEmptyString },
              ]}>
              <Select
                showSearch={true}
                placeholder={t("select-access-right")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={userGroupStore.rightsOptions}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default inject('projectStore', 'userGroupStore', 'projectTeamsStore')(observer(ModalAddAccessControl))