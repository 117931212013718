import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Layout,
  message,
  Popconfirm,
  Row,
  Table,
  Input,
  Form,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fi'
import 'moment/locale/es'
import 'moment/locale/sv'
import 'moment/locale/vi'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import queryString from 'query-string'
import { Link, useHistory, useParams } from 'react-router-dom'
import AdminTemplate from '../../../layout/AdminTemplate'
import {
  CustomTopTitle,
  CustomContentAdminWrapper,
  HeaderAdmin,
  PaginationAdmin,
} from '../style'
import { SearchOrganizationWrapper, CustomSearch, CustomButton } from './style'
import debounce from 'lodash/debounce'
import { Trans, useTranslation } from 'react-i18next'
import axios from 'axios'
import { apiUrl } from './../../../../config'
import { toJS } from 'mobx'
import { checkAvaiableLicenses } from '../../../../lib/projectLib'
import settings from '../../../../siteConfig'
import Utils from '../../../../utils'
let cancelToken

const Organization = props => {
  const { commonStore, organizationStore, adminStore } = props
  const { t } = useTranslation()
  const [isLoadingTableUsers, setisLoadingTableUsers] = useState(
    organizationStore.isLoading
  )
  const [searchValue, setSearchValue] = useState('')
  const history = useHistory()
  const [form] = Form.useForm()
  const [_limit, setLimit] = useState()
  const [_page, setPage] = useState()
  const { queryParam } = useParams()
  const [fetchURL, setFetchURL] = useState(true)
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  moment.locale(`${commonStore.language}`)

  useEffect(() => {
    commonStore.setCurrentPage('admin/organization')
    adminStore.clearCurrentOrganizationDetail()
    adminStore.clearStatus()
    const queryStringParams = queryString.parse(queryParam)
    if (queryStringParams.search) {
      setSearchValue(queryStringParams.search)
      form.setFieldsValue({
        searchOrrg: queryStringParams.search,
      })
    }
  }, [])

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    if (!queryStringParams.search && !fetchURL) {
      setSearchValue('')
      form.setFieldsValue({
        searchOrrg: ''
      })
      handleSearchUsers('')
    }
  }, [queryParam])


  useEffect(() => {
    return () => {
      adminStore.clearPaginationParams()
    }
  })
  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    setLimit(parseInt(queryStringParams._limit))
    setPage(parseInt(queryStringParams._page))
    if (fetchURL && _page && _limit) {
      setisLoadingTableUsers(true)
      adminStore.setCurrentPage(_page)
      adminStore.setCurrentLimit(_limit)
      adminStore
        .customGetOrganizations(
          _limit,
          _page,
          searchValue ? searchValue.trim() : searchValue
        )
        .then(res => {
          setisLoadingTableUsers(false)
        })
        .catch(() => {
          setisLoadingTableUsers(false)
          adminStore.clearCustomListOrganization()
        })
    }
  }, [queryParam, _page, _limit])

  const onChange = e => {
    debounceCalculate(e.target.value)
  }

  const calculateStorage = data => {
    let ptojects = data.filter(c => !c.isDeleted) || []
    let quotas = 0
    while (ptojects.length > 0) {
      const prj = ptojects.shift()
      quotas = quotas + Number(prj.storage || 0)
    }
    return quotas
  }

  const handleRedirectEditOrganizationForm = () => {
    adminStore.setRiderect(true)
    adminStore.setEditMode(true)
    adminStore.setCreateMode(false)
  }

  const handleDeleteOrganization = async id => {
    setisLoadingTableUsers(true)
    adminStore
      .deleteOrganization(id)
      .then(() => {
        adminStore.setRiderect(false)
        adminStore.organizations.data = adminStore.organizations.data.filter(
          x => x.id !== id
        )
        message.success(t('delete-successfully'))
        setisLoadingTableUsers(false)
      })
      .catch(() => {
        message.error(t('delete-failed'))
      })
      .finally(() => { })
  }

  const handleModeCreateOrganization = () => {
    adminStore.setRiderect(true)
    adminStore.setCreateMode(true)
    adminStore.setEditMode(false)
    return history.push('/admin/organization/new')
  }
  const handleSearchUsers = async e => {
    const search = e ? e.trim() : e
    setFetchURL(false)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    if (search === '') {
      setSearchValue()
      history.push(
        `/admin/organizations/_limit=${_limit ? _limit : 10}&_page=${1}`
      )
    } else {
      setSearchValue(search)
      history.push(
        `/admin/organizations/_limit=${_limit ? _limit : 10
        }&_page=${1}&search=${encodeURIComponent(e)}`
      )
    }

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    cancelToken = axios.CancelToken.source()
    try {
      setisLoadingTableUsers(true)
      const response = await axios.get(
        `${apiUrl}/organizations/paginate?_limit=${_limit}&_page=${1}${search && search !== '' ? '&search=' + search : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          cancelToken: cancelToken.token,
        }
      )
      if (response) {
        setisLoadingTableUsers(false)
        let orgList = response.data?.data || []
        let result = orgList.map(element => {
          let _totalLicenses = element.licenses
          let _avaiableLicense =checkAvaiableLicenses(_totalLicenses)
          return {
            ...element,
            avaiableLicense: _avaiableLicense,
          }
        })
        adminStore.setCustomListOrganization({
          data: result,
          count: response.data?.count || 0,
        })
      }
    } catch (error) {
      if (error) {
        setisLoadingTableUsers(false)
        adminStore.clearCustomListOrganization()
        console.log(error)
      }
    }
  }

  const clearSearch = () => {
    setFetchURL(true)
    setSearchValue('')
    form.resetFields()
    //setDataTable(adminStore.customListProjects.data)
    setLimit(_limit ? _limit : 10)
    setPage(1)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    return history.push(
      `/admin/organizations/_limit=${_limit ? _limit : 10}&_page=${1}`
    )
  }

  const debounceCalculate = debounce(function (value) {
    handleSearchUsers(value)
  }, 500)

  const addTimeStap = (day) => {
    if (day) return 60000 * 60 * 24 * 4 * day
  }
  const getTimeStap = (date) => {
    if (date) return new Date(date).getTime()
  }

  const sortExpire = obj => {
    let _expireTime
    let expireTimeZone
    let r = obj.avaiableLicense
    if (r && r.length > 0) {
      expireTimeZone = r[0].expiration ? getTimeStap(r[0].expiration) : (getTimeStap(r[0].activated ? r[0].activated : r[0].createdAt) + addTimeStap(_time))
      let _time = r[0].timeLimit || 0
      _expireTime = r[0].expiration
        ? moment(r[0].expiration).toString()
        : moment(r[0].createdAt).add(_time, 'days').toString()
      for (let i = 0; i < r.length; i++) {
        let currentExpireDate = r[i].expiration
          ? moment(r[i].expiration).toString()
          : moment(r[i].createdAt).add(r[i].timeLimit, 'days').toString()
        if (Date.parse(currentExpireDate) < Date.parse(_expireTime)) {
          _expireTime = currentExpireDate
        }
        if ((r[i].expiration ? getTimeStap(r[i].expiration) : getTimeStap(r[i].activated ? r[i].activated : r[i].createdAt) + addTimeStap(r[i].timeLimit)) < expireTimeZone) {
          expireTimeZone = r[i].expiration ? getTimeStap(r[i].expiration) : getTimeStap(r[i].activated ? r[i].activated : r[i].createdAt) + addTimeStap(r[i].timeLimit)
        }
      }
    }
    return expireTimeZone
  }
  const getLengthUser = obj => {
    let licenseMaxUser = 0
    let _p = []
    let r = obj.avaiableLicense
    let _projects = obj.projects.filter(v => !v.isDeleted)
    if (_projects.length > 0) {
      _projects.map(prj => {
        let _cPrjUserRole = prj.projectuserroles?.filter(
          elm =>
            elm.email &&
            elm.inviteStatus !== 'rejected' &&
            elm.email !== 'maintenance@xd-visuals.fi'
        )
        // eslint-disable-next-line no-unused-expressions
        _cPrjUserRole?.map(c => {
          let isExist = _p.find(k => k.email === c.email)
          if (isExist) return
          _p.push(c)
        })
      })
    }
    if (r) {
      for (let i = 0; i < r.length; i++) {
        if (r[i].numberUser < 9999 && typeof licenseMaxUser === 'number') {
          licenseMaxUser += r[i].numberUser
        } else {
          licenseMaxUser = 'UNLIMITTED'
        }
      }
    }
    return { length: _p ? _p.length : 0, licenseMaxUser: licenseMaxUser }
  }
  const getLengthProject = (record, obj) => {
    let _projects = []
    let licenseMaxPrj = 0
    let r = obj.avaiableLicense
    if (r) {
      for (let i = 0; i < r.length; i++) {
        if (r[i].numberProject < 9999 && typeof licenseMaxPrj === 'number') {
          licenseMaxPrj += r[i].numberProject
        } else {
          licenseMaxPrj = 'UNLIMITTED'
        }
      }
    }
    if (record) {
      _projects = record.filter(v => !v.isDeleted)
    }
    return {
      length: _projects ? _projects.length : 0,
      licenseMaxPrj: licenseMaxPrj,
    }
  }

  const columns = [
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      // defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('users'),
      dataIndex: 'organizationuserroles',
      key: 'users',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        compare: (a, b) =>
          a && b ? getLengthUser(a).length - getLengthUser(b).length : true,
      },
      render: (record, obj) => {
        let data = getLengthUser(obj)
        return (
          <span>
            {data.length}/{data.licenseMaxUser}
          </span>
        )
      },
    },
    {
      title: t('expire'),
      key: 'expire',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = sortExpire(a) ? new Date(sortExpire(a)) : distantFuture
        let dateB = sortExpire(b) ? new Date(sortExpire(b)) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: (record, obj) => {
        return (
          <span>
            {sortExpire(obj)
              ? moment(sortExpire(obj)).format('MMMM Do YYYY, h:mm:ss a')
              : ''}
          </span>
        )
      },
    },
    {
      title: t('storage'),
      dataIndex: 'projects',
      key: 'storage',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        compare: (a, b) =>
          calculateStorage(a.projects) - calculateStorage(b.projects),
      },
      render: (record, obj) => {
        let licenseMaxQuota = 0
        let r = obj.avaiableLicense
        if (r) {
          for (let i = 0; i < r.length; i++) {
            if (
              r[i].storageQuota < 9999 &&
              typeof licenseMaxQuota === 'number'
            ) {
              licenseMaxQuota += r[i].storageQuota
            } else {
              licenseMaxQuota = 'UNLIMITTED'
            }
          }
          licenseMaxQuota =
            licenseMaxQuota !== 'UNLIMITTED'
              ? parseFloat(licenseMaxQuota.toFixed(3))
              : licenseMaxQuota
        }
        let result = calculateStorage(record) || 0
        return (
          <span>
            {Utils.kbytesToSize(result)}/
            {`${licenseMaxQuota} ${licenseMaxQuota === 'UNLIMITTED' ? '' : 'GB'
              }`}
          </span>
        )
      },
    },
    {
      title: t('projects'),
      dataIndex: 'projects',
      key: 'projects',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        compare: (a, b) =>
          a && b
            ? getLengthProject(a.projects, a).length -
            getLengthProject(b.projects, b).length
            : true,
      },
      render: (record, obj) => {
        let data = getLengthProject(record, obj)
        return (
          <span>
            {data.length}/{data.licenseMaxPrj}
          </span>
        )
      },
    },
    {
      title: t('created'),
      key: 'createdAt',
      width: 150,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = a.createdAt ? new Date(a.createdAt) : distantFuture
        let dateB = b.createdAt ? new Date(b.createdAt) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: record => {
        return <div>{moment(record.createdAt).format('MMM DD, YYYY')}</div>
      },
    },
    {
      title: t('action'),
      dataIndex: 'id',
      key: 'event',
      fixed: 'right',
      width: 120,
      align: 'center',
      render: record => {
        return (
          <Row type="flex" justify="center" key="action">
            <Link
              onClick={() => handleRedirectEditOrganizationForm()}
              to={`/admin/organization/${record}`}>
              <Button style={{ margin: 2, padding: '3px 7px' }}>
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              placement="top"
              title={t('are-you-sure-delete')}
              onConfirm={() => handleDeleteOrganization(record)}
              okText={t('commons.yes')}
              cancelText={t('commons.no')}>
              <Button type="danger" style={{ margin: 2, padding: '3px 7px' }}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Row>
        )
      },
    },
  ]

  const handleChangePagination = (current, pageSize) => {
    setFetchURL(true)
    setLimit(pageSize)
    setPage(current)
    adminStore.setCurrentPage(current)
    adminStore.setCurrentLimit(pageSize)
    if (searchValue) {
      return history.push(
        `/admin/organizations/_limit=${pageSize}&_page=${current}&search=${searchValue.trim()}`
      )
    } else {
      return history.push(
        `/admin/organizations/_limit=${pageSize}&_page=${current}`
      )
    }
  }
  return (
    <AdminTemplate title={t('organization')}>
      <CustomContentAdminWrapper
        nodata={adminStore.organizations?.count ? false : true}>
        <HeaderAdmin>
          <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Col xs={24} sm={12}>
              <CustomTopTitle>
                <div className="title">{t('organizations')}</div>
                <div className="count">
                  {t('total-records-found', {
                    length: adminStore.organizations?.count || 0,
                  })}
                </div>
              </CustomTopTitle>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                style={{ float: isMobile ? 'left' : 'right' }}
                type="primary"
                onClick={() => handleModeCreateOrganization()}>
                <PlusOutlined style={{ marginRight: '5px' }} />
                {t('add-organization')}
              </Button>
            </Col>
            <Col span={24}>
              <Form form={form} layout="vertical">
                <SearchOrganizationWrapper>
                  <CustomSearch>
                    <Form.Item name="searchOrrg">
                      <Input
                        // onChange={e => handleSearchUsers(e.target.value)}
                        onChange={onChange}
                        placeholder={t('search-for-an-entry')}
                        prefix={<SearchOutlined />}></Input>
                    </Form.Item>
                  </CustomSearch>
                  {searchValue && (
                    <CustomButton onClick={() => clearSearch()}>
                      <CloseOutlined />
                    </CustomButton>
                  )}
                </SearchOrganizationWrapper>
              </Form>
            </Col>
          </Row>
        </HeaderAdmin>
        <PaginationAdmin>
          <Table
            rowKey={record => record.id}
            size="default"
            bordered
            scroll={{ x: 1000 }}
            loading={isLoadingTableUsers}
            columns={columns}
            dataSource={adminStore.organizations?.data || []}
            pagination={{
              defaultPageSize: _limit,
              showSizeChanger: true,
              current: _page,
              pageSizeOptions: settings.adminPageSizeOptions,
              onChange: (page, pageSize) =>
                handleChangePagination(page, pageSize),
              total: adminStore.organizations?.count || 1,
              locale: { items_per_page: '' },
              position: ['topRight'],
            }}
            locale={{ emptyText: t('no-data') }}
            onRow={record => ({
              onClick: event => {
                // console.log(toJS(record))
              },
            })}
          />
        </PaginationAdmin>
      </CustomContentAdminWrapper>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore'
)(observer(Organization))
