
import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import './style.js';
import { Col, Row, Button, Empty, Modal, Input, Form, InputNumber } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { CustomAttributesContainer, CustomAttributesTool, TableAttributes } from './style.js';
import ModalEditCustomAttribute from './ModalEditCustomAttribute.js';

const CustomAttributes = ({ projectStore, adminStore, zIndex, currentAttribute, selectedAttrData, prjAttributeDatas, setCurrentEditAttribute, setVisibleModalAttribute, visibleModal, isProjectCustomAtt, customAttributeDatas, txtAddMoal, txtEditModal, handleUpdateAttribute, handleAddAttribute, handleDeleteAttribute, updateModelAttributes, isEditingModelAttribute, setIsEditingModelAttribute ,place , titleLevel }) => {
    const { t } = useTranslation();
    const [visibleEditIcon, setVisibleEditIcon] = useState(false)
    const [form] = Form.useForm();

    const checkingFeatureRole = (type) => {
        if (!type) return false
        return adminStore.checkingFeatureRole(projectStore, type)
    }

    useEffect(() => {
        if (!customAttributeDatas || customAttributeDatas?.length === 0) {
            setVisibleEditIcon(false)
            if (setIsEditingModelAttribute) {
                setIsEditingModelAttribute(false)
            }
        }
    }, [customAttributeDatas])

    useEffect(() => {
        return () => {
            if (setIsEditingModelAttribute) {
                setIsEditingModelAttribute(false)
            }
        }
    }, [])

    useEffect(() => {
        if (!isEditingModelAttribute) {
            form.resetFields()
        }

    }, [isEditingModelAttribute])

    const handleCloseModal = () => {
        setVisibleModalAttribute(false)
        setCurrentEditAttribute(false)
    }

    const handleClickEditAtt = () => {
        if (customAttributeDatas && customAttributeDatas.length > 0) {
            setVisibleEditIcon(!visibleEditIcon)
        } else {
            setVisibleEditIcon(false)
        }
    }

    const handleClickEditing = () => {
        if (customAttributeDatas && customAttributeDatas.length > 0) {
            setIsEditingModelAttribute(!isEditingModelAttribute)
        } else {
            setIsEditingModelAttribute(false)
        }
        if (isEditingModelAttribute) {
            form.resetFields()
        }
    }

    const handleCancelEditing = () => {
        if (isEditingModelAttribute) {
            form.resetFields()
            setIsEditingModelAttribute(false)
        }
    }

    const hanldeSaveObjectAttribute = () => {
        form.validateFields().then(values => {
            let payload = customAttributeDatas.map(elm => {
                let data = {
                    projectcustomattribute: elm.id,
                    value: { value: values[elm.id] },
                    guid: selectedAttrData?.GUID ? selectedAttrData.GUID : null
                }
                if (elm?.customattribute && elm?.customattribute?.id) {
                    data.id = elm.customattribute.id
                } else {
                    data.id = 'new'
                    data.unit = elm.unit
                    data.name = elm.name
                    data.type = elm.type
                    if (selectedAttrData) {
                        data.objectType = selectedAttrData.objectType
                        data.modelId = selectedAttrData.modelId
                        data.guid = selectedAttrData.GUID
                    }
                }
                return data
            })
            const data = {
                project: projectStore.projectDetail?.id,
                attributes: payload,
                customAttributeDatas,
                id: selectedAttrData.modelId,
                guid: selectedAttrData?.guidLoad ? selectedAttrData.guidLoad : null
            }
            updateModelAttributes(data)
        })
    }

    const renderForm = (attributeType, initialValue, name) => {
        switch (attributeType) {
            case 'text':
                return (
                    <Form.Item
                        initialValue={initialValue}
                        name={name}
                        rules={[
                            {
                                required: false,
                                message: `Value is required.`,
                            },
                        ]}
                    >
                        <Input
                        />
                    </Form.Item>
                )
            case 'number':
                return (
                    <Form.Item
                        initialValue={initialValue}
                        name={name}
                        rules={[
                            {
                                required: false,
                                message: `Value is required.`,
                            },
                        ]}
                    >
                        <InputNumber  controls={false} />
                    </Form.Item>
                )
            case 'date':
                return (
                    <Form.Item
                        initialValue={initialValue}
                        name={name}
                        rules={[
                            {
                                required: false,
                                message: `Value is required.`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                )
            default:
                break
        }
    }

    return (
        <CustomAttributesContainer place={place}>
        <div>
        {
                    checkingFeatureRole('feature_custom_attribute_edit') && (
                        <CustomAttributesTool gutter={[16, 8]} justify='end'>
                            {
                                isProjectCustomAtt && (
                                    <Col>
                                        <Button onClick={() => setVisibleModalAttribute(true)} className='c-button' type="primary">{t('commons.new')}</Button>
                                    </Col>
                                )
                            }
                            {
                                customAttributeDatas && customAttributeDatas.length > 0 && (
                                    <Col>
                                        {
                                            isProjectCustomAtt ? (
                                                <Button onClick={handleClickEditAtt} className='c-button' type="primary">{visibleEditIcon ? t('commons.close') : t('commons.edit')}</Button>
                                            ) : (
                                                <>
                                                {
                                                    isEditingModelAttribute && <Button onClick={handleCancelEditing} style={{marginRight :'5px'}} className=''>{t('commons.cancel')}</Button>
                                                }
                                                <Button onClick={isEditingModelAttribute ? hanldeSaveObjectAttribute : handleClickEditing} className='c-button' type="primary">{isEditingModelAttribute ? t('commons.save') : t('commons.edit')}</Button>
                                                </>
                                            )
                                        }
                                    </Col>
                                )
                            }
                        </CustomAttributesTool>
                    )
                }
                <div><span className='att-table-label'>{titleLevel}</span><span className='att-table-label-kt'>{titleLevel ? "*" : ""}</span></div>
        </div>
            <Form form={form} style={{overflowX: 'auto'}}>
                <Row className={`att-table-container ${customAttributeDatas && customAttributeDatas.length > 0 ?'':'empty-table'}`}>
                    {
                        customAttributeDatas && customAttributeDatas.length > 0 ? (
                            <TableAttributes>
                                <tbody>
                                    <tr className='tr-attribute'>
                                        <th className='tr-attribute-name'>{t('name')}</th>
                                        <th className='tr-attribute-att'>{t('level')}</th>
                                        {
                                            isProjectCustomAtt ? <th className='tr-attribute-att '>{t('type')}</th> : <th className='tr-attribute-value'>{t('value')}</th>
                                        }
                                            <th className='tr-attribute-att'>{t('accumulation')}</th>
                                            <th className='tr-attribute-att'>{t('timescale')}</th>
                                            <th className='tr-attribute-unit'>{t('unit')}</th>
                                        {
                                            isProjectCustomAtt && visibleEditIcon && <th className='tr-attribute-action'></th>
                                        }
                                    </tr>
                                    {
                                        customAttributeDatas
                                            .map((_attribute, index) => (
                                                <tr key={index}>
                                                    <td>{_attribute?.name ? _attribute?.name : ""}</td>
                                                    <td className='tr-attribute-capitalize'>{_attribute?.level ? t(`${_attribute?.level}`) : ""}</td>
                                                    {
                                                        isProjectCustomAtt ? <td className='tr-attribute-capitalize'>{_attribute?.type ? t(`${_attribute?.type}`) : ""}</td> : (
                                                            !isEditingModelAttribute ? <td
                                                                className="editable-cell-value-wrap"
                                                                style={{
                                                                    paddingRight: 24,
                                                                }}
                                                            >{_attribute?.customattribute && _attribute?.customattribute?.value ? _attribute?.customattribute?.value?.value : ""}</td> : (
                                                                <td>{renderForm(_attribute?.type, _attribute?.customattribute?.value?.value, _attribute.id)}</td>
                                                            )
                                                        )
                                                    }
                                                    <td className='tr-attribute-capitalize'>{_attribute?.accumulation && _attribute?.type ==='number'? t(`${_attribute?.accumulation}`) : ""}</td>
                                                    <td className='tr-attribute-capitalize'>{_attribute?.timescale && _attribute?.type ==='number' && _attribute?.accumulation ==='cumulative' ? t(`${_attribute?.timescale}`) : ""}</td>
                                                    {
                                                        <td>{_attribute?.unit && _attribute?.type ==='number'  ? _attribute?.unit : ""}</td>
                                                    }
                                                    {
                                                        isProjectCustomAtt && visibleEditIcon && (
                                                            <td >
                                                                <span className='action-tool-attribute'>
                                                                    <Button
                                                                        className="btn btn-link"
                                                                        type="button"
                                                                        style={{ float: 'right' }}
                                                                        onClick={() => {
                                                                            setVisibleModalAttribute(true)
                                                                            setCurrentEditAttribute(_attribute)
                                                                        }}
                                                                    >
                                                                        <EditOutlined />
                                                                    </Button>
                                                                </span>
                                                            </td>
                                                        )
                                                    }
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </TableAttributes>
                        ) : (<Empty description={<span>{t('no-custom-attribute-data')}</span>} />)
                    }
                </Row>
                <Modal
                    className="modal-add-license"
                    title={currentAttribute ? txtEditModal : txtAddMoal}
                    zIndex={zIndex}
                    visible={visibleModal}
                    onCancel={handleCloseModal}
                    maskClosable={false}
                    footer={false}
                    forceRender={true}
                >
                    <ModalEditCustomAttribute
                        handleCloseModal={handleCloseModal}
                        currentAttribute={currentAttribute}
                        isProjectCustomAtt={isProjectCustomAtt}
                        visibleModal={visibleModal}
                        handleUpdateAttribute={handleUpdateAttribute}
                        handleAddAttribute={handleAddAttribute}
                        handleDeleteAttribute={handleDeleteAttribute}
                        prjAttributeDatas={prjAttributeDatas}
                        selectedAttrData={selectedAttrData}
                    ></ModalEditCustomAttribute>
                </Modal>
            </Form>
            {/* <div><span className='att-table-label'>t('file-level')</span><span className='att-table-label-kt'>*</span></div> */}
        </CustomAttributesContainer>
    )
}

export default inject('projectStore', 'adminStore')(observer(CustomAttributes))
