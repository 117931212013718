import { Col, Row, Tooltip } from 'antd'
import React from 'react';
import uuid from 'uuid'
import { isMobile, isTablet } from 'react-device-detect';
import { } from './CustomStyled'
import { t } from 'i18next';
import { toJS } from 'mobx';
function AttributeTableV2({ objectData, modelType }) {
    const getUnitData = (rightData) => {
        let unit = '';
        if (rightData) {
            let data = rightData.trim().split(" ");
            if (data && Number.isFinite(parseFloat(data[0]))) {
                unit = rightData.replace(data[0], '').trim()
            }
        }
        return unit
    }
    const getValueData = (rightData) => {
        let value = '';
        if (rightData) {
            let data = rightData.trim().split(" ");
            if (data && Number.isFinite(parseFloat(data[0]))) {
                value = data[0]
            } else {
                value = rightData.trim();
            }
        }
        if (value === '.T.') {
            value = 'true'
        } else if (value === '.F.') {
            value = 'false'
        }
        return value
    }

    const getValueDataV2 = (value) => {
        if (value === '.T.') {
            value = 'true'
        } else if (value === '.F.') {
            value = 'false'
        }
        return value
    }

    // Function to extract value by key
    function extractValue(key, str) {
        const regex = new RegExp(`"${key}":"(.*?)"`, 'g');
        const match = regex.exec(str);
        return match ? match[1] : '';
    }

    const getName = (dataName, start) => {
        let keyName = ""
        if (dataName) {
            if (start == "name=") {
                dataName.map(item => {
                    if (item.children?.length > 0) {
                        item.children.map(attr => {
                            if (attr.inf?.startsWith(start)) {
                                if (!keyName) {
                                    keyName = attr.inf.split('=')[1]
                                }
                            }
                        })
                    }
                })
            } else {
                dataName.map(item => {
                    if (item.inf?.startsWith(start)) {
                        if (!keyName) {
                            keyName = item.inf.split('=')[1]
                        }
                    }
                })
            }
        }
        return keyName
    }
    const renderData = (data) => {
        let res = [];
        if (data && data.length > 0) {
            data.map((obj) => {
                let dataFeature = [];
                let result = (modelType === "ifc" || modelType === "cad") ?
                    <div key={uuid()}>
                        <div className="attribute__firstLine attribute__line">
                            <span className="attribute__firstLine__name">
                                {obj?.inf?.split("(") ? obj.inf.split("(")[0] : obj.inf.split("'") ? obj.inf.split("'")[0] : obj.inf}
                            </span>
                        </div>
                        {obj?.children?.name && obj.children.name !== "Undefined" ? <Row className="childAttribute">
                            <React.Fragment key={uuid()}>
                                <Col className="headingAttribute__item" span={8}>
                                    <Tooltip title={t('globel-id')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                        Name
                                    </Tooltip>
                                </Col>
                                <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={16}>
                                    <Tooltip title={obj.children.name} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                        {obj.children.name}
                                    </Tooltip>
                                </Col>
                            </React.Fragment>
                        </Row> : null}
                        {obj.guid ? <Row className="childAttribute">
                            <React.Fragment key={uuid()}>
                                <Col className="headingAttribute__item" span={8}>
                                    <Tooltip title={t('globel-id')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                        GlobalID
                                    </Tooltip>
                                </Col>
                                <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={16}>
                                    <Tooltip title={obj.guid} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                        {obj.guid}
                                    </Tooltip>
                                </Col>
                            </React.Fragment>
                        </Row> : null}
                        <Row className="childAttribute">
                            <React.Fragment key={uuid()}>
                                {
                                    obj.children?.children?.map((attr) => {
                                        let leftTitle = attr.inf.indexOf('(') > 0 ? attr.inf.split("(")[0] : attr.inf.split("'")[0]
                                        let rightTitle = attr.inf.indexOf('(') > 0 ? attr.inf.split("(")[1] : attr.inf.split("'")[1]
                                        let spliceRightTitle = rightTitle?.indexOf(')') > 0 ? (rightTitle?.slice(0, rightTitle.length - 1).replaceAll("'", "")) : rightTitle?.replaceAll("'", "")
                                        return (
                                            <React.Fragment key={uuid()}>
                                                {
                                                    attr.type === 10 || attr.type === 5 ? null : <React.Fragment key={uuid()}>
                                                        <Col className="headingAttribute__title" span={8}>
                                                            <Tooltip title={leftTitle.replaceAll("'", "")} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                {leftTitle ? leftTitle.replaceAll("'", "") : attr.title}
                                                            </Tooltip>
                                                        </Col>
                                                        <Col className="headingAttribute__title" style={{ paddingLeft: 35 }} span={16}>
                                                            <Tooltip title={spliceRightTitle} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                {spliceRightTitle}
                                                            </Tooltip>
                                                        </Col>
                                                    </React.Fragment>
                                                }
                                                {
                                                    attr?.children?.map((at) => {
                                                        // objectinfo version 2
                                                        const _label = extractValue("label", at.inf);
                                                        const _value = extractValue("value", at.inf);
                                                        const _unit = extractValue("unit", at.inf);
                                                        return (
                                                            <React.Fragment key={uuid()} >
                                                                <Col className="headingAttribute__item" span={8}>
                                                                    <Tooltip title={_label ? _label : at?.title} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                        {_label ? _label : at?.title}
                                                                    </Tooltip>
                                                                </Col>
                                                                <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={8}>
                                                                    <Tooltip title={getValueDataV2(_value)} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                        {getValueDataV2(_value) && getValueDataV2(_value) !== 'null' ? getValueDataV2(_value) : ''}
                                                                    </Tooltip>
                                                                </Col>
                                                                <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={8}>
                                                                    <Tooltip title={_unit ? _unit : ''} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                        {_unit && _unit !== 'null' ? _unit : ''}
                                                                    </Tooltip>
                                                                </Col>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </React.Fragment>
                        </Row>
                    </div> :
                    <div key={uuid()}>
                        <div className="attribute__firstLine attribute__line">
                            <span className="attribute__firstLine__name">
                                {obj.inf} {getName(obj.children, 'name=') ? `'${getName(obj.children, 'name=')}'` : null}
                            </span>
                        </div>
                        {obj.guid ? <Row className="childAttribute">
                            <React.Fragment key={uuid()}>
                                <Col className="headingAttribute__item" span={8}>
                                    <Tooltip title={t('globel-id')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                        GlobalID
                                    </Tooltip>
                                </Col>
                                <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={16}>
                                    <Tooltip title={obj.guid} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                        {obj.guid}
                                    </Tooltip>
                                </Col>
                            </React.Fragment>
                        </Row> : null}
                        <Row className="childAttribute">
                            <React.Fragment key={uuid()}>
                                {
                                    obj?.children?.map((attrChildren) => {
                                        let leftTitle = attrChildren?.inf?.indexOf('(') > 0 ? attrChildren?.inf?.split("(")[0] : attrChildren?.inf?.split("'")[0]
                                        let rightTitle = attrChildren?.inf?.indexOf('(') > 0 ? attrChildren?.inf?.split("(")[1] : attrChildren?.inf?.split("'")[1]
                                        let spliceRightTitle = rightTitle?.indexOf(')') > 0 ? (rightTitle?.slice(0, rightTitle.length - 1).replaceAll("'", "")) : rightTitle?.replaceAll("'", "")
                                        return (
                                            <React.Fragment key={uuid()}>
                                                {
                                                    attrChildren.type === 10 || attrChildren.type === 5 || !attrChildren.inf ? null : <React.Fragment key={uuid()}>
                                                        <Col className="headingAttribute__title" span={8}>
                                                            <Tooltip title={leftTitle?.replaceAll("'", "")} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                {leftTitle?.replaceAll("'", "")}
                                                            </Tooltip>
                                                        </Col>
                                                        {spliceRightTitle ? <Col className="headingAttribute__title" style={{ paddingLeft: 35 }} span={16}>
                                                            <Tooltip title={spliceRightTitle} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                {spliceRightTitle}
                                                            </Tooltip>
                                                        </Col> : null}
                                                    </React.Fragment>
                                                }
                                                {
                                                    attrChildren?.children?.map((at) => {
                                                        let leftTitle = at?.inf?.indexOf('=') > 0 ? at?.inf?.split("=")[0] : at?.inf?.split("'")[0]
                                                        let rightTitle = at?.inf?.indexOf('=') > 0 ? at?.inf?.split("=")[1] : at?.inf?.split("'")[1];
                                                        let unit = getUnitData(rightTitle)
                                                        let spliceRightTitle = rightTitle?.indexOf(')') > 0 ? (rightTitle?.slice(0, rightTitle.length - 1).replaceAll("'", "")) : rightTitle?.replaceAll("'", "")
                                                        return (
                                                            <React.Fragment key={uuid()}>
                                                                <Col className="headingAttribute__item" span={8}>
                                                                    <Tooltip title={at.type === 10 ? null : leftTitle.replaceAll("'", "")} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                        {at.type === 10 ? null : leftTitle.replaceAll("'", "")}
                                                                    </Tooltip>
                                                                </Col>
                                                                <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={8}>
                                                                    <Tooltip title={getValueData(spliceRightTitle)} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                        {getValueData(spliceRightTitle)}
                                                                    </Tooltip>
                                                                </Col>
                                                                <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={8}>
                                                                    <Tooltip title={unit} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                        {unit}
                                                                    </Tooltip>
                                                                </Col>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                                {
                                                    attrChildren?.children?.filter(x => x.type === 10)?.map(feature => {
                                                        feature.children.map((featureChild) => {
                                                            dataFeature.push(<Row className="childAttribute" key={uuid()}>
                                                                <React.Fragment>
                                                                    <>
                                                                        {
                                                                            <React.Fragment key={uuid()}>
                                                                                <Col className="headingAttribute__title" span={8}>
                                                                                    <Tooltip title={getName(featureChild.children, "code=")} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                                        {getName(featureChild.children, "code=")}
                                                                                    </Tooltip>
                                                                                </Col>
                                                                                <Col className="headingAttribute__title" style={{ paddingLeft: 35 }} span={16}>
                                                                                    <Tooltip title={getName(featureChild.children, "source=")} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                                        {getName(featureChild.children, "source=")}
                                                                                    </Tooltip>
                                                                                </Col>
                                                                            </React.Fragment>
                                                                        }
                                                                        {
                                                                            featureChild?.children?.filter(x => x.type === 7)?.map((at) => {
                                                                                let label = null;
                                                                                let value = null;
                                                                                let unit = null;
                                                                                let attrFeature = at.children?.find(x => x.type === 5)?.children?.map(item => {
                                                                                    if (getName([item], "label=")) {
                                                                                        label = getName([item], "label=")
                                                                                    }
                                                                                    if (getName([item], "value=")) {
                                                                                        value = getName([item], "value=")
                                                                                    }
                                                                                })
                                                                                return (
                                                                                    <React.Fragment key={uuid()}>
                                                                                        <Col className="headingAttribute__item" span={8}>
                                                                                            <Tooltip title={label} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                                                {label}
                                                                                            </Tooltip>
                                                                                        </Col>
                                                                                        <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={8}>
                                                                                            <Tooltip title={value} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                                                {value}
                                                                                            </Tooltip>
                                                                                        </Col>
                                                                                        <Col className="headingAttribute__item" style={{ paddingLeft: 35 }} span={8}>
                                                                                            <Tooltip title={unit} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                                                {unit}
                                                                                            </Tooltip>
                                                                                        </Col>
                                                                                    </React.Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                </React.Fragment>
                                                            </Row>
                                                            )
                                                        })
                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </React.Fragment>
                        </Row>
                    </div>
                res.push(result)
                dataFeature.map(item => {
                    res.push(item)
                })
            })
        }
        return res
    }
    return (
        <div className='attributeTable'>
            {
                renderData(objectData)
            }
        </div>
    )
}
export default AttributeTableV2
