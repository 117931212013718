import React, {useState} from 'react'
import { Modal, Select, Switch } from 'antd'
import { Trans, useTranslation } from 'react-i18next';
const { Option } = Select

function MeshModal({
    setMeshValue, 
    setDracoValue, 
    setShowMeshModal, 
    setUploadStatus,
    showMeshModal,
    setValidFiles,
    setSelectedFiles,
}) {
    const { t } = useTranslation();
    const [meshModelValue, setMeshModelValue] = useState('3D_MODEL')
    const [meshDracoValue, setMeshDracoValue] = useState(false)

    const onChangeModel = (value) => {
        setMeshModelValue(value)
    }

    const onChangeDraco = (value) => {
        setMeshDracoValue(value)
    }

    const handleOkMeshModal = () => {
      setMeshValue(meshModelValue)
      setDracoValue(meshDracoValue)
      setShowMeshModal(false)
      setUploadStatus(true)
      setMeshDracoValue(false)
      setMeshModelValue('3D_MODEL')
    }
  
  const handleHiddenMeshModal = () => {
      setShowMeshModal(false)
      setUploadStatus(false)
      setMeshDracoValue(false)
      setMeshValue()
      setDracoValue() 
      setMeshModelValue('3D_MODEL')
      if(setValidFiles && setSelectedFiles) {
        setValidFiles()
        setSelectedFiles([])
      }
  }
    
    return (
        <Modal 
            title={false}
            visible={showMeshModal} 
            onOk={handleOkMeshModal}
            onCancel={handleHiddenMeshModal}
            okText={t('commons.ok')}
            cancelText={t('commons.cancel')}
            zIndex={10001}
        >
        <h2>{t('select-model-content-type')}</h2>
        <Select
            value={meshModelValue}
            style={{ width: 250, marginBottom: 20 }}
            onChange={onChangeModel}
        >
            <Option value="3D_MODEL">{t('generic-3d-model')}</Option>
            <Option value="3D_CAPTURE">{t('photogrammetry-model')}</Option>
        </Select>
        <br />
        <span>{t('use-draco-compression')}</span>
        <Switch style={{marginLeft: 15}} onChange={onChangeDraco} checked={meshDracoValue}  />
      </Modal>
    )
}

export default MeshModal
    