import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Popconfirm, Row, Slider, Switch } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SettingControlContainer } from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
const Cesium = require('cesium')


const UndergroundSetting = ({ projectStore, uiStore, viewer, projectSettingStore, usersStore }) => {
    const { t } = useTranslation();
    const [UnderParam, setUnderParam] = useState({})
    const [param, setParam] = useState(false)
    var scene = viewer.current.cesiumElement.scene;
    var globe = scene.globe
    let color = Cesium.Color.BLACK
    const [dataSetting, setDataSetting] = useState({})
    const { projectId } = useParams()



    function updateKey(data) {
        var x = Object.assign(UnderParam, data)
        setUnderParam(x)
    }

    useEffect(() => {

        let fetch = async () => {
            // await projectStore.getProjectDetail(projectId)
            if (Object.keys(projectStore.projectDetail).length > 0 && usersStore.currentUser.id) {
                let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
                let data = {}
                if (_systemProjectSetting.length > 0) {
                    let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)
                    if (!_currentUserSystemSetting) return
                    data = _currentUserSystemSetting.projectSetting
                    setDataSetting(_currentUserSystemSetting.projectSetting)

                } else {
                    data = projectSettingStore.systemProjectSetting
                    setDataSetting(projectSettingStore.systemProjectSetting)
                }

                if (scene) {
                    scene.screenSpaceCameraController.enableCollisionDetection = !data.underGroundSetting.enabled;
                }
                let _value = {
                    enabled: data?.underGroundSetting?.enabled,
                    nearDistance: data?.underGroundSetting?.nearDistance,
                    farDistance: data?.underGroundSetting?.farDistance,
                    nearAlpha: data?.underGroundSetting?.nearAlpha,
                    farAlpha: data?.underGroundSetting?.farAlpha,
                }
                setUnderParam(_value)
            }
        }

        fetch()

    }, [projectStore.projectDetail])

    useEffect(() => {
        if (viewer) {
            let viewModel = { ...UnderParam }
            globe.undergroundColor = viewModel.enabled ? color : undefined;
            if (scene) {
                scene.screenSpaceCameraController.enableCollisionDetection = !viewModel.enabled;
            }
            if (viewModel.nearDistance > viewModel.farDistance) {
                viewModel.nearDistance = viewModel.farDistance;
            }
            if (viewModel.enabled) {
                if (globe) {
                    globe.undergroundColorAlphaByDistance.near = viewModel.nearDistance;
                    globe.undergroundColorAlphaByDistance.far = viewModel.farDistance;
                    globe.undergroundColorAlphaByDistance.nearValue = viewModel.nearAlpha;
                    globe.undergroundColorAlphaByDistance.farValue = viewModel.farAlpha;
                }
            }
            projectSettingStore.setUndergroundSetting(UnderParam)
            projectStore.setUndergroundSetting(UnderParam)
        }
    }, [param, color])

    const confirm = () => {
        let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
        projectStore.setLoadingProgress(true)
        projectStore.updateProjectMetadata({ metadata }).then(res => {
            projectStore.setLoadingProgress(false)
            uiStore.setShowUndergroundSetting(false)
            projectStore.setCleanMode(false)
        })
            .catch(err => {
                projectStore.setLoadingProgress(false)
            })
    }

    const cancel = () => {
        // uiStore.setShowUndergroundSetting(false)
        // projectStore.setCleanMode(false)
    }

    return (
        <SettingControlContainer>
            <div style={{ padding: 20 }}>
                <p><strong>{t('underground-setting')}</strong></p>
                {t('near-distance')}:
                <br />
                <Row>
                    <Col span={12}>
                        <Slider min={0} max={1000000} defaultValue={2}
                            onChange={(v) => {
                                updateKey({ nearDistance: v })
                                setParam({ nearDistance: v })
                            }}
                            value={UnderParam.nearDistance}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber value={UnderParam.nearDistance} min={0} max={1000000} style={{ margin: '0 16px' }} onChange={(v) => {
                            updateKey({ nearDistance: v })
                            setParam({ nearDistance: v })
                        }}
                        />
                    </Col>
                </Row>
                <br />
                {t('far-distance')}:
                <br />
                <Row>
                    <Col span={12}>
                        <Slider min={100000} max={3000000} defaultValue={2}
                            onChange={(v) => {
                                updateKey({ farDistance: v })
                                setParam({ farDistance: v })
                            }}
                            value={UnderParam.farDistance}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber value={UnderParam.farDistance} min={100000} max={3000000} style={{ margin: '0 16px' }} onChange={(v) => {
                            updateKey({ farDistance: v })
                            setParam({ farDistance: v })
                        }}
                        />
                    </Col>
                </Row>
                <br />
                {t('near-alpha')}:
                <br />
                <Row>
                    <Col span={12}>
                        <Slider min={0} max={1}
                            onChange={(v) => {
                                updateKey({ nearAlpha: v })
                                setParam({ nearAlpha: v })
                            }}
                            value={UnderParam.nearAlpha}
                            step={0.01}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber step={0.01} value={UnderParam.nearAlpha} min={0} max={1} style={{ margin: '0 16px' }} onChange={(v) => {
                            updateKey({ nearAlpha: v })
                            setParam({ nearAlpha: v })
                        }}
                        />
                    </Col>
                </Row>
                <br />
                {t('far-alpha')}:
                <br />
                <Row>
                    <Col span={12}>
                        <Slider min={0} max={1}
                            onChange={(v) => {
                                updateKey({ farAlpha: v })
                                setParam({ farAlpha: v })
                            }}
                            value={UnderParam.farAlpha}
                            step={0.01}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber step={0.01} value={UnderParam.farAlpha} min={0} max={1} style={{ margin: '0 16px' }} onChange={(v) => {
                            updateKey({ farAlpha: v })
                            setParam({ farAlpha: v })
                        }}
                        />
                    </Col>
                </Row>
                <br />
                {t('enabled')}:
                <Switch
                    checkedChildren={t('commons.on')}
                    unCheckedChildren={t('commons.off')}
                    checked={UnderParam.enabled}
                    defaultChecked={UnderParam.enabled}
                    onChange={(v) => {
                        if (isNaN(v)) v = false
                        updateKey({ enabled: v })
                        setParam({ enabled: v })
                    }}
                    style={{ marginLeft: '5px' }}
                />
                <br />
                <br />
                <Popconfirm
                    title={t('exist-and-save-your-changes')}
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText={t('commons.yes')}
                    cancelText={t('commons.no')}
                >
                    <Button style={{ marginLeft: 5 }}
                        type="default"
                        icon={<CloseOutlined />}
                    >
                        {t('commons.cancel')}
                    </Button>
                </Popconfirm>

            </div>

        </SettingControlContainer>
    )
}
export default inject('projectStore', 'uiStore', 'projectSettingStore', 'usersStore')(observer(UndergroundSetting))
