import { Checkbox, Descriptions, Empty, Image, Radio } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react';
import './style.css';
import { Trans, useTranslation } from 'react-i18next';

function FeedbackReportTable({ dataTable, commonStore }) {
    const { t } = useTranslation();
    const width = (window.innerWidth > 0) ? window.innerWidth - 40 : 1710;
    const [tableWidth, setTableWith] = useState(width)
    moment.locale(`${commonStore.language}`)

    useEffect(() => {
        if (dataTable) {
            let c = 0
            dataTable.map(elm => {
                let result = renderColumnFeedbackForm(elm)
                if (result.length >= 0 && result.length >= c) {
                    c = result.length
                }
            })
            setTableWith(width + c * 50)
        }
    }, [dataTable])

    function renderColumnFeedbackForm(feedback) {
        let fbAnswers = feedback.feedbackAnswers
        let _fbForm = feedback?.feedbackform?.formControlData?.elements || []
        _fbForm = _fbForm.filter(c => c.className !== 'textReadonly' && c.className !== 'image')
        let elementDeleted = _fbForm.filter(c => c => c.className !== 'textReadonly' && c.className !== 'image' && c.isDelete)

        //func remove feedback form element not has value in all answer
        const removeElementNotAnswers = (result, object) => {
            fbAnswers.map(answer => {
                let arrAnswers = answer?.formControlData?.elements || []
                arrAnswers.map(c => {
                    let isExist = result.find(k => k.name === c.name)
                    if (c.name === object.name && !isExist) {
                        if (c.value) {
                            result.push(object)
                        }
                    }
                })
            })
            return result
        }
        let columnElements = _fbForm.reduce(removeElementNotAnswers, [])
        // func get old element deleted but  someone has answered to that
        const getElements = (result, object) => {
            let arrAnswers = object?.formControlData?.elements || []
            arrAnswers.map(c => {
                let isExistDeleted = elementDeleted.find(k => k.name === c.name)
                let isExistResult = result.find(k => k.name === c.name)
                if (!isExistResult && isExistDeleted && c.value) {
                    result.push(isExistDeleted)
                }
            })
            return result
        }
        return fbAnswers.reduce(getElements, columnElements)
    }

    const FeedbackFormData = ({ feedbackForm, feedbackAnswers }) => {
        let formData = { className: "empty" }
        let arrAnswers = feedbackAnswers?.formControlData?.elements || []
        let data = arrAnswers.find(c => c.name === feedbackForm.name)
        if (data) {
            formData = data
        }
        const elementSkeleton = () => {
            switch (formData.className) {
                case 'singleLine':
                    return <td>{formData.value ? formData.value : ''}</td>
                case 'multiLine':
                    return <td>{formData.value ? formData.value : ''}</td>
                case 'email':
                    return <td>{formData.value ? formData.value : ''}</td>
                case 'dropdown':
                    return <td>{formData.value ? formData.value : ''}</td>
                case 'empty':
                    return <td></td>
                case 'radioGroup':
                    return (
                        <td>
                            {
                                formData.value && (
                                    <Radio checked value={formData.value}>{formData.value}</Radio>
                                )
                            }
                        </td>
                    )
                case 'checkboxGroup':
                    return (
                        <td>
                            {
                                formData.value && (
                                    <Checkbox.Group
                                        defaultValue={formData.value}
                                        style={
                                            formData.direction === 'horizontal'
                                                ? null : {
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }
                                        }
                                        options={formData.value}
                                    />
                                )
                            }
                        </td>
                    )
                case 'smileyButton':
                    return (
                        <td
                        >
                            {formData.value ? <img src={formData.value} /> : ''}
                        </td>
                    )
                case 'urlReadonly':
                    return (
                        <td>
                            {
                                formData.value && (
                                    <Descriptions.Item >
                                        <a href={formData.value || '#'} rel="noopener noreferrer" target="_blank">{formData.text || formData.value}</a>
                                    </Descriptions.Item>
                                )
                            }
                        </td>
                    )
                default:
                    break
            }
        }

        return (<>{elementSkeleton()}</>)
    }
    return (

        <div className="feedBackReportTable" style={{ width: `${tableWidth}px` }}>
            {
                dataTable.length > 0 ? (
                    dataTable.map(feedback => {
                        let fbAnswers = feedback.feedbackAnswers
                        let _fbForm = renderColumnFeedbackForm(feedback)
                        {/* let _fbForm = feedback?.feedbackform?.formControlData?.elements
                        if (_fbForm) {
                            _fbForm = _fbForm.filter(c => c.className !== 'textReadonly' && c.className !== 'image' && !c.isDelete)
                        } */}
                        return (
                            <table key={`${feedback?.feedbackform ? feedback.feedbackform.id : 'feedbackreport'}`}>
                                <thead >
                                    <tr>
                                        <th className="topic" colSpan={_fbForm.length + 2}>{t(feedback?.feedbackform?.title)}</th>
                                    </tr>
                                    <tr>
                                        <th className="topic" colSpan="2">{t('feedback')}</th>
                                        <th className="topic" colSpan={_fbForm.length}>{t('element')}</th>
                                    </tr>
                                    <tr>
                                        <th className="topic-title">{t('user')}</th>
                                        <th className="topic-title">{t('time')}</th>
                                        {
                                            _fbForm && _fbForm.map(fbAnswer => {
                                                return <th key={fbAnswer.name} className="topic-title">{fbAnswer.title}</th>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fbAnswers.length > 0 ? (
                                            fbAnswers.map((answer, index) => {
                                                return (
                                                    <tr key={`${answer.name}-${index}`}>
                                                        <td className="feedback-item ">
                                                            {answer?.user?.username ? answer.user.username : ''}
                                                        </td>
                                                        <td className="feedback-item">
                                                            {moment(answer?.cloneData?.createdAt ? answer?.cloneData?.createdAt : answer?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                                        </td>
                                                        {
                                                            _fbForm && _fbForm.map(_form => {
                                                                return <FeedbackFormData key={`${_form.name}-answer`} feedbackForm={_form} feedbackAnswers={answer} formData={_form, answer} />
                                                            })

                                                        }
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr>
                                                <th className='no-data' colSpan={feedback.feedbackform.formControlData.elements?.length + 2}>{t('no-answer')}</th>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        )
                    })
                ) : <Empty description={false} />
            }
        </div>
    )
}

export default inject(
    'projectStore',
    'commonStore'
)(observer(FeedbackReportTable))
