import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
  WallGraphics,
  PolylineGraphics,
  PolygonGraphics,
  Entity,
  CorridorGraphics,
  PolylineVolumeGraphics,
  LabelGraphics,
} from 'resium'
import {
  Color,
  Cartographic,
  Cartesian2,
  Cartesian3,
  PolylineOutlineMaterialProperty,
  ClassificationType,
  HorizontalOrigin,
  VerticalOrigin,
  BoundingSphere,
  Math as CesiumMath,
  CornerType,
  DistanceDisplayCondition,
  PolylineVolumeGraphics as CesiumPolylineVolumeGraphics,
  CorridorGraphics as CesiumCorridorGraphics,
  PolylineGraphics as CesiumPolylineGraphics,
  WallGraphics as CesiumWallGraphics
} from 'cesium'

import IntersectionAPI from '../../../../assets/3dcitydb-web-map-1.9.0/ThirdParty/Intersection/IntersectionAPI';
import city3ddb from '../../../../assets/3dcitydb-web-map-1.9.0/js/3dcitydb-web-map';
import CitydbUtil from '../../../../assets/3dcitydb-web-map-1.9.0/js/CitydbUtil';
import CitydbWebworker from '../../../../assets/3dcitydb-web-map-1.9.0/js/CitydbWebworker';
import CitydbSceneTransforms from '../../../../assets/3dcitydb-web-map-1.9.0/js/CitydbSceneTransforms';
import CitydbKmlHighlightingManager from '../../../../assets/3dcitydb-web-map-1.9.0/js/CitydbKmlHighlightingManager';
import CitydbKmlTilingManager from '../../../../assets/3dcitydb-web-map-1.9.0/js/CitydbKmlTilingManager';
import CitydbKmlDataSource from '../../../../assets/3dcitydb-web-map-1.9.0/js/CitydbKmlDataSource';
import CitydbKmlLayer from '../../../../assets/3dcitydb-web-map-1.9.0/js/CitydbKmlLayer';
import Cesium3DTilesDataLayer from '../../../../assets/3dcitydb-web-map-1.9.0/js/Cesium3DTilesDataLayer';
import DataSourceController from '../../../../assets/3dcitydb-web-map-1.9.0/utils/mashup-data-source-service/application/DataSourceController';
import { toJS } from 'mobx';

const CityDB3DView = props => {
  const { viewer, projectStore, mode3DCityDBlList, imageryViewLink } = props

  function startReRender() {
    if (viewer.current && viewer.current.cesiumElement && viewer.current.cesiumElement._cesiumWidget) {
      if (viewer.current.cesiumElement?.scene?.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }
  // const getMinLod = (value) => {
  //   const scale = 12.5
  //   return scale * value
  // }
  //effect show hide 3dcitydb model from datatree

  const renderRequestedEvent = new Event('renderRequestedEvent');
  useEffect(() => {
    if (viewer.current.cesiumElement && projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0 && projectStore.city3DDB && projectStore.city3DDB._layers.length > 0 && !imageryViewLink) {
      var layers = projectStore.city3DDB._layers;
      const minLod = (projectStore.gmlmaximumScreenSpaceError || 100) * 4;
      const maxCountOfVisibleTiles = projectStore.maxCountOfVisibleTiles || 200
      layers.forEach(item => {
        let _model = projectStore.visibleTilesets.find(obj => obj.modelId === item.id && projectStore.deleteIds.indexOf(item.id) === -1)

        if (_model) {
          const isChangeLod = item.minLodPixels !== minLod || item.maxCountOfVisibleTiles !== maxCountOfVisibleTiles

          if (projectStore.zoomToModel === item.id) {
            item.zoomToStartPosition();
            projectStore.setZoomToModel(false)
          }

          if (_model.isVisible && (!item.active || isChangeLod)) {
            item.minLodPixels = minLod
            item.maxCountOfVisibleTiles = maxCountOfVisibleTiles
            if (isChangeLod) {
              if (!item._active) {
                item.activate(true)
              }
              item.triggerEvent("VIEWCHANGED");
              // Trigger the custom event to request a re-render
              item.triggerEvent(renderRequestedEvent);
              item.reActivate(viewer.current.cesiumElement);
            }
            else
              item.activate(true)
          } else if (!_model.isVisible && item.active) {
            item.activate(false)
          }
        }
        else {
          item.activate(false)
        }
        startReRender()
      })
    }
    if (viewer.current.cesiumElement?.scene?.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }, [projectStore.visibleTilesets, projectStore.maxCountOfVisibleTiles, projectStore.gmlmaximumScreenSpaceError, projectStore.deleteIds])


  // 3dcitydb project link
  useEffect(() => {
    if (viewer.current.cesiumElement && projectStore.city3DDB && projectStore.city3DDB._layers.length > 0 && imageryViewLink) {
      var layers = projectStore.city3DDB._layers;
      const minLod = (projectStore.gmlmaximumScreenSpaceError || 100) * 4;
      const maxCountOfVisibleTiles = projectStore.maxCountOfVisibleTiles || 200
      let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds()
      layers.forEach(item => {
        let _model = projectStore.listAllModel3DSLink.find(obj => obj.id === item.id && projectStore.deleteIds.indexOf(item.id) === -1)
        if (_model && _model?.isVisible4D) {
          const isChangeLod = item.minLodPixels !== minLod || item.maxCountOfVisibleTiles !== maxCountOfVisibleTiles

          if (projectStore.zoomToModel === item.id) {
            item.zoomToStartPosition();
            projectStore.setZoomToModel(false)
          }
          let isVisible = true;
          if (!visibleTileViewIds.includes(item.id)) {
            isVisible = false
          }
          if (projectStore.hiddenModelLinkIDs.includes(item.id)) {
            isVisible = false
          }
          if (isVisible && (!item.active || isChangeLod)) {
            item.minLodPixels = minLod
            item.maxCountOfVisibleTiles = maxCountOfVisibleTiles
            if (isChangeLod) {
              if (!item._active) {
                item.activate(true)
              }
              item.triggerEvent("VIEWCHANGED");
              // Trigger the custom event to request a re-render
              item.triggerEvent(renderRequestedEvent);
              item.reActivate(viewer.current.cesiumElement);
            }
            else
              item.activate(true)
          } else if (!isVisible && item.active) {
            item.activate(false)
          }
        }
        startReRender()
      })
    }
    if (viewer.current.cesiumElement?.scene?.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }, [projectStore.maxCountOfVisibleTiles, projectStore.gmlmaximumScreenSpaceError, projectStore.deleteIds, projectStore.hiddenModelLinkIDs])

  useEffect(() => {
    for (let i = 0; i < projectStore.hiddenCityGmlSelected.length; i++) {
      if (!projectStore.hiddenCityGmlSelected[i]["isDeleted"]) {

      }
    }
  }, [projectStore.hiddenTileSelected, projectStore.hiddenCityGmlSelected])

  useEffect(() => {
    if (viewer.current && viewer.current.cesiumElement && viewer.current.cesiumElement._cesiumWidget) {
      let cityGmlMap = new window.WebMap3DCityDB(viewer.current.cesiumElement)
      cityGmlMap.activateViewChangedEvent(true);
      cityGmlMap.activateMouseClickEvents(true);
      cityGmlMap.activateMouseMoveEvents(true);
      projectStore.setCity3DDB(cityGmlMap)
      startReRender()
    }
  }, [viewer])

  useEffect(() => {

    if (viewer && viewer.current && viewer.current.cesiumElement && mode3DCityDBlList.length && projectStore.projectDetail) {
      const citygmlMap = projectStore.city3DDB
      const hiddenObjects = projectStore.hiddenCityGmlSelected

      if (citygmlMap) {
        const existingLayer = []
        const minLod = (projectStore.gmlmaximumScreenSpaceError || projectStore.projectDetail?.metadata?.renderResolution?.gmlmaximumScreenSpaceError || 100) * 4
        const maxCountOfVisibleTiles = projectStore.projectDetail?.metadata?.renderResolution?.maxCountOfVisibleTiles || 200
        mode3DCityDBlList.map((model) => {
          const index = citygmlMap._layers.findIndex(x => x.id === model.id)
          const hiddenObject = hiddenObjects.find(x => x.modelid === model.id)
          if (index < 0) {
            let _3DcityDB = new window.CitydbKmlLayer({
              id: model.id,
              name: model.name,
              url: model.src,
              minLodPixels: minLod,
              maxCountOfVisibleTiles: maxCountOfVisibleTiles
            });
            _3DcityDB.minLodPixels = minLod;
            _3DcityDB.maxCountOfVisibleTiles = maxCountOfVisibleTiles;
            if (!_3DcityDB._active) {
              _3DcityDB.activate(true)
            }
            citygmlMap.addLayer(_3DcityDB);
            existingLayer.push(model.id)
            if (hiddenObject) {
              _3DcityDB.hideObject(hiddenObject.url)
            }
            startReRender()
          } else {
            existingLayer.push(citygmlMap._layers[index].id)
            if (hiddenObject) {
              citygmlMap._layers[index].hideObject(hiddenObject.url)
            }
          }
        })
        let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds();
        for (let i = 0; i < citygmlMap._layers.length; i++) {
          const l = citygmlMap._layers[i];
          if (imageryViewLink) {
            if (!(existingLayer.indexOf(l.id) > -1)) {
              l.activate(false)
            }
            if (!visibleTileViewIds.includes(l.id)) {
              l.activate(false)
            }
          } else {
            const dataTreeItem = projectStore.visibleTilesets.find(x => x.modelId === l.id)
            let isVisible = dataTreeItem
            if (dataTreeItem) {
              isVisible = dataTreeItem.isVisible
            }
            if (!(existingLayer.indexOf(l.id) > -1)) {
              l.activate(false)
            }
            if (!isVisible) {
              l.activate(false)
            }
          }
          if (projectStore.deleteIds.indexOf(l.id) > -1) {
            l.activate(false)
          }
          l.triggerEvent("VIEWCHANGED");
        }
      }
    }
  }, [mode3DCityDBlList, projectStore.projectDetail, projectStore.maxCountOfVisibleTiles, projectStore.gmlmaximumScreenSpaceError])

  return <></>
}

export default inject('projectStore')(observer(CityDB3DView))
