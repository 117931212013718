import styled from 'styled-components'

export const TodosPageWrapper = styled.div`
  margin-top: 55px;
  display: block;
  padding: 15px;
  background-color: #fff;
`
export const TodosPageHeading = styled.h1`
  font-size: 24px;
  color: #ff8d4f;
  text-align: center;
  margin-bottom: 40px;
`
export const FormStyled = styled.div`
  max-width: 640px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  .ant-form-item {
    margin-bottom: 10px;
  }
  .selectdrp{
    max-width:300px
  }
`
export const ProjectCoverWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .img {
    img {
      height: 150px;
    }
  }
  .uploader {
    margin-left: 15px;
    .ant-form-item-control {
      line-height: 1;
    }
  }
`

export const NavigationStyled = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  -webkit-transition: all .2s;
  transition: all .2s;
  border-radius: 5px;
  .code-box-title {
    position: absolute;
    top: -14px;
    margin-left: 16px;
    padding: 1px 8px;
    color: #777;
    background: #fff;
    border-radius: 2px 2px 0 0;
    -webkit-transition: background-color .4s;
    transition: background-color .4s;
  }
  .code-box-description{
    padding: 18px 24px 12px;
  }
`
export const ButtonGroup = styled.div`
  display: inline-table;
  align-items: center;
  justify-content: space-between;
  width: 100%
`
export const HeaderProfileWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 30px;
    }
`
