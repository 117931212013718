import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Form, Button, Input, Switch, Select } from 'antd'
import validator from '../../../../../../validator'
import { OrganizationEditWrapper } from './style'
import FormButtonGroup from '../../../../../organisms/FormButtonGroup'
import { toJS } from 'mobx'
import { Trans, useTranslation } from 'react-i18next';
const { TextArea } = Input
const { Option } = Select;

const ProjectForm = ({ projectData, onSubmit, projectStore, projectId, organizationId, organizationStore }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const OrganizationForm = props => {
    const [projectionList, setProjectionList] = useState([])
    let timeout;
    let currentValue;
    const fetchSearchProjection = (value, callback) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      currentValue = value;

      const searchProjection = () => {
        projectStore.getSearchProjection(value)
          .then(() => {
            if (currentValue === value) {
              const { results } = projectStore.projectionList;
              const data = [];
              if (typeof results !== 'undefined') {
                results.forEach(r => {
                  data.push(r);
                });
              }
              callback(data);
            }
          });
      }
      timeout = setTimeout(searchProjection, 300);
    }

    const handleSearch = value => {
      if (value) {
        fetchSearchProjection(value, data => {
          setProjectionList(data)
        });
      } else {
        setProjectionList([]);
      }
    }

    const handleChange = (value) => {
      const pr = projectionList.filter(d => d.code === value)
      projectStore.setProjectionDetail(pr)
    }

    const handleSubmit = values => {
      const submitValues = {
        name: values.name,
        desc: values.desc,
        elevationSystem: values.elevationSystem,
      }
      if (projectStore.projectionDetail.length > 0) {
        submitValues.coordinateSystem = values.coordinateSystem

        let _tilesetData = projectData.tilesetData ? projectData.tilesetData : {}
        _tilesetData.coordinateSystem = {
          code: projectStore.projectionDetail[0].code,
          name: projectStore.projectionDetail[0].name,
          proj4: projectStore.projectionDetail[0].proj4,
          wkt: projectStore.projectionDetail[0].wkt,
          unit: projectStore.projectionDetail[0].unit
        }
        submitValues.tilesetData = _tilesetData
      }
      submitValues.organization = organizationId
      onSubmit(submitValues)
    }

    useEffect(() => {
      if (organizationStore.showOrganization) {
        form.resetFields()
        fetchSearchProjection('4326', data => {
          setProjectionList(data)
          const pr = data.filter(d => d.code === '4326');
          projectStore.setProjectionDetail(pr)
        });
      }
    }, [organizationStore.showOrganization])


    useEffect(() => {
      if (projectData.tilesetData && projectData.tilesetData.coordinateSystem) {
        fetchSearchProjection(projectData.tilesetData.coordinateSystem.code, data => {
          setProjectionList(data)
          handleChange(projectData.tilesetData.coordinateSystem.code)
        });
      }
      return () => {
        setProjectionList([]);
      }
    }, [projectData])

    const handleCancel = () => {
      organizationStore.setModalCreateProject(false)
    }


    const options = projectionList.map(d => <Option key={d.code}>{'EPSG:' + d.name}</Option>);
    return (
      <Form name="add-project" layout="vertical" onFinish={handleSubmit}>
        <Form.Item label={t('title')} name="name" rules={[
          {
            required: true,
            message: t('please-input-the-title-of-project'),
          },
          { validator: validator.validateEmptyString },
        ]} initialValue={projectData.name}>
          <Input
            placeholder={t('input-project-title')} />
        </Form.Item>
        <Form.Item
          label={t('coordinate-system')}
          name="coordinateSystem"
          rules={[
            {
              required: true,
              message: t('please-choose-coordinate-system'),
            },
            { validator: validator.validateEmptyString },
          ]}
          initialValue={(projectData.tilesetData && projectData.tilesetData.coordinateSystem) ? projectData.tilesetData.coordinateSystem.code : '4326'}
        >
          <Select
            showSearch
            placeholder={t('enter-country-code-or-name-coordinate-system')}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={val => handleSearch(val)}
            onChange={val => handleChange(val)}
            notFoundContent={null}
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('elevation-system')}
          name="elevationSystem"
          initialValue={projectData.elevationSystem ? projectData.elevationSystem : "None"} 
          rules={[
            {
              required: false,
              message: t('please-choose-elevation-system'),
            },
            { validator: validator.validateEmptyString },
          ]}>
          <Select className='selectdrp'>
            <Option value="None">None</Option>
            <Option value="N2000">N2000</Option>
            <Option value="N60">N60</Option>
            <Option value="EGM96">EGM96</Option>
            <Option value="EGM2008">EGM2008</Option>
            <Option value="NN2000">NN2000</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('description')}
          name="desc"
          rules={[
            { validator: validator.validateEmptyString },
          ]}
          initialValue={projectData.desc}
        >
          <TextArea
            rows={5}
            placeholder={t('input-description')}
            type="textarea" />
        </Form.Item>
        <FormButtonGroup>
          <Button type={'default'} onClick={handleCancel}>
            {t('commons.cancel')}
          </Button>
          <Button type={'primary'} htmlType={'submit'} >
            {projectId !== 'new' ? t('commons.save') : t('commons.create')}
          </Button>
        </FormButtonGroup>
      </Form>
    )
  }

  return (
    <OrganizationEditWrapper>
      <OrganizationForm />
    </OrganizationEditWrapper>
  )
}

export default withRouter(
  inject(
    'projectStore',
    'organizationStore'
  )(observer(ProjectForm)))