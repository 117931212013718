/* eslint-disable no-loop-func */
import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Cartesian3,
  Cartesian2,
  CallbackProperty,
  Color,
  Transforms,
  ClippingPlaneCollection,
  ClippingPlane,
  ScreenSpaceEventType,
  ScreenSpaceEventHandler,
  Matrix4,
  SceneMode,
  EllipsoidGeodesic,
  Ellipsoid,
  defined,
  GridMaterialProperty,
  ClassificationType,
  Math as CesiumMath,
  Plane, Cartographic
} from 'cesium'
import { clickPoint, calculateBearing } from '../helper/CesiumUtils'

var ellipsoid = Ellipsoid.WGS84
const ClippingPlaneVertical = ({ projectStore, commonStore, viewer }) => {
  var activeShapePoints = []
  var step = 0
  var targetY = 0.0
  var planeEntities = []
  var selectedPlane
  var clickHandle, downHandler, upHandler, moveHandler, handlerMoveLine
  var clippingHeading = 0
  var customLine = new CustomLine();

  function clearAll() {
    if (viewer.current && viewer.current.cesiumElement && viewer.current.cesiumElement.dataSources) {
      resetClip()
    } else {
      projectStore.setClippingViewPoint(false)
      projectStore.setClippingMode(false)
    }
    customLine.removeAll();
    if (viewer.current && viewer.current.cesiumElement && viewer.current.cesiumElement.scene.requestRenderMode) {
      viewer.current.cesiumElement.scene.requestRender();
    }
  }

  function resetClip() {
    // remove entity clipping
    for (let i = 0; i < planeEntities.length; i++) {
      var _entities = viewer.current.cesiumElement.entities.getById(planeEntities[i].id)
      if (_entities) {
        viewer.current.cesiumElement.entities.remove(_entities);
      }
    }

    //remove tileset clipping
    for (let i = 0; i < viewer.current.cesiumElement.scene.primitives.length; i++) {
      var _tileset1 = viewer.current.cesiumElement.scene.primitives.get(i)
      if (_tileset1.isCesium3DTileset && _tileset1.clippingPlanes && _tileset1.clippingPlanes._planes.length > 0) {
        _tileset1.clippingPlanes.removeAll();
      }
    }

    if (viewer.current.cesiumElement.scene.globe.clippingPlanes) {
      viewer.current.cesiumElement.scene.globe.clippingPlanes.enabled = !1
      viewer.current.cesiumElement.scene.globe.clippingPlanes.removeAll()
    }

    planeEntities = [];
    targetY = 0.0;
    activeShapePoints = []
    step = 0
  }

  function createClipPlane(position, normalizeVector, dimensions, distance) {
    step = 0
    if (handlerMoveLine) handlerMoveLine.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
    if (clickHandle) clickHandle.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
    // Update plane on mouse click
    clickHandle = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas)
    clickHandle.setInputAction(function (movement) {
      if (defined(selectedPlane)) {
        viewer.current.cesiumElement.scene.screenSpaceCameraController.enableInputs = true;
        selectedPlane = undefined
        return
      }
      var prevPickObj = commonStore.pickedObject
      var pickedObject = viewer.current.cesiumElement.scene.pick(movement.position)
      if (prevPickObj && prevPickObj.position && prevPickObj.position.x === movement.position.x && prevPickObj.position.y === movement.position.y) {
        commonStore.setpickedObject(pickedObject, movement.position)
      }
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableInputs = true
    }, ScreenSpaceEventType.LEFT_CLICK)

    downHandler = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas)
    downHandler.setInputAction(function (movement) {
      if (movement.position) {
        var position = viewer.current.cesiumElement.scene.camera.pickEllipsoid(movement.position, viewer.current.cesiumElement.scene.globe.ellipsoid);
        var pickedObject = viewer.current.cesiumElement.scene.pick(movement.position)
        if (projectStore.clippingPickDone) {
          commonStore.setpickedObject(undefined, movement.position)
        }
        if (
          defined(pickedObject) &&
          defined(pickedObject.id) &&
          defined(pickedObject.id.plane)
        ) {
          selectedPlane = pickedObject.id.plane
          selectedPlane.name = pickedObject.id.name
          selectedPlane.material = Color.WHITE.withAlpha(0.05)
          selectedPlane.outlineColor = Color.WHITE
          selectedPlane.startPosition = movement.position;
          selectedPlane.startPosition1 = CesiumMath.toDegrees((ellipsoid.cartesianToCartographic(position).longitude))
          viewer.current.cesiumElement.scene.screenSpaceCameraController.enableInputs = false
        }

      }
    }, ScreenSpaceEventType.LEFT_DOWN)

    // Release plane on mouse up
    upHandler = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas)
    upHandler.setInputAction(function (movement) {
      if (defined(selectedPlane)) {
        selectedPlane.material = Color.WHITE.withAlpha(0.1)
        selectedPlane.outlineColor = Color.WHITE
        selectedPlane = undefined
      }
      viewer.current.cesiumElement.scene.screenSpaceCameraController.enableInputs = true
    }, ScreenSpaceEventType.LEFT_UP)

    // Update plane on mouse move
    moveHandler = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas)
    moveHandler.setInputAction(function (movement) {
      if (defined(selectedPlane)) {
        var endPoint = viewer.current.cesiumElement.camera.pickEllipsoid(movement.endPosition, viewer.current.cesiumElement.scene.globe.ellipsoid);
        var startPoint = viewer.current.cesiumElement.camera.pickEllipsoid(movement.startPosition, viewer.current.cesiumElement.scene.globe.ellipsoid);
        var deltaY = 0
        if ((clippingHeading >= 0 && clippingHeading < 90) || (clippingHeading >= -90 && clippingHeading < 0)) {
          deltaY = endPoint.y - startPoint.y
        }

        else if ((clippingHeading >= 90 && clippingHeading < 180) || (clippingHeading >= -180 && clippingHeading < -90)) {
          deltaY = endPoint.x - startPoint.x
        }

        else if ((clippingHeading >= 180 && clippingHeading < 270) || (clippingHeading >= -270 && clippingHeading < -180)) {
          deltaY = startPoint.y - endPoint.y
        }

        else if ((clippingHeading >= 270 && clippingHeading <= 360) || (clippingHeading >= -360 && clippingHeading < -270)) {
          deltaY = startPoint.x - endPoint.x
        }
        if (deltaY < -20)
          deltaY = -20
        else if (deltaY > 20)
          deltaY = 20

        return targetY += (deltaY * 10) / 100;
      }
    }, ScreenSpaceEventType.MOUSE_MOVE)

    createClipPlaneForTiles(position, normalizeVector, distance)
    if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }

  function createPlaneUpdateFunction(plane) {
    return function () {
      plane.distance = targetY
      projectStore.setClippingViewPoint({ ...projectStore.clippingViewPoint, distance: targetY })
      return plane
    }
  }

  async function createClipPlaneForTiles(position, normalizeVector, distance) {
    if (distance) {
      targetY = distance
    }
    var primitives = viewer.current.cesiumElement.scene.primitives._primitives.filter(function (value, index, arr) {
      return value.isCesium3DTileset;
    });
    let dataClip = primitives.filter(item => {
      let check = false;
      for (let i = 0; i < projectStore.visibleTilesets.length; i++) {
        let data = projectStore.visibleTilesets[i]
        if (item.id === data.modelId && data.isVisibleClip) {
          check = true
        }
      }
      for (let i = 0; i < projectStore.listProjectLink.length; i++) {
        let projectLink = projectStore.listProjectLink[i]
        projectStore.listAllModel3DSLink.map(model => {
          if (model.link?.id === projectLink.link?.id) {
            if (projectLink.isVisibleClip) {
              if (item.id === model.id) {
                check = true
              }
            }
          }
        })
      }
      return check
    })

    for (let [i, _tileset] of dataClip.entries()) {
      if (_tileset?.isCesium3DTileset) {
        // eslint-disable-next-line no-loop-func
        const _3dtileset = _tileset
        try {
          if (_3dtileset.clippingPlanes) {
            if (_3dtileset.clippingPlanes._planes.length === 0) _3dtileset.clippingPlanes.add(ClippingPlane.fromPlane(new ClippingPlane(new Cartesian3(normalizeVector.y, -normalizeVector.x, 0.0), 0)))
            _3dtileset.clippingPlanes.modelMatrix = Matrix4.inverse(_3dtileset._initialClippingPlanesOriginMatrix, new Matrix4())
          } else {
            _3dtileset.clippingPlanes = new ClippingPlaneCollection({
              planes: [new ClippingPlane(new Cartesian3(normalizeVector.y, -normalizeVector.x, 0.0), 0)],
              edgeWidth: 3.0,
              edgeColor: Color.BLACK,
              unionClippingRegions: true,
              modelMatrix: Matrix4.inverse(_3dtileset._initialClippingPlanesOriginMatrix, new Matrix4())
            })
          }

          var globalMatrix = Transforms.eastNorthUpToFixedFrame(position, Ellipsoid.WGS84, new Matrix4());
          var toLocalMatrix = Matrix4.inverse(_3dtileset.clippingPlanesOriginMatrix, new Matrix4());
          _3dtileset.clippingPlanes.modelMatrix = Matrix4.multiply(toLocalMatrix, globalMatrix, new Matrix4());

          for (let j = 0; j < _3dtileset.clippingPlanes.length; ++j) {
            var plane = _3dtileset.clippingPlanes.get(j)
            var planeEntity = viewer.current.cesiumElement.entities.add({
              position: position,
              name: `clip-${_3dtileset.id}`,
              plane: {
                dimensions: new CallbackProperty(() => {
                  let dimension = 5;
                  if (viewer.current?.cesiumElement?.camera?.position) {
                    let campos = viewer.current.cesiumElement.camera.position;
                    dimension = (Cartesian3.distance(campos, position) / 100) * 5
                  }
                  return new Cartesian2(dimension, dimension)
                }, false),
                material: ((i + 1) === (dataClip.length) ? Color.WHITE.withAlpha(0.1) : Color.WHITE.withAlpha(0)),
                plane: new CallbackProperty(createPlaneUpdateFunction(plane), false),
                outline: true,
                outlineColor: Color.WHITE
              }
            })
            planeEntities.push(planeEntity)
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  //Function Create line mouse move
  function CustomLine() {
    this.positions = [];
    this.markers = [];
    var that = this;

    this.line = viewer.current.cesiumElement.entities.add({
      polyline: {
        positions: new CallbackProperty(function () {
          return that.positions;
        }, false),
        width: 2,
        material: Color.WHITE,
        depthFailMaterial: new GridMaterialProperty({
          color: Color.RED
        }),
        destroyPrimitives: false,
        classificationType: ClassificationType.BOTH
      }
    });
  }

  //Function add point click
  CustomLine.prototype.addPoint = function (position) {
    var that = this;
    var n = this.positions.length;
    this.positions[n] = position;

    var marker = viewer.current.cesiumElement.entities.add({
      position: new CallbackProperty(function () {
        return that.positions[n];
      }, false),
      point: {
        pixelSize: 5,
        color: Color.RED,
        outlineColor: Color.WHITE,
        outlineWidth: 2,
      },
    })
    this.markers.push(marker);
  };

  //function remove point and line
  CustomLine.prototype.removeAll = function () {
    if (this.markers && this.markers.length > 0) {
      for (var i = 0; i < this.markers.length; i++) {
        if (viewer && viewer.current && viewer.current.cesiumElement)
          viewer.current.cesiumElement.entities.remove(this.markers[i]);
      }
      this.markers = [];
      this.positions = [];
    }
  };

  function getdirection(pointA, pointB) {
    const transform = Transforms.eastNorthUpToFixedFrame(pointA)
    const positionvector = Cartesian3.subtract(pointB, pointA, new Cartesian3())
    const vector = Matrix4.multiplyByPointAsVector(
      Matrix4.inverse(transform, new Matrix4()),
      positionvector,
      new Cartesian3()
    )
    const planeNormalDirection1 = Cartesian3.normalize(vector, new Cartesian3());
    const plane = new Plane.fromPointNormal(Cartesian3.ZERO, planeNormalDirection1);
    return plane
  }

  function startPickPoint() {
    clickHandle = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas)
    clickHandle.setInputAction(function (click) {
      if (!viewer.current.cesiumElement.scene) {
        return
      }

      if (viewer.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
        try {
          let resultClick = clickPoint(viewer.current.cesiumElement, click.position)
          var mousePosition = viewer.current.cesiumElement.scene.pickPosition(click.position)
          if (defined(mousePosition)) {
            if (step === 0) {
              customLine.addPoint(resultClick.position);
              activeShapePoints.push(mousePosition)
              step = 1
            } else if (step === 1) {
              customLine.removeAll();
              activeShapePoints.push(mousePosition)
              step = 2
              var point1 = activeShapePoints[0]
              var point2 = activeShapePoints[1]

              var ellipsoidGeodesic = new EllipsoidGeodesic(ellipsoid.cartesianToCartographic(point1), ellipsoid.cartesianToCartographic(point2));
              var distancetwopoint = ellipsoidGeodesic.surfaceDistance;

              if (distancetwopoint === 0) {
                activeShapePoints.splice(1, 1);
                step = 1
                return false
              }

              // check if distancetwopoint > 1(m) then get distancetwopoint = 1 from center two point
              if (distancetwopoint > 1) {
                distancetwopoint = 1
              }

              projectStore.setClippingPickDone(true);
              var planeNormalDirection = getdirection(point2, point1) //get direction
              var _position = Cartesian3.midpoint(point1, point2, new Cartesian3())
              clippingHeading = calculateBearing(point1, point2)
              let dimensions = ellipsoidGeodesic.surfaceDistance
              projectStore.setClippingViewPoint({ position: _position, direction: planeNormalDirection.normal, dimension: dimensions, distance: 0 }) // for save clipping to viewpoint
              createClipPlane(_position, planeNormalDirection.normal, dimensions)
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
    }, ScreenSpaceEventType.LEFT_CLICK)

    //event mouse move for draw line
    handlerMoveLine = new ScreenSpaceEventHandler(viewer.current.cesiumElement.canvas);
    handlerMoveLine.setInputAction(function (event) {
      let resultClick = clickPoint(viewer.current.cesiumElement, event.endPosition)
      if (resultClick && resultClick.position) {
        const cartographic = Cartographic.fromCartesian(
          resultClick.position
        );
        // if (cartographic.height >= -0.1) {
          var n = customLine.positions.length;
          if (n === 0) {
            customLine.addPoint(resultClick.position);
          } else {
            customLine.positions[n - 1] = resultClick.position;
          }
        // }
      }
    }, ScreenSpaceEventType.MOUSE_MOVE);
  }

  useEffect(() => {
    clearAll()
    if (projectStore.clippingMode === 'vertical') {
      if (projectStore.clippingViewPoint && projectStore.clippingViewPoint.position) {
        projectStore.setClippingPickDone(true);
        createClipPlane(projectStore.clippingViewPoint.position, projectStore.clippingViewPoint.direction, projectStore.clippingViewPoint.dimension, projectStore.clippingViewPoint.distance)
      } else {
        projectStore.setClippingPickDone(false);
        startPickPoint()
      }
    }

    return () => {
      if (clickHandle)
        clickHandle.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
      if (downHandler)
        downHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN)
      if (upHandler)
        upHandler.removeInputAction(ScreenSpaceEventType.LEFT_UP)
      if (moveHandler)
        moveHandler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
      if (handlerMoveLine) {
        customLine.removeAll();
        handlerMoveLine.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
        handlerMoveLine.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
      }
      projectStore.setClippingPickDone(false);
      clearAll()
    }
  }, [projectStore.visibleTilesets, projectStore.listProjectLink])

  return <></>
  // Mouse over the globe to see the cartographic position
}
export default inject('projectStore', 'commonStore')(observer(ClippingPlaneVertical))
