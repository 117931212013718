import { SaveOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Layout, message, Modal, Row, Spin, Switch } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import LoadingSpinner from '../../../elements/LoadingSpinner/LoadingSpinner';
import AdminTemplate from '../../../layout/AdminTemplate';
import { ContentAdmin, CustomContentAdminWrapper, HeaderAdmin } from '../style';
import '../style.css';
import validator from './../../../../validator';
import { DateWrapper } from './style';
import { Trans, useTranslation } from 'react-i18next';
const { Content } = Layout;


const Notification = ({ commonStore, adminStore, projectStore }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false);
    const [reload, setReload] = useState(false);
    const [dateTime, setDateTime] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [isModalLoadingOver, setIsModalLoadingOver] = useState(false);
    const [sendAll, setSendAll] = useState(false);
    const [maintenanceInfo, setMaintenanceInfo] = useState({});
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    moment.locale(`${commonStore.language}`)
    const isMobile = useMediaQuery({query: '(max-width: 600px)'})

    useEffect(() => {
        const populateOrganizationFormData = async () => {
            adminStore.clearStatus()
            setReload(false)
            setisLoadingFormEdit(true)
            commonStore.setCurrentPage("admin/notification")
            projectStore.getMaintenanceInfo().then(res => {
                if (res.statusCode === 200) {
                    setMaintenanceInfo(res.data)
                    let _startDate = new Date(parseInt(res.data.startDate))
                    let _endDate = new Date(parseInt(res.data.endDate))
                    form.setFieldsValue({
                        startDate: moment(_startDate, dateFormat),
                        endDate: moment(_endDate, dateFormat),
                        description: res.data.description,
                        active: res.data.active
                    })
                    let x = Object.assign(dateTime, { startDate: res.data.startDate, endDate: res.data.endDate })
                    setDateTime(x)
                }
            })
            setisLoadingFormEdit(false)
        }
        populateOrganizationFormData()
        return () => {
            adminStore.setCurrentUser({})
            adminStore.clearStatus()
        }
    }, [reload])
  const showModal = () => {
    setIsModalVisible(true)
    setIsModalLoading(false)
    setIsModalLoadingOver(false)
    setSendAll(false)
    form.resetFields(['email'])
  }

  const sendEmailNotification = param => {
    setIsModalLoading(true)
    adminStore
      .sendEmailNotification(param)
      .then(resp => {
        if (resp.statusCode === 400) {
          message.error(t(resp.message || 'an-error-occurred'))
          setIsModalLoading(false)
        } else {
          message.success(t('send-notification-successfully'))
          setIsModalVisible(false)
        }
      })
      .catch(err => {
        setIsModalLoading(false)
        console.log(err)
      })
  }

  const handleOk = () => {
    let _param
    if (sendAll) {
      let values = form.getFieldsValue(true)
      _param = {
        email: values.email,
        allUser: sendAll,
        description: values.description,
      }
      return sendEmailNotification(_param)
    } else {
      form.validateFields().then(res => {
        _param = {
          email: res.email,
          allUser: sendAll,
          description: res.description,
        }
        return sendEmailNotification(_param)
      })
    }
  }
  const sendEmailNotificationOver = param => {
    setIsModalLoadingOver(true)
    adminStore
      .sendEmailNotificationOver(param)
      .then(resp => {
        if (resp.statusCode === 400) {
          message.error(t(resp.message || 'an-error-occurred'))
          setIsModalLoadingOver(false)
        } else {
          message.success(t('send-notification-successfully'))
          setIsModalVisible(false)
        }
      })
      .catch(err => {
        setIsModalLoadingOver(false)
        console.log(err)
      })
  }

  const handleMaintenanceOver = () => {
    let _param
    if (sendAll) {
      let values = form.getFieldsValue(true)
      _param = {
        email: values.email,
        allUser: sendAll,
        description: values.description,
      }
      return sendEmailNotificationOver(_param)
    } else {
      form.validateFields().then(res => {
        _param = {
          email: res.email,
          allUser: sendAll,
          description: res.description,
        }
        return sendEmailNotificationOver(_param)
      })
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsModalLoading(false)
    setIsModalLoadingOver(false)
  }

  function setStartDate(date, dateString) {
    let x = Object.assign(dateTime, {
      startDate: new Date(dateString).getTime(),
    })
    setDateTime(x)
  }
  function setEndDate(date, dateString) {
    let x = Object.assign(dateTime, { endDate: new Date(dateString).getTime() })
    setDateTime(x)
  }

  const updateNotification = payload => {
    let data = { ...maintenanceInfo, ...payload, ...dateTime }
    delete data.email
    delete data.allUser
    projectStore.setLoadingProgress(true)
    projectStore
      .updateMaintenanceInfo(data)
      .then(res => {
        message.success(t('update-successfully'))
        setMaintenanceInfo(data)
      })
      .catch(err => console.log(err))
      .finally(() => {
        projectStore.setLoadingProgress(false)
      })
  }

  const customSpin = (
    <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
  )
  return (
    <AdminTemplate title={t('notification')}>
      <Spin spinning={isLoadingFormEdit} indicator={customSpin}>
        <CustomContentAdminWrapper className="edit-user">
          <div style={{  background: '#fff', textAlign: 'center' }}>
            <Layout>
              <Form
                form={form}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={value => updateNotification(value)}>
                <HeaderAdmin style={{backgroundColor: '#f0f2f5'}}>
                  <Content>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style={{ padding: '10px ' }}>
                      <Col xs={24} sm={12}>
                        <div style={{ float: 'left', fontSize: 20 }}>
                          {t('edit-notification')}
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Col span={24} className="control-btn-group">
                          <Button
                            style={{
                              float: isMobile?'left':'right',
                              marginRight: 10
                            }}
                            htmlType="submit"
                            type="primary">
                            <SaveOutlined />
                            {!isMobile && t('commons.save')}
                          </Button>
                          <Button
                            style={{
                              float: isMobile?'left':'right',
                              marginRight: 10,
                            }}
                            type="danger"
                            onClick={showModal}>
                            <SendOutlined />
                            {!isMobile && t('send')}
                          </Button>
                          <Modal
                            zIndex={9999}
                            title={t('send-email-notification')}
                            visible={isModalVisible}
                            className="modal-send-email-notification"
                            onOk={handleOk}
                            onCancel={handleCancel}
                            maskClosable={false}
                            footer={
                              <div style={{ textAlign: 'right' }}>
                                <Button key="back" onClick={handleCancel}>
                                  {t('commons.cancel')}
                                </Button>
                                <Button
                                  key="up"
                                  type="primary"
                                  loading={isModalLoading}
                                  onClick={handleOk}>
                                  <SendOutlined />
                                  {t('send')}
                                </Button>
                                {/* <Button
                                                                      key="over"
                                                                      type="primary"
                                                                      loading={isModalLoadingOver}
                                                                      onClick={handleMaintenanceOver}>
                                                                      Maintenance Over
                                                                  </Button> */}
                              </div>
                            }>
                            <Col span={24}>
                              <Form.Item
                                label={t('user')}
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-email-user'),
                                  },
                                  {
                                    validator: sendAll
                                      ? false
                                      : validator.validateEmail,
                                  },
                                ]}>
                                <Input type="text" />
                              </Form.Item>
                              <Form.Item
                                name="allUser"
                                rules={[
                                  {
                                    required: false,
                                    message: t('please-input-email-user'),
                                  },
                                ]}>
                                <Checkbox
                                  onChange={e => setSendAll(e.target.checked)}
                                  checked={sendAll}>
                                  {t('send-all-user')}
                                </Checkbox>
                              </Form.Item>
                            </Col>
                          </Modal>
                        </Col>
                      </Col>
                    </Row>
                  </Content>
                </HeaderAdmin>
                <Content>
                  <div style={{ padding: '20px 0', background: '#fff' }}>
                    <Row type="flex">
                      <Col span={24}>
                        <div
                          className="content-left"
                          style={{
                            background: '#fff',
                            padding: '20px 0',
                          }}>
                          <Row gutter={[24, 8]}>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <DateWrapper>
                                <Form.Item
                                  label={t('start-date')}
                                  name="startDate"
                                  rules={[
                                    {
                                      required: false,
                                      message: t('please-select-start-date'),
                                    },
                                  ]}>
                                  <DatePicker
                                    showTime
                                    format={dateFormat}
                                    onChange={setStartDate}
                                  />
                                </Form.Item>
                              </DateWrapper>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <DateWrapper>
                                <Form.Item
                                  label={t('end-date')}
                                  name="endDate"
                                  rules={[
                                    {
                                      required: false,
                                      message: t('please-select-end-date'),
                                    },
                                  ]}>
                                  <DatePicker
                                    showTime
                                    format={dateFormat}
                                    onChange={setEndDate}
                                  />
                                </Form.Item>
                              </DateWrapper>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('description')}
                                name="description"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-description'),
                                  },
                                ]}>
                                <Input type="text" />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('active')}
                                name="active"
                                valuePropName="checked"
                                className="checkbox-status">
                                <Switch />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Content>
              </Form>
            </Layout>
          </div>
        </CustomContentAdminWrapper>
      </Spin>
    </AdminTemplate>
  )
}

export default inject(
  'projectStore',
  'commonStore',
  'adminStore'
)(observer(Notification))
