import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { inject, observer } from 'mobx-react'
import { message, Spin } from 'antd'
import { apiUrl, assetUrl } from '../../../config'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { RedirectPageWrapper } from './style';

const ConnectPage = props => {
  const { t } = useTranslation();
  const { commonStore, usersStore, match, location } = props
  moment.locale(`${commonStore.language}`)

  const provider = match.params.provider
  const search = location.search
  const token = commonStore.token

  const formatQueryParams = params => {
    return Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&')
  }

  useEffect(() => {
    let options = {}

    options.headers = Object.assign({
      'Content-Type': 'application/json',
    }, options.headers, {})

    if (token) {
      options.headers = Object.assign({
        'Authorization': `Bearer ${token}`,
      }, options.headers)
    }

    let requestURL = `${apiUrl}/auth/${provider}/callback${search}`
    if (options && options.params) {
      const params = formatQueryParams(options.params)
      requestURL = `${requestURL}?${params}`
    }

    if (options && options.body) {
      options.body = JSON.stringify(options.body)
    }

    fetch(requestURL, options)
      .then(response => response.json())
      .then(data => {
        if (data.statusCode && data.statusCode !== 200) {
          return Promise.reject(data);
        }
        commonStore.setToken(data.jwt)
        commonStore.setLanguage(data.language ? data.language : 'en')
        localStorage.setItem('jwt', data.jwt)
        usersStore.setCurrentUser()
        return data
      })
      .then(data => {
        fetch(`${assetUrl}maintananceInfo.json`
          , {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          })
          .then(function (response) {
            return response.json();
          })
          .then(function (myJson) {
            let u = data?.user?.data
            if (myJson.startDate && myJson.endDate && myJson.active) {
              let startTime = new Date(parseInt(myJson.startDate))
              let endTime = new Date(parseInt(myJson.endDate))

              let isBetween = moment(Date.now()).isBetween(moment(startTime), moment(endTime))
              const isActive = myJson.active === true || (myJson.active && myJson.active.toString().toLowerCase() === 'true')
              if (isBetween && isActive) {
                return props.history.push(`/notification`)
              }
            } else {
              usersStore.updatelLastLoggin(data.user.id, { lastLoggin: Date.now() })
              if (data.user.role.type === "web_admin") {
                message.success(t('welcome-username', { username: data.user.username }))
                if (u && (u.policy !== myJson.policy || u.terms !== myJson.terms)) {
                  props.history.push('/')
                  return window.location.reload()
                } else {
                  return props.history.push('/')
                }
              }
              if (props.history && props.history.location.state && props.history.location.state.from && props.history.location.state.from.pathname) {
                if (props.history.location.state.from.pathname.replace(/^\/([^\/]*).*$/, '$1') === 'admin') {
                  let _roleUser = data.user.role.type
                  if (_roleUser && _roleUser === " admin") {
                    props.history.push(`${props.history.location.state.from.pathname}`)
                  } else {
                    props.history.push('/')
                  }
                  message.success(t('welcome-username', { username: data.user.username }))
                }
                if (props.history.location.state.from.pathname.replace(/^\/([^\/]*).*$/, '$1') === 'project' && data.user.projectuserroles.length > 0) {
                  let _userinproject = data.user.projectuserroles.findIndex(x => x.project === props.history.location.state.from.pathname.split("/").pop());
                  if (_userinproject !== -1) {
                    props.history.push(`${props.history.location.state.from.pathname}`)
                  } else {
                    props.history.push('/')
                  }
                } else {
                  props.history.push(`${props.history.location.state.from.pathname}`)
                }
              } else {
                if (u && (u.policy !== myJson.policy || u.terms !== myJson.terms)) {
                  props.history.push('/')
                  return window.location.reload()
                } else {
                  return props.history.push('/')
                }
              }
              message.success(t('welcome-username', { username: data.user.username }))
            }
          });
      })
      .catch(error => {
        if (Array.isArray(error.data[0].messages)) {
          try {
            let v = error.data[0].messages[0].id || t('something-went-wrong')
            if (v) message.error(t(v))
          } catch (error) {
            message.error(t('something-went-wrong'))
          }
        } else {
          message.error(t('something-went-wrong'))
        }
        window.location = `${process.env.REACT_APP_COGNITO_DOMAIN}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URL}`;
        // props.history.push(`/auth/logout`)
      })
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('redirecting')} | 6DPlanner</title>
      </Helmet>
      <RedirectPageWrapper>
        <div className='middle'>
          <div className='inner'>
            <Spin tip={`Please wait..`}>
              {/* {t('retrieving-your-token-and-checking-validity')} */}
            </Spin>
          </div>
        </div>
      </RedirectPageWrapper>
    </HelmetProvider>
  )
}

export default inject('commonStore', 'usersStore')(observer(ConnectPage))