import styled from 'styled-components';
import { Content } from 'antd';


export const TableWrapper = styled.div`
    background : #fff;
    overflow : initial;
    flex : auto;
    min-height : 0 ;
    background: #fff;
    text-align: center;
    height: calc(100vh - 60px);
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { 
    width: 0 !important;
    display: none; 
    }

    .ant-form-item {
        margin-bottom: 0;
    }
`

export const TableContentWrapper = styled.div`
    background : #fff;
    text-align : center ;
    padding: 24px;
    width : 100%;
}
    .ant-form-item {
    margin-bottom: 0;
}
    .ant-table-tbody tr td {
    padding: 5px 16px;
}

    .ant-collapse-content > .ant-collapse-content-box{
    padding : 0px;
}
`