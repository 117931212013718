import styled from 'styled-components'

export const PageWrapper = styled.div`
  margin-top: 55px;
  display: block;
  padding: 15px;
  background-color: #fff;
  .text {
    font-size: 30px;
    line-height: 33px;
    text-align: center;    
    margin-top: 20%;
  }
`