import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { inject, observer } from 'mobx-react'
import DefaultTemplate from '../../layout/DefaultTemplate'
import { PageWrapper } from './CustomStyled'
import Container from '../../layout/Container'
import { message } from 'antd'
import { Trans, useTranslation } from 'react-i18next';

const ConfirmDeleteAccountPage = props => {
    const {
        commonStore, usersStore, match, history
    } = props
    const tokenid = match.params.tokenid
    const { t } = useTranslation();
    useEffect(() => {
        commonStore.setCurrentPage('confirm-delete-account')
        if (tokenid) {
            usersStore.getValidToken(tokenid).then((res) => {
                if (res.status === 200) {
                    let _validUntil = new Date(res.data.validUntil);
                    let today = new Date()
                    if (((today > _validUntil) || res.data.activatedDate)) {
                        message.error(t('token-is-not-valid-or-has-expired'))
                        history.push(`/`)
                    } else {
                        usersStore.deleteUser(tokenid).then((res) => {
                            if (res.status === 200) {
                                message.success(t('your-account-deleted'))
                                usersStore.userLogout()
                                    .then(() => {
                                        history.push(`/`)
                                    })
                            } else {
                                message.error(res.statusText)
                                history.push(`/`)
                            }
                        })
                    }
                } else {
                    history.push(`/`)
                }
            })
        }
    }, [])

    return (
        <HelmetProvider>
            <DefaultTemplate>
                <Helmet>
                    <title>{t('confirm-delete-my-Account')} | 6DPlanner</title>
                </Helmet>
                <PageWrapper>
                    <Container>
                        {/* <div className="text">
                        <p>Your account deleted click <Link to={'/'} style={{ color: commonStore.appTheme.solidColor }}>here</Link> go to homepage</p>
                    </div> */}
                    </Container>
                </PageWrapper>
            </DefaultTemplate >
        </HelmetProvider>
    )
}

export default inject('commonStore', 'usersStore')(observer(ConfirmDeleteAccountPage))
