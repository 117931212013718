import styled from 'styled-components'

export const TreePanel = styled.div`
  width: 100%;
  overflow-y: auto;
  padding-left: 10px;
`

export const AttrPaneWrapper = styled.div`
  margin-left: -24px;
  margin-right: -14px;
  margin-top: -24px;
  margin-bottom: -24px;
`

export const AttrPanel = styled.div`
  overflow-y: auto;
  max-height: calc(100% - 250px);
  .attributeTable{
    height: 500px;
    // overflow: auto;
    padding-bottom: 15px;
    //border-top: 1px solid #7b7b7b;
    border-bottom-left-radius: 10px;
    padding-left: 15px;
  }
  .ant-table {
    font-size: 12px;
    .ant-table-tbody  tr{
      &:hover {
        box-shadow: none;
      }
      td {
        padding: 2px 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;   
      }
    } 
  }
  .headingAttribute__title{
    padding: 8px;
    font-weight: bold;
    font-size: 1.4rem;
  }
  .headingAttribute__item{
    padding: 0 15px 0 25px;
  }
`

export const PanelHeading = styled.div`
  height: 45px;
  position: relative;
  border-bottom: 1px solid #7b7b7b;
`

export const HeadingAttribute = styled.div`
  font-weight: 500;
  border-bottom: 0;
  margin: 0 25px;
  font-size: 13px;
`

export const AttrPanelTitle = styled.span`
  margin-left: ${props => props.marginLeft ? props.marginLeft + 'px' : '10px'};
  display: inline-block;
  padding: 6px 8px;
  font-weight: 500; 
  border: 1px solid ${props => props.active ? "#F26524" : "#7b7b7b"};
  border-top-right - radius: 8px;
  border-top-left - radius: 8px;
  border-bottom: 0;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  color: ${props => props.active ? "#F26524" : "#000"};
`

export const CustomAttributesContainer = styled.div`
padding: 15px;
overflow-y: auto;
  max-height: calc(100% - 250px);
  min-height: 500px;
`

export const RightPanelActions = styled.span`
float: right;
line-height: 45px;
  .ant-avatar, button {
  margin-right: 8px;
  cursor: pointer;
  background: #fff!important;
}
`

