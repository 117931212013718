import React, { useState, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { inject, observer } from 'mobx-react'
import DefaultTemplate from '../../layout/DefaultTemplate'
import {
  TodosPageWrapper,
  TodosPageHeading,
  FormStyled,
} from './ProjectSettingsPageStyled'
import Container from '../../layout/Container'
import ProjectSettingsForm from './ProjectSettingsForm'
import { Trans, useTranslation } from 'react-i18next';

const ProjectSettingsPage = props => {
  const { commonStore, projectStore, match, history } = props
  const { t } = useTranslation();
  const [geoid, setGeoid] = useState([])
  const projectId = match.params.projectId

  useEffect(() => {
    // projectStore.getProjectDetail(projectId, true)
    const fetch = async () => {
      projectStore.setLoadingProgress(true)
      await projectStore.getProjectDetail(projectId).then(res => {
      }).catch(err => {
        if (err.status === 401) {
          return history.push("/auth/login")
        }
      })

      // list geoid
      await projectStore.getAllGEOID().then(res => {
        if (res.status === 200) {
          setGeoid(res.data)
        }
      }).catch(err => {
        console.log(err)
      })

      projectStore.setLoadingProgress(false)
    }

    commonStore.setCurrentPage('project-settings')
    fetch()

    return () => {
      projectStore.clearProjectDetail()
      projectStore.setModelList([])
      setGeoid([])
    }
  }, [])

  return (
    <HelmetProvider>
      <DefaultTemplate>
        <Helmet>
          <title>{t('project-settings')} | xdTwin</title>
        </Helmet>
        <TodosPageWrapper>
          <Container>
            <TodosPageHeading>{t('project-settings')}</TodosPageHeading>
            <FormStyled>
              <ProjectSettingsForm geoidlist={geoid} projectDetail={projectStore.projectDetail} />
            </FormStyled>
          </Container>
        </TodosPageWrapper>
      </DefaultTemplate>
    </HelmetProvider>
  )
}

export default inject(
  'commonStore',
  'usersStore',
  'projectStore'
)(observer(ProjectSettingsPage))
