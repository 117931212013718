import { toJS } from 'mobx'
const NODE_DATA_TYPES = {
  NODE_FILE: 'FILE',
  NODE_FOLDER: 'FOLDER',
}

const PROJECT_ROLE_TYPES = {
  OWNER: 'project_owner',
  MANAGER:'project_manager',
  USER :'project_user'
}
const GroupUtils = {
  searchTreeNode(data, key, match) {
    const stack = [];
    data.map(item => stack.push(item));
    while (stack.length > 0) {
      const node = stack.pop();
      if (node[key] === match) {
        return node;
      } else if (node.children) {
        node.children.map(child => stack.push(child))
      }
    }
    return null;
  },
   findAllTreeNode(data){
    const stack = [];
    const result = [];
    data.map(item => stack.push(item));
    while (stack.length > 0) {
      const node = stack.shift();
      if ((node.type === NODE_DATA_TYPES.NODE_FILE || node.type === NODE_DATA_TYPES.NODE_FOLDER ) && !node.isDeleted) {
        result.push(node)
      }
      if (node.type === NODE_DATA_TYPES.NODE_FOLDER && !node.isDeleted && node.children) {
        node.children.map(child => stack.push(child))
      }
    }
    return result
  },
   getMemberGroups(groups) {
    const stack = []
    const members = []
    groups.map(item => stack.push(item));
    while (stack.length > 0) {
      const node = stack.shift();
      if (node && node.email && node.user) {
          members.push(toJS(node))
      }
      if (node.users) {
          node.users.map(child => stack.push(child))
      }
  }
    return members
  },
   checkAvaiableGroups(node,groups) {
    const result = []
    let accesscontrols = node.accesscontrols || []
    for (let i = 0; i < accesscontrols.length; i++) {
      const element = accesscontrols[i];
      let isExist = groups.find(c => c.id ===element.id )
      if(isExist) {
        result.push(toJS(element))
      } 
    }
    return result 
  },
   traversalTree (data , parentKey,indent = 0){
    return data.map(item => {
      if (item.children) {
        return {
          ...item,
          children: this.traversalTree(item.children, item.key,indent +1),
          parentKey,
          indent
        };
      }
      return {
        ...item,
        parentKey,
        indent
      };
    });
  },
  /**
 * Checking user rights at treenode
 * @param {*} treeNode // Object : curent node need tobe checking
 * @param {*} userInfo  // Object : current user info
 * @param {*} processedUserGroups // Array combine from [all usergroups + all users] in prject
 * @returns Object {id :"62df596e5ec9b60019c34b22",name: "Sơn Đẹp Trai",rights: "modify"}
 */
  checkingUserRightsAtTreeNode (treeNode,userInfo,tTree){
    let currentUserRight ;
    if(userInfo){
      let accesscontrols = treeNode.accesscontrols || []
      let isExist = accesscontrols.find(c => c.id ===userInfo.id )
      if(isExist){
        currentUserRight = isExist ;
      }else{
        if (treeNode.parentKey) {
          let parrentNode = this.searchTreeNode(tTree, 'key', treeNode.parentKey);
          if (parrentNode) {
            currentUserRight = this.checkingUserRightsAtTreeNode(parrentNode, userInfo,tTree)
            if(currentUserRight){
              currentUserRight.inherit = {
                title : parrentNode.title,
                key : parrentNode.key,
                type : parrentNode.type,
                indent : parrentNode.indent
              }
            }
          }
        }
      }
    }
    return currentUserRight;
  }
}

export default GroupUtils
