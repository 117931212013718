import React, { Fragment, useState, useEffect } from 'react'
import { Icon, Tooltip, Row, Col } from 'antd'
import avatar from '../../../../../assets/imgs/organization-512.png'
import InfoCard from '../../../../organisms/InfoCard'
import moment from 'moment'
import ActiveStatusIndicator from '../../../../elements/ActiveStatusIndicator'
import { inject, observer } from 'mobx-react'
import {
  OrganizationViewWrapper,
  CardHeader,
  CardFooter,
  List,
} from './style'
import '../../style.css'
import { EditOutlined } from '@ant-design/icons'
import { Trans, useTranslation } from 'react-i18next';
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/sv";
import "moment/locale/es";
import "moment/locale/vi";
import { isMobile, isTablet } from 'react-device-detect'

const OrganizationView = ({ organizationStore, onToggleEditMode, commonStore, adminStore, drawerWidth }) => {
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  const [listUser, setListUser] = useState([]);
  useEffect(() => {
    if (adminStore.currentOrganizationView) {
      let _v = adminStore.currentOrganizationView?.organizationuserroles
      if (_v) {
        let users = _v.filter(v => v.user)
        setListUser(users)
      }
    }
  }, [adminStore.currentOrganizationView])
  return (
    <Fragment>
      <OrganizationViewWrapper>
        <CardHeader>
          <Row className={drawerWidth <= 576 ? 'block relative org-image' : 'org-image'}>
            {
              adminStore.roleCurrentUserInOrganization && adminStore.roleCurrentUserInOrganization.role === "Admin" ? (
                <Tooltip title={t('edit-organization-info')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <EditOutlined
                    className="action-edit"
                    style={{ background: commonStore.appTheme.gradientColor }}
                    onClick={onToggleEditMode}
                  />
                </Tooltip>
              ) : ''
            }
            <Col className={drawerWidth <= 576 ? 'w10 image-wrapper' : 'image-wrapper'}>
              <img src={adminStore.currentOrganizationView.logo && adminStore.currentOrganizationView.logo.url ? adminStore.currentOrganizationView.logo.url : avatar} alt={t('user-avatar')} />
            </Col>
            <Col className={drawerWidth <= 576 ? 'w10' : 'fl1'}>
              <div className="project-name">
                <span>{adminStore.currentOrganizationView.name}</span>
              </div>
              <ActiveStatusIndicator type={'button'} status={adminStore.currentOrganizationView && adminStore.currentOrganizationView.isActive ? true : false} />
              <Row className='contact-information'>
                <div className='contact-title'>{t('contact-information')}:</div>
                <Row className={drawerWidth <= 576 ? 'block' : ''}>
                  <Col className={drawerWidth <= 576 ? 'w10' : ''} span={24} sm={6}>
                    <b className="contact-infomation-title">{t('date-created')}:</b>
                  </Col>
                  <Col className={drawerWidth <= 576 ? 'w10' : ''} span={24} sm={18}>
                    <span>{moment(adminStore.currentOrganizationView.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                  </Col>
                </Row>
                <Row className={drawerWidth <= 576 ? 'block' : ''}>
                  <Col className={drawerWidth <= 576 ? 'w10' : ''} span={24} sm={6}>
                    <b className="contact-infomation-title">{t('address')}:</b>
                  </Col>
                  <Col className={drawerWidth <= 576 ? 'w10' : ''} span={24} sm={18}>
                    <span>{adminStore.currentOrganizationView.address}</span>
                  </Col>
                </Row>

              </Row>
            </Col>
          </Row>
        </CardHeader>
        <InfoCard
          color={'#FF9800'}
          title={''}
          left={adminStore.currentOrganizationView.projects ? adminStore.currentOrganizationView.projects.filter(c => !c.isDeleted).length : 0}
          right={listUser ? listUser.length : 0}
          leftTxt={t('project-belong')}
          rightTxt={t('users-belong')} />
      </OrganizationViewWrapper>
    </Fragment>
  )
}

export default inject('commonStore', 'organizationStore', "adminStore")(observer(OrganizationView))