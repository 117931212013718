import { t } from 'i18next';
const validator = {

  validatePhoneNumber: (rule, value, callback) => {
    const phoneRegex = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g
    if (value && !phoneRegex.test(value)) {
      callback(t('not-a-valid-phone-number'))
    } else {
      callback()
    }
  },

  validateWebsite: (rule, value, callback) => {
    const websiteRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
    if (value && !websiteRegex.test(value)) {
      callback(t('not-a-valid-website'))
    } else {
      callback()
    }
  },

  validateEmail: (rule, value, callback) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm
    if (value && !emailRegex.test(value)) {
      callback(t('not-a-valid-email'))
    } else {
      callback()
    }
  },

  validateEmptyString: (rule, value, callback) => {
    if (value && value.trim() === '') {
      return Promise.reject(t('must-contain-a-letter'))
    } else {
      return Promise.resolve()
    }
  },

  validateIntergerNumber: (rule, value, callback) => {
    if (value && !Number.isInteger(value)) {
      callback(t('not-an-interger-or-valid-number'))
    } else if (value && value < 0) {
      callback(t('not-accept-negative-number'))
    } else {
      callback()
    }
  },

  validateEmptyArray: (rule, value, callback) => {
    if (!value || value.length === 0) {
      callback(t('must-not-empty'));
    } else {
      callback();
    }
  }
}

export default validator