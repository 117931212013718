import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next';

const ForgotPassword = ({ match, authStore, handleChangeNewPassword }) => {
    const { t } = useTranslation();
    const CustomForm = props => {
        const [form] = Form.useForm();
        const [confirmDirty, setConfirm] = useState(false)

        const compareToFirstPassword = (rule, value, callback) => {
            if (value && value !== form.getFieldValue('password')) {
                callback(t('passwords-do-not-match'))
            } else {
                callback()
            }
        }

        const handleConfirmBlur = (e) => {
            const value = e.target.value
            setConfirm(confirmDirty || !!value)
        }

        return (
            <Form
                form={form}
                name="login-form"
                layout="vertical"
                className={`auth-form`}
                onFinish={v => handleChangeNewPassword(v)}>
                <Form.Item label={t('password')} name="password" rules={[
                    {
                        required: true,
                        message: t('please-input-your-password'),
                    },
                ]}>
                    <Input type="password" placeholder={t('enter-your-new-password')} />
                </Form.Item>
                <Form.Item label={t('confirm-password')} name="confirm" rules={[
                    {
                        required: true,
                        message: t('please-re-type-your-password'),
                    },
                    { validator: compareToFirstPassword },
                ]}>
                    <Input type="password" placeholder={t('re-type-password')} onBlur={handleConfirmBlur} />
                </Form.Item>
                <Button block size={'large'} type={'primary'} htmlType={'submit'}>
                    {t('reset-password')}
        </Button>
            </Form>
        )
    }
    return <CustomForm />
}

export default withRouter(inject('authStore')(observer(ForgotPassword)))