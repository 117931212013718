import styled from 'styled-components'
import { Avatar, Button } from 'antd';

export const ToolbarBottomContainer = styled.div`
  display: block;
  // position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px;
  z-index: ${props => props.alignment? `9999`: `999`};
  background: none;
  position: fixed;
  bottom: ${props => props.isIpad? '15px':'2px'};
  .ant-avatar-group {    
    .ant-avatar {
      border: 1px solid #000;
    }
  }
  .ant-btn {
    border: 1px solid #000;
  }
  
`

export const ToolbarBottomItem = styled.div`
  float:left;
  margin-bottom: 4px;
  color: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  } 
  .camera, .no-padding {
    padding:0 !important;
  }
  .no-padding .icon3D path{
    fill: #F26524 !important;
  }
  .ant-btn {
    border: 1px solid #000;
    width: 32px !important;
    height: 32px !important;
    margin-right: 5px;
    border-radius: 5px;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .end-sketch-btn {width: auto !important}
`
export const ARToolbarBottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 37px;
  z-index: 9999;
  bottom: 7px;
`
export const ARMaskingToolbarBottomContainer = styled.div`
  position: fixed;
  bottom: 4px;
  right: 3px;
  z-index: 999;
`
export const AvatarCustom = styled(Avatar)`
  border-radius: 5px !important;
  margin-right: 5px !important;
  cursor: pointer; 
  background: #fff !important;
  line-height: inherit !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  &:not(:first-child) {
    margin-left: 0px !important;
  }
  &:hover, &:focus {     
      border: 1px solid #ff8d4f !important;
  }
  color: ${props => props.activated === 1 ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated === 1 ? '#ff8d4f' : '#191919'} !important;
    transition: ease .3s;
  }
  ${props => props.alignment? `opacity: 30%; pointer-events: none;`: ``}
`

export const ButtonCustom = styled(Button)`
  margin-right: 5px !important;
  cursor: pointer;
  border-radius: 4px !important;
  position: relative;
  width : 30px !important;
  height : 30px !important;
  line-height: inherit !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  &:not(:first-child) {
    margin-left: 0px !important;
  }
  &:hover, &:focus {     
      border: 1px solid #ff8d4f !important;
  }
  transition: ease .3s;
`

export const ToolbarBottomHidenArea = styled.div` 
  display: inline-flex;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px;
  z-index: 999;
  background: none;  
`