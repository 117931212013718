import { observable, action, decorate } from 'mobx';
import { WorkflowRequest, ProjectTeamsRequest } from '../requests'
import { message } from 'antd'

class WorkflowStore {
    isLoading = false
    workflowList = []
    showAddWorkflowModal = false
    workflowData = {}
    showWorkflowDrawer = false

    setWorkflowData = (data) => {
        this.workflowData = data
    }

    clearWorkflowData = () => {
        this.workflowData = {};
    }

    clearWorkflowList = () => {
        this.workflowList = [];
    }

    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading
    }

    createWorkflow = (data) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            WorkflowRequest.create(data).then(() => {
                this.setLoadingProgress(false)
                this.setShowAddWorkflowModal(false);
                resolve()
            }).catch((err) => {
                console.log(err);
                this.setLoadingProgress(false);
                this.setShowAddWorkflowModal(false);
                reject(err)
            })
        })

    }

    setShowAddWorkflowModal(isShown) {
        this.showAddWorkflowModal = isShown;
    }

    getWorkflowList = async (projectId) => {
        return WorkflowRequest.getWorkflowByProject(projectId).then((respond) => {
            this.workflowList = [...respond.data];
        })
    }

    getWorkflowById = async (workflowId) => {
        return await new Promise((resolve, reject) => {
            WorkflowRequest.getWorkflowById(workflowId)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    updateWorkflow = async (workflowId, data) => {
        this.setLoadingProgress(true);
        return await new Promise((resolve, reject) => {
             WorkflowRequest.updateWorkflow(workflowId, data).then((res) => {
                this.setLoadingProgress(false);
                resolve(res.data)
            }).catch(err => {
                console.log(err);
                this.setLoadingProgress(false);
                reject(err)
            })
        })
    }

    deleteWorkflow(workflowId) {
        return new Promise((resolve, reject) => {
            WorkflowRequest.deleteWorkflow(workflowId).then((response) => {
                resolve(response)
            }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    }

    /**
     * set show workflow drawer
     * @param {*} state Type boolean
     */
    setShowWorkflowDrawer = state => {
        this.showWorkflowDrawer = state
    }
}


decorate(WorkflowStore, {
    isLoading: observable,
    workflowList: observable,
    showAddWorkflowModal: observable,
    setShowAddWorkflowModal: action,
    setLoadingProgress: action,
    createWorkflow: action,
    getWorkflowList: action,    
    getWorkflowById: action,
    deleteWorkflow: action,
    workflowData: observable,
    setWorkflowData: action,
    clearWorkflowData: action,
    showWorkflowDrawer: observable,
    setShowWorkflowDrawer: action,
})

export default new WorkflowStore()