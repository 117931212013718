import React, { useEffect } from 'react'
import { HiddenAreaUtils } from './HiddenAreaUtils';

const HiddenAreaView = props => {
    const { viewer, hideArea } = props
    useEffect(() => {
        if (viewer.current && viewer.current.cesiumElement && hideArea && hideArea?.points?.length > 0){
          HiddenAreaUtils.drawArea(viewer.current.cesiumElement, hideArea, true);
        }
    }, [hideArea.points, hideArea.edgeWidth, hideArea.edgeColor, hideArea.visibilitySource, hideArea.isCutTerrain])

    return <></>
}

export default HiddenAreaView
