import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, message, Modal, Upload, Radio, Space, Divider } from 'antd'
import { inject, observer } from 'mobx-react'
import { isFileTypeAllowed } from '../../Gantt/ganttUtils'
import { useTranslation } from 'react-i18next'

const gantt = window.gantt
const MS_PROJECT =
  '.mpp,.xml, text/xml, application/xml, application/vnd.ms-project, application/msproj, application/msproject, application/x-msproject, application/x-ms-project, application/x-dos_ms_project, application/mpp, zz-application/zz-winassoc-mpp'
const PRIMAVERA = '.xer,.xml, text/xml, application/xml, application/xer'

const ModalIEGantt = ({ projectGanttStore }) => {
  const { t } = useTranslation()
  const {
    isOpenModalExportnImportProject,
    setIsOpenModalExportnImportProject,
    setImportFile,
    isUploading,
    setIsUploading,
  } = projectGanttStore
  const [fileList, setFileList] = useState([])
  const [projectType, setProjectType] = useState('ms_project')
  const onChange = e => {
    setProjectType(e.target.value)
  }

  useEffect(
    () => () => {
      setFileList([])
      setProjectType('ms_project')
    },
    [isOpenModalExportnImportProject]
  )

  const handleImport = () => {
    if (fileList?.length) {
      setIsUploading(true)
      const formData = new FormData()
      formData.append('file', fileList[0])
      setImportFile({ file: formData, type: projectType })
    }
  }

  const props = {
    maxCount: 1,
    multiple: false,
    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    accept: projectType === 'ms_project' ? MS_PROJECT : PRIMAVERA,
    beforeUpload: file => {
      const isAllow = isFileTypeAllowed(file, projectType)
      if (!isAllow) {
        message.error(
          t(`gantt.you-can-only-upload-${
            projectType === 'ms_project' ? 'ms-project' : 'primavera-file'
          }`)
        )
        return false
      }
      setFileList([file])
      return false
    },
    fileList,
  }

  const handleCloseModal = () => {
    setIsOpenModalExportnImportProject({ type: 'import', open: false })
  }
  const handleExport = () => {
    let payload = {
      skip_circular_links: false,
      tasks: {
        Finish: function (task) {
          return task.end_date
        },
      },
    }
    gantt[projectType === 'ms_project'? 'exportToMSProject' : 'exportToPrimaveraP6'](payload)
  }

  return (
    <Modal
      title={`${
        isOpenModalExportnImportProject.type === 'import' ? t('commons.import') : t('commons.export')
      } ${t('project')}`}
      visible={isOpenModalExportnImportProject.open}
      footer={null}
      zIndex={10001}
      onCancel={handleCloseModal}>
      <Space>
        <Radio.Group onChange={onChange} value={projectType}>
          <Radio value={'ms_project'} style={{ marginRight: 10 }}>
            MS Project
          </Radio>
          <Radio value={'primavera'}>Primavera</Radio>
        </Radio.Group>
      </Space>
      <Divider />
      {isOpenModalExportnImportProject.type === 'import' ? (
        <>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>{t('gantt.select-file')}</Button>
          </Upload>
          <Button
            type="primary"
            onClick={handleImport}
            disabled={fileList.length === 0}
            loading={isUploading}
            style={{
              marginTop: 16,
            }}>
            {isUploading ? t('importing') : t('commons.import')}
          </Button>
        </>
      ) : (
        <Button type="primary" onClick={handleExport}>
          {t('commons.export')}
        </Button>
      )}
    </Modal>
  )
}
export default inject('projectGanttStore')(observer(ModalIEGantt))
