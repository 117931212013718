import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react'
import {
    Cartesian3,
    Cartesian2,
    CallbackProperty,
    Color,
    Transforms,
    ClippingPlaneCollection,
    ClippingPlane,
    ScreenSpaceEventType,
    ScreenSpaceEventHandler,
    Matrix4,
    SceneMode,
    Ellipsoid,
    defined,
    GridMaterialProperty,
    ClassificationType,
    Math as CesiumMath,
    Plane,
    Cartographic
} from 'cesium'
import { isMobile } from 'react-device-detect';
import { clickPoint } from '../helper/CesiumUtils'
import { notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
var ellipsoid = Ellipsoid.WGS84

const ClippingPlaneAlignment = ({ projectStore, commonStore, alignmentStore, uiStore, viewer }) => {
    let planeEntities = []
    let selectedPlane
    const { t } = useTranslation();

    var clickHandle, downHandler, upHandler, moveHandler, handlerMoveLine, clickHanleOnMobile
    let customLine = new CustomLine();

    function clearAll() {
        if (viewer.current && viewer.current.cesiumElement && viewer.current.cesiumElement.dataSources) {
            resetClip()
        } else {
            projectStore.setClippingMode(false)
        }
        customLine.removeAll();
        if (viewer.current && viewer.current.cesiumElement && viewer.current.cesiumElement.scene.requestRenderMode) {
            viewer.current.cesiumElement.scene.requestRender();
        }
    }

    function resetClip() {
        // remove entity clipping
        for (var i = 0; i < planeEntities.length; i++) {
            var _entities = viewer.current.cesiumElement.entities.getById(planeEntities[i].id)
            if (_entities) {
                viewer.current.cesiumElement.entities.remove(_entities);
            }
        }

        //remove tileset clipping
        for (var i = 0; i < viewer.current.cesiumElement.scene.primitives.length; i++) {
            var _tileset1 = viewer.current.cesiumElement.scene.primitives.get(i)
            if (_tileset1.isCesium3DTileset && _tileset1.clippingPlanes) {
                _tileset1.clippingPlanes.removeAll();
            }
        }

        if (viewer.current.cesiumElement.scene.globe.clippingPlanes) {
            viewer.current.cesiumElement.scene.globe.clippingPlanes.enabled = !1
            viewer.current.cesiumElement.scene.globe.clippingPlanes.removeAll()
        }

        planeEntities = [];
        selectedPlane = undefined
    }

    function createClipPlane(position, pickedObject, _listStation, currentStep) {
        downHandler = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas);
        // Update plane on mouse click
        clickHanleOnMobile = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas)

        clickHanleOnMobile.setInputAction(function (movement) {
            if (defined(selectedPlane)) {
                viewer.current.cesiumElement.scene.screenSpaceCameraController.enableInputs = true;
                selectedPlane = undefined
                return
            }
            var prevPickObj = commonStore.pickedObject
            var pickedObject = viewer.current.cesiumElement.scene.pick(movement.position)
            if (prevPickObj && prevPickObj.position && prevPickObj.position.x === movement.position.x && prevPickObj.position.y === movement.position.y) {
                commonStore.setpickedObject(pickedObject, movement.position)
            }
            viewer.current.cesiumElement.scene.screenSpaceCameraController.enableInputs = true
        }, ScreenSpaceEventType.LEFT_CLICK)
        downHandler.setInputAction(function (movement) {
            var position = viewer.current.cesiumElement.scene.camera.pickEllipsoid(movement.position, viewer.current.cesiumElement.scene.globe.ellipsoid);
            var pickedObject = viewer.current.cesiumElement.scene.pick(movement.position, 20)
            if (projectStore.clippingPickDone) {
                commonStore.setpickedObject(undefined, movement.position)
            }
            if (defined(pickedObject) && defined(pickedObject.id) && defined(pickedObject.id.plane)) {
                selectedPlane = pickedObject.id.plane;
                selectedPlane.material = Color.WHITE.withAlpha(0.05);
                selectedPlane.outlineColor = Color.WHITE;
                selectedPlane.startPosition1 = CesiumMath.toDegrees((ellipsoid.cartesianToCartographic(position).longitude))
                viewer.current.cesiumElement.scene.screenSpaceCameraController.enableInputs = false;
            }
        }, ScreenSpaceEventType.LEFT_DOWN);

        // Release plane on mouse up
        upHandler = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas);
        upHandler.setInputAction(function (movement) {
            if (defined(selectedPlane)) {
                selectedPlane.material = Color.WHITE.withAlpha(0.1);
                selectedPlane.outlineColor = Color.WHITE;
                selectedPlane = undefined;
            }
            viewer.current.cesiumElement.scene.screenSpaceCameraController.enableInputs = true;
        }, ScreenSpaceEventType.LEFT_UP);

        // Update plane on mouse move
        moveHandler = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas);
        moveHandler.setInputAction(function (movement) {
            if (defined(selectedPlane)) {
                const deltaY = movement.startPosition.y - movement.endPosition.y;
                if (deltaY > 0) {
                    alignmentStore.setCurrentStep(alignmentStore.currentStep + 1)
                } else {
                    if (alignmentStore.currentStep > 5)
                        alignmentStore.setCurrentStep(alignmentStore.currentStep - 1)
                }
                alignmentStore.setChangeSlider(true)
            }
        }, ScreenSpaceEventType.MOUSE_MOVE);

        if (pickedObject) {
            const alignmentId = pickedObject && pickedObject.id && pickedObject.id.isAlignment ? (pickedObject.id.parentId || pickedObject.id.id) : ''
            if (alignmentId && pickedObject && pickedObject.id.entityCollection.values) {
                let values = []    
                let alignmentName = pickedObject?.id?.properties?._alignment_name?._value
                if (alignmentName) {
                    values = pickedObject.id.entityCollection.values.filter(x => x.alignmentType === "tick" && x?.properties?._alignment_name?._value === alignmentName)
                } else {
                    values = pickedObject.id.entityCollection.values.filter(x => x.alignmentType === "tick")
                }
                if (values.length > 0) {
                    let listStation = values.map(function (obj) {
                        return { value: parseFloat(obj._id), position: new Cartesian3.midpoint(obj.polyline.positions._value[0], obj.polyline.positions._value[1], new Cartesian3()) }
                    })
                    alignmentStore.setAlignmentTicks(listStation) // set all alignment entity
                    projectStore.setClippingViewPoint({ position: position, listStation: listStation, currentStep: alignmentStore.currentStep || 0 }) // for save clipping to viewpoint
                    alignmentStore.setAlignmentChangeWidth(null)
                    createClipPlaneForTiles(position, listStation)
                    if (handlerMoveLine) handlerMoveLine.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
                    if (clickHandle) clickHandle.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                    if (clickHanleOnMobile) clickHanleOnMobile.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                    projectStore.setClippingPickDone(true);
                } else {
                    notification.open({
                        message: t('can-not-found-alignment'),
                        icon: <InfoCircleOutlined style={{ color: '#faad14' }} />
                    })
                }
            } else {
                notification.open({
                    message: t('can-not-found-alignment'),
                    icon: <InfoCircleOutlined style={{ color: '#faad14' }} />
                })
            }
        } else {
            if (_listStation && currentStep) {
                alignmentStore.setAlignmentTicks(_listStation) // set all alignment entity
                createClipPlaneForTiles(position, _listStation, currentStep)
            }
        }

        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }

    async function createClipPlaneForTiles(position, listStation, currentStep) {
        uiStore.setClippingPlaneToolVisible(false); // hide menu bottom clipping plane

        // for clipping plane
        var primitives = viewer.current.cesiumElement.scene.primitives._primitives.filter(function (value, index, arr) {
            return value.isCesium3DTileset;
        });
        let dataClip = primitives.filter(item => {
            let check = false;
            for (var i = 0; i < projectStore.visibleTilesets.length; i++) {
                let data = projectStore.visibleTilesets[i]
                if (item.id == data.modelId && data.isVisibleClip) {
                    check = true
                }
            }
            for (var i = 0; i < projectStore.listProjectLink.length; i++) {
                let projectLink = projectStore.listProjectLink[i]
                projectStore.listAllModel3DSLink.map(model => {
                    if (model.link?.id === projectLink.link?.id) {
                        if (projectLink.isVisibleClip) {
                            if (item.id === model.id) {
                                check = true
                            }
                        }
                    }
                })
            }
            return check
        })

        let nearestToClickedPoint = listStation.reduce((a, b) => Cartesian3.distance(position, a.position) < Cartesian3.distance(position, b.position) ? a : b);
        let positionIndex = listStation.findIndex(item => item.value === nearestToClickedPoint.value);
        let tick1
        let tick2
        if ((listStation[positionIndex].value === listStation[0].value) || (listStation[positionIndex].value === listStation[listStation.length - 1].value)) {
            if (nearestToClickedPoint.value === listStation[0].value) {
                tick1 = nearestToClickedPoint
                tick2 = listStation[positionIndex + 1]
            } else {
                tick1 = listStation[positionIndex - 1]
                tick2 = nearestToClickedPoint
            }
        } else {
            let nextpos = listStation[positionIndex + 1]
            let previouspos = listStation[positionIndex - 1]
            let distanceFromPositionToNext = Cartesian3.distance(position, nextpos.position)
            let distanceFromPositionToPrevious = Cartesian3.distance(position, previouspos.position)

            if (distanceFromPositionToPrevious < distanceFromPositionToNext) {
                tick1 = previouspos
                tick2 = nearestToClickedPoint
            } else {
                tick1 = nearestToClickedPoint
                tick2 = nextpos
            }
        }
        alignmentStore.setCurrentStep(tick1.value) // current step
        if(!currentStep){
            projectStore.setClippingViewPoint({ position: position, listStation: listStation, currentStep: tick1.value}) // for save clipping to viewpoint
        }

        const planeNormalDirection = getdirection(tick1.position, tick2.position)
        planeNormalDirection.position = tick1.position
        alignmentStore.setOrientationClippingPlane(planeNormalDirection)
        for (let [i, _tileset] of dataClip.entries()) {
            if (_tileset?.isCesium3DTileset) {
                if (_tileset.clippingPlanes) _tileset.clippingPlanes.removeAll();
                let clippingPlanes = new ClippingPlaneCollection({
                    planes: [new ClippingPlane(planeNormalDirection.normal, 0)],
                    edgeWidth: 3.0,
                    edgeColor: Color.BLACK,
                    unionClippingRegions: true,
                    modelMatrix: Matrix4.inverse(_tileset.root.computedTransform, new Matrix4())
                })
                _tileset.clippingPlanes = clippingPlanes
                try {
                    for (var j = 0; j < clippingPlanes.length; ++j) {
                        var plane = clippingPlanes.get(j)
                        var planeEntity = viewer.current.cesiumElement.entities.add({
                            type: 'clipping',
                            position: new CallbackProperty(function () {
                                if (alignmentStore.orientationClippingPlane) {
                                    var globalMatrix = Transforms.eastNorthUpToFixedFrame(alignmentStore.orientationClippingPlane.position, Ellipsoid.WGS84, new Matrix4());
                                    var toLocalMatrix = Matrix4.inverse(_tileset.clippingPlanesOriginMatrix, new Matrix4());
                                    clippingPlanes.modelMatrix = Matrix4.multiply(toLocalMatrix, globalMatrix, new Matrix4());
                                    clippingPlanes._planes[0].normal = alignmentStore.orientationClippingPlane.normal
                                    clippingPlanes._planes[0].distance = alignmentStore.orientationClippingPlane.distance
                                    return alignmentStore.orientationClippingPlane.position
                                }
                                return tick1.position
                            }, false),
                            matrix: Matrix4.clone(clippingPlanes.modelMatrix),
                            plane: {
                                dimensions: new CallbackProperty(() => {
                                    let dimension = 5;
                                    if (viewer.current?.cesiumElement?.camera?.position) {
                                        let campos = viewer.current.cesiumElement.camera.position;
                                        dimension = (Cartesian3.distance(campos, position) / 100) * 5
                                    }
                                    return new Cartesian2(dimension, dimension)
                                }, false),
                                material: ((i + 1) == (dataClip.length) ? Color.WHITE.withAlpha(0.1) : Color.WHITE.withAlpha(0)),
                                plane: plane,
                                outline: true,
                                outlineColor: Color.WHITE,
                                planeId: j,
                            },
                            planeId: j
                        })
                        planeEntities.push(planeEntity)
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        alignmentStore.toggleAlignmentTimeSliderVisible(true) // toggle show aligment slider tool
        //for case load from viewpoint
        if (currentStep) {
            alignmentStore.setCurrentStep(currentStep) // current step
            alignmentStore.setChangeSlider(true)
        }

    }

    useEffect(() => {
        if (alignmentStore.changeSlider && alignmentStore.currentStep !== undefined && alignmentStore.alignmentTicks.length > 0) {
            let roundDownToNearest = closestNearestDown(alignmentStore.alignmentTicks.map(({ value }) => value), alignmentStore.currentStep) // ex: station1: 1430, station2:1440 input 1435.5 =>result: 1430
            let indexCurrent = alignmentStore.alignmentTicks.findIndex(item => item.value === roundDownToNearest);
            if (indexCurrent > -1) {
                let closest1 = alignmentStore.alignmentTicks[indexCurrent]
                let closest2 = alignmentStore.alignmentTicks[indexCurrent + 1] ? alignmentStore.alignmentTicks[indexCurrent + 1] : closest1

                if (closest1 && closest2) {
                    const planeNormalDirection = getdirection(closest1.position, closest2.position)
                    planeNormalDirection.position = closest1.position
                    let targetY = closest1.value - alignmentStore.currentStep
                    planeNormalDirection.distance = targetY
                    projectStore.setClippingViewPoint({ ...projectStore.clippingViewPoint, currentStep: alignmentStore.currentStep })
                    alignmentStore.setOrientationClippingPlane(planeNormalDirection)
                }
            }
            alignmentStore.setChangeSlider(false)
        }
    }, [alignmentStore.changeSlider])

    /**
     * find closest nearest down
     * @param {*} arr 
     * @param {*} val 
     * @returns 
     */
    function closestNearestDown(arr, val) {
        return Math.max.apply(null, arr.filter(function (v) { return v <= val }))
    }

    function getdirection(point1, point2) {
        if (Cartesian3.distance(point1, point2) === 0) {
            return alignmentStore.orientationClippingPlane
        }
        const transform = Transforms.eastNorthUpToFixedFrame(point1);
        const positionvector = Cartesian3.subtract(point2, point1, new Cartesian3());
        const vector = Matrix4.multiplyByPointAsVector(
            Matrix4.inverse(transform, new Matrix4()),
            positionvector,
            new Cartesian3()
        );
        const planeNormalDirection1 = Cartesian3.normalize(vector, new Cartesian3());
        const plane = new Plane.fromPointNormal(Cartesian3.ZERO, planeNormalDirection1);
        return plane
    }

    //Function Create line mouse move
    function CustomLine() {
        this.positions = [];
        this.markers = [];
        var that = this;

        this.line = viewer.current.cesiumElement.entities.add({
            polyline: {
                positions: new CallbackProperty(function () {
                    return that.positions;
                }, false),
                width: 2,
                material: Color.WHITE,
                depthFailMaterial: new GridMaterialProperty({
                    color: Color.RED
                }),
                destroyPrimitives: false,
                classificationType: ClassificationType.BOTH
            }
        });
    }

    //Function add point click
    CustomLine.prototype.addPoint = function (position) {
        var that = this;
        var n = this.positions.length;
        this.positions[n] = position;

        var marker = viewer.current.cesiumElement.entities.add({
            position: new CallbackProperty(function () {
                return that.positions[n];
            }, false),
            point: {
                pixelSize: 5,
                color: Color.RED,
                outlineColor: Color.WHITE,
                outlineWidth: 2,
            },
        })
        this.markers.push(marker);
    };

    //function remove point and line
    CustomLine.prototype.removeAll = function () {
        if (this.markers && this.markers.length > 0) {
            for (var i = 0; i < this.markers.length; i++) {
                if (viewer && viewer.current && viewer.current.cesiumElement)
                    viewer.current.cesiumElement.entities.remove(this.markers[i]);
            }
            this.markers = [];
            this.positions = [];
        }
    };
    function startPickPoint() {
        clickHandle = new ScreenSpaceEventHandler(viewer.current.cesiumElement.scene.canvas)
        clickHandle.setInputAction(function (click) {
            if (!viewer.current.cesiumElement.scene) {
                return
            }
            if (alignmentStore.alignmentChangeWidth) {
                projectStore.Alignment.map(item => {
                    if (item && item.ref?.current?.cesiumElement) {
                        const entitiesCZML = item.ref.current.cesiumElement.entities.values
                        entitiesCZML.map(e => {
                            if (e.polyline?.width?._value) {
                                e.polyline.width = 2
                            }
                        })
                        alignmentStore.setAlignmentChangeWidth(null)
                    }
                })
            }
            if (viewer.current.cesiumElement.scene.mode !== SceneMode.MORPHING) {
                try {
                    var mousePosition = viewer.current.cesiumElement.scene.pickPosition(click.position)
                    let pickedObject
                    if (isMobile) {
                        pickedObject = viewer.current.cesiumElement.scene.drillPick(click.position, 4, 20, 20).find(el => el.id?.isAlignment)
                        pickedObject = pickedObject ? pickedObject : { id: {} }
                    }
                    else pickedObject = viewer.current.cesiumElement.scene.pick(click.position)

                    if (defined(mousePosition)) {
                        customLine.removeAll();
                        createClipPlane(mousePosition, pickedObject)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }, ScreenSpaceEventType.LEFT_CLICK)

        //event mouse move for draw line
        handlerMoveLine = new ScreenSpaceEventHandler(viewer.current.cesiumElement.canvas);
        handlerMoveLine.setInputAction(function (event) {
            let resultClick = clickPoint(viewer.current.cesiumElement, event.endPosition)
            if (resultClick?.pickedObject?.id?.isAlignment && resultClick?.pickedObject?.id?.alignmentKey) {
                projectStore.Alignment.map(item => {
                    if (item && item.ref?.current?.cesiumElement && item.key === resultClick?.pickedObject?.id?.alignmentKey) {
                        const entitiesCZML = item.ref.current.cesiumElement.entities.values
                        entitiesCZML.map(e => {
                            if (e.polyline?.width?._value && e?.properties?._alignment_name?._value === resultClick?.pickedObject?.id?.properties?._alignment_name?._value) {
                                e.polyline.width = 10
                            }
                        })
                        alignmentStore.setAlignmentChangeWidth(resultClick?.pickedObject?.id?.alignmentKey)
                    }
                })
            } else {
                if (alignmentStore.alignmentChangeWidth) {
                    projectStore.Alignment.map(item => {
                        if (item && item.ref?.current?.cesiumElement) {
                            const entitiesCZML = item.ref.current.cesiumElement.entities.values
                            entitiesCZML.map(e => {
                                if (e.polyline?.width?._value) {
                                    e.polyline.width = 2
                                }
                            })
                            alignmentStore.setAlignmentChangeWidth(null)
                        }
                    })
                }
            }
            if (resultClick && resultClick.position) {
                const cartographic = Cartographic.fromCartesian(
                    resultClick.position
                );
                // if (cartographic.height >= -0.1) {
                    var n = customLine.positions.length;
                    if (n === 0) {
                        customLine.addPoint(resultClick.position);
                    } else {
                        customLine.positions[n - 1] = resultClick.position;
                    }
                // }
            }
        }, ScreenSpaceEventType.MOUSE_MOVE);
    }

    useEffect(() => {
        clearAll()
        if (projectStore.clippingMode === 'alignment') {
            if (projectStore.clippingViewPoint && projectStore.clippingViewPoint.position) {
                projectStore.setClippingPickDone(true);
                createClipPlane(projectStore.clippingViewPoint.position, null, projectStore.clippingViewPoint.listStation, alignmentStore.currentStep)
            } else {
                projectStore.setClippingPickDone(false);
                startPickPoint()
            }
        }

        return () => {
            if (clickHandle)
                clickHandle.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
            if (clickHanleOnMobile)
                clickHanleOnMobile.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
            if (downHandler)
                downHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOWN)
            if (upHandler)
                upHandler.removeInputAction(ScreenSpaceEventType.LEFT_UP)
            if (moveHandler)
                moveHandler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
            if (handlerMoveLine) {
                customLine.removeAll();
                handlerMoveLine.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                handlerMoveLine.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
            }
            projectStore.setClippingPickDone(false);
            clearAll()
        }
    }, [projectStore.visibleTilesets, projectStore.listProjectLink,projectStore.targetViewPoint])

    return <></>
    // Mouse over the globe to see the cartographic position
}
export default inject('projectStore', 'commonStore', 'alignmentStore', 'uiStore')(observer(ClippingPlaneAlignment))
