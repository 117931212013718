import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { WorkflowWrapper, WorkflowContent, HeadingPhase, PhaseItemContainerStyle, PhasesContainerStyle, WorkflowChecklistContainer, GlobalStylePopconfirm, HeaderWorkflowWrapper } from './customStyle'
import { Button, Drawer, Input, Select, Skeleton, Form, Popconfirm, Col, Divider, Row, Tooltip, Typography, AutoComplete, message, Modal } from 'antd'
import { ColumnWidthOutlined, PlusOutlined, MinusOutlined, DownOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { ReactComponent as DragIcon } from '../../../assets/svgs/drag-indicator.svg'
import validator from "../../../validator"
import SVGIcon from '../../elements/SVGIcon'
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import HelpButton from '../../elements/HelpButton'
const { TextArea } = Input
const { Text } = Typography
const { Option } = Select

const DrawerWorkflow = ({ projectStore, workflowStore, usersStore, projectSettingStore, projectTeamsStore, topicStore }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const isTabletOrMobile = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [phases, setPhases] = useState([]);
    const [isDragEnd, setIsDragEnd] = useState();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const [selectedWorkflow, setSelectedWorkflow] = useState(false)
    const [workflowOptions, setWorkflowOptions] = useState([])
    const checklistInput = React.createRef()
    const addWorkflowRef = React.createRef()
    const [addWorkFlowModal, setAddWorkFlowModal] = useState(false)
    const [autoFocusInput, setAutoFocusInput] = useState(false)

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if(newWidth > window.innerWidth){
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 360;
        if (offsetRight > minDrawerWidth) {
            setDrawerWidth(offsetRight);
        }
        if(offsetRight > window.innerWidth){
            setDrawerWidth(window.innerWidth);
        }
    }, []);
    //#endregion

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])

    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize)

    useEffect(() => {
        if (workflowStore.showWorkflowDrawer) {
            setDrawerWidth(isTabletOrMobile ? 360 : window.innerWidth < 360 ? window.innerWidth : (window.innerWidth / 2)< 360 ? 360 : window.innerWidth / 2)
            form.resetFields(['name', []])
            const loadInitData = async () => {
                setLoading(true)
                await workflowStore.getWorkflowList(projectStore.projectDetail._id).then(res => {
                    let options = workflowStore.workflowList.map((item) => {
                        return {
                            key: item.id,
                            value: item.name,
                            label: item.name
                        }
                    })
                    setWorkflowOptions(options)
                })
                await projectTeamsStore.getProjectTeams(projectStore.projectDetail._id)
                setPhases([{ name: '', users: [], checklists: [] }]);
                setLoading(false)
            }
            loadInitData()
        }
    }, [workflowStore.showWorkflowDrawer])

    //effect change selected workflow
    useEffect(() => {
        if (selectedWorkflow) {
            let cpPhases = []
            let workflowsphase = selectedWorkflow.workflowsphase
            workflowsphase.forEach((item, index) => {
                cpPhases.push({
                    id: item.id,
                    name: item.name,
                    users: item.assigntopersons.length > 0 ? item.assigntopersons.map(x => x.id) : [],
                    checklists: !item.workflowsphasechecklists ? []
                    : item.workflowsphasechecklists.map((wfchecklist) =>{
                        return {
                            id : wfchecklist?.id,
                            name : wfchecklist?.name,
                            order : wfchecklist?.order,
                            workflowsphase : wfchecklist?.workflowsphase
                        }
                    }),
                    topicphases: !item.topicphases ? []
                    : item.topicphases.map(tfphase =>{
                        return {
                            id : tfphase?.id,
                            name : tfphase?.name,
                            order : tfphase?.order,
                            topic : tfphase?.topic,
                            workflowsphase : tfphase?.workflowsphase,
                            assigntopersons : tfphase?.assigntopersons || [],
                            checklists : tfphase?.checklists || []
                        }
                    })
                })
                setPhases([...cpPhases]);
            });
            form.setFieldsValue({ name: selectedWorkflow.name });
            form.setFieldsValue({ description: selectedWorkflow.description });
        }
    }, [selectedWorkflow]);

    /**
     * Effect phase change
     */
    useEffect(() => {
        if (isDragEnd) {
            setIsDragEnd(false);
        }
        if (phases && phases.length > 0) {
            for (let i = 0; i < phases.length; i++) {
                const phaseName = { [`phase-${i}`]: phases[i].name }
                const phaseUsers = { [`users-${i}`]: phases[i].users }

                form.setFieldsValue(phaseName);
                form.setFieldsValue(phaseUsers);

                if (phases[i].checklists) {
                    for (let j = 0; j < phases[i].checklists.length; j++) {
                        const checklistName = { [`checklists-${i}-${j}`]: phases[i].checklists[j].name }
                        form.setFieldsValue(checklistName);
                    }
                }
            }
        }
    }, [phases])


    const handleDragEnd = (result) => {
        if (result.type === "dragphase") {
            if (!result.destination) return;
            const tmpPhases = reorder(phases, result.source.index, result.destination.index);
            setPhases([...tmpPhases]);
            setIsDragEnd(true);
        } else {
            if (!result.destination) return;
            const tmpPhases = reorder(phases[parseInt(result.type, 10)].checklists, result.source.index, result.destination.index);
            const tmpTopicPhases = reorderTopicPhase(phases[parseInt(result.type, 10)].topicphases || [], result.source.index, result.destination.index);
            setPhases(prevObjs => (prevObjs.map((o, index) => {
                if (index === parseInt(result.type, 10)) return { ...o, checklists: tmpPhases, topicphases: tmpTopicPhases }
                return o;
            })))
            setIsDragEnd(true);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const reorderTopicPhase = (list, startIndex, endIndex) => {
        const result = []
        list.map(elm => {
            const res = Array.from(elm.checklists);
            const [removed] = res.splice(startIndex, 1);
            res.splice(endIndex, 0, removed);
            elm.checklists = res
            result.push(elm)
        })
        return result;
    };

    const addPhase = () => {
        var cpPhases = JSON.parse(JSON.stringify(phases));
        cpPhases.push({
            name: '',
            checklists: [
                // {
                //     name: ''
                // }
            ],
            id: undefined,
            topicphases: []
        })
        setPhases([...cpPhases]);
        let _phaseindex = cpPhases.length - 1
        for (let i = 0; i < cpPhases[_phaseindex].checklists.length; i++) {
            const checklistname = { [`checklists-${_phaseindex}-${i}`]: cpPhases[_phaseindex].checklists[i].name }
            form.setFieldsValue(checklistname);
        }
    };

    const removePhase = (phaseIndex) => {
        var cpPhases = JSON.parse(JSON.stringify(phases));
        if (cpPhases.length > 0) {
            const checklistCount = cpPhases[phaseIndex].checklists.length;
            cpPhases[phaseIndex].checklists.splice(0, checklistCount);
            cpPhases.splice(phaseIndex, 1);
            setPhases([...cpPhases]);
        }

        for (let i = 0; i < cpPhases.length; i++) {
            const phaseName = { [`phase-${i}`]: cpPhases[i].name }
            const phaseUsers = { [`users-${i}`]: cpPhases[i].users }

            form.setFieldsValue(phaseName);
            form.setFieldsValue(phaseUsers);

            for (let j = 0; j < cpPhases[i].checklists.length; j++) {
                const checklistName = { [`checklists-${i}-${j}`]: cpPhases[i].checklists[j].name }
                form.setFieldsValue(checklistName);
            }
        }
    };

    const handlePhaseNameChange = (e, phaseIndex) => {
        const value = e.target.value;
        form.setFieldsValue({ [`phase-${phaseIndex}`]: value });
        let cpPhases = [...phases];
        cpPhases[phaseIndex].name = form.getFieldValue(`phase-${phaseIndex}`);
        setPhases([...cpPhases]);
    }

    const handlePeopleChange = (phaseIndex,userId) => {
        if(userId){
            let cpPhases = [...phases];
            cpPhases[phaseIndex].users = !cpPhases[phaseIndex].users?.length ? [] : cpPhases[phaseIndex].users.filter(u => u !== userId)
            setPhases([...cpPhases]);
        }else{
            let cpPhases = [...phases];
            cpPhases[phaseIndex].users = form.getFieldValue(`users-${phaseIndex}`);
            setPhases([...cpPhases]);
        }
    }

    const handleChecklistChange = (e, phaseIndex, checklistIndex) => {
        const value = e.target.value;
        form.setFieldsValue({ [`checklists-${phaseIndex}-${checklistIndex}`]: value });
        let cpPhases = [...phases];
        cpPhases[phaseIndex].checklists[checklistIndex].name = form.getFieldValue(`checklists-${phaseIndex}-${checklistIndex}`);
        setPhases([...cpPhases]);
    }

    const handleChecklistInputKeyDown = (event, phaseIndex) => {
        if (event.key === 'Enter') {
            addChecklistItem(phaseIndex);
        }
    }

    const addChecklistItem = (phaseIndex) => {
        setAutoFocusInput(true)
        var cpPhases = JSON.parse(JSON.stringify(phases));
        var cpCheckLists = cpPhases[phaseIndex].checklists;
        cpPhases[phaseIndex].checklists = [
            ...cpCheckLists,
            {
                name: ""
            }
        ];

        setPhases([...cpPhases]);
        for (let i = 0; i < cpPhases[phaseIndex].checklists.length; i++) {
            const checklistname = { [`checklists-${phaseIndex}-${i}`]: cpPhases[phaseIndex].checklists[i].name }
            form.setFieldsValue(checklistname);
        }
    };

    const removeCheckListItem = (phaseIndex, checklistIndex) => {
        var cpPhases = JSON.parse(JSON.stringify(phases));
        var cpCheckLists = cpPhases[phaseIndex].checklists;
        if (cpCheckLists.length > 1) {
            cpPhases[phaseIndex].checklists.splice(checklistIndex, 1);
        }
        setPhases([...cpPhases]);

        for (let i = 0; i < cpPhases[phaseIndex].checklists.length; i++) {
            const checklistname = { [`checklists-${phaseIndex}-${i}`]: cpPhases[phaseIndex].checklists[i].name }
            form.setFieldsValue(checklistname);
        }
    };

    const getItemStyleChecklist = (isDragging, draggableStyle) => {
        return {
            userSelect: "none",
            display: "inline-flex",
            lineHeight: '35px',
            width: '100%',
            background: isDragging ? '#f5f5f5' : '#ffffff',
            ...draggableStyle
        };
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? '#f5f5f5' : '#ffffff',
        ...draggableStyle
    });

    const onCloseWorkflow = () => {
        setAutoFocusInput(false)
        form.resetFields(['name', []])
        setLoading(false)
        setDrawerWidth(0)
        if (!topicStore.isShowTopicEditor && !topicStore.isTopic3DLocationVisible && !topicStore.isTopic3DObjectsVisible && !topicStore.isDrawer3DViewVisible){
            workflowStore.clearWorkflowList()
            projectTeamsStore.clearTeamList();
        }
        setPhases([])
        setIsDragEnd(undefined)
        setSelectedWorkflow(false)
        setWorkflowOptions([])

        workflowStore.setShowWorkflowDrawer(false);
        projectSettingStore.toggleProjectSettingDrawer(true); //Show again project setting drawer    
    };

    /**
     * Reset workflow form
     */
    const onClickResetHandler = async () => {
        form.resetFields(['name', []])
        setPhases([{ name: '', users: [], checklists: [{ name: '' }] }]);
        setSelectedWorkflow(false)
        setIsDragEnd(undefined)
    }

    /**
     * Function change drop Autocomplete
     * @param {*} val 
     * @param {*} option 
     */
    const onSelectWorkflow = (option) => {
        setAutoFocusInput(false)
        let _wobj = workflowStore.workflowList.find(x => x.id === option.key)
        setSelectedWorkflow(_wobj)
    };
    /**
     * Save new workflow
     * @param {*} name 
     */
    const onSaveNewClickHandler = (name) => {
        form.validateFields().then((values) => {
            if(checkDuplicateWorkflow(workflowOptions, values.name)) {
                message.error(t('workflow-name-already-exists')) 
                return
            }
            values = {
                name,
                description: values.description,
                user: usersStore.currentUser._id,
                project: projectStore.projectDetail._id,
                workflowsphase:
                    phases.map((phase, phaseIndex) => {
                        return {
                            name: values[`phase-${phaseIndex}`],
                            order: phaseIndex,
                            assigntopersons: values[`users-${phaseIndex}`],
                            workflowsphasechecklist:
                                phases[phaseIndex].checklists.map((checklist, checklistIndex) => {
                                    return {
                                        order: checklistIndex,
                                        name: values[`checklists-${phaseIndex}-${checklistIndex}`]
                                    }
                                }),
                        }
                    }),
            }
            projectStore.setLoadingProgress(true)
            workflowStore.createWorkflow(values).then(() => {
                projectStore.setLoadingProgress(false)
                message.success(t('workflow-insert-successfull'))
                workflowStore.getWorkflowList(projectStore.projectDetail._id).then(response => {
                    let options = workflowStore.workflowList.map((item) => {
                        return {
                            key: item.id,
                            value: item.name,
                            label: item.name
                        }
                    })
                    setWorkflowOptions(options)
                    options.forEach(el => el.value === values.name && onSelectWorkflow(el))
                })
                form.setFieldsValue({name: name})
                setAddWorkFlowModal(false)
                if(addWorkflowRef?.current){addWorkflowRef.current.resetFields()}
            }).catch(error => {
                projectStore.setLoadingProgress(false)
            });
        }).catch(err => {
            message.error(t('phase-name-cannot-be-emptied'))
            if(!selectedWorkflow) form.setFieldsValue({name: ''})
            else form.setFieldsValue({name: selectedWorkflow.name})
        })
    }

    /**
     * Update workflow
     * @param {*} name 
     */
    const onUpdateClickHandler = (name) => {
        form.validateFields().then(async (values) => {
            let _wf = {
                name,
                description: values.description,
                topics: !selectedWorkflow.topics ? [] 
                :selectedWorkflow.topics.map(topic=>{
                    return {
                        id : topic.id,
                        name : topic.name,
                        _id : topic._id,
                        workflow : topic.workflow,
                    }
                }) ,
                workflowsphase:
                    phases.map((phase, phaseIndex) => {
                        return {
                            id: phase.id ? phase.id : undefined,
                            name: values[`phase-${phaseIndex}`],
                            order: phaseIndex,
                            assigntopersons: values[`users-${phaseIndex}`] ? values[`users-${phaseIndex}`] : [],
                            workflowsphasechecklist:
                                phases[phaseIndex].checklists.map((checklist, checklistIndex) => {
                                    return {
                                        id: checklist.id ? checklist.id : undefined,
                                        name: values[`checklists-${phaseIndex}-${checklistIndex}`],
                                        order: checklistIndex,
                                        workflowsphase: checklist.workflowsphase
                                    }
                                }),
                            topicphases:
                                phases[phaseIndex].topicphases.map((topicphase, topicphaseIndex) => {
                                    return {
                                        id: topicphase.id ? topicphase.id : undefined,
                                        name: values[`phase-${phaseIndex}`],
                                        order: phaseIndex,
                                        workflowsphase: topicphase.workflowsphase,
                                        assigntopersons: values[`users-${topicphaseIndex}`] ? values[`users-${topicphaseIndex}`] : [],
                                        topic: topicphase.topic,
                                        isActive: topicphase.isActive ? topicphase.isActive : false,
                                        checklist:
                                            phases[phaseIndex].checklists.map((topicphasechecklist, topicphasechecklistIndex) => {
                                                return {
                                                    id: topicphasechecklist.id ? topicphasechecklist.id : undefined,
                                                    name: values[`checklists-${phaseIndex}-${topicphasechecklistIndex}`],
                                                    order: topicphasechecklistIndex,
                                                    workflowsphasechecklist: topicphasechecklist?.id,
                                                    isUnCheck: !topicphase.checklists || !topicphase.checklists[topicphasechecklistIndex] || topicphase.checklists[topicphasechecklistIndex].isUnCheck ? true : false
                                                }
                                            }),
                                    }
                                }),
                        }
                    }),
            }
            projectStore.setLoadingProgress(true)
            await workflowStore.updateWorkflow(selectedWorkflow.id, _wf).then((res) => {
                setAddWorkFlowModal(false)
                if(addWorkflowRef?.current){addWorkflowRef.current.resetFields()}
                topicStore.getLocationTopicsByProject(projectStore.projectDetail._id)
                workflowStore.getWorkflowList(projectStore.projectDetail._id).then(async response => {
                    let options = workflowStore.workflowList.map((item) => {
                        return {
                            key: item.id,
                            value: item.name,
                            label: item.name
                        }
                    })
                    options.forEach(el => el.key === res.id && onSelectWorkflow(el))
                    setWorkflowOptions(options)
                    projectStore.setLoadingProgress(false)
                    message.success(t('workflow-updated-successfull'))
                    form.setFieldsValue({name: name})
                })
            }).catch(err => {
                message.error(t('update-workflow-failed'))
                projectStore.setLoadingProgress(false)
            });
        })
    }

    /**
     * Delete workflow
     */
    const deleteWorkflowHandler = () => {
        if (selectedWorkflow && selectedWorkflow.id) {
            projectStore.setLoadingProgress(true)
            workflowStore.deleteWorkflow(selectedWorkflow.id).then((res) => {
                projectStore.setLoadingProgress(false)
                onClickResetHandler()
                message.success(t('workflow-name-deleted-successfully', { name: res.data.name }))
                workflowStore.getWorkflowList(projectStore.projectDetail.id).then(response => {
                    let options = workflowStore.workflowList.map((item) => {
                        return {
                            key: item.id,
                            value: item.name,
                            label: item.name
                        }
                    })
                    setWorkflowOptions(options)
                    // updateTopicLocactions()
                    // projectStore.setLoadingProgress(false)
                    // onClickResetHandler()
                    // message.success(t('workflow-name-deleted-successfully', { name: res.data.name }))
                })
            }).catch(err => {
                message.error(t('delete-workflow-failed'))
                projectStore.setLoadingProgress(false)
            });
        }
    }

    const checkDuplicateWorkflow = (l, record) =>{
        return l?.length > 0 && l.some(el => el.value === record && selectedWorkflow?.name !==record)
    } 

    const openChangeWorkflowModal = () => {
        form.validateFields().then(res => {
            setAddWorkFlowModal(true)
            if(selectedWorkflow ){
                form.setFieldsValue({newWfName: selectedWorkflow.name})
            }
        })
    }

    const handleSaveWorkflow = () => {
        form.validateFields().then((values) => {
        const newName = values.newWfName
        if(!newName) return alert(t('workflow-name-cannot-be-emptied'))
        if(selectedWorkflow ){ // update exist wf
            onUpdateClickHandler(newName)
        }else{ // create new wf
            onSaveNewClickHandler(newName)
        }
        })
    }

    const onCloseModalWF = () => {
        setAddWorkFlowModal(false)
        form.resetFields(['newWfName'])
    }

    return (
        <Drawer
            title={
                <div className="help-btn-wrapper">
                    {t('workflow')}
                    <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={"workflow"} />
                        </div>
                    </Tooltip>
                </div>
            }
            placement="left"
            width={drawerWidth}
            closable={true}
            onClose={e => onCloseWorkflow()}
            visible={workflowStore.showWorkflowDrawer}
            maskClosable={false}
            mask={false}
            destroyOnClose={true}
            bodyStyle={{ marginRight: 10 }}
            className={workflowStore.showWorkflowDrawer ? " workflow-drawer custom-wraper-splitPanel " : " workflow-drawer "}
            footer={
                <div style={{ textAlign: 'right', marginRight: 10 }} className='button-wapper'>
                    <GlobalStylePopconfirm ismobile={isMobile || isTablet}/>
                    <Skeleton loading={loading} active>
                        {
                            projectStore.isExistLicenses && (
                                <Button style={{ marginRight: 8 }} type="primary" onClick={onClickResetHandler}>
                                {t('commons.new')}
                                </Button>
                            )
                        }
                        {
                            projectStore.isExistLicenses && (
                                <Button style={{ marginRight: 8 }} type="primary" onClick={openChangeWorkflowModal}>
                                {t('commons.save')}
                                </Button>
                            )
                        }
                        {
                            selectedWorkflow && selectedWorkflow.id ? <>
                                <Popconfirm
                                    placement={'topRight'}
                                    okText={t('commons.ok')} cancelText={t('commons.cancel')} okType={'danger'} onConfirm={deleteWorkflowHandler}
                                    title={
                                        selectedWorkflow && selectedWorkflow?.topics?.length > 0 ?
                                        t('workflow-is-in-use-in-one-or-more-Topics')
                                        :t('are-you-sure-you-want-to-delete-this-workflow')
                                    }
                                    className='popconfirm-workflow'
                                >
                                    <Button type="primary" danger style={{ marginRight: 8 }} disabled={selectedWorkflow && selectedWorkflow.id ? false : true}>
                                        {t('commons.delete')}
                                    </Button>
                                </Popconfirm>
                            </> : ''
                        }
                    </Skeleton>
                </div>
            }
        >
            <WorkflowWrapper>
                {isTabletOrMobile ? (
                    <div className="splitpanel-mobile-left">
                        <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                            <ColumnWidthOutlined />
                        </Button>
                    </div>
                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-left" />)
                }
                <Skeleton loading={loading} active>
                    <WorkflowContent>
                        <Form
                            form={form}
                            name="workflow-form"
                            layout="vertical"
                        >
                            <Form.Item
                                name="name"
                                label={t('load')}
                                labelAlign="left"
                                rules={[
                                    {
                                        required: false,
                                        message: t('workflow-name-cannot-be-emptied'),
                                    },
                                    {
                                        validator: validator.validateEmptyString
                                    }
                                ]}
                            >
                                <AutoComplete
                                    backfill={true}
                                    dropdownMatchSelectWidth={false}
                                    style={{ width: "100%" }}
                                    options={workflowOptions}
                                    onSelect={(val, option) => onSelectWorkflow(option)}
                                    allowClear
                                    onClear={() => setSelectedWorkflow(false)}
                                >
                                    <Input suffix={<DownOutlined />} placeholder={t('input-workflow-name')} style={{ color: '#919699' }} />
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item
                                label={t('description')}
                                name="description"
                                initialValue={selectedWorkflow?.description}
                                rules={[
                                    {
                                        required: false
                                    }
                                ]}
                            >
                                <TextArea placeholder={t('please-input-workflow-description')} rows={6} />
                            </Form.Item>
                            <Divider></Divider>
                            <Row>
                                <Col span={24} className="dragcontainer">
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId='droppable_phase' direction='horizontal' type="dragphase">
                                            {(provided, snapshot) => (
                                                <>
                                                    <PhasesContainerStyle
                                                        ref={provided.innerRef}
                                                    // {...provided.droppableProps}
                                                    >
                                                        {phases.map((phase, phaseIndex) => {
                                                            return (
                                                                <Draggable
                                                                    draggableId={`dragablePhase${phaseIndex}`}
                                                                    index={phaseIndex}
                                                                    key={`phase${phaseIndex}`}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <PhaseItemContainerStyle
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <Fragment>
                                                                                <HeadingPhase {...provided.dragHandleProps}>
                                                                                    <Text>{t('phase-name')}</Text> {phases.length > 1 ? <Button icon={<MinusOutlined />} onClick={() => removePhase(phaseIndex)} /> : ''}
                                                                                </HeadingPhase>
                                                                                <Form.Item
                                                                                    name={`phase-${phaseIndex}`}
                                                                                    initialValue={phases[phaseIndex].name}
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: t('phase-name-cannot-be-emptied')
                                                                                        },
                                                                                        {
                                                                                            validator: validator.validateEmptyString
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Input onChange={(e) => handlePhaseNameChange(e, phaseIndex)} />
                                                                                </Form.Item>
                                                                                <Text>{t('person-to-assign')}</Text>
                                                                                <Form.Item
                                                                                    name={`users-${phaseIndex}`}
                                                                                    //initialValue={phases[phaseIndex].users ? phases[phaseIndex].users.map(x => x.id) : []}
                                                                                    rules={[
                                                                                        {
                                                                                            required: false,
                                                                                            message: t('person-cannot-be-emptied')
                                                                                        }
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        showSearch
                                                                                        optionFilterProp="children"
                                                                                        notFoundContent={'No result'}
                                                                                        mode='multiple'
                                                                                        placeholder={t('person-to-assign')}
                                                                                        onSelect={() => handlePeopleChange(phaseIndex)}
                                                                                        onDeselect={(v) => handlePeopleChange(phaseIndex, v)}
                                                                                        filterOption={(input, option) =>
                                                                                            option.props.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }
                                                                                    >
                                                                                        {projectTeamsStore.teamList.filter(item => item?.email !== "maintenance@xd-visuals.fi")
                                                                                            .map((item) => {
                                                                                                return (item.inviteStatus === 'accepted' && item.user ? < Option key={item.user?._id} value={item.user?._id}>
                                                                                                    {
                                                                                                        item.user?.firstName && item.user?.lastName ?
                                                                                                            item.user.firstName + ' ' + item.user.lastName : !item.user?.firstName && item.user?.lastName ?
                                                                                                                item.user.lastName : item.user?.firstName && !item.user?.lastName ?
                                                                                                                    item.user.firstName : item.user.username
                                                                                                    }
                                                                                                </Option> : '')
                                                                                            })}
                                                                                    </Select>
                                                                                </Form.Item>
                                                                                <Text>{t('checklist')}</Text>
                                                                                <WorkflowChecklistContainer>
                                                                                    <Droppable droppableId={`droppable_checklist${phaseIndex}`} type={`${phaseIndex}`}>
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                            //style={getAnswerListStyle(snapshot.isDraggingOver)}
                                                                                            >
                                                                                                {phase.checklists == 0 ? <Button icon={<PlusOutlined />} onClick={() => addChecklistItem(phaseIndex)} /> : ''}
                                                                                                {phase.checklists.map((checklistitem, checklistIndex) => {
                                                                                                    return (

                                                                                                        <Draggable
                                                                                                            key={`phase${phaseIndex}-checklist${checklistIndex}`}
                                                                                                            draggableId={`dragableChecklist-${phaseIndex}-${checklistIndex}`}
                                                                                                            index={checklistIndex}
                                                                                                        >
                                                                                                            {(provided, snapshot) => (
                                                                                                                <>
                                                                                                                    <div
                                                                                                                        ref={provided.innerRef}
                                                                                                                        {...provided.draggableProps}
                                                                                                                        style={getItemStyleChecklist(
                                                                                                                            snapshot.isDragging,
                                                                                                                            provided.draggableProps.style
                                                                                                                        )}
                                                                                                                    >
                                                                                                                        <span {...provided.dragHandleProps}>
                                                                                                                            <SVGIcon content={<DragIcon className='icon-drag' />} width={20} height={20} />
                                                                                                                        </span>
                                                                                                                        <Form.Item>
                                                                                                                            <Form.Item
                                                                                                                                name={`checklists-${phaseIndex}-${checklistIndex}`}
                                                                                                                                key={`checklist${phaseIndex}${checklistIndex}`}
                                                                                                                                rules={[
                                                                                                                                    {
                                                                                                                                        required: false,
                                                                                                                                        message: t('checklist-is-required')
                                                                                                                                    },
                                                                                                                                    {
                                                                                                                                        validator: validator.validateEmptyString
                                                                                                                                    }
                                                                                                                                ]}
                                                                                                                                initialValue={checklistitem ? checklistitem.name : ''}
                                                                                                                            >
                                                                                                                                <Input autoFocus={autoFocusInput} ref={checklistInput} onChange={(e) => handleChecklistChange(e, phaseIndex, checklistIndex)} onKeyDown={(e) => handleChecklistInputKeyDown(e, phaseIndex)} />
                                                                                                                            </Form.Item>



                                                                                                                            {phase.checklists.length > 1 ? <Button icon={<MinusOutlined />} onClick={() => removeCheckListItem(phaseIndex, checklistIndex)} /> : ''}

                                                                                                                        </Form.Item>

                                                                                                                    </div>
                                                                                                                    {checklistIndex === phase.checklists.length - 1 ? <Button icon={<PlusOutlined />} onClick={() => addChecklistItem(phaseIndex)} /> : ''}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </Draggable>
                                                                                                    );
                                                                                                })}
                                                                                                {provided.placeholder}
                                                                                            </div>
                                                                                        )}
                                                                                    </Droppable>
                                                                                </WorkflowChecklistContainer>
                                                                            </Fragment>
                                                                        </PhaseItemContainerStyle>
                                                                    )
                                                                    }
                                                                </Draggable>
                                                            )
                                                        })}
                                                        {provided.placeholder}
                                                    </PhasesContainerStyle>
                                                    <Button icon={<PlusOutlined />} onClick={addPhase} />
                                                </>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Col>
                            </Row>
                            <Modal
                                title={selectedWorkflow ? t('update-workflow') : t('new-workflow')}
                                visible={addWorkFlowModal}
                                zIndex={9999}
                                onCancel={onCloseModalWF}
                                maskClosable={false}
                                footer={
                                    <Row justify="end">
                                        <Button
                                            key="back"
                                            icon={<CloseCircleOutlined />}
                                            onClick={onCloseModalWF}>
                                            {t('commons.cancel')}
                                        </Button>
                                        <Button
                                            form="add-workflow"
                                            type="primary"
                                            key="save"
                                            icon={<SaveOutlined />}
                                            htmlType="submit"
                                            onClick={handleSaveWorkflow}>
                                            {t('commons.save')}
                                        </Button>
                                    </Row>
                                }>
                                <Form.Item
                                    label={t('name')}
                                    name="newWfName"
                                    rules={[
                                        {
                                            required: false,
                                            message: t('workflow-name-cannot-be-emptied'),
                                        },
                                        {
                                            validator: validator.validateEmptyString
                                        },
                                        {
                                            message: t('workflow-name-already-exists'),
                                            validator: (_, value) => {
                                                return checkDuplicateWorkflow(workflowOptions, value) ? Promise.reject('error') : Promise.resolve();
                                            }
                                        }
                                    ]}>
                                    <Input />
                                </Form.Item>
                            </Modal>
                        </Form>
                    </WorkflowContent>
                </Skeleton>
            </WorkflowWrapper>
        </Drawer>
    )
}

export default inject(
    'projectStore',
    'workflowStore',
    'usersStore',
    'projectSettingStore',
    'projectTeamsStore',
    'topicStore'
)(observer(DrawerWorkflow))
