const defaultSiteSettings = {
  pathPrefix: '',
  siteUrl: '',
  siteTitle: '',
  siteDescription: '',
  author: '',
  defaultLanguage: 'en',
  social: {
    facebook: '',
    twitter: '',
    linkedin: '',
  }
};

const commonConfig = {
  defaultColorTile: 'color("red")',
  saveCameraPosition: 60000,
  TOPICS: {
    TYPE: {
      1: 'Note',
      2: 'Comment',
      3: 'Idea',
      4: 'Task',
      5: 'Issue',
      6: 'Risk',
      7: "Requirement"
    },
    PRIORITY: {
      1: 'Low',
      2: 'Normal',
      3: 'High',
      4: 'Critical'
    },
    STATUS: {
      1: 'New',
      2: 'Don’t do',
      3: 'ToDo',
      4: 'Waiting',
      5: 'Doing',
      6: 'Done',
      7: 'Closed'
    }
  }
}

const licenseConfig = {
  licensesStatus: true,
  numberProject: 5,
  numberUser: 5,
  storageQuota: 0.5,
  timeLimit: 15,
  licensesType: "Free trial",
  description: "Free trial - 14 days - 5 projects - 512 MB storage ",
}

const featureBlocked = ["feature_data_tree_edit", "feature_sketch", "feature_hide", "feature_project_setting", "feature_add_topic", "feature_topic_list", "feature_topic_report", "feature_project_dashboard", 'feature_topic_list_edit', "feature_topic_list_view", 'feature_projects','feature_custom_attribute_edit','feature_organization','feature_profile','feature_save_view_edit', 'feature_4d_gantt_edit']

const adminPageSizeOptions =  ['10', '20', '50', '100']

const settings = { defaultSiteSettings, commonConfig, licenseConfig ,featureBlocked ,adminPageSizeOptions };
export default settings;
