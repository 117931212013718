import styled from 'styled-components'

export const DrawerHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 35px;
  .anticon:hover {
    cursor: pointer;
  }
`
export const DrawerContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 10px;
  .tileset-name {
    font-weight: 500;   
    color: #191919;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;   
  }
  .tile-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 230px;
  }
  .ant-table-tbody {
    tr {
      td {
        padding: 12px 16px;
        background: none;
        border: 0;
      }
      &:hover {
        box-shadow: none;
        // td {
        //   background-color: #fff !important;
        // }
      }
    }
  }
  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {    
    margin-left: 20px;
  }
`

export const DrawerContentHeader = styled.div`
  display: ${props => ((props.visible || props.visible === undefined) ? 'block' : 'none')};
  padding: 10px 20px
`
export const SettingHiddenTileControlStyle = styled.div`
  background-color: rgba(222, 222, 222, 0.75);
  padding: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 2px;
  overflow: auto;
  height: 97%;
  @media screen and (max-width: 768px) {
    padding: 8px;
  }
  .ant-btn {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
`
export const HiddenAreaListContent = styled.div`
  margin: 10px 20px;
  .ant-form-item {
    margin-bottom: 0;
  }
`

