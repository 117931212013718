import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Col, message, Popconfirm, Row, Table, Form, Input } from 'antd'
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import AdminTemplate from '../../../layout/AdminTemplate'
import axios from 'axios'
import { apiUrl } from './../../../../config'
import { CustomButton, CustomSearch, SearchLicenseWrapper } from './styled'
import {
  CustomTopTitle,
  CustomContentAdminWrapper,
  HeaderAdmin,
  PaginationAdmin,
} from '../style'
import { useTranslation } from 'react-i18next'
import { toJS } from 'mobx'
import settings from '../../../../siteConfig';
var cancelToken
const Licenses = props => {
  const { commonStore, organizationStore, adminStore } = props
  const { t } = useTranslation()
  const [isLoadingTableLicense, setLoadingTableLicense] = useState(
    organizationStore.isLoading
  )
  const history = useHistory()
  const [searchValue, setSearchValue] = useState();
  const [_limit, setLimit] = useState();
  const [_page, setPage] = useState();
  const [fetchURL, setFetchURL] = useState(true);
  const [form] = Form.useForm();
  const { queryParam } = useParams()

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    setLimit(parseInt(queryStringParams._limit))
    setPage(parseInt(queryStringParams._page))
    if (fetchURL && _page && _limit) {
      setLoadingTableLicense(true)
      adminStore.setCurrentPage(_page)
      adminStore.setCurrentLimit(_limit)
      adminStore
        .customGetLicenses(
          _limit,
          _page,
          searchValue ? searchValue.trim() : searchValue
        )
        .then(() => {
          setLoadingTableLicense(false)
        })
        .catch(() => {
          setLoadingTableLicense(false)
          adminStore.clearCustomListLicenses()
        })
    }
  }, [queryParam, _page, _limit, fetchURL])

  useEffect(() => {
    commonStore.setCurrentPage('admin/licenses')
    adminStore.clearStatus()
    const queryStringParams = queryString.parse(queryParam)
    if (queryStringParams.search) {
      setSearchValue(queryStringParams.search)
      form.setFieldsValue({
        searchLicense: queryStringParams.search,
      })
    }
  }, [])

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    if (!queryStringParams.search && !fetchURL) {
      setLoadingTableLicense(true)
      setSearchValue('')
      form.setFieldsValue({
        searchLicense: ''
      })
      handleSearchLicenses('')
    }
  }, [queryParam])

  const handleRedirectEditLicenseForm = () => {
    adminStore.setRiderect(true)
    adminStore.setEditMode(true)
    adminStore.setCreateMode(false)
  }

  const handleDeleteLicense = async id => {
    setLoadingTableLicense(true)
    adminStore
      .deleteLicense(id)
      .then(res => {
        let _licenses = adminStore.licenses.filter(c => c.id !== res.data.id)
        adminStore.setLicense(_licenses)
        setLoadingTableLicense(false)
        adminStore.setRiderect(false)
        message.success(t('delete-license-successfully'))
      })
      .catch(() => {
        message.error(t('delete-license-failed'))
        setLoadingTableLicense(false)
      })
  }

  const handleModeCreateOrganization = () => {
    adminStore.setRiderect(true)
    adminStore.setCreateMode(true)
    adminStore.setEditMode(false)
    return history.push('/admin/license/new')
  }

  const columns = [
    {
      title: t('license-types'),
      key: 'licensetype',
      dataIndex: 'licensetype',
      render: record => {
        return (
          <span
            style={{ textTransform: `${!record ? 'uppercase' : 'inherit'}` }}>
            {' '}
            {record ? record.name ? record.name : record[0]?.name : t('no-license-type')}
          </span>
        )
      },
    },
    {
      title: t('description'),
      key: 'description',
      dataIndex: 'description',
      render: record => {
        return <div>{record ? record : ''}</div>
      },
    },
    {
      title: t('organization'),
      key: 'organization',
      dataIndex: 'organization',
      render: record => {
        return <div>{record ? record.name ? record.name : record[0]?.name : ''}</div>
      },
    },
    {
      title: t('status'),
      key: 'isActive',
      dataIndex: 'isActive',
      render: record => {
        return <div>{record ? 'Active' : 'Inactive'}</div>
      },
    },
    {
      title: t('action'),
      dataIndex: '_id',
      key: 'event',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: record => {
        return (
          <Row type="flex" justify="center" key="action">
            <Link
              onClick={() => handleRedirectEditLicenseForm()}
              to={`/admin/license/${record}`}>
              <Button style={{ margin: 2, padding: '3px 7px' }}>
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              placement="top"
              title={t('are-you-sure-delete')}
              onConfirm={() => handleDeleteLicense(record)}
              okText={t('commons.yes')}
              cancelText={t('commons.no')}>
              <Button type="danger" style={{ margin: 2, padding: '3px 7px' }}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Row>
        )
      },
    },
  ]

  const handleSearchLicenses = async e => {
    const search = e ? e.trim() : e
    setFetchURL(false)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    if (search === '') {
      setSearchValue()
      history.push(`/admin/licenses/_limit=${_limit ? _limit : 10}&_page=${1}`)
    } else {
      setSearchValue(search)
      history.push(
        `/admin/licenses/_limit=${_limit ? _limit : 10
        }&_page=${1}&search=${encodeURIComponent(e)}`
      )
    }
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    cancelToken = axios.CancelToken.source()
    try {
      setLoadingTableLicense(true)
      const response = await axios.get(
        `${apiUrl}/licensesPaginate?_limit=${_limit}&_page=${1}${search && search !== '' ? '&search=' + search : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          cancelToken: cancelToken.token,
        }
      )
      if (response) {
        setLoadingTableLicense(false)
        adminStore.setCustomListLicenses(response.data)
      }
    } catch (error) {
      if (error) {
        setLoadingTableLicense(false)
        adminStore.clearCustomListLicenses()
        console.log(error)
      }
    }
  }

  const clearSearch = () => {
    setSearchValue('')
    setFetchURL(true)
    form.resetFields()
    setLimit(_limit ? _limit : 10)
    setPage(1)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    return history.push(
      `/admin/licenses/_limit=${_limit ? _limit : 10}&_page=${1}`
    )
  }

  const debounceCalculate = debounce(function (value) {
    handleSearchLicenses(value)
  }, 500)

  const onChange = e => {
    setFetchURL(false)
    debounceCalculate(e.target.value)
  }

  const handleChangePagination = (current, pageSize) => {
    setLimit(pageSize)
    setPage(current)
    setFetchURL(true)
    adminStore.setCurrentPage(current)
    adminStore.setCurrentLimit(pageSize)
    if (searchValue) {
      return history.push(
        `/admin/licenses/_limit=${pageSize}&_page=${current}&search=${searchValue.trim()}`
      )
    } else {
      return history.push(`/admin/licenses/_limit=${pageSize}&_page=${current}`)
    }
  }

  return (
    <AdminTemplate title={t('licenses')}>
      <CustomContentAdminWrapper
        nodata={adminStore.customListLicenses?.count || 0 ? false : true}>
        <HeaderAdmin>
          <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Col xs={24} sm={12}>
              <CustomTopTitle>
                <div className="title">{t('licenses')}</div>
                <div className="count">
                  {t('total-records-found', {
                    length: adminStore.customListLicenses?.count || 0,
                  })}
                </div>
              </CustomTopTitle>
            </Col>
            <Col span={24}>
              <Form form={form} layout="vertical">
                <SearchLicenseWrapper>
                  <CustomSearch>
                    <Form.Item name="searchLicense">
                      <Input
                        onChange={onChange}
                        placeholder={t('search-for-an-entry')}
                        prefix={<SearchOutlined />}></Input>
                    </Form.Item>
                  </CustomSearch>
                  {searchValue && (
                    <CustomButton onClick={() => clearSearch()}>
                      <CloseOutlined />
                    </CustomButton>
                  )}
                </SearchLicenseWrapper>
              </Form>
            </Col>
            {/* <Col span={12}>
                            <Button
                                style={{ float: "right" }}
                                type="primary"
                                onClick={() => handleModeCreateOrganization()}
                            >
                                <PlusOutlined style={{ marginRight: "5px" }} />
                                New licenses
                            </Button>
                        </Col> */}
          </Row>
        </HeaderAdmin>
        <PaginationAdmin>
          <Table
            rowKey={record => record.id}
            size="default"
            bordered
            scroll={{ x: 1000 }}
            loading={isLoadingTableLicense}
            columns={columns}
            dataSource={adminStore.customListLicenses?.data || []}
            pagination={{
              defaultPageSize: _limit,
              showSizeChanger: true,
              current: _page,
              pageSizeOptions: settings.adminPageSizeOptions,
              onChange: (page, pageSize) =>
                handleChangePagination(page, pageSize),
              total: adminStore.customListLicenses?.count || 0,
              locale: { items_per_page: '' },
              position: ['topRight'],
            }}
            locale={{ emptyText: t('no-data') }}
            onRow={record => ({
              onClick: event => { },
            })}
          />
        </PaginationAdmin>
      </CustomContentAdminWrapper>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore'
)(observer(Licenses))
