import React, { useEffect, useState } from 'react'
// import { Helmet, HelmetProvider } from 'react-helmet-async'
import DefaultTemplate from '../../layout/DefaultTemplate'
import { inject, observer } from 'mobx-react'
import { Viewer, Cesium3DTileset, CameraFlyTo, Entity } from 'resium'
import {
  IonResource,
  Cartesian3,
  Transforms,
  HeadingPitchRange,
  Cesium3DTileColorBlendMode,
  Cesium3DTileStyle,
} from 'cesium'
import './style.less'
import { assetUrl, apiUrl, corsproxy } from '../../../config'
import DrawerTilesetExplorer from './DrawerTilesetExplorer'
import DrawerCaptureViews from './DrawerCaptureViews'
// import { toJS } from 'mobx'
import SEO from '../../layout/SEO'

const ProjectDetailPage = ({
  commonStore,
  projectStore,
  capturesStore,
  ...props
}) => {
  const [tilesets, setTilesets] = useState([])
  const [currentTile, setCurrentTile] = useState(null)
  const [allModelLoaed, setAllModelLoaed] = useState(false)
  const [flyToPos, setFly] = useState(false)
  const [orientation, setorientation] = useState(false)
  const viewerRef = React.createRef()

  const onTileLoad = tile => {
    //if (tile) tile.style = new Cesium3DTileStyle({ pointSize: 8 })
  }

  useEffect(() => {
    commonStore.setCurrentPage('projectShare')
    projectStore.getProjectDetail(props.match.params.projectId)
    return () => {
      projectStore.setSelectedModel(false)
      setAllModelLoaed(false)
      setTilesets([])
      capturesStore.setClickAdd(false)
      capturesStore.setCameraData(false)
      setCurrentTile(null)
      setorientation(false)
      setFly(false)
    }
  }, [])

  useEffect(() => {
    if (currentTile && currentTile.content && currentTile.ref.current) {
      if (viewerRef.current) {
        const viewer = viewerRef.current.cesiumElement
        // setFly(false)
        capturesStore.setCameraData(false)
        if (
          currentTile.type === 'ifc' ||
          currentTile.type === 'cad' ||
          currentTile.type === 'ion' ||
          currentTile.type === 'kmz' ||
          currentTile.type === 'model3d'
        ) {
          let tilePosition = new Cartesian3.fromDegrees(0, 0, 300)
          if (currentTile.crs) {
            if (currentTile.crs.transform) {
              tilePosition = new Cartesian3.fromDegrees(
                currentTile.crs.transform.lng,
                currentTile.crs.transform.lat,
                300
              )
            } else if (currentTile.crs.position) {
              Cartesian3.fromElements(
                currentTile.crs.position.x,
                currentTile.crs.position.y,
                300,
                tilePosition
              )
            }
          }
          setFly(tilePosition)
        } else {
          viewer
            .zoomTo(
              currentTile.ref.current.cesiumElement,
              new HeadingPitchRange(0.0, -0.5, 300)
            )
            .then(() => {
              getCurrentCam()
            })
        }

        projectStore.setDisplayPanel(false)
        // if (currentTile.type != 'ifc' && !currentTile.loaded) projectStore.setLoadingProgress(true)
      }
    }
    // return () => {
    //   setCurrentTile(null)
    // }
  }, [currentTile])

  const renderAllTile = () => {
    let tiles = []
    projectStore.modelList.forEach((m, index) => {
      let tile = { id: m.id, loaded: false }
      TilesetByModel(m, tile)
      tiles.push(tile)
      // if (m.type == 'model3d' || m.type == 'cloudpoint') {
      //   if (m.data.location == undefined) {
      //     moveToLocation(m.id, { long: 26.881997, lat: 60.501649, height: 0 })
      //   }
      // }
    })
    setTilesets(tiles)
  }

  const tilesetClick = model => {
    if (!tilesets) return
    var tile = tilesets.find(item => item.id === model.id)
    if (tile) {
      tile.type = model.type
      tile.crs = model.crs
      setCurrentTile(tile)
    }
  }

  useEffect(() => {
    if (!capturesStore.cameraData) {
      setFly(false)
      setorientation(false)
      return
    }
    let cam = capturesStore.cameraData
    if (cam.position) {
      let position = new Cartesian3(
        cam.position.x,
        cam.position.y,
        cam.position.z
      )
      setFly(position)
    }

    if (cam.direction) {
      let direction = new Cartesian3(
        cam.direction.x,
        cam.direction.y,
        cam.direction.z
      )
      let up = new Cartesian3(cam.up.x, cam.up.y, cam.up.z)
      setorientation({ direction, up })
    }

    // let fly = {
    //   destination: position,
    //   orientation: {
    //     direction: direction,
    //   },
    // }
  }, [capturesStore.cameraData])

  const getCurrentCam = () => {
    if (viewerRef.current != null) {
      let camera = viewerRef.current.cesiumElement.camera
      let camData = {}
      camData.position = {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
      }
      camData.direction = {
        x: camera.direction.x,
        y: camera.direction.y,
        z: camera.direction.z,
      }
      camData.up = {
        x: camera.up.x,
        y: camera.up.y,
        z: camera.up.z,
      }
      // camData.heading = camera.heading
      // camData.pitch = camera.pitch
      // camData.roll = camera.roll
      let newCapture = {
        name: 'Viewpoint ' + capturesStore.captureList.length + 1,
        cameraData: camData,
        project: props.match.params.projectId,
      }
      capturesStore.setCameraData(camData)
      return newCapture
    }
    return false
  }

  useEffect(() => {
    if (capturesStore.clickAdd) {
      let cam = getCurrentCam()
      if (cam) {
        // capturesStore.setCameraData(cam.cameraData)
        capturesStore.addCaptureViews(cam)
        capturesStore.setClickAdd(false)
      }
    }
  }, [capturesStore.clickAdd])

  useEffect(() => {
    if (projectStore.zoomClick) {
      if (projectStore.selectedModel) {
        let tile = tilesets.find(
          item => item.id === projectStore.selectedModel.id
        )
        if (!tile) {
          projectStore.setZoomClick(false)
          return
        }
        if (!tile.ref.current) {
          projectStore.setZoomClick(false)
          return
        }
        if (viewerRef.current) {
          const viewer = viewerRef.current.cesiumElement
          viewer.zoomTo(tile.ref.current.cesiumElement)
        }
      }
      projectStore.setZoomClick(false)
    }
  }, [projectStore.zoomClick])

  function TilesetByModel(model, tile) {
    // console.log('asset', assetUrl + model.hash + '/tileset.json')
    tile.ref = React.createRef()
    let tilePosition = new Cartesian3.fromDegrees(0, 0, 0)
    if (model.crs) {
      if (model.crs.transform) {
        tilePosition = new Cartesian3.fromDegrees(
          model.crs.transform.lng,
          model.crs.transform.lat,
          model.crs.transform.height
        )
      } else if (model.crs.position) {
        Cartesian3.fromElements(
          model.crs.position.x,
          model.crs.position.y,
          model.crs.position.z,
          tilePosition
        )
      }
    }

    switch (model.type) {
      case 'landxml':
      case 'landxmlBackground':
        tile.content = (
          <Cesium3DTileset
            key={model.id}
            url={assetUrl + model.hash + '/tileset.json'}
            ref={tile.ref}
            onReady={onTileLoad}
            colorBlendMode={Cesium3DTileColorBlendMode.REPLACE}
          />
        )
        break
      case 'ifc':
        let ifc_uri = apiUrl + '/assets/output/' + model.hash + '.glb'
        tile.content = (
          <Entity
            key={model.id}
            model={{
              uri: ifc_uri,
              show: true,
            }}
            position={tilePosition}
            ref={tile.ref}
          />
        )
        break
      case 'model3d':
        tile.content = (
          <Cesium3DTileset
            key={model.id}
            url={model.data.srchTileset}
            ref={tile.ref}
            onReady={onTileLoad}
            modelMatrix={Transforms.eastNorthUpToFixedFrame(tilePosition)}
          />
        )
        break
      case 'cloudpoint':
        tile.content = (
          <Cesium3DTileset
            key={model.id}
            url={model.data.srchTileset}
            ref={tile.ref}
            onTileLoad={onTileLoad}
            modelMatrix={Transforms.eastNorthUpToFixedFrame(tilePosition)}
          />
        )
        break
      default:
        break
    }
    if (tile.content) return tile
    if (model.sourceType === 'external') {
      if (model.data.ionAssetId) {
        tile.content = (
          <Cesium3DTileset
            key={model.id}
            url={IonResource.fromAssetId(model.data.ionAssetId)}
            maximumScreenSpaceError={1}
            maximumNumberOfLoadedTiles={1000}
            ref={tile.ref}
            onReady={onTileLoad}
          />
        )
      } else {
        tile.content = (
          <Cesium3DTileset
            key={model.id}
            url={corsproxy + model.src}
            maximumScreenSpaceError={1}
            maximumNumberOfLoadedTiles={1000}
            ref={tile.ref}
            onReady={onTileLoad}
          />
        )
      }
    }
    return tile
  }

  useEffect(() => {
    if (!allModelLoaed && projectStore.modelList.length > 0) {
      projectStore.setLoadingProgress(true)
      setAllModelLoaed(true)
      renderAllTile()
      projectStore.setLoadingProgress(false)
    }
  }, [projectStore.modelList])

  useEffect(() => {
    if (projectStore.selectedModel) tilesetClick(projectStore.selectedModel)
  }, [projectStore.selectedModel])

  return (
    <DefaultTemplate>
      <SEO
        title={
          projectStore.projectDetail
            ? 'Project | ' + projectStore.projectDetail.name
            : 'Project | Open Bim Tool'
        }
        imageurl={
          projectStore.projectDetail
            ? projectStore.projectDetail.thumbnail
              ? projectStore.projectDetail.thumbnail.url
              : null
            : null
        }
      />
      <DrawerTilesetExplorer />
      <DrawerCaptureViews />
      <React.Fragment>
        <Viewer
          timeline={false}
          homeButton={false}
          navigationInstructionsInitiallyVisible={false}
          navigationHelpButton={false}
          selectionIndicator={false}
          infoBox={false}
          style={{ height: 'calc(100vh)' }}
          ref={viewerRef}>
          {tilesets.length > 0
            ? tilesets.map(t => (t.content ? t.content : ''))
            : ''}
          {flyToPos ? (
            <CameraFlyTo
              duration={2}
              destination={flyToPos}
              orientation={orientation}
            />
          ) : (
            ''
          )}
        </Viewer>
      </React.Fragment>
    </DefaultTemplate>
  )
}

export default inject(
  'commonStore',
  'projectStore',
  'capturesStore'
)(observer(ProjectDetailPage))
