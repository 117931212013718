import styled from 'styled-components'

export const ListStyle = styled.div`
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
`

export const CustomTopTitle = styled.div`
float:left;
.title{
  font-weght:600;
  font-size:20px;
  color: #000000;
}
.count{
  font-size: 13px;
  color: #787e8f;
}
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
    padding: 0 14px;
  }
`

export const ModalStyle = styled.div`
  .inlinelayout {
    display: flex !important;
    flex-flow: row wrap !important;

    label{
      height: 32px !important;
      padding-right: 10px;
    }
  }
`