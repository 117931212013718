import { toJS } from 'mobx';
import sketchingStore from '../../../../stores/sketchingStore'
var Cesium = require('cesium')

const CesiumDrawing = {}

CesiumDrawing.extendEntity = function (entity) {
    entity.startEdit = new Cesium.Event();
    entity.stopEdit = new Cesium.Event();
    entity.edited = new Cesium.Event();
    entity.startEditing = function () {
        entity.startEdit.raiseEvent(entity);
    };

    entity.stopEditing = function () {
        entity.stopEdit.raiseEvent(entity);
    };

    entity.onEdit = function (editPoint) {
        entity.edited.raiseEvent(entity, editPoint);
    }
};

CesiumDrawing.Editor = function (viewer) {
    this.viewer = viewer;
    this.initializeDraggerHandler();
};

CesiumDrawing.Editor.prototype.startEditing = function (entity) {
    entity.startEditing();

    if (entity.polygon && entity.drawData === 'area') {
        entity.editor = new CesiumDrawing.PolygonEditor(this, entity);
    }
    if (entity && entity.drawData === 'line') {
        entity.editor = new CesiumDrawing.SketchLineEditor(this, entity);
    }
    if (entity.cylinder && entity.drawData === 'point') {
        entity.editor = new CesiumDrawing.SketchSingleEditor(this, entity);
    }
    this.currentEditEntity = entity
};

CesiumDrawing.Editor.prototype.stopEditing = function (entity, onStopEdit) {
    entity.stopEditing();

    if (entity.editor) {
        entity.editor.destroy();
        entity.editor = null;
    }
    // Mark the position properties as being constant since we are done editing.
    // You will see a flash as the geometry rebuilds, but rendering performance of the static geometries will
    // be faster.
    if (entity.polyline) {
        entity.polyline.positions.isConstant = true;
    }
    else if (entity.polygon) {
        entity.polygon.hierarchy.isConstant = true;
    }
    else if (entity.corridor) {
        entity.corridor.positions.isConstant = true;
    }
    if (onStopEdit) {
        onStopEdit(entity)
    }
};

CesiumDrawing.Editor.prototype.createDragger = function (options) {
    const self = this;
    var position = Cesium.defaultValue(options.position, Cesium.Cartesian3.ZERO).clone();
    var onDrag = Cesium.defaultValue(options.onDrag, null);
    var dragger = self.viewer.entities.add({
        position: position,
        point: {
            pixelSize: 18,
            color: Cesium.Color.RED,
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 2
        },
    })

    dragger._isDragger = true;
    dragger.onDrag = onDrag;
    dragger.horizontal = Cesium.defaultValue(options.horizontal, true);
    dragger.vertical = Cesium.defaultValue(options.vertical, false);
    dragger.verticalCtrl = Cesium.defaultValue(options.vertical, false);

    return dragger;
};

CesiumDrawing.Editor.prototype.initializeDraggerHandler = function () {
    const self = this;
    // Create the handler.
    var draggerHandler = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas);

    // Initialize the active dragger to null;
    draggerHandler.dragger = null;

    // Left down selects a dragger
    draggerHandler.setInputAction(
        function (click) {
            try {
                var pickedObjects = self.viewer.scene.drillPick(click.position);
                if (Cesium.defined(pickedObjects)) {
                    const getDragger = function (pickedObjects) {
                        for (let i = 0; i < pickedObjects.length; i++) {
                            const pickedObject = pickedObjects[i];
                            if (!pickedObject?.id || pickedObject?.id._isDragger)
                                return pickedObject
                        }
                    }
                    const pickedObject = pickedObjects ? getDragger(pickedObjects) : undefined
                    if (Cesium.defined(pickedObject)) {
                        var entity = pickedObject.id;
                        if (entity && Cesium.defaultValue(entity._isDragger, false)) {
                            draggerHandler.dragger = entity;
                            self.viewer.scene.screenSpaceCameraController.enableRotate = false;
                            self.viewer.scene.screenSpaceCameraController.enableTilt = false;
                            self.viewer.scene.screenSpaceCameraController.enableTranslate = false;
                        }
                    }
                }
            } catch (error) {

            }
        },
        Cesium.ScreenSpaceEventType.LEFT_DOWN
    );

    // Mouse move drags the draggers and calls their onDrag callback.
    draggerHandler.setInputAction(
        function (movement) {
            if (draggerHandler.dragger) {
                if (draggerHandler.dragger.horizontal) {
                    var hit = self.viewer.scene.pickPosition(movement.endPosition);
                    if (hit) {
                        draggerHandler.dragger.position = hit;
                        if (draggerHandler.dragger.onDrag) {
                            draggerHandler.dragger.onDrag(draggerHandler.dragger, hit);
                            if (draggerHandler.dragger.positions) {
                                let positions = draggerHandler.dragger.positions
                                positions[draggerHandler.dragger.index] = hit;
                                sketchingStore.setSketchProps({ pointDragger: positions })
                            }
                        }
                    }
                }

                if (draggerHandler.dragger.vertical) {
                    var dy = movement.endPosition.y - movement.startPosition.y;
                    var position = draggerHandler.dragger.position._value;
                    var tangentPlane = new Cesium.EllipsoidTangentPlane(position);

                    scratchBoundingSphere.center = position;
                    scratchBoundingSphere.radius = 1;

                    var metersPerPixel = self.viewer.scene.frameState.camera.getPixelSize(scratchBoundingSphere,
                        self.viewer.scene.frameState.context.drawingBufferWidth,
                        self.viewer.scene.frameState.context.drawingBufferHeight);

                    var zOffset = new Cesium.Cartesian3();

                    Cesium.Cartesian3.multiplyByScalar(tangentPlane.zAxis, -dy * metersPerPixel, zOffset);
                    var newPosition = Cesium.Cartesian3.clone(position);
                    Cesium.Cartesian3.add(position, zOffset, newPosition);

                    draggerHandler.dragger.position = newPosition;
                    if (draggerHandler.dragger.onDrag) {
                        draggerHandler.dragger.onDrag(draggerHandler.dragger, newPosition);
                    }
                }
            }
        },
        Cesium.ScreenSpaceEventType.MOUSE_MOVE
    );

    var scratchBoundingSphere = new Cesium.BoundingSphere();

    // Mouse move drags the draggers and calls their onDrag callback.
    draggerHandler.setInputAction(
        function (movement) {
            if (draggerHandler.dragger && draggerHandler.dragger.verticalCtrl) {
                var dy = movement.endPosition.y - movement.startPosition.y;
                var position = draggerHandler.dragger.position._value;
                var tangentPlane = new Cesium.EllipsoidTangentPlane(position);

                scratchBoundingSphere.center = position;
                scratchBoundingSphere.radius = 1;

                var metersPerPixel = self.viewer.scene.frameState.camera.getPixelSize(scratchBoundingSphere,
                    self.viewer.scene.frameState.context.drawingBufferWidth,
                    self.viewer.scene.frameState.context.drawingBufferHeight);

                var zOffset = new Cesium.Cartesian3();

                Cesium.Cartesian3.multiplyByScalar(tangentPlane.zAxis, -dy * metersPerPixel, zOffset);
                var newPosition = Cesium.Cartesian3.clone(position);
                Cesium.Cartesian3.add(position, zOffset, newPosition);

                draggerHandler.dragger.position = newPosition;
                if (draggerHandler.dragger.onDrag) {
                    draggerHandler.dragger.onDrag(draggerHandler.dragger, newPosition);
                }
            }
        },
        Cesium.ScreenSpaceEventType.MOUSE_MOVE,
        Cesium.KeyboardEventModifier.CTRL
    );


    // Left up stops dragging.
    draggerHandler.setInputAction(
        function () {
            if (draggerHandler.dragger) {
                draggerHandler.dragger = null;
                self.viewer.scene.screenSpaceCameraController.enableRotate = true;
                self.viewer.scene.screenSpaceCameraController.enableTilt = true;
                self.viewer.scene.screenSpaceCameraController.enableTranslate = true;
            }
        },
        Cesium.ScreenSpaceEventType.LEFT_UP
    );

    // Left up stops dragging.
    draggerHandler.setInputAction(
        function () {
            if (draggerHandler.dragger) {
                draggerHandler.dragger = null;
                self.viewer.scene.screenSpaceCameraController.enableRotate = true;
                self.viewer.scene.screenSpaceCameraController.enableTilt = true;
                self.viewer.scene.screenSpaceCameraController.enableTranslate = true;
            }
        },
        Cesium.ScreenSpaceEventType.LEFT_UP,
        Cesium.KeyboardEventModifier.CTRL
    );

    this.draggerHandler = draggerHandler;
};

/**
 * Polygon editor.
 */
CesiumDrawing.PolygonEditor = function (editor, entity) {
    const self = this;
    this.editor = editor;
    this.entity = entity;
    this.draggers = [];
    var virtualPositions = entity._originalPoints || entity?.polygon?.hierarchy?._value?.positions
    const originalPos = virtualPositions.map(x => x.clone())
    var entityPositions = entity?.polygon?.hierarchy?._value?.positions || virtualPositions
    // entity.polygon.hierarchy.isConstant = false;
    const onCancel = function () {

        for (let i = 0; i < originalPos.length; i++) {
            const loc = originalPos[i];
            entity._originalPoints[i] = loc
            entity.polygon.hierarchy._value.positions[i] = loc
        }
        entity.onEdit();
        self.destroy();
    }
    entity.onCancel = onCancel
    // entity.polygon.hierarchy.isConstant = false;

    entity.polygon.hierarchy.setCallback(entity.polygon.hierarchy._callback, false);
    for (let i = 0; i < virtualPositions.length; i++) {
        var loc = virtualPositions[i];

        var dragger = editor.createDragger({
            position: loc,
            onDrag: function (dragger, position) {
                dragger.positions[dragger.index] = position;
                entity.onEdit();
                entity._currentPoints = dragger.positions
            },
            positions: virtualPositions
        });
        dragger.index = i;
        dragger.positions = entityPositions;
        this.draggers.push(dragger);
    }
};

/**
 * Polygon editor.
 */
CesiumDrawing.SketchLineEditor = function (editor, entity) {
    const self = this;
    this.editor = editor;
    this.entity = entity;
    this.draggers = [];
    var virtualPositions = entity._originalPoints || entity?.polylineVolume?.positions?._value
    const originalPos = virtualPositions.map(x => x.clone())
    var entityPositions = entity?.polylineVolume?.positions?._value || virtualPositions
    const onCancel = function () {

        for (let i = 0; i < originalPos.length; i++) {
            const loc = originalPos[i];
            entity._originalPoints[i] = loc
            entity.polylineVolume.positions._value[i] = loc
        }
        entity.onEdit();
        self.destroy();
    }
    // entity.polygon.hierarchy.isConstant = false;
    entity.onCancel = onCancel

    if (entity.polyline) {
        if (entity?.polyline?.positions) {
            entity.polyline.positions.setCallback(entity.polyline.positions._callback, false);
        }
    }
    if (entity.polylineVolume) {
        if (entity?.polylineVolume?.positions) {
            entity.polylineVolume.positions.setCallback(entity.polylineVolume.positions._callback, false);
        }
        if (entity?.polylineVolume?.shape) {
            entity.polylineVolume.shape.setCallback(entity.polylineVolume.shape._callback, false);
        }
    }
    if (entity.wall) {
        if (entity?.wall?.positions) {
            entity.wall.positions.setCallback(entity.wall.positions._callback, false);
        }
        if (entity?.wall?.maximumHeights) {
            entity.wall.maximumHeights.setCallback(entity.wall.maximumHeights._callback, false);
        }
        if (entity?.wall?.minimumHeights) {
            entity.wall.minimumHeights.setCallback(entity.wall.minimumHeights._callback, false);
        }
    }
    if (entity.corridor) {
        if (entity?.corridor?.positions) {
            entity.corridor.positions.setCallback(entity.corridor.positions._callback, false);
        }
    }
    for (let i = 0; i < virtualPositions.length; i++) {
        var loc = virtualPositions[i];

        var dragger = editor.createDragger({
            position: loc,
            onDrag: function (dragger, position) {
                dragger.positions[dragger.index] = position;
                entity.onEdit();
                entity._currentPoints = dragger.positions
            },
            positions: virtualPositions
        });
        dragger.index = i;
        dragger.positions = entityPositions;
        this.draggers.push(dragger);
    }
};

CesiumDrawing.SketchLineEditor.prototype.destroy = function () {
    for (var i = 0; i < this.draggers.length; i++) {
        this.editor.viewer.entities.remove(this.draggers[i]);
    }
    this.draggers = [];
};

CesiumDrawing.PolygonEditor.prototype.destroy = function () {
    for (var i = 0; i < this.draggers.length; i++) {
        this.editor.viewer.entities.remove(this.draggers[i]);
    }
    this.draggers = [];
};

CesiumDrawing.SketchSingleEditor = function (editor, entity) {
    // debugger
    const self = this;
    this.editor = editor;
    this.entity = entity;
    this.draggers = [];
    var virtualPositions = entity._originalPoints || entity?.position?._value
    const originalPos = virtualPositions.map(x => x.clone())
    var entityPositions = entity?.position?._value || virtualPositions
    const onCancel = function () {

        for (let i = 0; i < originalPos.length; i++) {
            const loc = originalPos[i];
            entity._originalPoints[i] = loc
            entity.position._value[i] = loc
        }
        entity.onEdit();
        self.destroy();
    }
    entity.onCancel = onCancel
    entity.position.setCallback(entity.position._callback, false);
    for (let i = 0; i < virtualPositions.length; i++) {
        var loc = virtualPositions[i];

        var dragger = editor.createDragger({
            position: loc,
            onDrag: function (dragger, position) {
                dragger.positions[dragger.index] = position;
                entity.onEdit();
                entity._currentPoints = dragger.positions
            },
            positions: virtualPositions
        });
        dragger.index = i;
        dragger.positions = entityPositions;
        this.draggers.push(dragger);
    }
};

CesiumDrawing.SketchSingleEditor.prototype.destroy = function () {
    for (var i = 0; i < this.draggers.length; i++) {
        this.editor.viewer.entities.remove(this.draggers[i]);
    }
    this.draggers = [];
};

export default CesiumDrawing
