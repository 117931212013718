import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Table } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ChangeRoleUserInProject from './ChangeRoleUserInProject';
import InviteUserToProject from './InviteUserToProject';
import { TableHeading, TableWrapper } from './style';
import { Trans, useTranslation } from 'react-i18next';

const ProjectUsers = props => {
  const { ProjectUserDataList, projectTeamsStore, adminStore, commonStore } = props;
  const [userDataList, setUserDataList] = useState([])
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)

  useEffect(() => {
    setUserDataList(ProjectUserDataList)
  }, [ProjectUserDataList])

  const handleClickRemoveUserFromProjectInOrganization = (projectuserrolesId) => {
    adminStore.setLoadingProgress(true)
    adminStore.removeUserFromProject(projectuserrolesId).then(res => {
      const index = userDataList.findIndex(x => x.id === projectuserrolesId)
      const temp = [...userDataList]
      temp.splice(index, 1)
      setUserDataList(temp)
      adminStore.setLoadingProgress(false)
      message.success(t('remove-user-successfully'))
    }).catch(error => {
      adminStore.setLoadingProgress(false)
      message.error(t('remove-user-failed-Please-try-again'))
      return;
    })
  }

  const tableColumns = [
    {
      title: t('user'),
      dataIndex: 'user',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a && a.username ? a.username.localeCompare(b.username) : true,
      render: record => {
        return (
          record && record?.firstName && record?.lastName ?
            record.firstName + ' ' + record.lastName : !record?.firstName && record?.lastName ?
              record.lastName : record?.firstName && !record?.lastName ?
                record.firstName : record && record?.username ?
                  record.username : t('user-not-found')
        )
      }
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: "email",
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t('role'),
      dataIndex: 'projectrole',
      sortDirections: ['descend', 'ascend'],
      render: record => {
        return (record.roleName)
      }
    },
    {
      title: t('created'),
      width: 170,
      key: "createdAt",
      render: record => {
        return (
          <div className={'tileset-name'}>{moment(record.createdAt).format('MMMM DD, YYYY')}</div>
        )
      }
    },
    {
      title: t('action'),
      key: 'action',
      width: 120,
      align: 'center',
      render: (record) => {
        let showButton = false
        let orgRole = adminStore.roleCurrentUserInOrganization.role
        let prjRole = record.projectrole.role
        showButton = (prjRole && prjRole === "project_owner" && record.user) ? false : true
        if (orgRole && orgRole === 'Admin') {
          showButton = true
        }
        return <>
          {
            showButton ? (
              <>
                <Button
                  onClick={() => adminStore.setShowChangeRoleUserModal(true)}
                  icon={<EditOutlined />} style={{ marginRight: '5px' }} />
                <Popconfirm
                  title={t('are-you-sure-remove-this-user-from-this-project')}
                  onConfirm={() => handleClickRemoveUserFromProjectInOrganization(record.id)}
                  okText={t('commons.yes')}
                  cancelText={t('commons.no')}
                >
                  <Button danger icon={<DeleteOutlined />} />
                </Popconfirm>
              </>
            ) : ''
          }
        </>
      }
    }
  ]

  const hanldeInviteMember = () => {
    projectTeamsStore.toggleShowAddTeamMemberModal(true)
  }
  return (
    <TableWrapper>
      <TableHeading>
        <div className="title">{t('team-members')}</div>
        <Button type={'primary'} onClick={() => hanldeInviteMember()}>
          <PlusOutlined />{t('invite-member')}
        </Button>
      </TableHeading>
      <Table
        scroll={{ x: 700 }}
        pagination={false}
        rowKey={record => record.id}
        columns={tableColumns}
        dataSource={userDataList}
        onRow={(record) => ({
          onClick: (event) => {
            return adminStore.setCurrentUserRoleDetailInProject(record)
          },
        })}
        locale={{ emptyText: t('no-data') }}
      />
      <InviteUserToProject />
      <ChangeRoleUserInProject userDataList={userDataList} />
    </TableWrapper>
  )
}
export default withRouter(inject('projectTeamsStore', "adminStore", 'commonStore')(observer(ProjectUsers)))