import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { inject, observer } from 'mobx-react'
import CityDemoTemplate from '../../../layout/CityDemoTemplate'
import ModelDemoViewer from '../ModelDemoViewer'

const ModelTampere = () => {
  return (
    <HelmetProvider>
      <CityDemoTemplate>
        <Helmet>
          <title>Tampere | OpxD Twin Viewer</title>
        </Helmet>
        <ModelDemoViewer modelSlug={'tampere'} />
      </CityDemoTemplate>
    </HelmetProvider>
  )
}

export default inject('commonStore')(observer(ModelTampere))
