import { Button, Card, Col, Icon, Row, Spin, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import '../../../assets/cesium-navigation/index.css'
// import uuid from 'uuid'
import { calculateWH } from '../../helper/CesiumUtils'
import ArTemplate from '../../layout/ArTemplate'
import AddArTestModal from './AddArTestModal'
import AddCalibrationModal from './AddCalibrationModal'
import ARDiff from './ARDiff'
import ARLineMatch from './ARLineMatch'
import ArModelViewer from './ArModelViewer'
import AROpacity from './AROpacity'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { isMobile, isTablet } from 'react-device-detect'

const { Meta } = Card

const StatPanel = styled.div`
  display: block;
  padding: 3px 10px;
  background: #ddd;
  width:100%;
  height:35px;
`

const StatContent = styled.div`
  background: #ccc;  
  margin:3px 0;
  padding:3px 10px;
  width:100%;
  height:26px;
  border-radius:4px;
  color:black;
`

const ArDataPage = props => {
  const {
    commonStore,
    projectStore,
    arStore,
  } = props
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const arDivContainer = useRef(null)
  const [CamStyle, setCamStyle] = useState({})

  const [ArTestByCalb, setArTestByCalb] = useState([])
  const [chooseArModal, setChooseArModal] = useState(false)


  useEffect(() => {
    window.scrollTo(0, 0)
    arStore.getAllArTest()
    arStore.getAllCalibration()
    return () => {
    }
  }, [])


  useEffect(() => {
    console.log('arStore.openAdArTestModal', arStore.openAdArTestModal)
    if (arStore.openAdArTestModal)
      setChooseArModal(<AddArTestModal arByCalb={ArTestByCalb} />)
    else
      setChooseArModal(false)
    return () => {
      setChooseArModal(false)
    }
  }, [arStore.openAdArTestModal])


  useEffect(() => {
    if (!arStore.currentCalibration) return
    if (arStore.arPanel !== 'calibration') return
    var t = []
    arStore.currentCalibration.data.artests.forEach(ar => {
      const index = arStore.arTestList.ar.findIndex(x => x.id === ar.id)
      if (index > -1) {
        t.push(
          {
            id: ar.id,
            ar: arStore.arTestList.ar[index],
            result: ar.result
          }
        )
      }
    });
    setArTestByCalb(t)
  }, [arStore.currentCalibration, arStore.arPanel])

  useEffect(() => {
    if (arStore.currentTestData) {
      let csize = arStore.currentTestData.modelRenderInfo.CanvasSize
      var s = calculateWH(csize.width, csize.height, arDivContainer.current.clientWidth / 2, arDivContainer.current.clientHeight)
      if (s.height > s.width) s.width = "auto"
      else s.height = "auto"
      s.maxHeight = arDivContainer.current.clientWidth / 2
      setCamStyle(s)
    }
  }, [arStore.currentTestData])

  const ArListByCalbContent = () => {
    return (
      <>
        {
          ArTestByCalb.map((ab, index) => {
            let item = ab.ar
            return (
              <Col key={index} xs={24} sm={12} md={4}>
                <Card
                  cover={
                    <img
                      style={{
                        width: '100%', maxHeight: 200, objectFit: 'cover'
                      }}
                      alt={item.name}
                      src={
                        item.imageModelRender.url
                      }
                    />
                  }
                  actions={
                    [
                      <Button
                        size={'small'}
                        type={'primary'}
                        onClick={() => {
                          // arStore.setCurrentCalbAr(arStore.currentCalibration.id)
                          arStore.setCurrentTestData(item)
                          arStore.setArPanel('default')
                          arStore.setViewType('match_line')
                        }}>
                        <CheckCircleOutlined />
                        Detail
                      </Button>,
                      <Button size={'small'} danger ghost onClick={() => {
                        arStore.deleteArTestFromCalb(ab.id, arStore.currentCalibration.id)
                      }}>
                        <CloseCircleOutlined />
                        Delete
                      </Button>]
                  }>
                  <Tooltip placement={'topLeft'} title={item.name} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                    <Meta
                      title={item.name}
                      description={
                        <>
                          {ab.result.Status === 'OK' ?
                            <>
                              <span style={{ marginLeft: 0 }}>Angle H: </span>
                              <span style={{ color: "green" }}>{ab.result.Angle[0]}</span>
                              <br />
                              Angle V: <span style={{ color: "green" }}>{ab.result.Angle[1]}</span>
                              <br />
                              Angle Tilt: <span style={{ color: "green" }}>{ab.result.Angle[2] ? ab.result.Angle[2] : "?"}</span>
                            </> :
                            <>
                              Result : <span style={{ color: "red" }}>Not found Angle!</span>
                              <br />
                              <br />
                            </>
                          }
                        </>
                      }
                    />

                  </Tooltip>
                </Card>
              </Col>
            )
          })
        }
      </>
    )
  }



  // const ArTestContent = ({ visible }) =>
  //   <Row
  //     style={{
  //       display: visible ? "block" : "none"
  //     }}
  //   >
  //     <Col span={12} style={{ padding: 8 }}>
  //       {arStore.currentTestData && arStore.currentTestData.photoDevice ? <img style={CamStyle} src={(arStore.show3DModel) ? arStore.currentTestData.imageModelRender.url : arStore.currentTestData.photoDevice.url} />
  //         :
  //         ""}
  //     </Col>
  //     <Col span={12} style={{ padding: 8 }}>
  //       {arStore.currentProjectId && arDivContainer.current ?
  //         <ArModelViewer
  //           containerWidth={arDivContainer.current.clientWidth}
  //           containerHeight={arDivContainer.current.clientHeight} />
  //         :
  //         ""}
  //     </Col>
  //   </Row >

  return (
    <HelmetProvider>
      <ArTemplate>
        <Helmet
          title='AR Test | 6DPlanner'
        />
        <AddCalibrationModal />
        {chooseArModal}
        {/* {arStore.openAdArTestModal ? <AddArTestModal /> : ""} */}

        {/* <AddArTestModal /> */}
        <div style={{
          display: (arStore.arPanel === "calibration") ? "block" : "none",
          padding: 10,
          backgroundColor: "#ddd",
          height: 'calc(100vh - 55px)'
        }}>
          <Spin spinning={arStore.isLoading}>
            <ArListByCalbContent />
          </Spin>
        </div>
        <div style={{ display: (arStore.arPanel === "default") ? "block" : "none" }}>
          <StatPanel>
            <StatContent>
              {arStore.currentStatText}
            </StatContent>
          </StatPanel>
          <div
            style={{
              padding: 10,
              backgroundColor: "#ddd",
              height: 'calc(100vh - 95px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            ref={arDivContainer}
          >
            {/* <ArTestContent visible={arStore.viewType === 'default'} /> */}
            <Spin spinning={arStore.isLoading}>
              <Row
                type="flex" justify="space-around" align="middle"
                style={{
                  display: arStore.viewType === 'default' ? "block" : "none"
                }}
              >
                <Col span={12} style={{ padding: 8 }}>
                  {arStore.currentProjectId && arDivContainer.current ?

                    <ArModelViewer
                      containerWidth={arDivContainer.current.clientWidth}
                      containerHeight={arDivContainer.current.clientHeight} />

                    :
                    ""}
                </Col>
                <Col span={12} style={{ padding: 8 }}>
                  {arStore.currentTestData && arStore.currentTestData.photoDevice && arStore.currentTestData.photoDevice.url && arStore.currentTestData.imageModelRender ? <img style={CamStyle} src={(arStore.show3DModel) ? arStore.currentTestData.imageModelRender.url : arStore.currentTestData.photoDevice.url} />
                    :
                    ""}
                </Col>
              </Row>
            </Spin>

            {arStore.viewType === 'match_line' ?
              <ARLineMatch clientWidth={arDivContainer.current.clientWidth} clientHeight={arDivContainer.current.clientHeight} /> : ""
            }
            {arStore.viewType === 'opacity' ?
              <AROpacity clientWidth={arDivContainer.current.clientWidth} clientHeight={arDivContainer.current.clientHeight} /> : ""
            }
            {arStore.viewType === 'filter' ?
              <ARDiff clientWidth={arDivContainer.current.clientWidth} clientHeight={arDivContainer.current.clientHeight} /> : ""
            }
            {/* <Row>
            <Col span={12} style={{ padding: 8 }}>
              {arStore.currentTestData && arStore.currentTestData.photoDevice ? <img style={CamStyle} src={arStore.show3DModel ? arStore.currentTestData.imageModelRender.url : arStore.currentTestData.photoDevice.url} />
                :
                ""}
            </Col>
            <Col span={12} style={{ padding: 8 }}>
              {arStore.currentProjectId && arDivContainer.current ? <ArModelViewer
                containerWidth={arDivContainer.current.clientWidth}
                containerHeight={arDivContainer.current.clientHeight} /> : ""}
            </Col>
          </Row> */}
          </div>
        </div>
      </ArTemplate >
    </HelmetProvider>
  )
}

export default inject(
  'commonStore',
  'projectStore',
  'schedulingStore',
  'capturesStore',
  'arStore'
)(observer(ArDataPage))
