import React, { useEffect, useState, useRef, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, InputNumber, Cascader, Modal, Switch, Input } from 'antd'
import Konva from "konva"
import axios from 'axios'
import {
  calculateWH
} from '../../helper/CesiumUtils'

const ARDiff = ({ arStore, clientWidth, clientHeight, pairs }) => {

  // const [imgCount, setImgCount] = useState(0)



  // function reset() {
  //   points = []
  //   halfWidth = clientWidth / 2
  //   imgPos = [0, 0, halfWidth, 0]
  //   imgScaleX = [1, 1]
  //   imgScaleY = [1, 1]
  //   imgCount = 0
  // }
  // useEffect(() => {

  //   return () => {

  //   }
  // }, [arStore.currentTestData])


  useEffect(() => {
    // if (!arStore.currentTestData || !arStore.currentCalbAr) return
    var points = []
    var radius = false
    var halfWidth = clientWidth / 2
    var imgPos = [0, 0, halfWidth, 0]
    var imgScaleX = [1, 1]
    var imgScaleY = [1, 1]
    var imgCount = 0
    if (arStore.currentTestData===false) return
    if (arStore.currentTestData.modelRenderInfo.calibration) {
      points = arStore.currentTestData.modelRenderInfo.calibration.Points
      if (arStore.currentTestData.modelRenderInfo.calibration.Radius)
        radius = arStore.currentTestData.modelRenderInfo.calibration.Radius
    }
    if (arStore.currentCalbAr !== 'cal-0') {
      let findCalId = arStore.calibrations.findIndex(x => x.id === arStore.currentCalbAr)
      if (findCalId > -1) {
        let findArId = arStore.calibrations[findCalId].data.artests.findIndex(x => x.id === arStore.currentTestData.id)
        if (findArId > -1) {
          let result = arStore.calibrations[findCalId].data.artests[findArId].result
          if (result.Status === 'OK') {
            points = result.Points
            if (result.Radius)
              radius = result.Radius
          }
          else
            points = false
        }
      }
    }

    if (!points) {
      alert('no matching data')
      points = []
    }
    var stage = new Konva.Stage({
      container: "kv-canvas",
      width: clientWidth,
      height: clientHeight
    });

    var layer = new Konva.Layer();
    var lineLayer = new Konva.Layer()
    // var dragLayer = new Konva.Layer();
    stage.add(layer);
    stage.add(lineLayer);


    // console.log('arStore.currentTestData', arStore.currentTestData)
    let csize = arStore.currentTestData.modelRenderInfo.CanvasSize
    var s = calculateWH(csize.width, csize.height, halfWidth, clientHeight)



    function drawMatchLine(myLayer, x1, y1, x2, y2, color, radius1, radius2) {
      var c = "black"
      if (!color) {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        c = "rgb(" + r + "," + g + "," + b + " )"
      } else {
        c = color
      }
      var myLine = new Konva.Line({
        points: [x1 * imgScaleX[0] + imgPos[0], y1 * imgScaleY[0] + imgPos[1], x2 * imgScaleX[1] + imgPos[2], y2 * imgScaleY[1] + imgPos[3]],
        // points: [5, 70, 140, 23, 250, 60, 300, 20],
        stroke: c,
        strokeWidth: 2,
        lineJoin: 'round',
      });
      myLayer.add(myLine);
    }

    function drawAllLine() {
      for (let index = 0; index < points.length / 4; index++) {
        var ii = index * 4
        var r1 = false
        var r2 = false
        if (radius) {
          r1 = radius[index * 2]
          r2 = radius[index * 2 + 1]
        }
        // drawMatchLine(lineLayer, points[ii], points[ii + 1], points[ii + 2], points[ii + 3], false)
        drawMatchLine(lineLayer, points[ii + 2], points[ii + 3], points[ii], points[ii + 1], false, r2, r1)
      }
      lineLayer.batchDraw();
    }

    function addImage(url, imgLayer, index) {
      var imageObj = new Image();
      imageObj.src = url;
      imageObj.onload = function () {
        this.image = imageObj;
        var ratio = this.image.width / this.image.height
        var imgOptions = {
          scaleX: 1,
          scaleY: 1,
          image: imageObj,
          opacity:0.1
        }
        if (s.height > s.width) {
          imgOptions.height = s.height
          imgOptions.width = s.height * ratio
        }
        else {
          imgOptions.width = s.width
          imgOptions.height = s.width / ratio
        }

        imgPos[index] = (clientWidth - imgOptions.width) / 2
        imgPos[index + 1] = (clientHeight - imgOptions.height) / 2

        imgOptions.x = imgPos[index]
        imgOptions.y = imgPos[index + 1]

        imgScaleX[index / 2] = imgOptions.width / this.image.width
        imgScaleY[index / 2] = imgOptions.height / this.image.height

        var kImg = new Konva.Image(imgOptions);
        // add the shape to the layer
        imgLayer.add(kImg);
        imgLayer.batchDraw();
        imgCount++
        if (imgCount == 2) {
          drawAllLine()
        }
      };
    }
    addImage(arStore.currentTestData.imageModelRender.url, layer, 0)
    addImage(arStore.currentTestData.photoDevice.url, layer, 2)

    return () => {
      points = []
      radius = []
      halfWidth = clientWidth / 2
      imgPos = [0, 0, halfWidth, 0]
      imgScaleX = [1, 1]
      imgScaleY = [1, 1]
      imgCount = 0
      stage.destroy()
    }
  }, [arStore.currentTestData, arStore.currentCalbAr])

  return (
    <div>
      <div id="kv-canvas" style={{ width: clientWidth, height: clientHeight }}>
      </div>
    </div>
  )
}
export default inject('arStore')(observer(ARDiff))
