import { Avatar, Button, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { isMobile, isTablet ,isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { ReactComponent as NavigationBackIcon } from '../../../../assets/svgs/navigation-back.svg';
import { ReactComponent as NavigationHomeIcon } from '../../../../assets/svgs/navigation-home.svg';
import SVGIcon from '../../../elements/SVGIcon';
import { ToolbarTopContainer, ToolbarTopCustom } from './styled';
import { toJS } from 'mobx';

const ToolbarTopRight = props => {
  const {
    currentPage,
    projectStore,
    viewer
  } = props
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const { t } = useTranslation();

  const  [isHover, setIsHover] = useState(false)
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState(false);

useEffect(() => {

  const handleHover = () => {
    setIsHover(true)
  };

  const handleHoverLeave = () => {
    setIsHover(false)
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleInput = (event) => {
    const userInputValue = event.target.value;
    setSearchValue(userInputValue)
  };

  const geocoderContainer = document.querySelector('.cesium-viewer-geocoderContainer');

  if (geocoderContainer) {
    geocoderContainer.addEventListener('mouseenter', handleHover);
    geocoderContainer.addEventListener('mouseleave', handleHoverLeave);
  }

  const inputField = document.querySelector('.cesium-viewer-geocoderContainer .cesium-geocoder-input');
    if (inputField) {
      inputField.addEventListener('focus', handleFocus);
      inputField.addEventListener('blur', handleBlur);
      inputField.addEventListener('input', handleInput);
    }

  return () => {
    if (geocoderContainer) {
      geocoderContainer.removeEventListener('mouseenter', handleHover);
      geocoderContainer.removeEventListener('mouseleave', handleHoverLeave);
    }
    if (inputField) {
      inputField.removeEventListener('focus', handleFocus);
      inputField.removeEventListener('blur', handleBlur);
      inputField.removeEventListener('input', handleInput);
      setSearchValue()
    }
  };
}, []);

  const viewByPage = () => {
    switch (currentPage) {
      case 'projectDetail':
        return projectStore.projectDetail?.id ? (
          <Fragment>
              <Avatar.Group 
              className='desktop-style'
              style={{ display: 'inline-flex' }}
              >
                <ToolbarTopCustom activated={0}>
                  {(( currentPage === 'projectDetail' ) && projectStore?.pastLocations?.length > 0 && projectStore.cameraLoadFinish) &&
                    <Tooltip title={t('back')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                      <Button
                        style={{ verticalAlign: 0 }}
                        icon={<SVGIcon content={<NavigationBackIcon />} width={32} height={32} />}
                        onClick={()=>projectStore.backToPastLocation(viewer)}
                      />
                    </Tooltip>
                  }
                  {(( currentPage === 'projectDetail') ) &&
                    <Tooltip title={t('commons.home')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                      <Button
                        style={{ verticalAlign: 0 }}
                        icon={<SVGIcon content={<NavigationHomeIcon />} width={32} height={32} />}
                        onClick={()=>projectStore.backToHome(viewer)}
                      />
                    </Tooltip>
                  }
                </ToolbarTopCustom>
              </Avatar.Group>
          </Fragment>
        ) : ''
      default:
        break;
    }
  }


  return (
    <React.Fragment>
      <ToolbarTopContainer
        right={(isPortrait || isMobileOnly) ? 'mobile' : 'pc'}
        isHover={isHover || isFocused || searchValue}
        className = 'toolbar-topright'
        >
        {currentPage ? viewByPage() : ''}
      </ToolbarTopContainer>
    </React.Fragment>
  )
}

export default withRouter(
  inject(
    'projectStore'
  )(observer(ToolbarTopRight))
)
