import styled from 'styled-components'
export const CardWrapper = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: max-content;
`
export const CardBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
`
export const CardBodyContentCol = styled.div` 
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 15px;
    &:last-child {
      border-top: 1px solid #E3E5E5;
      padding-top:20px;
      margin-bottom: 0;
    }
  }
  .title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 25px;
  }
`
export const List = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  dt {
    width: 140px;
    position: relative;   
    .color-svg {
      position: absolute;
      left: 0;
    }
  }
  dd {
    width: calc(100% - 140px);
    padding-left: 15px;
    word-break: break-all;
    span {
      overflow: hidden;
      display: block;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
  dt, dd {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
export const TableWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px; 
`
export const TableHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  .title { 
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
  .action {
    display: flex;
    .ant-btn {
      height: 32px;
    }
  }
`
export const Toolsbox = styled.div`
  display: flex;
  .ant-btn {
    margin-left: 10px;
  }
`