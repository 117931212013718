import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, message, Slider, Switch, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpButton from '../elements/HelpButton';
import { SettingControlContainer } from './CustomStyled';
const Cesium = require('cesium')

const LightSetting = ({ projectStore, uiStore, commonStore, projectSettingStore, usersStore, viewer }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [advancedAtmosphere, setShowAdvancedAtmosphere] = useState(false);
    const [form] = Form.useForm();
    const { Option } = Select;

    useEffect(() => {
        let fetchData = async () => {
            let data = {}
            if (projectStore.projectDetail && usersStore.currentUser.id) {
                let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
                if (_systemProjectSetting.length > 0) {
                    let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)
                    if (!_currentUserSystemSetting) return
                    data = Object.assign(projectSettingStore.systemProjectSetting, _currentUserSystemSetting.projectSetting)
                } else {
                    data = projectSettingStore.systemProjectSetting
                }
                form.setFieldsValue({
                    color: data.lightSetting?.color,
                    intensitySunLight: !isNaN(data.lightSetting?.intensitySunLight) ? data.lightSetting?.intensitySunLight : 2.0,
                    intensitySkyLight: !isNaN(data.lightSetting?.intensitySkyLight) ? data.lightSetting?.intensitySkyLight : 5.0,
                    improvedAtmosphere: (data.lightSetting?.improvedAtmosphere) ? data.lightSetting?.improvedAtmosphere : false,
                    advanced : (data?.lightSetting?.advanced) ? data?.lightSetting?.advanced : false ,
                    activeLighting: data.lightSetting?.activeLighting || false,
                    enableShadows: data.enableShadows,
                    soft: data.renderResolution?.softShadows,
                    distance: data.renderResolution?.shadowDistance || 1000,
                    darkness: data.renderResolution?.shadowDarkness || 0.5,
                    accuracy: data.renderResolution?.shadowAccuracy || 4096,
                })
                if(data?.lightSetting?.advanced){
                    setShowAdvancedAtmosphere(true)
                }
            } else {
                data = projectSettingStore.systemProjectSetting
                form.setFieldsValue({
                    color: data.lightSetting?.color,
                    intensitySunLight: !isNaN(data.lightSetting?.intensitySunLight) ? data.lightSetting?.intensitySunLight : 2.0,
                    intensitySkyLight: !isNaN(data.lightSetting?.intensitySkyLight) ? data.lightSetting?.intensitySkyLight : 5.0,
                    improvedAtmosphere: (data.lightSetting?.improvedAtmosphere) ? data.lightSetting?.improvedAtmosphere : false,
                    advanced : (data.lightSetting?.advanced) ? data.lightSetting?.advanced : false ,
                    activeLighting: data.lightSetting?.activeLighting || false,
                    enableShadows: data.enableShadows,
                    soft: data.renderResolution?.softShadows,
                    distance: data.renderResolution?.shadowDistance || 1000,
                    darkness: data.renderResolution?.shadowDarkness || 0.5,
                    accuracy: data.renderResolution?.shadowAccuracy || 4096,
                })
                setShowAdvancedAtmosphere(false)
            }
        }
        fetchData()
        return () => {
            resetAtmosphereSettings()
            setShowAdvancedAtmosphere(false)
        }
    }, [])

    const onSubmit = () => {
        form.validateFields().then(res => {
            let resdata = {
                color: res.color,
                intensitySunLight: res.intensitySunLight,
                intensitySkyLight: res.intensitySkyLight,
                improvedAtmosphere: res.improvedAtmosphere,
                type: res.type,
            }
            projectSettingStore.setAssignObjSystemProjectSetting("enableShadows", res.enableShadows)
            projectSettingStore.setLightSetting(resdata)
            projectStore.setSoftShadows(res.soft)
            projectSettingStore.setRenderResolution('softShadows', res.soft)
            projectStore.setShadowDarkness(res.darkness)
            projectSettingStore.setRenderResolution('shadowDarkness', res.darkness)
            projectStore.setShadowDistance(res.distance)
            projectSettingStore.setRenderResolution('shadowDistance', res.distance)
            projectStore.setShadowAccuracy(res.accuracy)
            projectSettingStore.setRenderResolution('shadowAccuracy', res.accuracy)

            if (usersStore.currentUser?.id) {
                let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
                setLoading(true)
                projectStore.setLoadingProgress(true)
                projectStore.updateProjectMetadata({ metadata }).then(res => {
                    projectStore.projectDetail.metadata = res.metadata
                    setLoading(false)
                    projectStore.setLoadingProgress(false)
                    uiStore.setShowLightSetting(false)
                    projectStore.setCleanMode(false)
                    uiStore.setRefreshViewer(true)
                    message.success(t('updated-successfully'))
                }).catch(() => {
                    setLoading(false)
                    projectStore.setLoadingProgress(false)
                    message.error(t('updated-failed'))
                    projectStore.setCleanMode(false)
                    uiStore.setShowLightSetting(false)
                })
            }
        })
    }

    const onCancel = () => {
        uiStore.setShowLightSetting(false)
        projectStore.setCleanMode(false)
        let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
        if (_systemProjectSetting.length > 0) {
            let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)
            if (_currentUserSystemSetting?.projectSetting) {
                projectSettingStore.setLightSetting(_currentUserSystemSetting.projectSetting.lightSetting)
                commonStore.setenableShadows(_currentUserSystemSetting.projectSetting?.enableShadows)
                projectSettingStore.setAssignObjSystemProjectSetting("enableShadows", _currentUserSystemSetting.projectSetting?.enableShadows)
                projectStore.setSoftShadows(_currentUserSystemSetting.projectSetting?.renderResolution?.softShadows)
                projectSettingStore.setRenderResolution('softShadows', _currentUserSystemSetting.projectSetting?.renderResolution?.softShadows)
                projectStore.setShadowDarkness(_currentUserSystemSetting.projectSetting?.renderResolution?.shadowDarkness)
                projectSettingStore.setRenderResolution('shadowDarkness', _currentUserSystemSetting.projectSetting?.renderResolution?.shadowDarkness)
                projectStore.setShadowDistance(_currentUserSystemSetting.projectSetting?.renderResolution?.shadowDistance)
                projectSettingStore.setRenderResolution('shadowDistance', _currentUserSystemSetting.projectSetting?.renderResolution?.shadowDistance)
                projectStore.setShadowAccuracy(_currentUserSystemSetting.projectSetting?.renderResolution?.shadowAccuracy)
                projectSettingStore.setRenderResolution('shadowAccuracy', _currentUserSystemSetting.projectSetting?.renderResolution?.shadowAccuracy)
            } else {
                setLightDefault()
            }
        } else {
            setLightDefault()
        }
        resetAtmosphereSettings()
        uiStore.setRefreshViewer(true)
    }

    const setLightDefault = () => {
        projectSettingStore.setLightSetting({
            color: '#fffffa',
            intensitySunLight: 2.0,
            intensitySkyLight: 5.0,
            improvedAtmosphere: false,
            advanced : false
        })
        setShowAdvancedAtmosphere(false)
        projectSettingStore.setAssignObjSystemProjectSetting("enableShadows", false)
        commonStore.setenableShadows(false)
        projectStore.setSoftShadows(false)
        projectSettingStore.setRenderResolution('softShadows', false)
        projectStore.setShadowDarkness(0.5)
        projectSettingStore.setRenderResolution('shadowDarkness', 0.5)
        projectStore.setShadowDistance(1000)
        projectSettingStore.setRenderResolution('shadowDistance', 1000)
        projectStore.setShadowAccuracy(4096)
        projectSettingStore.setRenderResolution('shadowAccuracy', 4096)
        return true
    }

    const onChangeColor = (e) => {
        let _light = projectSettingStore.systemProjectSetting.lightSetting
        _light.color = e.target.value
        projectSettingStore.setLightSetting(_light)
        uiStore.setRefreshViewer(true)
    }

    const onChangeIntensitySkyLight = (value) => {
        let _light = projectSettingStore.systemProjectSetting.lightSetting
        _light.intensitySkyLight = value
        projectSettingStore.setLightSetting(_light)
        uiStore.setRefreshViewer(true)
    };

    const onChangeIntensitySunLight = (value) => {
        let _light = projectSettingStore.systemProjectSetting.lightSetting
        _light.intensitySunLight = value
        projectSettingStore.setLightSetting(_light)
        uiStore.setRefreshViewer(true)
    };

    const onChangeImprovedAtmosphere = (value) => {
        let _improvedAtmosphere = projectSettingStore.systemProjectSetting.lightSetting
        _improvedAtmosphere.improvedAtmosphere = value
        projectSettingStore.setLightSetting(_improvedAtmosphere)
        uiStore.setRefreshViewer(true)
    };


    const onChangeActiveShadows = (value) => {
        projectSettingStore.setAssignObjSystemProjectSetting("enableShadows", value)
        commonStore.setenableShadows(value)
        uiStore.setRefreshViewer(true)
    };

    const onChangeSoftShadowCheckbox = (value) => {
        projectStore.setSoftShadows(value)
        projectSettingStore.setRenderResolution('softShadows', value)
        uiStore.setRefreshViewer(true)
    }

    const onChangeShadowDarkness = value => {
        projectStore.setShadowDarkness(value)
        projectSettingStore.setRenderResolution('shadowDarkness', value)
        uiStore.setRefreshViewer(true)
    }

    const onChangeShadowDistance = value => {
        projectStore.setShadowDistance(value)
        projectSettingStore.setRenderResolution('shadowDistance', value)
        uiStore.setRefreshViewer(true)
    }

    const onChangeShadowAccuracy = value => {
        projectStore.setShadowAccuracy(value)
        projectSettingStore.setRenderResolution('shadowAccuracy', value)
        uiStore.setRefreshViewer(true)
    }

    const improvedAtmosphere = (value) => {
        onChangeImprovedAtmosphere(value);
    }

    const resetAtmosphereSettings = () => {
        return
        if (viewer && viewer.current && viewer.current.cesiumElement) {
            const scene = viewer.current.cesiumElement?.scene;
            const globe = scene?.globe;
            if (!globe) return;
            globe.enableLighting = false;
            uiStore.setRefreshViewer(true)
        }
    }

    const onChangeLighting = (value) => {
        let _brightnessShift = value ? 0.4 : 0.25;
        let _activeLighting = projectSettingStore.systemProjectSetting.lightSetting
        _activeLighting.activeLighting = value
        projectSettingStore.setLightSetting(_activeLighting)
        // if (viewer && viewer.current && viewer.current.cesiumElement) {
        //     const scene = viewer.current.cesiumElement?.scene;
        //     if (!scene) return;
        //     const skyAtmosphere = scene.skyAtmosphere;
        //     skyAtmosphere.brightnessShift = _brightnessShift;
        //     uiStore.setRefreshViewer(true);
        //     startReRender()
        // }
    };

    const onChangeAtmosphere = (value) => {
        setShowAdvancedAtmosphere(value);
        let _advanced = projectSettingStore?.systemProjectSetting?.lightSetting
        _advanced.advanced = value
        if (!value) {
            _advanced.improvedAtmosphere = value
            _advanced.activeLighting = value
            form.setFieldsValue({
                improvedAtmosphere : false,
                activeLighting : false
            })
        }
        projectSettingStore.setLightSetting(_advanced)
        uiStore.setRefreshViewer(true)
    }

    return (
        <SettingControlContainer>
            <div className='help-btn-wrap'>
                <HelpButton helppage={"system_settings_light"} />
            </div>
            <Form form={form} layout='horizontal' className='lightsetting'>
                <p><strong>{t('lighting-settings')}</strong></p>
                <p><strong>{t('skylight')}</strong></p>
                <Form.Item
                    label={t('intensity')}
                    className="form-vertical"
                    name="intensitySkyLight">
                    <Slider
                        style={{ width: 'auto' }}
                        min={0.0}
                        max={10.0}
                        step={0.1}
                        onChange={onChangeIntensitySkyLight}
                    />
                </Form.Item>
                <Form.Item
                    label={<div><strong>{t('advanced')}</strong></div>}
                    valuePropName="checked"
                    name="advanced">
                    <Switch
                        style={{ width: 'auto' }}
                        checkedChildren={t('commons.on')}
                        unCheckedChildren={t('commons.off')}
                        onChange={onChangeAtmosphere}
                    />
                </Form.Item>
                {
                    advancedAtmosphere && (
                        <div style={{paddingLeft : '10px'}}>
                            <Form.Item
                                label={t('dynamic-atmosphere')}
                                valuePropName="checked"
                                name="improvedAtmosphere">
                                <Switch
                                    style={{ width: 'auto' }}
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    onChange={improvedAtmosphere}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('globe-lighting')}
                                valuePropName="checked"
                                name="activeLighting">
                                <Switch
                                    style={{ width: 'auto' }}
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    onChange={onChangeLighting}
                                />
                            </Form.Item>
                        </div>
                    )
                }
                <p><strong>{t('sunlight')}</strong></p>
                <Form.Item
                    label={t('color')}
                    initialValue={'#ffffff'}
                    name="color">
                    <input type="color" className='custom-input-color' onChange={onChangeColor} />
                </Form.Item>
                <Form.Item
                    label={t('intensity')}
                    className="form-vertical"
                    name="intensitySunLight">
                    <Slider
                        style={{ width: 'auto' }}
                        min={0.0}
                        max={10.0}
                        step={0.1}
                        onChange={onChangeIntensitySunLight}
                    />
                </Form.Item>
                <p><strong>{t('shadows-settings')}</strong></p>
                <Form.Item
                    label={t('active')}
                    valuePropName="checked"
                    name="enableShadows">
                    <Switch
                        style={{ width: 'auto' }}
                        checkedChildren={t('commons.on')}
                        unCheckedChildren={t('commons.off')}
                        onChange={onChangeActiveShadows}
                    />
                </Form.Item>
                <Form.Item
                    label={t('soft')}
                    valuePropName="checked"
                    name="soft">
                    <Switch
                        style={{ width: 'auto' }}
                        checkedChildren={t('commons.on')}
                        unCheckedChildren={t('commons.off')}
                        onChange={onChangeSoftShadowCheckbox}
                    />
                </Form.Item>
                <Form.Item
                    label={t('darkness')}
                    className="form-vertical"
                    name="darkness">
                    <Slider
                        style={{ width: 'auto' }}
                        min={0.0}
                        max={1.0}
                        step={0.1}
                        onChange={onChangeShadowDarkness}
                    />
                </Form.Item>
                <Form.Item
                    label={t('distance')}
                    className="form-vertical"
                    name="distance">
                    <Slider
                        style={{ width: 'auto' }}
                        min={0}
                        max={10000.0}
                        step={100}
                        onChange={onChangeShadowDistance}
                    />
                </Form.Item>
                <Form.Item
                    label={t('accuracy')}
                    className="form-vertical"
                    name="accuracy">
                    <Select
                        style={{ width: '100%' }}
                        onChange={onChangeShadowAccuracy}
                    >
                        <Option value={512}>512</Option>
                        <Option value={1024}>1024</Option>
                        <Option value={2048}>2048</Option>
                        <Option value={4096}>4096</Option>
                        <Option value={8192}>8192</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: 20, marginTop: 20 }}>
                    <Button style={{ marginRight: 5 }}
                        type="default"
                        icon={<CloseOutlined />}
                        onClick={onCancel}>
                        {t('commons.cancel')}
                    </Button>
                    <Button
                        loading={loading}
                        type="primary"
                        onClick={onSubmit}
                        icon={<SaveOutlined />}

                    >
                        {t('commons.save')}
                    </Button>
                </Form.Item>
            </Form>
        </SettingControlContainer>
    )
}
export default inject('projectStore', 'uiStore', 'commonStore', 'projectSettingStore', 'usersStore')(observer(LightSetting))

