import React, { useEffect, useState } from "react"
import { Viewer, Cesium3DTileset,ImageryLayer } from "resium"
import { Cesium3DTileStyle, IonImageryProvider, BingMapsImageryProvider, BingMapsStyle } from 'cesium'
import { corsproxy,bingMapKey } from '../../../../config'

const ModelDemoViewer = async ({ modelSlug }) => {
  const [loaded, setLoaded] = useState(0)
  const [tileUrl, setTileUrl] = useState(null)

  const viewerRef = React.createRef()
  const tile_ref = React.createRef()

  // const Sentinel2 = new IonImageryProvider({ assetId: 3954 })
  const bing = await BingMapsImageryProvider.fromUrl('https://dev.virtualearth.net', {
    key: bingMapKey,
    mapStyle: BingMapsStyle.AERIAL
  });

  const tilesetData = {
    "tampere": {
      url: corsproxy + "xd-visuals.com/demos/Tampere_Large/Scene/Ultracam_Tampere_Cesium.json",
      maximumScreenSpaceError: 1,
      maximumNumberOfLoadedTiles: 1000
    },
    "helsinki": {
      url: "https://kartta.hel.fi/3d/b3dm_2017/tileset.json",
      maximumScreenSpaceError: 1,
      maximumNumberOfLoadedTiles: 1000
    }
  }

  useEffect(() => {
    if (loaded === 0) {
      if (viewerRef.current) {
        setLoaded(1)
        if (tilesetData[modelSlug] !== undefined)
          setTileUrl(tilesetData[modelSlug].url)
      }
    }
    return () => {
      if (viewerRef.current) {
        const viewer = viewerRef.current.cesiumElement
        viewer.entities.removeAll()
        viewer.scene.primitives.removeAll()
        setLoaded(0)
      }
    }
  }, [])

  const onReady = (tileset) => {
    const viewer = viewerRef.current.cesiumElement
    if (viewer) {
      viewer.zoomTo(tileset);
      tileset.style = new Cesium3DTileStyle({ pointSize: 4 });
    }
  };

  const viewTile = (
    <Cesium3DTileset
      url={tileUrl}
      ref={tile_ref}
      onReady={onReady}
      maximumScreenSpaceError={tilesetData[modelSlug].maximumScreenSpaceError}
      maximumNumberOfLoadedTiles={tilesetData[modelSlug].maximumNumberOfLoadedTiles}
    />
  )

  return (
    <React.Fragment>
      <Viewer
        timeline={false}
        homeButton={false}
        navigationInstructionsInitiallyVisible={false}
        navigationHelpButton={false}
        selectionIndicator={false}
        infoBox={false}
        style={{ height: "calc(100vh)" }}
        ref={viewerRef}
      >
        <ImageryLayer imageryProvider={bing} />
        {tileUrl != null
          ? viewTile
          : ""}

      </Viewer>
    </React.Fragment>
  )
}

export default ModelDemoViewer
