import { PlusOutlined, DeleteOutlined,EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Popconfirm, message } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import AdminTemplate from '../../../layout/AdminTemplate';
import { CustomTopTitle } from './style';
import ModalAddResources from './ModalAddResources';
import {
  CustomContentAdminWrapper,
  PaginationAdmin,
  HeaderAdmin,
} from '../style'
import { useTranslation } from 'react-i18next'
import settings from '../../../../siteConfig';
import { toJS } from 'mobx';

const PreDefinedAccess = props => {
  const { t } = useTranslation()
  const { commonStore, organizationStore, adminStore } = props
  const [isLoadingTableUsers, setisLoadingTableUsers] = useState(organizationStore.isLoading);
  const [currentData, setCurrentData] = useState(false);

  moment.locale(`${commonStore.language}`)
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' })

  useEffect(() => {
    let getPreDefinedAccesss = async () => {
      adminStore.getPreDefinedAccesss().then(() => {
        setisLoadingTableUsers(false);
      }).catch(() => setisLoadingTableUsers(false));
    }
    commonStore.setCurrentPage('admin/predefinedaccess');
    adminStore.clearStatus();
    setisLoadingTableUsers(true);
    getPreDefinedAccesss();
    return () => {
      adminStore.clearPreDefinedAccess()
      setCurrentData()
    }
  }, [])

  const handleShowModalAddResource = () => {
    adminStore.setShowAddResourceModel(true)
  }

  function clickEditDataLibrary(data) {
    setCurrentData(data)
    adminStore.setShowAddResourceModel(true)
  }

  const handleDelete = (data) => {
    adminStore.setLoadingProgress(true)
    adminStore.deletePreDefinedAccess(data).then(res => {
      message.success(t('delete-successfully'))
      adminStore.setLoadingProgress(false)
    })
      .catch(err => {
        console.log(err)
        adminStore.setLoadingProgress(false)
      })
  }

  const actionStyle = {margin: 2, padding: '3px 7px'};

  const columns = [
    {
      title: t('name'),
      key: "name",
      dataIndex: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: t('source-type'),
      key: "sourceType",
      dataIndex: 'sourceType',
      render: record => {
        return (
          <div>{record ? record : ""}</div>
        )
      }
    },
    {
      title: t('type'),
      key: "type",
      dataIndex: 'type',
      render: record => {
        return (
          <div>{record ? record : ""}</div>
        )
      }
    },
    {
      title: t('created'),
      key: "createdAt",
      width: 150,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: record => {
        return (
          <div>{moment(record.createdAt).format('MMM DD, YYYY')}</div>
        )
      }
    },
    {
      title: t('action'),
      key: 'action',
      width: 120,
      align: 'center',
      render: (record) => (
        <Row type="flex" justify="center" key="action">
          {/* <Button onClick={() => clickEditDataLibrary(record)} style={actionStyle}>
            <EditOutlined />
          </Button> */}
          <Popconfirm
            title={t('are-you-sure-you-want-to-delete-this-record')}
            onConfirm={() => handleDelete(record.id)}
            okText={t('commons.yes')}
            cancelText={t('commons.no')}
          >
            <Button style={actionStyle} type="danger" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Row>
      )
    }
  ];

  return (
    <AdminTemplate title={t('data-library')}>
      <CustomContentAdminWrapper
        nodata={adminStore.listPreDefinedAccess.length ? false : true}>
        <HeaderAdmin>
          <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Col xs={24} sm={12}>
              <CustomTopTitle>
                <div className="title">{t('data-library')}</div>
                <div className="count">
                  {t('total-records-found', {
                    length: adminStore.listPreDefinedAccess.length,
                  })}
                </div>
              </CustomTopTitle>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                style={{ float: isMobile ? 'left' : 'right' }}
                type="primary"
                onClick={handleShowModalAddResource}>
                <PlusOutlined />
                {t('commons.add')}
              </Button>
            </Col>
          </Row>
        </HeaderAdmin>
        <PaginationAdmin>
          <Table
            rowKey={record => record.id}
            size="default"
            bordered
            scroll={{ x: 1000 }}
            loading={isLoadingTableUsers}
            columns={columns}
            dataSource={adminStore.listPreDefinedAccess}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: settings.adminPageSizeOptions,
              locale: { items_per_page: '' },
              position: ['topRight'],
            }}
            locale={{ emptyText: t('no-data') }}
            onRow={record => ({
              onClick: event => { },
            })}
          />
        </PaginationAdmin>
      </CustomContentAdminWrapper>
      <ModalAddResources></ModalAddResources>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore'
)(observer(PreDefinedAccess))
