import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx'
import { Trans, useTranslation } from 'react-i18next';
highchartsMore(Highcharts);
solidGauge(Highcharts);


const TopicChart = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { projectStore, dashboardStore } = props
    const { listTopicFilterByType } = dashboardStore
    const [dataTable, setDataTable] = useState(dashboardStore.listTopicFilterByType)

    useImperativeHandle(ref, () => ({

        handleReflowChart() { // handle reflow container draw chart when drawer be rezise by user
            if (projectStore.showProjectDashBoard  ) {
                Highcharts.charts.forEach(el => el?.reflow())
            }
        }
    }))

    useEffect(() => {
        if (projectStore.showProjectDashBoard) {
            setDataTable(dashboardStore.listTopicFilterByType)
        }
    }, [dashboardStore.curentTypeTopicPie, dashboardStore.listTopicFilterByType, dashboardStore.listTopicTypeSelected])

    const colors = ['#55D8FE', '#FF8373', "#FFDA83", '#A3A0FB', "#e74c3c", "#7f8c8d", "#2c3e50", "#00008b", "#008b8b", "#a9a9a9", "#8b008b", "#556b2f", "#ff8c00", "#9932cc", "#8b0000", "#e9967a", "#9400d3", "#bdb76b", "#ff00ff", "#ffd700", "#008000", "#4b0082", "#f0e68c", "#add8e6", "#e0ffff", "#90ee90", "#d3d3d3", "#ffb6c1", "#ffffe0", "#00ff00", "#ff00ff", "#800000", "#000080", "#808000", "#ffa500", "#ffc0cb", "#800080", "#800080", "#ff0000", "#c0c0c0", "#ffffff", "#ffff00","#006400",
    ]
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            events: {
                redraw: function () {
                    if (projectStore.showProjectDashBoard && Highcharts.charts.length > 0 ) {
                        Highcharts.charts.forEach(el => el?.reflow())
                    }
                }
            },
        },
        title: {
            text: `${listTopicFilterByType.length} ${t('topic')}` || `0 ${t('topic')}`,
            align: 'center',
            verticalAlign: 'middle',
            // y: 60
            x: -120
        },
        tooltip: {
            pointFormat: '{series.name}  <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                borderWidth: 0,
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                showInLegend: true,
            }
        },
        series: [{
            type: 'pie',
            name: '',
            innerSize: '50%',
            data: (function () {
                var data = [];
                const { curentTypeTopicPie } = dashboardStore
                dashboardStore.listTopicTypeSelected.map((item, index) => {
                    let _result;
                    _result = dataTable.filter(value => value[`${curentTypeTopicPie}`] === item.name)
                    data.push({
                        name: item.name,
                        y: _result.length
                    })
                })
                return data;
            }())
        }],
        credits: {
            enabled: false
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            itemMarginTop: 10,
            itemMarginBottom: 10,
            useHTML: true,
            labelFormatter: function () {
                var legendItem = document.createElement('div'),
                    symbol = document.createElement('span'),
                    countTopic = document.createElement("div"),
                    labelwrap = document.createElement('div'),
                    label = document.createElement('span');
               

                legendItem.classList.add("topic-detail");
                labelwrap.classList.add("label-wrap");
                countTopic.classList.add('count-topic');

                countTopic.innerText = this.y + " " + t('topic');


                // symbol.innerText = this.y.toFixed(2);
                symbol.style.borderColor = this.color;
                symbol.classList.add('xLegendSymbol');
                label.innerText = this.name;


                labelwrap.appendChild(symbol);
                labelwrap.appendChild(label);
                legendItem.appendChild(labelwrap);
                legendItem.appendChild(countTopic);

                return legendItem.outerHTML;
            }
        },
        colors,
    }
    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
})

export default inject("projectStore", "dashboardStore")(observer(TopicChart));
