import {
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useParams } from 'react-router-dom'
import LoadingSpinner from '../../../elements/LoadingSpinner/LoadingSpinner'
import AdminTemplate from '../../../layout/AdminTemplate'
import '../style.css'
import { Trans, useTranslation } from 'react-i18next'
import { CustomContentAdminWrapper, HeaderAdmin } from '../style'
const { Content } = Layout

const EditLicenseType = ({ organizationStore, commonStore, adminStore }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false)
  const [reload, setReload] = useState(false)
  const { licenseTypeID } = useParams()
  const isMobile = useMediaQuery({ query: '(max-width: 400px)' })
  useEffect(() => {
    const populateLicenseFormData = async () => {
      adminStore.clearStatus()
      setReload(false)
      setisLoadingFormEdit(true)
      commonStore.setCurrentPage('admin/licensetypes')
      adminStore.setRiderect(true)
      await adminStore.getLicenseTypes()
      if (licenseTypeID !== 'new') {
        adminStore.setEditMode(true)
        await adminStore
          .getLicenseTypeById(licenseTypeID)
          .then(res => {
            setisLoadingFormEdit(false)
            let _value = res.data
            if (_value?.numberProject === 9999) {
              _value.numberProject = -1
            }
            if (_value?.numberUser === 9999) {
              _value.numberUser = -1
            }
            if (_value?.storageQuota === 9999) {
              _value.storageQuota = -1
            }
            if (_value?.timeLimit === 9999) {
              _value.timeLimit = -1
            }
            form.setFieldsValue({
              numberProject: _value?.numberProject,
              numberUser: _value?.numberUser,
              storageQuota: _value?.storageQuota,
              timeLimit: _value?.timeLimit,
              description: _value?.description,
              name: _value?.name,
              customOrganizationLogo : _value?.customOrganizationLogo
            })
          })
          .catch(() => setisLoadingFormEdit(false))
      } else {
        adminStore.setEditMode(false)
        adminStore.setCreateMode(true)
        setisLoadingFormEdit(false)
        form.resetFields()
      }
    }
    populateLicenseFormData()
    return () => {
      adminStore.clearLicenseSelected()
    }
  }, [reload])

  const handleDeleteLicense = async id => {
    setisLoadingFormEdit(true)
    adminStore
      .deleteLicenseType(id)
      .then(() => {
        adminStore.setRiderect(false)
        message.success(t('delete-license-successfully'))
        history.push(`/admin/licensetypes/_limit=10&_page=1`)
      })
      .catch(() => {
        adminStore.setRiderect(false)
        message.error(t('delete-license-failed'))
      })
      .finally(() => {
        setisLoadingFormEdit(false)
      })
  }

  const createLicense = info => {
    setisLoadingFormEdit(true)
    if (info?.numberProject === -1) {
      info.numberProject = 9999
    }
    if (info?.numberUser === -1) {
      info.numberUser = 9999
    }
    if (info?.storageQuota === -1) {
      info.storageQuota = 9999
    }
    if (info?.timeLimit === -1) {
      info.timeLimit = 9999
    }
    info.type = 'license_basic'
    adminStore
      .createLicenseType(info)
      .then(() => {
        message.success(t('create-new-license-successfully'))
        history.push(`/admin/licensetypes/_limit=10&_page=1`)
      })
      .catch(() => {
        message.error(t('create-new-license-failed'))
      })
      .finally(() => {
        adminStore.setRiderect(false)
        setisLoadingFormEdit(false)
      })
  }

  const updateLicenseType = async (id, info) => {
    setisLoadingFormEdit(true)
    if (info?.numberProject === -1) {
      info.numberProject = 9999
    }
    if (info?.numberUser === -1) {
      info.numberUser = 9999
    }
    if (info?.storageQuota === -1) {
      info.storageQuota = 9999
    }
    if (info?.timeLimit === -1) {
      info.timeLimit = 9999
    }
    adminStore
      .updateLicenseType(id, info)
      .then(() => {
        message.success(t('license-updated-successfully'))
        organizationStore.setRiderect(false)
        history.push(`/admin/licensetypes/_limit=10&_page=1`)
      })
      .catch(() => {
        message.error(t('an-error-occurred'))
      })
      .finally(() => {
        setisLoadingFormEdit(false)
      })
  }

  const handleSaveLicenseType = payload => {
    if (licenseTypeID === 'new') {
      //  case create new license noraml
      return createLicense(payload)
    } else {
      // case update license instance
      let currentLicense = adminStore.currentLicenseType.id
      return updateLicenseType(currentLicense, payload)
    }
  }

  const resetFormLicenseType = () => {
    form.resetFields()
    history.push(`/admin/licensetypes/_limit=10&_page=1`)
  }

  const showConfirmDeleteLicense = () => {
    console.log(adminStore.currentLicenseType.name)
    Modal.confirm({
      zIndex: 9999,
      title: t('do-you-want-to-delete-this-license'),
      content: `${t('please-confirm-to-delete')} :  "${
        adminStore.currentLicenseType.name
      }" `,
      icon: <ExclamationCircleOutlined />,
      okText: t('commons.delete'),
      cancelText: t('commons.cancel'),
      okButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk() {
        handleDeleteLicense(adminStore.currentLicenseType.id)
      },
      onCancel() {
        console.log('')
      },
    })
  }
  const customSpin = (
    <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
  )
  return (
    <AdminTemplate title={t('license-type-detail')}>
      <Spin spinning={isLoadingFormEdit} indicator={customSpin}>
        <CustomContentAdminWrapper>
          <div style={{ background: '#fff', textAlign: 'center' }}>
            <Layout>
              <Form
                form={form}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={value => handleSaveLicenseType(value)}>
                <HeaderAdmin style={{ backgroundColor: '#f0f2f5' }}>
                  <Content>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style={{ padding: '10px ' }}>
                      <div style={{ float: 'left', fontSize: 20 }}>
                        {' '}
                        {adminStore.createMode
                          ? t('new-license')
                          : t('edit-license-type')}
                      </div>
                      <Row>
                        <Col span={24} className="control-btn-group">
                          <Button
                            style={{
                              float: 'right',
                              marginRight: 10,
                            }}
                            htmlType="submit"
                            type="primary"
                            loading={adminStore.isLoading}>
                            <SaveOutlined />
                            {!isMobile && t('commons.save')}
                          </Button>
                          {!adminStore.createMode ? (
                            <Fragment>
                              <Button
                                style={{
                                  float: 'right',
                                  marginRight: 10,
                                }}
                                type="danger"
                                onClick={showConfirmDeleteLicense}>
                                <DeleteOutlined />
                                {!isMobile && t('commons.delete')}
                              </Button>
                            </Fragment>
                          ) : null}
                          <Button
                            style={{
                              float: 'right',
                              marginRight: 10,
                            }}
                            type="primary"
                            onClick={() => resetFormLicenseType()}>
                            <CloseCircleOutlined />
                            {!isMobile && t('commons.cancel')}
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </Content>
                </HeaderAdmin>
                <Content>
                  <div style={{ padding: '20px 0', background: '#fff' }}>
                    <Row type="flex">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div
                          className="content-left"
                          style={{
                            background: '#fff',
                            padding: '20px 0',
                          }}>
                          <Row gutter={[24, 8]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                              <Form.Item
                                label={t('name')}
                                name="name"
                                rules={[
                                  {
                                    required: false,
                                    message: t('please-input-licenses-name'),
                                  },
                                ]}>
                                <Input
                                  placeholder={t('input-your-licenses-name')}
                                  type="text"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                              <Form.Item
                                label={t('description')}
                                name="description"
                                rules={[
                                  {
                                    required: false,
                                    message: t(
                                      'please-input-licenses-description'
                                    ),
                                  },
                                ]}>
                                <Input
                                  placeholder={t(
                                    'input-your-licenses-description'
                                  )}
                                  type="text"
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                              <Form.Item
                                label={t('number-user')}
                                name="numberUser"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-total-user'),
                                  },
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={-1}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                              <Form.Item
                                label={t('number-project')}
                                name="numberProject"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-total-project'),
                                  },
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={-1}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                              <Form.Item
                                label={t('storage-quota')}
                                name="storageQuota"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-storage-quota'),
                                  },
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={-1}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                              <Form.Item
                                label={t('time-limit')}
                                name="timeLimit"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-time-limit'),
                                  },
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={-1}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('custom-organization-logo')}
                                name="customOrganizationLogo"
                                valuePropName="checked"
                                className="checkbox-status">
                                <Checkbox
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Content>
              </Form>
            </Layout>
          </div>
        </CustomContentAdminWrapper>
      </Spin>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore'
)(observer(EditLicenseType))
