import { Button, Form, Input, message, Modal, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import uuid from 'uuid'
import { Trans, useTranslation } from 'react-i18next';
const { Option } = Select

const AddLicenseToOrganizationModal = props => {
  const { t } = useTranslation();
  const { organizationStore, licenseTypes, licenseStore, organizationId } = props
  const [form] = Form.useForm();
  const [licenseType, setLicenseType] = useState()

  useEffect(() => {
    if (licenseType) {

      let typeData = licenseStore.licenseTypes.find(elm => elm.id === licenseType)
      form.setFieldsValue({
        numberProject: typeData.numberProject,
        numberUser: typeData.numberUser,
        storageQuota: typeData.storageQuota,
        timeLimit: typeData.timeLimit
      })
    }
  }, [licenseType])

  const handleCloseModal = () => {
    form.resetFields()
    organizationStore.toggleShowAddLicenseToOrganizationModal(false)
  }


  const handleSubmit = () => {
    form.validateFields().then(values => {
      values.organization = organizationId
      values.licenceKey = uuid()
      licenseStore.setLoadingProgress(true)
      licenseStore.createLicense(values).then(res => {
        message.success(t('add-license-to-organization-successfully'))
        licenseStore.setLoadingProgress(false)
        handleCloseModal()
        organizationStore.setForceReload(1)
      })
        .catch(err => {
          licenseStore.setLoadingProgress(false)
          message.error(t('an-error-occurred'))
          console.log(err)
        })
    })
  }
  const CustomForm = props => {

    return (
      <Form
        form={form}
        layout="vertical"
        id={'invite-member-form'}>
        <Form.Item label={t('licenses-type')} name="licensetype" rules={[
          {
            required: true,
            message: t('please-select-licenses-type'),
          },
        ]}>
          <Select
            onChange={v => setLicenseType(v)}
            placeholder={t('select-license')}>
            {
              licenseTypes.map((r) => {
                return (
                  <Option key={r.id} value={r.id}>{r.name}</Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item label={t('number-project')} name="numberProject" rules={[
          {
            required: false,
          },
        ]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t('time-limit')} name="timeLimit" rules={[
          {
            required: false,
          },
        ]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t('storage-quota')} name="storageQuota" rules={[
          {
            required: false,
          },
        ]}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t('number-user')} name="numberUser" rules={[
          {
            required: false,
          },
        ]}>
          <Input disabled />
        </Form.Item>

      </Form>
    )
  }

  return (
    <Modal
      title={t('add-license-to-organization')}
      onCancel={() => handleCloseModal()}
      zIndex={9999}
      visible={organizationStore.showAddLicenseToOrganizationModal}
      className="my-modal-class"
      maskClosable={false}
      footer={[
        <Button
          key="cancel"
          onClick={() => handleCloseModal()}>
          {t('commons.cancel')}
        </Button>,
        <Button
          onClick={handleSubmit}
          form={'invite-license-form'}
          key="submit" type="primary">
          {t('commons.save')}
        </Button>,
      ]}
    >
      <CustomForm />
    </Modal>
  )
}

export default withRouter(inject('organizationStore', 'adminStore', 'licenseStore')(observer(AddLicenseToOrganizationModal)))