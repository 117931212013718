import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";

const Camera = () => {
    const [webcamActive, setWebcamActive] = useState(false);
    const [url, setUrl] = useState(null);
    const [facingMode, setFacingMode] = useState("environment");
    const webcamRef = useRef(null);

    const activateWebcam = () => {
        setWebcamActive(true);
    };

    const capturePhoto = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setUrl(imageSrc);
        }
    }, [webcamRef]);

    const switchCamera = () => {
        setFacingMode((prevMode) => (prevMode === "environment" ? "user" : "environment"));
    };

    const videoConstraints = {
        width: 540,
        facingMode: facingMode,
    };

    return (
        <>
            {webcamActive ? (
                <>
                    <Webcam
                        ref={webcamRef}
                        audio={true}
                        screenshotFormat="image/jpeg"
                        screenshotQuality={1}
                        forceScreenshotSourceSize={false}
                        minScreenshotWidth={1280}
                        videoConstraints={videoConstraints}
                    />
                    <button onClick={capturePhoto}>Capture</button>
                    <button onClick={() => setUrl(null)}>Refresh</button>
                    <button onClick={switchCamera}>
                        Switch to {facingMode === "environment" ? "Front" : "Rear"} Camera
                    </button>
                </>
            ) : (
                <button onClick={activateWebcam}>Activate Camera</button>
            )}
            {url && (
                <div>
                    <img src={url} alt="Screenshot" />
                </div>
            )}
        </>
    );
};

export default Camera;
