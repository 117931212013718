import { BuildOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, message, Modal, notification, Row, Col, Select, Slider, Spin, Tabs, Divider, Switch } from 'antd'
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react'
import { createGooglePhotorealistic3DTileset, GoogleMaps } from 'cesium'
import { withRouter } from 'react-router-dom'
import { apiNcWmsUrl, apiUrl } from '../../../../config'
import Utils from '../../../../utils'
import { getOriginPublicJsonLink } from './../../../../lib/projectLib'
import { validDegreeBox } from '../../../helper/CesiumUtils'
import { useTranslation } from 'react-i18next';
import { ProjectRequest } from '../../../../requests'
import GML3 from 'ol/format/GML3.js';
import GML2 from 'ol/format/GML2.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Buffer } from 'buffer';
const dateFormat = 'YYYY-MM-DD';
const { TabPane } = Tabs
const { Option } = Select
const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />
const isUrl = urlString => {
  return (
    urlString &&
    (urlString.startsWith('https://') || urlString.startsWith('http://'))
  )
}


const ModalAddResources = ({ projectStore, commonStore, adminStore }) => {
  const { t } = useTranslation();
  const onChangeTab = activeKey => {
    adminStore.setCurrentAddTab(activeKey)
  }
  const [isLoading, setLoadingProgress] = useState(false);
  moment.locale(`${commonStore.language}`)

  const ExternalForm = () => {
    const [form] = Form.useForm();
    const [isUseCredential, setUseCredentialization] = useState(false)
    const [loadingDataLibrary, setLoadingDataLibrary] = useState(false)

    const handleSubmit = async values => {
      setLoadingProgress(true)
      let newPreDefined = {
        name: values.name ? values.name.trim() : 'Unnamed',
        hash: '',
        data: {},
        sourceType: 'external'
      }
      newPreDefined.data.detailLevel = values.detailLevel

      if (!isUrl(values.src)) {
        newPreDefined.data.ionAssetId = parseInt(values.src)
      } else {
        // newPreDefined.src = values.src.replace(/^https?\:\/\//i, '')
        if (values.src.includes('xd-visuals.com')) {
          newPreDefined.src = values.src.replace(/^https?\:\/\//i, '')
        } else if (values.src.includes('tile.googleapis.com')) {
          try {
            GoogleMaps.defaultApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
            GoogleMaps.mapTilesApiEndpoint = values.src.split('3dtiles/')[0];
            const googleTileset = await createGooglePhotorealistic3DTileset();
            newPreDefined.src = googleTileset.resource.url
            newPreDefined.data.isPhotorealistic3DTile = true
          } catch (error) {
            setLoadingProgress(false)
            console.log(`${t('error-loading-photorealistic-3d-tiles')}${error}`);
            notification.open({
              message: `${t('error-google-3dtiles-maximum-load-amount')} ${error}`,
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            })
            return
          }
        } else if (Utils.getDomain(values.src, true) === window.location.host) {
          // case check if url same with domain (copy public json in edit model)
          let projectId = values.src.split('/')[3];
          let hash = values.src.split('/')[4];
          let modelname = values.src.split("/").pop();
          let jsonlink = await getOriginPublicJsonLink(projectStore, commonStore.token, projectId, hash, modelname)
          newPreDefined.src = jsonlink
        } else {
          newPreDefined.src = values.src
        }

        if (isUseCredential && values.username && values.password) {
          const { data } = await ProjectRequest.checkJSONLink({ url: newPreDefined.src, username: values.username, password: values.password })
          if (data && (data.status === 401 || data.status === 403)) {
            setLoadingProgress(false)
            notification.open({
              message: t('invalid-credentials'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
            return
          } else {
            newPreDefined.data.credential = Buffer.from(values.username + ':' + values.password).toString('base64');
            newPreDefined.data.is3DTilesPointcloud = false
            let myJson = data.data;
            if (myJson.root && myJson.root.content) {
              let key = Object.keys(myJson.root.content)[0];
              let value = myJson.root.content[key];
              if (value?.split) {
                let ext = value.split('.').pop();
                if (ext === 'pnts') {
                  newPreDefined.data.is3DTilesPointcloud = true
                }
              }
            }
          }
        }
      }

      adminStore
        .createPreDefinedAccess(newPreDefined)
        .then(async (resp) => {
          adminStore.getPreDefinedAccesss()
          message.success(t('create-successfully'), 5)
          onCancel()
        })
        .catch(err => {
          notification.open({
            message: t('an-error-occurred-when-creating-data', { error: err?.data?.error }),
            description: t('something-went-wrong-when-creating-data'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        }).finally(() => {
          setLoadingProgress(false)
        })
    }

    useEffect(() => {
      form.setFieldsValue({ detailLevel: 16 })
      return () => {
        setUseCredentialization(false)
      }
    }, [])

    function roundNumber(value) {
      if (!isNaN(value)) {
        return Math.round(value)
      }
    }

    const validJsonLink = async (rule, value) => {
      if (!value) {
        setUseCredentialization(false)
        form.resetFields(["username", "password"]);
        return Promise.resolve();
      }
      if (!isUrl(value)) { //case ionAssetId
        setUseCredentialization(false)
        form.resetFields(["username", "password"]);
        return Promise.resolve();
      }

      if (value.includes("tile.googleapis.com")) {
        setUseCredentialization(false)
        form.resetFields(["username", "password"]);
        return Promise.resolve();
      } else {
        setLoadingDataLibrary(true)
        const { data } = await ProjectRequest.checkJSONLink({ url: value })
        setLoadingDataLibrary(false)
        if (data && data.status === 200) {
          setUseCredentialization(false)
          form.resetFields(["username", "password"]);
          return Promise.resolve();
        } else {
          if (data && (data.status === 401 || data.status === 403)) {
            setUseCredentialization(true)
            return Promise.resolve();
          } else {
            setUseCredentialization(false)
            form.resetFields(["username", "password"]);
            return Promise.reject(data && data.message ? data.message : t("invalid-url"));
          }
        }
      }

    };

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={(
            <>
              {t('link')}
              {loadingDataLibrary && <Spin indicator={loadingIcon} />}
            </>
          )}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-external-link'),
            },
            { validator: validJsonLink }
          ]}>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('username')}
              name="username"
              // autoComplete="off"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-username'),
                },
              ]}
            >
              <Input  placeholder={t('username')} />
            </Form.Item>
          </Col>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('password')}
              name="password"
              // autoComplete="off"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-password'),
                },
              ]}
            >
              <Input.Password  placeholder={t('password')} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t('detail-level-multiplier')}
          name="detailLevel"
        >
          <InputNumber min={0.025} max={128} step={0.025} placeholder={t('detail-level-multiplier')} style={{ width: '100%' }} />
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button disabled={loadingDataLibrary} loading={isLoading} type="primary" htmlType="submit">
            {t('add-resources')}
          </Button>
        </Row>
      </Form>
    )
  }

  const CesiumIonForm = () => {
    const [form] = Form.useForm();
    useEffect(() => {
      form.setFieldsValue({ detailLevel: 1 })
      return () => {
      }
    }, [])
    function roundNumber(value) {
      if (!isNaN(value)) {
        return Math.round(value)
      }
    }
    const handleSubmit = async values => {
      setLoadingProgress(true)
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {
          cesiumToken: values.cesiumToken
        },
        sourceType: 'external',
        isTerrain: values.isTerrain
      }
      newModel.data.detailLevel = values.detailLevel
      if (!isUrl(values.src)) {
        newModel.data.ionAssetId = parseInt(values.src)
      } else {
        // newModel.src = values.src.replace(/^https?\:\/\//i, '')
        if (values.src.includes('xd-visuals.com')) {
          newModel.src = values.src.replace(/^https?\:\/\//i, '')
        } else if (Utils.getDomain(values.src, true) === window.location.host) {
          // case check if url same with domain (copy public json in edit model)
          let projectId = values.src.split('/')[3];
          let hash = values.src.split('/')[4];
          let modelname = values.src.split("/").pop();
          let jsonlink = await getOriginPublicJsonLink(projectStore, commonStore.token, projectId, hash, modelname)
          newModel.src = jsonlink
        } else {
          newModel.src = values.src
        }
      }
      adminStore
        .createPreDefinedAccess(newModel)
        .then(async (model) => {
          adminStore.getPreDefinedAccesss()
          setLoadingProgress(false)
          onCancel()
          message.success(t('create-successfully'), 5)
        })
        .catch(err => {
          setLoadingProgress(false)
          notification.open({
            message: t('an-error-occurred-when-creating-pre-defined-access-data', { error: err.data.error }),
            description: t('something-went-wrong-when-creating-model'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }
    return (
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={false}
          name="isTerrain"
          valuePropName="checked"
        >
          <Checkbox>{t('terrain')}</Checkbox>
        </Form.Item>
        <Form.Item
          label={t('cesium-ion-asset-id')}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-ion-asset-id'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('cesium-ion-access-token')}
          name="cesiumToken"
          rules={[
            {
              required: true,
              message: t('please-input-ion-access-token'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('detail-level-multiplier')}
          name="detailLevel"
        >
          <InputNumber min={0.025} max={128} step={0.025} placeholder={t('detail-level-multiplier')} style={{ width: '100%' }} />
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {t('add-resources')}
          </Button>
        </Row>
      </Form>
    )
  }

  const WMSForm = () => {
    const [form] = Form.useForm();
    const [layerData, setLayerData] = useState([])
    const [xmlData, setXmlData] = useState()
    const [loadingXml, setLoadingXml] = useState(false)
    const [epsg, setEpsg] = useState()
    const [isNcWms, setIsNcWms] = useState(false)
    const [prevSrv, setPrevSrv] = useState('')
    const [isUseCredential, setUseCredentialization] = useState(false)
    const [palettes, setPalettes] = useState([])
    const [metaDatas, setMetaDatas] = useState([])
    const [availableDates, setAvailableDates] = useState([])
    const [selectedDate, setSelectedDate] = useState()
    const [timeSteps, setTimeSteps] = useState([])
    const [terrainValue, setTerrainValue] = useState('TERRAIN')
    const [planeValue, setPlaneValue] = useState('PLANE')
    const [showPlaneForm, setShowPlaneForm] = useState(false)

    const onChangeClassificationType = (value, key) => {
      if (value === 'CESIUM_3D_TILE' || value === 'BOTH' || key.key === 'TERRAIN') {
        setShowPlaneForm(false)
      }
      if (key.key === 'PLANE') {
        setShowPlaneForm(true)
      }
      if (value !== "TERRAIN") {
        form.setFieldsValue({ height: 0 })
      }
    }

    useEffect(() => {
      if (terrainValue === "TERRAIN") {
        form.setFieldsValue({ height: 0 })
      }
    }, [terrainValue])

    const handleTerrain = () => {
      setPlaneValue('2')
      setTerrainValue('TERRAIN')
      setShowPlaneForm(false)
    }

    const handlePlane = () => {
      setShowPlaneForm(true)
      setPlaneValue('TERRAIN')
      setTerrainValue('1')
    }

    function subStringURL(value) {
      const subServer = value.split("?")[0];
      let cutSubServer = value.replace(subServer, '')
      let layer = ''
      let subLayer = null
      if (cutSubServer)
        layer = cutSubServer.split("layers=")[1];
      if (layer)
        subLayer = layer.split("&")[0];
      if (subServer) {
        form.setFieldsValue({ src: subServer })
        onBlur(subServer)
      }
    }

    const onBlur = (src) => {
      const urlParams = src ? Utils.getAllUrlParams(src) : {}
      let urlSrc, prevSrvUrl
      try {
        urlSrc = new URL(src);
        if (prevSrv)
          prevSrvUrl = new URL(prevSrv)
      } catch (error) {
        notification.open({
          message:
            t('invalid-url'),
          //description: 'This resource require credentials',
          icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          duration: 5,
        })
        return
      }
      if (prevSrvUrl && prevSrvUrl.origin !== urlSrc.origin) {
        setUseCredentialization(false)
        form.setFieldsValue({
          useProxy: false
        })
        form.setFieldsValue({ username: undefined });
        form.setFieldsValue({ layers: undefined });
        form.setFieldsValue({ password: undefined });
      }
      if (src && (src.trim() === `${apiNcWmsUrl}/wms` || src.trim() === `${apiNcWmsUrl}/wms/`)) {
        setIsNcWms(true);
      } else {
        setIsNcWms(false);
      }
      try {
        if (urlParams && urlParams.request) {
          var baseUrl = src.split('?')[0]
          baseUrl += '?'
          for (const key in urlParams) {
            if (key.toLowerCase() != 'request')
              baseUrl += `${key}=${urlParams[key]}`
          }
          src = baseUrl
          form.setFieldsValue({ src: src });
        }
      } catch (error) {

      }
      setPrevSrv(src)
      fetchLayers(src)
    }

    const fetchLayers = async (src, retry = false, version = undefined) => {
      form.setFieldsValue({ layers: undefined });
      form.setFieldsValue({ date: undefined });
      form.setFieldsValue({ time: undefined });
      setLayerData([]);
      if (src) {
        if (form.getFieldValue('useProxy')) {
          src = `${apiUrl}/wms/${encodeURIComponent(src)}`
        }
        setLoadingXml(true)

        let requestOption = {
          method: 'get',
          url: src,
          responseType: 'document',
          params: {
            request: 'GetCapabilities',
            service: 'wms'
          }
        }
        if (version) {
          requestOption.params.version = version
        }
        const username = form.getFieldValue('username')
        const password = form.getFieldValue('password')
        if (username && password && isUseCredential) {
          const Authorization = btoa(`${username.trim()}:${password}`)
          requestOption.headers = {
            Authorization: `Basic ${Authorization}`
          }
        }
        const res = await axios(requestOption).catch(e => {
          if (e?.status === 401 || e?.status === 403) {
            setUseCredentialization(true)
            form.setFieldsValue({
              useProxy: false
            })
            notification.open({
              message: t('invalid-credentials'),
              description: t('this-resource-require-credentials'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          } else {
            if (isUseCredential) {
              notification.open({
                message: t('invalid-credentials'),
                description: t('this-resource-require-credentials'),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 5,
              })
            } else if (!retry) {
              form.setFieldsValue({
                useProxy: true
              })
              fetchLayers(form.getFieldValue('src'), true);
            }
          }
        })
        setLoadingXml(false)
        if (res?.data) {
          const layerData = res.data.querySelectorAll('Layer > Name');
          setXmlData(res.data.getElementsByTagName("Layer"));
          setLayerData(Array.prototype.map.call(layerData, element => element.innerHTML));
        }
      }
    }

    const getMetadata = async (src, layerName) => {
      if (src) {
        const res = await axios({
          method: 'get',
          url: src,
          params: {
            request: 'GetMetadata',
            item: 'layerDetails',
            layerName
          }
        })

        if (res.data && res.data.palettes?.length) {
          const { palettes } = res.data
          setMetaDatas(res.data)
          setPalettes(palettes)

          form.setFieldsValue({
            palette: palettes[0]
          })
        }
      }
    }

    const getTimeSteps = async (src, layerName) => {

      if (selectedDate) {
        const res = await axios({
          method: 'get',
          url: src,
          params: {
            request: 'GetMetadata',
            item: 'timesteps',
            layerName,
            day: moment(selectedDate).format(dateFormat),
          }
        })
        if (res.data) {
          setTimeSteps(res.data.timesteps)
          if (res.data.timesteps && res.data.timesteps.length)
            form.setFieldsValue({ time: res.data.timesteps[0] });
        }
      }
    }

    // disable date
    const disabledDate = (current) => {
      let condition = false

      const formatedDate = moment(current).format(dateFormat)
      if (availableDates && availableDates.length > 0) {
        return availableDates.indexOf(formatedDate) === -1
      }
      return condition
    }

    const onChangeDateSerial = (date) => {
      setSelectedDate(date)
      form.setFieldsValue({ date: moment(date) });
    }
    const onChangeTimeSerial = (time) => {
      form.setFieldsValue({ time: time });
    }
    const extractAvailableDate = () => {
      if (metaDatas && metaDatas.nearestTimeIso) {
        const defaultDate = metaDatas.nearestTimeIso.slice(0, 10)

        const getStartTime = metaDatas.datesWithData
        const availableDate = []
        for (let year in getStartTime) {
          let months = getStartTime[year]
          for (let month in months) {
            const realMonth = Number(month) + 1;
            const formattedMonth = realMonth < 10 ? '0' + realMonth : realMonth
            let days = months[month]
            for (let index = 0; index < days.length; index++) {
              const day = days[index];
              const formattedDate = day < 9 ? '0' + day : day
              availableDate.push(`${year}-${formattedMonth}-${formattedDate}`)
            }
          }
        }
        setAvailableDates(availableDate)
        form.setFieldsValue({ date: moment(defaultDate) });
        setSelectedDate(moment(defaultDate))
      }
    }
    // Datepicker
    useEffect(() => {
      extractAvailableDate()
    }, [metaDatas, layerData])

    useEffect(() => {

      getTimeSteps(form.getFieldValue('src'), form.getFieldValue('layers'))
    }, [selectedDate])

    /**
     * Check Authen layer
     * @param {*} data { src: src, layer: layer, bbox: bbox, username, password }
     * @returns 
     */
    const checkAuthLayer = async (data) => {
      let { src, layer, bbox, username, password, version } = data
      let _bbox = [bbox.x1, bbox.y1, bbox.x2, bbox.y2].join(',')
      if(!bbox.x1){
        if(bbox.length === 4){
          _bbox = bbox.join(',')
        }
      }
      let requestOption = {
        method: 'get',
        url: src,
        responseType: 'document',
        params: {
          service: 'wms',
          request: 'GetMap',
          crs: "EPSG:4326",
          format: "image/png",
          layers: layer,
          width: 256,
          height: 256,
          bbox: _bbox,
          version:'1.1.1',
          styles:''
        }
      }

      if (version) {
        requestOption.params.version = version
      }

      if (username && password && isUseCredential) {
        const Authorization = btoa(`${username.trim()}:${password}`)
        requestOption.headers = {
          'Authorization': `Basic ${Authorization}`,
          'Access-Control-Allow-Origin': '*',
        }
      }
      return await axios(requestOption).then(res => {
        return true
      }).catch(e => {
        if (e?.status === 401 || e?.status === 403) {
          notification.open({
            message: t('invalid-credentials'),
            description: t('this-resource-require-credentials'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 5,
          })

          setUseCredentialization(true)
          return false
        }
      })
    }

    const onChangeLayer = async (value) => {
      if (isNcWms) {
        setSelectedDate(undefined)
        await getMetadata(form.getFieldValue('src'), value)

      }
      var box = { x1: undefined, x2: undefined, y1: undefined, y2: undefined };

      form.setFieldsValue({ bbox: box });
      for (let i = 0; i < xmlData.length; i++) {
        const isGroup = xmlData[i].getElementsByTagName('Layer').length > 0
        if (isGroup) continue
        const tagName = xmlData[i].getElementsByTagName('Name')[0];
        if (tagName.innerHTML === value) {
          const geographicBoundingBox = xmlData[i].getElementsByTagName('EX_GeographicBoundingBox');
          if (geographicBoundingBox?.length) {
            let x1, x2, y1, y2
            // eslint-disable-next-line no-unused-expressions
            geographicBoundingBox[0]?.childNodes.forEach(x => {
              if (x.localName === 'westBoundLongitude') {
                x1 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'eastBoundLongitude') {
                x2 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'southBoundLatitude') {
                y1 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'northBoundLatitude') {
                y2 = +x.childNodes[0]?.nodeValue
              }
            });
            box = { x1, y1, x2, y2 }
          } else {
            const boundingBoxs = xmlData[i].getElementsByTagName('BoundingBox');
            if (boundingBoxs?.length) {
              for (let j = 0; j < boundingBoxs.length; j++) {
                if (
                  boundingBoxs[j].getAttribute('SRS')?.toUpperCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('srs')?.toLowerCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('CRS')?.toLowerCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('crs')?.toLowerCase() === 'EPSG:4326'
                ) {
                  const x1 = +boundingBoxs[j].getAttribute('minx')
                  const x2 = +boundingBoxs[j].getAttribute('maxx')
                  const y1 = +boundingBoxs[j].getAttribute('miny')
                  const y2 = +boundingBoxs[j].getAttribute('maxy')
                  box = { x1, y1, x2, y2 }
                }
              }
            }
            else {
              const latlngBoxs = xmlData[i].getElementsByTagName('LatLonBoundingBox')[0];
              if (latlngBoxs) {
                const x1 = +latlngBoxs.getAttribute('minx')
                const x2 = +latlngBoxs.getAttribute('maxx')
                const y1 = +latlngBoxs.getAttribute('miny')
                const y2 = +latlngBoxs.getAttribute('maxy')
                box = { x1, y1, x2, y2 }
              }
            }
          }
          try {
            if (!validDegreeBox(box)) throw box;
            form.setFieldsValue({ bbox: box });
            setEpsg('EPSG:4326');

            //check auth layer
            await checkAuthLayer({ src: form.getFieldValue('src'), layer: form.getFieldValue('layers'), bbox: form.getFieldValue('bbox'), username: form.getFieldValue('username'), password: form.getFieldValue('password'), version: form.getFieldValue('version') })
          } catch (error) {
            box = undefined
            notification.open({
              message: t('wms-layer-coordinates-do-not-match'),
              description: t('xd-twin-cannot-import-this-layer'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          }
        }
      }
    }

    const handleSubmit = async values => {

      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {
          layers: values.layers,
          parameters: {
            transparent: true,
            format: "image/png",
          }
        },
        sourceType: 'WMS',
        src: values.src
      }
      if (!values.bbox || !values.bbox.x1 || !values.layers) {
        return
      }
      setLoadingProgress(true)
      newModel.data.alpha = isNaN(values.alpha) ? 0 : values.alpha
      newModel.data.bbox = [values.bbox.x1, values.bbox.y1, values.bbox.x2, values.bbox.y2]
      newModel.data.epsg = +epsg.split(':')[1];
      newModel.data.classificationType = values.classificationType || null;
      newModel.data.useWMSProvider = values.useWMSProvider;
      newModel.data.useProxy = values.useProxy;
      newModel.data.height = values.height;
      newModel.data.presentation = (planeValue === 'TERRAIN') ? 'PLANE' : ''
      newModel.data.version = values.version ? values.version : '';
      newModel.data.isUseCredential = isUseCredential !== undefined ? isUseCredential : false;
      newModel.data.username = values.username ? values.username : '';
      newModel.data.password = values.password ? values.password : '';
      const createPreDefineAccess = (newModel) => {
        adminStore
          .createPreDefinedAccess(newModel)
          .then(async (model) => {
            adminStore.getPreDefinedAccesss()
            setLoadingProgress(false)
            onCancel()
            message.success(t('data-added-to-project'), 5)
          })
          .catch(err => {
            setLoadingProgress(false)
            notification.open({
              message: t('an-error-occurred-when-creating-data', { error: err?.data?.error }),
              description: t('something-went-wrong-when-creating-data'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          })
      }
      if (isNcWms) {
        newModel.data.isNcWms = true;
        newModel.data.palette = values.palette;
        newModel.data.time = `${moment(values.date).format(dateFormat)}T${values.time || '00:00:00.000Z'}`
        axios({
          method: 'get',
          url: values.src,
          params: {
            request: 'GetMetadata',
            item: 'minmax',
            layers: values.layers,
            styles: 'default-scalar',
            bbox: newModel.data.bbox.join(','),
            srs: 'EPSG:4326',
            version: '1.1.1',
            height: 100,
            width: 100,
            time: newModel.data.time
          }
        }).then(res => {
          newModel.data.scaleRange = res.data;
          createPreDefineAccess(newModel);
        }).catch(e => {
          setLoadingProgress(false)
          notification.open({
            message: t('an-error-occurred-when-creating-data'),
            description: t('something-went-wrong-when-creating-data'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
      } else {
        createPreDefineAccess(newModel);
      }
    }
    return (
      <Form autoComplete='off' layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input placeholder={t('name')} />
        </Form.Item>
        <Form.Item
          label={t('server')}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-url'),
            },
          ]}>
          <Input onChange={(e) => { subStringURL(e.target.value) }} placeholder="URL" />
        </Form.Item>
        <Form.Item name="useProxy" valuePropName="checked">
          <Checkbox onChange={() => fetchLayers(form.getFieldValue('src'))}>{t('proxy-select-if-cannot-load-any-layers')}</Checkbox>
        </Form.Item>
        <Form.Item
          label={`${t('version')} (${t('select-if-cannot-load-any-layers')})`}
          name="version"
        >
          <Select onChange={(e) => fetchLayers(form.getFieldValue('src'), false, e)} allowClear placeholder={t('select-a-option')}>
            <Option key="1.0.0" value={'1.0.0'}>1.0.0</Option>
            <Option key="1.1.0" value={'1.1.0'}>1.1.0</Option>
            <Option key="1.1.1" value={'1.1.1'}>1.1.1</Option>
            <Option key="1.3.0" value={'1.3.0'}>1.3.0</Option>
            <Option key="2.0.0" value={'2.0.0'}>2.0.0</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={(
            <>
              {t('layer')}
              {loadingXml && <Spin indicator={loadingIcon} />}
            </>
          )}
          name="layers"
          rules={[
            {
              required: true,
              message: t('please-select-layers'),
            },
          ]}>
          <Select allowClear showSearch placeholder={t('select-a-option')} onChange={onChangeLayer}>
            {loadingXml ?
              (<Option><Spin tip={t('loading-layers')} spinning={true} /></Option>) :
              (
                layerData.map(layer => (
                  <Option key={layer} value={layer}>{layer}</Option>
                ))
              )
            }
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('username')}
              name="username"
              // autoComplete="off"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-username'),
                },
              ]}
            >
              <Input placeholder={t('username')} />
            </Form.Item>
          </Col>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('password')}
              name="password"
              // autoComplete="off"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-password'),
                },
              ]}
            >
              <Input.Password  placeholder={t('password')} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={`${t('coordinates')} ${epsg ? `(${epsg})` : ''}`}>
          <Form.Item name={['bbox', 'x1']} style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginBottom: 0 }}>
            <InputNumber placeholder="x1" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name={['bbox', 'y1']} style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}>
            <InputNumber placeholder="y1" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name={['bbox', 'x2']} style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}>
            <InputNumber placeholder="x2" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name={['bbox', 'y2']} style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}>
            <InputNumber placeholder="y2" style={{ width: '100%' }} />
          </Form.Item>
        </Form.Item>
        {(isNcWms) && (
          <Row>
            <Form.Item label={t('date-and-time')}>
              <Form.Item
                name="date"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                initialValues={selectedDate}
                rules={[
                  {
                    required: true,
                    message: t('please-select-time'),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                  format="YYYY-MM-DD"
                  inputReadOnly={true}
                  onChange={onChangeDateSerial}
                />
              </Form.Item>
              <Form.Item
                name="time"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                rules={[
                  {
                    required: true,
                    message: t('please-select-time'),
                  },
                ]}
              >

                <Select
                  initialValues="00:00:00.000Z"
                  style={{ width: '100%' }}
                  onChange={onChangeTimeSerial}
                >
                  {
                    timeSteps &&
                    timeSteps.map(time =>
                      <Option value={time} key={time}>{time}</Option>
                    )
                  }

                </Select>
              </Form.Item>
            </Form.Item>
          </Row>
        )}

        {isNcWms && (
          <Form.Item
            label={t('colour-palette')}
            name="palette"
            rules={[
              {
                required: true,
                message: t('please-select-palette'),
              },
            ]}>
            <Select allowClear showSearch placeholder={t('select-a-option')}>
              {loadingXml ?
                (<Option><Spin tip={t('loading-palettes')} spinning={true} /></Option>) :
                (
                  palettes.map(palette => (
                    <Option key={palette} value={palette}>
                      <img src={`${form.getFieldValue('src')}?request=GetLegendGraphic&height=20&width=500&numcolorbands=250&colorbaronly=true&vertical=false&palette=${palette}`} />
                    </Option>
                  ))
                )
              }
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={t('presentation-style')}
          name="classificationType"
          initialValue={terrainValue}
        >
          <Select onChange={onChangeClassificationType} allowClear placeholder={t('select-a-option')}>
            <Option key="TERRAIN" value={terrainValue} onMouseDown={handleTerrain}>{t('background-map')}</Option>
            <Option key="CESIUM_3D_TILE" value={'CESIUM_3D_TILE'}>{t('drape-to-models')}</Option>
            <Option key="BOTH" value={'BOTH'}>{t('background-and-models')}</Option>
            <Option key="PLANE" value={planeValue} onMouseDown={handlePlane}>{t('plane-with-elevation')}</Option>
          </Select>
        </Form.Item>
        {
          showPlaneForm &&
          <Form.Item
            label={t('elevation')}
            name="height"
          >
            <InputNumber placeholder={t('elevation')} style={{ width: '100%' }} />
          </Form.Item>
        }
        <Form.Item
          label={(
            <>
              {t('transparency')}
            </>
          )}
          name="alpha">
          <Slider
            style={{ margin: '10px' }}
            min={0.0}
            max={1.0}
            step={0.1}
          />
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {t('add-resource')}
          </Button>
        </Row>
      </Form>
    )
  }

  const WFSForm = () => {
    const [form] = Form.useForm();
    const [layerData, setLayerData] = useState([])
    const [xmlData, setXmlData] = useState()
    const [loadingXml, setLoadingXml] = useState(false)
    const [epsg, setEpsg] = useState()
    const [prevSrv, setPrevSrv] = useState('')
    const [isUseCredential, setUseCredentialization] = useState(false)
    const [subLayerData, setSubLayerData] = useState()
    const [outputFormat, setOutputFormat] = useState('application/json')
    const [authType, setAuthType] = useState() //authtype server, layer
    const [version, setVersion] = useState('')
    const [loadingWfs, setLoadingWfs] = useState(false)
    const [pointStyle, setPointStyle] = useState('Billboard')

    useEffect(() => {
      form.setFieldsValue({
        pointColor: '#ffffff',
        pointOutlineColor: '#000000',
        pointAlpha: 0,
        pointPixelSize: 5,
        pointOutlineWidth: 0,
        lineMaterial: '#ffffff',
        lineAlpha: 0,
        lineWidth: 2,
        polygonMaterial: '#ffffff',
        polygonOutlineColor: '#000000',
        polygonAlpha: 0,
        polygonHeight: '',
        polygonExtrudedHeight: '',
        clampToGround: true,
        outputFormat: 'application/json',
        username: '',
        password: ''
      })

      return () => {
        form.resetFields()
        setLayerData([])
        setXmlData()
        setLoadingXml(false)
        setEpsg()
        setPrevSrv('')
        setUseCredentialization(false)
        setAuthType()
        setVersion('')
        setPointStyle('Billboard')
      }
    }, [])

    /**
     * substring server url get host
     * @param {*} value //typename
     */
    const subStringURL = (value) => {
      const subServer = value.split("?")[0];
      let cutSubServer = value.replace(subServer, '')
      let layer = ''
      let subLayer = null
      if (cutSubServer)
        layer = cutSubServer.split("layers=")[1];

      if (layer)
        subLayer = layer.split("&")[0];

      if (subLayer) {
        setSubLayerData(subLayer)
      }

      if (subServer) {
        form.setFieldsValue({ src: subServer })
        onBlur(subServer)
      }
    }

    const onBlur = (src) => {
      const urlParams = src ? Utils.getAllUrlParams(src) : {}
      let urlSrc, prevSrvUrl
      try {
        urlSrc = new URL(src);
        if (prevSrv)
          prevSrvUrl = new URL(prevSrv)
      } catch (error) {
        notification.open({
          message: t('invalid-url'),
          icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          duration: 5,
        })
        return
      }

      if (prevSrvUrl && prevSrvUrl.origin !== urlSrc.origin) {
        setUseCredentialization(false)
        form.setFieldsValue({ username: undefined });
        form.setFieldsValue({ layers: undefined });
        form.setFieldsValue({ password: undefined });
      }

      try {
        if (urlParams && urlParams.request) {
          var baseUrl = src.split('?')[0]
          baseUrl += '?'
          for (const key in urlParams) {
            if (key.toLowerCase() !== 'request')
              baseUrl += `${key}=${urlParams[key]}`
          }
          src = baseUrl
          form.setFieldsValue({ src: src });
        }
      } catch (error) {

      }
      setPrevSrv(src)
      fetchLayers(src)
    }

    const fetchLayers = async (src, retry = false) => {
      setUseCredentialization(false)
      form.setFieldsValue({ layers: undefined });
      form.setFieldsValue({ bbox: { x1: '', y1: '', x2: '', y2: '' } });
      setLayerData([]);
      if (src) {
        let requestOption = {
          method: 'get',
          url: src,
          responseType: 'document',
          params: {
            request: 'GetCapabilities',
            service: 'WFS'
          }
        }

        const username = form.getFieldValue('username')
        const password = form.getFieldValue('password')
        if (username && password && isUseCredential) {
          const Authorization = btoa(`${username.trim()}:${password}`)
          requestOption.headers = {
            Authorization: `Basic ${Authorization}`
          }
        }
        setLoadingXml(true)
        const res = await axios(requestOption).catch(e => {
          if (e?.status === 401 || e?.status === 403) {
            setUseCredentialization(true)
            notification.open({
              message: t('invalid-credentials'),
              description: t('this-resource-require-credentials'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          } else {
            if (isUseCredential) {
              notification.open({
                message: t('invalid-credentials'),
                description: t('this-resource-require-credentials'),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 5,
              })
            } else if (!retry) {
              fetchLayers(form.getFieldValue('src'), true);
            }
          }
        }).finally(() => {
          setLoadingXml(false)
        })
        if (res?.data) {
          let _capabilities = res.data.getElementsByTagName('wfs:WFS_Capabilities')[0] ? res.data.getElementsByTagName('wfs:WFS_Capabilities')[0] : res.data.getElementsByTagName('WFS_Capabilities')[0]
          if (_capabilities) {
            setVersion(_capabilities.getAttribute('version'))
          }

          const layerData = res.data.querySelectorAll('FeatureTypeList > FeatureType > Name');
          setXmlData(res.data.querySelectorAll('FeatureType'))
          setLayerData(Array.prototype.map.call(layerData, element => element.innerHTML));
        }
      }
    }

    const onChangeLayer = async (value) => {
      setUseCredentialization(false)
      if (value) {
        var box = { x1: undefined, x2: undefined, y1: undefined, y2: undefined };

        form.setFieldsValue({ bbox: box });
        for (let i = 0; i < xmlData.length; i++) {
          const isGroup = xmlData[i].getElementsByTagName('FeatureTypeList').length > 0
          if (isGroup) continue
          const tagName = xmlData[i].querySelectorAll('FeatureTypeList > FeatureType > Name')[0];
          if (tagName && tagName.innerHTML === value) {
            // get boudingbox
            let x1, x2, y1, y2
            if (xmlData[i].getElementsByTagName('ows:WGS84BoundingBox') && xmlData[i].getElementsByTagName('ows:WGS84BoundingBox').length) {
              xmlData[i].getElementsByTagName('ows:WGS84BoundingBox')[0].childNodes.forEach(x => {
                let str = x.childNodes[0]?.nodeValue.split(" ")
                if (str && x.localName === 'LowerCorner') {
                  x1 = +str[0]
                  y1 = +str[1]
                }
                if (str && x.localName === 'UpperCorner') {
                  x2 = +str[0]
                  y2 = +str[1]
                }
              });

              box = { x1, y1, x2, y2 }
            } else if (xmlData[i].getElementsByTagName('LatLongBoundingBox') && xmlData[i].getElementsByTagName('LatLongBoundingBox').length) {
              x1 = +xmlData[i].getElementsByTagName('LatLongBoundingBox')[0].getAttribute('minx')
              x2 = +xmlData[i].getElementsByTagName('LatLongBoundingBox')[0].getAttribute('maxx')
              y1 = +xmlData[i].getElementsByTagName('LatLongBoundingBox')[0].getAttribute('miny')
              y2 = +xmlData[i].getElementsByTagName('LatLongBoundingBox')[0].getAttribute('maxy')
              box = { x1, y1, x2, y2 }
            } else if (xmlData[i].getElementsByTagName('BoundingBox') && xmlData[i].getElementsByTagName('BoundingBox').length) {
              const boundingBoxs = xmlData[i].getElementsByTagName('BoundingBox');
              for (let j = 0; j < boundingBoxs.length; j++) {
                if (
                  boundingBoxs[j].getAttribute('SRS')?.toUpperCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('srs')?.toLowerCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('CRS')?.toLowerCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('crs')?.toLowerCase() === 'EPSG:4326'
                ) {
                  x1 = +boundingBoxs[j].getAttribute('minx')
                  x2 = +boundingBoxs[j].getAttribute('maxx')
                  y1 = +boundingBoxs[j].getAttribute('miny')
                  y2 = +boundingBoxs[j].getAttribute('maxy')
                  box = { x1, y1, x2, y2 }
                }
              }
            }

            // get SRS name
            let srsName = 'EPSG:4326'
            if (xmlData[i].getElementsByTagName('DefaultCRS') && xmlData[i].getElementsByTagName('DefaultCRS').length) {
              srsName = xmlData[i].getElementsByTagName('DefaultCRS')[0].innerHTML
            } else if (xmlData[i].getElementsByTagName('wfs:DefaultCRS') && xmlData[i].getElementsByTagName('wfs:DefaultCRS').length) {
              srsName = xmlData[i].getElementsByTagName('wfs:DefaultCRS')[0].innerHTML
            } else if (xmlData[i].getElementsByTagName('SRS') && xmlData[i].getElementsByTagName('SRS').length) {
              srsName = xmlData[i].getElementsByTagName('SRS')[0].innerHTML
            } else if (xmlData[i].getElementsByTagName('DefaultSRS') && xmlData[i].getElementsByTagName('DefaultSRS').length) {
              srsName = xmlData[i].getElementsByTagName('DefaultSRS')[0].innerHTML
            } else if (xmlData[i].getElementsByTagName('OtherSRS') && xmlData[i].getElementsByTagName('OtherSRS').length) {
              srsName = xmlData[i].getElementsByTagName('OtherSRS')[0].innerHTML
            }

            try {
              //if (!validDegreeBox(box)) throw box; // because bbox maybe lat, long, height or xyz
              if (!x1 || !y1 || !x2 || !y2) return
              form.setFieldsValue({ bbox: box });
              setEpsg(Number(srsName.match(/\d+$/)[0]));

              //check valid wfs outputformat              
              let rescheck = await checkValidGeoURL({ src: form.getFieldValue('src'), outputFormat: ['application/json', version === '1.0.0' ? 'GML2' : 'GML3'], layer: form.getFieldValue('layers'), bbox: form.getFieldValue('bbox'), username: form.getFieldValue('username'), password: form.getFieldValue('password'), version: version })
              if (rescheck.status !== 200) {
                if (rescheck.status === 401) {
                  setUseCredentialization(true)
                } else {
                  form.setFieldsValue({ layers: undefined });
                  form.setFieldsValue({ bbox: { x1: undefined, x2: undefined, y1: undefined, y2: undefined } });
                }
                notification.open({
                  message: t('error'),
                  description: t(rescheck.message),
                  icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />
                })
              }
            } catch (error) {
              box = undefined
              notification.open({
                message: t('wfs-server-error'),
                description: t('Requested WFS not supported'),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 5,
              })
            }
            break;
          }
        }
      }
    }

    const gml2geojson = (gml, version) => {
      var features
      if (version === '1.0.0') {
        features = new GML2().readFeatures(gml);
      } else {
        features = new GML3().readFeatures(gml);
      }
      var geoJsonStr = new GeoJSON().writeFeatures(features);
      return JSON.parse(geoJsonStr)
    }

    /**
     * check valid GetFeature
     * @param {*} data { src: src, outputFormat: outputFormat, layer: layer, bbox: bbox, username, password, version }
     * @returns {status, data, message} //status: 200, 401, 400, 404
     */
    const checkValidGeoURL = async (data) => {
      let { src, outputFormat, layer, bbox, username, password, version } = data
      let _bbox = [bbox.x1, bbox.y1, bbox.x2, bbox.y2].join(',')

      let resl
      for (const _outputformat of outputFormat) {
        setOutputFormat(_outputformat)
        let requestOption = {
          method: 'get',
          url: src,
          params: {
            service: 'WFS',
            request: 'GetFeature',
            outputFormat: _outputformat,
            typeName: layer,
            bbox: _bbox,
            maxFeatures: 1,
          }
        }

        if (version) {
          if (version === '2.0.0') requestOption.params.count = 1
          requestOption.params.version = version
        }

        if (username && password && isUseCredential) {
          const Authorization = btoa(`${username.trim()}:${password}`)
          requestOption.headers = {
            'Authorization': `Basic ${Authorization}`,
            'Access-Control-Allow-Origin': '*',
          }
        }
        let res = await axios(requestOption).then((_res) => {
          return { status: 200, data: _res.data, message: 'success' }
        }).catch(e => {
          if (e?.status === 401 || e?.status === 403) {
            let message = t('data-needs-authentication')
            if (username && password) {
              message = t('authentication-failed')
            }
            return { status: 401, data: undefined, message: message }
          }

          let message = t('layer-is-not-valid');
          if (e?.data) {
            message = Utils.strip_html_tags(e.data)
          }

          return { status: 400, data: undefined, message: message }
        })
        if (res && res.status === 200 && res.data) {
          try {
            let wfs_data
            if (['GML2', 'GML3'].includes(_outputformat)) {
              wfs_data = gml2geojson(res.data, version)
            } else {
              let features = new GeoJSON().readFeatures(res.data)
              wfs_data = new GeoJSON().writeFeatures(features);
            }
            res.data = wfs_data
            resl = res
            break; //exit loop
          } catch (error) {
            res.message = t('requested-outputFormat-not-supported')
            res.status = 404
          }
        } else {
          resl = res
          if (res?.status === 401) {
            break; //exit loop
          }
        }
      }

      return resl
    }

    const handleSubmit = async () => {
      form.validateFields().then(async res => {
        setLoadingWfs(true)
        if (!res.bbox || !res.bbox.x1 || !res.layers) {
          return
        }
        let newModel = {
          name: res.name ? res.name : 'Unnamed',
          hash: '',
          data: {
            layers: res.layers
          },
          sourceType: 'WFS',
          src: res.src,
        }
        newModel.data.outputFormat = outputFormat
        newModel.data.bbox = [res.bbox.x1, res.bbox.y1, res.bbox.x2, res.bbox.y2]
        newModel.data.epsg = +epsg;

        // need convert to 4326
        if (!validDegreeBox(res.bbox)) {
          const _bbox = []
          let _convertPLToWGS841 = await projectStore.convertProjectPlaneToWGS84(res.bbox.y1, res.bbox.x1, 0, epsg, 'None')
          if (_convertPLToWGS841.Status === 'OK') {
            _bbox.push(_convertPLToWGS841.Point[1])
            _bbox.push(_convertPLToWGS841.Point[0])
          }
          let _convertPLToWGS842 = await projectStore.convertProjectPlaneToWGS84(res.bbox.y2, res.bbox.x2, 0, epsg, 'None')
          if (_convertPLToWGS842.Status === 'OK') {
            _bbox.push(_convertPLToWGS842.Point[1])
            _bbox.push(_convertPLToWGS842.Point[0])
          }
          if (_bbox.length === 4) {
            newModel.data.projectPlaneToWGS84 = { bbox: _bbox, epsg: 4326 }
          }
        }

        newModel.data.clampToGround = res.clampToGround;
        newModel.data.style = {
          point: {
            isShowPointAs: res.isShowPointAs, //Billboard; Point
            color: res.pointColor,
            alpha: res.pointAlpha,
            pixelSize: res.pointPixelSize ?? 5,
            outlineColor: res.pointOutlineColor ? res.pointOutlineColor : '#000000',
            outlineWidth: res.pointOutlineWidth ?? 0,
          },
          line: {
            width: res.lineWidth ?? 2,
            color: res.lineMaterial,
            alpha: res.lineAlpha,
          },
          polygon: {
            color: res.polygonMaterial,
            alpha: res.polygonAlpha,
            height: res.polygonHeight,
            outlineColor: res.polygonOutlineColor,
            extrudedHeight: res.polygonExtrudedHeight,
          }
        }
        newModel.data.version = version ? version : '';
        newModel.data.isUseCredential = isUseCredential !== undefined ? isUseCredential : false;
        newModel.data.username = isUseCredential && res.username ? res.username : '';
        newModel.data.password = isUseCredential && res.password ? res.password : '';

        if (isUseCredential) {
          let resp = await checkValidGeoURL({ src: res.src, outputFormat: [outputFormat], layer: res.layers, bbox: res.bbox, username: res.username, password: res.password, version: res.version })
          if (!resp || resp?.status !== 200) {
            notification.open({
              message: t('error'),
              description: t(resp.message),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />
            })
            setLoadingWfs(false)
            return
          }
        }
        await createModel(newModel);
        setLoadingWfs(false)
      }).catch(error => {
        console.log(error)
        setLoadingWfs(false)
      })
    }

    const createModel = async (newModel) => {
      adminStore
        .createPreDefinedAccess(newModel)
        .then(async (model) => {
          adminStore.getPreDefinedAccesss()
          setLoadingWfs(false)
          onCancel()
          message.success(t('data-added-to-project'), 5)
        })
        .catch(err => {
          setLoadingWfs(false)
          notification.open({
            message: t('an-error-occurred-when-creating-data', { error: err?.data?.error }),
            description: t('something-went-wrong-when-creating-data'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }

    const handleChangePointStyle = (value) => {
      setPointStyle(value)
    };

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input placeholder={t('name')} />
        </Form.Item>
        <Form.Item
          label={t('server-url')}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-url'),
            },
          ]}>
          <Input onChange={(e) => { subStringURL(e.target.value) }} placeholder="URL" />
        </Form.Item>
        <Form.Item
          label={(
            <>
              {t('layer')}
              {loadingXml && <Spin indicator={loadingIcon} />}
            </>
          )}
          name="layers"
          rules={[
            {
              required: true,
              message: t('please-select-layers'),
            },
          ]}>
          <Select allowClear showSearch placeholder={t('please-select')} onChange={(e) => { onChangeLayer(e) }}>
            {loadingXml ?
              (<Option><Spin tip={t('loading-layers')} spinning={true} /></Option>) :
              (
                layerData.map(layer => (
                  <Option key={layer} value={layer}>{layer}</Option>
                ))
              )
            }
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('username')}
              // autoComplete="off"
              name="username"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-username'),
                },
              ]}
            >
              <Input  placeholder={t('username')} />
            </Form.Item>
          </Col>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('password')}
              // autoComplete="off"
              name="password"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-password'),
                },
              ]}
            >
              <Input.Password  placeholder={t('password')} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={`${t('coordinates')} ${epsg ? `(${epsg})` : ''}`}>
          <Form.Item
            name={['bbox', 'x1']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="x1" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'y1']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="y1" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'x2']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="x2" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'y2']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="y2" style={{ width: '100%' }} />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label={t('clamp-to-ground')}
          name="clampToGround"
          valuePropName="checked"
          className="form-horizontal">
          <Switch
            checkedChildren={t('commons.on')}
            unCheckedChildren={t('commons.off')}
            style={{ marginLeft: '5px' }}
          />
        </Form.Item>
        <Divider orientation="left" orientationMargin="0">
          {t('point-style')}
        </Divider>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('point-style')}
                className="form-horizontal"
                name="isShowPointAs"
                initialValue={'Billboard'}
              >
                <Select
                  placeholder={t('select-filter')}
                  style={{ width: '250px' }}
                  onChange={handleChangePointStyle}
                >
                  <Option value="Billboard">{t('shows-points-as-billboards')}</Option>
                  <Option value="Point">{t('shows-points-as-point-graphics')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('color')}
                className="form-horizontal"
                name="pointColor"
                initialValue={'#ffffff'}
              >
                <input type="color" className='custom-input-color' />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              {pointStyle === 'Point' ?
                (<Form.Item
                  label={t('outline-color')}
                  className="form-horizontal"
                  initialValue={'#ffffff'}
                  name="pointOutlineColor">
                  <input type="color" className='custom-input-color' />
                </Form.Item>) : ''
              }
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('transparency')}
                name="pointAlpha">
                <Slider
                  style={{ marginRight: '7px' }}
                  min={0.0}
                  max={1.0}
                  step={0.1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={`${t('size')} (px)`}
                className="form-horizontal"
                name="pointPixelSize">
                <InputNumber min={1} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              {pointStyle === 'Point' ?
                (<Form.Item
                  label={t('outline-width')}
                  className="form-horizontal"
                  name="pointOutlineWidth">
                  <InputNumber min={0} step={1} />
                </Form.Item>) : ''
              }
            </Col>
          </Row>
        </Form.Item>
        <Divider orientation="left" orientationMargin="0">
          {t('line-style')}
        </Divider>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('color')}
                className="form-horizontal"
                initialValue={'#ffffff'}
                name="lineMaterial">
                <input type="color" className='custom-input-color' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('transparency')}
                className="form-horizontal"
                name="lineAlpha">
                <Slider
                  style={{ marginRight: '7px' }}
                  min={0}
                  max={1.0}
                  step={0.1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={`${t('width')} (px)`}
                className="form-horizontal"
                name="lineWidth">
                <InputNumber min={1} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Divider orientation="left" orientationMargin="0">
          {t('polygon-style')}
        </Divider>
        <Form.Item>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('color')}
                className="form-horizontal"
                initialValue={'#ffffff'}
                name="polygonMaterial">
                <input type="color" className='custom-input-color' />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('outline-color')}
                className="form-horizontal"
                initialValue={'#ffffff'}
                name="polygonOutlineColor">
                <input type="color" className='custom-input-color' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('transparency')}
                className="form-horizontal"
                name="polygonAlpha">
                <Slider
                  style={{ marginRight: '7px' }}
                  min={0.0}
                  max={1.0}
                  step={0.1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('elevation')}
                className="form-horizontal"
                name="polygonHeight">
                <InputNumber min={0.0} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('extrusion')}
                className="form-horizontal"
                name="polygonExtrudedHeight">
                <InputNumber min={0} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button loading={loadingWfs} type="primary" htmlType="submit">
            {t('add-resource')}
          </Button>
        </Row>
      </Form>
    )
  }

  const Form3DCityDB = () => {
    const [form] = Form.useForm();

    const handleSubmit = async values => {
      setLoadingProgress(true)
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {},
        sourceType: 'City3DDB'
      }
      newModel.src = values.src
      adminStore
        .createPreDefinedAccess(newModel)
        .then(async (model) => {
          adminStore.getPreDefinedAccesss()
          setLoadingProgress(false)
          onCancel()
          message.success(t('create-successfully'), 5)
        }).catch(err => {
          setLoadingProgress(false)
          notification.open({
            message: t('an-error-occurred-when-creating-data', { error: err?.data?.error }),
            description: t('something-went-wrong-when-creating-data'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input placeholder={t('name')} />
        </Form.Item>
        <Form.Item
          label="URL"
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-external-link'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {t('add-resource')}
          </Button>
        </Row>
      </Form>
    )
  }

  useEffect(() => {
  }, [projectStore.progressFileOnS3Ion])

  const onCancel = () => {
    adminStore.setShowAddResourceModel(false)
    projectStore.setDisplayPanel(true)
  }

  return (
    <>
      <Modal
        width={500}
        centered={true}
        onCancel={onCancel}
        visible={adminStore.showAddResourceModel}
        title={t('library')}
        icon={<BuildOutlined />}
        iconType="build"
        zIndex={9999}
        footer={false}
        maskClosable={false}
      >
        <Tabs activeKey={adminStore.currentAddTab} onChange={onChangeTab}>
          <TabPane
            tab={
              <span>
                {t('3d-tiles')}
              </span>
            }
            key="externalLink"
          >
            <ExternalForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                Cesium Ion
              </span>
            }
            key="cesiumIon"
          >
            <CesiumIonForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                WMS
              </span>
            }
            key="WMS"
          >
            <WMSForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                WFS
              </span>
            }
            key="WFS"
          >
            <WFSForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                {t('3d-city-db')}
              </span>
            }
            key="3DCityDB"
          >
            <Form3DCityDB />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  )

}

export default withRouter(inject('projectStore', 'commonStore', 'adminStore')(observer(ModalAddResources)))
