import styled from 'styled-components'

export const TopicPageWrapper = styled.div`
  .ant-tag{
    margin-bottom: 5px;
    cursor: pointer;
  }
  .ant-tag:last-child{
    margin-right: 0
  }

  .ant-typography {
    cursor: pointer;
    &.selected {
      cursor: pointer;      
      color: #fff;
      background-color: #F26524;
      padding: 4px 7px;
      font-size: 12px;
      line-height: 20px;    
      border-radius: 4px;
    }
  }
  .ant-table-body {
    overflow-x: auto !important;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 10px 10px;   

  //   .ant-table {
  //     display: block;
  //     @media screen and (max-width: 600px) {
  //       &-thead {
  //         display: none;
  //       }
    
  //       &-thead>tr,
  //       &-tbody>tr {
  //         th,
  //         td {
  //           &:first-of-type {
  //             padding-top: 1rem;
  //           }
    
  //           &:last-of-type {
  //             padding-bottom: 1rem;
  //           }
  //         }
    
  //         >th,
  //         >td {
  //           display: block;
  //           width: auto !important;
  //           border: none;
  //           padding: 0 1rem;
  //           font-size: 1.1rem;
  //           text-align: left !important;
  //           &:last-child {
  //             border-bottom: 1px solid #eee;
  //           }
  //         }
  //       }
  //     }
  //   }
  // .ant-table .ant-table-selection-column {
  //     width: 150px
  // }
  .ant-select-dropdown {
    z-index: 999999;
  }  
`
export const TopicPageHeading = styled.div`
  padding: 20px 0;    
  display: inline;
  .headingtext {
    font-size: 24px;
    color: #ff8d4f;
    vertical-align: middle;
    margin-right: 20px;
  }
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
  }
`
export const ProjectCoverWrapper = styled.div`
  background: #FFFFFF;
  padding: 60px 15px 15px;
  margin-top: 55px;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`
export const ColStyled = styled.div`
  .completeness {
    display: flex;
    line-height: 47px;
    flex-wrap:wrap;
   
	flex-direction: column;
  }
  .percen-text {
    margin: 0 auto;
    width: 20%;
    text-align: right;
  }  
`
export const FormStyled = styled.div` 
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  .ant-form-item{
    margin-bottom: 8px;
  }
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
    padding: 0 13px;
    // height: 40px;    
  }
  .ant-btn.lastbtn {
    margin-right: 0px;
  }
  .ant-progress-custom {
    margin-bottom: 10px;
  }
  .label{    
      position: relative; 
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px; 
  }
  .footerbar{
    text-align: center;
    padding: 20px;
  }
  .ant-form-item-label {
    line-height: inherit;
  }
  .react-multi-carousel-item {
    padding: 10px;
  }
  .react-multiple-carousel__arrow {
    min-width: 30px !important;
    min-height: 30px !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(1% + 1px) !important;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px) !important;
  }
  .location-image-carousel{
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
  }  
`

export const CheckListStyled = styled.div`
  min-height: 200px;
  overflow: auto;
`

export const ReplyHeaderStyle = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  margin: 0;
  padding: 5px;
  border-radius: .28571429rem;
  border: 1px solid rgba(34,36,38,.15);
  font-size: 1em;
  line-height: 1.4285em;
  color: rgba(0,0,0,.87);
  .cancel-right{
    width: auto;
    left: auto;
    right: 0;
    border-radius: .21428571rem 0 .28571429rem 0;
    position: absolute;
    margin: 0;    
    background-color: #db2828!important;
    border-color: #db2828!important;
    color: #fff!important;
    cursor: pointer;
    line-height: 1;
    padding: 2px 5px;
    min-width: auto;
    height: auto;
    bottom: 0;
  }
  .reply-content{
    background: #f3f4f5;
    color: rgba(0,0,0,.6);
    margin-bottom: 0;    
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15); 
    padding: 10px;
    border-radius: .28571429rem;
    border: 1px solid rgba(34,36,38,.15);
    p {
      margin: 0;   
      line-height: 1.4285em;
      color: rgba(0,0,0,.6);
    }
  }
  .reply-date {
    color: rgba(0,0,0,.4);
    font-size: .875em;
  }
`
export const CommentListStyle = styled.div`
  overflow: auto;
  margin-bottom: 5px;
  .ant-comment {
    line-height: 1.5;
    min-width: 200px;
  }
  .ant-comment-actions {
    margin: 0;
  }
`
