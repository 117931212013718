import { AutoComplete, Button, Form, message, Modal, Row, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from './../../../../src/validator';
const { Option } = Select

const AddTeamMemberModal = props => {
  const { t } = useTranslation();
  const { projectTeamsStore, projectStore, organizationStore, adminStore } = props
  const [form] = Form.useForm();
  const roles = projectTeamsStore.projectRoles
  const [listUserOrganization, setListUserOrganization] = useState([]);
  const [checkValidateEmail, setCheckValidateEmail] = useState(true)

  useEffect(() => {
    if (projectTeamsStore.showAddTeamMemberModal) {
      form.resetFields()
      if (!projectStore.projectDetail.organization) return
      organizationStore.setLoadingProgress(true)
      organizationStore
        .getAllUserInOraganization(projectStore.projectDetail.organization.id)
        .then(res => {
          let v = res.filter(c => c.email !== "maintenance@xd-visuals.fi" || c?.user?.username !== "maintenance")
          .map(elm => {
            return {
              id: elm.id,
              email: elm?.email,
              user: elm?.user,
              username: elm?.user?.username,
              invitestatus: elm.inviteStatus,
              key: elm.id,
              value: elm?.user
                ? elm?.user?.firstName && elm?.user?.lastName
                  ? elm.user?.firstName + ' ' + elm.user?.lastName
                  : !elm?.user?.firstName && elm?.user?.lastName
                  ? elm.user?.lastName
                  : elm?.user?.firstName && !elm?.user?.lastName
                  ? elm.user?.firstName
                  : elm.user?.username
                : elm.email,
            }
          })
          setListUserOrganization(v)
          organizationStore.setLoadingProgress(false)
        })
        .catch(err => {
          console.log(err)
          organizationStore.setLoadingProgress(false)
        })
    }
    return () => {
      setListUserOrganization([])
    }
  }, [projectTeamsStore.showAddTeamMemberModal, projectStore.projectDetail.organization])

  const onChange = (value) => {
    for (var i = 0; i < listUserOrganization.length; i++) {
      let item = listUserOrganization[i].value
      if (item === value) {
        setCheckValidateEmail(false)
        break
      }
      setCheckValidateEmail(true)
    }
  }

  const handleSubmit = () => {
    form.validateFields().then(async values => {
      let res
      let emailParam = { number: adminStore.licenseMaxUser === 'UNLIMITTED' ? 99999 : adminStore.licenseMaxUser === 'UNLIMITTED' }
      listUserOrganization.map(elm => {
        let _cur = elm?.user ? (
          elm?.user?.firstName && elm?.user?.lastName ?
            elm.user?.firstName + ' ' + elm.user?.lastName : !elm?.user?.firstName && elm?.user?.lastName ?
              elm.user?.lastName : elm?.user?.firstName && !elm?.user?.lastName ?
                elm.user?.firstName : elm.user?.username
        ) : elm.email

        if (_cur !== values.email) return
        res = elm
      })
      if (res) {
        emailParam = res.email
      } else {
        emailParam = values.email.trim()
      }
      const currentLicenseNumber = adminStore.licenseMaxUser === 'UNLIMITTED' ? 999999 : adminStore.licenseMaxUser
      projectStore.setLoadingProgress(true)
      await adminStore.sendInviteUserToProject(emailParam, values.role, projectStore.projectDetail.id, currentLicenseNumber).then(async res => {
        if (res?.data?.error) {
          projectStore.setLoadingProgress(false)
          message.error(t(res.data.error))
          return
        }
        await projectTeamsStore.getProjectTeams(projectStore.projectDetail.id)
        projectTeamsStore.toggleShowAddTeamMemberModal(false)
        message.success(t('invitation-sent-successfully'))
        projectStore.setLoadingProgress(false)
      }).catch(err => {
        projectStore.setLoadingProgress(false)
      })
    })
  }

  return (
    <Modal
      title={t('invite-member')}
      onCancel={() => projectTeamsStore.toggleShowAddTeamMemberModal(false)}
      okText={t('send-invite')}
      cancelText={t('commons.cancel')}
      visible={projectTeamsStore.showAddTeamMemberModal}
      maskClosable={false}
      footer={[
        <Row justify='end' key='group-btn'>
          <Button key="back" onClick={() => projectTeamsStore.toggleShowAddTeamMemberModal(false)}>
            {t('commons.cancel')}
          </Button>
          <Button key="submit" type="primary" onClick={e => handleSubmit()}>
            {t('send-invite')}
          </Button>,
        </Row>
      ]}
      zIndex={9999}
    >
      <Form
        form={form}
        layout="vertical"
        id={'invite-member-form'}>
        <Form.Item
          label={t('user-email')}
          name="email"
          rules={[
            {
              required: true,
              message: t('please-input-user-email'),
            },
            { validator: checkValidateEmail ? validator.validateEmail : false },
          ]}
        >
          <AutoComplete
            options={listUserOrganization}
            onChange={onChange}
            placeholder={t('input-user-email')}
            filterOption={(inputValue, option) =>
              option.username.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 || option.email.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 || option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Form.Item>
        <Form.Item
          label={t('role')}
          name="role"
          rules={[
            {
              required: true,
              message: t('please-select-role'),
            },
          ]}
        >
          <Select
            placeholder={t('select-role')}>
            {
              Object.keys(roles).map((key) => {
                return (
                  <Option key={key} value={key}>{roles[key].roleName}</Option>
                )
              })
            }
          </Select>
        </Form.Item>
      </Form>
    </Modal >
  )
}

export default inject('projectTeamsStore', 'projectStore', 'organizationStore', 'adminStore')(observer(AddTeamMemberModal))