import React, { useEffect, useState, useRef, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, InputNumber, Cascader, Modal, Switch, Input } from 'antd'
import Konva from "konva"
import axios from 'axios'
import {
  calculateWH
} from '../../helper/CesiumUtils'

const ARLineMatch = ({ arStore, clientWidth, clientHeight, pairs }) => {

  // const [imgCount, setImgCount] = useState(0)



  // function reset() {
  //   points = []
  //   halfWidth = clientWidth / 2
  //   imgPos = [0, 0, halfWidth, 0]
  //   imgScaleX = [1, 1]
  //   imgScaleY = [1, 1]
  //   imgCount = 0
  // }
  // useEffect(() => {

  //   return () => {

  //   }
  // }, [arStore.currentTestData])

  function drawCircleLine(x, y, radius, angle, c, layer) {
    let x2 = x - radius * Math.sin(angle * Math.PI / 180)
    let y2 = y - radius * Math.cos(angle * Math.PI / 180)
    var myLine = new Konva.Line({
      // points: [x1 * imgScaleX[0] + imgPos[0], y1 * imgScaleY[0] + imgPos[1], x2 * imgScaleX[1] + imgPos[2], y2 * imgScaleY[1] + imgPos[3]],
      points: [x, y, x2, y2],
      // points: [5, 70, 140, 23, 250, 60, 300, 20],
      stroke: c,
      strokeWidth: 4,
      lineJoin: 'round',
    });
    layer.add(myLine);
  }

  useEffect(() => {
    // if (!arStore.currentTestData || !arStore.currentCalbAr) return
    var points = []
    var radius = false
    var orientation = false
    var halfWidth = clientWidth / 2
    var imgPos = [0, 0, halfWidth, 0]
    var imgScaleX = [1, 1]
    var imgScaleY = [1, 1]
    var imgCount = 0
    var ratio = 1
    var circleScale = [1, 1]
    if (arStore.currentTestData === false) return
    if (arStore.currentTestData.modelRenderInfo.calibration) {
      points = arStore.currentTestData.modelRenderInfo.calibration.Points
      if (arStore.currentTestData.modelRenderInfo.calibration.Radius)
        radius = arStore.currentTestData.modelRenderInfo.calibration.Radius
    }
    if (arStore.currentCalbAr !== 'cal-0') {
      let findCalId = arStore.calibrations.findIndex(x => x.id === arStore.currentCalbAr)
      if (findCalId > -1) {
        let findArId = arStore.calibrations[findCalId].data.artests.findIndex(x => x.id === arStore.currentTestData.id)
        if (findArId > -1) {
          let result = arStore.calibrations[findCalId].data.artests[findArId].result
          if (result.Status === 'OK') {
            points = result.Points
            if (result.Radius)
              radius = result.Radius
            if (result.Orientations)
              orientation = result.Orientations
          }
          else
            points = false
        }
      }
    }
    if (!points) {
      alert('no matching data')
      points = []
    }
    // console.log('radius', radius)

    // points = [768 , 439 , 698 , 90  ]
    var stage = new Konva.Stage({
      container: "kv-canvas",
      width: clientWidth,
      height: clientHeight
    });

    var layer = new Konva.Layer();
    var lineLayer = new Konva.Layer()
    // var dragLayer = new Konva.Layer();
    stage.add(layer);
    stage.add(lineLayer);


    // console.log('arStore.currentTestData', arStore.currentTestData)
    let csize = arStore.currentTestData.modelRenderInfo.CanvasSize

    ratio = csize.width / csize.height


    var s = calculateWH(csize.width, csize.height, halfWidth, clientHeight)
    var numberLines = 0
    if (points.length > 0)
      numberLines = points.length / 4
    var text = new Konva.Text({
      x: 10,
      y: 10,
      fontSize: 14,
      fill: 'green',
      text: 'Number of lines: ' + numberLines.toString()
    });
    layer.add(text);

    function drawMatchLine(myLayer, x1, y1, x2, y2, color, radius1, radius2, o1, o2) {
      var c = "black"
      if (!color) {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        c = "rgb(" + r + "," + g + "," + b + " )"
      } else {
        c = color
      }

      var circle1 = new Konva.Circle({
        x: x1 * imgScaleX[0] + imgPos[0],
        y: y1 * imgScaleY[0] + imgPos[1],
        // radius: radius1 * imgScaleX[0],
        radius: radius1 * circleScale[1],
        // radius: radius1 * circleScale[0],
        stroke: c,
        strokeWidth: 2
      });

      if (o1) {
        drawCircleLine(circle1.x(), circle1.y(), circle1.radius(), o1, c, myLayer)
      }

      var circle2 = new Konva.Circle({
        x: x2 * imgScaleX[1] + imgPos[2],
        y: y2 * imgScaleY[1] + imgPos[3],
        // radius: radius2 * imgScaleX[1],
        radius: radius2 * circleScale[1],
        stroke: c,
        strokeWidth: 2
      });

      if (o2) {
        drawCircleLine(circle2.x(), circle2.y(), circle2.radius(), o2, c, myLayer)
      }

      var xx1 = x1 * imgScaleX[0] + imgPos[0]
      var yy1 = y1 * imgScaleY[0] + imgPos[1]
      var xx2 = x2 * imgScaleX[1] + imgPos[2]
      var yy2 = y2 * imgScaleY[1] + imgPos[3]
      // console.log('imgScaleX', imgScaleX)
      // console.log('imgScaleY', imgScaleY)
      // console.log('imgPos', imgPos)
      // var xx1 = x1 + imgPos[0] / imgScaleX[0]
      // var yy1 = y1 + imgPos[1] / imgScaleY[0]
      // var xx2 = x2 + imgPos[2] / imgScaleX[1]
      // var yy2 = y2 + imgPos[3] / imgScaleY[1]

      var points = [xx1, yy1, xx2, yy2]
      // console.log('points', points)
      var myLine = new Konva.Line({
        // points: [x1 * imgScaleX[0] + imgPos[0], y1 * imgScaleY[0] + imgPos[1], x2 * imgScaleX[1] + imgPos[2], y2 * imgScaleY[1] + imgPos[3]],
        points,
        // points: [5, 70, 140, 23, 250, 60, 300, 20],
        stroke: c,
        strokeWidth: 2,
        lineJoin: 'round',
      });
      myLayer.add(myLine);
      myLayer.add(circle1);
      myLayer.add(circle2);
    }

    function drawAllLine() {
      for (let index = 0; index < points.length / 4; index++) {
        var ii = index * 4
        var r1 = false
        var r2 = false
        var o1 = false
        var o2 = false
        if (radius) {
          r1 = radius[index * 2]
          r2 = radius[index * 2 + 1]
        }

        if (orientation) {
          o1 = orientation[index * 2]
          o2 = orientation[index * 2 + 1]
        }

        // drawMatchLine(lineLayer, points[ii], points[ii + 1], points[ii + 2], points[ii + 3], false)
        drawMatchLine(lineLayer, points[ii + 2], points[ii + 3], points[ii], points[ii + 1], false, r2, r1, o2, o1)
      }
      lineLayer.batchDraw();
    }

    function addImage(url, imgLayer, index) {
      var imageObj = new Image();
      imageObj.src = url;
      imageObj.onload = function () {
        this.image = imageObj;
        // console.log('this.image.width', this.image.width)
        // console.log('this.image.height', this.image.height)
        var ratio = this.image.width / this.image.height
        var imgOptions = {
          scaleX: 1,
          scaleY: 1,
          image: imageObj,
        }
        if (s.height > s.width) {
          imgOptions.height = s.height
          imgOptions.width = s.height * ratio
        }
        else {
          imgOptions.width = s.width
          imgOptions.height = s.width / ratio
        }

        if (ratio < 1 && index == 0)
          imgPos[index] = imgPos[index] + (halfWidth - imgOptions.width)

        imgPos[index + 1] = imgPos[index + 1] + (clientHeight - imgOptions.height) / 2

        imgOptions.x = imgPos[index]
        imgOptions.y = imgPos[index + 1]

        imgScaleX[index / 2] = imgOptions.width / this.image.width
        imgScaleY[index / 2] = imgOptions.height / this.image.height

        if (ratio > 1)
          circleScale[index / 2] = imgScaleX[index / 2]
        else
          circleScale[index / 2] = imgScaleY[index / 2]

        var kImg = new Konva.Image(imgOptions);
        // add the shape to the layer
        imgLayer.add(kImg);
        imgLayer.batchDraw();
        imgCount++
        if (imgCount == 2) {
          drawAllLine()
        }
      };

    }
    // console.log('s', s)
    if (arStore.currentTestData.imageModelRender)
      addImage(arStore.currentTestData.imageModelRender.url, layer, 0)
    if (arStore.currentTestData.photoDevice)
      addImage(arStore.currentTestData.photoDevice.url, layer, 2)

    return () => {
      points = []
      radius = []
      halfWidth = clientWidth / 2
      imgPos = [0, 0, halfWidth, 0]
      imgScaleX = [1, 1]
      imgScaleY = [1, 1]
      imgCount = 0
      stage.destroy()
    }
  }, [arStore.currentTestData, arStore.currentCalbAr])

  return (
    <div>
      <div id="kv-canvas" style={{ width: clientWidth, height: clientHeight }}>
      </div>
    </div>
  )
}
export default inject('arStore')(observer(ARLineMatch))
