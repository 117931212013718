import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Entity, BillboardGraphics, LabelGraphics, Label, LabelCollection } from 'resium'
import { Cartesian3, Math as CesiumMath, DistanceDisplayCondition, Color, VerticalOrigin, HorizontalOrigin } from 'cesium'

import testicontopic from '../../../../assets/imgs/topic.png'
import TopicsNoType from '../../../../assets/form-elems/TopicsNoType.png'
import TopicsComment from '../../../../assets/form-elems/TopicsComment.png'
import TopicsIdea from '../../../../assets/form-elems/TopicsIdea.png'
import TopicsIssue from '../../../../assets/form-elems/TopicsIssue.png'
import TopicsNote from '../../../../assets/form-elems/TopicsNote.png'
import TopicsRisk from '../../../../assets/form-elems/TopicsRisk.png'
import TopicsTask from '../../../../assets/form-elems/TopicsTask.png'
import uuid from 'uuid'
import { toJS } from 'mobx'
import { useHistory } from 'react-router-dom'

const TopicLocationView = props => {
    const { viewer, topicLocationData, topicStore } = props
    const [topicLocationViews, setTopicLocationView] = useState([])
    const history = useHistory()

    const handleClickEditTopic = (topic) => {
        let name = encodeURIComponent(topic.name);
        history.push(`${props.location.pathname}?topic=${name}`)
        topicStore.setShowTopicEditor(true, false, topic)
    }


    const renderImage = (topictype) => {
        switch (topictype) {
            case 'Note':
                return TopicsNote;
            case 'Comment':
                return TopicsComment;
            case 'Idea':
                return TopicsIdea;
            case 'Task':
                return TopicsTask;
            case 'Issue':
                return TopicsIssue;
            case 'Risk':
                return TopicsRisk;
            case 'No type':
                return TopicsNoType;
            default:
                return testicontopic;
        }
    }

    const renderColor = (topicpriority) => {
        switch (topicpriority) {
            case 'Low':
                return new Color.fromCssColorString('#1a7fe6');
            case 'Normal':
                return new Color.fromCssColorString('#29a329');
            case 'High':
                return new Color.fromCssColorString('#e69e1a');
            case 'Critical':
                return new Color.fromCssColorString('#f07676');
            default:
                return Color.WHITE;
        }
    }

    /**
     * Add icon topic to view
     * @param {*} data object topics
     */
    const addIconTopicLocation = data => {
        if (data.location3D.location) {
            const ref = React.createRef()
            const cartesians = new Cartesian3(data.location3D.location.x, data.location3D.location.y, data.location3D.location.z)
            let icontopic = renderImage(data.topictype ? data.topictype : 'No type')
            let iconbg = renderColor(data.topicpriority)
            const labelDefault = {
                font: '12px monospace',
                showBackground: true,
                horizontalOrigin: HorizontalOrigin.CENTER,
                verticalOrigin: VerticalOrigin.TOP,
                disableDepthTestDistance: Number.POSITIVE_INFINITY
            }
            let show = true;
            if (!data.topictype) {
                data.topictype = 'No type'
            }
            let x = topicStore.visibleTopic.find(x => x.controlName === data.topictype)
            if (x) {
                show = x.isShow
            }

            setTopicLocationView(topicLocationViews => [
                ...topicLocationViews,
                <Entity
                    id={data.id}
                    key={data.id}
                    show={show}
                    ref={ref}
                    onClick={() => { handleClickEditTopic(data) }}
                    position={cartesians}
                    type="topic"
                    name={data.topictype}
                >
                    <>
                        <BillboardGraphics
                            image={icontopic}
                            verticalOrigin={VerticalOrigin.BOTTOM}
                            horizontalOrigin={HorizontalOrigin.CENTER}
                            distanceDisplayCondition={new DistanceDisplayCondition(0.0, 6000)}
                            disableDepthTestDistance={Number.POSITIVE_INFINITY}
                            color={iconbg}
                        />
                        <LabelGraphics {...labelDefault} show={show} text={data.name} />
                    </>

                </Entity>

            ])
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }

    useEffect(() => {
        if (topicLocationData.length > 0) {
            setTopicLocationView([])
            topicLocationData.map(data => addIconTopicLocation(data))
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
        return () => {
            setTopicLocationView([])
        }
    }, [topicLocationData])

    /**
   * Effect Show/Hide topic icon
   */
    useEffect(() => {
        if (topicStore.visibleTopic.length > 0) {
            topicLocationViews.forEach(itemView => {
                if (itemView.ref) {
                    const topicElement = itemView.ref.current.cesiumElement
                    let x = topicStore.visibleTopic.find(x => x.controlName === itemView.props.name)
                    if (x) {
                        if (x.isShow == undefined) {
                            topicElement.show = true
                        }
                        else {
                            topicElement.show = x.isShow
                        }
                    }
                }
            })
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }, [topicStore.visibleTopic])

    return <>{topicLocationViews.map(topicLocationView => topicLocationView)}</>
}

export default inject('topicStore')(observer(TopicLocationView))
