import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Entity, BillboardGraphics, LabelGraphics } from 'resium'
import { Cartesian3, Math as CesiumMath, DistanceDisplayCondition, Color, Cartographic, Ellipsoid, ScreenSpaceEventType } from 'cesium'
import genericFeedbackFreeTextIcon from '../../../../assets/form-elems/generic-feedback@3x.png'
import smiley1 from '../../../../assets/form-elems/smiley1.png'
import smiley2 from '../../../../assets/form-elems/smiley2.png'
import smiley3 from '../../../../assets/form-elems/smiley3.png'
import smiley4 from '../../../../assets/form-elems/smiley4.png'
import smiley5 from '../../../../assets/form-elems/smiley5.png'
import { toJS } from 'mobx'
const FeedbackAnswerGenericVisualization = props => {
    const { feedbackStore, feedbackData, projectStore, usersStore, viewer } = props
    const [feedbackLocationViews, setFeedbackLocationViews] = useState([])

    const goViewpoint = camdata => {
        if (!camdata) return
        let destination = new Cartesian3(
            camdata.position.x,
            camdata.position.y,
            camdata.position.z
        )
        let direction = new Cartesian3(
            camdata.direction.x,
            camdata.direction.y,
            camdata.direction.z
        )
        let up = new Cartesian3(
            camdata.up.x,
            camdata.up.y,
            camdata.up.z
        )

        viewer.current.cesiumElement.camera.setView({
            destination,
            orientation: {
                direction,
                up,
            },
        })
    }

    const handleClickViewFeedback = (data) => {
        if (data.id !== feedbackStore.feedbackFormDetail.id) {
            if (feedbackStore.isEditingLocationControl) return
            feedbackStore.setLoadingProgress(true)
            feedbackStore.clearFeedbackFormDetail()
            feedbackStore.clearShowFeedbackAnswer()
            feedbackStore.setShowFeedbackAnswer(true, true, 'view', data)
            viewer.current.cesiumElement.screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
            goViewpoint(data.viewLocation.cameraLocation)
        }
    }

    const checkHeightIconLocation = cartesians => {
        const _cartographic = new Cartographic.fromCartesian(cartesians)
        let height = _cartographic?.height || 0
        if (height < 0) {
          height = 0
          const newPosition = new Cartesian3.fromDegrees(
            _cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN,
            _cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN,
            height
          )
          return newPosition
        } else {
          return cartesians
        }
      }

    const addSmileyIconLocation = data => {
        let cartesians = new Cartesian3()
        let _heightFromGround = data.viewLocation.userHeightFromGround ? data.viewLocation.userHeightFromGround : data.viewLocation.cemeraHeight
        if (_heightFromGround < 20 || data.viewLocation.cameraTargetLocation === '') {
            cartesians = new Cartesian3(data.viewLocation.cameraLocation.position.x, data.viewLocation.cameraLocation.position.y, data.viewLocation.cameraLocation.position.z)
        } else {

            if (data.viewLocation.cameraTargetLocation && data.viewLocation.cameraTargetLocation.cartographic)
                cartesians = new Cartographic.toCartesian(data.viewLocation.cameraTargetLocation.cartographic, Ellipsoid.WGS84)
            else if (data.viewLocation.location) {
                cartesians = new Cartesian3(data.viewLocation.location.x, data.viewLocation.location.y, data.viewLocation.location.z)
            }
            else if (data.viewLocation.cameraTargetLocation && data.viewLocation.cameraTargetLocation.position) {
                cartesians = new Cartesian3(data.viewLocation.cameraTargetLocation.position.x, data.viewLocation.cameraTargetLocation.position.y, data.viewLocation.cameraTargetLocation.position.z)
            }
            // cartesians = new Cartesian3(data.viewLocation.cameraTargetLocation.position.x, data.viewLocation.cameraTargetLocation.position.y, data.viewLocation.cameraTargetLocation.position.z)

        }
        cartesians = checkHeightIconLocation(cartesians)

        let _smileyfeedback = data.formControlData.elements.filter(x => x.value !== null)
        _smileyfeedback.forEach((_value, index) => {
            const ref = React.createRef()
            let show = true
            let x = feedbackStore.visibleFeedback.find(
              x => x.controlName === _value.name
            )
            if (x) {
              show = x.isShow
            }
            setFeedbackLocationViews(feedbackLocationViews => [
                ...feedbackLocationViews,
                <Entity
                    key={_value.dynamicFieldId}
                    name={_value.name}
                    show={show}
                    ref={ref}
                    onClick={() => { handleClickViewFeedback(data) }}
                    position={cartesians}
                >
                    {
                        projectStore.currentUserRoleInProject && projectStore.currentUserRoleInProject !== "project_public_viewer" && projectStore.currentUserRoleInProject !== "project_viewer" && (
                            <BillboardGraphics
                                //image={_value.className === 'smileyButton' ? smiley1 : genericFeedbackFreeTextIcon}
                                image={_value.className === 'smileyButton' ? _value.value : genericFeedbackFreeTextIcon}
                                disableDepthTestDistance={Number.POSITIVE_INFINITY}
                            />
                        )
                    }
                </Entity>
            ])
        });

        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }

    useEffect(() => {
        setFeedbackLocationViews([])
        feedbackData.map(data => {
            if(usersStore?.currentUser?.id){
                addSmileyIconLocation(data)
            }else{
              const isPublic = feedbackStore.publicFeedbackAnswers.find(c => c.id === data.id);
              if(isPublic){
                addSmileyIconLocation(data)
              }
            }
        })
        return () => {
            setFeedbackLocationViews([])
        }
    }, [feedbackData, projectStore.currentUserRoleInProject])

    /**
     * Effect Show/Hide feedback icon
     */
    useEffect(() => {
        if (feedbackStore.visibleFeedback.length > 0) {
            feedbackLocationViews.forEach(itemView => {
                if (itemView.ref) {
                    const fbElement = itemView.ref.current.cesiumElement
                    let x = feedbackStore.visibleFeedback.find(x => x.controlName === itemView.props.name)
                    if (x) {
                        fbElement.show = x.isShow
                    }
                }
            })
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }, [feedbackStore.visibleFeedback])

    return <>{feedbackLocationViews.map(feedbackLocationView => feedbackLocationView)}</>
}

export default inject('feedbackStore', "projectStore",'usersStore')(observer(FeedbackAnswerGenericVisualization))
