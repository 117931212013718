import { observable, action, decorate, toJS } from 'mobx';
import { TopicRequest, FileRequest } from '../requests'
import settings from '../siteConfig'
import { message } from 'antd'
import uuid from 'uuid'
import i18n from 'i18next';
import commonStore from './commonStore';

class TopicStore {
    topicList = []
    isLoading = false
    isShowTopicEditor = false
    topicTypes = []
    topicStatuses = []
    topicPriorities = []
    topicTags = []
    viewingTopicForm = {}
    isShownListTopic = false
    isShowDrawerTopicReport = false
    isDrawer3DViewVisible = false
    isSave3DView = false
    list3DViewTopic = []
    topic3DObject = {}
    list3DObjectsTopic = []
    isTopic3DLocationVisible = false
    isTopic3DObjectsVisible = false
    userTeamList = []
    arrTopicLocation = []
    visibleTopic = []
    getTopicForm = ''
    editTopicForm = false
    isClose = false
    currentTopic3DView = {}
    hiddenTopicType = []
    reloadDataTopic = 0
    isCheckSaveViewDone = true;
    isShowLoading = true
    sketchObjectHighLights = []

    setSketchObjectHighLights = (data) => {
        this.sketchObjectHighLights = data
    }

    setIsCheckSaveViewDone = (data) => {
        this.isCheckSaveViewDone = data
    }

    setIsShowLoading = (data) => {
        this.isShowLoading = data
    }
    setReloadDataTopic = () => {
        this.reloadDataTopic = this.reloadDataTopic + 1
    }

    setHiddenTopicType = data => {
        this.hiddenTopicType = data
    }

    setCurrentTopic3DView = data => {
        this.currentTopic3DView = data
    }

    setIsClose = status => {
        this.isClose = status
    }

    setEditTopicForm = value => {
        this.editTopicForm = value
    }

    setGetTopicForm = value => {
        this.getTopicForm = value
    }

    setVisibleTopic = p => {
        this.visibleTopic = p
    }

    clearVisibleTopic = () => {
        this.visibleTopic = []
    }

    setArrTopicLocation = s => {
        this.arrTopicLocation = s
    }

    clearArrTopicLocation = () => {
        this.arrTopicLocation = []
    }

    /**
     * Get topic location show icon on view
     * @param {*} projectId 
     */
    getLocationTopicsByProject = async (projectId) => {
        this.setLoadingProgress(true)
        return TopicRequest.getTopicList(projectId)
            .then(response => {
                let _topiclocation = response.data.filter(x => x.location3D) // filter only get topic has location and topic type
                if (_topiclocation) {
                    this.setArrTopicLocation(_topiclocation)
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setLoadingProgress(false))
    }

    getTopicList = (projectId, params) => {
        return new Promise((resolve, reject) => {
            TopicRequest.getTopicList(projectId, params).then((res) => {
                this.topicList = res.data
                resolve(res);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
    /**
     * Get topic in project
     * @param {*} projectId 
     * @returns {*} topic location, all topic in project
     */
    getProjectTopics = (projectId) => {
        return new Promise((resolve, reject) => {
            TopicRequest.getProjectTopics(projectId).then((res) => {
                this.topicList = res.data
                let _topiclocation = res.data.filter(x => x.location3D) // filter only get topic has location and topic type
                if (_topiclocation) {
                    this.setArrTopicLocation(_topiclocation)
                }
                resolve(res);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    setTopicList = (data) => {
        this.topicList = data
    }

    clearTopicList = () => {
        this.topicList = []
    }

    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading;
    }

    setIsShowDrawerTopicReport(status) {
        this.isShowDrawerTopicReport = status
    }

    setTopic3DObject(data) {
        this.topic3DObject = data
    }

    setShowTopicEditor = async (isShown, isClosedTemporarily, topicRecord) => {
        if (!isClosedTemporarily) { // isClosedTemporarily đóng tạm thời
            if (isShown) {
                let shownTopicForm = {}
                if (topicRecord) {
                    shownTopicForm = {
                        ...topicRecord,
                        okText: 'OK',
                        label: topicRecord.name,
                        isEdit: true
                    }
                } else {
                    shownTopicForm = {
                        isAssigned: false,
                        label: i18n.t('create-new-topic'),
                        okText: 'OK',
                        isEdit: false
                    }
                }
                this.viewingTopicForm = shownTopicForm
                this.isShowTopicEditor = true;
                this.isShownListTopic = false;
            } else {
                this.viewingTopicForm = {}
                this.isShowTopicEditor = false;
            }
        } else {
            this.isShowTopicEditor = isShown;
        }
    }

    setViewingTopicForm = (data) => {
        this.viewingTopicForm = data
    }

    setShownListTopic = (isShown) => {
        this.isShownListTopic = isShown;
        this.isShowTopicEditor = false;
    }

    setIsShowTopicEditor = status => {
        this.isShowTopicEditor = status
    }

    getTopicById = (topicId) => {
        TopicRequest.getTopicById(topicId).then((res) => {
            this.viewingTopicForm = { ...this.viewingTopicForm, res }
        }).catch(err => {
            console.log(err)
        });
    }

    createTopic = data => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            TopicRequest.create(data).then((res) => {
                if(res.data){
                    this.topicList = [...this.topicList,res.data]
                }
                this.setLoadingProgress(false);
                resolve();
            }).catch(err => {
                console.log(err);
                this.setLoadingProgress(false);
                reject(err)
            })
        })
    }

    updateTopic = (topicId, data) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            return TopicRequest.updateTopic(topicId, data).then((res) => {
                let index = this.topicList.findIndex(c => c.id === topicId )
                if(index !== -1){
                    this.topicList[index] = res.data
                }else{
                    this.topicList = [...this.topicList,res.data]
                }
                this.setLoadingProgress(false);
                resolve(res.data);
            }).catch(err => {
                console.log(err);
                this.setLoadingProgress(false);
                reject(err)
            })
        })
    }

    completeTopicPhase = (data) => {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            TopicRequest.CompleteTopicPhase(data).then((res) => {
                this.setLoadingProgress(false);
                resolve(res.data);
            }).catch(err => {
                console.log(err);
                this.setLoadingProgress(false);
                reject(err)
            })
        })
    }

    setTopicTypes = () => {
        this.topicTypes = Object.keys(settings.commonConfig.TOPICS.TYPE).map(key => ({ id: Number(key), name: settings.commonConfig.TOPICS.TYPE[key] }));
    }

    setTopicStatuses = () => {
        this.topicStatuses = Object.keys(settings.commonConfig.TOPICS.STATUS).map(key => ({ id: Number(key), name: settings.commonConfig.TOPICS.STATUS[key] }));
    }

    setTopicPriorities = () => {
        this.topicPriorities = Object.keys(settings.commonConfig.TOPICS.PRIORITY).map(key => ({ id: Number(key), name: settings.commonConfig.TOPICS.PRIORITY[key] }));
    }

    getTopicTags = async (projectId) => {
        await TopicRequest.getTopicTags(projectId)
            .then((res) => {
                this.topicTags = res.data;
            })
            .catch(err => {
                console.log(err)
            })
    }


    clearTopicStatuses = () => {
        this.topicStatuses = []
    }

    clearTopicPriorities = () => {
        this.topicPriorities = []
    }

    clearTopicTypes = () => {
        this.topicTypes = []
    }

    clearTopicTags = () => {
        this.topicTags = []
    }

    createComment = data => {
        return new Promise((resolve, reject) => {
            TopicRequest.createComment(data).then((res) => {
                resolve(res.data);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
    editComment = (id, data) => {
        return new Promise((resolve, reject) => {
            TopicRequest.updateComment(id, data).then((res) => {
                resolve(res.data);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })

    }
    deleteComment = id => {
        return new Promise((resolve, reject) => {
            TopicRequest.deleteComment(id).then((res) => {
                resolve(res.data);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    toggleDrawer3DViewVisible = (state) => {
        this.isDrawer3DViewVisible = state
    }

    setSave3DView = status => {
        this.isSave3DView = status
    }

    addCapture3DView = (data,logInfo) => {        
        let _data = {
            name: data.name,
            cameraData: data.cameraData,
            image: data.image,
            treeData: data.treeData
        }
        if (this.viewingTopicForm.isEdit) {
            _data.topic = this.viewingTopicForm.id
            _data.order = this.list3DViewTopic.length > 0 ? [...this.list3DViewTopic].pop().order + 1 : 0

            this.setLoadingProgress(true)
            return (
                TopicRequest.create3DView(_data)
                    .then(info => {
                        this.list3DViewTopic.push(info.data)
                        message.success(i18n.t('3d-view-added'), 5)
                        //success close 3DView back to topic editor
                        this.isShowTopicEditor = true;
                        this.isDrawer3DViewVisible = false
                        this.setLoadingProgress(false)
                        if(logInfo){
                          commonStore.loggingFunction('Create new topic viewpoint','success',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        message.error(i18n.t('an-error-occurred'))
                        this.setLoadingProgress(false)
                        if(logInfo){
                          commonStore.loggingFunction('Create new topic viewpoint','failed',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
                        }
                    })
            )
        } else { // for case new topic 
            _data.id = uuid() // create auto general id for edit image view
            this.list3DViewTopic.push(_data)
            if (this.viewingTopicForm.name) {
                message.success(i18n.t('3d-view-added'), 5)
                if(logInfo){
                          commonStore.loggingFunction('Create new topic viewpoint','success',logInfo.startTime,logInfo.user,logInfo.project ,logInfo.organization)
                }
            }
            //success close 3DView back to topic editor
            if(this.isClose) {
                this.isShowTopicEditor = true;
            }
            this.isDrawer3DViewVisible = false
        }
    }

    deleteTopic3DViews = (id,logInfo) => {
        const startTime = Date.now();
        if (this.viewingTopicForm.isEdit) {
            this.setLoadingProgress(true)
            TopicRequest.deleteTopic3Dviews(id)
                .then((res) => {
                    const index = this.list3DViewTopic.findIndex(x => x.id === id)
                    this.list3DViewTopic.splice(index, 1)
                    const viewPoint = res.data
                    const viewPointImage = viewPoint.image
                    return FileRequest.delete(viewPointImage.id)
                })
                .then(() => {
                    message.success(i18n.t('3d-view-deleted'))
                    this.setLoadingProgress(false)
                    if(logInfo){
                        commonStore.loggingFunction('Delete topic viewpoint','success',startTime,logInfo.user  ,logInfo.project ,logInfo.organization)
                    }
                })
                .catch(error => {
                    console.log(error)
                    message.error(i18n.t('an-error-occurred'))
                    this.setLoadingProgress(false)
                    if(logInfo){
                      commonStore.loggingFunction('Delete topic viewpoint','failed',startTime,logInfo.user  ,logInfo.project ,logInfo.organization)
                    }
                })
        } else {
            let _3dview = this.list3DViewTopic.find(x => x.id === id)
            if (_3dview && _3dview.image) {
                FileRequest.delete(_3dview.image.id)
                const index = this.list3DViewTopic.findIndex(x => x.id === id)
                this.list3DViewTopic.splice(index, 1)
            }
        }
    }

    deleteTopic3DObject = id => {
        if (this.viewingTopicForm.isEdit) {
            this.setLoadingProgress(true)
            TopicRequest.deleteTopic3DObject(id)
                .then((res) => {
                    this.list3DObjectsTopic = this.list3DObjectsTopic.filter(x => x.id !== id)
                })
                .then(() => {
                    message.success(i18n.t('3d-object-deleted'))
                    this.setLoadingProgress(false)
                })
                .catch(error => {
                    console.log(error)
                    message.error(i18n.t('an-error-occurred'))
                    this.setLoadingProgress(false)
                })
        } else {
            const _3Dobjects = this.list3DObjectsTopic.find(x => x.id === id)
            if (_3Dobjects) {
                this.list3DObjectsTopic = this.list3DObjectsTopic.filter(x => x.id !== _3Dobjects.id)
            }
        }
    }

    addTopic3DObjects = data => {
        if (this.viewingTopicForm.isEdit) {
            this.setLoadingProgress(true)
            return (
                TopicRequest.createTopic3DObjects(data)
                    .then(res => {
                        try {
                            let ojName = res.data.map(rs => rs.name)
                            let ojId = res.data.map(rs => rs.id)
                            let _topic3DObject
                            _topic3DObject = this.topic3DObject.map((oj, i) => {
                                return {
                                    name: ojName[i],
                                    folderTree: oj.folderTree,
                                    fileName: oj.fileName,
                                    createdAt: res.data.map(rs => rs.createdAt)[0],
                                    GUID: oj.GUID,
                                    id: ojId[i],
                                    topic: res.data.map(rs => rs.topic)[0],
                                    _id: oj._id,
                                    model3D: res.data.map(rs => rs.model3D)[0],
                                    type : oj?.type,
                                    sketch: res.data.map(rs => rs.sketch)[i],
                                }
                            })
                            this.list3DObjectsTopic = [...this.list3DObjectsTopic, ..._topic3DObject]
                            message.success(i18n.t('3d-objects-saved'))
                            this.setLoadingProgress(false)
                        } catch (error) {
                            console.log(error)
                            this.setLoadingProgress(false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        message.error(i18n.t('an-error-occurred'))
                        this.setLoadingProgress(false)
                    })
            )
        } else { // for case new topic 
            return (
                TopicRequest.getTopic3DObjectsName(data)
                    .then(res => {
                        let ojName = res.data.map(rs => rs.name)
                        let _topic3DObject
                        _topic3DObject = this.topic3DObject.map((oj, i) => {
                            return {
                                name: ojName[i],
                                folderTree: oj.folderTree,
                                fileName: oj.fileName,
                                GUID: oj.GUID,
                                id: oj.id,
                                topic: oj.topic,
                                _id: oj._id,
                                model3D: oj.model3D,
                                sketch: oj.sketch,
                                type : oj?.type
                            }
                        })
                        this.list3DObjectsTopic = [...this.list3DObjectsTopic, ..._topic3DObject];
                        message.success(i18n.t('3d-objects-saved'))
                        this.setLoadingProgress(false)
                    })
                    .catch(error => {
                        console.log(error)
                        message.error(i18n.t('an-error-occurred'))
                        this.setLoadingProgress(false)
                    })
            )
        }
    }

    setList3DViewTopic = data => {
        this.list3DViewTopic = data
    }

    setList3DObjectsTopic = data => {
        this.list3DObjectsTopic = data
    }

    clearList3DViewTopic = () => {
        this.list3DViewTopic = []
    }

    toggleTopic3DLocationVisible = (state) => {
        this.isTopic3DLocationVisible = state
    }

    toggleTopic3DObjectsVisible = (state) => {
        this.isTopic3DObjectsVisible = state
    }

    updateTopic3Dlocation = (topicId, body) => {
        return new Promise((resolve, reject) => {
            TopicRequest.updateTopic3Dlocation(topicId, body).then((res) => {
                resolve(res.data);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    deleteTopic(topicId) {
        return new Promise((resolve, reject) => {
            TopicRequest.deleteTopic(topicId)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    deleteMultiTopic(body) {
        this.setLoadingProgress(true)
        return new Promise((resolve, reject) => {
            TopicRequest.deleteMultiTopic(body)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }


    getSearchUserTeam = (project, value) => {
        return TopicRequest.searchProjectTeam(project, value)
            .then(response => {
                this.userTeamList = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }
    updateTopic3Dviews = (id, body, reName) => {
        let text = reName ? i18n.t('rename') : i18n.t('updated')
        this.setLoadingProgress(true)
        return TopicRequest.updateTopic3Dviews(id, body)
            .then((response) => {
                let index
                for (let i = 0; i < this.list3DViewTopic.length; i++) {
                    if (this.list3DViewTopic[i].id === id) {
                        index = i
                    }
                }
                this.list3DViewTopic[index] = response.data
                this.setLoadingProgress(false)
                message.success(i18n.t('capture-successfully', { text: text }))
            })
            .catch(error => {
                console.log(error)
                message.error(`Capture ${text} failed`)
                message.error(i18n.t('capture-failed', { text: text }))
                this.setLoadingProgress(false)
            })
    }
}

decorate(TopicStore, {
    topicList: observable,
    setTopicList : action,
    isLoading: observable,
    topicTypes: observable,
    topicPriorities: observable,
    topicTags: observable,
    topicStatuses: observable,
    viewingTopicForm: observable,
    setTopicTypes: action,
    setTopicPriorities: action,
    setTopicStatuses: action,
    getTopicTags: action,
    clearTopicPriorities: action,
    clearTopicStatuses: action,
    clearTopicTags: action,
    clearTopicTypes: action,
    setLoadingProgress: action,
    createTopic: action,
    setShowTopicEditor: action,
    isShowTopicEditor: observable,

    getTopicById: action,

    setShownListTopic: action,
    isShownListTopic: observable,
    getTopicList: action,
    clearTopicList: action,
    completeTopicPhase: action,
    createComment: action,
    editComment: action,
    toggleDrawer3DViewVisible: action,
    isDrawer3DViewVisible: observable,
    setSave3DView: action,
    isSave3DView: observable,
    addCapture3DView: action,
    list3DViewTopic: observable,
    setList3DViewTopic: action,
    list3DObjectsTopic: observable,
    setList3DObjectsTopic: action,
    clearList3DViewTopic: action,
    toggleTopic3DLocationVisible: action,
    isTopic3DLocationVisible: observable,
    updateTopic3Dlocation: action,
    deleteTopic: action,
    getSearchUserTeam: action,
    userTeamList: observable,
    setViewingTopicForm: action,
    arrTopicLocation: observable,
    setArrTopicLocation: action,
    getLocationTopicsByProject: action,
    clearArrTopicLocation: action,

    isShowDrawerTopicReport: observable,
    setIsShowDrawerTopicReport: action,
    isTopic3DObjectsVisible: observable,
    toggleTopic3DObjectsVisible: action,
    visibleTopic: observable,
    setVisibleTopic: action,
    clearVisibleTopic: action,

    topic3DObject: observable,
    setTopic3DObject: action,

    getTopicForm: observable,
    setGetTopicForm: action,

    setEditTopicForm: action,
    editTopicForm: observable,

    setIsShowTopicEditor: action,
    setIsClose: action,
    isClose: observable,
    setCurrentTopic3DView: action,
    currentTopic3DView: observable,
    setHiddenTopicType: action,
    hiddenTopicType: observable,
    setReloadDataTopic: action,
    reloadDataTopic: observable,
    updateTopic3Dviews: action,
    isCheckSaveViewDone: observable,
    setIsCheckSaveViewDone: action,
    isShowLoading:observable,
    setIsShowLoading:action,
    getProjectTopics : action,
    setSketchObjectHighLights : action,
    sketchObjectHighLights : observable
})

export default new TopicStore();
