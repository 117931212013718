import { RedoOutlined, SaveOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Spin,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import LoadingSpinner from '../../../elements/LoadingSpinner/LoadingSpinner'
import AdminTemplate from '../../../layout/AdminTemplate'
import { ContentAdmin, CustomContentAdminWrapper, HeaderAdmin } from '../style'
import '../style.css'
import { Trans, useTranslation } from 'react-i18next'
const { Content } = Layout

const Legal = ({ commonStore, adminStore, projectStore }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false)
  const [maintenanceInfo, setMaintenanceInfo] = useState({})
  const isMobile = useMediaQuery({query: '(max-width: 575px)'})
  useEffect(() => {
    const populatePolicyanTermsConditionData = async () => {
      adminStore.clearStatus()
      setisLoadingFormEdit(true)
      commonStore.setCurrentPage('admin/legal')
      projectStore
        .getMaintenanceInfo()
        .then(res => {
          if (res.statusCode === 200) {
            setMaintenanceInfo(res.data)
            form.setFieldsValue({
              policy: res.data?.policy,
              terms: res.data?.terms,
            })
          }
          setisLoadingFormEdit(false)
        })
        .catch(err => {
          setisLoadingFormEdit(false)
          console.log(err)
        })
    }
    populatePolicyanTermsConditionData()
    return () => {
      adminStore.clearStatus()
    }
  }, [])

  function confirmReset() {
    setisLoadingFormEdit(true)
    adminStore
      .resetAcceptPolicyTermConditions()
      .then(res => {
        message.success(t('reset-policy-and-term-coditions-successfully'))
        setisLoadingFormEdit(false)
      })
      .catch(err => {
        setisLoadingFormEdit(false)
        message.error(t('reset-policy-and-term-coditions-failed-try-again'))
      })
  }

  const updateLegal = payload => {
    let data = { ...maintenanceInfo, ...payload }
    projectStore.setLoadingProgress(true)
    projectStore
      .updateMaintenanceInfo(data)
      .then(res => {
        message.success(t('update-successfully'))
        setMaintenanceInfo(data)
      })
      .catch(err => console.log(err))
      .finally(() => {
        projectStore.setLoadingProgress(false)
      })
  }

  const customSpin = (
    <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
  )
  return (
    <AdminTemplate title={t('legal')}>
      <Spin spinning={isLoadingFormEdit} indicator={customSpin}>
        <CustomContentAdminWrapper className="edit-user">
          <div style={{  background: '#fff', textAlign: 'center' }}>
            <Layout>
              <Form
                form={form}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={value => updateLegal(value)}>
                <HeaderAdmin style={{backgroundColor: '#f0f2f5'}}>
                  <Content>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style={{ padding: '10px ' }}>
                      <Col xs={24} sm={12}>
                        <div style={{ float: 'left', fontSize: 20 }}>
                          {t('privacy-policy')}
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Col span={24} className="control-btn-group">
                          <Button
                            style={{
                              float: isMobile?'left':'right',
                              marginRight: 10,
                            }}
                            htmlType="submit"
                            type="primary">
                            <SaveOutlined />
                            {!isMobile && t('commons.save')}
                          </Button>
                          <Popconfirm
                            title={t(
                              'are-you-sure-to-reset-policy-and-terms-conditions'
                            )}
                            onConfirm={confirmReset}
                            okText={t('commons.yes')}
                            cancelText={t('commons.no')}>
                            <Button
                              style={{
                                float: isMobile?'left':'right',
                                marginRight: 10,
                              }}
                              htmlType="submit"
                              type="primary">
                              <RedoOutlined />
                              {!isMobile && t('commons.reset')}
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Col>
                    </Row>
                  </Content>
                </HeaderAdmin>
                <Content>
                  <div style={{ padding: '20px 0', background: '#fff' }}>
                    <Row type="flex">
                      <Col span={24}>
                        <div
                          className="content-left"
                          style={{
                            background: '#fff',
                            padding: '20px 0',
                          }}>
                          <Row gutter={[24, 8]}>
                            <Col span={24}>
                              <Form.Item
                                label={t('privacy-policy')}
                                name="policy"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-privacy-policy'),
                                  },
                                ]}>
                                <Input type="text" />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('terms-and-conditions')}
                                name="terms"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'please-input-terms-and-conditions'
                                    ),
                                  },
                                ]}>
                                <Input type="text" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Content>
              </Form>
            </Layout>
          </div>
        </CustomContentAdminWrapper>
      </Spin>
    </AdminTemplate>
  )
}

export default inject(
  'projectStore',
  'commonStore',
  'adminStore'
)(observer(Legal))
