import { observable, action, decorate } from 'mobx'

class PolylineCutToolStore {
    isLoading = false
    drawMode = false
    drawEditor = false
    showForm = false
    hideArea = {
        name: 'Polyline cut',
        edgeColor: '#ffffff',
        edgeWidth: 2.0,
        visibilitySource: [], // cut not include model
        points: [], //[[x,y,z]...]
        isCutTerrain: true
    }
    hideAreaOld = false
    cancelDrawArea = true;
    closeHideTool = false;
    hideAreaTemp = false;
    hideAreaTempForMobileOrTablet = false;

    setHideAreaTempForMobileOrTablet = (props) =>{
        this.hideAreaTempForMobileOrTablet = props;
    };
    
    setHideAreaTemp = props =>{
       this.hideAreaTemp = props;
    };

    setHideArea = props => {
        if (!props) {
            this.hideArea = {
                name: 'Polyline cut',
                edgeColor: '#ffffff',
                edgeWidth: 2.0,
                visibilitySource: [],
                points: [],
                isCutTerrain: true
            }
        }
        if (props.hasOwnProperty('name')) {
            this.hideArea.name = props.name
        }
        if (props.hasOwnProperty('edgeColor')) {
            this.hideArea.edgeColor = props.edgeColor
        }
        if (props.hasOwnProperty('edgeWidth')) {
            this.hideArea.edgeWidth = props.edgeWidth
        }
        if (props.hasOwnProperty('visibilitySource')) {
            this.hideArea.visibilitySource = props.visibilitySource
        }
        if (props.hasOwnProperty('points')) {
            this.hideArea.points = props.points
        }
        if (props.hasOwnProperty('isCutTerrain')) {
            this.hideArea.isCutTerrain = props.isCutTerrain
        }
    }

    setCloseHideTool = data => {
        this.closeHideTool = data
    }

    setHideAreaOld = data => {
        this.hideAreaOld = data
    }

    // active draw mode true false
    setDrawMode = isDrawMode => {
        this.drawMode = isDrawMode
    }

    setLoadingProgress = state => {
        this.isLoading = state
    }

    setDrawEditor = data => {
        this.drawEditor = data
    }

    setShowForm = b => {
        this.showForm = b
    }

    setCancelDrawArea = (data) => {
        this.cancelDrawArea = data
    }
}

decorate(PolylineCutToolStore, {
    hideAreaTempForMobileOrTablet: observable,
    setHideAreaTempForMobileOrTablet: action,
    hideAreaTemp: observable,
    setHideAreaTemp: action,
    isLoading: observable,
    setLoadingProgress: action,
    drawMode: observable,
    setDrawMode: action,
    setDrawEditor: action,
    drawEditor: observable,
    showForm: observable,
    setShowForm: action,
    setHideArea: action,
    hideArea: observable,
    setHideAreaOld: action,
    hideAreaOld: observable,
    setCancelDrawArea: action,
    cancelDrawArea : observable,
    setCloseHideTool : action,
    closeHideTool : observable
})

export default new PolylineCutToolStore()
