import { message, Modal, Select, Form, Button } from 'antd';
import React, { useState } from 'react';
import adminStore from '../../../stores/adminStore';
import { Trans, useTranslation } from 'react-i18next';
const { Option } = Select;

function LinkOrganizationModal({
    showOrganizationModal,
    setShowOrganizationModal,
    setOrganizationData,
    organizationData,
    setOrgValue,
    orgValue,
    licenseID,
    setReload,
    reload,
    selectLoading
}) {
    const { t } = useTranslation();
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [form] = Form.useForm();

    const hiddenOrganiztionModal = () => {
        setShowOrganizationModal(false)
        setOrganizationData([])
        setOrgValue()
    }

    const handleChange = (value) => {
        setOrgValue(value)
    }

    const handleOrganizationOk = async () => {
        if (orgValue && licenseID) {
            setLoadingStatus(true)
            await adminStore.updateLicense(licenseID, { organization: orgValue })
            message.success(t('assign-license-to-organization-successfully'));
            setShowOrganizationModal(false)
            setOrganizationData([])
            setOrgValue()
            setReload(!reload)
            setLoadingStatus(false)
            form.resetFields()
        }
    }

    return (
        <Modal title={t('organization-dialog')}
            visible={showOrganizationModal}
            zIndex={9999}
            footer={false}
            confirmLoading={loadingStatus}
            onCancel={hiddenOrganiztionModal}
        >
            <Form form={form} name="control-hooks" onFinish={handleOrganizationOk}>
                <Form.Item
                    name="organization" label={t('organizations')}
                    rules={[{ required: true, message: t('please-select-a-organization') }]}
                >
                    <Select
                        style={{ width: '100%' }}
                        value={orgValue}
                        onChange={v => handleChange(v)}
                        allowClear
                        loading={selectLoading}
                    >
                        {
                            organizationData?.map((org, index) => (
                                <Option value={org.id} key={index}>{org.name}</Option>

                            ))
                        }
                    </Select>
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" htmlType="submit" loading={loadingStatus}>
                        {t('commons.submit')}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default LinkOrganizationModal
