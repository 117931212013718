import React, { useEffect, useState, useRef, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Modal, message, Avatar, Table } from 'antd'
import uuid from 'uuid'
import { func } from 'prop-types'
import { includes } from 'lodash-es'
import { Trans, useTranslation } from 'react-i18next';

const AddArTestModal = ({ arStore, arByCalb }) => {
  const { t } = useTranslation();
  const [selectedList, setSelectedList] = useState([])

  const onCancel = () => {
    arStore.setOpenArTestModal(false)
  }

  const onOk = () => {
    if (!arStore.currentCalibration) return
    if (!selectedList) return
    var id = arStore.currentCalibration.id
    arStore.setCurrentCalibration(false)
    arStore.updateMassCalibration(
      id,
      selectedList
    ).then((data) => {
      arStore.setOpenArTestModal(false)
      if (data)
        message.success(t('test-successfully'))
      else
        message.error(t('something-went-wrong'))
    })
  }

  const columns = [
    {
      title: t('image'),
      dataIndex: 'imageModelRender',
      key: 'imageModelRender',
      render: data => <Avatar style={{ border: "1px solid #ccc" }} shape="square" size={64} src={data && data.url ? data.url : ""} />,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    }
  ];

  function isIn(id) {
    for (let index = 0; index < arByCalb.length; index++) {
      const a = arByCalb[index].ar;
      if (a.id === id) return true
    }
    return false
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedList(selectedRowKeys)
    },
    getCheckboxProps: record => ({
      disabled: isIn(record.id),
      name: record.name,
    }),
  };

  return (
    <Modal
      className="addArModal"
      width={700}
      visible={true}
      title={t('choose-ar-test-iImage-ipair-to-apply-this-callibration')}
      okText={t('choose')}
      cancelText={t('commons.cancel')}
      onCancel={onCancel}
      onOk={onOk}
      footer={
        <Fragment>
          <Button key="back" onClick={onCancel}>
            {t('commons.cancel')}
        </Button>
          <Button
            key="submit"
            type="primary"
            loading={arStore.isLoading}
            onClick={onOk}>
            {t('commons.ok')}
        </Button>
        </Fragment>
      }>
      <div id="addAModal-control" style={{ width: "100%", height: 500 }}>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={arStore.arTestList.ar}
          rowKey="id"
          pagination={false}
          size="small"
          locale={{emptyText: t('no-data')}}
        />,
      </div>
    </Modal>

  )
}
export default inject('arStore')(observer(AddArTestModal))
