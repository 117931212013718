import { Cesium3DTileStyle, Color, PolylineDashMaterialProperty } from 'cesium'
import moment from 'moment'
import TreeUtils from '../../../../../tree-utils'
import projectStore from '../../../../../stores/projectStore'

const gantt = window.gantt

export const DATE_ZOOMINGS = [
  'Minutes',
  'Hours',
  'Days',
  'Weeks',
  'Months',
  'Quarters',
  'Years',
]

export const ACTUAL_DATE_ZOOMINGS = {
  Minutes: 'minute',
  Hours: 'hour',
  Days: 'day',
  Weeks: 'week',
  Months: 'month',
  Quarters: 'month', //'quarter',
  Years: 'year',
}

/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */
Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof dowOffset == 'number' ? dowOffset : 0 //default dowOffset to zero
  var newYear = new Date(this.getFullYear(), 0, 1)
  var day = newYear.getDay() - dowOffset //the day of week the year begins on
  day = day >= 0 ? day : day + 7
  var daynum =
    Math.floor(
      (this.getTime() -
        newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
        86400000
    ) + 1
  var weeknum
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      let nYear = new Date(this.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      /*if the next year starts before the middle of
                the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}

export class DateShow {
  constructor() {
    const currentDate = new Date()
    this.markerDate = currentDate
    this.id = currentDate.toString()
  }

  resetToCurrentDate() {
    this.markerDate = new Date()
  }

  setDateShow(date) {
    if (!(date instanceof Date)) return false
    this.markerDate = date
  }

  updateDateShow = (value, filter) => {
    switch (filter) {
      case 'Minutes':
        this.markerDate = gantt.date.add(this.markerDate, value, 'minute')
        break
      case 'Hours':
        this.markerDate = gantt.date.add(this.markerDate, value, 'minute')
        break
      case 'Days':
        this.markerDate = gantt.date.add(this.markerDate, value, 'hour')
        break
      case 'Weeks':
        this.markerDate = gantt.date.add(this.markerDate, value, 'day')
        break
      case 'Months':
        this.markerDate = gantt.date.add(this.markerDate, value, 'week')
        break
      case 'Quarters':
        this.markerDate = gantt.date.add(this.markerDate, value, 'month')
        break
      case 'Years':
        this.markerDate = gantt.date.add_quarter(this.markerDate, value)
        break
      default:
        this.markerDate = gantt.date.add(this.markerDate, value, 'day')
        break
    }
  }

  getNowTime() {
    return this.markerDate
  }

  getPreviousTime = (inputDate, filter) => {
    if (!(inputDate instanceof Date)) {
      throw new Error('Invalid input. Please provide a valid Date object.')
    }
    const resultDate = new Date(inputDate)
    switch (filter) {
      case 'Minutes':
        return gantt.date.add(resultDate, -1, 'minute')
      case 'Hours':
        return gantt.date.add(resultDate, -1, 'minute')
      case 'Days':
        return gantt.date.add(resultDate, -1, 'hour')
      case 'Months':
        return gantt.date.add(resultDate, -1, 'week')
      case 'Quarters':
        return gantt.date.add(resultDate, -1, 'month')
      case 'Years':
        return gantt.date.add_quarter(resultDate, -1)
      default:
        return gantt.date.add(resultDate, -1, 'day')
    }
  }

  getNextTime = (inputDate, filter) => {
    if (!(inputDate instanceof Date)) {
      throw new Error('Invalid input. Please provide a valid Date object.')
    }

    const resultDate = new Date(inputDate)
    switch (filter) {
      case 'Minutes':
        return gantt.date.add(resultDate, 1, 'minute')
      case 'Hours':
        return gantt.date.add(resultDate, 1, 'minute')
      case 'Days':
        return gantt.date.add(resultDate, 1, 'hour')
      case 'Months':
        return gantt.date.add(resultDate, 1, 'week')
      case 'Quarters':
        return gantt.date.add(resultDate, 1, 'month')
      case 'Years':
        return gantt.date.add_quarter(resultDate, 1)
      default:
        return gantt.date.add(resultDate, 1, 'day')
    }
  }
}

export const handleAddDateTime = (originDate, value, filter) => {
  switch (filter) {
    case 'Minutes':
      return gantt.date.add(originDate, value, 'minute')
    case 'Hours':
      return gantt.date.add(originDate, value, 'minute')
    case 'Days':
      return gantt.date.add(originDate, value, 'hour')
    case 'Weeks':
      return gantt.date.add(originDate, value, 'day')
    case 'Months':
      return gantt.date.add(originDate, value, 'week')
    case 'Quarters':
      return gantt.date.add(originDate, value, 'month')
    case 'Years':
      return gantt.date.add_quarter(originDate, value)
    default:
      return gantt.date.add(originDate, value, 'day')
  }
}

export function removeDuplicateText(input) {
  if (input === 'Weeks') return input
  // Use regular expressions to find and remove duplicate text
  return input.replace(/(\w+)\1/g, '$1')
}

let gantt3DObjtects = []
let listAlignmentfeature = []

const findTile = (tileId, tileViews) => {
  let fkey = tileId + '-tile'
  let tile = tileViews.find(t => t.key == fkey)
  if (!tile) return false
  if (!tile.ref) return false
  if (!tile.ref.current) return false
  return tile.ref
}

const setColorEntitiesValue = (viewer, features, isReset) => {
  if (!isReset) {
    features.map(feature => {
      const tileView = feature?.entities?.values
      const color = feature?.color
      if (tileView) {
        tileView.map(feature => {
          if (feature?.polygon?.material) {
            feature.polygon.material = color
          } else if (feature?.polyline?.material) {
            feature.polyline.material = color
          } else if (feature?.point?.color) {
            feature.point.color = color
          } else if (feature?.billboard?.color) {
            feature.billboard.color = color
          }
        })
      }
    })
  } else {
    features.map(feature => {
      const tileView = feature?.entities?.values
      if (tileView && feature?.isColorEntitiesValue) {
        tileView.map(feature => {
          if (feature?.polygon?.material) {
            feature.polygon.material = Color.clone(Color.WHITE, feature.color)
          } else if (feature?.polyline?.material) {
            feature.polyline.material = Color.clone(Color.WHITE, feature.color)
          } else if (feature?.point?.color) {
            feature.point.color = Color.clone(Color.WHITE, feature.color)
          } else if (feature?.billboard?.color) {
            feature.billboard.color = Color.clone(Color.WHITE, feature.color)
          }
        })
      }
    })
  }
  if (viewer.scene.requestRenderMode) {
    viewer.scene.requestRender()
  }
}

const saveOriginalAlignmentColor = (_alignment) =>{
  if (_alignment) {
    const originalColor = _alignment.polyline.material
      .getValue()
      .color.toCssHexString()
    if (!_alignment.originalColor) {
      _alignment.originalColor = originalColor
    }
    listAlignmentfeature.push(_alignment)
  }
}

const getColorAlignment = (feature, style, isReset) => {
  let color = null
  if (!isReset) {
    const { color: colorQuery, alpha } = style
    color = Color.fromCssColorString(colorQuery || '#FFFFFF').withAlpha(alpha)
  } else {
    color = Color.fromCssColorString(feature?.originalColor || '#ffffff')
  }
  const AlignmentHorCurve = new PolylineDashMaterialProperty({
    color: Color.clone(color, feature.color),
  })
  return AlignmentHorCurve
}

const unHighlightXMLAlignment = features => {
  if (!features || !features.length) {
    return
  }
  features.map(feature => {
    if (feature.polyline) {
      feature.polyline.material = getColorAlignment(feature, false, true)
    }
  })
  listAlignmentfeature = []
}

const highlighXMLAlignment = (viewer, features, style) =>{
  if (!features || !features.length) {
    return
  }
  features.map(feature => {
    if (feature.polyline) {
      feature.polyline.material = getColorAlignment(feature, style, style?.color === '#ffffff')
    }
  })

  if (viewer.scene.requestRenderMode) {
    viewer.scene.requestRender()
  }
}

const highlightAlignment = (viewer, modelId, style) =>{
  if(!viewer || !modelId || !style) return

  const alignments = projectStore.alignmentFeatures.filter(
    feature => feature?.modelId === modelId
  )
  if(alignments?.length > 0){
    alignments.forEach(alm => saveOriginalAlignmentColor(alm))
    highlighXMLAlignment(viewer, listAlignmentfeature, style)
  }

}

const selectFeatures = (features) => {
  gantt3DObjtects = [...gantt3DObjtects, ...features]
}

export const applyColorChanges = (
  modelId,
  tileViews,
  color = '#ff0000',
  alpha = 0.5,
  viewer
) => {
  let _model = findTile(modelId, tileViews)
  if (_model) {
    _model.modelId = modelId
    _model.isColorEntitiesValue = false
    _model.sourceType = 'file'
    if (_model?.current?.cesiumElement?.entities?.values) {
      _model.isColorEntitiesValue = true
      _model.entities = {
        values: _model?.current?.cesiumElement?.entities?.values,
      }
      _model.color = new Color.fromCssColorString(color).withAlpha(
        color === '#ffffff' ? 1 : Number(alpha)
      )
      gantt3DObjtects.push(_model)
      setColorEntitiesValue(viewer, [_model])
    } else {
      selectFeatures([_model])
    }

    highlightAlignment(viewer, modelId, {color, alpha: Number(alpha)})
  }
}

const resetTilesetColor = (viewer, tilesets) => {
  if (tilesets?.length > 0) {
    tilesets.map(tileset => {
      if (tileset?.sourceType === 'file' && !tileset?.isColorEntitiesValue) {
        tileset.style = new Cesium3DTileStyle()
      }
    })
    if (viewer.scene.requestRenderMode) {
      viewer.scene.requestRender()
    }
  }
}

const unselectFeatures = (viewer, features) => {
  if (!features || !features.length) {
    return
  }
  resetTilesetColor(viewer, features)
  setColorEntitiesValue(viewer, features, true)
  if (features === gantt3DObjtects) {
    gantt3DObjtects = []
  }
}

export const onComponentDidMount = viewer => {
  unHighlightXMLAlignment(listAlignmentfeature)
  unselectFeatures(viewer, gantt3DObjtects)
  gantt3DObjtects = []
}

/**
 * Single datetime range
 * @param {*} currentTime
 * @param {*} startDate
 * @param {*} endDate
 * @returns 'before' | 'after' |'between' |'same'|'none'
 */
const checkDateInSingleRange = (currentTime, startDate, endDate) => {
  // Kiểm tra xem currentTime có nằm trước startDate hay không
  const isBeforeStartDate = currentTime.isBefore(startDate)

  // Kiểm tra xem currentTime có nằm sau endDate hay không
  const isAfterEndDate = currentTime.isAfter(endDate)

  // Kiểm tra xem currentTime có nằm trong khoảng giữa startDate và endDate hay không
  const isInRange = currentTime.isBetween(startDate, endDate)

  const isSameStartOrEnd =
    currentTime.isSame(startDate) ||
    currentTime.isSame(endDate) ||
    currentTime.toDate().toString() === startDate.toDate().toString() ||
    currentTime.toDate().toString() === endDate.toDate().toString()

  if (isBeforeStartDate) {
    return 'before'
  } else if (isSameStartOrEnd) {
    return 'same'
  } else if (isAfterEndDate) {
    return 'after'
  } else if (isInRange) {
    return 'between'
  } else {
    return 'none'
  }
}

const convertStringToDate = dateString => {
  //const dateString = 'Wed Dec 13 2023 00:00:00 GMT+0700 (Indochina Time)';
  return moment(dateString, 'ddd MMM DD YYYY HH:mm:ss ZZ')
}

/**
 * Two datetime range
 * @param {*} targetDate
 * @param {*} start1
 * @param {*} end1
 * @param {*} start2
 * @param {*} end2
 * @returns 'middle' | 'left' | 'right' | 'nothing'
 */
export function checkDateInRanges(targetDateRaw, start1, end1, start2, end2) {
  const targetDate = convertStringToDate(targetDateRaw.toDate().toString())
  const isInRange1 = targetDate.isBetween(start1, end1, null, '[]') // [] đại diện cho inclusive
  const isInRange2 = targetDate.isBetween(start2, end2, null, '[]')

  if (isInRange1 && isInRange2) {
    return 'middle'
  } else if (isInRange1) {
    return 'left'
  } else if (isInRange2) {
    return 'right'
  } else {
    return 'nothing'
  }
}

/**
 * Check and take the priority task
 * @param {*} currentArray
 * @param {*} currentTime
 * @returns Array
 */
const doubleCheckFunction = (currentArray, currentTime) => {
  if (!currentArray.length) return []

  const filteredResult = currentArray.filter((el, index, arr) => {
    const duplicateTasks = arr.filter(t => t.id === el.id)

    if (!duplicateTasks.length || duplicateTasks.length === 1) return true

    const [task1, task2] = duplicateTasks
    const result = checkDateInRanges(
      currentTime,
      task1.momentStart,
      task1.momentEnd,
      task2.momentStart,
      task2.momentEnd
    )

    // Old logic: take top task instead of lower task
    switch (result) {
      case 'middle':
      case 'left':
        return !(el.taskId === task2.taskId && el.id === task2.id)
      case 'right':
        return !(el.taskId === task1.taskId && el.id === task1.id)
      case 'nothing':
      default:
        return !(el.taskId === task2.taskId && el.id === task2.id)
    }

    // switch (result) {
    //   case 'left':
    //     return !(el.taskId === task2.taskId && el.id === task2.id)
    //   case 'middle':
    //   case 'right':
    //     return !(el.taskId === task1.taskId && el.id === task1.id)
    //   case 'nothing':
    //   default:
    //     return !(el.taskId === task1.taskId && el.id === task1.id)
    // }
  })

  return filteredResult || []
}

/**
 *
 * @param {*} task
 * @param {*} currentTime
 * @param {*} listKeyModelShow
 * @param {*} listAllModelLinkedToGantt
 * @returns
 */
export const preProcessingData = (
  task,
  currentTime,
  listKeyModelShow,
  listAllModelLinkedToGantt,
  type = 'dataTree',
) => {
  let momentStart = task.start_date && moment(task.start_date)
  let momentEnd = task.end_date && moment(task.end_date)
  const uniqueDataTree = type === 'dataTree' ? [...new Set(task?.dataTree || [])] : [...new Set(task?.savedQuery || [])]

  listAllModelLinkedToGantt = listAllModelLinkedToGantt.concat(
    Array.from(uniqueDataTree)
  )

  if (task && (task.end_date || task.start_date)) {
    const currentDatePosition = checkDateInSingleRange(
      currentTime,
      momentStart,
      momentEnd
    )
    const isEmptyTaskType = task?.taskType
    const isHighlight =
      (currentDatePosition === 'between' &&
        (task?.taskType === 'demolish' ||
          task?.taskType === 'new' ||
          task?.taskType === 'temporary')) ||
      currentDatePosition === 'between' ||
      currentDatePosition === 'same'
    const isShow =
      (currentDatePosition === 'before' && task?.taskType === 'demolish') ||
      (currentDatePosition === 'after' && task?.taskType === 'new') ||
      isHighlight ||
      (currentDatePosition === 'after' &&
        (isEmptyTaskType === undefined || isEmptyTaskType === null))

    if (isShow) {
      const tempArr = uniqueDataTree.map(d => ({
        id: d,
        isHighlight: isHighlight,
        highlightColor: task?.highlightColor,
        highlightAlpha:
          task?.highlightAlpha !== undefined
            ? Number(task.highlightAlpha)
            : 0.5,
        momentStart,
        momentEnd,
        taskId: task.id,
      }))

      listKeyModelShow = doubleCheckFunction(
        listKeyModelShow.concat(Array.from(tempArr)),
        currentTime
      )
    }
  } else if (task) {
    const tempArr = uniqueDataTree.map(d => ({
      id: d,
      isHighlight: false,
      highlightColor: task?.highlightColor,
      highlightAlpha:
        task?.highlightAlpha !== undefined ? Number(task.highlightAlpha) : 0.5,
      momentStart,
      momentEnd,
      taskId: task.id,
    }))
    listKeyModelShow = doubleCheckFunction(
      listKeyModelShow.concat(Array.from(tempArr)),
      currentTime
    )
  }

  return [listKeyModelShow, listAllModelLinkedToGantt]
}

export const convertModelToKey = (treeData, linkedModels) =>{
  let result = []
  if(!linkedModels?.length) return result

  linkedModels.forEach(lm =>{
    const model = TreeUtils.searchTreeNode(treeData, 'modelId', lm)
    const sketch = TreeUtils.searchTreeNode(treeData, 'sketchId', lm)
    if(model){
      result.push(model?.key)
      return
    }
    if(sketch){
      result.push(sketch?.key)
      return
    }
  })

  return result
}

export const convertKeyToModel = (treeData, keys) =>{
  let result = []
  if(!treeData?.length || !keys?.length) return result
  
  keys.forEach(lm =>{
    const model = TreeUtils.searchTreeNode(treeData, 'key', lm)
    if(model?.modelId){
      result.push(model.modelId)
      return
    }
    if(model?.sketchId){
      result.push(model.sketchId)
      return
    }
  })
  return result
}

export const COLUMNS = [
  'wbs',
  'text',
  'start_date',
  'end_date',
  'duration',
  'priority',
  'taskType',
  'progress',
  'buttons',
]

const allowedTypes = {
  ms_project: [
    'mpp',
    'xml',
    'text/xml',
    'application/xml',
    'application/vnd.ms-project',
    'application/msproj',
    'application/msproject',
    'application/x-msproject',
    'application/x-ms-project',
    'application/x-dos_ms_project',
    'application/mpp',
    'zz-application/zz-winassoc-mpp',
  ],
  primavera: ['xer', 'xml', 'text/xml', 'application/xml', 'application/xer'],
}

export const isFileTypeAllowed = (file, projectType) => {
  // Lấy phần mở rộng của tệp
  const extension = file.name.split('.').pop().toLowerCase()
  // Lấy kiểu MIME của tệp
  const mimeType = file.type.toLowerCase()

  // Kiểm tra xem phần mở rộng hoặc kiểu MIME có tồn tại trong danh sách cho phép không
  return (
    allowedTypes[projectType].includes(extension) ||
    allowedTypes[projectType].includes(mimeType)
  )
}

export const simplifiedGanttDataToGetSavedQuery = (ganttData) =>{
  if(!ganttData) return []
  let result = ganttData.data.map(task => ({
    ...task,
    savedQuery: task?.savedQuery && task?.savedQuery?.length > 0 ?  task.savedQuery.map(sq => sq.id) : []
  }))
  return result
}

export const checkIsValidId = (inputString) => {
  // Biểu thức chính quy để kiểm tra
  const regexPattern = /^[0-9a-fA-F]{24}$/

  
  // Kiểm tra xem chuỗi nhập vào có khớp với biểu thức chính quy không
  const isMatch = regexPattern.test(inputString);
  
  // Trả về kết quả
  return isMatch;
}
