import React, { useEffect, useRef } from 'react'
import { Button, DatePicker, Popover, Select, Space, Switch, Modal } from 'antd'
import {
  CloseOutlined,
  MoreOutlined,
  ImportOutlined,
  ExportOutlined,
} from '@ant-design/icons'
import {
  ButtonCustom,
  MoreOptionWrapper,
  OptionWrapper,
  PlayerWrapper,
  ToolbarWrapper,
} from './styled'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import en from 'antd/lib/date-picker/locale/en_US'
import fi from 'antd/lib/date-picker/locale/fi_FI'
import sv from 'antd/lib/date-picker/locale/sv_SE'
import es from 'antd/lib/date-picker/locale/es_ES'
import vi from 'antd/lib/date-picker/locale/vi_VN'

import { ReactComponent as RedoIcon } from '../../../../../assets/svgs/redo.svg'
import { ReactComponent as UndoIcon } from '../../../../../assets/svgs/undo.svg'
import { ReactComponent as Icon4DPause } from '../../../../../assets/svgs/4DPause-s0.svg'
import { ReactComponent as Icon4DPlay } from '../../../../../assets/svgs/4DPlay-s0.svg'
import { ReactComponent as Icon4DForward } from '../../../../../assets/svgs/4DForward-s0.svg'
import { ReactComponent as Icon4DBack } from '../../../../../assets/svgs/4DBack-s0.svg'

import SVGIcon from '../../../../elements/SVGIcon'
import { handleAddDateTime } from '../Gantt/ganttUtils'
import GridColumnMenu from './GridColumnMenu'
import ModalIEGantt from './ModalIEGantt'

const Toolbar = ({
  zoom,
  onZoomChange,
  zoomToFit,
  onToggleZoomToFit,
  projectGanttStore,
  commonStore,
  projectStore,
  adminStore,
}) => {
  const { t } = useTranslation()
  const {
    playerMode,
    setPlayerMode,
    isPlay4d,
    setIsPlay4d,
    setBackOrForwardCount,
    isShowGrid,
    setIsShowGrid,
    isShowBaseline,
    setIsShowBaseline,
    setIsOpenModalExportnImportProject,
  } = projectGanttStore

  const checkingFeatureRole = type => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
  }

  const handleZoomChange = value => {
    if (onZoomChange) {
      onZoomChange({ status: value, isUpdate: true })
    }
  }

  const handleZoomToFit = checked => {
    onToggleZoomToFit({ status: checked, isUpdate: true })
  }

  const handleChangeCriticalPath = checked => {
    projectGanttStore.setIsShowCriticalPath(checked)
  }
  const handleChangeAutoScheduling = checked => {
    projectGanttStore.setIsAutoScheduling(checked)
  }

  const handleUndo = () => {
    projectGanttStore.setUndoCount()
  }
  const handleRedo = () => {
    projectGanttStore.setRedoCount()
  }

  const handleSetTime = value => {
    projectGanttStore.setCurrentViewingTime(value)
  }

  const content = (
    <OptionWrapper>
      <GridColumnMenu />
    </OptionWrapper>
  )

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  useInterval(
    () => {
      jumpForward()
    },
    isPlay4d ? 2000 : null
  )

  useEffect(() => {
    if (playerMode === 'dragDate') {
      setBackOrForwardCount(0)
    }
  }, [playerMode])

  const playVideo = () => {
    setIsPlay4d(true)
  }

  const pauseVideo = () => {
    setIsPlay4d(false)
  }

  const jumpForward = () => {
    const updateDate = handleAddDateTime(
      projectGanttStore.currentViewingTime.toDate(),
      1,
      zoom.status
    )
    handleSetTime(updateDate)
    !isPlay4d && playerMode === 'dragGantt' && setBackOrForwardCount()
  }

  const jumpBackward = () => {
    const updateDate = handleAddDateTime(
      projectGanttStore.currentViewingTime.toDate(),
      -1,
      zoom.status
    )
    handleSetTime(updateDate)
    !isPlay4d && playerMode === 'dragGantt' && setBackOrForwardCount()
  }

  const handleToggleGrid = () => {
    setIsShowGrid(!isShowGrid)
  }

  const handleGenericBaseline = () => {
    if (!projectGanttStore.projectGanttData?.length) {
      return
    }
    const { data, _id } = projectGanttStore.projectGanttData[0]
    if (!data?.length) return
    projectGanttStore.baselineGenerator({ data }).then(() => {
      projectGanttStore.getProject4dGantt(projectStore.projectDetail.id)
    })
  }

  const handleToggleShowBaseline = () => {
    setIsShowBaseline(!isShowBaseline)
  }

  const handleOpenExportnImportProject = type => {
    setIsOpenModalExportnImportProject({ type, open: true })
  }

  return (
    <ToolbarWrapper>
      <MoreOptionWrapper>
        <Popover content={content} className="popover">
          <MoreOutlined style={{ fontSize: '20px' }} />
        </Popover>
        <CloseOutlined
          style={{ fontSize: 16 }}
          onClick={() => projectGanttStore.setIsShowGanttPanel(false)}
        />
      </MoreOptionWrapper>
      <div className="toolbar-component">
        <Space>
          <b className="max-content">{t('gantt.fit')}: </b>
          <Switch checked={zoomToFit.status} onChange={handleZoomToFit} />
        </Space>
        <Space>
          <b className="max-content">{t('gantt.auto-schedule')}: </b>
          <Switch
            checked={projectGanttStore.isAutoScheduling}
            onChange={handleChangeAutoScheduling}
          />
        </Space>
        <Space>
          <b className="max-content">{t('gantt.critical-path')}: </b>
          <Switch
            checked={projectGanttStore.isShowCriticalPath}
            onChange={handleChangeCriticalPath}
          />
        </Space>
        <Space>
          <b className="max-content">{t('gantt.grid')}: </b>
          <Switch checked={isShowGrid} onChange={handleToggleGrid} />
        </Space>
        <Space>
          <b className="max-content">{t('gantt.baseline')}: </b>
          <Switch
            checked={isShowBaseline}
            onChange={handleToggleShowBaseline}
          />
        </Space>
        {checkingFeatureRole('feature_4d_gantt_edit') && (
          <Space>
            <Button type="primary" onClick={handleGenericBaseline}>
              {t('gantt.save-as-baseline')}
            </Button>
          </Space>
        )}
        <Space
          className={projectGanttStore.isActiveUndo ? '' : 'disable-undo'}
          style={{ cursor: 'pointer', lineHeight: 0 }}
          onClick={() => handleUndo()}>
          <SVGIcon content={<UndoIcon />} width={24} height={24} />{' '}
          <b className="max-content">{t('gantt.undo')}</b>
        </Space>
        <Space
          className={projectGanttStore.isActiveRedo ? '' : 'disable-undo'}
          style={{ cursor: 'pointer', lineHeight: 0 }}
          onClick={() => handleRedo()}>
          <SVGIcon content={<RedoIcon />} width={24} height={24} />{' '}
          <b className="max-content">{t('gantt.redo')}</b>
        </Space>
        {checkingFeatureRole('feature_4d_gantt_edit') && (
          <>
            <Space>
              <Button
                type="primary"
                icon={<ImportOutlined />}
                onClick={() => handleOpenExportnImportProject('import')}>
                {t('commons.import')}
              </Button>
            </Space>
            <Space>
              <Button
                type="primary"
                icon={<ExportOutlined />}
                onClick={() => handleOpenExportnImportProject('export')}>
                {t('commons.export')}
              </Button>
            </Space>
          </>
        )}
      </div>
      <div className="toolbar-component">
        <Space>
          <b className="max-content">{t('commons.mode')}: </b>
          <Select
            style={{ width: 120, marginLeft: 5 }}
            onChange={value => setPlayerMode(value)}
            value={playerMode}
            options={[
              { value: 'dragDate', label: t('drag-date') },
              { value: 'dragGantt', label: t('drag-gantt') },
            ]}
          />
        </Space>
        <Space>
          <b className="max-content">{t('scale')}: </b>
          <Select
            id={zoom ? `lable-zoom-gantt${zoom}` : zoom}
            className={`radio-label ${
              zoom ? 'radio-label-active' : ''
            } lable-zoom-gantt`}
            style={{ width: 120, marginLeft: 5 }}
            onChange={handleZoomChange}
            value={zoom.status}
            options={[
              { value: 'Hours', label: t('hours') },
              { value: 'Days', label: t('days') },
              { value: 'Weeks', label: t('weeks') },
              { value: 'Months', label: t('months') },
              { value: 'Quarters', label: t('quarters') },
              { value: 'Years', label: t('years') },
            ]}
          />
        </Space>
        <Space>
          <b className="max-content">4D:</b>
          <DatePicker
            locale={{
              ...(commonStore.language === 'fi'
                ? fi
                : commonStore.language === 'sv'
                ? sv
                : commonStore.language === 'es'
                ? es
                : commonStore.language === 'vi'
                ? vi
                : en),
              lang: {
                ...(commonStore.language === 'fi'
                  ? fi
                  : commonStore.language === 'sv'
                  ? sv
                  : commonStore.language === 'es'
                  ? es
                  : commonStore.language === 'vi'
                  ? vi
                  : en
                ).lang,
                now: t('now'),
                ok: t('commons.ok'),
              },
            }}
            value={projectGanttStore.currentViewingTime}
            showTime
            placeholder={t('select-time')}
            onChange={handleSetTime}
            onOk={handleSetTime}
            style={{ minWidth: 220 }}
            allowClear={false}
            disabled={projectGanttStore.playerMode === 'dragGantt'}
          />
        </Space>
        <Space style={{ lineHeight: 0 }}>
          <b className="max-content">{t('gantt.play-4d')}: </b>
          <ButtonCustom
            icon={<SVGIcon content={<Icon4DBack />} />}
            onClick={jumpBackward}
          />
          {isPlay4d ? (
            <ButtonCustom
              icon={<SVGIcon content={<Icon4DPause />} />}
              onClick={pauseVideo}
            />
          ) : (
            <ButtonCustom
              icon={<SVGIcon content={<Icon4DPlay />} />}
              onClick={playVideo}
            />
          )}
          <ButtonCustom
            icon={<SVGIcon content={<Icon4DForward />} />}
            onClick={jumpForward}
          />
        </Space>
      </div>
      <ModalIEGantt />
    </ToolbarWrapper>
  )
}

export default inject(
  'adminStore',
  'commonStore',
  'projectGanttStore',
  'projectStore'
)(observer(Toolbar))
