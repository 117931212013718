import styled from 'styled-components'
import { Card } from 'antd'

export const DemoCard = styled(Card)`
width: 320px;
border: 1px solid #e8e8e8 !important;
`

export const StyleToolbarUser = styled.div`
    position: absolute;
    bottom: 0;    
    background-color: #fafafa;
    border-top: 1px solid #e8e8e8;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -24px;
    height: 42px;
    .logout{
        text-align: right;        
        cursor: pointer;      
        a:link {
            color: #F26524 !important;
            text-decoration: none;
        }      
        a:hover, a:active {
            color: #F26524;
            text-decoration: none;
        }
    }
    .user-bar {
        padding: 10px
    }
    .user-setting {
        text-align: center;
    }
    .info {
        fontSize: 16px;
        color: #666666;
        cursor: pointer;
        &:hover{
            color: #F26524
        }
    }
    .uname{        
        cursor: pointer;
        &:hover {
            color: #F26524
        }
    }
    
`