import styled from 'styled-components'
import { Button } from 'antd'

export const ProjectSettingWrapper = styled.div`
    .splitpanel-left {
        top: 0;
        right: 0;
        width: 10px;
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        border-top: 1px solid #ddd;
        background-color: #f2cfc4;
    }
  
    .splitpanel-mobile-left {
        top: 0;
        right: 0; 
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        .btnSplitPanel {
        position: absolute;
        top: 50%;
        margin-left: -15px;
        padding: 0 !important;
        }
    }
`
export const ProjectSettingContent = styled.div`
    .delete-icon {
        position: absolute;
        width: auto;
        background: none;
        top: 3px;
        right: 5px;
        display: none;
        &:hover {
            cursor: pointer;
        }
    }
    .ant-form-item-control-input-content:hover {
        .delete-icon {
            display: block;
        }
    }
`
export const ProjectSettingButton = styled(Button)`
    margin-bottom : 4px;
    margin-left : 8px;
    margin-top : 4px;
`