
import { Button, message, Modal, Tabs, Upload, Switch, notification, Spin, Form, Input, Select, InputNumber, Divider, Checkbox, Progress, DatePicker, Row, Col, Slider, Tooltip } from 'antd'
import {
  BuildOutlined,
  InboxOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  SyncOutlined
} from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { UploadWrapper, ProgressBodyStype } from './CustomStyled'
import { Cartesian3, GoogleMaps, createGooglePhotorealistic3DTileset } from 'cesium'
import { toJS } from 'mobx'
import { getOriginPublicJsonLink, getMimeOfFile, bytesToSize, updateProjectModel, mergeAllModels, create_ifcengineusage, updateProjectTreeData, updateContenModelIon, convertExtToLowerCase } from '../../../../lib/projectLib'
import { apiUrl, apiNcWmsUrl } from '../../../../config'
import Utils from '../../../../utils'
import axios from 'axios'
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { getCenterScreenCoordinates, validDegreeBox } from '../../../helper/CesiumUtils'
import MeshModal from '../MeshModal/MeshModal'
import { useTranslation } from 'react-i18next';
import SVGIcon from '../../../elements/SVGIcon/SVGIcon'
import { ReactComponent as CloudDone } from '../../../../assets/svgs/cloud-done.svg'
import uuid from 'uuid'
import * as PromiseBlue from "bluebird";
import AddProjectLink from './AddProjectLink'
import { ProjectRequest } from '../../../../requests'
import HelpButton from '../../../elements/HelpButton'
import { isMobile, isTablet } from 'react-device-detect'
import GML3 from 'ol/format/GML3.js';
import GML2 from 'ol/format/GML2.js';
import GML32 from 'ol/format/GML32.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import WMSCapabilities from 'ol/format/WMSCapabilities.js';
import { Buffer } from 'buffer';
const dateFormat = 'YYYY-MM-DD';
const { Dragger } = Upload
const { TabPane } = Tabs
const { Option } = Select
const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />
const isUrl = urlString => {
  return (
    urlString &&
    (urlString.startsWith('https://') || urlString.startsWith('http://'))
  )
}
const ASSET_URL = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/`
const CancelToken = axios.CancelToken;
const confirm = Modal.confirm;
const ModalAddResources = ({ projectStore, capturesStore, commonStore, viewer, adminStore, projectSettingStore, fileStore, pointsceneStore, usersStore, objectQueryStore }) => {
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  const progressInfosRef = useRef({ val: [] })
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [cancelsRequest, setCancelsRequest] = useState([]);
  const onChangeTab = activeKey => {
    projectStore.setCurrentAddTab(activeKey)
  }

  // effect get location center screen
  useEffect(() => {
    if (projectStore.showAddResourceModel) { // get center screen for process upload (model position)      
      if (!viewer) return
      var centerScreen = getCenterScreenCoordinates(viewer)
      if (!centerScreen || !viewer.camera) return
      projectStore.setCenterData(centerScreen)
    }
  }, [projectStore.showAddResourceModel])

  // handle hide modal add resource
  const handleHideModalAddResources = () => {
    projectStore.setShowAddResourceModel(false)
    projectStore.setShowProcessInBackground(true)
    projectStore.setCheckModalStatus(true)
  }


  // handle close modal add resource
  const onCancel = () => {
    // check all files processed then close dialog upload
    let filterprocessed = progressInfosRef.current.val.filter(x => x.isComplete === true || (x.isFailed === true))
    if (projectStore.currentAddTab === 'uploadTab' && progressInfosRef.current.val.length !== filterprocessed.length) {
      confirm({
        title: t('confirm-leave'),
        content: t('are-you-sure-you-want-to-leave-not-save'),
        onOk() {
          progressInfosRef.current.val = []; // clear progressInfosRef
          setProgressInfos({ val: [] }); // clear progressInfos
          projectStore.setShowAddResourceModel()
          projectStore.setDisplayPanel(true)

          cancelsRequest.forEach((c) => c()) // cancel all requests if it is still available
          setCancelsRequest([]) //clear cancel request

        },
        onCancel() {
          console.log('');
        },
        okText: t('commons.ok'),
        cancelText: t('commons.cancel'),
        zIndex: "99999999"
      });
    } else {
      progressInfosRef.current.val = []; // clear progressInfosRef
      setProgressInfos({ val: [] }); // clear progressInfos
      projectStore.setShowAddResourceModel()
      projectStore.setDisplayPanel(true)
    }
  }

  const PreDefineAccessForm = () => {
    const [form] = Form.useForm();
    const [loadingDataLibrary, setLoadingDataLibrary] = useState(false)
    useEffect(() => {
      if (projectStore.currentAddTab === 'preDefineAccess') {
        setLoadingDataLibrary(true)
        adminStore.getPreDefinedAccesss().then(res => {
          setLoadingDataLibrary(false)
        })
          .catch(err => {
            setLoadingDataLibrary(false)
            console.log(err)
          })
      }
      return () => {
      }
    }, [])
    const handleSubmit = async values => {
      projectStore.setLoadingProgress(true)
      let selectedPredefineAccess = adminStore.listPreDefinedAccess.find(elm => elm.id === values.predefineaccess)
      let crs = {}
      if (capturesStore.cameraData) {
        crs.position = {
          x: capturesStore.cameraData.position.x,
          y: capturesStore.cameraData.position.y,
          z: capturesStore.cameraData.position.z,
        }
        crs.code = '0'
      }
      let newModel = {
        name: selectedPredefineAccess.name ? selectedPredefineAccess.name : 'Unnamed',
        hash: selectedPredefineAccess.hash,
        src: selectedPredefineAccess.src,
        data: selectedPredefineAccess.data,
        project: projectStore.projectDetail._id,
        sourceType: selectedPredefineAccess.sourceType,
        crs,
        selectedKey: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null,
        isTerrain: selectedPredefineAccess.type === 'terrain' ? true : false
      }
      projectStore
        .createModel(newModel)
        .then(async (model) => {
          // Update Project tilesetdata and Model data return current model
          let _currentmodel = await updateProjectModel(model.project, model)

          // update project TreeData
          let _treeData = await updateProjectTreeData(model.project._id, model, projectStore.projectDetail.treeData)
          _currentmodel.project.treeData = _treeData

          /**Update FE projectStore.ProjectDetail */
          await projectStore.updateProjectRefPoint(_currentmodel.project)

          projectStore.setCurrentModelId(false)
          projectStore.setNewModelId(_currentmodel._id)

          projectStore.setSelectedModel(_currentmodel)
          projectStore.setZoomToModel(_currentmodel._id)
        })
        .then(() => {
          projectStore.setLoadingProgress(false)
          onCancel()
          message.success(t('data-added-to-project'), 5)
        })
        .catch(err => {
          projectStore.setLoadingProgress(false)
          console.log('err', err)
          notification.open({
            message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
            description: t('something-went-wrong-when-creating-model'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={(
            <>
              {t('select-dataset')}
              {loadingDataLibrary && <Spin indicator={loadingIcon} />}
            </>
          )}
          name="predefineaccess"
          rules={[
            {
              required: true,
              message: t('please-select-an-dataset'),
            },
          ]}>
          <Select
            allowClear
            showSearch
            placeholder={t('select-dataset')}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
          >
            {loadingDataLibrary ?
              (<Option><Spin tip={t('loading-data-library')} spinning={true} /></Option>) :
              (
                adminStore.listPreDefinedAccess.length > 0 ? adminStore.listPreDefinedAccess.map(pre => (
                  <Option key={pre.id} value={pre.id}>{pre.name}</Option>
                )) : ''
              )
            }
          </Select>
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button type="primary" htmlType="submit">
            {t('add-resource')}
          </Button>
        </Row>
      </Form>
    )
  }

  const ExternalForm = () => {
    const [form] = Form.useForm();
    const [isUseCredential, setUseCredentialization] = useState(false)
    const [loadingDataLibrary, setLoadingDataLibrary] = useState(false)

    const handleSubmit = async values => {
      projectStore.setLoadingProgress(true)
      let crs = {}
      if (capturesStore.cameraData) {
        crs.position = {
          x: capturesStore.cameraData.position.x,
          y: capturesStore.cameraData.position.y,
          z: capturesStore.cameraData.position.z,
        }
        crs.code = '0'
      }
      let newModel = {
        name: values.name ? values.name.trim() : 'Unnamed',
        hash: '',
        data: {},
        project: projectStore.projectDetail._id,
        sourceType: 'external',
        crs,
        selectedKey: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null
      }
      newModel.data.detailLevel = values.detailLevel

      if (!isUrl(values.src)) {
        newModel.data.ionAssetId = parseInt(values.src)
      } else {
        // newModel.src = values.src.replace(/^https?\:\/\//i, '')
        if (values.src.includes('xd-visuals.com')) {
          newModel.src = values.src.replace(/^https?\:\/\//i, '')
        } else if (values.src.includes('tile.googleapis.com')) {
          try {
            GoogleMaps.defaultApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
            GoogleMaps.mapTilesApiEndpoint = values.src.split('3dtiles/')[0];;
            const googleTileset = await createGooglePhotorealistic3DTileset();
            newModel.src = googleTileset.resource.url
            newModel.data.isPhotorealistic3DTile = true
          } catch (error) {
            projectStore.setLoadingProgress(false)
            console.log(`Error loading Photorealistic 3D Tiles tileset.${error}`);
            notification.open({
              message: `${t('error-google-3dtiles-maximum-load-amount')} ${error}`,
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            })
            return
          }
        } else if (Utils.getDomain(values.src, true) === window.location.host) {
          // case check if url same with domain (copy public json in edit model)
          let projectname = values.src.split('/')[3];
          let hash = values.src.split('/')[4];
          let modelname = values.src.split("/").pop();
          let jsonlink = await getOriginPublicJsonLink(projectStore, commonStore.token, projectname, hash, modelname)
          newModel.src = jsonlink
        } else {
          newModel.src = values.src
        }
        if (isUseCredential && values.username && values.password) {
          const { data } = await ProjectRequest.checkJSONLink({ url: newModel.src, username: values.username, password: values.password })
          if (data && (data.status === 401 || data.status === 403)) {
            projectStore.setLoadingProgress(false)
            notification.open({
              message: t('invalid-credentials'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
            return
          } else if (data && data.status === 400) {
            projectStore.setLoadingProgress(false)
            notification.open({
              message: t('response-not-valid-json'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
            return
          } else {
            newModel.data.credential = Buffer.from(values.username + ':' + values.password).toString('base64');
            newModel.data.is3DTilesPointcloud = false
            let myJson = data.data;
            if (myJson.root && myJson.root.content) {
              let key = Object.keys(myJson.root.content)[0];
              let value = myJson.root.content[key];
              if (value?.split) {
                let ext = value.split('.').pop();
                if (ext === 'pnts') {
                  newModel.data.is3DTilesPointcloud = true
                }
              }
            }
          }
        } else {
          const { data } = await ProjectRequest.checkJSONLink({ url: newModel.src })
          if (data.status === 200) {
            let myJson = data.data;
            if (myJson.root && myJson.root.content) {
              let key = Object.keys(myJson.root.content)[0];
              let value = myJson.root.content[key];
              if (value?.split) {
                let ext = value.split('.').pop();
                if (ext === 'pnts') {
                  newModel.data.is3DTilesPointcloud = true
                }
              }
            }
          }
        }
      }
      if (!newModel.src.includes('.json')) {
        newModel.data.isI3sModel = true
      }
      projectStore
        .createModel(newModel)
        .then(async (model) => {
          // Update Project tilesetdata and Model data return current model
          let _currentmodel = await updateProjectModel(model.project, model)

          // update project TreeData
          let _treeData = await updateProjectTreeData(model.project._id, model, projectStore.projectDetail.treeData)
          _currentmodel.project.treeData = _treeData

          /**Update FE projectStore.ProjectDetail */
          await projectStore.updateProjectRefPoint(_currentmodel.project)

          projectStore.setCurrentModelId(false)
          projectStore.setNewModelId(_currentmodel._id)

          projectStore.setSelectedModel(_currentmodel)
          projectStore.setZoomToModel(_currentmodel._id)
        })
        .then(() => {
          projectStore.setLoadingProgress(false)
          onCancel()
          message.success(t('data-added-to-project'), 5)
        })
        .catch(err => {
          projectStore.setLoadingProgress(false)
          console.log('err', err)
          notification.open({
            message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
            description: t('something-went-wrong-when-creating-model'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }

    useEffect(() => {
      form.setFieldsValue({ detailLevel: 16 })
      return () => {
        setUseCredentialization(false)
        setLoadingDataLibrary(false)
      }
    }, [])

    function roundNumber(value) {
      if (!isNaN(value)) {
        return Math.round(value)
      }
    }

    const validJsonLink = async (rule, value) => {
      if (!value) {
        setUseCredentialization(false)
        form.resetFields(["username", "password"]);
        return Promise.resolve();
      }
      if (!isUrl(value)) { //case ionAssetId
        setUseCredentialization(false)
        form.resetFields(["username", "password"]);
        return Promise.resolve();
      }
      if (value.includes("tile.googleapis.com")) {
        setUseCredentialization(false)
        form.resetFields(["username", "password"]);
        return Promise.resolve();
      } else {
        setLoadingDataLibrary(true)
        const { data } = await ProjectRequest.checkJSONLink({ url: value })
        setLoadingDataLibrary(false)
        if (data && data.status === 200) {
          setUseCredentialization(false)
          form.resetFields(["username", "password"]);
          return Promise.resolve();
        } else {
          if (data && (data.status === 401 || data.status === 403)) {
            setUseCredentialization(true)
            return Promise.resolve();
          } else {
            setUseCredentialization(false)
            form.resetFields(["username", "password"]);
            return Promise.resolve();
          }
        }
      }
    };

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-external-tileset-or-ion-asset-id'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={(
            <>
              {t('link')}
              {loadingDataLibrary && <Spin indicator={loadingIcon} />}
            </>
          )}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-external-link'),
            },
            { validator: validJsonLink }
          ]}>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('username')}
              name="username"
              autoComplete="off"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-username'),
                },
              ]}
            >
              <Input autoComplete="username" placeholder={t('username')} />
            </Form.Item>
          </Col>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('password')}
              name="password"
              autoComplete="off"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-password'),
                },
              ]}
            >
              <Input.Password type='password' autoComplete="new-password" placeholder={t('password')} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t('detail-level-multiplier')}
          name="detailLevel"
        >
          <InputNumber min={0.025} max={128} step={0.025} placeholder={t('detail-level-multiplier')} style={{ width: '100%' }} />
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button type="primary" disabled={loadingDataLibrary} htmlType="submit">
            {t('add-resource')}
          </Button>
        </Row>
      </Form>
    )
  }

  const CesiumIonForm = () => {
    const [form] = Form.useForm();
    useEffect(() => {
      form.setFieldsValue({ detailLevel: 1 })
      return () => {
      }
    }, [])
    function roundNumber(value) {
      if (!isNaN(value)) {
        return Math.round(value)
      }
    }
    const handleSubmit = async values => {
      projectStore.setLoadingProgress(true)
      let crs = {}
      if (capturesStore.cameraData) {
        crs.position = {
          x: capturesStore.cameraData.position.x,
          y: capturesStore.cameraData.position.y,
          z: capturesStore.cameraData.position.z,
        }
        crs.code = '0'
      }
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {
          cesiumToken: values.cesiumToken
        },
        project: projectStore.projectDetail._id,
        sourceType: 'external',
        crs,
        selectedKey: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null,
        isTerrain: values.isTerrain
      }
      newModel.data.detailLevel = values.detailLevel
      if (!isUrl(values.src)) {
        newModel.data.ionAssetId = parseInt(values.src)
      } else {
        // newModel.src = values.src.replace(/^https?\:\/\//i, '')
        if (values.src.includes('xd-visuals.com')) {
          newModel.src = values.src.replace(/^https?\:\/\//i, '')
        } else if (Utils.getDomain(values.src, true) === window.location.host) {
          // case check if url same with domain (copy public json in edit model)
          let projectname = values.src.split('/')[3];
          let hash = values.src.split('/')[4];
          let modelname = values.src.split("/").pop();
          let jsonlink = await getOriginPublicJsonLink(projectStore, commonStore.token, projectname, hash, modelname)
          newModel.src = jsonlink
        } else {
          newModel.src = values.src
        }
      }
      projectStore
        .createModel(newModel)
        .then(async (model) => {
          // Update Project tilesetdata and Model data return current model
          let _currentmodel = await updateProjectModel(model.project, model)

          // update project TreeData
          let _treeData = await updateProjectTreeData(model.project._id, model, projectStore.projectDetail.treeData)
          _currentmodel.project.treeData = _treeData

          /**Update FE projectStore.ProjectDetail */
          await projectStore.updateProjectRefPoint(_currentmodel.project)

          projectStore.setCurrentModelId(false)
          projectStore.setNewModelId(_currentmodel._id)

          projectStore.setSelectedModel(_currentmodel)
          projectStore.setZoomToModel(_currentmodel._id)
        })
        .then(() => {
          projectStore.setLoadingProgress(false)
          onCancel()
          message.success(t('data-added-to-project'), 5)
        })
        .catch(err => {
          projectStore.setLoadingProgress(false)
          console.log('err', err)
          notification.open({
            message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
            description: t('something-went-wrong-when-creating-model'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }

    return (
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={false}
          name="isTerrain"
          valuePropName="checked"
        >
          <Checkbox>{t('terrain')}</Checkbox>
        </Form.Item>
        <Form.Item
          label={t('cesium-ion-asset-id')}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-ion-asset-id'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('cesium-ion-access-token')}
          name="cesiumToken"
          rules={[
            {
              required: true,
              message: t('please-input-ion-access-token'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('detail-level-multiplier')}
          name="detailLevel"
        >
          <InputNumber min={0.025} max={128} step={0.025} placeholder={t('detail-level-multiplier')} style={{ width: '100%' }} />
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button type="primary" htmlType="submit">
            {t('add-resource')}
          </Button>
        </Row>
      </Form>
    )
  }

  const WMSForm = () => {
    const [form] = Form.useForm();
    const [layerData, setLayerData] = useState([])
    const [xmlData, setXmlData] = useState()
    const [loadingXml, setLoadingXml] = useState(false)
    const [epsg, setEpsg] = useState()
    const [isNcWms, setIsNcWms] = useState(false)
    const [prevSrv, setPrevSrv] = useState('')
    const [palettes, setPalettes] = useState([])
    const [metaDatas, setMetaDatas] = useState([])
    const [availableDates, setAvailableDates] = useState([])
    const [selectedDate, setSelectedDate] = useState()
    const [isUseCredential, setUseCredentialization] = useState(false)
    const [timeSteps, setTimeSteps] = useState([])
    const [terrainValue, setTerrainValue] = useState('TERRAIN')
    const [planeValue, setPlaneValue] = useState('PLANE')
    const [showPlaneForm, setShowPlaneForm] = useState(false)
    const [subLayerData, setSubLayerData] = useState()
    const [authType, setAuthType] = useState() //authtype server, layer
    const [version, setVersion] = useState('')

    useEffect(() => {
      return () => {
        if (projectStore.currentAddTab === 'WMS') {
          form.resetFields()
          setLayerData([])
          setXmlData()
          setLoadingXml(false)
          setEpsg()
          setPrevSrv('')
          setUseCredentialization(false)
          setVersion('')
          setAuthType()
        }
      }
    }, [])

    const onChangeClassificationType = (value, key) => {
      if (value === 'CESIUM_3D_TILE' || value === 'BOTH' || key.key === 'TERRAIN') {
        setShowPlaneForm(false)
      }
      if (key.key === 'PLANE') {
        setShowPlaneForm(true)
      }
      if (value !== "TERRAIN") {
        form.setFieldsValue({ height: 0 })
      }
    }

    useEffect(() => {
      if (terrainValue === "TERRAIN") {
        form.setFieldsValue({ height: 0 })
      }
    }, [terrainValue])

    const handleTerrain = () => {
      setPlaneValue('2')
      setTerrainValue('TERRAIN')
      setShowPlaneForm(false)
    }

    const handlePlane = () => {
      setShowPlaneForm(true)
      setPlaneValue('TERRAIN')
      setTerrainValue('1')
    }

    const onBlur = (src) => {
      const urlParams = src ? Utils.getAllUrlParams(src) : {}
      let urlSrc, prevSrvUrl
      try {
        urlSrc = new URL(src);
        if (prevSrv)
          prevSrvUrl = new URL(prevSrv)
      } catch (error) {
        notification.open({
          message: t('invalid-url'),
          icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          duration: 5,
        })
        return
      }

      if (prevSrvUrl && prevSrvUrl.origin !== urlSrc.origin) {
        setUseCredentialization(false)
        form.setFieldsValue({
          useProxy: false
        })
        form.setFieldsValue({ username: undefined });
        form.setFieldsValue({ layers: undefined });
        form.setFieldsValue({ password: undefined });
      }
      if (src && (src.trim() === `${apiNcWmsUrl}/wms` || src.trim() === `${apiNcWmsUrl}/wms/`)) {
        setIsNcWms(true);
      } else {
        setIsNcWms(false);
      }
      try {
        if (urlParams && urlParams.request) {
          var baseUrl = src.split('?')[0]
          baseUrl += '?'
          for (const key in urlParams) {
            if (key.toLowerCase() !== 'request')
              baseUrl += `${key}=${urlParams[key]}`
          }
          src = baseUrl
          form.setFieldsValue({ src: src });
        }
      } catch (error) {

      }
      setPrevSrv(src)
      fetchLayers(src, form.getFieldValue('useProxy'), false)
    }

    const fetchLayers = async (src, retry = false, credentials = false) => {
      setUseCredentialization(credentials)
      form.setFieldsValue({ date: undefined });
      form.setFieldsValue({ time: undefined });
      if (src) {
        if (form.getFieldValue('useProxy')) {
          src = `${apiUrl}/wms/${encodeURIComponent(src)}`
        }

        let requestOption = {}
        const username = form.getFieldValue('username')
        const password = form.getFieldValue('password')
        if (username && password && isUseCredential) {
          const Authorization = btoa(`${username.trim()}:${password}`)
          requestOption.headers = {
            Authorization: `Basic ${Authorization}`
          }
        }

        setLoadingXml(true)
        const parser = new WMSCapabilities();
        let res = await fetch(`${src}?request=GetCapabilities&service=WMS`, requestOption).then(function (response) {
          if (!response.ok) {
            if (response.status === 401) {
              setAuthType('server')
              setUseCredentialization(true)
              form.setFieldsValue({
                useProxy: false
              })
              notification.open({
                message: t('invalid-credentials'),
                description: t('data-needs-authentication'),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 5,
              })
            } else {
              if (isUseCredential) {
                notification.open({
                  message: t('invalid-credentials'),
                  description: t('authentication-failed'),
                  icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                  duration: 5,
                })
              } else if (!retry) {
                form.setFieldsValue({
                  useProxy: true
                })
                fetchLayers(form.getFieldValue('src'), true);
              }
            }
          } else {
            return response.text();
          }
        }).then(function (text) {
          if (text) {
            const result = parser.read(text);
            setVersion(result ? result.version : '')
          }
          return text
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          setLoadingXml(false)
        });

        var xml = res ? Utils.parseXml(res) : false;
        if (xml) {
          const layerData = xml.querySelectorAll('Layer > Name');
          setXmlData(xml.getElementsByTagName("Layer"))
          setLayerData(Array.prototype.map.call(layerData, element => element.innerHTML));
        } else {
          form.setFieldsValue({ layers: undefined });
          setLayerData([]);
          form.setFieldsValue({ bbox: { x1: '', y1: '', x2: '', y2: '' } });
        }
      }
    }

    const getMetadata = async (src, layerName) => {
      if (src) {
        const res = await axios({
          method: 'get',
          url: src,
          params: {
            request: 'GetMetadata',
            item: 'layerDetails',
            layerName
          }
        })

        if (res.data && res.data.palettes?.length) {
          const { palettes } = res.data
          setMetaDatas(res.data)
          setPalettes(palettes)

          form.setFieldsValue({
            palette: palettes[0]
          })
        }
      }
    }

    const getTimeSteps = async (src, layerName) => {

      if (selectedDate) {
        const res = await axios({
          method: 'get',
          url: src,
          params: {
            request: 'GetMetadata',
            item: 'timesteps',
            layerName,
            day: moment(selectedDate).format(dateFormat),
          }
        })
        if (res.data) {
          setTimeSteps(res.data.timesteps)
          if (res.data.timesteps && res.data.timesteps.length)
            form.setFieldsValue({ time: res.data.timesteps[0] });
        }
      }
    }

    // disable date
    const disabledDate = (current) => {
      let condition = false

      const formatedDate = moment(current).format(dateFormat)
      if (availableDates && availableDates.length > 0) {
        return availableDates.indexOf(formatedDate) === -1
      }
      return condition
    }

    const onChangeDateSerial = (date) => {
      setSelectedDate(date)
      form.setFieldsValue({ date: moment(date) });
    }

    const onChangeTimeSerial = (time) => {
      form.setFieldsValue({ time: time });
    }

    const extractAvailableDate = () => {
      if (metaDatas && metaDatas.nearestTimeIso) {
        const defaultDate = metaDatas.nearestTimeIso.slice(0, 10)

        const getStartTime = metaDatas.datesWithData
        const availableDate = []
        for (let year in getStartTime) {
          let months = getStartTime[year]
          for (let month in months) {
            const realMonth = Number(month) + 1;
            const formattedMonth = realMonth < 10 ? '0' + realMonth : realMonth
            let days = months[month]
            for (let index = 0; index < days.length; index++) {
              const day = days[index];
              const formattedDate = day < 9 ? '0' + day : day
              availableDate.push(`${year}-${formattedMonth}-${formattedDate}`)
            }
          }
        }
        setAvailableDates(availableDate)
        form.setFieldsValue({ date: moment(defaultDate) });
        setSelectedDate(moment(defaultDate))
      }
    }

    // Datepicker
    useEffect(() => {
      extractAvailableDate()
    }, [metaDatas, layerData])

    useEffect(() => {
      getTimeSteps(form.getFieldValue('src'), form.getFieldValue('layers'))
    }, [selectedDate])

    /**
     * Check Authen layer
     * @param {*} data { src: src, layer: layer, bbox: bbox, username, password }
     * @returns 
     */
    const checkAuthLayer = async (data) => {
      let { src, layer, bbox, username, password, version } = data
      let _bbox = [bbox.x1, bbox.y1, bbox.x2, bbox.y2].join(',')
      if (!bbox.x1) {
        if (bbox.length === 4) {
          _bbox = bbox.join(',')
        }
      }
      if (form.getFieldValue('useProxy')) {
        src = `${apiUrl}/wms/${encodeURIComponent(src)}`
      }
      let requestOption = {
        method: 'get',
        url: src,
        responseType: 'document',
        params: {
          service: 'wms',
          request: 'GetMap',
          crs: "EPSG:4326",
          format: "image/png",
          layers: layer,
          width: 256,
          height: 256,
          bbox: _bbox,
          version: '1.1.1',
          styles: ''
        }
      }

      if (version) {
        requestOption.params.version = version
      }

      if (username && password && isUseCredential) {
        const Authorization = btoa(`${username.trim()}:${password}`)
        requestOption.headers = {
          'Authorization': `Basic ${Authorization}`,
          'Access-Control-Allow-Origin': '*',
        }
      }
      return await axios(requestOption).then(res => {
        return true
      }).catch(e => {
        if (e?.status === 401 || e?.status === 403) {
          setAuthType('layer')
          setUseCredentialization(true)
          if (isUseCredential) {
            notification.open({
              message: t('invalid-credentials'),
              description: t('authentication-failed'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          } else {
            notification.open({
              message: t('invalid-credentials'),
              description: t('data-needs-authentication'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          }

          return false
        } else {
          notification.open({
            message: t('invalid-credentials'),
            description: t('layer-is-not-valid'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 5,
          })
          return false
        }
      })
    }

    const onChangeLayer = async (value) => {
      var box = { x1: undefined, x2: undefined, y1: undefined, y2: undefined };
      form.setFieldsValue({ bbox: box });
      if (!value) return;
      if (isNcWms) {
        setSelectedDate(undefined)
        await getMetadata(form.getFieldValue('src'), value)
      }

      for (let i = 0; i < xmlData.length; i++) {
        const isGroup = xmlData[i].getElementsByTagName('Layer').length > 1
        if (isGroup) continue

        const tagName = xmlData[i].getElementsByTagName('Name')[0];
        if (tagName.innerHTML === value) {
          const geographicBoundingBox = xmlData[i].getElementsByTagName('EX_GeographicBoundingBox');
          const CRS = xmlData[i].getElementsByTagName('CRS');
          if (CRS && CRS.length) {

          }
          if (geographicBoundingBox?.length) {
            let x1, x2, y1, y2
            // eslint-disable-next-line no-unused-expressions
            geographicBoundingBox[0]?.childNodes.forEach(x => {
              if (x.localName === 'westBoundLongitude') {
                x1 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'eastBoundLongitude') {
                x2 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'southBoundLatitude') {
                y1 = +x.childNodes[0]?.nodeValue
              }
              if (x.localName === 'northBoundLatitude') {
                y2 = +x.childNodes[0]?.nodeValue
              }
            });

            box = { x1, y1, x2, y2 }
            //form.setFieldsValue({ bbox: { x1, y1, x2, y2 } });
          } else {
            const boundingBoxs = xmlData[i].getElementsByTagName('BoundingBox');
            if (boundingBoxs?.length) {
              for (let j = 0; j < boundingBoxs.length; j++) {
                if (
                  boundingBoxs[j].getAttribute('SRS')?.toUpperCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('srs')?.toLowerCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('CRS')?.toLowerCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('crs')?.toLowerCase() === 'EPSG:4326'
                ) {
                  const x1 = +boundingBoxs[j].getAttribute('minx')
                  const x2 = +boundingBoxs[j].getAttribute('maxx')
                  const y1 = +boundingBoxs[j].getAttribute('miny')
                  const y2 = +boundingBoxs[j].getAttribute('maxy')
                  box = { x1, y1, x2, y2 }
                }
              }
            } else {
              const latlngBoxs = xmlData[i].getElementsByTagName('LatLonBoundingBox')[0];
              if (latlngBoxs) {
                const x1 = +latlngBoxs.getAttribute('minx')
                const x2 = +latlngBoxs.getAttribute('maxx')
                const y1 = +latlngBoxs.getAttribute('miny')
                const y2 = +latlngBoxs.getAttribute('maxy')
                box = { x1, y1, x2, y2 }
              }
            }
          }
          try {
            if (!validDegreeBox(box)) throw box;
            form.setFieldsValue({ bbox: box });
            setEpsg('EPSG:4326');

            //check auth layer
            await checkAuthLayer({ src: form.getFieldValue('src'), layer: form.getFieldValue('layers'), bbox: form.getFieldValue('bbox'), username: form.getFieldValue('username'), password: form.getFieldValue('password'), version: version })
          } catch (error) {
            box = undefined
            notification.open({
              message: t('wms-layer-coordinates-do-not-match'),
              description: t('xd-twin-cannot-import-this-layer'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          }
          break;
        }
      }
    }

    function subStringURL(value) {
      const subServer = value.split("?")[0];
      let cutSubServer = value.replace(subServer, '')
      let layer = ''
      let subLayer = null
      if (cutSubServer)
        layer = cutSubServer.split("layers=")[1];
      if (layer)
        subLayer = layer.split("&")[0];
      if (subServer) {
        form.setFieldsValue({ src: subServer })
        onBlur(subServer)
      }
      if (subLayer) {
        setSubLayerData(subLayer)
      }
    }

    useEffect(() => {
      if (xmlData && subLayerData) {
        form.setFieldsValue({ layers: subLayerData })
        onChangeLayer(subLayerData)
      }
    }, [xmlData])

    useEffect(() => {
      var count = 0;
      if (subLayerData) {
        for (var i = 0; i < layerData.length; i++) {
          if (layerData[i] === subLayerData) {
            count++;
          }
        }
        if (count > 0) {
          form.setFieldsValue({ layers: subLayerData })
        }
        else {
          form.setFieldsValue({ layers: '' })
        }
      }

    })

    const handleSubmit = async values => {
      let crs = {}
      if (capturesStore.cameraData) {
        crs.position = {
          x: capturesStore.cameraData.position.x,
          y: capturesStore.cameraData.position.y,
          z: capturesStore.cameraData.position.z,
        }
        crs.code = '0'
      }

      if (!values.bbox || !values.bbox.x1 || !values.layers) {
        return
      }
      projectStore.setLoadingProgress(true)
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {
          layers: values.layers,
          parameters: {
            transparent: true,
            format: "image/png",
          }
        },
        project: projectStore.projectDetail._id,
        sourceType: 'WMS',
        crs,
        src: values.src,
        selectedKey: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null
      }
      newModel.data.alpha = isNaN(values.alpha) ? 0 : values.alpha
      newModel.data.bbox = [values.bbox.x1, values.bbox.y1, values.bbox.x2, values.bbox.y2]
      newModel.data.epsg = +epsg.split(':')[1];
      newModel.data.classificationType = values.classificationType || null;
      newModel.data.useWMSProvider = values.useWMSProvider;
      newModel.data.useProxy = values.useProxy !== undefined ? values.useProxy : false;
      newModel.data.height = values.height;
      newModel.data.presentation = (planeValue === 'TERRAIN') ? 'PLANE' : ''
      newModel.data.version = version ? version : '';
      newModel.data.authType = authType !== undefined ? authType : '';
      newModel.data.isUseCredential = isUseCredential !== undefined ? isUseCredential : false;
      newModel.data.username = values.username ? values.username : '';
      newModel.data.password = values.password ? values.password : '';
      if (isUseCredential && authType === 'layer') {
        let resp = await checkAuthLayer({ src: values.src, layer: values.layers, bbox: values.bbox, username: values.username, password: values.password, version: version })
        if (!resp) {
          projectStore.setLoadingProgress(false)
          return
        }
      }

      // 
      const createModel = (newModel) => {
        projectStore
          .createModel(newModel)
          .then(async (model) => {
            // Update Project tilesetdata and Model data return current model
            let _currentmodel = await updateProjectModel(model.project, model)

            // update project TreeData
            let _treeData = await updateProjectTreeData(model.project._id, model, projectStore.projectDetail.treeData)
            _currentmodel.project.treeData = _treeData

            /**Update FE projectStore.ProjectDetail */
            await projectStore.updateProjectRefPoint(_currentmodel.project)

            projectStore.setCurrentModelId(false)
            projectStore.setNewModelId(_currentmodel._id)

            projectStore.setSelectedModel(_currentmodel)
            projectStore.setSelectedModel(_currentmodel)
            projectStore.setZoomToModel(_currentmodel._id)
          })
          .then(() => {
            projectStore.setLoadingProgress(false)
            onCancel()
            message.success(t('data-added-to-project'), 5)
          })
          .catch(err => {
            projectStore.setLoadingProgress(false)
            console.log('err', err)
            notification.open({
              message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
              description: t('something-went-wrong-when-creating-model'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          })
      }
      if (isNcWms) {
        newModel.data.isNcWms = true;
        newModel.data.palette = values.palette;
        newModel.data.time = `${moment(values.date).format(dateFormat)}T${values.time || '00:00:00.000Z'}`
        axios({
          method: 'get',
          url: values.src,
          params: {
            request: 'GetMetadata',
            item: 'minmax',
            layers: values.layers,
            styles: 'default-scalar',
            bbox: newModel.data.bbox.join(','),
            srs: 'EPSG:4326',
            height: 100,
            width: 100,
            time: newModel.data.time
          }
        }).then(res => {
          newModel.data.scaleRange = res.data;
          createModel(newModel);
        }).catch(e => {
          projectStore.setLoadingProgress(false)
          notification.open({
            message: t('an-error-occurred-when-creating-model-data'),
            description: t('something-went-wrong-when-extracting-scale-range'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
      } else {
        createModel(newModel);
      }
    }

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input placeholder={t('name')} />
        </Form.Item>
        <Form.Item
          label={t('server')}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-url'),
            },
          ]}>
          <Input onChange={(e) => { subStringURL(e.target.value) }} placeholder="URL" />
        </Form.Item>
        <Form.Item name="useProxy" valuePropName="checked">
          <Checkbox onChange={(e) => fetchLayers(form.getFieldValue('src'), e, isUseCredential)}>{t('proxy-select-if-cannot-load-any-layers')}</Checkbox>
        </Form.Item>
        <Form.Item
          label={(
            <>
              {t('layer')}
              {loadingXml && <Spin indicator={loadingIcon} />}
            </>
          )}
          name="layers"
          rules={[
            {
              required: true,
              message: t('please-select-layers'),
            },
          ]}>
          <Select allowClear showSearch placeholder={t('please-select')} onChange={(e) => { onChangeLayer(e) }}>
            {loadingXml ?
              (<Option><Spin tip={t('loading-layers')} spinning={true} /></Option>) :
              (
                layerData.map(layer => (
                  <Option key={layer} value={layer}>{layer}</Option>
                ))
              )
            }
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('username')}
              autoComplete="off"
              name="username"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-username'),
                },
              ]}
            >
              <Input autoComplete="username" placeholder={t('username')} onBlur={e => {
                if (e.target.value && form.getFieldValue('password') && authType === 'server') {
                  fetchLayers(form.getFieldValue('src'), form.getFieldValue('useProxy'), isUseCredential)
                }
              }} />
            </Form.Item>
          </Col>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('password')}
              autoComplete="off"
              name="password"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-password'),
                },
              ]}
            >
              <Input.Password autoComplete="new-password" placeholder={t('password')} onBlur={e => {
                if (e.target.value && form.getFieldValue('username') && authType === 'server') {
                  fetchLayers(form.getFieldValue('src'), form.getFieldValue('useProxy'), isUseCredential)
                }
              }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={`${t('coordinates')} ${epsg ? `(${epsg})` : ''}`}>
          <Form.Item
            name={['bbox', 'x1']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="x1" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'y1']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="y1" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'x2']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="x2" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'y2']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="y2" style={{ width: '100%' }} />
          </Form.Item>
        </Form.Item>
        {(isNcWms) && (
          <Row>
            <Form.Item label={t('date-and-time')}>
              <Form.Item
                name="date"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                initialValues={selectedDate}
                rules={[
                  {
                    required: true,
                    message: t('please-select-time'),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                  format="YYYY-MM-DD"
                  inputReadOnly={true}
                  onChange={onChangeDateSerial}
                />
              </Form.Item>
              <Form.Item
                name="time"
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                rules={[
                  {
                    required: true,
                    message: t('please-select-time'),
                  },
                ]}
              >

                <Select
                  initialValues="00:00:00.000Z"
                  style={{ width: '100%' }}
                  onChange={onChangeTimeSerial}
                >
                  {
                    timeSteps &&
                    timeSteps.map(time =>
                      <Option value={time} key={time}>{time}</Option>
                    )
                  }

                </Select>
              </Form.Item>
            </Form.Item>
          </Row>
        )}

        {isNcWms && (
          <Form.Item
            label={t('colour-palette')}
            name="palette"
            rules={[
              {
                required: true,
                message: t('please-select-palette'),
              },
            ]}>
            <Select allowClear showSearch placeholder={t('select-a-option')}>
              {loadingXml ?
                (<Option><Spin tip={t('loading-palettes')} spinning={true} /></Option>) :
                (
                  palettes.map(palette => (
                    <Option key={palette} value={palette}>
                      <img src={`${form.getFieldValue('src')}?request=GetLegendGraphic&height=20&width=500&numcolorbands=250&colorbaronly=true&vertical=false&palette=${palette}`} />
                    </Option>
                  ))
                )
              }
            </Select>
          </Form.Item>
        )}

        {/* <Form.Item
          label="Classification Type"
          name="classificationType"
        >
          <Select onChange={onChangeClassificationType} allowClear placeholder="Select a option">
            <Option key="BOTH" value={'BOTH'}>BOTH</Option>
            <Option key="CESIUM_3D_TILE" value={'CESIUM_3D_TILE'}>CESIUM 3D TILE</Option>
            <Option key='TERRAIN' value={'TERRAIN'} onMouseDown={handleTerrain}>TERRAIN</Option>
          </Select>
        </Form.Item> */}
        <Form.Item
          label={t('presentation-style')}
          name="classificationType"
          initialValue={terrainValue}
        >
          <Select onChange={onChangeClassificationType} allowClear placeholder={t('select-a-option')}>
            <Option key="TERRAIN" value={terrainValue} onMouseDown={handleTerrain}>{t('background-map')}</Option>
            <Option key="CESIUM_3D_TILE" value={'CESIUM_3D_TILE'}>{t('drape-to-models')}</Option>
            <Option key="BOTH" value={'BOTH'}>{t('background-and-models')}</Option>
            <Option key="PLANE" value={planeValue} onMouseDown={handlePlane}>{t('plane-with-elevation')}</Option>
          </Select>
        </Form.Item>
        {
          showPlaneForm &&
          <Form.Item
            label={t('elevation')}
            name="height"
          >
            <InputNumber placeholder={t('elevation')} style={{ width: '100%' }} />
          </Form.Item>
        }
        <Form.Item
          label={(
            <>
              {t('transparency')}
            </>
          )}
          name="alpha">
          <Slider
            style={{ margin: '10px' }}
            min={0.0}
            max={1.0}
            step={0.1}
          />
        </Form.Item>
        {/* <Form.Item name="useWMSProvider" valuePropName="checked">
          <Checkbox>Use WMS Imagery Provider</Checkbox>
        </Form.Item> */}
        <Form.Item>
          <Row justify='end'>
            <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
            <Button type="primary" htmlType="submit">
              {t('add-resource')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    )
  }

  const Form3DCityDB = () => {
    const [form] = Form.useForm();

    const handleSubmit = async values => {
      projectStore.setLoadingProgress(true)
      let crs = {}
      if (capturesStore.cameraData) {
        crs.position = {
          x: capturesStore.cameraData.position.x,
          y: capturesStore.cameraData.position.y,
          z: capturesStore.cameraData.position.z,
        }
        crs.code = '0'
      }
      let newModel = {
        name: values.name ? values.name : 'Unnamed',
        hash: '',
        data: {},
        project: projectStore.projectDetail._id,
        sourceType: 'City3DDB',
        crs,
        selectedKey: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null
      }
      newModel.src = values.src

      projectStore
        .createModel(newModel)
        .then(async (model) => {
          // Update Project tilesetdata and Model data return current model
          let _currentmodel = await updateProjectModel(model.project, model)

          // update project TreeData
          let _treeData = await updateProjectTreeData(model.project._id, model, projectStore.projectDetail.treeData)
          _currentmodel.project.treeData = _treeData

          /**Update FE projectStore.ProjectDetail */
          await projectStore.updateProjectRefPoint(_currentmodel.project)

          projectStore.setCurrentModelId(false)
          projectStore.setNewModelId(_currentmodel._id)

          projectStore.setSelectedModel(_currentmodel)
          projectStore.setZoomToModel(_currentmodel._id)

        }).then(() => {
          projectStore.setLoadingProgress(false)
          onCancel()
          message.success(t('data-added-to-project'), 5)
        }).catch(err => {
          projectStore.setLoadingProgress(false)
          console.log('err', err)
          notification.open({
            message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
            description: t('something-went-wrong-when-creating-model'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            duration: 0,
          })
        })
    }

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input placeholder={t('name')} />
        </Form.Item>
        <Form.Item
          label="URL"
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-external-link'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Row justify='end'>
          <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
          <Button type="primary" htmlType="submit">
            {t('add-resource')}
          </Button>
        </Row>
      </Form>
    )
  }

  // tab file
  const DragDropFileForm = () => {
    const [showMeshModal, setShowMeshModal] = useState(false)
    const [meshValue, setMeshValue] = useState()
    const [dracoValue, setDracoValue] = useState()
    const [uploadStatus, setUploadStatus] = useState(false)
    const [validFiles, setValidFiles] = useState();

    const dummyRequest = async ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    }

    const fileProps = {
      name: 'file',
      multiple: true,
      showUploadList: false,
      customRequest: dummyRequest,
      onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
          let _progressInfos = Array.from(info.fileList).reduce(function (filtered, file) {
            if (file.uid && file.status === 'done' && !progressInfosRef.current.val.some(el => el.file.uid === file.uid)) {
              filtered.push(file);
            }
            return filtered;
          }, []);
          if (_progressInfos.length === info.fileList.length) {
            setValidFiles([...info.fileList]);
          }
        } else if (status === 'error') {
          message.error(`${info.file.name} ${t('file-upload-failed')}`);
        }
      }
    };

    useEffect(() => {
      if (validFiles && validFiles.length > 0) {
        const outputFileType = [];
        for (var i = 0, f; f = validFiles[i]; i++) {
          let fileType = f.name.slice(f.name.length - 4)
          fileType = fileType ? fileType.toLowerCase() : ''
          outputFileType.push(fileType)
        }

        const fileTypeCheck = ['.obj', '.gltf', '.glb', '.zip', '.dae', '.fbx']
        const found = fileTypeCheck.some(r => outputFileType.indexOf(r) >= 0)
        if (found) {
          //set state show meshmodel dialog confirm some option
          setShowMeshModal(true)
        } else {
          // set state uploadStatus
          setUploadStatus(true)
        }
      }
    }, [validFiles])

    useEffect(() => {
      if (validFiles && validFiles.length > 0 && uploadStatus) {
        let _progressInfos = Array.from(validFiles).map(file => {
          return { file: file.originFileObj, percentage: 0, isComplete: false, isCancel: false, isFailed: false, isUnsupported: (file.invalid === true) }
        });

        progressInfosRef.current = { val: [...progressInfosRef.current.val, ..._progressInfos] }
        // set state progressInfos
        setProgressInfos({
          val: [
            ...progressInfos.val,
            ..._progressInfos //add new array file upload
          ]
        })

        // filter get only files valid
        let filterValidFile = Array.from(validFiles).map(file => {
          // add property _id for file, used to check if the file has been dragged and check the position of the file in the array
          file.originFileObj._id = uuid();
          return file.originFileObj;
        }).filter(item => {
          return !item.invalid
        });

        // call function generate presignedurl from s3
        generatePreSigned(filterValidFile)

        setUploadStatus(false)
        setValidFiles()
      }
    }, [uploadStatus])

    /**
    * generate preSigned url from s3
    * @param {*} files array file need generate
    */
    const generatePreSigned = async (files) => {
      let fileUploadSize = 0;
      for (var i = 0; i < files.length; i++) {
        // calculate total size of all files        
        fileUploadSize += parseFloat(files[i].size) / 1024 / 1024;
      }

      if (fileUploadSize > Number.MAX_VALUE) {
        clearData()
        message.error(t('file-size-is-too-large'))
        projectStore.setLoadingProgress(false)
        return;
      }

      // check linces and totalQuota remaining        
      if (projectStore.projectDetail.organization && projectStore.projectDetail.organization.licenses && projectStore.projectDetail.organization.licenses.length > 0) {
        const licenseInfos = projectStore.getCurrentProjectStorageQuota(projectStore.projectDetail.organization)
        if (licenseInfos.totalQuota === 0) {
          clearData()
          message.error(t('your-license-is-expried-please-update-your-license'))
          projectStore.setLoadingProgress(false)
          return;
        }
      } else {
        clearData()
        message.error(t('you-do-not-have-license-please-update-license'))
        projectStore.setLoadingProgress(false)
        return;
      }

      // promise get presigned url from s3 return {file: filename, url: url signed, fileData: content file}
      const presignedS3Urls = await Promise.all(Array.from(files).map(async file => {
        let result = await projectStore.generatePreSignedPutUrl({ fileName: convertExtToLowerCase(file.name), projectId: projectStore.projectDetail._id })
        return {
          url: result.url,
          fileName: result.file,
          fileData: file,
          selectedNode: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null
        }
      }))

      projectStore.setLoadingProgress(false)
      if (fileStore.filesReadyProcess.length > 0) {
        await waitUntilPromiseFinish() // for case drop files when upload still in process
      }
      fileStore.setFilesReadyProcess([presignedS3Urls])
      let _sortpresigned = presignedS3Urls.sort((a, b) => parseFloat(a.fileData.size) - parseFloat(b.fileData.size));
      const promisesUploadS3 = _sortpresigned.map((filePresigned) => uploadToS3(filePresigned));
      try {
        // Create a queue to manage the processing tasks
        let processingQueue = Promise.resolve();

        // Upload all models in parallel and process each one as soon as it's uploaded
        const uploadPromises = promisesUploadS3.map(async (uploadPromise) => {
          const uploadedModel = await uploadPromise; // Wait for upload to finish

          // Add the processing task to the queue
          processingQueue = processingQueue.then(() => processModel(uploadedModel.idx, uploadedModel.model));
        });

        // Wait for all uploads to finish
        await Promise.all(uploadPromises);

        // Wait for all processing tasks to finish
        await processingQueue;

        console.log('All models uploaded and processed successfully');
        let filterprocessed = progressInfosRef.current.val.filter(x => x.isComplete === true || (x.isFailed === true))
        if (progressInfosRef.current.val.length === filterprocessed.length) {
          clearData()
        }
      } catch (error) {
        console.error('Error occurred:', error);
      }

      // await PromiseBlue.each(promisesUploadS3, async (item) => {
      //   if (item) {
      //     await processModel(item.idx, item.model)
      //   }
      // }).then(function (result) {
      //   // check all files processed then close dialog upload
        
      // }).catch(function (rejection) {
      //   console.log("Catch: " + rejection);
      // });
      fileStore.setFilesReadyProcess([])
    }

    async function waitUntilPromiseFinish() {
      return await new Promise(resolve => {
        const interval = setInterval(() => {
          if (fileStore.filesReadyProcess.length === 0) {
            resolve();
            clearInterval(interval);
          };
        }, 5000);
      });
    }
    /**
    * upload put file to s3 from presignedurl
    * @param {*} idx position file in array progressInfosRef.current.val
    * @param {*} presignedS3Url {file: filename, url: url signed, fileData: content file}
    */
    const uploadToS3 = async (presignedS3Url) => {
      // get state array files [{file: File, percentage: 0, isComplete: false, isCancel: false, isFailed: false, isUnsupport:},{...}]        
      let idx = progressInfosRef.current.val.findIndex(x => x.file._id === presignedS3Url.fileData._id);
      if (idx > -1) {
        progressInfosRef.current.val[idx].url = presignedS3Url.url;
        progressInfosRef.current.val[idx].fileName = presignedS3Url.fileName;
        progressInfosRef.current.val[idx].isCancel = false; // need init for case refresh upload
        progressInfosRef.current.val[idx].isFailed = false;

        var config = {
          method: 'put',
          url: presignedS3Url.url,
          headers: {
            'Content-Type': getMimeOfFile(presignedS3Url.fileData.name),
            'Access-Control-Allow-Origin': '*'
          },
          data: presignedS3Url.fileData,
          onUploadProgress: event => {
            progressInfosRef.current.val[idx].percentage = Math.round(
              (100 * event.loaded) / event.total
            );
            setProgressInfos({ val: progressInfosRef.current.val });
          },
          cancelToken: new CancelToken(function executor(c) {
            setCancelsRequest(prev => [...prev, c]);  //set to array cancel token
          })
        };

        let file = presignedS3Url.fileData;
        let ext = presignedS3Url.fileName.split('.').pop()
        file.hash = presignedS3Url.fileName.split('.').shift()
        file.ext = `.${ext}`
        file.url = `${ASSET_URL}${presignedS3Url.fileName}`
        file.id = `${ASSET_URL}${presignedS3Url.fileName}`

        //put presignedurl
        return await axios(config)
          .then(async () => {
            // after put completed file uploaded s3 then call create model and synce cesium ion
            return await createModelUploadIon(idx, file, presignedS3Url.selectedNode)
          })
          .catch(error => {
            if (progressInfosRef.current.val.length > 0) {
              if (axios.isCancel(error) || error === undefined) {
                progressInfosRef.current.val[idx].isCancel = true;
              } else {
                progressInfosRef.current.val[idx].isFailed = true;
              }
              progressInfosRef.current.val[idx].percentage = 0;
              setProgressInfos({ val: progressInfosRef.current.val });
            }
          })
      }
    };

    /**
    * Create model and synce to ion
    * @param {*} idx position file in array progressInfosRef.current.val
    * @param {*} file 
    * @returns model
    */
    const createModelUploadIon = async (idx, file, selectedNode) => {
      // get progressInfosRef.current.val for upldate state file success or error
      let crs = {}
      let tilePosition = new Cartesian3.fromDegrees(0, 0)
      // neu co diem center man hinh                
      if (projectStore.centerData) {
        if (projectStore.centerData.cartographic.height < -1000) {
          crs.initPos = toJS(projectStore.centerData)
          crs.initPos.cartographic.height = 28
        } else
          crs.initPos = toJS(projectStore.centerData)
      } else {
        crs.initPos = {
          oldPos: tilePosition
        }
      }
      let newModel = {
        name: file.name ? file.name : 'Unnamed',
        src: file.url,
        hash: file.hash,
        modelType: meshValue,
        useDraco: dracoValue,
        data: {
          ext: file.ext,
          id: file.id,
          size: file.size,
        },
        project: projectStore.projectDetail._id,
        sourceType: 'local',
        crs,
        selectedKey: selectedNode
      }

      var pCrs = projectStore?.projectDetail?.tilesetData?.coordinateSystem ? projectStore?.projectDetail?.tilesetData?.coordinateSystem : '4326'

      if (pCrs)
        newModel.data.refData = {
          epsg: pCrs.code,
          heightSystem: projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
        }

      // we will use ifcSetting as tiling setting for both landxml and ifc        
      if (newModel.data.ext && ['.ifc', '.xml', '.las', '.laz'].includes(newModel.data.ext.toLowerCase())) {
        newModel.data.ifcSetting = projectSettingStore.systemProjectSetting.ifcSetting
      }

      // license type convert IFCEngineUsage
      if (projectStore.projectDetail.organization) {
        newModel.org = projectStore.projectDetail.organization.id
      }

      return await projectStore
        .createModel(newModel)
        .then(async (model) => {
          if (model.crs['Use IFCEngine'] !== undefined && model.crs['Use IFCEngine'] === 'True') {
            await create_ifcengineusage(projectStore.projectDetail.organization._id, projectStore.projectDetail._id, model, usersStore.currentUser._id)
          }
          if (model?.data?.ionAssetId) {
            let isUpdateConten = await updateContenModelIon(model, file)
            if (!isUpdateConten) {
              progressInfosRef.current.val[idx].isFailed = true;
              progressInfosRef.current.val[idx].percentage = 0;
              setProgressInfos({ val: progressInfosRef.current.val });
              notification.open({
                message: t('an-error-occurred-when-creating-model-data'),
                description: (
                  <>
                    `${t('file')}`: <b>{newModel.name}</b><br />
                    `${t('unable-update-ion-model-content')}`
                  </>
                ),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 0,
              })
              return false
            }
          }
          // update organization quota usage
          await projectStore.updateProjectStorage(projectStore.projectDetail._id)
          model.ref = false; //for case check load model (if true not reload)
          return { idx: idx, model: model }
        }).catch(err => {
          // update state progressInfos isFailed           
          progressInfosRef.current.val[idx].isFailed = true;
          progressInfosRef.current.val[idx].percentage = 0; //reset percentage
          setProgressInfos({ val: progressInfosRef.current.val });

          if (err && err.data && err.data.message) {
            notification.open({
              message: t('an-error-occurred-when-creating-model-data'),
              description: (
                <>
                  `${t('file')}`: <b>{newModel.name}</b><br />
                  {t(err.data.message)}
                </>
              ),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          } else {
            notification.open({
              message: t('an-error-occurred-when-creating-model-data'),
              description: (
                <>
                  `${t('file')}`: <b>{newModel.name}</b><br />
                  {t('something-went-wrong-when-creating-model')}
                </>
              ),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          }
        });
    };

    const processModel = async (idx, model) => {
      // Update Project tilesetdata and Model data return current model
      let rsp_model = await updateProjectModel(model.project, model)
      let _project = rsp_model.project

      // merge All model
      if (_project.tilesetData && _project.tilesetData.RefPoint && _project.tilesetData.coordinateSystem && (!['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'metre')) {
        let _model3ds = _project.model3DS.map(c => {
          let u = { ...c }
          if (c.id === model.id) {
            u.newUpdate = true
          }
          return u;
        })
        let modelmerge = await mergeAllModels(
          _model3ds,
          Cartesian3.fromDegrees(_project.tilesetData.RefPoint[1], _project.tilesetData.RefPoint[0], _project.tilesetData.RefPoint[2]),
          _project.tilesetData.refLocalProject ? Cartesian3.fromArray(_project.tilesetData.refLocalProject) : false,
          _project.tilesetData.georeferenced !== undefined ? _project.tilesetData.georeferenced : false,
          _project.headingRotation,
          _project.tilesetData.coordinateSystem.code,
          _project.elevationSystem ? _project.elevationSystem : 'None'
        );
        _project.model3DS = modelmerge // reassign list model to project
      }

      // update project TreeData
      let _treeData = await updateProjectTreeData(_project._id, model, projectStore.projectDetail.treeData)
      _project.treeData = _treeData

      /**Update FE projectStore.ProjectDetail */
      await projectStore.updateProjectRefPoint(_project)

      // effect projectStore.modelList only load model no model.ref => incorrect
      // after merge all models => position of model change => need set setNewModelId to effect updatePlaneMatix
      projectStore.setCurrentModelId(false)
      projectStore.setNewModelId(model._id)
      objectQueryStore.checkObjectInforsReady(projectStore.projectDetail._id)
      // update status file uploaded
      progressInfosRef.current.val[idx].isComplete = true
      setProgressInfos({ val: progressInfosRef.current.val });
      if (model?.type === 'cloudpoint' && model?.status === "waitting_process") {
        message.success(t('data-process-background'));
      }
    }

    const clearData = () => {
      progressInfosRef.current.val = []; // clear progressInfosRef
      setProgressInfos({ val: [] }); // clear progressInfos
      setDracoValue()
      setMeshValue()
      projectStore.setLoadingProgress(false)
      projectStore.setDisplayPanel(false)
      projectStore.setCheckModalStatus(false)
      projectStore.setShowProcessInBackground(false)
      projectStore.setShowAddResourceModel(false)
      projectStore.setSelectedStatus(true)
      cancelsRequest.forEach((c) => c()) // cancel all requests if it is still available
      setCancelsRequest([]) //clear cancel request
      fileStore.setFilesReadyProcess([])
    }

    return (
      <>
        <UploadWrapper>
          <Dragger {...fileProps} style={{ maxHeight: "180px" }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t('click-or-drag-file-to-this-area-to-upload')}
            </p>
            <p className="ant-upload-hint">
              {t('file-support')}: {projectSettingStore.systemProjectSetting.ifcSetting?.pointCloudEngine === 'xdEngine' ? ".xml,.ifc,.gltf,.glb,.zip,.obj,.dae,.fbx,.laz,.las,.kmz,.kml,.tiff,.tif,.geojson" : ".xml,.ifc,.gltf,.glb,.zip,.obj,.dae,.fbx,.laz,.las,.kmz,.kml,.tiff,.tif,.geojson"}
            </p>
            {
              progressInfos.val.some(file => file.isCancel === false && file.isFailed === false && file.isUnsupported === false && (file.percentage < 100 || file.isComplete === false)) ? (
                <p>{t('uploading-length-of-total-files', { length: progressInfos.val.filter(file => file.percentage === 100).length, total: progressInfos.val.length })}</p>
              ) : !uploadStatus && progressInfos.val.some(file => file.isCancel === true || file.isFailed === true || file.isUnsupported === true) ? (
                <p>{t('length-of-total-files-uploaded', { length: progressInfos.val.filter(file => file.percentage === 100 && file.isComplete === true).length, total: progressInfos.val.length })}</p>
              ) : null
            }
          </Dragger>
          <ProgressBodyStype>
            <div style={{ height: '100%' }}>
              {progressInfos && progressInfos.val.length > 0 &&
                progressInfos.val.map((progressInfo, index) => (
                  <div className='row-body' key={index} >
                    <div className="row-center row-distribute">
                      <div className="flex-row row-distribute flex-1">
                        <div className="flex-1 text-ellipsis">
                          <p className="text-ellipsis">{progressInfo.file.name}</p>
                          {
                            progressInfo.isCancel ? (
                              <div className="small text-ellipsis"><span className="text-bold">{t('canceled')}</span></div>
                            ) : progressInfo.percentage === 100 && !progressInfo.isComplete ? (
                              <div className="small text-ellipsis"><span className="text-bold">{t('syncing')}</span></div>
                            ) : progressInfo.isFailed ? (
                              <div className="small text-ellipsis"><span className="text-bold">{t('failed')}</span></div>
                            ) : progressInfo.isUnsupported ? (
                              <div className="small text-ellipsis"><span className="text-bold">{t('unsupported-format')}</span></div>
                            ) : null
                          }
                        </div>
                        <div className="text-muted">{bytesToSize(progressInfo.file.size)}</div>
                      </div>
                      {
                        progressInfo.isFailed ? (
                          <div className="flex-row"><InfoCircleOutlined style={{ minWidth: '40px', fontSize: '20px', color: '#c81922' }} /></div>
                        ) : progressInfo.isUnsupported ? (
                          <div className="flex-row"><InfoCircleOutlined style={{ minWidth: '40px', fontSize: '20px', color: '#c81922' }} /></div>
                        ) : progressInfo.percentage === 100 && progressInfo.isComplete ? (
                          <div className="flex-row"><span style={{ minWidth: '40px', textAlign: 'center' }}><SVGIcon content={<CloudDone />} width={22} height={22} color={'#72a544'} /></span></div>
                        ) : progressInfo.percentage === 100 && !progressInfo.isComplete ? (
                          <div className="flex-row"><SyncOutlined style={{ minWidth: '40px', fontSize: '18px', color: '#F26524' }} spin /></div>
                        ) : null
                      }
                    </div>
                    {progressInfo.percentage === 100 || progressInfo.isCancel || progressInfo.isFailed || progressInfo.isUnsupported ? '' : <Progress trailColor={'#d9d9d9'} percent={progressInfo.percentage} showInfo={false} />}
                  </div>
                ))
              }
            </div>
          </ProgressBodyStype>
          {
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleHideModalAddResources}>{t('process-in-background')}</Button>
            </div>
          }
        </UploadWrapper>
        <MeshModal
          setMeshValue={setMeshValue}
          setDracoValue={setDracoValue}
          setShowMeshModal={setShowMeshModal}
          setUploadStatus={setUploadStatus}
          showMeshModal={showMeshModal}
          setValidFiles={setValidFiles}
        />
      </>
    )
  }


  const WFSForm = () => {
    const [form] = Form.useForm();
    const [layerData, setLayerData] = useState([])
    const [xmlData, setXmlData] = useState()
    const [loadingXml, setLoadingXml] = useState(false)
    const [epsg, setEpsg] = useState()
    const [prevSrv, setPrevSrv] = useState('')
    const [isUseCredential, setUseCredentialization] = useState(false)
    const [subLayerData, setSubLayerData] = useState()
    const [outputFormat, setOutputFormat] = useState('application/json')
    const [authType, setAuthType] = useState() //authtype server, layer
    const [version, setVersion] = useState('')
    const [pointStyle, setPointStyle] = useState('Billboard')


    useEffect(() => {
      if (projectStore.currentAddTab === 'WFS') {
        form.setFieldsValue({
          pointColor: '#ffffff',
          pointOutlineColor: '#000000',
          pointAlpha: 0,
          pointPixelSize: 5,
          pointOutlineWidth: 0,
          lineMaterial: '#ffffff',
          lineAlpha: 0,
          lineWidth: 2,
          polygonMaterial: '#ffffff',
          polygonOutlineColor: '#000000',
          polygonAlpha: 0,
          polygonHeight: '',
          polygonExtrudedHeight: '',
          clampToGround: true,
          outputFormat: 'application/json',
          username: '',
          password: ''
        })
      }

      return () => {
        if (projectStore.currentAddTab === 'WFS') {
          form.resetFields()
          setLayerData([])
          setXmlData()
          setLoadingXml(false)
          setEpsg()
          setPrevSrv('')
          setUseCredentialization(false)
          setAuthType()
          setVersion('')
          setPointStyle('Billboard')
        }
      }
    }, [])

    /**
     * substring server url get host
     * @param {*} value //typename
     */
    const subStringURL = (value) => {
      const subServer = value.split("?")[0];
      let cutSubServer = value.replace(subServer, '')
      let layer = ''
      let subLayer = null
      if (cutSubServer)
        layer = cutSubServer.split("layers=")[1];

      if (layer)
        subLayer = layer.split("&")[0];

      if (subLayer) {
        setSubLayerData(subLayer)
      }

      if (subServer) {
        form.setFieldsValue({ src: subServer })
        onBlur(subServer)
      }
    }

    const onBlur = (src) => {
      const urlParams = src ? Utils.getAllUrlParams(src) : {}
      let urlSrc, prevSrvUrl
      try {
        urlSrc = new URL(src);
        if (prevSrv)
          prevSrvUrl = new URL(prevSrv)
      } catch (error) {
        notification.open({
          message: t('invalid-url'),
          icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          duration: 5,
        })
        return
      }

      if (prevSrvUrl && prevSrvUrl.origin !== urlSrc.origin) {
        setUseCredentialization(false)
        form.setFieldsValue({ username: undefined });
        form.setFieldsValue({ layers: undefined });
        form.setFieldsValue({ password: undefined });
      }

      try {
        if (urlParams && urlParams.request) {
          var baseUrl = src.split('?')[0]
          baseUrl += '?'
          for (const key in urlParams) {
            if (key.toLowerCase() !== 'request')
              baseUrl += `${key}=${urlParams[key]}`
          }
          src = baseUrl
          form.setFieldsValue({ src: src });
        }
      } catch (error) {

      }
      setPrevSrv(src)
      fetchLayers(src)
    }

    const fetchLayers = async (src, retry = false) => {
      setUseCredentialization(false)
      form.setFieldsValue({ layers: undefined });
      form.setFieldsValue({ bbox: { x1: '', y1: '', x2: '', y2: '' } });
      setLayerData([]);
      if (src) {
        let requestOption = {
          method: 'get',
          url: src,
          responseType: 'document',
          params: {
            request: 'GetCapabilities',
            service: 'WFS'
          }
        }

        const username = form.getFieldValue('username')
        const password = form.getFieldValue('password')
        if (username && password && isUseCredential) {
          const Authorization = btoa(`${username.trim()}:${password}`)
          requestOption.headers = {
            Authorization: `Basic ${Authorization}`
          }
        }
        setLoadingXml(true)
        const res = await axios(requestOption).catch(e => {
          if (e?.status === 401 || e?.status === 403) {
            setUseCredentialization(true)
            notification.open({
              message: t('invalid-credentials'),
              description: t('this-resource-require-credentials'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 5,
            })
          } else {
            if (isUseCredential) {
              notification.open({
                message: t('invalid-credentials'),
                description: t('this-resource-require-credentials'),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 5,
              })
            } else if (!retry) {
              fetchLayers(form.getFieldValue('src'), true);
            }
          }
        }).finally(() => {
          setLoadingXml(false)
        })
        if (res?.data) {
          let _capabilities = res.data.getElementsByTagName('wfs:WFS_Capabilities')[0] ? res.data.getElementsByTagName('wfs:WFS_Capabilities')[0] : res.data.getElementsByTagName('WFS_Capabilities')[0]
          if (_capabilities) {
            setVersion(_capabilities.getAttribute('version'))
          }

          const layerData = res.data.querySelectorAll('FeatureTypeList > FeatureType > Name');
          setXmlData(res.data.querySelectorAll('FeatureType'))
          setLayerData(Array.prototype.map.call(layerData, element => element.innerHTML));
        }
      }
    }

    const onChangeLayer = async (value) => {
      setUseCredentialization(false)
      if (value) {
        var box = { x1: undefined, x2: undefined, y1: undefined, y2: undefined };

        form.setFieldsValue({ bbox: box });
        for (let i = 0; i < xmlData.length; i++) {
          const isGroup = xmlData[i].getElementsByTagName('FeatureTypeList').length > 0
          if (isGroup) continue
          const tagName = xmlData[i].querySelectorAll('FeatureTypeList > FeatureType > Name')[0];
          if (tagName && tagName.innerHTML === value) {
            // get boudingbox
            let x1, x2, y1, y2
            if (xmlData[i].getElementsByTagName('ows:WGS84BoundingBox') && xmlData[i].getElementsByTagName('ows:WGS84BoundingBox').length) {
              xmlData[i].getElementsByTagName('ows:WGS84BoundingBox')[0].childNodes.forEach(x => {
                let str = x.childNodes[0]?.nodeValue.split(" ")
                if (str && x.localName === 'LowerCorner') {
                  x1 = +str[0]
                  y1 = +str[1]
                }
                if (str && x.localName === 'UpperCorner') {
                  x2 = +str[0]
                  y2 = +str[1]
                }
              });

              box = { x1, y1, x2, y2 }
            } else if (xmlData[i].getElementsByTagName('LatLongBoundingBox') && xmlData[i].getElementsByTagName('LatLongBoundingBox').length) {
              x1 = +xmlData[i].getElementsByTagName('LatLongBoundingBox')[0].getAttribute('minx')
              x2 = +xmlData[i].getElementsByTagName('LatLongBoundingBox')[0].getAttribute('maxx')
              y1 = +xmlData[i].getElementsByTagName('LatLongBoundingBox')[0].getAttribute('miny')
              y2 = +xmlData[i].getElementsByTagName('LatLongBoundingBox')[0].getAttribute('maxy')
              box = { x1, y1, x2, y2 }
            } else if (xmlData[i].getElementsByTagName('BoundingBox') && xmlData[i].getElementsByTagName('BoundingBox').length) {
              const boundingBoxs = xmlData[i].getElementsByTagName('BoundingBox');
              for (let j = 0; j < boundingBoxs.length; j++) {
                if (
                  boundingBoxs[j].getAttribute('SRS')?.toUpperCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('srs')?.toLowerCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('CRS')?.toLowerCase() === 'EPSG:4326' ||
                  boundingBoxs[j].getAttribute('crs')?.toLowerCase() === 'EPSG:4326'
                ) {
                  x1 = +boundingBoxs[j].getAttribute('minx')
                  x2 = +boundingBoxs[j].getAttribute('maxx')
                  y1 = +boundingBoxs[j].getAttribute('miny')
                  y2 = +boundingBoxs[j].getAttribute('maxy')
                  box = { x1, y1, x2, y2 }
                }
              }
            }

            // get SRS name
            let srsName = 'EPSG:4326'
            if (xmlData[i].getElementsByTagName('DefaultCRS') && xmlData[i].getElementsByTagName('DefaultCRS').length) {
              srsName = xmlData[i].getElementsByTagName('DefaultCRS')[0].innerHTML
            } else if (xmlData[i].getElementsByTagName('wfs:DefaultCRS') && xmlData[i].getElementsByTagName('wfs:DefaultCRS').length) {
              srsName = xmlData[i].getElementsByTagName('wfs:DefaultCRS')[0].innerHTML
            } else if (xmlData[i].getElementsByTagName('SRS') && xmlData[i].getElementsByTagName('SRS').length) {
              srsName = xmlData[i].getElementsByTagName('SRS')[0].innerHTML
            } else if (xmlData[i].getElementsByTagName('DefaultSRS') && xmlData[i].getElementsByTagName('DefaultSRS').length) {
              srsName = xmlData[i].getElementsByTagName('DefaultSRS')[0].innerHTML
            } else if (xmlData[i].getElementsByTagName('OtherSRS') && xmlData[i].getElementsByTagName('OtherSRS').length) {
              srsName = xmlData[i].getElementsByTagName('OtherSRS')[0].innerHTML
            }

            try {
              //if (!validDegreeBox(box)) throw box; // because bbox maybe lat, long, height or xyz
              if (!x1 || !y1 || !x2 || !y2) return
              form.setFieldsValue({ bbox: box });
              setEpsg(Number(srsName.match(/\d+$/)[0]));

              //check valid wfs outputformat              
              let rescheck = await checkValidGeoURL({ src: form.getFieldValue('src'), outputFormat: ['application/json', version === '1.0.0' ? 'GML2' : version === '2.0.0' ? 'text/xml; subtype=gml/3.2.1' : 'GML3'], layer: form.getFieldValue('layers'), bbox: form.getFieldValue('bbox'), username: form.getFieldValue('username'), password: form.getFieldValue('password'), version: version })
              if (rescheck.status !== 200) {
                if (rescheck.status === 401) {
                  setUseCredentialization(true)
                } else {
                  form.setFieldsValue({ layers: undefined });
                  form.setFieldsValue({ bbox: { x1: undefined, x2: undefined, y1: undefined, y2: undefined } });
                }
                notification.open({
                  message: t('error'),
                  description: t(rescheck.message),
                  icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />
                })
              }
            } catch (error) {
              box = undefined
              notification.open({
                message: t('wfs-server-error'),
                description: t('request-wfs-not-supported'),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 5,
              })
            }
            break;
          }
        }
      }
    }

    const gml2geojson = (gml, version) => {
      var features
      if (version === '1.0.0') {
        features = new GML2().readFeatures(gml);
      } else if (version === '2.0.0') {
        features = new GML32().readFeatures(gml);
      } else {
        features = new GML3().readFeatures(gml);
      }
      var geoJsonStr = new GeoJSON().writeFeatures(features);
      return JSON.parse(geoJsonStr)
    }

    /**
     * check valid GetFeature
     * @param {*} data { src: src, outputFormat: outputFormat[], layer: layer, bbox: bbox, username, password, version }
     * @returns {status, data, message} //status: 200, 401, 400, 404
     */
    const checkValidGeoURL = async (data) => {
      let { src, outputFormat, layer, bbox, username, password, version } = data
      let _bbox = [bbox.x1, bbox.y1, bbox.x2, bbox.y2].join(',')
      let resl
      for (const _outputformat of outputFormat) {
        setOutputFormat(_outputformat)
        let requestOption = {
          method: 'get',
          url: src,
          params: {
            service: 'WFS',
            request: 'GetFeature',
            outputFormat: _outputformat,
            typeName: layer,
            bbox: _bbox,
            maxFeatures: 1
          }
        }
        if (version) {
          requestOption.params.version = version
        }
        if (_outputformat === 'text/xml; subtype=gml/3.2.1') {
          requestOption.params.bbox = requestOption.params.bbox + ',EPSG:4326'
          requestOption.params.srsName = 'EPSG:4326'
          requestOption.params.version = '1.1.0'
        }
        if (username && password && isUseCredential) {
          const Authorization = btoa(`${username.trim()}:${password}`)
          requestOption.headers = {
            'Authorization': `Basic ${Authorization}`,
            'Access-Control-Allow-Origin': '*',
          }
        }
        projectStore.setLoadingProgress(true)
        let res = await axios(requestOption).then((_res) => {
          return { status: 200, data: _res.data, message: 'success' }
        }).catch(e => {
          if (e?.status === 401 || e?.status === 403) {
            let message = 'data-needs-authentication.'
            if (username && password) {
              message = 'authentication-failed'
            }
            return { status: 401, data: undefined, message: message }
          }

          let message = 'layer-is-not-valid';
          if (e?.data) {
            message = Utils.strip_html_tags(e.data)
          }

          return { status: 400, data: undefined, message: message }
        }).finally(() => {
          projectStore.setLoadingProgress(false)
        })
        if (res && res.status === 200 && res.data) {
          try {
            let wfs_data
            if (['GML2', 'GML3', 'text/xml; subtype=gml/3.2.1'].includes(_outputformat)) {
              wfs_data = gml2geojson(res.data, version)
            } else {
              let features = new GeoJSON().readFeatures(res.data)
              wfs_data = new GeoJSON().writeFeatures(features);
            }
            res.data = wfs_data
            resl = res
            break; //exit loop
          } catch (error) {
            res.message = 'Requested outputFormat not supported'
            res.status = 404
          }
        } else {
          resl = res
          if (res?.status === 401) {
            break; //exit loop
          }
        }
      }

      return resl
    }

    const handleSubmit = async () => {
      form.validateFields().then(async res => {
        projectStore.setLoadingProgress(true)
        let crs = {}
        if (capturesStore.cameraData) {
          crs.position = {
            x: capturesStore.cameraData.position.x,
            y: capturesStore.cameraData.position.y,
            z: capturesStore.cameraData.position.z,
          }
          crs.code = '0'
        }

        if (!res.bbox || !res.bbox.x1 || !res.layers) {
          return
        }
        let newModel = {
          name: res.name ? res.name : 'Unnamed',
          hash: '',
          data: {
            layers: res.layers
          },
          project: projectStore.projectDetail._id,
          sourceType: 'WFS',
          crs,
          src: res.src,
          selectedKey: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null
        }
        newModel.data.outputFormat = outputFormat
        newModel.data.bbox = [res.bbox.x1, res.bbox.y1, res.bbox.x2, res.bbox.y2]
        newModel.data.epsg = +epsg;

        // need convert to 4326
        if (!validDegreeBox(res.bbox)) {
          const _bbox = []
          let _convertPLToWGS841 = await projectStore.convertProjectPlaneToWGS84(res.bbox.y1, res.bbox.x1, 0, epsg, 'None')
          if (_convertPLToWGS841.Status === 'OK') {
            _bbox.push(_convertPLToWGS841.Point[1])
            _bbox.push(_convertPLToWGS841.Point[0])
          }
          let _convertPLToWGS842 = await projectStore.convertProjectPlaneToWGS84(res.bbox.y2, res.bbox.x2, 0, epsg, 'None')
          if (_convertPLToWGS842.Status === 'OK') {
            _bbox.push(_convertPLToWGS842.Point[1])
            _bbox.push(_convertPLToWGS842.Point[0])
          }
          if (_bbox.length === 4) {
            newModel.data.projectPlaneToWGS84 = { bbox: _bbox, epsg: 4326 }
          }
        }

        newModel.data.clampToGround = res.clampToGround;
        newModel.data.style = {
          point: {
            isShowPointAs: res.isShowPointAs, //Billboard; Point
            color: res.pointColor,
            alpha: res.pointAlpha,
            pixelSize: res.pointPixelSize ?? 5,
            outlineColor: res.pointOutlineColor ? res.pointOutlineColor : '#000000',
            outlineWidth: res.pointOutlineWidth ?? 0,
          },
          line: {
            width: res.lineWidth ?? 2,
            color: res.lineMaterial,
            alpha: res.lineAlpha,
          },
          polygon: {
            color: res.polygonMaterial,
            alpha: res.polygonAlpha,
            height: res.polygonHeight,
            outlineColor: res.polygonOutlineColor,
            extrudedHeight: res.polygonExtrudedHeight,
          }
        }
        newModel.data.version = version ? version : '';
        newModel.data.isUseCredential = isUseCredential !== undefined ? isUseCredential : false;
        newModel.data.username = isUseCredential && res.username ? res.username : '';
        newModel.data.password = isUseCredential && res.password ? res.password : '';

        if (isUseCredential) {
          let resp = await checkValidGeoURL({ src: res.src, outputFormat: [outputFormat], layer: res.layers, bbox: res.bbox, username: res.username, password: res.password, version: res.version })
          if (!resp || resp?.status !== 200) {
            notification.open({
              message: t('error'),
              description: t(resp.message),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />
            })
            return
          }
        }
        await createModel(newModel);
        projectStore.setLoadingProgress(false)
      }).catch(error => {
        console.log(error)
        projectStore.setLoadingProgress(false)
      })
    }

    const createModel = async (newModel) => {
      await projectStore.createModel(newModel).then(async (model) => {
        // Update Project tilesetdata and Model data return current model
        let _currentmodel = await updateProjectModel(model.project, model)

        // update project TreeData
        let _treeData = await updateProjectTreeData(model.project._id, model, projectStore.projectDetail.treeData)
        _currentmodel.project.treeData = _treeData

        /**Update FE projectStore.ProjectDetail */
        await projectStore.updateProjectRefPoint(_currentmodel.project)

        projectStore.setCurrentModelId(false)
        projectStore.setNewModelId(_currentmodel._id)

        projectStore.setSelectedModel(_currentmodel)
        projectStore.setZoomToModel(_currentmodel._id)
      }).then(() => {
        onCancel()
        message.success(t('data-added-to-project'), 5)
      }).catch(err => {
        console.log('err', err)
        notification.open({
          message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
          description: t('something-went-wrong-when-creating-model'),
          icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          duration: 0,
        })
      })
    }

    const handleChangePointStyle = (value) => {
      setPointStyle(value)
    };

    return (
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('please-input-name'),
            },
          ]}>
          <Input placeholder={t('name')} />
        </Form.Item>
        <Form.Item
          label={t('server-url')}
          name="src"
          rules={[
            {
              required: true,
              message: t('please-input-url'),
            },
          ]}>
          <Input onChange={(e) => { subStringURL(e.target.value) }} placeholder="URL" />
        </Form.Item>
        <Form.Item
          label={(
            <>
              {t('layer')}
              {loadingXml && <Spin indicator={loadingIcon} />}
            </>
          )}
          name="layers"
          rules={[
            {
              required: true,
              message: t('please-select-layers'),
            },
          ]}>
          <Select allowClear showSearch placeholder={t('please-select')} onChange={(e) => { onChangeLayer(e) }}>
            {loadingXml ?
              (<Option><Spin tip={t('loading-layers')} spinning={true} /></Option>) :
              (
                layerData.map(layer => (
                  <Option key={layer} value={layer}>{layer}</Option>
                ))
              )
            }
          </Select>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('username')}
              autoComplete="off"
              name="username"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-username'),
                },
              ]}
            >
              <Input autoComplete="username" placeholder={t('username')} />
            </Form.Item>
          </Col>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t('password')}
              autoComplete="off"
              name="password"
              rules={[
                {
                  required: isUseCredential,
                  message: t('please-input-your-password'),
                },
              ]}
            >
              <Input.Password autoComplete="new-password" placeholder={t('password')} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={`${t('coordinates')} ${epsg ? `(${epsg})` : ''}`}>
          <Form.Item
            name={['bbox', 'x1']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="x1" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'y1']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="y1" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'x2']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="x2" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name={['bbox', 'y2']}
            style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginLeft: '5px', marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: t('please-set-coordinates')
              },
            ]}>
            <InputNumber placeholder="y2" style={{ width: '100%' }} />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label={t('clamp-to-ground')}
          name="clampToGround"
          valuePropName="checked"
          className="form-horizontal">
          <Switch
            checkedChildren={t('commons.on')}
            unCheckedChildren={t('commons.off')}
            style={{ marginLeft: '5px' }}
          />
        </Form.Item>
        <Divider orientation="left" orientationMargin="0">
          {t('point-style')}
        </Divider>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('point-style')}
                className="form-horizontal"
                name="isShowPointAs"
                initialValue={'Billboard'}
              >
                <Select
                  placeholder={t('select-filter')}
                  style={{ width: '250px' }}
                  onChange={handleChangePointStyle}
                >
                  <Option value="Billboard">{t('shows-points-as-billboards')}</Option>
                  <Option value="Point">{t('shows-points-as-point-graphics')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('color')}
                className="form-horizontal"
                name="pointColor"
                initialValue={'#ffffff'}
              >
                <input type="color" className='custom-input-color' />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              {pointStyle === 'Point' ?
                (<Form.Item
                  label={t('outline-color')}
                  className="form-horizontal"
                  initialValue={'#ffffff'}
                  name="pointOutlineColor">
                  <input type="color" className='custom-input-color' />
                </Form.Item>) : ''
              }
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('transparency')}
                name="pointAlpha">
                <Slider
                  style={{ marginRight: '7px' }}
                  min={0.0}
                  max={1.0}
                  step={0.1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={`${t('size')} (px)`}
                className="form-horizontal"
                name="pointPixelSize">
                <InputNumber min={1} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              {pointStyle === 'Point' ?
                (<Form.Item
                  label={t('outline-width')}
                  className="form-horizontal"
                  name="pointOutlineWidth">
                  <InputNumber min={0} step={1} />
                </Form.Item>) : ''
              }
            </Col>
          </Row>
        </Form.Item>
        <Divider orientation="left" orientationMargin="0">
          {t('line-style')}
        </Divider>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('color')}
                className="form-horizontal"
                initialValue={'#ffffff'}
                name="lineMaterial">
                <input type="color" className='custom-input-color' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('transparency')}
                className="form-horizontal"
                name="lineAlpha">
                <Slider
                  style={{ marginRight: '7px' }}
                  min={0}
                  max={1.0}
                  step={0.1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={`${t('width')} (px)`}
                className="form-horizontal"
                name="lineWidth">
                <InputNumber min={1} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Divider orientation="left" orientationMargin="0">
          {t('polygon-style')}
        </Divider>
        <Form.Item>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('color')}
                className="form-horizontal"
                initialValue={'#ffffff'}
                name="polygonMaterial">
                <input type="color" className='custom-input-color' />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('outline-color')}
                className="form-horizontal"
                initialValue={'#ffffff'}
                name="polygonOutlineColor">
                <input type="color" className='custom-input-color' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                label={t('transparency')}
                className="form-horizontal"
                name="polygonAlpha">
                <Slider
                  style={{ marginRight: '7px' }}
                  min={0.0}
                  max={1.0}
                  step={0.1}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('elevation')}
                className="form-horizontal"
                name="polygonHeight">
                <InputNumber min={0.0} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={t('extrusion')}
                className="form-horizontal"
                name="polygonExtrudedHeight">
                <InputNumber min={0} step={1} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row justify='end'>
            <Button type="default" onClick={onCancel} style={{ marginRight: 10 }}>{t('commons.cancel')}</Button>
            <Button type="primary" htmlType="submit">
              {t('add-resource')}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    )
  }

  const PointsceneForm = () => {
    const [uploadStatus, setUploadStatus] = useState(false)
    const [validFiles, setValidFiles] = useState();

    const dummyRequest = async ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    }

    const fileProps = {
      name: 'file',
      multiple: true,
      showUploadList: false,
      accept: ".laz,.las, .LAZ, .LAS",
      customRequest: dummyRequest,
      onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
          let _progressInfos = Array.from(info.fileList).reduce(function (filtered, file) {
            if (file.uid && file.status === 'done' && !progressInfosRef.current.val.some(el => el.file.uid === file.uid)) {
              filtered.push(file);
            }
            return filtered;
          }, []);
          if (_progressInfos.length === info.fileList.length) {
            setValidFiles([...info.fileList]);
          }
        } else if (status === 'error') {
          message.error(`${info.file.name} ${t('file-upload-failed')}`);
        }
      }
    };

    useEffect(() => {
      if (validFiles && validFiles.length > 0) {
        const outputFileType = [];
        for (var i = 0, f; f = validFiles[i]; i++) {
          let fileType = f.name.slice(f.name.length - 4)
          fileType = fileType ? fileType.toLowerCase() : ''
          outputFileType.push(fileType)
        }

        setUploadStatus(true)
      }
    }, [validFiles])

    useEffect(() => {
      if (validFiles && validFiles.length > 0 && uploadStatus) {
        let _progressInfos = Array.from(validFiles).map(file => {
          return { file: file.originFileObj, percentage: 0, isComplete: false, isCancel: false, isFailed: false, isUnsupported: (file.invalid === true) }
        });

        progressInfosRef.current = { val: [...progressInfosRef.current.val, ..._progressInfos] }
        // set state progressInfos
        setProgressInfos({
          val: [
            ...progressInfos.val,
            ..._progressInfos //add new array file upload
          ]
        })

        // filter get only files valid
        let filterValidFile = Array.from(validFiles).map(file => {
          // add property _id for file, used to check if the file has been dragged and check the position of the file in the array
          file.originFileObj._id = uuid();
          return file.originFileObj;
        }).filter(item => {
          return !item.invalid
        });

        // call function generate presignedurl from s3
        generatePreSigned(filterValidFile)

        setUploadStatus(false)
        setValidFiles()
      }
    }, [uploadStatus])

    const generatePreSigned = async (files) => {
      let fileUploadSize = 0;
      for (var i = 0; i < files.length; i++) {
        // calculate total size of all files        
        fileUploadSize += parseFloat(files[i].size) / 1024 / 1024;
      }

      if (fileUploadSize > Number.MAX_VALUE) {
        clearData()
        message.error(t('file-size-is-too-large'))
        projectStore.setLoadingProgress(false)
        return;
      }

      // check linces and totalQuota remaining        
      if (projectStore.projectDetail.organization && projectStore.projectDetail.organization.licenses && projectStore.projectDetail.organization.licenses.length > 0) {
        const licenseInfos = projectStore.getCurrentProjectStorageQuota(projectStore.projectDetail.organization)
        if (licenseInfos.totalQuota === 0) {
          clearData()
          message.error(t('your-license-is-expried-please-update-your-license'))
          projectStore.setLoadingProgress(false)
          return;
        }
      } else {
        clearData()
        message.error(t('you-do-not-have-license-please-update-license'))
        projectStore.setLoadingProgress(false)
        return;
      }

      // check authentication pointscene      
      if (!Utils.checkPointsceneToken(pointsceneStore.pointsceneJWT)) {
        let resultPointscene = await pointsceneStore.pointsceneAuthentication()
        if (resultPointscene.status === "OK") {
          resultPointscene.data.expires_at = new Date().getTime() + (1000 * resultPointscene.data.expires_in)
          pointsceneStore.setPointsceneJWT(resultPointscene.data)
        } else {
          clearData()
          message.error(t(resultPointscene.message))
          projectStore.setLoadingProgress(false)
          return;
        }
      }

      // promise get presigned url from s3 return {file: filename, url: url signed, fileData: content file}
      const presignedS3Urls = await Promise.all(Array.from(files).map(async file => {
        let result = await projectStore.generatePreSignedPutUrl({ fileName: convertExtToLowerCase(file.name), projectId: projectStore.projectDetail._id })
        return {
          url: result.url,
          fileName: result.file,
          fileData: file,
          selectedNode: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null
        }
      }))

      projectStore.setLoadingProgress(false)
      if (fileStore.filesReadyProcess.length > 0) {
        await waitUntilPromiseFinish() // for case drop files when upload still in process
      }
      fileStore.setFilesReadyProcess([presignedS3Urls])
      let _sortpresigned = presignedS3Urls.sort((a, b) => parseFloat(a.fileData.size) - parseFloat(b.fileData.size));
      const promisesUploadS3 = _sortpresigned.map((filePresigned) => uploadToS3(filePresigned));
      await PromiseBlue.each(promisesUploadS3, async (item) => {
        if (item) {
          await processModel(item.idx, item.model)
        }
      }).then(function (result) {
        // check all files processed then close dialog upload
        let filterprocessed = progressInfosRef.current.val.filter(x => x.isComplete === true || (x.isFailed === true))
        if (progressInfosRef.current.val.length === filterprocessed.length) {
          clearData()
        }
      }).catch(function (rejection) {
        console.log("Catch: " + rejection);
      });
      fileStore.setFilesReadyProcess([])
    }

    async function waitUntilPromiseFinish() {
      return await new Promise(resolve => {
        const interval = setInterval(() => {
          if (fileStore.filesReadyProcess.length === 0) {
            resolve();
            clearInterval(interval);
          };
        }, 5000);
      });
    }

    /**
    * upload put file to s3 from presignedurl
    * @param {*} idx position file in array progressInfosRef.current.val
    * @param {*} presignedS3Url {file: filename, url: url signed, fileData: content file}
    */
    const uploadToS3 = async (presignedS3Url) => {
      // get state array files [{file: File, percentage: 0, isComplete: false, isCancel: false, isFailed: false, isUnsupport:},{...}]        
      let idx = progressInfosRef.current.val.findIndex(x => x.file._id === presignedS3Url.fileData._id);
      if (idx > -1) {
        progressInfosRef.current.val[idx].url = presignedS3Url.url;
        progressInfosRef.current.val[idx].fileName = presignedS3Url.fileName;
        progressInfosRef.current.val[idx].isCancel = false; // need init for case refresh upload
        progressInfosRef.current.val[idx].isFailed = false;

        var config = {
          method: 'put',
          url: presignedS3Url.url,
          headers: {
            'Content-Type': getMimeOfFile(presignedS3Url.fileData.name),
            'Access-Control-Allow-Origin': '*'
          },
          data: presignedS3Url.fileData,
          onUploadProgress: event => {
            progressInfosRef.current.val[idx].percentage = Math.round(
              (100 * event.loaded) / event.total
            );
            setProgressInfos({ val: progressInfosRef.current.val });
          },
          cancelToken: new CancelToken(function executor(c) {
            setCancelsRequest(prev => [...prev, c]);  //set to array cancel token
          })
        };

        let file = presignedS3Url.fileData;
        let ext = presignedS3Url.fileName.split('.').pop()
        file.hash = presignedS3Url.fileName.split('.').shift()
        file.ext = `.${ext}`
        file.url = `${ASSET_URL}${presignedS3Url.fileName}`
        file.id = `${ASSET_URL}${presignedS3Url.fileName}`

        //put presignedurl
        return await axios(config)
          .then(async () => {
            // after put completed file uploaded s3 then call createWorkflow
            let _data = {
              projectId: projectStore.projectDetail._id,
              token: pointsceneStore.pointsceneJWT.access_token,
              name: file.name ? file.name : 'Unnamed',
              pointclound_url: file.url,
              labels_name: `3d-tiles-${uuid()}`
            }

            let resPointScene = await pointsceneStore.createWorkflow(_data)
            if (resPointScene.status === "OK") {
              let run_name = uuid()
              let _hash = `${projectStore.projectDetail._id}/${run_name}`
              let s3_url = null
              let pointscene_url = `${resPointScene.data.stream}/tileset.json`

              let paramzip = {
                token: pointsceneStore.pointsceneJWT.access_token,
                resourceId: resPointScene.data.stream.split('/').pop(),
                fileNameZipFile: `${run_name}.zip`,
                resource_name: `zip-3d-tiles-${run_name}`
              }

              let resZipResource = await pointsceneStore.createWorkflowZipResource(paramzip)
              if (resZipResource.status === "OK") {
                let resUploandUnzipToS3 = await pointsceneStore.uploadFromUrlToS3({ url: resZipResource.data.signedUrl, projectId: projectStore.projectDetail._id, filename: run_name })
                if (resUploandUnzipToS3 && resUploandUnzipToS3.Location) {
                  s3_url = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${_hash}/tmp/resource/tileset.json`
                }
              }
              //save create model 
              return await createModelPointscene(idx, file, presignedS3Url.selectedNode, pointscene_url, s3_url, _hash)
            } else {
              progressInfosRef.current.val[idx].isFailed = true;
              progressInfosRef.current.val[idx].percentage = 0; // reset percentage
              setProgressInfos({ val: progressInfosRef.current.val });
            }
          })
          .catch(error => {
            if (progressInfosRef.current.val.length > 0) {
              if (axios.isCancel(error) || error === undefined) {
                progressInfosRef.current.val[idx].isCancel = true;
              } else {
                progressInfosRef.current.val[idx].isFailed = true;
              }
              progressInfosRef.current.val[idx].percentage = 0;
              setProgressInfos({ val: progressInfosRef.current.val });
            }
          })
      }
    };

    /**
     * Create model and synce to Pointscene
     * @param {*} idx position file in array progressInfosRef.current.val
     * @param {*} file 
     * @param {*} selectedNode Folder selected tree node
     * @param {*} pointscene_url url from pointscene
     * @param {*} s3_url url from s3
     * @param {*} hash 
     * @returns 
     */
    const createModelPointscene = async (idx, file, selectedNode, pointscene_url, s3_url, hash) => {
      // get progressInfosRef.current.val for upldate state file success or error
      let crs = {}
      if (capturesStore.cameraData) {
        crs.position = {
          x: capturesStore.cameraData.position.x,
          y: capturesStore.cameraData.position.y,
          z: capturesStore.cameraData.position.z,
        }
        crs.code = '0'
      }
      let newModel = {
        name: file.name ? file.name : 'Unnamed',
        src: s3_url ? s3_url : pointscene_url,
        hash: hash,
        sourceType: 'external',
        data: {
          pointscene_url: pointscene_url,
          id: file.id,
          size: file.size,
          detailLevel: 16,
          is3DTilesPointcloud: true
        },
        project: projectStore.projectDetail._id,
        crs,
        selectedKey: selectedNode
      }

      var pCrs = projectStore?.projectDetail?.tilesetData?.coordinateSystem ? projectStore?.projectDetail?.tilesetData?.coordinateSystem : '4326'
      var refPoint = projectStore?.projectDetail?.tilesetData?.RefPoint

      if (pCrs)
        newModel.data.refData = {
          epsg: pCrs.code,
          heightSystem: projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
        }

      // we will use ifcSetting as tiling setting for both landxml and ifc        
      if (newModel.data.ext && ['.ifc', '.xml', '.las', '.laz'].includes(newModel.data.ext.toLowerCase())) {
        newModel.data.ifcSetting = projectSettingStore.systemProjectSetting.ifcSetting
      }

      // license type convert IFCEngineUsage
      if (projectStore.projectDetail.organization) {
        newModel.org = projectStore.projectDetail.organization.id
      }

      return await projectStore
        .createModel(newModel)
        .then(async (model) => {
          if (model.crs['Use IFCEngine'] !== undefined && model.crs['Use IFCEngine'] === 'True') {
            await create_ifcengineusage(projectStore.projectDetail.organization._id, projectStore.projectDetail._id, model, usersStore.currentUser._id)
          }

          // update organization quota usage
          await projectStore.updateProjectStorage(projectStore.projectDetail._id)
          model.ref = false; //for case check load model (if true not reload)
          return { idx: idx, model: model }
        }).catch(err => {
          // update state progressInfos isFailed           
          progressInfosRef.current.val[idx].isFailed = true;
          progressInfosRef.current.val[idx].percentage = 0; //reset percentage
          setProgressInfos({ val: progressInfosRef.current.val });

          if (err && err.data && err.data.message) {
            notification.open({
              message: t('an-error-occurred-when-creating-model-data'),
              description: (
                <>
                  `${t('file')}`: <b>{newModel.name}</b><br />
                  {t(err.data.message)}
                </>
              ),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          } else {
            notification.open({
              message: t('an-error-occurred-when-creating-model-data'),
              description: (
                <>
                  `${t('file')}`: <b>{newModel.name}</b><br />
                  {t('something-went-wrong-when-creating-model')}
                </>
              ),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            })
          }
        });
    };

    const processModel = async (idx, model) => {
      // Update Project tilesetdata and Model data return current model
      let rsp_model = await updateProjectModel(model.project, model)
      let _project = rsp_model.project

      // merge All model
      if (_project.tilesetData && _project.tilesetData.RefPoint && _project.tilesetData.coordinateSystem && (!['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'metre')) {
        let _model3ds = _project.model3DS.map(c => {
          let u = { ...c }
          if (c.id === model.id) {
            u.newUpdate = true
          }
          return u;
        })
        let modelmerge = await mergeAllModels(
          _model3ds,
          Cartesian3.fromDegrees(_project.tilesetData.RefPoint[1], _project.tilesetData.RefPoint[0], _project.tilesetData.RefPoint[2]),
          _project.tilesetData.refLocalProject ? Cartesian3.fromArray(_project.tilesetData.refLocalProject) : false,
          _project.tilesetData.georeferenced !== undefined ? _project.tilesetData.georeferenced : false,
          _project.headingRotation,
          _project.tilesetData.coordinateSystem.code,
          _project.elevationSystem ? _project.elevationSystem : 'None'
        );
        _project.model3DS = modelmerge // reassign list model to project
      }

      // update project TreeData
      let _treeData = await updateProjectTreeData(_project._id, model, projectStore.projectDetail.treeData)
      _project.treeData = _treeData

      /**Update FE projectStore.ProjectDetail */
      await projectStore.updateProjectRefPoint(_project)

      // effect projectStore.modelList only load model no model.ref => incorrect
      // after merge all models => position of model change => need set setNewModelId to effect updatePlaneMatix
      projectStore.setCurrentModelId(false)
      projectStore.setNewModelId(model._id)

      // update status file uploaded
      progressInfosRef.current.val[idx].isComplete = true
      setProgressInfos({ val: progressInfosRef.current.val });
      if (model?.type === 'cloudpoint' && model?.status === "waitting_process") {
        message.success(t('data-process-background'));
      }
    }

    const clearData = () => {
      progressInfosRef.current.val = []; // clear progressInfosRef
      setProgressInfos({ val: [] }); // clear progressInfos     
      projectStore.setLoadingProgress(false)
      projectStore.setDisplayPanel(false)
      projectStore.setCheckModalStatus(false)
      projectStore.setShowProcessInBackground(false)
      projectStore.setShowAddResourceModel(false)
      projectStore.setSelectedStatus(true)
      cancelsRequest.forEach((c) => c()) // cancel all requests if it is still available
      setCancelsRequest([]) //clear cancel request
      fileStore.setFilesReadyProcess([])
    }

    return (
      <>
        <UploadWrapper>
          <Dragger {...fileProps} style={{ maxHeight: "180px" }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {t('click-or-drag-file-to-this-area-to-upload')}
            </p>
            <p className="ant-upload-hint">
              {t('file-support')}: .laz,.las
            </p>
          </Dragger>
          <ProgressBodyStype>
            <div style={{ height: '100%' }}>
              {progressInfos && progressInfos.val.length > 0 &&
                progressInfos.val.map((progressInfo, index) => (
                  <div className='row-body' key={index} >
                    <div className="row-center row-distribute">
                      <div className="flex-row row-distribute flex-1">
                        <div className="flex-1 text-ellipsis">
                          <p className="text-ellipsis">{progressInfo.file.name}</p>
                          {
                            progressInfo.isCancel ? (
                              <div className="small text-ellipsis"><span className="text-bold">{t('canceled')}</span></div>
                            ) : progressInfo.percentage === 100 && !progressInfo.isComplete ? (
                              <div className="small text-ellipsis"><span className="text-bold">{t('syncing')}</span></div>
                            ) : progressInfo.isFailed ? (
                              <div className="small text-ellipsis"><span className="text-bold">{t('failed')}</span></div>
                            ) : progressInfo.isUnsupported ? (
                              <div className="small text-ellipsis"><span className="text-bold">{t('unsupported-format')}</span></div>
                            ) : null
                          }
                        </div>
                        <div className="text-muted">{bytesToSize(progressInfo.file.size)}</div>
                      </div>
                      {
                        progressInfo.isFailed ? (
                          <div className="flex-row"><InfoCircleOutlined style={{ minWidth: '40px', fontSize: '20px', color: '#c81922' }} /></div>
                        ) : progressInfo.isUnsupported ? (
                          <div className="flex-row"><InfoCircleOutlined style={{ minWidth: '40px', fontSize: '20px', color: '#c81922' }} /></div>
                        ) : progressInfo.percentage === 100 && progressInfo.isComplete ? (
                          <div className="flex-row"><span style={{ minWidth: '40px', textAlign: 'center' }}><SVGIcon content={<CloudDone />} width={22} height={22} color={'#72a544'} /></span></div>
                        ) : progressInfo.percentage === 100 && !progressInfo.isComplete ? (
                          <div className="flex-row"><SyncOutlined style={{ minWidth: '40px', fontSize: '18px', color: '#F26524' }} spin /></div>
                        ) : null
                      }
                    </div>
                    {progressInfo.percentage === 100 || progressInfo.isCancel || progressInfo.isFailed || progressInfo.isUnsupported ? '' : <Progress trailColor={'#d9d9d9'} percent={progressInfo.percentage} showInfo={false} />}
                  </div>
                ))
              }
            </div>
          </ProgressBodyStype>
          {
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleHideModalAddResources}>{t('process-in-background')}</Button>
            </div>
          }
        </UploadWrapper>
      </>
    )
  }

  return (
    <>
      <Modal
        width={777}
        centered={true}
        onCancel={onCancel}
        visible={projectStore.showAddResourceModel && projectStore.isExistLicenses && !fileStore.modalDrop3DView}
        title={
          <div className="help-btn-wrapper">
            {t('add-resources')}
            <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <div>
                <HelpButton helppage={'add_resources'} hovered={'black'} />
              </div>
            </Tooltip>
          </div>
        }
        icon={<BuildOutlined />}
        iconType="build"
        zIndex={10000}
        footer={false}
        maskClosable={false}
      >
        <Tabs activeKey={projectStore.currentAddTab} onChange={onChangeTab}>
          <TabPane
            tab={
              <span>
                {t('file')}
              </span>
            }
            key="uploadTab"
          >
            <DragDropFileForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                {t('library')}
              </span>
            }
            key="preDefineAccess"
          >
            <PreDefineAccessForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                WMS
              </span>
            }
            key="WMS"
          >
            <WMSForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                WFS
              </span>
            }
            key="WFS"
          >
            <WFSForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                {t('Tiles')}
              </span>
            }
            key="externalLink"
          >
            <ExternalForm />
          </TabPane>
          <TabPane
            tab={
              <span>
                {t('3d-city-db')}
              </span>
            }
            key="3DCityDB"
          >
            <Form3DCityDB />
          </TabPane>
          <TabPane
            tab={
              <span >
                {t('link')}
              </span>
            }
            key="ProjectLink"
          >
            <AddProjectLink onCancel={onCancel} />
          </TabPane>
          <TabPane
            tab={
              <span>
                Cesium Ion
              </span>
            }
            key="cesiumIon"
          >
            <CesiumIonForm />
          </TabPane>

          {/* <TabPane
            tab={
              <span>
                Pointscene
              </span>
            }
            key="Pointscene"
          >
            <PointsceneForm />
          </TabPane> */}
        </Tabs>
      </Modal>
    </>
  )

}

export default withRouter(inject('projectStore', 'capturesStore', 'commonStore', 'adminStore', 'projectSettingStore', 'fileStore', 'pointsceneStore', 'usersStore', 'objectQueryStore')(observer(ModalAddResources)))