import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { List, Tag } from 'antd'
// import { TilesetItemAction } from '../CustomStyled'

const colorByType = {
  ifc: 'blue',
  cad: 'blue',
  landxml: 'orange',
  model3d: 'green',
  unknown: 'brown',
  cloudpoint: 'DeepSkyBlue',
  cloudpoint: 'e57',
  local: '#689BC6',
  external: '#83CA6A',
  ion: 'magenta',
}

const TilesetListPanel = ({ projectStore }) => {
  const clickTileset = model => {
    if (projectStore.showEditLocation) return
    projectStore.setSelectedModel(model)
  }

  // const clickEditLocation = model => {
  //   projectStore.setShowEditLocation(model, true)
  // }

  // const deleteTilesets = itemId => {
  //   projectStore.deleteTileset(itemId)
  // }

  return (
    <List
      itemLayout="horizontal"
      dataSource={projectStore.modelList}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            style={{ width: '100%' }}
            title={<a onClick={() => clickTileset(item)}>{item.name}</a>}
            description={
              <Fragment>
                <Tag color={colorByType[item.sourceType]}>
                  {item.sourceType}
                </Tag>
                <Tag color={colorByType[item.type]}>{item.type}</Tag>
                {item.useService ? (
                  <Tag color={colorByType[item.useService]}>
                    {item.useService}
                  </Tag>
                ) : (
                  ''
                )}
              </Fragment>
            }
          />
        </List.Item>
      )}
    />
  )
}

export default inject('projectStore')(observer(TilesetListPanel))
