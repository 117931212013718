import { Button, Form, message, Modal, Select ,Popconfirm  } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { List } from './style';
import { Trans, useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
const { Option } = Select

const ChangeRoleUserInProject = props => {
    const { t } = useTranslation();
    const { projectTeamsStore, projectStore, adminStore, userGroupStore ,usersStore } = props
    const [form] = Form.useForm();
    const roles = projectTeamsStore.projectRoles
    const { currentUserRoleDetailInProject } = adminStore
    const [currentGroupMember, setCurrentGroupMember] = useState()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (adminStore.showChangeRoleUserModal) {
            form.resetFields();
            setLoading(true)
            currentUserRoleDetailInProject?.user?._id && userGroupStore.getAllGroupByUserId(currentUserRoleDetailInProject?.user?._id).then(res =>{
                if(res?.length > 0){
                    res.forEach(el => {
                    if(el.group?._id && (el.group?.project === projectStore.projectDetail._id)){
                        form.setFieldsValue({group: el.group?._id})
                        setCurrentGroupMember(el)
                    }})
                }
                setLoading(false)
            }).catch(err => {
                console.log(err)
                setLoading(false)
            })
        }
    }, [adminStore.showChangeRoleUserModal])

    const closeChangeRoleModal = () =>{
        setLoading(false)
        setCurrentGroupMember()
        adminStore.setShowChangeRoleUserModal(false)
    }

    const checkLastManager = () => {
        let warning = false;
        const preRole = currentUserRoleDetailInProject?.projectrole;
        const userList = projectTeamsStore.teamList.filter(c => c.email !== "maintenance@xd-visuals.fi" && c?.projectrole?.role ==='project_owner')
        if(preRole?.role === 'project_owner'&& (!userList || userList?.length < 2)  ) {
            warning =  true;
        }
        return warning ;
    }

    const handleChangeRoleUser = () => {
        form.validateFields().then(async values => {
            let submitValue = { projectrole: values.role }
            if(values.group){
                currentGroupMember ? userGroupStore.updateProjectUserGroupMember(currentGroupMember._id, {group: values.group})
                : userGroupStore.createProjectUserGroupMember({
                    user: currentUserRoleDetailInProject.user._id,
                    email: currentUserRoleDetailInProject.email || '',
                    group: values.group,
                    project: projectStore.projectDetail._id
                })
            }
            projectStore.setLoadingProgress(true)            
            await adminStore.updateProjectUserRole(currentUserRoleDetailInProject._id, submitValue)
                .then(async res => {
                    await projectTeamsStore.getProjectTeams(projectStore.projectDetail.id)
                    message.success(t('change-user-role-successfully'))
                    projectStore.setLoadingProgress(false)
                    closeChangeRoleModal()
                    if (res.data) {
                      if (usersStore?.currentUser?.id === res.data?.user?.id) {
                        if (res?.data?.projectrole?.role) {
                          projectStore.setCurrentUserRoleInProject(
                            res?.data?.projectrole?.role
                          )
                        }
                      }
                    }
                }).catch(err => {
                    projectStore.setLoadingProgress(false)
                })
        })
    }

    return (
        <Modal
            className="modal-addUser"
            title={t('change-user-role-in-project')}
            onCancel={() => closeChangeRoleModal()}
            okText={'Send role'}
            cancelText={t('commons.cancel')}
            visible={adminStore.showChangeRoleUserModal}
            footer={
                <>
                    <Button key="back" onClick={() => adminStore.setShowChangeRoleUserModal(false)}>
                        {t('commons.cancel')}
                    </Button>
                    {
                        checkLastManager() ? (
                            <Popconfirm placement="top" title={t('confirm-update-last-user')} onConfirm={handleChangeRoleUser} okText={t('commons.yes')} cancelText={t('commons.no')}>
                                <Button key="submit" type="primary">
                                    {t('commons.save')}
                                </Button>
                            </Popconfirm>
                        ) : (
                            <Button key="submit" type="primary" onClick={e => handleChangeRoleUser()}>
                                {t('commons.save')}
                            </Button>
                        )
                    }
                </>
            }
            zIndex={9999}
        >
            <Form
                form={form}
                layout="vertical"
                id={'invite-member-form'}
            >
                <div style={{ padding: "20px 0" }} >
                    <List>
                        <dt>{t('username')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInProject.user && currentUserRoleDetailInProject.user.username ? currentUserRoleDetailInProject.user.username : t("user-not-found")
                            }</span>
                        </dd>
                        <dt>{t('user-email')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInProject.email ? currentUserRoleDetailInProject.email : " "
                            }</span>
                        </dd>
                        <dt>{t('current-role')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInProject.projectrole && currentUserRoleDetailInProject.projectrole.roleName ? currentUserRoleDetailInProject.projectrole.roleName : ""
                            }</span>
                        </dd>
                    </List>
                </div>
                <Form.Item
                    style={{marginBottom: 12}}
                    label={t('project-roles')}
                    name="role"
                    rules={[
                        {
                            required: true,
                            message: t('please-select-role'),
                        },
                    ]}
                    initialValue={currentUserRoleDetailInProject.projectrole ? currentUserRoleDetailInProject.projectrole._id : ''}
                >
                    <Select
                        placeholder={t('select-role')}>
                        {
                            Object.keys(roles).map((key) => (
                                <Option key={key} value={key}>{roles[key].roleName}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    style={{marginBottom: 0}}
                    label='Group'
                    name="group"
                >
                    <Select
                        placeholder='Select group'
                        loading={loading}
                        >
                        {
                            userGroupStore.groups.map((group) => (
                                <Option key={group._id} value={group._id}>{group.name}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default inject('projectTeamsStore', 'projectStore', 'adminStore','usersStore', 'userGroupStore')(observer(ChangeRoleUserInProject))