import React from 'react';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import { inject, observer } from 'mobx-react';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const ProjectCompleteness = ({ dashboardStore }) => {
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false
        },
        title: {
            text: null
        },
        tooltip: {
            enabled: false
        },
        pane: {
            center: ['50%', '85%'],
            size: '140%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: '#95a5a6',
                borderWidth: 0,
                shape: 'arc',
                innerRadius: '60%',
                outerRadius: '95%'
            }
        },
        yAxis: {
            stops: [
                [100 / 100, '#00ff00'],
                [0 / 100, '#FFDA83']
            ],
            min: 0,
            max: 100,
            minorTickLength: 0,
            lineWidth: 0,
            tickPixelInterval: 30,
            tickWidth: 1,
            tickPosition: 'inside',
            tickLength: 15,
            tickColor: '#95a5a6',
            tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            labels: {
                distance: 10
            }
        },
        plotOptions: {
            pie: {
                size: '100%',
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }
        },
        series: [{
            type: 'gauge',
            data: (function () {
                var data = [];
                let totalTopic = dashboardStore.topicListBelongProject
                let listTopicDone = totalTopic.filter(value => value.topicstatus === "Done")
                data.push({
                    y: listTopicDone.length > 0 ? (((listTopicDone.length / totalTopic.length) * 100).toFixed()) * 1 : 0
                })
                return data;
            }()),
            pivot: {
                radius: 0
            },
            dataLabels: {
                y: -5,
                borderWidth: 0,
                style: {
                    fontSize: '20px'
                },
                format: '{y} %'
            },
            dial: {
                radius: '0%',
                backgroundColor: 'red',
                borderWidth: 0,
                baseWidth: 3,
                topWidth: 3,
                baseLength: '100%', // of radius
                rearLength: '0%'
            }
        }, {
            type: 'solidgauge',
            fillColor: 'green',
            data: (function () {
                var data = [];
                let totalTopic = dashboardStore.topicListBelongProject
                let listTopicDone = totalTopic.filter(value => value.topicstatus === "Done")
                data.push({
                    y: listTopicDone.length > 0 ? ((listTopicDone.length / totalTopic.length).toFixed(2)) * 100 : 0
                })
                return data;
            }()),
            radius: '95%',

        }],
        credits: {
            enabled: false
        },
    }
    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
}
export default inject("dashboardStore")(observer(ProjectCompleteness));

