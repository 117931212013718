import styled from 'styled-components'

export const DrawerHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 35px;
  .anticon:hover {
    cursor: pointer;
  }
`
export const ProjectInfoTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 25px;
`
export const SocialItem = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
    > div {
      margin-right: 5px;
    }
  }
`

