import styled from 'styled-components';
import { Content } from 'antd';


export const TableWrapper = styled.div`
    margin : 16px 16px 0 ;
    background : #fff;
    overflow : initial;
    flex : auto;
    min-height : 0 ;
`

export const TableContentWrapper = styled.div`
    background : #fff;
    text-align : center ;
    width : 100%;
     .ant-table-tbody tr td {
    padding: 5px 16px;
}
    .ant-form-item {
    margin-bottom: 0;
}
`
export const TeamsPageWrapper = styled.div`
.splitpanel-left {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
}

.user-color.ant-switch-checked {
    background-color: #4CAF50;
}

.splitpanel-mobile-left {
    top: 0;
    right: 0; 
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-left: -15px;
    padding: 0 !important;
    }
}
`
export const HeaderFeatureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 30px;
    }
`