import { Button, Checkbox, Form, Input, message, Modal, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import validator from '../../../../validator'
import { Trans, useTranslation } from 'react-i18next';
import HelpButton from '../../../elements/HelpButton'
import { isMobile, isTablet } from 'react-device-detect'

const ModalAddProjectTemplate = ({ projectStore, organizationStore, usersStore }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)
  const AddForm = props => {
    useEffect(() => {
      if (projectStore.showAddProjectTemplateModal) {
        form.resetFields()
      }
      return () => {
        organizationStore.clearOrganizationTemplate()
        projectStore.setDataTemplateChecked({})
      }
    }, [])

    const isSystemTemplate = (e) => {
      var x = Object.assign(projectStore.dataTemplateChecked, { isSystemTemplate: e.target.checked })
      projectStore.setDataTemplateChecked(x)
    }

    return (
      <Form form={form} name="add-project" layout="vertical">
        <Form.Item label={t('name')} name="name" rules={[
          {
            required: true,
            message: t('please-input-the-title-of-project'),
          },
          { validator: validator.validateEmptyString },
        ]}>
          <Input
            placeholder={t('input-project-title')} />
        </Form.Item>
        {
          usersStore.systemUserRole.type === 'web_admin' && (
            <Checkbox onChange={isSystemTemplate}>{t('system-template')}</Checkbox>
          )
        }
      </Form>
    )
  }

  const onCancel = () => {
    projectStore.setShowAddProjectTemplateModal(false)
    projectStore.setProjectionDetail([])
  }

  const onCreate = () => {
    form.validateFields().then(values => {
      values.organization = projectStore.projectDetail.organization?.id
      values.project = projectStore.projectDetail.id
      values.isOrganizationTemplate = true
      values.sourceUpdate = 'add-project-template'
      let _value = Object.assign(values, projectStore.dataTemplateChecked)
      setIsLoading(true)
      // projectStore.createProjectTemplate(projectStore.projectDetail.id, _value)
      projectStore.duplicateProject(projectStore.projectDetail.id, _value)
        .then(res => {
          if (res?.error) {
            projectStore.setLoadingProgress(false)
            message.error(t(res?.error || 'an-error-occurred'))
            return
          }
          message.success(t('project-created-successfully'))
          form.resetFields()
          projectStore.setShowAddProjectTemplateModal(false)
        })
        .catch(err => {
          console.log(err)
          message.error(t('an-error-occurred'))
        })
        .finally(() => {
          setIsLoading(false)
          projectStore.setLoadingProgress(false)
        })
    });
  }

  return (
    <Modal
      visible={projectStore.showAddProjectTemplateModal && projectStore.isExistLicenses}
      title={
        <div className="help-btn-wrapper">
          {t('create-project-template')}
          <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
            <div>
              <HelpButton helppage={"create_project_template"} hovered={'black'}/>
            </div>
          </Tooltip>
        </div>
      }
      okText={t('commons.create')}
      cancelText={t('commons.cancel')}
      zIndex={9999}
      onCancel={onCancel}
      onOk={onCreate}
      maskClosable={false}
      footer={
        <div style={{ textAlign: 'right' }}  >
          <Button key="back" onClick={onCancel}>
            {t('commons.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={onCreate}>
            {t('commons.save')}
          </Button>
        </div>
      }>
      <AddForm />
    </Modal>
  )
}

export default withRouter(inject('projectStore', 'organizationStore', 'usersStore')(observer(ModalAddProjectTemplate)))
