import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SettingControlContainer } from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
import HelpButton from '../elements/HelpButton';

const AmbientOccSetting = ({ projectStore, projectSettingStore, usersStore, uiStore, viewer }) => {
  const { t } = useTranslation();
  const [AmbientOccParams, setAmbientOccParams] = useState(projectSettingStore.systemProjectSetting?.ambientOccSetting)
  const [SaveValue, setSaveValue] = useState(false)
  const { projectId } = useParams()
  const [form] = Form.useForm();
  function updateKey(data) {

    var x = Object.assign(AmbientOccParams, data)
    setAmbientOccParams(x)
    if (viewer.current.cesiumElement && viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }

  useEffect(() => {

    let fetch = async () => {
      // await projectStore.getProjectDetail(projectId)
      if (Object.keys(projectStore.projectDetail).length > 0 && usersStore.currentUser.id) {
        let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
        let data = {}
        if (_systemProjectSetting.length > 0) {
          let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)
          if (!_currentUserSystemSetting) return
          data = _currentUserSystemSetting.projectSetting

        } else {
          data = projectSettingStore.systemProjectSetting
        }
        form.setFieldsValue({
          intensity: data?.ambientOccSetting?.intensity,
          bias: data?.ambientOccSetting?.bias,
          lengthCap: data?.ambientOccSetting?.lengthCap,
          stepSize: data?.ambientOccSetting?.stepSize,
          frustumLength: data?.ambientOccSetting?.frustumLength,
          blurStepSize: data?.ambientOccSetting?.blurStepSize
        })
        let _value = {
          intensity: data?.ambientOccSetting?.intensity,
          bias: data?.ambientOccSetting?.bias,
          lengthCap: data?.ambientOccSetting?.lengthCap,
          stepSize: data?.ambientOccSetting?.stepSize,
          frustumLength: data?.ambientOccSetting?.frustumLength,
          blurStepSize: data?.ambientOccSetting?.blurStepSize
        }
        setAmbientOccParams(_value)
        setSaveValue(_value)
      } else {
        form.setFieldsValue({
          intensity: projectSettingStore.systemProjectSetting?.ambientOccSetting?.intensity || 1,
          bias: projectSettingStore.systemProjectSetting?.ambientOccSetting?.bias || 0.4,
          lengthCap: projectSettingStore.systemProjectSetting?.ambientOccSetting?.lengthCap || 0.3,
          stepSize: projectSettingStore.systemProjectSetting?.ambientOccSetting?.stepSize || 2,
          frustumLength: projectSettingStore.systemProjectSetting?.ambientOccSetting?.frustumLength || 100,
          blurStepSize: projectSettingStore.systemProjectSetting?.ambientOccSetting?.blurStepSize || 2
        })
        let _value = {
          intensity: projectSettingStore.systemProjectSetting?.ambientOccSetting?.intensity || 1,
          bias: projectSettingStore.systemProjectSetting?.ambientOccSetting?.bias || 0.4,
          lengthCap: projectSettingStore.systemProjectSetting?.ambientOccSetting?.lengthCap || 0.3,
          stepSize: projectSettingStore.systemProjectSetting?.ambientOccSetting?.stepSize || 2,
          frustumLength: projectSettingStore.systemProjectSetting?.ambientOccSetting?.frustumLength || 100,
          blurStepSize: projectSettingStore.systemProjectSetting?.ambientOccSetting?.blurStepSize || 2
        }
        setAmbientOccParams(_value)
        setSaveValue(_value)
      }
    }

    fetch()

  }, [projectStore.projectDetail])

  const handleSaveAmbientOccSetting = () => {
    form.validateFields().then(res => {
      projectSettingStore.setAmbientOccSetting({ ...res })
      projectStore.setAmbientOccSetting({ ...res })
      if (usersStore.currentUser?.id) {
        let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
        projectStore.setLoadingProgress(true)
        projectStore.updateProjectMetadata({ metadata, store:'metadata' }).then(res => {
          message.success(t('save-ambient-occlusion-successfully'))
          projectStore.setLoadingProgress(false)
          uiStore.setShowAmbientOccSetting(false)
          projectStore.setCleanMode(false)
        })
          .catch(err => {
            projectStore.setLoadingProgress(false)
            message.error(t('update-ambient-occlusion-failed'))
          })
      } else {
        message.success(t('save-ambient-occlusion-successfully'))
        uiStore.setShowAmbientOccSetting(false)
        projectStore.setCleanMode(false)
      }
    })
  }
  return (
    <SettingControlContainer>
      <div className='help-btn-wrap'>
          <HelpButton helppage={"system_settings_ambient_occlusion"}/>
      </div>
      <Form form={form} layout='vertical'>
        <Form.Item label={t('intensity')}
          name='intensity'
          onChange={(v) => {
            if (isNaN(v)) v = -1
            if (typeof v === 'string') v = -1
            updateKey({ intensity: v })
          }}
        >
          <InputNumber placeholder={t('set-intensity')} className='hw-full'
          />
        </Form.Item>
        <Form.Item label={t('bias')}
          name='bias'
          onChange={(v) => {
            if (isNaN(v)) v = -1
            if (typeof v === 'string') v = -1
            updateKey({ bias: v })
          }}
        >
          <InputNumber placeholder={t('set-bias')} className='hw-full'
          />
        </Form.Item>

        <Form.Item
          name='lengthCap'
          label={t('length-cap')}
          onChange={(v) => {
            if (isNaN(v)) v = -1
            if (typeof v === 'string') v = -1
            updateKey({ lengthCap: v })
          }}
        >
          <InputNumber placeholder={t('set-length-cap')} className='hw-full' style={{ marginBottom: 10 }}
          />
        </Form.Item>

        <Form.Item
          name='stepSize'
          label={t('step-size')}
          onChange={(v) => {
            if (isNaN(v)) v = -1
            if (typeof v === 'string') v = -1
            updateKey({ stepSize: v })
          }}
        >
          <InputNumber placeholder={t('set-step-size')} className='hw-full'
          />
        </Form.Item>
        {/* <br />
        FrustumLength:
      <br />
      <InputNumber placeholder="Set FrustumLength" className='hw-full' style={{ marginBottom: 10 }}
        value={AmbientOccParams.frustumLength ? AmbientOccParams.frustumLength : 0}
        onChange={(v) => {
          if (isNaN(v)) v = -1
          if (typeof v === 'string') v = -1
          updateKey({ frustumLength: v })
        }}
      /> */}
        <Form.Item
          name='blurStepSize'
          label={t('blur-step-size')}
          onChange={(v) => {
            if (isNaN(v)) v = -1
            if (typeof v === 'string') v = -1
            updateKey({ blurStepSize: v })
          }}
        >
          <InputNumber placeholder={t('set-blur-step-size')} className='hw-full' style={{ marginBottom: 10 }}
          />
        </Form.Item>
        <Button
          type="default"
          icon={<CloseOutlined />}
          onClick={() => {
            projectStore.setAmbientOccSetting(SaveValue)
            uiStore.setShowAmbientOccSetting(false)
            projectStore.setCleanMode(false)
          }}
        >
          {t('commons.cancel')}
        </Button>
        <Button
          style={{ marginLeft: 5 }}
          type="primary"
          icon={<CheckCircleOutlined />}
          onClick={() => handleSaveAmbientOccSetting()}
        >
          {t('commons.save')}
        </Button>
      </Form>
    </SettingControlContainer>
  );
}



export default inject('projectStore', 'uiStore', "projectSettingStore", "usersStore")(observer(AmbientOccSetting))