import React, { useEffect, useState } from 'react'
// Load jQuery
import { Loading } from './loading'
// Config
import { cesiumToken, arcGisToken } from './config'
import { Ion, ArcGisMapService } from 'cesium'
import { toJS } from 'mobx'

// Pages
import NotFoundPage from './components/pages/NotFoundPage'
import HomePage from './components/pages/HomePage'
import GpsPage from './components/pages/GpsPage'
import ProjectListPage from './components/pages/ProjectListPage'
import ProjectDetailPage from './components/pages/ProjectDetailPage'
import ProjectShare from './components/pages/ProjectShare'
import ModelHelsinki from './components/pages/ModelDemoPage/ModelHelsinki'
import ModelTampere from './components/pages/ModelDemoPage/ModelTampere'
import AuthPage from './components/pages/AuthPage'
import ConnectPage from './components/pages/ConnectPage'
import ProjectTeamsPage from './components/pages/ProjectTeamsPage'
import ProjectSettingsPage from './components/pages/ProjectSettingsPage'
import UserSettingsPage from './components/pages/UserSettingsPage'
import ArDataPage from './components/pages/ArDataPage'
import ConfirmDeleteAccountPage from './components/pages/ConfirmDeleteAccountPage'
import PublicJsonLink from './components/pages/PublicJsonLink'
import AdminPage from './components/pages/AdminPage'
import EmailNotification from './components/pages/EmailNotification'

// Routing
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import ProtectedRoute from './components/pages/ProtectedRoute'

// MobX
import { Provider } from 'mobx-react'
import commonStore from './stores/commonStore'
import uiStore from './stores/uiStore'
import authStore from './stores/authStore'
import usersStore from './stores/usersStore'
import projectStore from './stores/projectStore'
import capturesStore from './stores/capturesStore'
import projectTeamsStore from './stores/projectTeamsStore'
import schedulingStore from './stores/schedulingStore'
import sketchingStore from './stores/sketchingStore'
import polylineCutToolStore from './stores/polylineCutToolStore'
import arStore from './stores/arStore'
import workflowStore from './stores/workflowStore'
import topicStore from './stores/topicStore'
import organizationStore from './stores/organizationStore'
import modelStore from './stores/modelStore'
import feedbackStore from './stores/feedbackStore'
import dashboardStore from './stores/dashboardStore'
import projectSettingStore from './stores/projectSettingStore'
import adminStore from './stores/adminStore'
import licenseStore from './stores/licenseStore'
import alignmentStore from './stores/alignmentStore'
import fileStore from './stores/fileStore'
import pointsceneStore from './stores/pointsceneStore'
import reportStore from './stores/reportStore'
import measureStore from './stores/measureStore'
import userGroupStore from './stores/userGroupStore'
import objectQueryStore from './stores/objectQueryStore'
import projectGanttStore from './stores/projectGanttStore'
import calculationStore from './stores/calculationStore'
import moment from 'moment'
// Styles
import './App.less'
import CookieConsent, { Cookies } from "react-cookie-consent";
import { renderHTML } from './components/helper/CesiumUtils'
import Organization from './components/pages/AdminPage/Organization'
import EditOrganization from './components/pages/AdminPage/Organization/EditOrganization'
import Licenses from './components/pages/AdminPage/Licenses/Licenses';
import EditLicenses from './components/pages/AdminPage/Licenses/EditLicenses';
import EditLicenseType from './components/pages/AdminPage/LicenseType/EditLicenseType';
import Notification from './components/pages/AdminPage/Notification/Notification';
import GenericEmailNotification from './components/pages/AdminPage/GenericEmailNotification/GenericEmailNotification';
import LicenseType from './components/pages/AdminPage/LicenseType/LicenseType';
import ConfirmOrganizationPage from './components/pages/ConfirmOrganizationPage/ConfirmOrganizationPage';
import ConfirmActiveAccount from './components/pages/ConfirmActiveAccount/ConfirmActiveAccount';
import PasswordPage from './components/pages/PasswordPage/PasswordPage';
import FeatureSetting from './components/pages/AdminPage/FeatureSetting'
import Projects from './components/pages/AdminPage/Projects'
import Users from './components/pages/AdminPage/Users'
import EditUser from './components/pages/AdminPage/Users/EditUser'
import IFCEngineUsage from './components/pages/AdminPage/IFCEngineUsage/IFCEngineUsage'
import SketchElement from './components/pages/AdminPage/SketchElement/SketchElement'
import LanguageEditor from './components/pages/AdminPage/LanguageEditor/LanguageEditor'
import GEOIDModels from './components/pages/AdminPage/GEOIDModels/GEOIDModels'

import PreDefinedAccess from './components/pages/AdminPage/PreDefinedAccess'

import { SocketContext, socket } from './socket-context'
import { assetUrl } from './config'
import Legal from './components/pages/AdminPage/Legal'
import Termandconditions from './components/layout/TermAndConditions/TermAndConditions';
import { Trans, useTranslation } from 'react-i18next';
const stores = {
  commonStore,
  uiStore,
  authStore,
  usersStore,
  projectStore,
  capturesStore,
  projectTeamsStore,
  schedulingStore,
  sketchingStore,
  arStore,
  workflowStore,
  topicStore,
  organizationStore,
  modelStore,
  feedbackStore,
  dashboardStore,
  projectSettingStore,
  adminStore,
  licenseStore,
  alignmentStore,
  fileStore,
  pointsceneStore,
  polylineCutToolStore,
  reportStore,
  measureStore,
  userGroupStore,
  objectQueryStore,
  projectGanttStore,
  calculationStore
}

const history = createBrowserHistory()

const App = () => {
  const { t } = useTranslation();
  Ion.defaultAccessToken = cesiumToken
  ArcGisMapService.defaultAccessToken =  arcGisToken
  const [cookie, setCookie] = useState(getCookie('CookieConsent'))
  const [showPolicyTermAndConditions, setShowPolicyTermAndConditions] = useState(false)
  const [data, setData] = useState({})

  const isAuthPage = () => {
    let _page = history?.location?.pathname
    return _page !== "/auth/forgot-password" && _page !== "/auth/register" && _page !== "/auth/login"
  }

  const {setIsAnyInputFocused} = projectStore;

  useEffect(() => {
    const handleFocusChange = () => {
      setIsAnyInputFocused(!!document.querySelector('input:focus, textarea:focus'));
    };

    document.addEventListener('focusin', handleFocusChange);
    document.addEventListener('focusout', handleFocusChange);
    // intervalLicenseDHX = setInterval(displayUniqueAlert, 10000); // 10 second

    return () => {
      document.removeEventListener('focusin', handleFocusChange);
      document.removeEventListener('focusout', handleFocusChange);
      // clearInterval(intervalLicenseDHX);
    };
  }, []);

  const getData = () => {
    fetch(`${assetUrl}maintananceInfo.json`
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        let _legal = {
          policy: myJson?.policy,
          terms: myJson?.terms,
          time: (new Date()).getTime()
        }
        setData(_legal)
        if (usersStore.currentUser && Object.keys(usersStore.currentUser).length > 0) {
          let user = usersStore.currentUser.data
          if (!user || user.policy !== myJson.policy || user.terms !== myJson.terms) {
            setShowPolicyTermAndConditions(true)
          }
        }
        if (myJson.startDate && myJson.endDate) {
          let startTime = new Date(parseInt(myJson.startDate))
          let endTime = new Date(parseInt(myJson.endDate))
          let isBetween = moment(Date.now()).isBetween(moment(startTime), moment(endTime))
          const isActive = myJson.active === true || (myJson.active && myJson.active.toString().toLowerCase() === 'true')
          if (isBetween && isActive) {
            if (history.location.pathname !== '/notification') {
              history.push(`/notification`)
              window.location.reload();
            }
          }
        }
      });
  }
  useEffect(() => {
    let fetchData = async () => {
      getData()
    }
    fetchData()
  }, [])

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const acceptTerm = () => {
    setShowPolicyTermAndConditions(false)
  }

  useEffect(() => {
      let fetchFeaturesHelpListData = async () => {
        projectStore.getFeaturesHelpListInfo()
      }
      fetchFeaturesHelpListData()
  }, [])

  useEffect(() => {
    if (history?.location?.search) {
        let param = history.location.search.toLowerCase();
        if (param.includes('publickey')) {
            usersStore.setCookie(true);
        }else{
            usersStore.setCookie(false);
        }
    }else{
        usersStore.setCookie(false);
    }
  }, [history?.location?.search])
  
  return (
    <SocketContext.Provider value={socket}>
      <Provider {...stores}>
        <Router history={history}>
          <Switch>
            <ProtectedRoute exact path="/" component={ProjectListPage} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin" component={AdminPage} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/organizations/:queryParam" component={Organization} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/organization/:organizationID" component={EditOrganization} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/organization/new" component={EditOrganization} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/licenses/:queryParam" component={Licenses} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/license/:licensesID" component={EditLicenses} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/license/new" component={EditLicenses} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/featuresetting" component={FeatureSetting} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/projects" component={Projects} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/predefinedaccess" component={PreDefinedAccess} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/projects/:queryParam" component={Projects} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/users/:queryParam" component={Users} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/user/:userId" component={EditUser} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/licensetypes/:queryParam" component={LicenseType} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/licensetype/:licenseTypeID" component={EditLicenseType} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/licensetype/new" component={EditLicenseType} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/notification" component={Notification} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/legal" component={Legal} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/generic-email-notification" component={GenericEmailNotification} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/ifcengineusage" component={IFCEngineUsage} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/ifcengineusage/:queryParam" component={IFCEngineUsage} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/sketch-library" component={SketchElement} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/language-editer" component={LanguageEditor} />
            <ProtectedRoute meta={{ roles: ["web_admin"] }} exact path="/admin/geoid-models" component={GEOIDModels} />

            <Route exact path="/auth/:authType" component={AuthPage} />
            <Route exact path="/notification" component={EmailNotification} >

            </Route>
            <Route exact path="/connect/:provider" component={ConnectPage} />
            <Route exact path="/invite/:organizationId" component={ConfirmOrganizationPage} />
            <Route exact path="/confirm-account/:userId" component={ConfirmActiveAccount} />
            <Route exact path="/auth/:passwordType/:tokenid" component={PasswordPage} />
            <Route exact path="/view/:projectId" component={ProjectShare} />
            <Route exact path="/gps" component={GpsPage} />
            {/* <Route exact path="/organizations" component={ManageOrganizationPage} />
          <Route exact path="/organizations/:organizationId" component={ManagerOrganization} /> */}
            <Route exact path="/confirm-delete-account/:tokenid" component={ConfirmDeleteAccountPage} />
            <ProtectedRoute meta={{ roles: ["web_admin", "authenticated"] }} exact path="/projects" component={ProjectListPage} />
            <ProtectedRoute exact path="/tampere" component={ModelTampere} />
            <ProtectedRoute exact path="/helsinki" component={ModelHelsinki} />
            <Route exact path="/project/:projectId" component={ProjectDetailPage} />
            <ProtectedRoute exact path="/project-teams/:projectId" component={ProjectTeamsPage} />
            <ProtectedRoute exact path="/project-settings/:projectId" component={ProjectSettingsPage} />
            <ProtectedRoute exact path="/user-settings" component={UserSettingsPage} />
            <ProtectedRoute exact path="/ar" component={ArDataPage} />
            <Route exact path="/myprofile/:token" component={UserSettingsPage}/>
            <Route exact path="/myprofile" component={UserSettingsPage}/>
            <Route exact path="/:projectId/:hash/:modelName" component={PublicJsonLink} />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
        {usersStore.cookie ? '' : <CookieConsent 
          location="top"
          style={{ background: "#2B373B", zIndex: "9999", textAlign: "center" }}
          buttonText={t('i-agree')}
          onAccept={() => setCookie(true)}
          buttonClasses="cookie-btn "
          containerClasses="cookie-consent-conatiner"
          contentClasses="cookie-text-capitalize"
        >
          <Trans i18nKey="this-site-uses-cookies-and-other-tracking-technologies-to-improve-you-experience" components={[<a target='_blank' href='/cookies-policy.html' />]} />
        </CookieConsent>}
        {
          cookie || usersStore.cookie ? '' : <div className='cookieWraper'></div>
        }
        {
          showPolicyTermAndConditions && isAuthPage() && (<Termandconditions data={data} showPolicyTermAndConditions={showPolicyTermAndConditions} acceptTerm={acceptTerm}></Termandconditions>)
        }
      </Provider>
    </SocketContext.Provider>
  )
}

export default App
