import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Empty, Modal, Table } from 'antd'
import { getListGuidHighlight } from './utils'
import { useTranslation } from 'react-i18next'

const ModalLinkGanttToSavedQuery = ({
  objectQueryStore,
  projectGanttStore,
  projectStore,
  viewer,
}) => {
  const { t } = useTranslation()
  const [savedQueryList, setSavedQueryList] = useState([])
  const [selectedSavedQuerys, setSelectedSavedQuerys] = useState([])
  const [selectedSavedQueryKey, setSelectedSavedQueryKeys] = useState([])

  const columns = [
    {
      title: t('gantt.saved-query'),
      dataIndex: 'name',
    },
  ]

  useEffect(() => {
    if (projectGanttStore.isOpenModalLinkSavedQuery) {
      if (
        objectQueryStore?.listObjectsQuery &&
        objectQueryStore?.listObjectsQuery?.length
      ) {
        setSavedQueryList(objectQueryStore?.listObjectsQuery)
        if(projectGanttStore.selectedSavedQuery?.length){
          setSelectedSavedQueryKeys(projectGanttStore.selectedSavedQuery?.map(sq => sq?.id))
          setSelectedSavedQuerys(projectGanttStore.selectedSavedQuery)
        }
      }
    }

    return () => {}
  }, [
    projectGanttStore.isOpenModalLinkSavedQuery,
    objectQueryStore?.listObjectsQuery,
  ])

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedSavedQueryKey,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSavedQuerys(selectedRows)
      setSelectedSavedQueryKeys(selectedRowKeys)
    },
    
  }

  const handleCancelLinkToDatatree = () => {
    projectGanttStore.setIsOpenModalLinkSavedQuery(false)
    setSelectedSavedQuerys([])
    setSelectedSavedQueryKeys([])
  }

  const handlelinkSavedQuerytoGantt = () => {
    projectGanttStore.setSelectedSavedQuery(selectedSavedQuerys)
    projectGanttStore.setIsOpenModalLinkSavedQuery(false)
    setSelectedSavedQuerys([])
    setSelectedSavedQueryKeys([])
  }

  return (
    <Modal
      title={t('gantt.saved-query')}
      centered
      zIndex={10001}
      visible={projectGanttStore.isOpenModalLinkSavedQuery}
      okText={t('commons.save')}
      cancelText={t('commons.cancel')}
      onOk={handlelinkSavedQuerytoGantt}
      okButtonProps={{
        style: {
          display: projectGanttStore.isReadonlyMode ? 'none' : 'initial',
        },
      }}
      onCancel={handleCancelLinkToDatatree}
      style={{
        maxHeight: 'calc(100vh - 20px)',
        //overflowY: 'auto'
      }}
      bodyStyle={{
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'auto',
      }}>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        showHeader={false}
        columns={columns}
        dataSource={savedQueryList}
        rowKey={(record) => record?.id}
        locale={{ emptyText: t('no-data') }}
      />
    </Modal>
  )
}

export default inject(
  'projectGanttStore',
  'projectStore',
  'objectQueryStore'
)(observer(ModalLinkGanttToSavedQuery))
