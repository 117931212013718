import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Row, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import uuid from 'uuid';
import '../style.css';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { useParams } from 'react-router-dom';
const { Option } = Select;


const AddLicense = (props) => {
    const { handleCloseModal, handleSetFieldvalue, handleRenewLicense, adminStore, commonStore } = props;
    const { t } = useTranslation();
    let CusmtomFormAddUser = () => {
        const [form] = Form.useForm();
        const [licenseTypes, setLicenseTypes] = useState('');
        const [isLoading, setLoadingProgress] = useState(false);
        const { organizationID } = useParams();
        moment.locale(`${commonStore.language}`)


        const handleCreateNewLicense = (v) => {
            form.validateFields().then(values => {
                let typeData = adminStore.licenseTypes.find(elm => elm.id === licenseTypes)
                values.licenceKey = uuid()
                values.licensesType = typeData?.name
                values.description = typeData?.description
                values.activated = Date.now()
                values.expiration = moment(Date.now()).add(values.timeLimit || 0, 'days').toString()
                setLoadingProgress(true)
                if (organizationID !== "new") {
                    const renewParam = {
                        organization: organizationID,
                        licensesType: typeData.id
                    }
                    adminStore.renewLicense(renewParam).then(res => {
                        handleRenewLicense(res.data)
                        message.success(t('create-new-license-successfully'))
                        setLoadingProgress(false)
                        handleCloseModal()
                    })
                        .catch(err => {
                            setLoadingProgress(false)
                            message.error(t('an-error-occurred'))
                            console.log(err)
                        })

                } else {
                    adminStore.createLicense(values).then(res => {
                        handleSetFieldvalue(res.data)
                        message.success(t('create-new-license-successfully'))
                        setLoadingProgress(false)
                        handleCloseModal()
                    })
                        .catch(err => {
                            setLoadingProgress(false)
                            message.error(t('an-error-occurred'))
                            console.log(err)
                        })
                }
            })
        }

        useEffect(() => {
            if (licenseTypes) {
                let typeData = adminStore.licenseTypes.find(elm => elm.id === licenseTypes)
                form.setFieldsValue({
                    numberProject: typeData.numberProject,
                    numberUser: typeData.numberUser,
                    storageQuota: typeData.storageQuota,
                    timeLimit: typeData.timeLimit
                })
            }
        }, [licenseTypes])

        return (
            <>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={v => handleCreateNewLicense(v)}
                    id={'add-license-form'}>

                    <Form.Item label={t('licenses-type')} name="licensetype" rules={[
                        {
                            required: true,
                            message: t('please-select-licenses-type'),
                        },
                    ]}>
                        <Select
                            onChange={v => setLicenseTypes(v)}
                            placeholder={t('select-license')}>
                            {
                                adminStore.licenseTypes.map((r) => {
                                    return (
                                        <Option key={r.id} value={r.id}>{r.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} label={t('number-project')} name="numberProject" rules={[
                        {
                            required: false,
                        },
                    ]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} label={t('time-limit')} name="timeLimit" rules={[
                        {
                            required: false,
                        },
                    ]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} label={t('storage-quota')} name="storageQuota" rules={[
                        {
                            required: false,
                        },
                    ]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} label={t('number-user')} name="numberUser" rules={[
                        {
                            required: false,
                        },
                    ]}>
                        <Input disabled />
                    </Form.Item>
                    <Row
                        justify="end"
                    >
                        <Button
                            onClick={() => handleCloseModal(false)}
                            icon={<CloseCircleOutlined />}
                        >
                            {t('commons.cancel')}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: "10px" }}
                            loading={isLoading}
                            icon={<SaveOutlined />}
                        >
                            {t('commons.create')}
                        </Button>
                    </Row>
                </Form>

            </>
        )
    }
    CusmtomFormAddUser = inject("usersStore", "organizationStore", "projectStore", "commonStore")(observer(CusmtomFormAddUser));
    return (
        <Fragment>
            <CusmtomFormAddUser></CusmtomFormAddUser>
        </Fragment>
    )
}
export default inject("usersStore", "adminStore", 'commonStore')(observer(AddLicense));
