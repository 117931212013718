import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Table } from 'antd';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import OrganizationProjectDetailPage from './OrganizationProjectDetailPage';
import { TableHeading, TableWrapper } from './style';
import { useTranslation } from 'react-i18next';
import Utils from '../../../../../utils';
const ProjectsTab = props => {
  const { t } = useTranslation();
  const { organizationStore, adminStore, commonStore, projectsBelongOrganizationData, licensesBelongOrganizationData } = props;
  const [projectsBelongOrganizationList, setProjectsBelongOrganizationList] = useState([])
  const organizationId = organizationStore.paramsOrganizationDetail
  moment.locale(`${commonStore.language}`)


  useEffect(() => {
    adminStore.getRoleCurrentUserInOrganization(organizationId)
    if (projectsBelongOrganizationData) {
      setProjectsBelongOrganizationList(projectsBelongOrganizationData.filter(c => !c.isDeleted))
    }
  }, [projectsBelongOrganizationData])

  const handleCreateProjectModal = () => {
    organizationStore.setModalCreateProject(true)
    organizationStore.setStatusProject('new')
  }

  const handleEditProjectModal = (projectId) => {
    organizationStore.setModalCreateProject(true)
    organizationStore.setStatusProject(projectId)
  }

  const handleDeleteProjectInOrganization = (projectId, organizationId) => {
    adminStore.setLoadingProgress(true)
    adminStore.deleteProjectInOrganization(projectId, organizationId).then(res => {
      const index = projectsBelongOrganizationList.findIndex(x => x.id === projectId)
      const temp = [...projectsBelongOrganizationList]
      message.success(t('project-deleted-successfully'))
      temp.splice(index, 1)
      setProjectsBelongOrganizationList(temp)
      adminStore.setLoadingProgress(false)
      organizationStore.setForceReload(1)
    }).catch(error => {
      adminStore.setLoadingProgress(false)
      message.error(t(error.message))
    })
  }

  const tableColumns = [
    {
      title: t('project-name'),
      dataIndex: 'name',
      key: "name",
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('storage'),
      dataIndex: 'storage',
      key: "storage",
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (a.storage || 0) - (b.storage || 0)
      },
      render: (record, obj) => {
        let count = record ? record : 0
        return <span> {Utils.kbytesToSize(count)} </span>
      }
    },
    {
      title: t('users'),
      dataIndex: 'projectuserroles',
      key: "user",
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        let _userA = a.projectuserroles.filter(v => v.user && v.email) || []
        let _userB = b.projectuserroles.filter(v => v.user && v.email) || []
        return _userA.length - _userB.length
      },
      render: (record, obj) => {
        let _users
        if (record) _users = record.filter(v => v.user && v.email && v.email !== "maintenance@xd-visuals.fi")
        return <span> {_users ? _users.length : 0}</span>
      }
    },
    {
      title: t('created'),
      width: 170,
      key: "createdAt",
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = a.createdAt ? new Date(a.createdAt) : distantFuture
        let dateB = b.createdAt ? new Date(b.createdAt) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: record => {
        return (
          <div className={'tileset-name'}>{moment(record.createdAt).format('MMMM DD, YYYY')}</div>
        )
      }
    },
    {
      title: t('last-used'),
      key: 'updatedAt',
      width: 150,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = a.updatedAt ? new Date(a.updatedAt) : distantFuture
        let dateB = b.updatedAt ? new Date(b.updatedAt) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: record => {
        return <div>{moment(record.updatedAt).format('MMM DD, YYYY')}</div>
      },
    },
    {
      title: t('action'),
      key: 'action',
      width: 120,
      align: 'center',
      render: (record) => (
        <>
          {
            adminStore.roleCurrentUserInOrganization.role === "Admin" || adminStore.roleCurrentUserInOrganization.role === "Manager" ? (
              <Button
                onClick={() => handleEditProjectModal(record.id)}
                icon={<EditOutlined />} style={{ marginRight: '5px' }} />
            ) : ''
          }

          {
            adminStore.roleCurrentUserInOrganization && adminStore.roleCurrentUserInOrganization.role === "Admin" ? (
              <Popconfirm
                title={t('are-you-sure-delete-project')}
                onConfirm={() => handleDeleteProjectInOrganization(record.id, organizationId)}
                okText={t('commons.yes')}
                cancelText={t('commons.no')}
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            ) : ''
          }
        </>
      ),
    }
  ]


  return (
    <TableWrapper>
      <TableHeading>
        <div className="title">
          {t('projects-belong-to-organization')}
        </div>
        {
          adminStore.roleCurrentUserInOrganization && adminStore.roleCurrentUserInOrganization.role === "Admin" ? (
            <Button type={'primary'} onClick={handleCreateProjectModal}>
              <PlusOutlined />{t('add-new-project')}
            </Button>
          ) : ''
        }
      </TableHeading>
      <Table
        pagination={false}
        rowKey={record => record.id}
        columns={tableColumns}
        dataSource={projectsBelongOrganizationList}
        locale={{ emptyText: t('no-data') }}
      />
      <OrganizationProjectDetailPage licensesBelongOrganizationData={licensesBelongOrganizationData} />
    </TableWrapper>
  )
}
export default withRouter(inject('organizationStore', "adminStore", 'commonStore')(observer(ProjectsTab)))
