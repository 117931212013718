import {
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined, CaretUpOutlined, CloseOutlined, SaveOutlined
} from '@ant-design/icons';
import { Button, Cascader, Col, Form, InputNumber, Row, Select, Switch, Spin, message } from 'antd';
import { HeadingPitchRoll, Math as CesiumMath, Quaternion } from 'cesium';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SettingControlContainer } from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
import HelpButton from '../elements/HelpButton';

const SettingControl = ({ projectStore, projectSettingStore, usersStore, commonStore, viewer }) => {
  const { t } = useTranslation();
  const [cesiumFov, setCesiumFov] = useState(projectStore.openCVData.extra.fov)
  const [heading, setHeading] = useState(0)
  const [height, setHeight] = useState(0)
  const [pitch, setPitch] = useState(0)
  const [roll, setRoll] = useState(0)
  const [aHeading, setAHeading] = useState(0)
  const [latitute, setLatitute] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [spinCamera, setSpinCamera] = useState(false)
  const [spinSensor, setSpinSensor] = useState(false)
  const [fovValue, setFovValue] = useState([])
  const [preFov, setPreFov] = useState()
  const { Option } = Select;
  const { projectId } = useParams()
  const [form] = Form.useForm();


  const fovOption = [
    {
      value: 'galaxyS10',
      label: 'Galaxy S10 plus',
      children: [
        {
          value: '77',
          label: 'Camera 1 (77)',
          id: 0,
        },
        {
          value: '123',
          label: 'Camera 2 (123)',
          id: 1,
        },
        {
          value: '45',
          label: 'Camera 3 (45)',
          id: 2,
        },
      ],
    },
    {
      value: 'note10plus',
      label: 'Galaxy Note 10 Plus',
      children: [
        {
          value: '77',
          label: 'Camera 1 (77)',
          id: 0,
        },
        {
          value: '123',
          label: 'Camera 2 (123)',
          id: 1,
        },
        {
          value: '45',
          label: 'Camera 3 (45)',
          id: 2,
        },
      ],
    },
    {
      value: 'other',
      label: t('other'),
      children: [
        {
          value: '45',
          label: 'FOV 45',
          id: 0,
        },
        {
          value: '52',
          label: 'FOV 52',
          id: 1,
        },
        {
          value: '60',
          label: 'FOV 60',
          id: 2,
        },
        {
          value: '77',
          label: 'FOV 77',
          id: 3,
        },
        {
          value: '80',
          label: 'FOV 80',
          id: 4,
        },
        {
          value: '123',
          label: 'FOV 123',
          id: 5,
        },
      ],
    },
  ]

  useEffect(() => {
    let data = projectSettingStore.systemProjectSetting
    form.setFieldsValue({
      vc: data?.openCVData?.vc || 0,
      hc: data?.openCVData?.hc || 0,
      fov: data?.openCVData?.extra?.fov || 60,
      sensorFrequences: data?.sensorFrequences,
      sampleRange: data?.sampleRange,
      smoothFilterType: data?.smoothFilterType,
      enableHighAccuracy: data?.enableHighAccuracy
    })
    // if (projectSettingStore.systemProjectSetting.openCVData.fovValue && projectSettingStore.systemProjectSetting.openCVData.fovValue.length > 1) {
    //   form.setFieldsValue({ fov: parseFloat(projectSettingStore.systemProjectSetting.openCVData.fovValue[1]).toFixed(2) })
    // }
    projectSettingStore.assignSetting(data)


  }, [projectSettingStore.systemProjectSetting])


  function setExtraOCV(key, value) {
    let extra = projectStore.openCVData.extra
    extra[key] = value
    projectStore.setOpenCVData({ extra: extra })
    projectSettingStore.setOpenCVData({ extra: extra })
  }
  function changeFOV(value) {
    setCesiumFov(value)
    setFovValue([undefined, value])
    viewer.current.cesiumElement.camera.frustum.fov = CesiumMath.toRadians(value)
    form.setFieldsValue({ fov: parseFloat(value).toFixed(2) })
  }

  function onChangeModel(value, selectedOption) {
    if (value) {
      setFovValue(value)
    } else {
      setFovValue()
    }
    if (!value[0]) return
    if (viewer.current.cesiumElement) {
      viewer.current.cesiumElement.camera.frustum.fov = CesiumMath.toRadians(value[1])
      const devices = commonStore.devices;
      if (selectedOption[1] && !isNaN(selectedOption[1].id) && devices.length > selectedOption[1].id && devices[selectedOption[1].id] && devices[selectedOption[1].id].deviceId) {
        commonStore.setDeviceId(devices[selectedOption[1].id].deviceId)
      }
      setCesiumFov(parseFloat(value[1]).toFixed(2))

      form.setFieldsValue({ fov: parseFloat(value[1]).toFixed(2) })
    }
  }
  function onChangeFilter(value, selectedOption) {
    projectSettingStore.setAssignObjSystemProjectSetting("smoothFilterType", value)
    projectStore.setSmoothFilterType(value)
  }
  function onChangeCompassMode(value, selectedOption) {
    projectSettingStore.setAssignObjSystemProjectSetting("compassMode", value)
    projectStore.setCompassMode(value)
  }

  useEffect(() => {
    if (viewer.current.cesiumElement && viewer.current.cesiumElement.camera.frustum) {
      if (!isNaN(cesiumFov)) return
      if (viewer.current.cesiumElement.camera.frustum.aspectRatio >= 1) {
        setExtraOCV('fovy', CesiumMath.toDegrees(viewer.current.cesiumElement.camera.frustum.fovy))
      } else {
        var fovy = cesiumFov * viewer.current.cesiumElement.camera.frustum.aspectRatio
        setExtraOCV('fovy', fovy)
      }
      setExtraOCV('fov', cesiumFov)
    }
  }, [cesiumFov])

  const onChangePOV = (value, isUp) => {
    if (viewer.current.cesiumElement) {
      //in radians
      let currentFOV = viewer.current.cesiumElement.camera.frustum.fov
      var aspectRatio = viewer.current.cesiumElement.camera.frustum.aspectRatio
      //viewerRef.current.cesiumElement.camera.frustum.aspectRatio = 16/9;
      var degree = CesiumMath.toDegrees(currentFOV)
      if (!isNaN(value)) {
        if (!isUp) value = -value
        degree += value
        degree = parseFloat(degree.toFixed(2))
        viewer.current.cesiumElement.camera.frustum.fov = CesiumMath.toRadians(degree)
        setCesiumFov(degree)
        form.setFieldsValue({ fov: degree })
      }
    }
  }

  const upFOV = () => {
    onChangePOV(1, true)
  }
  const downFOV = value => {
    onChangePOV(1)
  }
  const onChangeHorizontal = (value, isUp) => {
    if (!isUp) value = -value
    projectStore.setOpenCVData({ hc: projectStore.openCVData.hc + value })
    projectSettingStore.setOpenCVData({ hc: projectSettingStore.systemProjectSetting.openCVData.hc + value })
    let hc = Number(parseFloat(projectSettingStore.systemProjectSetting.openCVData.hc + value).toFixed(2))
    form.setFieldsValue({
      hc
    })
  }
  const onChangeVertical = (value, isUp) => {
    if (!isUp) value = -value
    projectStore.setOpenCVData({ vc: projectStore.openCVData.vc + value })
    projectSettingStore.setOpenCVData({ vc: projectSettingStore.systemProjectSetting.openCVData.vc + value })
    let vc = Number(parseFloat(projectSettingStore.systemProjectSetting.openCVData.vc + value).toFixed(2))
    form.setFieldsValue({
      vc
    })
  }
  
  const upVertical = () => {
    onChangeVertical(0.5, true)
  }
  const downVertical = value => {
    onChangeVertical(0.5)
  }
  const upHorizontal = () => {
    onChangeHorizontal(0.5)
  }
  const downHorizontal = value => {
    onChangeHorizontal(0.5, true)
  }
  useEffect(() => {
    if (viewer.current.cesiumElement) {
      projectStore.setCleanMode(true)
      setPreFov(viewer.current.cesiumElement.camera.frustum.fov)
      var degree = CesiumMath.toDegrees(viewer.current.cesiumElement.camera.frustum.fov)
      setCesiumFov(degree.toFixed(2))
    }
  }, [])

  useEffect(() => {
    if (viewer.current.cesiumElement) {
      if (projectStore.obtDeviceSensor.quaternion) {
        var b = projectStore.obtDeviceSensor.quaternion;

        var q = new Quaternion(b[0], b[1], b[2], b[3]);
        var hpr = HeadingPitchRoll.fromQuaternion(q);

        setHeading(CesiumMath.toDegrees(hpr.heading))
        setPitch(CesiumMath.toDegrees(hpr.pitch))
        setRoll(CesiumMath.toDegrees(hpr.roll))
        if (projectStore.obtGPSHeight.height)
          setHeight(projectStore.obtGPSHeight.height)
        if (projectStore.averageOrientation && projectStore.averageOrientation.heading)
          setAHeading(CesiumMath.toDegrees(projectStore.averageOrientation.heading))
        if (projectStore.currentGPS.coords) {
          setLatitute(projectStore.currentGPS.coords.latitude)
          setLongitude(projectStore.currentGPS.coords.longitude)
        }
      }
    }
  }, [projectStore.averageOrientation])
  function onChangeGPSAccuracy(checked) {
    commonStore.setenableHighAccuracy(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("enableHighAccuracy", checked)
  }

  const handleSensorSetting = res => {
    if (projectSettingStore.openCameraSetting) {
      projectSettingStore.setOpenCVData({ hc: res.hc, vc: res.vc, extra: { fov: res.fov }, fovValue: fovValue.length > 0 ? fovValue : undefined })
    } else {
      projectSettingStore.assignSetting({ ...projectSettingStore.systemProjectSetting, ...res })
    }
    // if (fovValue.length === 0) {
    //   viewer.current.cesiumElement.camera.frustum.fov = preFov
    // }
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    setSpinSensor(true)
    setSpinCamera(true)
    if (usersStore.currentUser?.id) {
      projectStore.updateProjectMetadata({ metadata }).then(res => {
        setSpinSensor(false)
        setSpinCamera(false)
        projectStore.setCleanMode(false)
        projectStore.setShowFov(false)
        projectSettingStore.setOpenSensorSetting(false)
        projectSettingStore.setOpenCameraSetting(false)
        message.success(t('updated-successfully'))
      })
        .catch(err => {
          setSpinSensor(false)
          setSpinCamera(false)
          message.error(t('updated-failed'))
        })
    } else {
      setSpinSensor(false)
      setSpinCamera(false)
      projectStore.setCleanMode(false)
      projectStore.setShowFov(false)
      projectSettingStore.setOpenSensorSetting(false)
      projectSettingStore.setOpenCameraSetting(false)
      message.success(t('updated-successfully'))
    }

  }


  const setSensorFrequences = b => {
    if (!isNaN(b))
      projectSettingStore.setAssignObjSystemProjectSetting("sensorFrequences", Number(b))
  }
  const SensorSetting = () => {
    return (
      <Fragment>
        {
          projectSettingStore.openSensorSetting && (
            <Spin spinning={spinSensor} delay={100}>
              <div className='help-btn-wrap'>
                <HelpButton helppage={"system_settings_sensor_setting"}/>
              </div>
              <Form.Item
                label={t('frequences')}
                name='sensorFrequences'
                onChange={(v) => {
                  projectStore.setSensorFrequences(v)
                  setSensorFrequences(v)
                }}
              >
                <InputNumber placeholder=""
                  min={1}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                label={t('filter-value')}
                name='sampleRange'
                onChange={(v) => {
                  projectStore.setSampleRange(v)
                  projectSettingStore.setSampleRange(v)
                }}
              >
                <InputNumber placeholder=""
                  min={1}
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                label={t('filter-type')}
                name='smoothFilterType'
                onChange={onChangeFilter}
              >
                <Select
                  placeholder={t('select-filter')}
                >
                  <Option value="ma">{t('moving-average')}</Option>
                  <Option value="lp">{t('low-pass')}</Option>
                  <Option value="kf">{t('kalman-filter')}</Option>
                  <Option value="na">{t('no-filter')}</Option>
                </Select>
              </Form.Item>

              {t('heading')}
              <br />
              <InputNumber placeholder=""
                value={heading}
                readOnly={true}
                style={{ width: '100%' }}
                disabled={true}
              />
              <br />
              {t('filter-heading')}
              <br />
              <InputNumber placeholder=""
                value={aHeading}
                readOnly={true}
                style={{ width: '100%' }}
                disabled={true}
              />
              <Row>
                <Col span={12}>{t('pitch')}:</Col>
                <Col span={12}>{t('roll')}:</Col>
              </Row>
              <Row style={{ height: '40px' }}>
                <Col span={12}>
                  <InputNumber placeholder=""
                    value={pitch}
                    readOnly={true}
                    disabled={true}
                  />
                </Col>
                <Col span={12}>
                  <InputNumber placeholder=""
                    value={roll}
                    readOnly={true}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>{t('longitude')}:</Col>
                <Col span={12}>{t('latitude')}:</Col>
              </Row>
              <Row style={{ height: '40px' }}>
                <Col span={12}>
                  <InputNumber placeholder=""
                    value={longitude}
                    readOnly={true}
                    disabled={true}
                  />
                </Col>
                <Col span={12}>
                  <InputNumber placeholder=""
                    value={latitute}
                    readOnly={true}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Form.Item
                label={t('compass-mode')}
                name='smoothFilterType'
                onChange={onChangeCompassMode}
                initialValue={projectStore.compassMode}
              >
                <Select
                  placeholder={t('compass-mode')}
                  
                >
                  <Option value="a">Compass mode A</Option>
                  <Option value="b">Compass mode B</Option>
                 
                </Select>
              </Form.Item>

              <Form.Item
                label={t('gps-high-accuracy')}
                name='enableHighAccuracy'
                onChange={onChangeGPSAccuracy}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={t('commons.on')}
                  unCheckedChildren={t('commons.off')}
                  size={'small'}
                  onChange={onChangeGPSAccuracy}
                />
              </Form.Item>
              
            </Spin>
          )} {projectSettingStore.openCameraSetting && (
            <Spin spinning={spinCamera} delay={100}>
              <HelpButton styles={'justify-content: end;'} helppage={"system_settings_camera_setting"}/>
              <Row align='bottom'>
                <Form.Item
                  label={t('vertical-calibration')}
                  name='vc'
                  onChange={(v) => {
                    projectStore.setOpenCVData({ vc: Number(parseFloat(v).toFixed(2)) })
                    projectSettingStore.setOpenCVData({ vc: Number(parseFloat(v).toFixed(2)) })
                  }}
                >
                  <InputNumber placeholder={t('set-vertical-correction')} className='hw'
                  />

                </Form.Item>
                <Button type="button" icon={<CaretUpOutlined />} onClick={upVertical}></Button>
                <Button type="button" icon={<CaretDownOutlined />} onClick={downVertical}></Button>
              </Row>

              <Row align='bottom'>
                <Form.Item label={t('horizontal-calibration')} name='hc' onChange={(v) => {
                  projectStore.setOpenCVData({ hc: Number(parseFloat(v).toFixed(2)) })
                  projectSettingStore.setOpenCVData({ hc: Number(parseFloat(v).toFixed(2)) })
                }}>
                  <InputNumber placeholder={t('set-horizontal-correction')} className='hw'
                  />
                </Form.Item>
                <Button type="button" icon={<CaretLeftOutlined />} onClick={upHorizontal}></Button>
                <Button type="button" icon={<CaretRightOutlined />} onClick={downHorizontal}></Button>
              </Row>
              FOV:
              <br />
              <Cascader
                options={fovOption}
                defaultValue={projectSettingStore?.systemProjectSetting?.openCVData?.fovValue && projectSettingStore?.systemProjectSetting?.openCVData?.fovValue.length > 1 && projectSettingStore?.systemProjectSetting?.openCVData?.fovValue[0] && (parseFloat(projectSettingStore?.systemProjectSetting?.openCVData?.fovValue[1]).toFixed(2)) === projectSettingStore.systemProjectSetting?.openCVData?.extra?.fov ? projectSettingStore?.systemProjectSetting?.openCVData?.fovValue : undefined}
                onChange={onChangeModel}
                placeholder={t('phone-model')}
              />
              <br />
              <Row align='bottom'>
                <Form.Item
                  name='fov'
                  onChange={(v) => {
                    // setCesiumFov(parseFloat(v).toFixed(2))
                    if (viewer.current.cesiumElement) {
                      let currentFOV = viewer.current.cesiumElement.camera.frustum.fov
                      var degree = CesiumMath.toDegrees(currentFOV)
                      if (!isNaN(v)) {
                        degree = v
                        viewer.current.cesiumElement.camera.frustum.fov = CesiumMath.toRadians(degree)
                        setCesiumFov(degree)
                      }
                    }

                  }}
                >
                  <InputNumber value={cesiumFov} className='hw' onChange={changeFOV}
                  />
                </Form.Item>
                <Button type="button" icon={<CaretUpOutlined />} onClick={upFOV}></Button>
                <Button type="button" icon={<CaretDownOutlined />} onClick={downFOV}></Button>
              </Row>
            </Spin>
          )
        }

      </Fragment>
    )

  }

  const cancel = () => {
    projectStore.setCleanMode(false)
    projectStore.setShowFov(false)
    projectSettingStore.setOpenSensorSetting(false)
    projectSettingStore.setOpenCameraSetting(false)
    setSpinCamera(false)
    setSpinSensor(false)
    if (viewer.current.cesiumElement) {
      viewer.current.cesiumElement.camera.frustum.fov = preFov
    }
  }

  return (
    <SettingControlContainer>
      <Form form={form} layout='vertical' onFinish={handleSensorSetting}>
        {
          SensorSetting()
        }
        <Row>
          <Button
            onClick={cancel}
            style={{ marginLeft: 0, marginTop: '5px' }}
            type="button"
            icon={<CloseOutlined />}
          >{t('commons.cancel')}</Button>
          <Button
            htmlType="submit"
            style={{ marginLeft: 0, marginTop: '5px', marginLeft: '5px' }}
            type='primary'
            icon={<SaveOutlined />}
          >{t('commons.save')}</Button>
        </Row>
      </Form>
    </SettingControlContainer>
  )
}
export default inject('projectStore', 'projectSettingStore', 'usersStore', 'commonStore')(observer(SettingControl))
