export const vi = {
    "date": {
        "month_full": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ],
        "month_short": [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        "day_full": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ],
        "day_short": [
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat"
        ],
        "month_short_hash": {
            "Jan": 0,
            "Feb": 1,
            "Mar": 2,
            "Apr": 3,
            "May": 4,
            "Jun": 5,
            "Jul": 6,
            "Aug": 7,
            "Sep": 8,
            "Oct": 9,
            "Nov": 10,
            "Dec": 11
        },
        "month_full_hash": {
            "January": 0,
            "February": 1,
            "March": 2,
            "April": 3,
            "May": 4,
            "June": 5,
            "July": 6,
            "August": 7,
            "September": 8,
            "October": 9,
            "November": 10,
            "December": 11
        }
    },
    "labels": {
        "new_task": "Nhiệm vụ mới",
        "icon_save": "Lưu",
        "icon_cancel": "Huỷ",
        "icon_details": "Chi tiết",
        "icon_edit": "Sửa",
        "icon_delete": "Xoá",
        "confirm_closing": "",
        "confirm_deleting": "Tác vụ sẽ bị xóa vĩnh viễn, bạn có chắc chắn không?",
        "section_description": "Mô tả",
        "section_time": "Khoảng thời gian",
        "section_type": "Loại",
        "column_wbs": "WBS",
        "column_text": "Tên nhiệm vụ",
        "column_start_date": "Bắt đầu",
        "column_duration": "Thời lượng",
        "column_add": "",
        "link": "Liên kết",
        "confirm_link_deleting": "sẽ bị xoá",
        "link_start": " (bắt đầu)",
        "link_end": " (kết thúc)",
        "type_task": "Nhiệm vụ",
        "type_project": "Dự án",
        "type_milestone": "Mốc",
        "minutes": "Phút",
        "hours": "Giờ",
        "days": "Ngày",
        "weeks": "Tuần",
        "months": "Tháng",
        "years": "Năm",
        "message_ok": "Đồng ý",
        "message_cancel": "Huỷ",
        "section_constraint": "Ràng buộc",
        "constraint_type": "Loại ràng buộc",
        "constraint_date": "Ngày ràng buộc",
        "asap": "Sớm nhất có thể",
        "alap": "Muộn nhất có thể",
        "snet": "Bắt đầu không sớm hơn",
        "snlt": "Bắt đầu không muộn hơn",
        "fnet": "Kết thúc không sớm hơn",
        "fnlt": "Kết thúc không muộn hơn",
        "mso": "Phải bắt đầu",
        "mfo": "Phải kết thúc",
        "resources_filter_placeholder": "gõ để lọc",
        "resources_filter_label": "ẩn trống",
        "empty_state_text_link": "Nhận vào đây",
        "empty_state_text_description": "để tạo nhiệm vụ đầu tiên của bạn",
        "section_priority": "Ưu tiên",
        "section_taskType": "Loại",
        "section_parent": "Nhiệm vụ cha",
        "section_progress": "Tiến trình",
        "section_categories": "Thể loại",
        "section_template": "Định nghĩa 4D",
        "section_highlightColor": "Màu nổi bật",
        "gantt_save_btn": "Lưu",
        "gantt_cancel_btn": "Huỷ",
        "gantt_delete_btn": "Xoá"
    }
}