import { Avatar, Button, DatePicker, Divider, Layout, List, Modal, Select, Spin, Switch } from 'antd'
import {
  ExperimentOutlined,
  BackwardOutlined,
  PlusSquareOutlined,
  CompassOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import './PageTemplate.css'
import { Trans, useTranslation } from 'react-i18next';
// const { SubMenu } = Menu
const { Content, Sider, Header } = Layout
const { Option } = Select
// const { Meta } = Card
const { RangePicker } = DatePicker;

const ValueStyle = styled.div`
  color:green;
  display:inline;
  font-weight:bold;
  `
const ArTemplate = ({ projectStore, usersStore, arStore, children, commonStore }) => {
  const { t } = useTranslation();
  const [Projects, setProjects] = useState([])
  const [ArByProjects, setArByProjects] = useState([])
  const [CaliByFilter, setCaliByFilter] = useState([])
  const [openSelectCalib, setOpenSelectCalib] = useState(false)
  const [SelectedCalb, setSelectedCalb] = useState(false)

  const [OptionCalbToApply, setOptionCalbToApply] = useState([])
  const [OptionCalbByAr, setOptionCalbByAr] = useState([])
  moment.locale(`${commonStore.language}`)

  const dateP = useRef(null)

  const checkLoading = () =>
    !!(
      projectStore.isLoading ||
      arStore.isLoading ||
      usersStore.isLoading
    )

  const OcvStat = ({ ocv, result }) => {
    if (!result.Angle) result.Angle = ['?', '?', '?']
    return (
      <span style={{ fontSize: '11px' }}>
        Hes: <strong>{ocv.hessianThreshold}</strong>  , Thresh: <strong>{ocv.ratio_thresh}</strong>
        , Octaves: {ocv.nOctaves} , Oct Layer: {ocv.nOctaveLayers}
        , Extended: {ocv.extended} , Upright: {ocv.upright}
        , fov: {ocv.fov} , fov y: {ocv.fovy}
        , a filter: {ocv.angle_filter ? ocv.angle_filter : "?"} , d filter: {ocv.distance_filter ? ocv.distance_filter : '?'} , p size: {ocv.pixel_size ? ocv.pixel_size : '?'} , p diff: {ocv.pixel_diff ? ocv.pixel_diff : '?'}
        <span style={{ marginLeft: 20 }}>H Angle: </span>
        <span style={{ color: result.Angle[0] ? "green" : "red" }}>{result.Angle[0] ? result.Angle[0] : "?"}</span>
        , V Angle: <span style={{ color: result.Angle[1] ? "green" : "red" }}>{result.Angle[1] ? result.Angle[1] : "?"}</span>
        , Tilt: <span style={{ color: result.Angle[2] ? "green" : "red" }}>{result.Angle[2] ? result.Angle[2] : "?"}</span>
      </span>
    )
  }

  useEffect(() => {
    if (!arStore.currentTestData) return
    arStore.setCorrectAngle({ v: "?", h: "?", t: '?', Points: [], apply: false })
    // arStore.setCurrentAngleApply({ v: 0, h: 0, t: 0, Points: [] })
    var result = {
      Angle: ['?', '?', '?'],
      Status: 'None',
      Points: []
    }
    arStore.setCurrentStatText('')

    // if (!arStore.currentCalbAr) {
    //   arStore.setCurrentCalbAr('cal-0')
    //   return
    // }
    if (arStore.currentCalbAr == 'cal-0') {
      if (!arStore.currentTestData.modelRenderInfo.OpenCV)
        arStore.setCurrentStatText('Old version!')
      if (arStore.currentTestData) {
        if (arStore.currentTestData.modelRenderInfo.calibration) result = arStore.currentTestData.modelRenderInfo.calibration
        try {
          arStore.setCurrentStatText(<OcvStat ocv={arStore.currentTestData.modelRenderInfo.OpenCV.extra} result={result} />)
        } catch (error) {
          arStore.setCurrentStatText('Nothing to show!')
          console.log('error', error)
        }
      }
    } else {
      const index = arStore.calibrations.findIndex(x => x.id === arStore.currentCalbAr)
      if (index < 0) return
      // arStore.setCurrentCalibration(arStore.calibrations[index])
      var ocv = arStore.calibrations[index].OpenCV
      if (!ocv.fov) {
        ocv.fov = arStore.currentTestData.modelRenderInfo.OpenCV.extra.fov
        ocv.fovy = arStore.currentTestData.modelRenderInfo.OpenCV.extra.fovy
      }

      const i = arStore.calibrations[index].data.artests.findIndex(c => c.id === arStore.currentTestData.id)
      if (i > -1) {
        let ret = arStore.calibrations[index].data.artests[i]
        if (ret.result.Status === "OK")
          result = ret.result
      }
      arStore.setCurrentStatText(<OcvStat ocv={ocv} result={result} />)
    }
    var apply = (result.Status === 'OK') ? true : false
    if (!apply) result.Angle = []
    arStore.setCorrectAngle({
      h: result.Angle[0] ? result.Angle[0] : "?",
      v: result.Angle[1] ? result.Angle[1] : "?",
      t: result.Angle[2] ? result.Angle[2] : "?",
      Points: result.Points ? result.Points : [],
      apply: apply
    })
  }, [arStore.currentCalbAr, arStore.currentTestData])
  // arStore.currentTestData

  useEffect(() => {
    arStore.getAllArTest()
  }, [arStore.filter.startDate, arStore.filter.endDate])

  useEffect(() => {
    if (arStore.arTestList.projects.length > 0) {
      setProjects(arStore.arTestList.projects)
      arStore.setCurrentProjectId(arStore.arTestList.projects[0].id)
    }
  }, [arStore.arTestList.projects.length])

  useEffect(() => {
    var a = []
    arStore.arTestList.ar.forEach(ar => {
      if (ar.project.id == arStore.currentProjectId)
        a.push(ar)
    });
    setArByProjects(a)
    if (a.length > 0) {
      // capturesStore.setCameraData(false)
      arStore.setCurrentTestData(a[0])
    }
  }, [arStore.currentProjectId])

  useEffect(() => {
    setCaliByFilter(arStore.calibrations)
  }, [arStore.calibrations.length])

  useEffect(() => {
    if (!arStore.currentTestData) return
    setOptionCalbByAr(arStore.getCalByArTest)
    if (!arStore.currentCalibration) {
      arStore.setCurrentCalbAr('cal-0')
    } else {
      arStore.setCurrentCalbAr(arStore.currentCalibration.id)
      arStore.setCurrentCalibration(false)
    }
  }, [arStore.currentTestData, arStore.arPanel])


  const clickArTestItem = (data) => {
    arStore.setCurrentTestData(data)
  }

  const onChangeShow3DModel = (v) => {
    arStore.setShow3DModel(v)
  }

  const PannelHeader = () => {
    const changeProject = (value) => {
      arStore.setCurrentProjectId(value)
    }

    const dateFormat = 'YYYY/MM/DD';

    return (
      <div className="ar-sider-header" style={{
        paddingLeft: 10,
      }}>
        <Button
          size='default'
          type='primary'
          icon={<ExperimentOutlined />}
          onClick={() => {
            arStore.setArPanel('calibration')
            arStore.setCurrentTestData(false)
            if (arStore.calibrations.length > 0 && !arStore.currentCalibration)
              arStore.setCurrentCalibration(arStore.calibrations[0])
          }}
        />
        &nbsp;&nbsp; <strong>{t('project')}</strong> :
        <Select
          size={'default'}
          style={{ marginLeft: 10, marginRight: 10, width: 150 }}
          placeholder={t('select-project')}
          onChange={changeProject}
          value={(arStore.currentProjectId ? arStore.currentProjectId : "")}
        >
          {Projects.map(p => {
            return (
              <Option key={p.id} value={p.id}>{p.name}</Option>
            )
          })}
        </Select>

        &nbsp;&nbsp; <strong>{t('calibration')}</strong> :
        <Select
          size={'default'}
          style={{ marginLeft: 10, marginRight: 10, width: 200 }}
          placeholder={t('select-calibration')}
          onChange={(value) => {
            arStore.setCurrentCalbAr(value)
          }}
          value={arStore.currentCalbAr ? arStore.currentCalbAr : "cal-0"}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{ padding: '4px 8px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={() => {
                  setOpenSelectCalib(true)
                }}
              >
                <PlusOutlined /> &nbsp;{t('apply-new-calibration')}
              </div>
            </div>
          )}

        >
          <Option key="cal-0" value="cal-0">({t('default')})</Option>
          {OptionCalbByAr.map(t => {
            var index = arStore.calibrations.findIndex(x => x.id === t)
            if (index > -1)
              return (
                <Option key={t} value={t}>{arStore.calibrations[index].name}</Option>
              )
          })}
        </Select>

        &nbsp;&nbsp; <strong>{t('time')}</strong>:
        <RangePicker
          style={{ marginLeft: 10, marginRight: 20 }}
          defaultValue={[arStore.filter.startDate, arStore.filter.endDate]}
          format={dateFormat}
          onChange={(value) => {
            arStore.setFilter({
              startDate: value[0],
              endDate: value[1]
            })
          }}
        />
        <strong>{t('view-type')}</strong>:
        <Select
          size={'default'}
          style={{ margin: "10px 10px 0 10px", width: 220 }}
          placeholder={t('select-view-type')}
          defaultValue={arStore.viewType}
          onChange={(value) => {
            arStore.setViewType(value)
          }}
        >
          <Option key={'v-default'} value="default">{t('default')}</Option>
          <Option key={'v-matchline'} value="match_line">{t('match-lines')}</Option>
          <Option key={'v-opcatity'} value="opacity">{t('opacity-overlap')}</Option>
          <Option key={'v-filter'} value="filter">{t('match-line-distance-accuracy')}</Option>
        </Select>

        <Switch
          checkedChildren="First 3D Model"
          unCheckedChildren="Camera Image"
          defaultChecked={arStore.show3DModel}
          onChange={onChangeShow3DModel}
        />
        <Switch
          checkedChildren={t('apply-angle')}
          unCheckedChildren={t('unapply-angle')}
          defaultChecked={arStore.correctAngle.apply}
          onChange={(value) => {
            arStore.setCorrectAngle({ apply: value })
          }}
        />


      </div>
    )
  }

  const CalibrationHeader = () => {
    return (
      <div className="ar-sider-header" style={{
        // padding:8,
        height: 50,
        lineHeight: '50px',
        paddingLeft: 10,
        borderBottom: '1px #ccc'
      }}>
        <Button
          size='default'
          icon={<BackwardOutlined />}
          onClick={() => {
            arStore.setArPanel('default')
            if (arStore.arTestList && arStore.arTestList.ar.length > 0) {
              arStore.setCurrentCalibration(null)
              arStore.setCurrentTestData(arStore.arTestList.ar[0])
            }
            // arStore.setCurrentTestData(false)
            arStore.setViewType('match_line')
          }}
          style={{ marginRight: 8 }}
        />
        <Button
          size='default'
          // type="primary"
          icon={<PlusSquareOutlined />}
          style={{ marginRight: 10 }}
          onClick={() => {
            arStore.setOpenCVModel(true)
          }}
        >
          {t('calibration')}
        </Button>
        {arStore.currentCalibration ?
          <Button
            size='default'
            type="primary"
            icon={<PlusSquareOutlined />}
            onClick={() => {
              arStore.setOpenArTestModal(true)
            }}
          >
            {t('apply-many-test')}
          </Button>
          : ''}

      </div>
    )
  }

  const CalibrationContent = () => {
    const CItemText = ({ data }) => {
      if (!data) return ""
      return (
        <span
          style={{ color: (arStore.currentCalibration.id === data.id) ? "orange" : "#ccc" }}
        >
          {data.name}
        </span>
      )
    };

    const IconText = ({ icon, text, onClick }) => (
      <span onClick={onClick}>
        <span style={{ marginRight: 8 }}>{icon}</span>
        {text}
      </span>
    );

    const OpenCVDesc = ({ ocv }) => {
      return (
        <div style={{
          fontSize: 13,
          color: '#333'
        }}>
          Thresh <ValueStyle>{ocv.ratio_thresh}</ValueStyle> , Hessian <ValueStyle>{ocv.hessianThreshold}</ValueStyle> , Pixel Size <ValueStyle>{ocv.pixel_size}</ValueStyle> , Key rotation <ValueStyle>{ocv.key_rotation}</ValueStyle>
          <br />
          Angle Filter <ValueStyle>{ocv.angle_filter}</ValueStyle> , Distance filter <ValueStyle>{ocv.distance_filter}</ValueStyle> , Pixel Diff <ValueStyle>{ocv.pixel_diff}</ValueStyle>
          <br />
          Octaves <ValueStyle>{ocv.nOctaves}</ValueStyle> , O Layer <ValueStyle>{ocv.nOctaveLayers}</ValueStyle> , Extended <ValueStyle>{ocv.extended}</ValueStyle> , UpRight <ValueStyle>{ocv.upright}</ValueStyle>
          <br />
        </div>
      )
    }

    return (
      <List
        loading={arStore.isLoading}
        style={{
          position: 'absolute',
          padding: '8px 10px',
          overflow: 'auto',
          height: 'calc(100vh - 50px)',
          width: 450
        }}
        itemLayout="vertical"
        size="large"
        dataSource={CaliByFilter}
        renderItem={item => (
          <List.Item
            key={item.id}
            actions={[
              <IconText icon={<DeleteOutlined />} text={t('commons.delete')} key="delete-act"
                onClick={
                  () => {
                    arStore.deleteCalibration(item.id)
                  }
                }
              />,
            ]}
          >
            <List.Item.Meta
              style={{ cursor: 'pointer' }}
              onClick={() => {
                arStore.setCurrentCalibration(item)
              }}
              avatar={<Avatar size={24} icon={<CompassOutlined />} />}
              title={<CItemText data={item} />}
              description={<OpenCVDesc ocv={item.OpenCV} />}
            />
          </List.Item>
        )}
      />
    )
  }


  const ArList = () => {
    const ArItemText = ({ data }) => (
      <span
        style={{ color: (arStore.currentTestData.id === data.id) ? "orange" : "#ccc" }}
      >
        {data.name}
      </span>
    );

    const IconText = ({ icon, text, onClick }) => (
      <span onClick={onClick}>
        <span style={{ marginRight: 8 }}>{icon}</span>
        {text}
      </span>
    );

    return (
      <Spin spinning={arStore.isLoading} style={{ height: 'calc(100vh)' }}>
        <List
          style={{
            position: 'absolute',
            padding: '8px 10px',
            overflow: 'auto',
            height: 'calc(100vh - 50px)',
            width: 300
          }}
          itemLayout="vertical"
          size="large"
          dataSource={ArByProjects}
          renderItem={item => (
            <List.Item
              key={item.id}
              actions={[
                <IconText icon={<PlusSquareOutlined />} text={t('create-calibration')} key="new-act" onClick={
                  () => {
                    arStore.setCurrentTestData(item)
                    setOpenSelectCalib(true)
                  }
                } />,
                <IconText icon={<DeleteOutlined />} text={t('commons.delete')} key="delete-act" onClick={
                  () => {
                    arStore.deleteArTest(item.id).then(() => {
                      if (ArByProjects.length > 1) {
                        arStore.setCurrentTestData(ArByProjects[0])
                      } else {
                        arStore.setCurrentTestData(false)
                      }
                      setArByProjects(ArByProjects.filter(ar => ar.id !== item.id));
                    })
                  }
                } />,
              ]}
            >
              <List.Item.Meta
                style={{ cursor: 'pointer' }}
                onClick={() => { clickArTestItem(item) }}
                avatar={<Avatar style={{ border: "1px solid #ccc" }} shape="square" size={96} src={item.imageModelRender && item.imageModelRender.url ? item.imageModelRender.url : ""} />}
                title={<ArItemText data={item} />}
                description={moment(item.createdAt).format('lll')}
              />
            </List.Item>
          )}
        />
      </Spin>
    )
    // {ArByProjects.map(ar=>{

    // })}
  }


  const ArSidebar = ({ arPanel }) => {
    if (arPanel === 'calibration') {
      return (
        <>
          {/* <CalibrationHeader /> */}
          <CalibrationContent />
        </>
      )
    }
    // if (arPanel === 'test-detail') {
    //   return (
    //     <>
    //       {/* <TestDetailHeader /> */}
    //     </>
    //   )
    // }
    return (
      <>
        {/* <PannelHeader /> */}
        <ArList />
      </>
    )
  }

  const TopBar = () => {
    if (arStore.arPanel === 'calibration') {
      return (
        <>
          <CalibrationHeader />
        </>
      )
    }
    return (
      <>
        <PannelHeader />
      </>
    )
  }

  const onCancel = () => {
    setOpenSelectCalib(false)
  }

  const onCreate = () => {

    arStore.updateMassCalibration(
      SelectedCalb,
      [arStore.currentTestData.id]
    ).then((data) => {
      if (data) {
        // const index = ArByProjects.findIndex(x => x.id === arStore.currentTestData.id)
        // if (index < 0) return
        // let ars = [...ArByProjects]
        // if (!ars[index]) return
        // if (!ars[index].cal) ars[index].cal = []
        // ars[index].cal.push(SelectedCalb)
        // setArByProjects(ars)
        // setOptionCalbByAr(ars[index].cal)
        setOptionCalbByAr(arStore.getCalByArTest)
        arStore.setCurrentCalbAr(SelectedCalb)
      }
      setOpenSelectCalib(false)
    })
    // arStore.setOpenCVModel(false)
    // var newItem = arStore.createCalibration({
    //   name:PresetName,
    //   OpenCV: Ocv,
    //   data: defaultOcv.data
    // })
  }

  function onChange(value) {
    setSelectedCalb(value)
  }

  return (
    <Layout id="artest-page">
      <Modal
        id="select-calb-modal"
        style={{
          width: 350
        }}
        visible={openSelectCalib}
        title={t('select-calibration-preset-to-apply')}
        okText={t('apply-with-this-preset')}
        cancelText={t('commons.cancel')}
        onCancel={onCancel}
        onOk={onCreate}
        footer={
          <Fragment>
            <Button key="back" onClick={onCancel}>
              {t('commons.cancel')}
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={arStore.isLoading}
              onClick={onCreate}>
              {t('commons.create')}
            </Button>
          </Fragment>
        }>
        <div id="select-calb-control">
          {t('name-param-preset')}:
          <br />
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select Preset"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {arStore.calibrations.map(cal => {
              if (!OptionCalbByAr.includes(cal.id))
                return (
                  <Option key={cal.id} value={cal.id}>{cal.name}</Option>
                )
            })}
          </Select>
          <br />
          <br />
        </div>
      </Modal>
      <Layout>
        <Header>
          <TopBar />
          {/* <Breadcrumb >
            <Breadcrumb.Item style={{ fontWeight: 'bold' }}>{'Project: ' + (arStore.currentTestData ? arStore.currentTestData.project.name : "Loading ..")}</Breadcrumb.Item>
            <Breadcrumb.Item>{arStore.currentTestData ? arStore.currentTestData.name : "Loading .."}</Breadcrumb.Item>

          </Breadcrumb> */}
        </Header>
        <Layout style={{ padding: 0 }}>

          <Sider width={arStore.arPanel === 'calibration' ? 450 : 300} style={{ background: '#fff' }}>
            <ArSidebar arPanel={arStore.arPanel} />
          </Sider>

          <Content
            style={{
              background: '#fff',
              padding: 0,
              margin: 0,
              minHeight: 280,
            }}>

            {children}
          </Content>
        </Layout>

      </Layout>
      {/* {
        checkLoading()
          ? <LoadingSpinner type={'page'} theme={commonStore.appTheme} />
          : null
      } */}
    </Layout>
  )
}

export default inject('projectStore', 'usersStore', 'arStore', 'commonStore')(observer(ArTemplate))