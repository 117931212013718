import React, { Fragment } from 'react';
import { CloseCircleOutlined, MailOutlined, SaveOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import '../style.css';

const AddUser = (props) => {
    const { handleAddFieldsFormModal, hanldeReloadFromModal, handleCloseModal, usersStore } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    //Get detail user display to selected item
    const getUserById = (id) => {
        usersStore.getUserById(id).then(res => {
            handleAddFieldsFormModal(res)
            usersStore.setCurrentUserViewDetail(res)
        }).catch(err => {
            console.log(err)
            message.error(t('an-error-occurred'))
        })
    }

    // Create an user at modal
    const handleCreateNewUser = (values) => {
        let param = {
            email: values.email.trim().toLowerCase(),
            username: values.username.trim().toLowerCase(),
            password: Math.random().toString(36).slice(-8)
        }
        usersStore.createUser(param)
            .then(res => {
                getUserById(res.id)
                message.success(t('create-user-successfully'))
                form.resetFields()
                // hanldeReloadFromModal(true)
            })
            .catch(error => {
                if (Array.isArray(error?.data?.data[0]?.messages)) {
                    let v = error?.data?.data[0]?.messages[0]?.message || t('something-went-wrong')
                    if (v) message.error(t(v))
                } else {
                    message.error(t('something-went-wrong'))
                }
            })
    }
    return (
        <Form
            form={form}
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={value => handleCreateNewUser(value)}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        label={t('username')}
                        name="username"
                        rules={[{ required: true, message: t('please-input-your-username') }]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="email" label={t('email')} rules={[{ type: 'email', required: true, message: t('please-input-your-email') }]}>
                        <Input
                            prefix={<MailOutlined className="site-form-item-icon" />}
                            type="email"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row
                justify="end"
            >
                <Button
                    onClick={() => handleCloseModal(false)}
                    icon={<CloseCircleOutlined />}
                >
                    {t('commons.cancel')}
                </Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "10px" }}
                    loading={usersStore.isLoading}
                    icon={<SaveOutlined />}
                >
                    {t('commons.create')}
                </Button>
            </Row>
        </Form>
    )
}
export default inject("usersStore")(observer(AddUser));
