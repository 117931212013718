import { observable, action, decorate } from 'mobx'
import { ProjectRequest, ObjectInforRequest } from '../requests';
class ReportStore {
  isLoading = false
  isEdittingTableReport = false
  showReportRules = false
  reportToolSettings = []

  setShowReportRules = status => {
    this.showReportRules = status
  }

  setReportToolSettings = data => {
    this.reportToolSettings = data
  }
  
  setLoadingProgress = state => {
    this.isLoading = state
  }

  setEdittingTableReport = state => {
    this.isEdittingTableReport = state
  }

  /**
* Calculate project attribute report
* @param {*} projectId project id
* @param {*} body param query , object {rowrules,columns }
*/
  async calculateReportingTool(projectId, body) {
    return new Promise((resolve, reject) => {
      ProjectRequest.calculateReportingTool(projectId, body).then(response => {
        resolve(response.data);
      })
        .catch(err => {
          reject(err)
        })
    })
  }

  /**
   * Calculate project attribute report
   * @param {*} projectId project id
   * @param {*} body param query , object {rowrules,columns }
   */
  async calculateObjectInfo(projectId, body, cancelToken) {
    return new Promise((resolve, reject) => {
      ObjectInforRequest.calculateObjectInfo(projectId, body, cancelToken).then(response => {
        resolve(response.data);
      })
        .catch(err => {
          reject(err)
        })
    })
  }

  getCalculationSettings = async (id) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.getCalculationSettings(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error);
          reject(error)
        })
    })
  }

  async updateProjectData(projectId,data) {
    return new Promise((resolve, reject) => {
      if (projectId) {
        ProjectRequest.updateProject(projectId, data)
          .then((response) => {
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      }
      else reject()
    })
  }

}

decorate(ReportStore, {
  isLoading: observable,
  setLoadingProgress: action,
  isEdittingTableReport: observable,
  setEdittingTableReport: action,
  setShowReportRules: action,
  showReportRules: observable,
  calculateReportingTool: action,
  calculateObjectInfo: action,
  reportToolSettings  :observable,
  setReportToolSettings  :action,
  getProjectDetail  :action,
  updateProjectData : action
})
export default new ReportStore()