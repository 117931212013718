import { Avatar, Button, Tooltip } from 'antd'
import { inject, observer } from "mobx-react"
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as SketchArea } from '../../../../assets/svgs/Sketch_Area.svg'
import { ReactComponent as SketchLine } from '../../../../assets/svgs/Sketch_Line.svg'
import { ReactComponent as SketchSingle } from '../../../../assets/svgs/Sketch_Single.svg'
import { ReactComponent as SketchVolume } from '../../../../assets/svgs/Sketch_Volume.svg'
import SVGIcon from '../../../elements/SVGIcon'
import { SketchItem } from './CustomStyled'

import { useTranslation } from 'react-i18next'
import Utils from '../../../../utils'

const SketchTool = props => {
  const { commonStore, sketchingStore ,projectStore} = props
  const { t } = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const onClickSketchTool = modeId => {
    sketchingStore.setCurrentSketchId(false)
    sketchingStore.setSketchMode(modeId)
    sketchingStore.setSketchToolVisible(!sketchingStore.sketchToolVisible)
    sketchingStore.setDrawMode(true)
    projectStore.setGlobeBehind(false)
  }

  return (
    <>
      <SketchItem>
        <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('single')}>
          <Button
            type="link"
            onClick={() => {
              projectStore.setCurrentHelpfeature('add_sketch')
              onClickSketchTool('point')
              sketchingStore.setSketchProps({
                color: { color: '#ffffff', alpha: 0.5 },
                name: Utils.generateSketchNameByType(sketchingStore.arrSketches,'point')
              })
              sketchingStore.setSketchProps({ extrudedHeight: 1 })
              sketchingStore.setSketchProps({ width: 1 })
              sketchingStore.setSketchProps({ rotation: 0 })
            }}
            size={commonStore.buttonSize}>
            <Avatar
              style={{ background: 'none' }}
              shape="square"
              icon={<SVGIcon className='sketchIcon' content={<SketchSingle />} />}
            />
          </Button>
        </Tooltip>
      </SketchItem>
      <SketchItem>
        <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('line')}>
          <Button
            type="link"
            onClick={() => {
              projectStore.setCurrentHelpfeature('add_sketch')
              sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
              sketchingStore.setSketchProps({ width: 0.5 })
              sketchingStore.setSketchProps({
                color: { color: '#ffffff', alpha: 0.5 },
                name: Utils.generateSketchNameByType(sketchingStore.arrSketches,'line')
              })
              onClickSketchTool('line')
            }}
            size={commonStore.buttonSize}>
            <Avatar
              style={{ background: 'none' }}
              shape="square"
              icon={<SVGIcon className='sketchIcon' content={<SketchLine />} />}
            />
          </Button>
        </Tooltip>
      </SketchItem>
      <SketchItem>
        <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('area')}>
          <Button
            type="link"
            onClick={() => {
              projectStore.setCurrentHelpfeature('add_sketch')
              sketchingStore.setSketchProps({ height: 0 })
              sketchingStore.setSketchProps({ extrudedHeight: 0 })
              sketchingStore.setSketchProps({ width: 0 })
              sketchingStore.setSketchProps({ readonlyHeight: true })
              sketchingStore.setSketchProps({
                color: { color: '#ffffff', alpha: 0.5 },
                name: Utils.generateSketchNameByType(sketchingStore.arrSketches,'area')
              })
              onClickSketchTool('area')
            }}
            size={commonStore.buttonSize}>
            <Avatar
              style={{ background: 'none' }}
              shape="square"
              icon={<SVGIcon className='sketchIcon' content={<SketchArea />} />}
            />
          </Button>
        </Tooltip>
      </SketchItem>
      <SketchItem>
        <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('volume')}>
          <Button
            type="link"
            onClick={() => {
              projectStore.setCurrentHelpfeature('add_sketch')
              sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
              sketchingStore.setSketchProps({ width: 0 })
              sketchingStore.setSketchProps({ readonlyHeight: false })
              sketchingStore.setSketchProps({
                color: { color: '#ffffff', alpha: 0.5 },
                name: Utils.generateSketchNameByType(sketchingStore.arrSketches,'area', true)
              })
              onClickSketchTool('area')
            }}
            size={commonStore.buttonSize}>
            <Avatar
              style={{ background: 'none' }}
              shape="square"
              icon={<SVGIcon className='sketchIcon' content={<SketchVolume />} />}
            />
          </Button>
        </Tooltip>
      </SketchItem>
      {/* <SketchItem>
        <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('Label')}>
          <Button
            type="link"
            onClick={() => {
              sketchingStore.setSketchProps({ sizeLabel: 50 })
              sketchingStore.setSketchProps({ width: 0 })
              sketchingStore.setSketchProps({ height: 100})
              sketchingStore.setSketchProps({ font: 'Arial' })
              sketchingStore.setSketchProps({
                color: { color: '#ffffff', alpha: 0.5 },
                name: Utils.generateSketchNameByType(sketchingStore.arrSketches,'label')
              })
              onClickSketchTool('label')
            }}
            size={commonStore.buttonSize}>
              <Avatar
                style={{ background: 'none' }}
                shape="square"
                icon={<SVGIcon className='sketchIcon testIcon' content={<SketchPoint />}/>}
              />
          </Button>   
        </Tooltip>
      </SketchItem> */}
    </>
  )
}

export default inject(
  'commonStore',
  'projectStore',
  'sketchingStore',
  'projectStore'
)(observer(SketchTool))