import styled from 'styled-components';
import { size } from 'lodash';

export const CustomTopDashBoardItem = styled.div`
  height:100%;
  padding: 20px;
  box-shadow:  0 1px 3px rgba(5, 5, 6, 0.15) ;
  &:hover{
      box-shadow : 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) ;
  }
`
export const CustomCount = styled.div`
color: #000000;
font-size : 20px;
font-weight: 600;
`

export const CustomPercent = styled.div`
color: #000000;
font-size : 20px;
font-weight: 600;
`
export const ProjectDashBoardDrawerWraper = styled.div`
width :100px;
height : 100px;
background-color : red;
`
export const CustomSplitMobile = styled.div`
position : absolute;
top : 50%;
left : -16px;
z-index: 999;
`
export const TopDashBoardWraper = styled.div`
padding  : 40px;
background-color : #fff;
@media (max-width: 768px) {
  padding  : 20px;
  }
`

export const ProjectDashBoardHeader = styled.div`
display : flex;
flex-wrap : wrap;
justify-content: space-between;
padding  : 0px 40px;
background-color : #fff;
.project-title{
  display: flex;
  justify-content: flex-start;
  .title_txt,
  .title_name {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
  }
  .title_name {
    padding-left: 10px;
  }
}
@media (max-width: 768px) {
  padding  : 10px 40px;
  }
`