import React, { useEffect, useState } from 'react'
import { Modal, Input } from 'antd'
import { Trans, useTranslation } from 'react-i18next';

function HttpLinkNameModal({ setHttpLinkName, setShowHttpLinkModal, showHttpLinkModal }) {
    const { t } = useTranslation();
    const [httpLinkValue, setHttpLinkValue] = useState()

    const onChangeModel = (e) => {
        setHttpLinkValue(e.target.value)
    }

    const handleOkMeshModal = () => {
        setHttpLinkName(httpLinkValue)
        setShowHttpLinkModal(false)
        setHttpLinkValue()
    }

    const handleHiddenMeshModal = () => {
        setHttpLinkValue()
        setHttpLinkName()
        setShowHttpLinkModal(false)
    }

    return (
        <Modal
            title={false}
            visible={showHttpLinkModal}
            onOk={handleOkMeshModal}
            onCancel={handleHiddenMeshModal}
            okText={t('commons.ok')}
            cancelText={t('commons.cancel')}
            zIndex={10001}
            maskClosable={false}
        >
            <h2>{t('http-link-name')}</h2>
            <Input value={httpLinkValue} onChange={onChangeModel} />
        </Modal>
    )
}

export default HttpLinkNameModal
