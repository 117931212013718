import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import cesiumNavMixin from '../../../assets/cesium-navigation/index'
import '../../../assets/cesium-navigation/index.css'
import { assetUrl, bingMapKey } from '../../../config'
import { notification, Icon, Row, Col } from 'antd'
import uuid from 'uuid'
import Gps from '../../helper/Gps'

import {
  getCurrentModel,
  findModelByUrl,
  hpr4ZoomTo,
  getLocFromMatrix,
  transformFromInput,
  getModelUrBySrc,
  calculateWH,
  rotateCamera
} from '../../helper/CesiumUtils'
import { Viewer, Cesium3DTileset, SkyAtmosphere, SkyBox, ImageryLayer, Scene , KmlDataSource} from 'resium'

import {
  Matrix4,
  IonResource,
  Cartesian3,
  Cesium3DTileColorBlendMode,
  Cesium3DTileStyle,
  Color,
  Math as CesiumMath,
  BingMapsImageryProvider,
  BingMapsStyle,
  BoundingSphere,
  Cartographic,
  PinBuilder
} from 'cesium'
import moment from 'moment'

import settings from '../../../siteConfig'
import { useMediaQuery } from 'react-responsive'

const ArModelViewer = props => {
  const {
    commonStore,
    projectStore,
    capturesStore,
    schedulingStore,
    arStore,
    containerWidth,
    containerHeight,
    uiStore
  } = props
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const viewerRef = useRef(null)
  const webcamRef = useRef(null)
  const arDivContainer = useRef(null)
  const [tileViews, setTileViews] = useState([])
  const [gpsView, setGpsView] = useState(false)
  const [IsArVertical, setIsArVertical] = useState(false)
  const [ViewerStyle, setViewerStyle] = useState({})
  // const [ViewerWidth, setViewerWidth] = useState('auto')
  // const [ViewerHeight, setViewerHeight] = useState('auto')

  // const [videoStreamList, setvideoStreamList] = useState([])

  // const Sentinel2 = new IonImageryProvider({ assetId: 3954 })
  const onTileLoad = tile => {
    var model = projectStore.findModelByUrl(tile.url)
    let noTrans = false
    if (model) {
      var saveTrans = tile.root.transform.clone()
      if (Matrix4.equals(saveTrans, Matrix4.IDENTITY)) {
        noTrans = true
      }
      if (!model.crs) model.crs = {}
      var pCenter = Cartographic.fromCartesian(tile.boundingSphere.center)
      var m = getLocFromMatrix(saveTrans)
      var centerXYZ = tile.boundingSphere.center.clone()
      if (model.crs.bbox) {
        var localPoint = Cartesian3.fromArray([model.crs.bbox[0], model.crs.bbox[1], 0])
        centerXYZ = Cartesian3.subtract(centerXYZ, localPoint, new Cartesian3())
      } else {
        model.crs.bbox = [0, 0, 0]
      }
      var center = Cartographic.fromCartesian(centerXYZ)
      center.height = pCenter.height - model.crs.bbox[2]
      if (model.sourceType == 'external') {  
        center = Cartographic.fromCartesian(tile.boundingSphere.center)
      }
      if (!model.crs.firstLoc) {
        // model.crs.orignalTransform = saveTrans
        model.crs.firstLoc = m
        model.crs.saveLoc = m
        // model.crs.saveLoc.heading = m.heading * CesiumMath.DEGREES_PER_RADIAN
        model.crs.saveLoc.heading = 0
        model.crs.saveLoc.pitch = 0
        model.crs.saveLoc.roll = 0
        // model.crs.saveLoc.pitch = m.pitch * CesiumMath.DEGREES_PER_RADIAN
        // model.crs.saveLoc.roll = m.roll * CesiumMath.DEGREES_PER_RADIAN
        if (model.crs.saveLoc.height === false) {
          model.crs.saveLoc.lat = 0
          model.crs.saveLoc.lng = 0
          model.crs.firstLoc.height = 0
          if (center) {
            model.crs.firstLoc.lat = center.latitude * CesiumMath.DEGREES_PER_RADIAN
            model.crs.firstLoc.lng = center.longitude * CesiumMath.DEGREES_PER_RADIAN
            model.crs.saveLoc.lat = center.latitude * CesiumMath.DEGREES_PER_RADIAN
            model.crs.saveLoc.lng = center.longitude * CesiumMath.DEGREES_PER_RADIAN
            model.crs.firstLoc.height = center.height
          }

          if (model.crs.initPos && model.data.ionAssetId) {
            model.crs.saveLoc.lat = model.crs.initPos.cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN
            model.crs.saveLoc.lng = model.crs.initPos.cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN
            model.crs.firstLoc.lat = model.crs.saveLoc.lat
            model.crs.firstLoc.lng = model.crs.saveLoc.lng
            model.crs.firstLoc.height = model.crs.initPos.cartographic.height
            // model.crs.saveLoc.height = 0      
            // model.crs.firstLoc.height = 0   
          }
        }

        model.crs.saveLoc.height = 0
      }
      if ((!noTrans) || (model.sourceType === 'local')) {
        tile.modelMatrix = transformFromInput(model.crs.saveLoc, model.crs.firstLoc)
        tile.root.transform = Matrix4.IDENTITY
        model.noTrans = false
      } else {
        model.noTrans = true
        tile.modelMatrix = transformFromInput(model.crs.saveLoc, model.crs.firstLoc, true)
      }
    }
  }

  const onClickTileset = (m, tile) => {
  }
  const onClickFeature = (tile, model) => {
    if (tile && tile.id) {
        const attrData = {
            pTitle: model.data.layers,
            pKey: model.id,
            KmlDataSource: true,
            model3D: model.id,
            children: []
        };

        const id = tile.id._id ? tile.id._id : '';
        attrData.children.push({
            key: uuid(),
            title: `Feature Id = ${id}`
        })

        const propertyNames = tile.id.properties && tile.id.properties.propertyNames ? tile.id.properties.propertyNames : []
        for (let i = 0; i < propertyNames.length; ++i) {
            const name = propertyNames[i];
            const value = tile.id.properties[name]._value;
            if (value) {
                attrData.children.push({
                    key: uuid(),
                    title: `${name} = ${value}`
                })
            }
        }
        uiStore.setShowAttrPanel(true);
        projectStore.setSelectedAttrData(attrData);
    }
}

  const TilesetByModel = model => {
    if (model.ref) return
    if (!model.isRender) return
    model.ref = true
    const mref = React.createRef()
    let maximumScreenSpaceError = model.data.maximumScreenSpaceError || 1
    let maximumNumberOfLoadedTiles =
      model.data.maximumNumberOfLoadedTiles || 1000

    // var modelMatrix = Transforms.eastNorthUpToFixedFrame(
    //   new Cartesian3.fromDegrees(0, 0)
    // )

    // if (model.crs && model.crs.initPos) {
    //   if (model.crs.initPos.oldPos)
    //     modelMatrix = Transforms.eastNorthUpToFixedFrame(
    //       model.crs.initPos.oldPos
    //     )
    // }
    // if (model.crs) {
    //   if (model.crs.trans) {
    //     console.log('Doing...')
    //   }
    //   if (model.crs.modelMatrix) {
    //     modelMatrix = Matrix4.fromRowMajorArray(
    //       model.crs.modelMatrix,
    //       new Matrix4()
    //     )
    //   }
    // }
    let tileKey = model.id + '-tile'
    switch (model.type) {
      case 'landxmlBackground':
      case 'landxml':
        setTileViews(tileViews => [
          ...tileViews,
          <Cesium3DTileset
            prop
            key={tileKey}
            url={assetUrl + model.hash + '/tileset.json'}
            ref={mref}
            onReady={onTileLoad}
            onClick={onClickTileset}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.MIX}
          // onAllTilesLoad={() => {
          //   setAllTilesLoaded(allTilesLoaded => [...allTilesLoaded, model.id])
          // }}
          />,
        ])
        return
      case 'ifc':
        // let ifc_uri = apiUrl + '/assets/output/' + model.hash + '.glb'
        setTileViews(tileViews => [
          ...tileViews,
          <Cesium3DTileset
            key={tileKey}
            url={assetUrl + model.hash + '/tileset.json'}
            ref={mref}
            onReady={onTileLoad}
            onClick={onClickTileset}
            // modelMatrix={Transforms.eastNorthUpToFixedFrame(tilePosition)}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.MIX}
          // onAllTilesLoad={() => {
          //   setAllTilesLoaded(allTilesLoaded => [...allTilesLoaded, model.id])
          // }}
          />,
        ])
        return
      case 'cad':
        let v = model.data?.api_ver
        let cad_uri = v ===3 ? '/ModelTileset.json' : '/tileset.json'
        setTileViews(tileViews => [
          ...tileViews,
          <Cesium3DTileset
            key={tileKey}
            url={assetUrl + model.hash + cad_uri}
            ref={mref}
            onReady={onTileLoad}
            onClick={onClickTileset}
            // modelMatrix={Transforms.eastNorthUpToFixedFrame(tilePosition)}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.MIX}
          // onAllTilesLoad={() => {
          //   setAllTilesLoaded(allTilesLoaded => [...allTilesLoaded, model.id])
          // }}
          />,
        ])
        return
      case 'kmz':
        setTileViews([
          ...tileViews,
          <KmlDataSource 
            key={tileKey}
            ref={mref}
            data={model.src}
            onReady={onTileLoad}
            onClick={(e) => {
              var pickedObject = viewerRef.current.cesiumElement.scene.pick(e.position)
              onClickFeature(pickedObject, model)
            }}
            clampToGround={model.data.clampToGround === true}
            colorBlendMode={Cesium3DTileColorBlendMode.MIX}
          />,
        ])

        return
      case 'model3d':
      case 'cloudpoint':
        if (model.data.srcTileset) {
          setTileViews(tileViews => [
            ...tileViews,
            <Cesium3DTileset
              key={tileKey}
              url={model.data.srcTileset}
              ref={mref}
              onReady={onTileLoad}
              onClick={onClickTileset}
              colorBlendAmount={0.7}
              colorBlendMode={Cesium3DTileColorBlendMode.MIX}
            // onAllTilesLoad={() => {
            //   setAllTilesLoaded(allTilesLoaded => [...allTilesLoaded, model.id])
            // }}
            />,
          ])
        }
        return
      case 'e57':
        if (model.data.srcTileset) {
          setTileViews(tileViews => [
            ...tileViews,
            <Cesium3DTileset
              key={tileKey}
              url={model.data.srcTileset}
              ref={mref}
              onReady={onTileLoad}
              onClick={onClickTileset}
              colorBlendAmount={0.7}
              colorBlendMode={Cesium3DTileColorBlendMode.MIX}
            // onAllTilesLoad={() => {
            //   setAllTilesLoaded(allTilesLoaded => [...allTilesLoaded, model.id])
            // }}
            />,
          ])
        }
        return
      default:
        break
    }
    if (model.sourceType === 'external') {
      if (model.data.ionAssetId) {
        setTileViews(tileViews => [
          ...tileViews,
          <Cesium3DTileset
            key={tileKey}
            ref={mref}
            url={IonResource.fromAssetId(model.data.ionAssetId)}
            onClick={onClickTileset}
            colorBlendAmount={0.7}
            colorBlendMode={Cesium3DTileColorBlendMode.MIX}
            maximumScreenSpaceError
            maximumNumberOfLoadedTiles
            onReady={onTileLoad}
          // onAllTilesLoad={() => {
          //   setAllTilesLoaded(allTilesLoaded => [...allTilesLoaded, model.id])
          // }}
          />,
        ])
      } else {
        var tUrl = getModelUrBySrc(model.src)
        setTileViews(tileViews => [
          ...tileViews,
          <Cesium3DTileset
            key={tileKey}
            ref={mref}
            url={tUrl}
            maximumScreenSpaceError
            maximumNumberOfLoadedTiles
            onClick={onClickTileset}
            onReady={onTileLoad}
          // onAllTilesLoad={() => {
          //   setAllTilesLoaded(allTilesLoaded => [...allTilesLoaded, model.id])
          // }}
          />,
        ])
      }
    }
  }

  useEffect(() => {
    if (arStore.currentProjectId) {
      projectStore.setModelList([])
      projectStore.clearProjectDetail()
      projectStore.setOpenCVData({
        id: 0,
        angle: 999,
        apply: false,
      })
      projectStore.setCombineBoundingSpheres([])
      setTileViews([])

      // if (viewerRef.current)
      //   viewerRef.current.cesiumElement.entities.removeAll();
      projectStore.getProjectDetail(arStore.currentProjectId)
    }
  }, [arStore.currentProjectId])

  useEffect(() => {
    window.scrollTo(0, 0)
    setTileViews([])
    capturesStore.setCameraData(false)
    commonStore.setCurrentPage('arData')
    if (viewerRef.current) {
      var options = {}
      options.enableZoomControls = false
      viewerRef.current.cesiumElement.extend(cesiumNavMixin, options)
      viewerRef.current.cesiumElement.scene.globe.show = false
    }
    return () => {
      projectStore.setCurrentModelId(false)
      projectStore.setCurrentTile(false)
      projectStore.setGpsMode('none')
      projectStore.setSkyColor('none')
      projectStore.setViewMode('Default mode')
      projectStore.setModelList([])
      projectStore.set2D(false)
      projectStore.clearProjectDetail()
      projectStore.setOpenCVData({
        id: 0,
        angle: 999,
        apply: false,
      })     
      projectStore.setCombineBoundingSpheres([])
      projectStore.setCurrentGPS({})
      setTileViews([])
    }
  }, [])

  useEffect(() => {
    if (arStore.correctAngle.apply) {
      // if (arStore.correctAngle.h != '?') {
      //   rotateCamera(viewerRef.current.cesiumElement, arStore.correctAngle.h, arStore.correctAngle.v)
      // }
      rotateCamera(viewerRef.current.cesiumElement, -arStore.correctAngle.h, -arStore.correctAngle.v)
    } else {
      if (arStore.currentTestData)
        capturesStore.setCameraData(arStore.currentTestData.modelRenderInfo.CesiumCameraData)
    }
  }, [arStore.correctAngle.apply, arStore.correctAngle.h, arStore.correctAngle.v])

  useEffect(() => {
    if (arStore.currentTestData) {
      let cam = arStore.currentTestData.modelRenderInfo.CesiumCameraData
      let flyOption = {
        duration: cam.duration !== 'undefined' ? cam.duration : 1,
      }
      if (cam.position) {
        let destination = new Cartesian3(
          cam.position.x,
          cam.position.y,
          cam.position.z
        )
        flyOption.destination = destination
        // setFly(position)
      }

      if (cam.direction) {
        let direction = new Cartesian3(
          cam.direction.x,
          cam.direction.y,
          cam.direction.z
        )
        let up = new Cartesian3(cam.up.x, cam.up.y, cam.up.z)
        flyOption.orientation = {
          direction,
          up,
        }
      }
      viewerRef.current.cesiumElement.camera.flyTo(flyOption)


      if (arStore.correctAngle.apply) {
        rotateCamera(viewerRef.current.cesiumElement, -arStore.correctAngle.h, -arStore.correctAngle.v)
      }
    }
  }, [arStore.currentStatText])


  useEffect(() => {
    if (arStore.arTestList && arStore.arTestList.ar.length > 0) {
      arStore.setCurrentTestData(arStore.arTestList[0])
    }
    // return () => {

    // }
  }, [arStore.arTestList.length])

  useEffect(() => {
    if (arStore.currentTestData) {
      let csize = arStore.currentTestData.modelRenderInfo.CanvasSize
      setIsArVertical((csize.height > csize.width))
      var s = calculateWH(csize.width, csize.height, containerWidth / 2, containerHeight)
      setViewerStyle(s)
      capturesStore.setCameraData(arStore.currentTestData.modelRenderInfo.CesiumCameraData)
    }
  }, [arStore.currentTestData])

  useEffect(() => {
    if (!capturesStore.cameraData) return
    if (!viewerRef.current) return
    if (!viewerRef.current.cesiumElement) return
    let cam = capturesStore.cameraData
    let flyOption = {
      duration: cam.duration !== 'undefined' ? cam.duration : 1,
    }
    if (cam.position) {
      let destination = new Cartesian3(
        cam.position.x,
        cam.position.y,
        cam.position.z
      )
      flyOption.destination = destination
      // setFly(position)
    }

    if (cam.direction) {
      let direction = new Cartesian3(
        cam.direction.x,
        cam.direction.y,
        cam.direction.z
      )
      let up = new Cartesian3(cam.up.x, cam.up.y, cam.up.z)
      flyOption.orientation = {
        direction,
        up,
      }
    }
    viewerRef.current.cesiumElement.camera.flyTo(flyOption)
    capturesStore.setCameraData(false)
    // let fly = {
    //   destination: position,
    //   orientation: {
    //     direction: direction,
    //   },
    // }
  }, [capturesStore.cameraData])

  useEffect(() => {
    projectStore.setNewModelId(0)
    if (projectStore.modelList.length > 0) {
      projectStore.setLoadingProgress(true)
      projectStore.modelList.forEach(TilesetByModel)
      projectStore.setLoadingProgress(false)
    }
  }, [projectStore.modelList])

  useEffect(() => {
    if (projectStore.zoomToModel) {
      let cm = getCurrentModel(
        projectStore.zoomToModel,
        projectStore.modelList,
        tileViews
      )
      if (viewerRef.current) {
        try {
          const viewer = viewerRef.current.cesiumElement
          if (cm.tile) {
            if (projectStore.displayPanel != '')
              var hpr = hpr4ZoomTo(viewer, cm.tile, true)
            viewer.zoomTo(cm.tile, hpr)
          }
        } catch (error) {
          console.log('error', error)
          alert('Data is not ready')
        }
      }
      projectStore.setZoomToModel(false)
    }
  }, [projectStore.zoomToModel])

  useEffect(() => {
    if (projectStore.gpsMode !== 'none')
      setGpsView(
        <Gps
          viewer={viewerRef}
          webcamRef={webcamRef}
          projectid={props.match.params.projectId}
        />
      )
    else setGpsView(false)
  }, [projectStore.gpsMode])

  useEffect(() => {
    if (projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0) {
      const visibleTileViewIds = projectStore.visibleTilesets.map(item => {
        if (item.isVisible) {
          return item.modelId + '-tile'
        }
      })

      tileViews.forEach(tileView => {
        const tileElement = tileView.ref.current.cesiumElement
        // tileElement.show = visibleTileViewIds.indexOf(tileView.key) > -1

        if (!(visibleTileViewIds.indexOf(tileView.key) > -1)) {
          //tileElement.style.color = Color.TRANSPARENT;
          tileElement.style = new Cesium3DTileStyle({
            pointSize: 4,
            color: "color('white', 0)",
            show: true,
          })
        } else {
          tileElement.style = new Cesium3DTileStyle({
            pointSize: 4,
          })
        }
      })
    }
  }, [projectStore.visibleTilesets])

  const defaultContextOption = useMemo(() => {
    var context = {
      webgl: {
        alpha: true,
      },
    }

    return context
  }, [])


  const defaultImagery = useMemo(async () => {
    // const Sentinel2 = new IonImageryProvider({ assetId: 3954 })
    const bing = await BingMapsImageryProvider('https://dev.virtualearth.net', {
      key: bingMapKey,
      mapStyle: BingMapsStyle.AERIAL
    });

    return bing
  }, [])

  // const terrainProvider = createWorldTerrain()
  // const terrainProvider = new EllipsoidTerrainProvider();

  // const terrainProvider = new EllipsoidTerrainProvider({
  //   tilingScheme : new WebMercatorTilingScheme()
  // });


  return (
    <React.Fragment>
      <Viewer
        // sceneModePicker={false}
        timeline={false}
        homeButton={false}
        navigationInstructionsInitiallyVisible={false}
        fullscreenButton={false}
        geocoder={false}
        navigationHelpButton={false}
        selectionIndicator={false}
        infoBox={false}
        contextOptions={defaultContextOption}
        imageryProvider={defaultImagery}
        style={ViewerStyle}
        // terrainProvider={terrainProvider}
        // style={{ position: 'block', width:250, height:500 }}
        // style={{
        //   position: 'block',
        //   width: canvasWidth,
        //   height: canvasHeight,
        //   // left: '50%',
        //   // top: '50%',
        //   // transform: 'translate(-50%, -50%)'
        // }}
        ref={viewerRef}>
        <SkyBox show={false} />
        <SkyAtmosphere show={false} />
        <Scene backgroundColor={Color.WHITE} />
        {tileViews.map(tile => tile)}
        {gpsView}
      </Viewer>
    </React.Fragment>
  )
}

export default inject(
  'commonStore',
  'projectStore',
  'schedulingStore',
  'capturesStore',
  'arStore',
  'uiStore'
)(observer(ArModelViewer))
