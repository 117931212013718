import { inject, observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {  PlusOutlined } from '@ant-design/icons'

const UploadButton = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <PlusOutlined />
            <div className="ant-upload-text">{t('upload')}</div>
        </Fragment>
    )
};

export default inject('projectStore')(observer(UploadButton))