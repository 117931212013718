import { CloseCircleOutlined, ColumnWidthOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, message, Skeleton, Switch, Table, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { TableContentWrapper, TeamsPageWrapper } from './style';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import HelpButton from '../../../elements/HelpButton';

import settings from '../../../../siteConfig';
import { toJS } from 'mobx';

const DrawerFeatureSetting = ({ projectTeamsStore, projectStore, projectSettingStore, adminStore }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [customData, setCustomData] = useState([])
    const [form] = Form.useForm();
    const { projectId } = useParams()
    const [dataTable, setDataTable] = useState([]);
    const [dataColumn, setDataColumn] = useState([]);
    const [loadingtable, setLoadingtable] = useState(false);
    const [isLoading, setLoadingProgress] = useState(false);
    const [activeFeature, setActiveFeature] = useState({});
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const { featureBlocked } = settings;

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if (newWidth > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 360;
        if (offsetRight > minDrawerWidth) {
            setDrawerWidth(offsetRight);
        }
        if (offsetRight > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize)

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])

    function updateKey(name, data) {
        let r = {}
        r[`${name}-active`] = true
        var x = Object.assign(activeFeature, r)
        setActiveFeature(x)
    }

    const featurePrjRoles = useMemo(() => {
        let publicProject = {
            roleName: "Public",
            roleType: "public_project"
        }
        let roles = projectTeamsStore.projectRoles
        let data = Object.keys(roles).map((key) => {
            return {
                id: key,
                roleName: roles[key].roleName,
                roleType: roles[key].role,
            }
        })
        data.push(publicProject)
        setCustomData(data || [publicProject])
        return data;
    }, [projectTeamsStore.projectRoles, isLoading])

    const compareFeatureRoles = (projectFeatureSetting) => {
        const systemFeatureSetting = adminStore?.systemFeatureSetting?.data;
        const diff = {};
        for (const property in projectFeatureSetting) {
            const splRole = property.split('-')
            let cR = splRole[0]
            const project = projectFeatureSetting[property];
            const system = systemFeatureSetting[property];
            const isExistRole = featurePrjRoles.find(c => c.roleType === cR)
            if (((!system && project) || (system && !project)) && isExistRole) {
                // if(project){
                diff[property] = project;
                // }
            }
        }
        return diff;
    }

    const checkSwitchColor = (roleType, Type) => {
        const rString = `${roleType}-${Type}`;
        let isDiff = false;
        const project = adminStore?.projectFeatureSetting?.data[rString];
        const system = adminStore?.systemFeatureSetting?.data[rString];
        if (((!system && project) || (system && !project)) && project) {
            isDiff = true;
        }
        return isDiff ? "user-color" : ''
    }

    const checkInitialRole = (roleType, recordType) => {
        const roleStr = `${roleType}-${recordType}`
        let role = false
        const systemSetting = adminStore.systemFeatureSetting?.data
        const projectSetting = adminStore.projectFeatureSetting?.data
        const system = systemSetting?.[roleStr];
        const project = projectSetting?.[roleStr];
        const isDiff = projectSetting.hasOwnProperty(roleStr)
        role = isDiff ? project : system
        return role ? role : false;
    }

    useEffect(() => {
        if (projectStore.showFeatureSettingDrawer) {
            setDrawerWidth(isTabletOrMobile ? 360 : 1200)
            const loadInitData = async () => {
                setLoading(true)
                await adminStore.getFeatures()
                await projectTeamsStore.getProjectRoles()
                await adminStore.getSystemFeatureSetting("system")
                await adminStore.getProjectFeatureSetting("project", projectId).then((res) => {
                    if (res.data.status === 'failed') {
                        adminStore.setProjectFeatureSetting(adminStore.systemFeatureSetting)
                        adminStore.setGreenColorSwitch(true)
                    }
                    let v = adminStore.features
                    setDataTable(v)
                    setLoading(false);
                    setLoadingProgress(true)
                }).catch(() => {
                    setLoading(false)
                    form.resetFields()
                    setDataTable(adminStore.features)
                });
                setLoading(false)
            }
            loadInitData()
            return () => {
                adminStore.setGreenColorSwitch(false)
            }
        }
    }, [projectStore.showFeatureSettingDrawer])

    useEffect(() => {
        if (projectStore.showFeatureSettingDrawer) {
            if (customData.length > 0) {
                let _column
                let defaultRoleColumn = customData.map(elm => {
                    return {
                        title: `${elm.roleName}`,
                        key: `${elm.id}`,
                        fixed: 'right',
                        width: 100,
                        render: record => {
                            let isPrivate = featureBlocked.find(element => element === record.Type)
                            if (elm.roleType === "public_project") {
                                if (isPrivate) {
                                    return null
                                } else {
                                    return (
                                        <Form.Item
                                            name={`${elm.roleType}-${record.Type}`}
                                            initialValue={checkInitialRole(elm.roleType, record.Type)}
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className={checkSwitchColor(elm.roleType, record.Type)}
                                                onChange={v => updateKey(`${elm.roleType}-${record.Type}`, v)}
                                                defaultChecked={checkInitialRole(elm.roleType, record.Type)}
                                            />
                                        </Form.Item>
                                    )
                                }

                            } else if (elm.roleType === "project_owner") {
                                if (record.Type === "feature_project_setting") {
                                    return null
                                } else {
                                    return (
                                        <Form.Item
                                            name={`${elm.roleType}-${record.Type}`}
                                            initialValue={checkInitialRole(elm.roleType, record.Type)}
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className={checkSwitchColor(elm.roleType, record.Type)}
                                                onChange={v => updateKey(`${elm.roleType}-${record.Type}`, v)}
                                                defaultChecked={checkInitialRole(elm.roleType, record.Type)} />
                                        </Form.Item>
                                    )
                                }
                            } else {
                                return (
                                    <Form.Item
                                        name={`${elm.roleType}-${record.Type}`}
                                        initialValue={checkInitialRole(elm.roleType, record.Type)}
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            className={checkSwitchColor(elm.roleType, record.Type)}
                                            onChange={v => updateKey(`${elm.roleType}-${record.Type}`, v)}
                                            defaultChecked={checkInitialRole(elm.roleType, record.Type)} />
                                    </Form.Item>
                                )
                            }
                        }
                    }
                })
                _column = [
                    {
                        title: t('feature'),
                        key: "Name",
                        dataIndex: 'Name',
                        width: 200,
                        defaultSortOrder: 'ascend',
                        sorter: (a, b) => a.Name.localeCompare(b.Name),
                        sortDirections: ['ascend', 'descend', 'ascend'],
                        render: record => {
                            return (
                                <span>{record}</span>
                            )
                        }
                    },
                    ...defaultRoleColumn
                ]
                setDataColumn(_column)
            }
        }
        return () => {

        }
    }, [customData, isLoading])

    const onClickHandlerAddSettingFeature = () => {
        form.validateFields().then((value) => {
            const valueDiffSystem = compareFeatureRoles(value);
            if (adminStore.projectFeatureSetting.id && adminStore.projectFeatureSetting.type !== "system_setting") {
                setLoadingtable(true);
                adminStore.updateFeatureSetting(adminStore.projectFeatureSetting.id, { data: valueDiffSystem }).then(res => {
                    message.success(t('project-setting-updated-successfull'))
                    setLoadingtable(false);
                    onClickHandlerCloseModal()
                })
                    .then(() => {
                        adminStore.getProjectFeatureSetting("project", projectId).then(respon => {
                            setLoadingProgress(!isLoading)
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        setLoadingtable(false);
                        message.error(t('an-error-occurred'))
                    })
            } else {
                let param = {
                    type: "project_setting",
                    data: valueDiffSystem,
                    project: projectId
                }
                setLoadingtable(true);
                adminStore.createFeatureSetting(param).then(resp => {
                    adminStore.getProjectFeatureSetting("project", projectId).then(respon => {
                        setLoadingProgress(!isLoading)
                    })
                    adminStore.setGreenColorSwitch(false)
                    message.success(t('project-setting-updated-successfull'))
                    setLoadingtable(false);
                    onClickHandlerCloseModal()
                }).catch(error => {
                    console.log(error)
                    setLoadingtable(false);
                    message.error(t('an-error-occurred'))
                })
            }
        })

    }

    const onClickHandlerCloseModal = () => {
        setLoading(false)
        setDrawerWidth(0)
        projectStore.setShowFeatureSettingDrawer(false)
        projectSettingStore.toggleProjectSettingDrawer(true); // snooze project setting drawer
    }

    return (
        <Drawer
            title={
                <div className="help-btn-wrapper">
                    {t('roles')}
                    <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={"roles"} />
                        </div>
                    </Tooltip>
                </div>
            }
            placement="left"
            width={drawerWidth}
            closable={true}
            onClose={e => onClickHandlerCloseModal()}
            visible={projectStore.showFeatureSettingDrawer}
            maskClosable={false}
            mask={false}
            destroyOnClose={true}
            forceRender
            className={projectStore.showFeatureSettingDrawer ? "project-team-drawer custom-wraper-splitPanel" : "project-team-drawer "}
            footer={
                <div style={{ textAlign: 'right', marginRight: 10 }}>
                    <Skeleton loading={loading} active>
                        {
                            projectStore.currentUserRoleInProject === 'project_owner' ? (
                                <>
                                    <Button style={{ marginRight: 8 }} icon={<CloseCircleOutlined />} onClick={() => onClickHandlerCloseModal()}>
                                        {t('commons.cancel')}
                                    </Button>
                                    <Button style={{ marginRight: 8 }} disabled={loadingtable} icon={<SaveOutlined />} type="primary" onClick={() => onClickHandlerAddSettingFeature()}>
                                        {t('commons.save')}
                                    </Button>
                                </>
                            ) : ''
                        }
                    </Skeleton>
                </div>
            }
        >
            <TeamsPageWrapper>
                {isTabletOrMobile ? (
                    projectStore.showFeatureSettingDrawer ? (
                        <div className="splitpanel-mobile-left">
                            <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                                <ColumnWidthOutlined />
                            </Button>
                        </div>
                    ) : " "

                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-left" />)
                }
                <Skeleton loading={loading} active>
                    <Form
                        form={form}
                        name="feature-setting-form"
                        layout="vertical"
                    >
                        <TableContentWrapper >
                            <Table
                                rowKey={record => record.id}
                                size='default'
                                bordered
                                scroll={{ x: 400 }}
                                columns={dataColumn}
                                loading={loadingtable}
                                dataSource={dataTable}
                                pagination={false}
                                onRow={(record) => ({
                                    onClick: (event) => {
                                    },
                                })}
                                locale={{ emptyText: t('no-data') }}
                            />
                        </TableContentWrapper>
                    </Form>
                </Skeleton>
            </TeamsPageWrapper>
        </Drawer>
    )
}

export default inject(
    'projectTeamsStore',
    'projectStore',
    'usersStore',
    'projectSettingStore',
    'adminStore'
)(observer(DrawerFeatureSetting))
