import styled from 'styled-components'

export const DrawerHeading = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
    margin-right: 30px;
  }
`
export const CardViewWrapper = styled.div`
  padding : 0 5px;
  .ant-avatar{
    background: none;
  }
  .ant-card {
    background-color: transparent;
    border: none;
    .ant-card-body {
      padding: 10px;
    }
    .ant-card-cover {
      position: relative;
      .anticon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: auto;
        color: white;
        font-size: 16px;
        opacity: 0;
        transition: ease .3s;
      }
      .save-action{
        position: absolute;
        top: 10px;
        right: 30px;
        width: auto;
        color: white;
        font-size: 16px;
        opacity: 0;
        transition: ease .3s;
      }
      &:hover {
        cursor: pointer;
        .anticon {
          transition: ease .3s;
          opacity: 1;
        }
        .save-action {
          transition: ease .3s;
          opacity: 1;
        }
      }
    }
    .ant-card-meta-title {
      color: white;
      font-size: 12px;
      text-align: center;
    }
  }
`

export const ViewPointImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ViewPointImage = styled.img`
  margin : 0 auto;
  object-fit: cover;  
  width: 100%;
  height: 100%;
  position: absolute;
`

export const CustomDrawerWraper = styled.div`
.slick-slide {
  padding: 0 5px !important;
}
.slick-prev, .slick-next {
  top: 32%;
}
@media(max-width : 768px){
    .slick-arrow:before{
      font-size :35px !important;
    }
};
@media(max-width : 576px){
    .slick-arrow:before{
      font-size :30px !important;
    }
};
`