import React, { Fragment, useEffect, useState } from 'react'
import {
  Drawer,
  Popconfirm,
  Modal,
  Tooltip,
  Button,
  Table,
  message,
  Tabs,
  List,
} from 'antd'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import {
  QuestionCircleOutlined,
  SaveOutlined,
  ReloadOutlined,
  DeleteOutlined,
  GatewayOutlined,
  EditOutlined
} from '@ant-design/icons';
import {
  DrawerHeaderWrapper,
  DrawerContent,
  DrawerContentHeader,
  HiddenAreaListContent
} from './CustomStyled'
import { Cesium3DTileColorBlendMode, Color, Cesium3DTileset } from 'cesium'
import { useTranslation } from 'react-i18next';
import DrawHiddenAreaForm from './DrawHiddenAreaForm'
import { useMediaQuery } from 'react-responsive'
import { isMobile, isTablet } from 'react-device-detect';
import HelpButton from '../../../elements/HelpButton';
import { toJS } from 'mobx';
import { HiddenAreaUtils } from './HiddenAreaUtils';

const DrawerHiddenTiles = props => {
  const { t } = useTranslation();
  const { projectStore, uiStore, polylineCutToolStore, viewer } = props
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  useEffect(() => {
    if (projectStore.showHiddenDrawer) {
      if (polylineCutToolStore.drawMode) {
        setActiveTab('2')
      }

      if (activeTab === "1") {
        projectStore.setShowHiddenTilesList(true)
      }
      return () => {
        setLoading(false)
        setActiveTab('1')
      }
    }
  }, [projectStore.showHiddenDrawer])

  function showConfirm(key) {
    Modal.confirm({
      title: t('confirm-leave'),
      content: t('are-you-sure-you-want-to-leave-not-save'),
      okText: t('commons.yes'),
      cancelText: t('commons.no'),
      onOk: () => {
        if (projectStore.projectDetail.tilesetData.hiddenTile) {
          projectStore.setHiddenTileSelected(projectStore.projectDetail.tilesetData.hiddenTile)
        } else {
          projectStore.setHiddenTileSelected([])
        }

        var primitives = viewer.scene.primitives;
        for (var i = 0; i < primitives.length; i++) {
          var _tileset = primitives.get(i)
          _tileset.debugColorizeTiles = false
        }
        uiStore.setRequestRender(true)
        uiStore.setRefreshViewer(true) //effect after updat fog setting refresh mainviewer

        projectStore.setShowHiddenTilesList(false)
        projectStore.setShowHiddenAreaTab(true)
        setActiveTab(key);
      },
      onCancel() { },
    });
  }

  const callbackTabClicked = (key, event) => {
    if (key === '1') {
      projectStore.setShowHiddenAreaTab(false)
      projectStore.setShowHiddenTilesList(true)
      setActiveTab(key);
    } else {
      uiStore.setClippingPlaneToolVisible(false);
      projectStore.setViewMode('default mode')
      projectStore.setClippingMode(false)
      if (projectStore.projectDetail.tilesetData?.hiddenArea) {
        if (polylineCutToolStore.hideArea && polylineCutToolStore.hideArea?.points?.length > 0)
          polylineCutToolStore.setHideArea(polylineCutToolStore.hideArea)
        else polylineCutToolStore.setHideArea(projectStore.projectDetail.tilesetData?.hiddenArea)
      }

      const isSameUrl = (a, b) => a.url === b.url;
      const onlyInLeft = (left, right, compareFunction) => left.filter(leftValue => !right.some(rightValue => compareFunction(leftValue, rightValue)));
      const onlyInA = projectStore.projectDetail.tilesetData.hiddenTile ? onlyInLeft(projectStore.projectDetail.tilesetData.hiddenTile, projectStore.hiddenTileSelected, isSameUrl) : [];
      const onlyInB = projectStore.projectDetail.tilesetData.hiddenTile ? onlyInLeft(projectStore.hiddenTileSelected, projectStore.projectDetail.tilesetData.hiddenTile, isSameUrl) : [];
      const result = [...onlyInA, ...onlyInB];
      if (result.length > 0) { //case change then show confirm
        showConfirm(key)
      } else {
        //reset viewer
        if (projectStore.projectDetail.tilesetData.hiddenTile) {
          projectStore.setHiddenTileSelected(projectStore.projectDetail.tilesetData.hiddenTile)
        } else {
          projectStore.setHiddenTileSelected([])
        }

        var primitives = viewer.scene.primitives;
        for (var i = 0; i < primitives.length; i++) {
          var _tileset = primitives.get(i)
          _tileset.debugColorizeTiles = false
        }
        uiStore.setRequestRender(true)
        uiStore.setRefreshViewer(true) //effect after updat fog setting refresh mainviewer

        projectStore.setShowHiddenTilesList(false)
        projectStore.setShowHiddenAreaTab(true)
        setActiveTab(key);
      }
    }
  };

  const onCloseDrawer = () => {
    // reset hidden object
    if (projectStore.projectDetail.tilesetData.hiddenTile) {
      projectStore.setHiddenTileSelected(projectStore.projectDetail.tilesetData.hiddenTile)
    } else {
      projectStore.setHiddenTileSelected([])
    }
    projectStore.setShowHiddenTilesList(false)

    var primitives = viewer.scene.primitives;
    for (var i = 0; i < primitives.length; i++) {
      var _tileset = primitives.get(i)
      _tileset.debugColorizeTiles = false
    }
    //=============
    polylineCutToolStore.setCloseHideTool(true);
    // reset hiden area
    if (polylineCutToolStore.drawEditor.currentEditEntity) {
      if (polylineCutToolStore.drawEditor.currentEditEntity.onCancel)
        polylineCutToolStore.drawEditor.currentEditEntity.onCancel();
      if (polylineCutToolStore.drawEditor.currentEditEntity.hasOwnProperty('onFinish') && typeof polylineCutToolStore.drawEditor.currentEditEntity.onFinish === 'function') {
        polylineCutToolStore.drawEditor.currentEditEntity?.onFinish()
      }
      viewer.entities.remove(polylineCutToolStore.drawEditor.currentEditEntity);
    }

    if (projectStore.projectDetail.tilesetData?.hiddenArea) {

      polylineCutToolStore.setHideArea(projectStore.projectDetail.tilesetData?.hiddenArea)
    } else {
      //clear clipping 3dtile before call
      polylineCutToolStore.setHideArea(false)
      if (viewer.scene && !viewer.scene.isDestroyed() && viewer.scene.primitives) {
        for (var i = 0; i < viewer.scene.primitives.length; i++) {
          var primitive = viewer.scene.primitives.get(i)
          // remove clipping
          if (primitive.clippingPlanes) {
            primitive.clippingPlanes.removeAll();
          }
        }
      }
      //clear clipping terrain
      if (viewer.scene.globe.clippingPlanes) {
        viewer.scene.globe.clippingPlanes.enabled = !1
        viewer.scene.globe.clippingPlanes.removeAll()
      }

    }
    polylineCutToolStore.setDrawEditor(false)
    polylineCutToolStore.setDrawMode(false) // reset drawmode
    polylineCutToolStore.setShowForm(false)
    //=============

    uiStore.setRequestRender(true)
    uiStore.setRefreshViewer(true) //effect after updat fog setting refresh mainviewer

    projectStore.setShowHiddenTilesList(false)
    projectStore.setShowHiddenAreaTab(false)
    projectStore.setShowHiddenDrawer(false)

    polylineCutToolStore.setHideAreaTemp(false)
  }


  const DrawerHeader = () => {
    return (
      <DrawerHeaderWrapper>
        <span>{t('hide-objects-list')}</span>
        <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <div>
            <HelpButton helppage={activeTab === '1' ? 'hide_object' : 'hide_area'} />
          </div>
        </Tooltip>
      </DrawerHeaderWrapper>
    )
  }

  // Tab Hide Object Component
  const HideobjectsTab = ({ showHiddenTilesList, hiddenTileSelected }) => {
    const [groupHideTileList, setGroupHideTileList] = useState([])
    const [dataChildren, setDataChildren] = useState([])

    useEffect(() => {
      if (showHiddenTilesList) {
        var primitives = viewer.scene.primitives;
        for (var i = 0; i < primitives.length; i++) {
          var _tileset = primitives.get(i)
          if (_tileset.style && _tileset.style.color && _tileset.style.color.expression === "color('white', 0)") {
            _tileset.colorBlendAmount = 0.7
            _tileset.colorBlendMode = Cesium3DTileColorBlendMode.MIX
            _tileset.debugColorizeTiles = false
          } else {
            _tileset.colorBlendAmount = 0.5
            _tileset.colorBlendMode = Cesium3DTileColorBlendMode.HIGHLIGHT
            _tileset.debugColorizeTiles = true
          }
        }
        changeToHighlightCity()
        let count = 0;
        function requestRenderBug() {
          count += 1
          if (!viewer?.scene?.isDestroyed()) {
            viewer?.scene.requestRender();
            count > 5 ? clearTimeout(timeout) : setTimeout(requestRenderBug, 1000);
          }
        }
        let timeout = showHiddenTilesList ? setTimeout(requestRenderBug, 1000) : false;
      }
    }, [showHiddenTilesList])

    useEffect(() => {
      var _modelgroup = [];
      var _dataChildren = {};
      for (let i = 0; i < hiddenTileSelected.length; i++) {
        if (!hiddenTileSelected[i]["isDeleted"]) {
          let _name = hiddenTileSelected[i]["name"];
          let _modelid = hiddenTileSelected[i]["modelid"]
          var index = _modelgroup.findIndex(x => x.modelid == _modelid)
          if (index === -1) {
            _modelgroup.push({
              key: _modelid,
              type: 1,
              name: _name ? _name : _modelid,
              modelid: _modelid
            });
          }
          if (!_dataChildren[_modelid]) _dataChildren[_modelid] = [];
          _dataChildren[_modelid].push({ ...hiddenTileSelected[i], key: hiddenTileSelected[i]["url"] });
        }
      }
      for (let i = 0; i < projectStore.hiddenCityGmlSelected.length; i++) {
        if (!projectStore.hiddenCityGmlSelected[i]["isDeleted"]) {
          let _name = projectStore.hiddenCityGmlSelected[i]["name"];
          let _modelid = projectStore.hiddenCityGmlSelected[i]["modelid"]
          var index = _modelgroup.findIndex(x => x.modelid == _modelid)
          if (index === -1) {
            _modelgroup.push({
              key: _modelid,
              type: 2,
              name: _name ? _name : _modelid,
              modelid: _modelid
            });
          }
          if (!_dataChildren[_modelid]) _dataChildren[_modelid] = [];
          _dataChildren[_modelid].push({ ...projectStore.hiddenCityGmlSelected[i], key: projectStore.hiddenCityGmlSelected[i]["url"] });
        }
      }
      setGroupHideTileList(_modelgroup)
      setDataChildren(_dataChildren)
    }, [hiddenTileSelected, projectStore.hiddenCityGmlSelected])

    const onReset = () => {
      if (projectStore.projectDetail.tilesetData.hiddenTile) {
        projectStore.setHiddenTileSelected(projectStore.hiddenTileSelected.map((o) => {
          let x = projectStore.projectDetail.tilesetData.hiddenTile.find(item => item.url === o.url)
          if (x) {
            return { ...x }
          }
          if (o.isDeleted === false) return { ...o, isDeleted: true }
          return o;
        }))
        projectStore.setHiddenCityGmlSelected(projectStore.hiddenCityGmlSelected.map((o) => {
          let x = projectStore.projectDetail.tilesetData.hiddenCityGmlObject.find(item => item.url === o.url)
          if (x) {
            return { ...x }
          }
          if (o.isDeleted === false) return { ...o, isDeleted: true }
          return o;
        }))
      }
    }

    const onUnhideAll = () => {
      projectStore.setHiddenTileSelected(projectStore.hiddenTileSelected.map((o) => {
        if (o.isDeleted === false) return { ...o, isDeleted: true }
        return o;
      }))
      projectStore.setHiddenCityGmlSelected(projectStore.hiddenCityGmlSelected.map((o) => {
        if (o.isDeleted === false) return { ...o, isDeleted: true }
        return o;
      }))
    }

    const deleteHiddenTile = record => {
      //Set show == true before delete    
      projectStore.setHiddenTileSelected(projectStore.hiddenTileSelected.map((o) => {
        if (o.url === record.url) return { ...o, isDeleted: true }
        return o;
      }))
      projectStore.setHiddenCityGmlSelected(projectStore.hiddenCityGmlSelected.map((o) => {
        if (o.url === record.url) return { ...o, isDeleted: true }
        return o;
      }))
    }


    const changeToHighlightCity = function () {
      const citygmlMap = projectStore.city3DDB
      const city3dLayers = citygmlMap?._layers
      const allHiddenObject = {}
      if (city3dLayers) {
        for (let i = 0; i < city3dLayers.length; i++) {
          const layer = city3dLayers[i];
          const hiddenObjects = layer.hiddenObjects
          if (hiddenObjects) {
            allHiddenObject[layer.id] = hiddenObjects
            for (var k = 0; k < hiddenObjects.length; k++) {
              var objectId = hiddenObjects;
              var highlightThis = {}
              highlightThis[objectId] = new Color(0.4, 0.4, 0, 1);
              layer.highlight(highlightThis);
            }
            layer.showAllObjects()
          }
        }
      }
      return allHiddenObject
    }

    const getHightlightObjects = function () {
      const citygmlMap = projectStore.city3DDB
      const city3dLayers = citygmlMap?._layers
      const allHiddenObject = []
      if (city3dLayers) {
        for (let i = 0; i < city3dLayers.length; i++) {
          const layer = city3dLayers[i];
          const highlightedObjects = layer._highlightedObjects
          if (highlightedObjects) {
            for (const id in highlightedObjects) {
              let hidetile = {
                modelid: layer.id,
                name: layer.name,
                defaultColor: new Color(0.4, 0.4, 0, 1),
                url: id,
                bbox: {},
                isDeleted: false
              }
              allHiddenObject.push(hidetile)

              layer.unHighlightObject(id)
            }
          }
        }
      }
      return allHiddenObject
    }

    const onSave = async () => {
      setLoading(true)

      let _tilesetData = projectStore.projectDetail.tilesetData
      _tilesetData.hiddenTile = projectStore.hiddenTileSelected.filter(item => item.isDeleted !== true)
      _tilesetData.hiddenCityGmlObject = getHightlightObjects()

      let newData = {
        tilesetData: _tilesetData,
        store: 'nodata'
      }

      try {
        await projectStore.updateProject(newData);
        setLoading(false);

        // Reset debugColorizeTiles for all primitives
        let primitives = viewer.scene.primitives._primitives;
        primitives.forEach((primitive) => {
          if (primitive instanceof Cesium3DTileset) {
            primitive.debugColorizeTiles = false;
          }
        });

        uiStore.setRequestRender(true);
        message.success(t('hide-objects-update-successfully'));
      } catch (error) {
        setLoading(false);
        message.error(t('hide-objects-update-failed'));
      }
    }

    const columns = [
      {
        key: "modelid",
        render: record => {
          return (
            <div className={'tileset-name'}>{record.name}</div>
          )
        }
      },
    ];

    const columns2 = [
      {
        key: "url",
        render: record => {
          return (
            <div className={'tile-name'}>{record.url.substring(record.url.lastIndexOf('/') + 1)}</div>
          )
        }
      },
      {
        key: 'action',
        width: 40,
        render: record => (
          <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => deleteHiddenTile(record)}
            okButtonProps={{ danger: true }}
            okText={t('commons.delete')}
            cancelText={t('commons.cancel')}
            title={t('delete-this-hide-objects')}>
            <Tooltip title={t('commons.delete')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>
        )
      }
    ];

    const expandedRow = row => {
      var inTable = dataChildren[row.key];
      return <Table
        showHeader={false}
        rowKey={record => record.url}
        pagination={false}
        columns={columns2}
        dataSource={inTable}
        locale={{ emptyText: t('no-data') }}
      />
    };

    return (
      <Fragment>
        <DrawerContentHeader>
          <Button
            loading={loading}
            type="primary"
            style={{ marginBottom: 10 }}
            icon={<SaveOutlined />}
            onClick={onSave}
          >{t('commons.save')}</Button>
          <Button
            type="default"
            style={{ marginBottom: 10, marginLeft: 5 }}
            onClick={onUnhideAll}
          >{t('unhide-all')}</Button>
          <Button
            type="default"
            style={{ marginBottom: 10, marginLeft: 5 }}
            shape="circle"
            onClick={onReset}
            icon={<ReloadOutlined />}></Button>
        </DrawerContentHeader>
        <Table
          showHeader={false}
          pagination={false}
          rowKey={record => record.modelid}
          dataSource={groupHideTileList}
          columns={columns}
          defaultExpandAllRows={true}
          expandedRowRender={expandedRow}
          locale={{ emptyText: t('no-data') }}
        />
      </ Fragment>
    )
  }

  const [hiddenAreaOld, setHiddenAreaOld] = useState(false);
  useEffect(() => {
    if (projectStore.showHiddenAreaTab && viewer.scene && !viewer.scene.isDestroyed() && viewer.scene.primitives) {
      let hideArea = polylineCutToolStore.hideArea;
      if (projectStore.clippingMode) {
        if (hideArea.points?.length > 0) {
          setHiddenAreaOld(hideArea)
          //HiddenAreaUtils.cancelState(viewer, polylineCutToolStore, false);
        }
      } else if (!projectStore.clippingMode) {
        let hiddenAreaDraw = hideArea
        if (hiddenAreaDraw?.points?.length > 0) {
          if (polylineCutToolStore.drawEditor) {
            if (!projectStore.showHiddenDrawer && polylineCutToolStore.drawMode && isTabletOrMobileDevice) {
              HiddenAreaUtils.cancelState(viewer, polylineCutToolStore, true);
              HiddenAreaUtils.drawArea(viewer, hiddenAreaDraw, false);
            }
          } else HiddenAreaUtils.drawArea(viewer, hideArea, true);
        }
        setHiddenAreaOld(false)
      }
    }
  }, [projectStore.clippingMode, polylineCutToolStore.hideArea, projectStore.showHiddenAreaTab, polylineCutToolStore.hideArea.isCutTerrain, hiddenAreaOld])

  // Tab Hide Area Component
  const HideAreaTab = ({ showHiddenAreaTab, hideArea, showForm, drawMode }) => {
    const onClickArea = async () => {
      polylineCutToolStore.setDrawMode(true)
      if (hideArea.points?.length === 0) {
        let _tilesetData = projectStore.projectDetail.tilesetData
        delete _tilesetData['hiddenArea']

        let newData = {
          tilesetData: _tilesetData,
          store: 'nodata'
        }
        if (viewer.scene && !viewer.scene.isDestroyed() && viewer.scene.primitives) {
          for (var i = 0; i < viewer.scene.primitives.length; i++) {
            var primitive = viewer.scene.primitives.get(i)
            if (primitive.clippingPlanes) {
              primitive.clippingPlanes.removeAll();
            }
          }
        }
        if (viewer.scene.globe.clippingPlanes) {
          viewer.scene.globe.clippingPlanes.enabled = !1
          viewer.scene.globe.clippingPlanes.removeAll()
        }

        try {
          await projectStore.updateProject(newData);
          polylineCutToolStore.setHideArea(false)
          uiStore.setRequestRender(true)
          polylineCutToolStore.setCancelDrawArea(false)
        } catch (error) {
          message.error(t('error'));
        }

      } else {
        polylineCutToolStore.setCancelDrawArea(true)
      }
      projectStore.setGlobeBehind(false) // importance
      if (isTabletOrMobileDevice) { // if on mobile hide drawer for draw
        projectStore.setShowHiddenDrawer(false)
      }
    }

    const handleDeleteHiddenArea = async () => {
      let _tilesetData = projectStore.projectDetail.tilesetData
      delete _tilesetData['hiddenArea']

      let newData = {
        tilesetData: _tilesetData
      }
      try {
        await projectStore.updateProject(newData);
        polylineCutToolStore.setHideArea(false);

        // Clear clipping planes for 3D Tiles
        if (viewer.scene && !viewer.scene.isDestroyed() && viewer.scene.primitives) {
          let primitives = viewer.scene.primitives._primitives;
          primitives.forEach((primitive) => {
            if (primitive.clippingPlanes) {
              primitive.clippingPlanes.removeAll();
            }
          });
        }

        // Clear clipping for terrain
        if (viewer.scene.globe.clippingPlanes) {
          viewer.scene.globe.clippingPlanes.enabled = false;
        }

        message.success(t('deleted-successfully'));
        uiStore.setRequestRender(true);
      } catch (error) {
        message.error(t('delete-failed'));
      }
    }

    return (
      <>
        <DrawerContentHeader visible={polylineCutToolStore.showForm ? false : true}>
          <Button
            type="primary"
            style={{ marginBottom: 10, marginLeft: 5 }}
            icon={<GatewayOutlined />}
            onClick={onClickArea}
          >{t('area')}</Button>
        </DrawerContentHeader>
        <HiddenAreaListContent>
          {showForm ? <DrawHiddenAreaForm viewer={viewer} hideArea={hideArea} /> :
            <List
              className="demo-loadmore-list"
              //loading={initLoading}
              itemLayout="horizontal"
              //loadMore={loadMore}
              dataSource={hideArea && hideArea.points.length > 0 ? [hideArea] : undefined}
              locale={{ emptyText: t('no-data') }}
              renderItem={item => (
                <List.Item
                  //actions={[<a key="list-loadmore-edit" >{t('commons.delete')}</a>]}
                  actions={[
                    <Button type="text" style={{ padding: 0 }} onClick={onClickArea}><EditOutlined /></Button>,
                    <Popconfirm
                      title={t('are-you-sure-you-want-to-delete-this-record')}
                      onConfirm={() => handleDeleteHiddenArea()}
                      okText={t('commons.yes')}
                      cancelText={t('commons.no')}>
                      <Button
                        style={{ padding: 0 }}
                        htmlType="submit"
                        type="text">
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  ]}
                >
                  {item.name}
                </List.Item>
              )}
            />
          }
        </HiddenAreaListContent>
      </>
    )
  }

  return (
    <Drawer
      className={'hidden-tiles-drawer'} id={'hidden-tiles-drawer'}
      width={360}
      placement={'left'}
      title={<DrawerHeader />}
      visible={projectStore.showHiddenDrawer}
      onClose={onCloseDrawer}
      mask={false}
    >
      <DrawerContent>
        <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onTabClick={callbackTabClicked}>
          <Tabs.TabPane tab={t('hide-objects')} key="1">
            {projectStore.showHiddenDrawer && <HideobjectsTab showHiddenTilesList={projectStore.showHiddenTilesList} hiddenTileSelected={projectStore.hiddenTileSelected} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('hide-area')} key="2">
            {projectStore.showHiddenDrawer && <HideAreaTab showHiddenAreaTab={projectStore.showHiddenAreaTab} hideArea={polylineCutToolStore.hideArea} showForm={polylineCutToolStore.showForm} drawMode={polylineCutToolStore.drawMode} />}
          </Tabs.TabPane>
        </Tabs>
      </DrawerContent>
    </Drawer>
  )
}

export default withRouter(inject('projectStore', 'uiStore', 'polylineCutToolStore')(observer(DrawerHiddenTiles)))
