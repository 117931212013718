import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DefaultTemplate from '../../layout/DefaultTemplate';
import { ConnectPageWrapper } from './CustomStyled';


const ConfirmOrganizationPage = ({ usersStore, commonStore, organizationStore, history }) => {
    const { organizationId } = useParams()
    const { t } = useTranslation();
    useEffect(() => {
        let fetch = async () => {

            await organizationStore.checkInvitationOrganization(organizationId).then(res => {
                if (res.data.message === 'User already exists') {
                    if (usersStore.currentUser.id) {
                        history.push('/')
                        commonStore.setShowOrganizationPageDrawer(true)
                    } else {
                        message.success(t('please-sign-in-to-access-to-organization'))
                        history.push('/auth/login')
                    }
                } else {
                    if (res.data.message === 'Not found user exists') {
                        organizationStore.setRegister(true)
                        organizationStore.setInvitationDetail(res.data)
                    }
                }
            })
                .catch(error => {
                    history.push('/')
                    message.error(t('an-error-occurred'))
                })
        }
        fetch()

        return () => {
            organizationStore.setRegister(false)
        }
    }, [])

    const handleRegister = () => {
        history.push('/auth/register')
    }

    return (
        <HelmetProvider>
            <DefaultTemplate>
                <Helmet>
                    <title>{t('organization-invitation')} || 6DPlanner Viewer</title>
                </Helmet>
                <ConnectPageWrapper>
                    <div style={{ fontSize: 18, textAlign: 'center', padding: "30px 0" }}>
                        {
                            organizationStore.register ? (
                                <div>{t('you-do-not-have-an-account-register-your-account-to-access-services')}. <a onClick={() => handleRegister()} >{t('register-now')}</a></div>
                            ) : (
                                <span>{t('please-wait-to-access-to-organization')}</span>
                            )
                        }
                    </div>
                </ConnectPageWrapper>
            </DefaultTemplate>
        </HelmetProvider>

    );
}

export default inject("usersStore", "commonStore", "organizationStore")(observer(ConfirmOrganizationPage));
