import React, { Fragment, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Modal, Form, Input, Button, message, Row } from 'antd'
import validator from '../../../../validator'
import uuid from 'uuid'
import TreeUtils from '../../../../tree-utils'
import { Trans, useTranslation } from 'react-i18next';
import { toJS } from 'mobx'

const ModalAddFolder = ({ projectStore, commonStore, usersStore }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const AddForm = () => {
    useEffect(() => {
      if (projectStore.folderEdit) {
        form.setFieldsValue({ title: projectStore.folderEdit.title });
      }
      else {
        form.setFieldsValue({ title: '' });
      }
    }, [projectStore.folderEdit])
    return (
      <Form form={form} name="add-folder" layout="vertical">
        <Form.Item label={t('name')} name="title" rules={[
          {
            required: true,
            message: t('please-input-the-name-of-folder'),
          },
          { validator: validator.validateEmptyString },
        ]}>
          <Input
            placeholder={t('input-folder-name')} />
        </Form.Item>
      </Form>
    )
  }

  const onCancel = () => {
    projectStore.setFolderEdit()
    form.resetFields();
    projectStore.setShowAddFolderModal(false)
  }

  const onCreate = () => {
    form.validateFields().then(values => {
      const { treeData } = projectStore.projectDetail;
      const newNode = {
        title: values.title,
        key: uuid(),
        type: 'FOLDER',
        isDeleted: false,
        children: [],
        accesscontrols: [],
        parentKey: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : projectStore.selectedNode?.type === 'tree' ? projectStore.selectedNode?.parentKey : null
      }
      addTreeNode(newNode, treeData, projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null);
    });
  }

  const addTreeNode = (child, data, toData) => {
    const startTime = Date.now();
    if (data?.length) {
      if (projectStore.folderEdit) {
        let toNode;
        if (projectStore.folderEdit.parentKey) {
          const parrentNode = TreeUtils.searchTreeNode(data, 'key', projectStore.folderEdit.parentKey);
          toNode = parrentNode.children;
        } else {
          toNode = data;
        }
        const existed = toNode.find(item => item.title === child.title);
        if (existed && existed.key !== projectStore.folderEdit.key) return message.error(t('folder-already-existed'))
        const node = toNode.find(child => child.key === projectStore.folderEdit.key);
        node.title = child.title;
      } else {
        let toNode;
        if (toData) {
          const node = TreeUtils.searchTreeNode(data, 'key', toData);
          node.children = node.children && node.children.length ? node.children : [];
          toNode = node.children;
          //inherit access right from its parent
          // child.accesscontrols = node?.accesscontrols || []
        } else {
          toNode = data;
        }
        const existed = toNode.find(item => item.title === child.title);
        if (existed) return message.error(t('folder-already-existed'))
        toNode.push(child)
      }
    } else {
      data = [child]
    }
    projectStore.setLoadingProgress(true);
    projectStore.updateProjectTreeData({ treeData: data, store: 'treedata' }).then((res) => {
      projectStore.projectDetail.treeData = res.treeData;
      form.resetFields()

      projectStore.setSelectedNode(projectStore.folderEdit ? { ...projectStore.folderEdit, title: child.title } : child)
      message.success(projectStore.folderEdit ? t('edit-folder-successfully') : t('add-folder-successfully'))
      projectStore.setShowAddFolderModal(false)
      commonStore.loggingFunction(projectStore.folderEdit ? 'edit folder' : 'add folder', 'success', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
    }).catch(err => {
      console.log(err)
      message.error(projectStore.folderEdit ? t('edit-folder-failed') : t('add-folder-failed'))
      commonStore.loggingFunction(projectStore.folderEdit ? 'edit folder' : 'add folder', 'failed', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
    }).finally(() => {
      projectStore.setLoadingProgress(false);
    })
  }

  return (
    <Modal
      visible={projectStore.showAddFolderModal}
      title={projectStore.folderEdit ? t('edit-folder-name') : t('create-new-folder')}
      okText={t('commons.create')}
      cancelText={t('commons.cancel')}
      onCancel={onCancel}
      onOk={onCreate}
      forceRender={true}
      zIndex={10000}
      footer={
        <Row justify='end'>
          <Button key="back" onClick={onCancel}>
            {t('commons.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={projectStore.isAppLoading}
            onClick={onCreate}>
            {projectStore.folderEdit ? t('save-folder-name') : t('create-new-folder')}
          </Button>
        </Row>
      }>
      <AddForm />
    </Modal>
  )
}

export default inject('projectStore', "commonStore", "usersStore")(observer(ModalAddFolder))