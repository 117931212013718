import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Collapse, Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import Scrollbars from 'react-custom-scrollbars';
import ModelStatusComponent from './ModelStatusComponent';
import { CollapseBody } from './styled';
import { useMemo } from 'react';
const { Panel } = Collapse;

function ModalProcessingModel3D({processModel, projectStore, fileStore}) {
    
  const genExtraCloseButtonProgressUpload = () => (
    <Button
      onClick={event => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation()
        projectStore.setShowModalProcessingModel3D(false)
      }}
      type="text"
      shape="circle"
      icon={<CloseOutlined style={{ fontSize: '20px' }} />}
    />
  )

  const styleModal = useMemo(() => {
    const style = {
      top: 'auto',
      position: 'absolute',
      right: 60,
      bottom: fileStore.modalDrop3DView ? fileStore.heightModal + 24 : 0
    }
    
    return style
  }, [fileStore.modalDrop3DView ,fileStore.heightModal])


  return (
    <>
       <div className="modal-container" />
       <Modal
          className="antdModalProcessWaittingUploadFile"
          mask={false}
          maskClosable={false}
          title=""
          style={styleModal}
          visible={ projectStore.showModalProcessingModel3D}
          closable={false}
          footer={null}
          width={400}
          getContainer={() => {
              return document.getElementsByClassName("modal-container")[0];
          }}
          
        >
          <CollapseBody>

              <Collapse expandIcon={({ isActive }) => isActive ? <DownOutlined /> : <UpOutlined />} defaultActiveKey={['1']}>
                  <Panel
                      header={
                          // progressInfos.val.some(file => file.isCancel === false && file.isFailed === false && file.isUnsupported === false && (file.percentage < 100 || file.isComplete === false)) ? (
                          //     t('uploading-length-of-total-files', { length: progressInfos.val.filter(file => file.percentage === 100).length, total: progressInfos.val.length })
                          // ) : !uploadStatus && progressInfos.val.some(file => file.isCancel === true || file.isFailed === true || file.isUnsupported === true) ? (
                          //     t('length-of-total-files-uploaded', { length: progressInfos.val.filter(file => file.percentage === 100 && file.isComplete === true).length, total: progressInfos.val.length })
                          // ) : !uploadStatus && progressInfos.val.filter(file => file.isComplete === true && !file.isCancel && !file.isFailed && !file.isUnsupported).length === progressInfos.val.length ? (
                          //     t('upload-complete')
                          // ) : null
                          "Processing 3D Model"
                      }
                      key="1"
                      extra={genExtraCloseButtonProgressUpload()}
                  >
                    <Scrollbars autoHeight autoHeightMax={249}>
                        {
                            processModel?.length > 0 && processModel.map(model =>
                                <ModelStatusComponent modelInfo={model} key={model?._id}/>
                            )
                        }
                    </Scrollbars>
                  </Panel>
              </Collapse>
          </CollapseBody>
        </Modal>
    </>
  )
}

export default inject('projectStore', 'fileStore')(observer(ModalProcessingModel3D))