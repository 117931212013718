import { Row } from 'antd'
import styled from 'styled-components'
export const CustomAttributesContainer = styled.div`
    word-break: break-all;
    ${props =>props.place ==='sketch-editpanel' ||  props.place ==='edit-position-panel' ? "max-height: 500px ;overflow-y: scroll;overflow-x: hidden;"
     : props.place ==='attributes-panel' ?  "height: auto; overflow-x: hidden; overflow-y: scroll;"
    : "height: auto"
    }
    &::-webkit-scrollbar {
       display: none;
     }
.c-button.ant-btn{
    padding : 5px 25px;
    color : #fff;
}
.att-table-container{
    /* overflow: auto hidden; */
    padding :10px 0;
}
.empty-table{
  justify-content: center;
}
span.att-table-label{
  text-decoration: underline;
  font-weight: 500;
  color: #000;
  cursor: pointer;
}
span.att-table-label-kt{
  color : red;
  cursor: pointer;
}
`
export const TableAttributes = styled.table`
  border-collapse: collapse;
  width: 100%;
  td, th {
  border: 1px solid #dddddd;
  padding: 8px;
}
  td {
  text-align: left;
  word-break: break-word;
  /* width: 100px; */
}
  th {
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  background: #fafafa;
  transition: background 0.3s ease;
}
 .btn.btn-link {
    border: none;
    background: none;
    outline: none;
    padding: 3px;
}
.action-tool-attribute{
    display :flex;
    justify-content: center;
    align-items:cemter;
}
.tr-attribute{
    width : 100%;
}
.tr-attribute .tr-attribute-att,.tr-attribute-value,.tr-attribute-unit {
    /* width : 40%; */
    min-width: 100px;
}

.tr-attribute .tr-attribute-eyes,.tr-attribute-action{
    width : 5%;
}
.tr-attribute-name {
  min-width: 200px;
}
.tr-attribute-capitalize{
  text-transform: capitalize;
}
.ant-avatar {
    border :none;
    cursor: pointer;
    background: #fff !important;
  }
  .ant-form-item{
    margin-bottom : 0 ;
  }
  .ant-input-number{
    width: 100%;
  }
  .tr-attribute-att,.tr-attribute-unit{
    text-align: center;
  }
`

export const CustomAttributesTool = styled(Row)`
`
