import { Button } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import Modal from 'antd/lib/modal/Modal'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router'
import XDTwin_Guide from '../../../../assets/imgs/xd-guide.png'
import './XDTwinGuideModal.css'
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
import { toJS } from 'mobx'

function XDTwinGuideModal({ usersStore, projectStore, projectSettingStore }) {
    const { t } = useTranslation();
    const history = useHistory();
    const [imageUrl, setimageUrl] = useState(null);
    const [showTwinGuideDialog, setShowTwinGuideDialog] = useState(false)
    const [dontShowAgain, setDontShowAgain] = useState(true)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })
    const isFooterOrMobile = useMediaQuery({ query: '(max-width: 450px)' })
    const onChange = () => {
        setDontShowAgain(!dontShowAgain)
    }
    const handleCloseTwinGuide = () => {
        setShowTwinGuideDialog(false)
        projectStore.setShowTwinGuideDialog(true)
    }

    useEffect(() => {
        if (showTwinGuideDialog) {
            projectStore.setUploadNewStartImage(false)
        }
    }, [showTwinGuideDialog])
    useEffect(() => {
        if (history?.location?.search) {
            let param = history.location.search.toLowerCase();
            if (param.includes('publickey')) {
                usersStore.setCookie(true);
            } else {
                usersStore.setCookie(false);
            }
        } else {
            usersStore.setCookie(false);
        }
    }, [history?.location?.search])
    const handleOk = () => {
        setShowTwinGuideDialog(false)
        if (usersStore.currentUser.id) {
            let _projectSetting = {}
            _projectSetting.userid = usersStore.currentUser.id
            _projectSetting.projectSetting = {
                show_quick_guide: dontShowAgain
            }

            let _projectMetadata = projectStore.projectDetail.metadata
            if (_projectMetadata && _projectMetadata.projectSetting && _projectMetadata.projectSetting.length > 0) {
                const objIndex = _projectMetadata.projectSetting.findIndex(obj => obj.userid === usersStore.currentUser.id);
                if (objIndex > -1) {
                    _projectMetadata.projectSetting[objIndex].projectSetting = { ..._projectMetadata.projectSetting[objIndex].projectSetting, show_quick_guide: dontShowAgain }
                } else {
                    _projectMetadata.projectSetting.push(_projectSetting)
                }
            } else {
                _projectMetadata.projectSetting = [_projectSetting]
            }
            projectSettingStore.setAssignObjSystemProjectSetting('show_quick_guide', dontShowAgain)

            let newData = {
                metadata: _projectMetadata, store : 'metadata'
            }
            const checkuserinproject = projectStore.projectDetail.projectuserroles.find(pur => pur.email === usersStore.currentUser.email && pur.inviteStatus === 'accepted')
            if (checkuserinproject) {
                projectStore.updateProjectData(newData).then((res) => {
                    projectStore.projectDetail.metadata = res.metadata
                    projectStore.setShowTwinGuideDialog(true)
                }).catch(err => {
                    console.log(err)
                })
            } else {
                projectStore.updateProjectDataForGuest(newData).then((res) => {
                    projectStore.setShowTwinGuideDialog(true)
                }).catch(err => {
                    console.log(err)
                })
            }
        } else {
            localStorage.setItem(`show_quick_guide-${projectStore.projectDetail.id}`, JSON.stringify({ show_quick_guide: dontShowAgain }));
        }
    }

    useEffect(() => {
        if (projectStore.projectDetail?.id && !projectStore.showTwinGuideDialog) {
            //Update metadata projectsetting if not exist
            const updateProjectSetting = async () => {
                const checkuserinproject = projectStore.projectDetail.projectuserroles.find(pur => pur.email === usersStore.currentUser.email && pur.inviteStatus === 'accepted')
                if (checkuserinproject) {
                    let _projectSetting = {}
                    _projectSetting.userid = usersStore.currentUser.id
                    _projectSetting.projectSetting = {
                        ...projectSettingStore.systemProjectSetting
                    }
                    let isUpdate = true

                    let _projectMetadata = projectStore.projectDetail.metadata
                    if (_projectMetadata && _projectMetadata.projectSetting && _projectMetadata.projectSetting.length > 0) {
                        const objIndex = _projectMetadata.projectSetting.findIndex(obj => obj.userid === usersStore.currentUser.id);
                        if (objIndex > -1) {
                            if (_projectMetadata.projectSetting[objIndex].projectSetting && Object.keys(_projectMetadata.projectSetting[objIndex].projectSetting).length > 1) {
                                isUpdate = false
                            } else {
                                _projectMetadata.projectSetting[objIndex].projectSetting = { ...projectSettingStore.systemProjectSetting, ..._projectMetadata.projectSetting[objIndex].projectSetting }
                            }
                        } else {
                            _projectMetadata.projectSetting.push(_projectSetting)
                        }
                    } else {
                        _projectMetadata.projectSetting = [_projectSetting]
                    }

                    if (isUpdate) {
                        await projectStore.updateProjectMetadata({ metadata: _projectMetadata }).then((res) => {
                            projectStore.projectDetail.metadata = res.metadata
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }
            }

            updateProjectSetting().catch(console.error);
            const startup_image = projectStore.projectDetail?.startup_image?.url
            setimageUrl(startup_image ? startup_image : XDTwin_Guide)
            if (usersStore.currentUser.id) {
                let prjProjectSetting = projectStore.projectDetail.metadata?.projectSetting?.find(x => x.userid === usersStore.currentUser.id)
                if (prjProjectSetting && prjProjectSetting.projectSetting) {
                    if (prjProjectSetting.projectSetting?.show_quick_guide === undefined) {
                        setShowTwinGuideDialog(true)
                    } else {
                        setShowTwinGuideDialog(prjProjectSetting.projectSetting?.show_quick_guide === true)
                    }
                } else {
                    setShowTwinGuideDialog(true)
                }
            } else {
                var retrievedObject = JSON.parse(localStorage.getItem(`show_quick_guide-${projectStore.projectDetail.id}`));
                if (retrievedObject) {
                    setShowTwinGuideDialog(retrievedObject.show_quick_guide)
                } else {
                    setShowTwinGuideDialog(true)
                }
            }
        }
    }, [projectStore.projectDetail])

    useEffect(() => {
        if (showTwinGuideDialog) {
            projectStore.setShowTwinGuideDialog(true)
        }
    }, [showTwinGuideDialog])

    return (
        <>
            <div className="modal-xdtwin-guide" />
            <Modal
                maskClosable={false}
                visible={showTwinGuideDialog && !usersStore.cookie}
                className={isTabletOrMobile ? "XDTwinGuideModalMobile" : "XDTwinGuideModalPC"}
                onOk={handleOk}
                centered
                title=" "
                onCancel={handleCloseTwinGuide}
                zIndex={9998}
                footer={
                    <div className={isFooterOrMobile ? '' : 'footer-modal'} >
                        <Checkbox onChange={onChange} style={isTabletOrMobile ? {} : { marginTop: "5px" }}>{t('do-not-display-this-again')}</Checkbox>
                        <div style={isTabletOrMobile ? { textAlign: 'right' } : {}} >
                            <Button key="back" onClick={handleCloseTwinGuide} size={isTabletOrMobile ? 'small' : 'default'}>
                                {t('commons.cancel')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleOk}
                                size={isTabletOrMobile ? 'small' : 'default'}
                            >
                                {t('commons.ok')}
                            </Button>
                        </div>
                    </div>
                }
                getContainer={() => {
                    return document.getElementsByClassName("modal-xdtwin-guide")[0];
                }}
            >
                {
                    imageUrl ? (
                        <img src={imageUrl} className='img-xdtwin-Guide' alt="Startup-Guide" />
                    ) : <img src={XDTwin_Guide} className='img-xdtwin-Guide' alt="XDTwin-Guide" />
                }
            </Modal>
        </>
    )
}

export default withRouter(inject('usersStore', 'projectStore', 'projectSettingStore')(observer(XDTwinGuideModal)))
