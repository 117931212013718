import styled from 'styled-components'

export const TeamsPageWrapper = styled.div`
.splitpanel-left {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
}

.splitpanel-mobile-left {
    top: 0;
    right: 0; 
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-left: -15px;
    padding: 0 !important;
    }
}
`
export const ProjectMemberList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`
export const ProjectMemberCard = styled.li`
  display: block;
  padding: 15px;
  background-color: #f3f3f3;
  position: relative;
  transition: ease .3s;
  margin-right: 20px;
  margin-bottom: 40px; 
  &:hover {
    transition: ease .3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  }
  &:before {
    display: inline-block;
    content: '${props => props.badge}';
    // position: absolute;
    bottom: 100%;
    // left: 30%;    
    background-color: ${props => props.currentUser ? '#f26524' : '#d0d0d7'};
    color: ${props => props.currentUser ? 'white' : '#191919'};
    font-size: 12px;
    text-transform: capitalize;
    padding: 5px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -44px;
  }
  .card-avatar {
    text-align: center;
    position: relative;
    img {
      border-radius: 50%;
      width: 180px;
      max-height: 180px;
      object-fit: cover;
      opacity: ${props => props.status === 'user_not_exist' || props.status === 'waiting_accept' ? .2 : 1};
    }
    &:after {
      display: ${props => props.status === 'user_not_exist' || props.status === 'waiting_accept' ? 'block' : 'none'};
      content: '${props => props.status === 'user_not_exist' ? 'Not register' : props.status === 'waiting_accept' ? 'Pending' : null}';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      text-shadow: 0 0 15px rgba(0, 0, 0, .3), 0 0 15px rgba(0, 0, 0, .3), 0 0 15px rgba(0, 0, 0, .3), 0 0 15px rgba(0, 0, 0, .3);
      color: white;
    }
  }
  .card-title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #191919;
    padding: 10px 0;
  }
  .card-subtitle {
    text-align: center;
    font-size: 14px;
    color: #999999;
    p {
      margin-bottom: 0;
      word-break: break-all;
    }
  }
`

export const List = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  dt {
    width: 130px;
    position: relative;
    padding-left: 0px;
    .color-svg {
      position: absolute;
      left: 0;
    }
  }
  dd {
    width: calc(100% - 130px);
    padding-left: 15px;
    span {
      overflow: hidden;
      display: block;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
  dt, dd {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const CustomTableUserOrganization = styled.div`
    background: #FFFFFF;
    box-shadow: 0 2px 10px rgb(0 0 0 / 5%);
    border-radius: 10px;
    padding: 15px;
    .title { 
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
`
export const SearchOrganizationWrapper = styled.div`
    display : flex ;
    margin-top : 5px;
    margin-bottom : 10px;
    width : 100%;
    height : 28px;
    border: 1px solid #d9d9d9;
`
export const CustomSearch = styled.div`
    flex-grow : 1;
    .ant-input-affix-wrapper{
        flex-grow : 1;
        height : 28px ;
        border : none ;
        background : none ;
        outline : none ;
        input {
        border : none ;
        background : none ;
        outline : none ;
        height : 100%;
      }
    }
    .ant-input-affix-wrapper:hover{
        border : none;
    }
    .ant-input-affix-wrapper-focused{
        border : none;
        background : none ;
        outline : none ;
        box-shadow : none;
    }
    
`
export const CustomButton = styled.div`
    display : flex;
    justify-content : center ;
    align-items : center ;
    padding : 0 5px;
    font-size : 11px
`
export const HeaderDrawerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 30px;
    }
`

export const DrawerContentHeader = styled.div`
  display: ${props => ((props.visible || props.visible === undefined) ? 'block' : 'none')};
  padding: 10px 10px
`