import styled from 'styled-components'
import { Button } from 'antd'

export const SliderContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin: 0px 0px 50px 0px;
  width: 90%;
  z-index: 9999;
`

export const CenteredContent = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  vertical-align: middle;
`

export const CenteredContentBigIcon = styled(CenteredContent)`
  font-size: 32px;
  > .anticon {
    color: white;
    &:hover {
      cursor: pointer;
      color: #1da57a;
    }
  }
`

export const ButtonCustom = styled(Button)`
  padding: 0 !important;
  margin: 0;
  border-radius: 5px !important;
`
