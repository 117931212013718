import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { Drawer } from 'antd'
import TilesetListPanel from './TilesetListPanel'

const DrawerTilesetExplorer = props => {
  const { projectStore } = props

  const DrawerHeader = () => {
    return (
      <Fragment>
        <div className="ant-drawer-title">
          <span className="explorer-title">All Tilesets</span>
        </div>
      </Fragment>
    )
  }

  return (
    <Drawer
      id="pageDetailDrawer"
      width={361}
      onClose={() => projectStore.setDisplayPanel(false)}
      visible={projectStore.displayPanel}
      title={<DrawerHeader />}
      placement="left">
      <TilesetListPanel />
    </Drawer>
  )
}

export default withRouter(
  inject('projectStore', 'capturesStore')(observer(DrawerTilesetExplorer))
)
