import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { inject, observer } from 'mobx-react'
import { Wrapper } from './CustomStyled'
import { Trans, useTranslation } from 'react-i18next';

const NotFoundPage = ({ commonStore }) => {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
    <Wrapper>
      <Helmet>
        <title>404 | 6DPlanner</title>
      </Helmet>
      <div className="content">
        <div className="browser-bar">
          <span className="close button"/>
          <span className="min button"/>
          <span className="max button"/>
        </div>
        <div className="text">
          <p>
            {t('bash-oops-the-page-you-are-looking-for-was-not-found-go-back')} <Link to={'/'}  style={{ color: commonStore.appTheme.solidColor }}>{t('commons.home')}</Link>
          </p>
          <p>Users-MBP:~ user$ <span className="indicator"/></p>
        </div>
      </div>
    </Wrapper>
    </HelmetProvider>
  )
}

export default inject('commonStore')(observer(NotFoundPage))
