import styled from 'styled-components'
export const AttributeWrapper = styled.div`
    .splitpanel-left {
        top: 0;
        right: 0;
        width: 10px;
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        border-top: 1px solid #ddd;
        background-color: #f2cfc4;
    }
  
    .splitpanel-mobile-left {
        top: 0;
        right: 0; 
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        .btnSplitPanel {
        position: absolute;
        top: 50%;
        margin-left: -15px;
        padding: 0 !important;
        }
    }
`

export const HeaderDrawerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 20px;
    }
`