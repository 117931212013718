import styled from 'styled-components'
export const TableLicenses = styled.table`
  border-collapse: collapse;
  width: 100%;
  td, th {
  border: 1px solid #dddddd;
  padding: 8px;
}
  td {
  text-align: left;
}
  th {
  text-align: center;
  background-color: #dddddd;
}
 .btn.btn-link {
    border: none;
    background: none;
    outline: none;
    padding: 3px;
}
.action-tool{
    display :flex;
    justify-content: center;
    align-items:cemter;
}
`