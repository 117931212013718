import { Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import { useTranslation } from 'react-i18next';

function ModalDataAttribute({ projectStore, viewer }) {
    const { t } = useTranslation();
    const [attributeURL, setAttributeURL] = useState([])
    const [containGoogle, setContainGoogle] = useState(false)
    const [defaultList, setDefaultList] = useState([])
    let models = projectStore.modelList

    const handleCancel = () => {
        projectStore.setDataAttributeModal(false);
    };

    useEffect(() => {
        if (models) {
            
            setAttributeURL([])
            const attributeURLList = []
            try {
                const defaultCredit = viewer.current?.cesiumElement?.cesiumWidget?.creditViewport.getElementsByTagName('li')
                const defaultCreditList = Array.from(defaultCredit)
                setDefaultList(defaultCreditList)
            } catch (error) {

            }
            models.map(model => {
                let find = attributeURLList.find(att => att.src === model.src)
                if (!find) {
                    attributeURLList.push(model)

                }
                if (model?.data?.ionAssetId === 2275207 || model?.src?.indexOf('https://tile.googleapis.com/v1/3dtiles/root.js') > -1) {

                    setContainGoogle(true)
                }
            })
            //setAttributeURL(attributeURLList)
        }
    }, [models, projectStore.dataAttributeModal])

    return (
        <Modal
            className="dataAttribute-modal"
            title={false}
            visible={projectStore.dataAttributeModal}
            onCancel={handleCancel}
            footer={false}
        >

            <h3 className="title">{t('data-provided-by')}: </h3>
            <div>

                <ul >
                    {
                        defaultList.map((x, i) => <li key={i}

                            dangerouslySetInnerHTML={{
                                __html: x.innerHTML,
                            }}
                        />)
                    }
                    {/* {
                        containGoogle ? <>
                            <li>
                                <span>Google</span>
                            </li>
                            <li>
                                <span>GoData SIO, NOAA, U.S. Navy, NGA, GEBCOogle</span>
                            </li>
                            <li>
                                <span>Landsat / Copernicus</span>
                            </li>
                            <li>
                                <span>IBCAO</span>
                            </li>
                        </> : null
                    } */}
                    {
                        attributeURL?.filter(att => (att.sourceType === "WMS" || att.sourceType === "WFS" || att.sourceType === 'external') && att.src).map((att, i) => {
                            if (att.src.indexOf('https://tile.googleapis.com/v1/3dtiles/root.js') > -1 || containGoogle) {

                            }
                            else
                                return (

                                    <li key={i}>
                                        <span>{att.src.split('?')[0]}</span>
                                    </li>
                                )
                        })
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default withRouter(inject('projectStore')(observer(ModalDataAttribute)))
