import { Button, Drawer, Form, message, Select, Tooltip, InputNumber, Switch } from 'antd'
import { CameraEventType } from 'cesium'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import validator from '../../../../validator'
import { DrawerHeaderWrapper, FormLayout, NavigationStyled } from './CustomStyled'
import { useTranslation } from 'react-i18next';
import HelpButton from '../../../elements/HelpButton'
import { isMobile, isTablet } from 'react-device-detect'
import { is6DPLANNER } from '../../../../lib/projectLib'
const { Option } = Select;

const DrawerUserNavigationSetting = props => {
  const { projectStore, usersStore, viewer } = props
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const scene = viewer.scene;

  const tailLayout = {
    wrapperCol: {
      span: 24,
    },
  };

  const DrawerHeader = () => {
    return (
      <DrawerHeaderWrapper>
        <span>{t('navigation-settings')}</span>
        <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <div>
           <HelpButton helppage={"system_settings_navigation_styles"} hovered={'black'}/>
          </div>
        </Tooltip>
      </DrawerHeaderWrapper>
    )
  }

    const [showCustom, setShowCustom] = useState(false);
    const [allowUnderground, setAllowUnderground] = useState(projectStore.navigationStyles?.allowUnderground ?? true);

    const handleSubmit = values => {
      let _control = {}
      if (values.navigationstyle == 'custom') {
        _control = {
          pan: values.pan,
          zoom: values.zoom,
          tilt: values.tilt,
          rotate: values.rotate
        }
      }

      let _navstyle = {
        userId: usersStore.currentUser.id,
        type: values.navigationstyle,
        control: _control,
        distanceLimit: values.distanceLimit,
        allowUnderground: allowUnderground
      }

      let _projectMetadata = projectStore.projectDetail.metadata
      if (_projectMetadata.navigationInstructions && _projectMetadata.navigationInstructions.length > 0) {
        if (_projectMetadata.navigationInstructions.length > 1) {
          _projectMetadata.navigationInstructions[0].type = values.navigationstyle
          _projectMetadata.navigationInstructions[0].control = _control
          _projectMetadata.navigationInstructions[0].distanceLimit = values.distanceLimit
          _projectMetadata.navigationInstructions[0].allowUnderground = allowUnderground
        }
        const objIndex = _projectMetadata.navigationInstructions.findIndex(obj => obj.userId === usersStore.currentUser.id);
        if (objIndex > -1) {
          _projectMetadata.navigationInstructions[objIndex].type = values.navigationstyle
          _projectMetadata.navigationInstructions[objIndex].control = _control
          _projectMetadata.navigationInstructions[objIndex].distanceLimit = values.distanceLimit
          _projectMetadata.navigationInstructions[objIndex].allowUnderground = allowUnderground
        } else {
          _projectMetadata.navigationInstructions.push(_navstyle)
        }
      } else {
        _projectMetadata.navigationInstructions = [_navstyle]
      }
      
      if (usersStore.currentUser.id && projectStore.projectDetail.projectuserroles.find(pur => pur.email === usersStore.currentUser.email && pur.inviteStatus === 'accepted')) {
        let newData = {
          metadata: _projectMetadata, store : 'metadata'
        }
        projectStore.setLoadingProgress(true)
        projectStore.updateProjectData(newData).then((res) => {
          // let prjNavigationInstructions = res.metadata.navigationInstructions.find(x => x.userId === usersStore.currentUser.id)
          let prjNavigationInstructions = res?.metadata?.navigationInstructions || [];
          const resultNavigationInstructions = prjNavigationInstructions[0];
          projectStore.setNavigationStyles(resultNavigationInstructions)
          message.success(t('updated-successfully'))
          projectStore.setShowDrawerConnectNavigation(false)
          projectStore.setLoadingProgress(false)
        }).catch(err => {
          if (err && err.data && err.data.message) {
            message.error(t(err.data.message))
          } else {
            message.error(t('updated-failed'))
          }
          projectStore.setLoadingProgress(false)
        })
      } else {
        projectStore.setNavigationStyles({ userId: usersStore.currentUser.id, type: values.navigationstyle, control: _control, distanceLimit: values.distanceLimit, allowUnderground: allowUnderground })
        message.success(t('updated-successfully'))
        projectStore.setShowDrawerConnectNavigation(false)
        projectStore.setLoadingProgress(false)
      }
    }

    const onReset = () => {
      form.resetFields();
      form.setFieldsValue({
         navigationstyle: projectStore?.navigationStyles?.type || 'xdTwin',
          distanceLimit: projectStore?.navigationStyles?.distanceLimit || -1,
           allowUnderground: projectStore?.navigationStyles?.allowUnderground ?? true
          })
      setAllowUnderground(projectStore.navigationStyles?.allowUnderground ?? true);
      if (projectStore.navigationStyles && projectStore.navigationStyles.type === 'custom') {
        setShowCustom(true)
      } else {
        setShowCustom(false)
      }
    }

    function handleChangeNavigation(value) {
      setShowCustom(value === "custom");
    }

    useEffect(() => {
      if (projectStore.showDrawerConnectNavigation) {
        if (projectStore.navigationStyles) {
          form.setFieldsValue({
             navigationstyle: projectStore.navigationStyles.type || 'xdTwin',
             distanceLimit: projectStore.navigationStyles?.distanceLimit || -1,
             allowUnderground: projectStore.navigationStyles?.allowUnderground ?? true
              })
            setShowCustom(projectStore.navigationStyles.type === 'custom' ? true : false)
        }
        setAllowUnderground(projectStore.navigationStyles?.allowUnderground ?? true);
      }
    }, [projectStore.showDrawerConnectNavigation,projectStore.navigationStyles])

    useEffect(()=>{
      if (scene) {
        scene.screenSpaceCameraController.enableCollisionDetection = !allowUnderground;
        scene.requestRender()
      }
    }, [allowUnderground])

  return (
    <Drawer
      className={'navigation-drawer'} id={'navigation-drawer'}
      width={360}
      placement={'left'}
      title={<DrawerHeader />}
      onClose={() => projectStore.setShowDrawerConnectNavigation(false)}
      visible={projectStore.showDrawerConnectNavigation}>
      <FormLayout>
        <Form layout="vertical" form={form} name="navigation-style-form" onFinish={handleSubmit}>
          <Form.Item
            label={t('navigation-presets')}
            name="navigationstyle"
            rules={[
              { validator: validator.validateEmptyString },
            ]}>
            <Select onChange={handleChangeNavigation}>
              <Option value="xdTwin">{is6DPLANNER(window?.location?.origin) ? "6DPlanner": "xD Twin"} </Option>
              <Option value="cesium">Cesium</Option>
              <Option value="connect">Connect</Option>
              <Option value="custom">Custom</Option>
            </Select>
          </Form.Item>
          {showCustom && <NavigationStyled>
            <div className="code-box-title">
              {t('settings')}
            </div>
            <div className="code-box-description">
              <Form.Item
                label={t('pan')}
                name="pan"
                initialValue={projectStore.navigationStyles.control.pan}
                rules={[
                  {
                    required: true,
                    message: t('please-select-pan-events'),
                  },
                ]}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('please-select')}
                >
                  {Object.keys(CameraEventType).map(key => (
                    <Option key={key} value={CameraEventType[key]}>
                      {key}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('zoom')}
                name="zoom"
                initialValue={projectStore.navigationStyles.control.zoom}
                rules={[
                  {
                    required: true,
                    message: t('please-select-zoom-events'),
                  },
                ]}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('please-select')}
                >
                  {Object.keys(CameraEventType).map(key => (
                    <Option key={key} value={CameraEventType[key]}>
                      {key}
                    </Option>
                  ))}
                </Select>

              </Form.Item>
              <Form.Item
                label={t('tilt')}
                name="tilt"
                initialValue={projectStore.navigationStyles.control.tilt}
                rules={[
                  {
                    required: true,
                    message: t('please-select-tilt-events'),
                  },
                ]}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('please-select')}
                >
                  {Object.keys(CameraEventType).map(key => (
                    <Option key={key} value={CameraEventType[key]}>
                      {key}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('rotate')}
                name="rotate"
                initialValue={projectStore.navigationStyles.control.rotate}
                rules={[
                  {
                    required: true,
                    message: t('please-select-rotate-events'),
                  },
                ]}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('please-select')}
                >
                  {Object.keys(CameraEventType).map(key => (
                    <Option key={key} value={CameraEventType[key]}>
                      {key}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </NavigationStyled>
          }
          <Form.Item 
            label={t('distance-limit')}
            name={'distanceLimit'}
          >
            <InputNumber min={-1} className='distance-limit-input'/>
          </Form.Item>
          <Form.Item valuePropName="checked" name="allowUnderground" className='navigation-underground'>
            {t('allow-underground')}
            <Switch
              checkedChildren={t('commons.yes')}
              unCheckedChildren={t('commons.no')}
              checked={allowUnderground}
              defaultChecked={allowUnderground}
              onChange={(v) => {
                  if (isNaN(v)) v = false
                  setAllowUnderground(v)
              }}
              style={{ marginLeft: '5px' }}
              />
          </Form.Item>
          <Form.Item {...tailLayout} className='text-c'>
            <Button type={'primary'} htmlType={'submit'}>{t('commons.save')}</Button>
            <Button htmlType="button"
              onClick={onReset}
            >
              {t('commons.reset')}
            </Button>
          </Form.Item>
        </Form>
      </FormLayout>
    </Drawer>
  )
}

export default withRouter(inject('projectStore', 'usersStore')(observer(DrawerUserNavigationSetting)))
