import styled from 'styled-components'
import { Col } from 'antd';
export const SearchOrganizationWrapper = styled.div`
    display : flex ;
    margin-top : 5px;
    ${'' /* margin-left : auto ; */}
    ${'' /* margin-right : auto ; */}
    width : 100%;
    max-width : 400px;
    height : 32px;
    border: 1px solid #d9d9d9;
`
export const CustomSearch = styled.div`
    flex-grow : 1;
    .ant-input-affix-wrapper{
        flex-grow : 1;
        height : 32px ;
        border : none ;
        background : none ;
        outline : none ;
        input {
            border : none ;
            background : none ;
            outline : none ;
            height : 100%;
        }
    }
    .ant-input-affix-wrapper:hover{
        border : none;
    }
    .ant-input-affix-wrapper-focused{
        border : none;
        background : none ;
        outline : none ;
        box-shadow : none;
    }
    
`
export const CustomButton = styled.div`
    display : flex;
    justify-content : center ;
    align-items : center ;
    padding : 0 5px;
    font-size : 11px
`

export const SettingWrapper = styled.div`
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 10px;
    border-radius: 10px;
    padding: 15px;
`

export const ItemSetting = styled.div`
    width : 100%;
    max-height : 300px;
    overflow-y: scroll;
    border: 1px solid rgb(246, 246, 246);
    -ms-overflow-style: none; 
    scrollbar-width: none;
    ::-webkit-scrollbar {
    display: none; }  

    .text-left{
        text-align : left;
    }
`
export const ItemList = styled.div`
    border-bottom: 1px solid rgb(246, 246, 246);
    padding: 0rem 1rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 3.2rem;
    cursor: pointer;
    &:hover{
        background-color : #F6F6F6;
        }
    `
export const NameUser = styled.div`
        text-align: start;
    `
export const EmailUser = styled.div`
        cursor: pointer;
        color: red;
        float: left;
        width:100%
    `

export const StatusUser = styled.div`
    display: flex;
`
export const ItemDateLicenses = styled.div`
display: flex;
`

export const TableLicenses = styled.table`
  border-collapse: collapse;
  width: 100%;
  td, th {
  border: 1px solid #dddddd;
  padding: 8px;
}
  td {
  text-align: left;
}
  th {
  text-align: center;
  background-color: #dddddd;
}
 .btn.btn-link {
    border: none;
    background: none;
    outline: none;
    padding: 3px;
}
.action-tool-license{
    display :flex;
    justify-content: center;
    align-items:cemter;
}
`

export const LogoWrapper = styled.div`
position: relative;
  .delete-icon{
    display: none;
    cursor: pointer;
    position: absolute;
    top: 3px;
    left: 120px;
    z-index: 999;
    background: none;
  }
  &:hover .delete-icon{
    display: block;
  }
`