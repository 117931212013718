import { Select, Spin, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
const { Option } = Select;

const CustomUserField = ({ adminStore, valuePass, onSelect }) => {
    const { t } = useTranslation();
    const [listUserDisplay, setListUserDisplay] = useState([]);
    const [listUserOption, setListUserOption] = useState([]);
    const [fetching, setFetching] = useState(false);
    const { organizationID } = useParams();
    const [dropdown, setDropdown] = useState(false);

    let lastFetchId = 0;
    useEffect(() => {
        if (organizationID !== "new") {
            setListUserOption(valuePass)
        }
    }, [valuePass])

    const handleSearchUsers = value => {
        setListUserOption([])
        if (value && value !== "") {
            lastFetchId += 1;
            const fetchId = lastFetchId;
            setListUserOption([])
            setFetching(true)
            adminStore.customFindUser('search', { valueFilter: value ? value.trim() : value })
                .then(res => {
                    if (fetchId !== lastFetchId) {
                        return;
                    }
                    setListUserOption(res.data)
                    setFetching(false)
                })
                .catch(err => {
                    setFetching(false)
                    setListUserOption([])
                })
        }
    };

    const handleChangeUsers = value => {
        setDropdown(true)
        setFetching(false)
        if (organizationID !== "new") {
            setListUserDisplay([])
        } else {
            let data = []
            adminStore.projectSearchList.map(el => {
                if (el.id = value.value) {
                    data.push(el)
                }
            })
            setListUserDisplay([])
        }
        setDropdown(false)
    };

    const handleClick = () => {
        setDropdown(!dropdown);
    }

    const onSelectUser = v => {
        onSelect(v, listUserOption)
    }

    return (
        <Select
            mode="multiple"
            labelInValue={organizationID === "new" ? true : false}
            tokenSeparators={[',']}
            value={listUserDisplay}
            placeholder={t('search-for-an-user')}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={debounce(handleSearchUsers, 1000)}
            onChange={v => handleChangeUsers(v)}
            style={{ width: '100%' }}
            open={dropdown}
            onFocus={() => setDropdown(true)}
            onBlur={() => setDropdown(false)}
            onDropdownVisibleChange={handleClick}
            onSelect={v => onSelectUser(v)}
        >
            {listUserOption && listUserOption.map(d => (
                <Option key={d.id} value={d.id}>
                    <Tooltip title={(d?.firstName && d?.lastName ?
                        d.firstName + ' ' + d.lastName : !d?.firstName && d?.lastName ?
                            d.lastName : d?.firstName && !d?.lastName ?
                                d.firstName : d.username) + " (" + d.email + ")"} 
                        placement="right"
                        overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div style={{ width: "100%" }}>
                            <div>
                                {
                                    (d?.firstName && d?.lastName ?
                                        d.firstName + ' ' + d.lastName : !d?.firstName && d?.lastName ?
                                            d.lastName : d?.firstName && !d?.lastName ?
                                                d.firstName : d.username) + " (" + d.email + ")"
                                }
                            </div>
                        </div>
                    </Tooltip>
                </Option>
            ))}
        </Select>

    );

}

export default inject("adminStore", "projectStore")(observer(CustomUserField));
