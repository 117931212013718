import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import DefaultTemplate from '../../layout/DefaultTemplate'
import { inject, observer } from 'mobx-react'
import { ProjectWraper, IntroWrapper } from './style'
import LoadingSpinner from '../../elements/LoadingSpinner'
import ModalAddProject from '../ProjectListPage/ModalAddProject'
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment'
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/sv";
import "moment/locale/es";
import "moment/locale/vi";
const HomePage = ({ usersStore, commonStore, history }) => {
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  useEffect(() => {
    commonStore.setCurrentPage('home')
  }, [])

  const checkAppLoading = () => {
    return !!(
      commonStore.isLoading ||
      usersStore.isLoading
    )
  }

  return (
    <HelmetProvider>
      <DefaultTemplate>
        <Helmet>
          <title>{t('commons.home')} | 6DPlanner Viewer</title>
        </Helmet>
        <ModalAddProject />
        <ProjectWraper>
          {/*<iframe src="https://corsproxy-eu-2pqbdk4vvq-ew.a.run.app/openbimtool.ideas.aha.io/" frameBorder="0" width="100%" height="100%"/>*/}
          {/* <iframe src="https://corsproxy-eu-2pqbdk4vvq-ew.a.run.app/openbimtool.xd-visuals.com/open-bim-viewer/" frameBorder="0" width="100%"/> */}
          <IntroWrapper>
            <div>
              <h1
                style={{
                  color: '#f26524',
                  fontSize: '30px',
                  lineHeight: '33px',
                }}>
                {t('welcome-title')}
              </h1>
              <Trans i18nKey="welcome-content" components={[<br />, <h3></h3>, <p></p>, <li></li>, <ol></ol>]} />
            </div>
            <div>
              <img
                alt="BIM Tools"
                width={'100%'}
                src="https://www.xd-visuals.fi/wp-content/uploads/2019/09/OBT_Karttapohja.png"
              />
            </div>
          </IntroWrapper>
          {
            checkAppLoading()
              ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
              : null
          }
        </ProjectWraper>
      </DefaultTemplate>
    </HelmetProvider>
  )
}

export default inject('usersStore', 'commonStore')(observer(HomePage))
