const merge = require('lodash/merge')

const config = {
  all: {
    env: process.env.NODE_ENV || 'production',
    isDev: process.env.NODE_ENV !== 'production',
    basename: process.env.PUBLIC_URL,
    isBrowser: typeof window !== 'undefined',
    corsproxy: process.env.REACT_APP_CORSPROXY || 'https://corsproxy-us-2pqbdk4vvq-uc.a.run.app/',
    cesiumToken: process.env.REACT_APP_ION_TOKEN,
    arcGisToken: process.env.REACT_APP_ARCGIS_TOKEN,
    bingMapKey: process.env.REACT_APP_BINGMAP_KEY,
    apiNcWmsUrl: process.env.REACT_APP_NCWMS_URL || 'https://ncwms.xd-twin.io',
    siteMode: process.env.REACT_APP_SITE_MODE || 'xd-twin',
    domain : {
      'XD' :  "https://www.xd-twin.io/",
      '6D' :  "https://www.6dplanner.com/"
    }
  },
  test: {},
  development: {
    apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:1337',
    assetUrl:
      process.env.REACT_APP_ASSET_URL || 'http://localhost:1337/upload/',
  },
  production: {
    apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:1337',
    assetUrl:
      process.env.REACT_APP_ASSET_URL || 'http://localhost:1337/upload/',
  },
}

module.exports = merge(config.all, config[config.all.env])
