import React, { useState, useEffect } from 'react';

import { Select, Spin, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
const { Option } = Select;

const CustomProjectField = ({ adminStore, onSelect, valuePass }) => {
  const { t } = useTranslation();
  const [listProjectDisplay, setListProjectDisplay] = useState([]);
  const [listProjectOption, setListProjectOption] = useState([]);
  const [fetching, setFetching] = useState(false);
  const { organizationID } = useParams();
  const [dropdown, setDropdown] = useState(false);
  let lastFetchId = 0;

  useEffect(() => {
    if (organizationID !== "new") {
      setListProjectOption(valuePass)

    }
  }, [valuePass])

  const handleSearchProjects = value => {
    setListProjectOption([])
    if (value && value !== "") {
      lastFetchId += 1;
      const fetchId = lastFetchId;
      setListProjectOption([])
      setFetching(true)
      adminStore.getSearchProjects(value)
        .then(res => {
          if (fetchId !== lastFetchId) {
            return;
          }
          setListProjectOption(res.data.filter(c => !c.isDeleted))
          setFetching(false)
        });
    }

  };

  const handleChangeProjects = value => {
    setFetching(false)
    setDropdown(true)
    if (organizationID !== "new") {
      setListProjectDisplay([])
      // setListProjectOption([])
    } else {
      let data = []
      adminStore.projectSearchList.map(el => {
        if (el.id = value.value) {
          data.push(el)
        }

      })
      // setListProjectOption([...data])
      setListProjectDisplay([])
    }
    setDropdown(false)
  };

  const handleClick = () => {
    setDropdown(!dropdown);
  }
  const onSelectProject = v => {
    onSelect(v, listProjectOption)
  }
  return (
    <Select
      mode="multiple"
      labelInValue={organizationID === "new" ? true : false}
      tokenSeparators={[',']}
      value={listProjectDisplay}
      placeholder={t('search-for-an-project')}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={debounce(handleSearchProjects, 500)}
      onChange={v => handleChangeProjects(v)}
      style={{ width: '100%' }}
      open={dropdown}
      onFocus={() => setDropdown(true)}
      onBlur={() => setDropdown(false)}
      onDropdownVisibleChange={handleClick}
      onSelect={v => onSelectProject(v)}
    >
      {listProjectOption && listProjectOption.map(d => (
        <Option key={d.id} value={d.id}>
          <Tooltip title={d.name} placement="left" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
            <div style={{ width: "100%" }}>
              <div
              >
                {d.name}
              </div>
            </div>
          </Tooltip>
        </Option>
      ))}
    </Select>

  );

}

export default inject("adminStore", "projectStore")(observer(CustomProjectField));
