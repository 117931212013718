import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { toJS } from 'mobx'
import { Trans, useTranslation } from 'react-i18next';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const TimeLineChart = ({ projectStore, dashboardStore }) => {
    const { t } = useTranslation();
    const colors = ['#55D8FE', '#FF8373', "#FFDA83", '#A3A0FB', "#e74c3c", "#7f8c8d", "#2c3e50", "#00008b", "#008b8b", "#a9a9a9", "#8b008b", "#556b2f", "#ff8c00", "#9932cc", "#8b0000", "#e9967a", "#9400d3", "#bdb76b", "#ff00ff", "#ffd700", "#008000", "#4b0082", "#f0e68c", "#add8e6", "#e0ffff", "#90ee90", "#d3d3d3", "#ffb6c1", "#ffffe0", "#00ff00", "#ff00ff", "#800000", "#000080", "#808000", "#ffa500", "#ffc0cb", "#800080", "#800080", "#ff0000", "#c0c0c0", "#ffffff", "#ffff00", "#006400",
    ]
    const [dataChart, setDataChart] = useState(dashboardStore.dataTopicTimeLineChart.timeLineChartData);
    const [maxY, setMaxY] = useState(dashboardStore.dataTopicTimeLineChart.maxYTimeLine);
    const [categories, setCategories] = useState(dashboardStore.curentCategories);
    // const [categories, setCategories] = useState(dashboardStore.dataTopicTimeLineChart.categories);
    useEffect(() => {
        let _categories = dashboardStore.curentCategories.reverse()
        setDataChart(dashboardStore.dataTopicTimeLineChart.timeLineChartData)
        setMaxY(dashboardStore.dataTopicTimeLineChart.maxYTimeLine)
        setCategories(_categories)
    }, [dashboardStore.dataTopicTimeLineChart])
    for( var i = 0 ; i< 7 ; i++){
        categories[i] = t(categories[i])
    }
    const options = {
        chart: {
            borderColor: '#ddd',
            borderWidth: 3,
            type: 'line'
        },
        title: {
            text: t('time'),
            align: 'center',
            verticalAlign: 'bottom',
        },
        colors: ['blue', 'red'],
        plotOptions: {
            line: {
                lineWidth: 3
            },
            tooltip: {
                hideDelay: 200
            },
        },
        subtitle: {
            text: '',
            x: -20
        },
        xAxis: {
            categories
        },
        yAxis: {
            title: {
                text: t('active-topics')
            },
            min: 0,
            max: maxY,
            plotLines: [{
                value: 0,
                width: 1
            }],
        },
        tooltip: {
            valueSuffix: '',
            crosshairs: true,
            shared: true
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            borderWidth: 1,
            borderColor: "#000000",
            padding: 5,
            itemMarginTop: 5,
            itemMarginBottom: 5,
            itemStyle: {
                lineHeight: '14px'
            },
            useHTML: true,
            labelFormatter: function () {
                var legendItem = document.createElement('div'),
                    symbol = document.createElement('span'),
                    labelwrap = document.createElement('div'),
                    label = document.createElement('span');


                legendItem.classList.add("timeline-detail");
                labelwrap.classList.add("label-wrap");


                // symbol.innerText = this.y.toFixed(2);
                symbol.style.borderColor = this.color;
                symbol.style.backgroundColor = this.color;
                symbol.classList.add('xLegendSymbol');
                label.innerText = this.name;



                labelwrap.appendChild(symbol);
                labelwrap.appendChild(label);
                legendItem.appendChild(labelwrap);


                return legendItem.outerHTML;
            }
        },
        series: dataChart,
        credits: {
            enabled: false
        },
        colors,
    }
    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
}

export default inject("projectStore", "dashboardStore")(observer(TimeLineChart));

