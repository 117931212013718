import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

function LogoutForm({ match, history, usersStore }) {
  // const { t } = useTranslation();
  const authType = match.params.authType

  useEffect(() => {
    if (authType === 'logout') {
      usersStore.userLogout()
        .then(() => {
          history.push(`/auth/login`)
        })
    }
  }, [])

  return (
    <div></div>
  )
}
export default withRouter(inject('usersStore')(observer(LogoutForm)))

