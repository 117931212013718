import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import uuid from 'uuid'
import Container from '../../layout/Container'
import DefaultTemplate from '../../layout/DefaultTemplate'
import AddTeamMemberModal from './AddTeamMemberModal'
import { ProjectMemberCard, ProjectMemberList, TeamsPageHeading, TeamsPageWrapper } from './ProjectTeamsPageStyled'
import { Trans, useTranslation } from 'react-i18next';
import { toJS } from 'mobx'
import moment from 'moment'

const ProjectTeamsPage = props => {
  const { t } = useTranslation();
  const {
    commonStore, projectTeamsStore, usersStore,
    match, adminStore, projectStore, licenseStore
  } = props

  const projectId = match.params.projectId

  useEffect(() => {
    commonStore.setCurrentPage('project-teams')
    const loadData = async () => {
      projectTeamsStore.setAppLoading(true)
      await projectStore.getProjectDetail(projectId)
      await projectTeamsStore.getProjectRoles()
      await projectTeamsStore.getProjectTeams(projectId)
      await projectStore.findProjectUserRole(projectStore.projectDetail.id)
      if (projectStore.projectDetail.organization) {
        await licenseStore.getQuotaLicenseOrganization(projectStore.projectDetail.organization.id).then(res => {
          projectTeamsStore.setAppLoading(false)
          adminStore.setLicenseMaxUser(res.licenseMaxUser || 0)
          adminStore.setListUserProjectUsageLicense(res.userUsage || 0)
        }).catch(error => {
          projectTeamsStore.setAppLoading(false)
        })
      }
    }
    loadData()

    return () => {
      projectTeamsStore.clearTeamList()
    }
  }, [])

  return (
    <HelmetProvider>
      <DefaultTemplate>
        <Helmet>
          <title>{t('team-members')} | 6DPlanner</title>
        </Helmet>
        <TeamsPageWrapper>
          <Container>
            <TeamsPageHeading>
              {t('team-members')}
            </TeamsPageHeading>
            <ProjectMemberList>
              {
                projectTeamsStore.teamList.map(member => {
                  if (!member.projectrole) return ''
                  if (member.email === "maintenance@xd-visuals.fi" || member?.user?.username === "maintenance") return
                  let role = projectTeamsStore.projectRoles[member?.projectrole?._id]?.roleName
                  const checkCurrentUser = () => {
                    if (!member.hasOwnProperty('user')) return false
                    if (member.user && member.user.username === usersStore.currentUser.username) return true
                  }
                  return (
                    <ProjectMemberCard
                      currentUser={checkCurrentUser()}
                      badge={role}
                      status={member.inviteStatus}
                      key={uuid()}>
                      <div className="card-avatar">
                        <img src={'https://style.anu.edu.au/_anu/4/images/placeholders/person.png'} alt="avatar" />
                      </div>
                      <div className="card-title">
                        {(member.user) ? member.user.username : t('user-not-found')}
                      </div>
                      <div className="card-subtitle">
                        <p>{member.email}</p>
                      </div>
                    </ProjectMemberCard>
                  )
                })
              }
            </ProjectMemberList>
          </Container>
        </TeamsPageWrapper>
        <AddTeamMemberModal />
      </DefaultTemplate>
    </HelmetProvider>
  )
}

export default inject('commonStore', 'usersStore', 'projectTeamsStore', 'adminStore', 'projectStore', 'licenseStore')(observer(ProjectTeamsPage))