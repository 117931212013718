
import { CloseCircleOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, Popconfirm, InputNumber } from 'antd'
import { inject, observer } from 'mobx-react'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
const { Option } = Select;
const ModalEditCustomAttribute = ({ projectStore, adminStore, visibleModal, selectedAttrData, isProjectCustomAtt, handleCloseModal, currentAttribute, handleUpdateAttribute, handleAddAttribute, handleDeleteAttribute }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false);
    const [typeattribute, setTypeattribute] = useState(false);
    const [levelattribute, setLevelAttribute] = useState(false);
    const [accumulationattribute, setAccumulationattribute] = useState(false);

    const projectId = projectStore.projectDetail.id
    const checkingFeatureRole = (type) => {
        if (!type) return false
        return adminStore.checkingFeatureRole(projectStore, type)
    }
    useEffect(() => {
        if (visibleModal) {
            if (currentAttribute) {
                form.setFieldsValue({
                    name: currentAttribute.name,
                    unit: currentAttribute.unit,
                })
                if (!isProjectCustomAtt) {
                    form.setFieldsValue({
                        value: currentAttribute?.value?.value
                    })
                }
                setLevelAttribute(currentAttribute.level)
                if(currentAttribute.type){
                    setTypeattribute(currentAttribute.type)
                    if(currentAttribute.type ==='number'){
                        if(currentAttribute.accumulation){
                            setAccumulationattribute(currentAttribute.accumulation)
                            form.setFieldsValue({
                                accumulation: currentAttribute.accumulation ? (currentAttribute.accumulation ==='repeat' ? 'during' : currentAttribute.accumulation) : undefined
                            })
                            if(currentAttribute.accumulation ==='cumulative'){
                                form.setFieldsValue({
                                    timescale: currentAttribute.timescale
                                })
                            }
                        }
                    }
                }
            }else{
                form.setFieldsValue({
                    level: 'file',
                })
            }
        }
        return () => {
            form.resetFields()
            setTypeattribute()
            setAccumulationattribute()
            setLevelAttribute()
        }
    }, [visibleModal])

    const onCancelModal = () => {
        handleCloseModal()
        form.resetFields()
    }

    const onChangeAttributeType = (type) => {
        setTypeattribute(type)
        if(type && type ==='number'){
            form.setFieldsValue({
                accumulation: 'during'
            })
        }else{
            form.resetFields(['unit','accumulation','timescale'])
        }
    }

    const onChangeAttributeAccumulation = (type) => {
        setAccumulationattribute(type)
        if(type && type ==='cumulative'){
            form.setFieldsValue({
                timescale: 'year'
            })
        }
    }

    const onSaveModal = () => {
        form.validateFields().then(values => {
            values.project = projectId
            if (isProjectCustomAtt) {
                if (currentAttribute) {
                    values.level = currentAttribute.level
                    handleUpdateAttribute(currentAttribute._id, {
                        ...values,
                        level : values.level ? values.level : 'file',
                        accumulation : values.accumulation ? (values.accumulation ==='repeat' ? 'during' : values.accumulation) : undefined
                    })
                } else {
                    handleAddAttribute(values)
                }
            } else {
                if (selectedAttrData) {
                    values.objectType = selectedAttrData.objectType
                    values.modelId = selectedAttrData.modelId
                }
                values.value = { value: values.value }
                if (currentAttribute) {
                    handleUpdateAttribute(currentAttribute._id, values)
                } else {
                    handleAddAttribute(values)
                }
            }
        })
    }

    return (
        <Form
            id={'edit-custom-attribute'}
            onFinish={onSaveModal}
            form={form}
            name="edit-custom-attribute"
            layout="vertical"
        >
            {
                !currentAttribute && isProjectCustomAtt && (
                    <Form.Item label={t('level')} name="level" rules={[
                        {
                            required: true,
                            message: t('please-select-type-of-level')
                        },
                    ]}>
                        <Select onChange={e =>setLevelAttribute(e)} style={{ width: '100%' }} >
                            <Option value="file">{t('file')}</Option>
                            <Option value="object">{t('object')}</Option>
                        </Select>
                    </Form.Item>
                )
            }
            <Form.Item label={t('name')} name="name" rules={[
                {
                    required: true,
                    message: t('please-input-name-of-attribute')
                },
            ]}>
                <Input placeholder={t('please-input-name-of-attribute')} />
            </Form.Item>
            {
                !currentAttribute && isProjectCustomAtt && (
                    <Form.Item label={t('type')} name="type" rules={[
                        {
                            required: true,
                            message: t('please-select-type-of-attribute')
                        },
                    ]}>
                        <Select onChange={onChangeAttributeType} style={{ width: '100%' }} >
                            <Option value="number">{t('number')}</Option>
                            <Option value="text">{t('text')}</Option>
                            <Option value="date" >{t('date')}</Option>
                        </Select>
                    </Form.Item>
                )
            }
            {
                isProjectCustomAtt  && typeattribute ==='number' && (
                    <Form.Item label={t('unit')} name="unit" rules={[
                {
                    required: false,
                },
            ]}>
                <Input placeholder={t('please-input-unit-of-attribute')} />
            </Form.Item>
                )
            }
            {
                 isProjectCustomAtt && typeattribute ==='number' &&(
                    <Form.Item  label={t('accumulation')} name="accumulation" rules={[
                        {
                            required: true,
                            message: t('please-select-accumulation')
                        },
                    ]}>
                        <Select onChange={onChangeAttributeAccumulation} style={{ width: '100%' }} >
                            <Option value="start">{t('start')}</Option>
                            <Option value="during" >{t('during')}</Option>
                            <Option value="end" >{t('end')}</Option>
                            <Option value="cumulative" >{t('cumulative')}</Option>
                        </Select>
                    </Form.Item>
                )
            }
            {
                 isProjectCustomAtt && typeattribute ==='number' && accumulationattribute ==='cumulative' && (
                    <Form.Item  label={t('timescale')} name="timescale" rules={[
                        {
                            required: true,
                            message: t('please-select-timescale')
                        },
                    ]}>
                        <Select style={{ width: '100%' }} >
                            <Option value="year">{t('year')}</Option>
                            <Option value="month" >{t('month')}</Option>
                            <Option value="week" >{t('week')}</Option>
                            <Option value="day" >{t('day')}</Option>
                            <Option value="hour" >{t('hour')}</Option>
                        </Select>
                    </Form.Item>
                )
            }
            {
                !isProjectCustomAtt && (
                        <Form.Item label={t('value')} name="value" rules={[
                            {
                                required: true,
                                message: t('please-input-custom-attribute-value')
                            },
                        ]}>
                            <Input placeholder={t('please-input-value-of-attribute')} />
                        </Form.Item>
                )
            }
            <Row
                justify="end"
            >
                <Button
                    onClick={onCancelModal}
                    icon={<CloseCircleOutlined />}
                >
                    {t('commons.cancel')}
                </Button>
                {
                    currentAttribute && checkingFeatureRole('feature_custom_attribute_edit') && (
                        <Popconfirm
                            title={t("are-you-sure-to-delete-this-attribute")}
                            onConfirm={() => handleDeleteAttribute(currentAttribute._id)}
                            okText={t('commons.yes')}
                            cancelText={t('commons.no')}
                        >
                            <Button
                                type="primary"
                                style={{ marginLeft: "10px" }}
                                loading={isLoadingFormEdit}
                                icon={<DeleteOutlined />}
                            >
                                {t('commons.delete')}
                            </Button>
                        </Popconfirm>

                    )
                }
                {
                    checkingFeatureRole('feature_custom_attribute_edit') && (
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: "10px" }}
                            loading={isLoadingFormEdit}
                            icon={<SaveOutlined />}
                        >
                            {t('commons.save')}
                        </Button>
                    )
                }
            </Row>
        </Form>
    )
}

export default inject('projectStore', 'adminStore')(observer(ModalEditCustomAttribute))