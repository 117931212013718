import { observable, action, decorate } from 'mobx';
import { FeedbackRequest } from '../requests'
import uuid from 'uuid'
import axios from 'axios'

class FeedbackStore {
    isLoading = false
    draggedItemFromBasicFields = {}
    submittedFormBuilder = {
        elements: [],
    }
    selectedElementIndex = undefined
    feedbackEditorList = []
    shownFeedbackAnswer = false
    feedbackFormDetail = false
    feedbackVisualization = [] //feedback anwer generic
    feedbackformNormalVisualization = []
    visibleFeedback = []
    visibleFeedbackForm = []
    showFeedbackEditorDrawer = false
    showAddLocationControl = false
    isEditingLocationControl = false
    locationControlData = undefined
    selectedFeedbackEditor = false
    currentFeedbackName = undefined
    showDrawerFeedbackReport = false
    hiddenTFeedback = []
    publicFeedbackAnswers = []

    setPublicFeedbackAnswer = data => {
        this.publicFeedbackAnswers = data || []
    }

    setIsEditingLocationControl = state => {
        this.isEditingLocationControl = state
    }

    setHiddenTFeedback = data => {
        this.hiddenTFeedback = data
    }

    setShowDrawerFeedbackReport = state => {
        this.showDrawerFeedbackReport = state
    }
    setlocationControlData = data => {
        this.locationControlData = data
    }
    setVisiblFeedback = p => {
        this.visibleFeedback = p
    }

    setVisiblFeedbackForm = p => {
        this.visibleFeedbackForm = p
    }
    setCurrentFeedbackName = name => {
        this.currentFeedbackName = name
    }

    clearVisiblFeedback = () => {
        this.visibleFeedback = []
    }

    clearSubmittedFormBuilder = () => {
        this.submittedFormBuilder = {
            elements: [],
        }
    }

    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading
    }

    selectItemFromBasicFields(item) {
        this.draggedItemFromBasicFields = item
    }

    addItemToFormPage(item) {
        let temp = {
            ...item,
            name: uuid(),
        }
        this.draggedItemFromBasicFields = {}
        this.submittedFormBuilder.elements.push(temp)
    }

    /**
     * Delete element
     * @param {*} index type number, index of element 
     */
    deleteElementFromPage(index) {
        this.selectedElementIndex = undefined
        if (this.submittedFormBuilder.elements[index].isDefault) {
            this.submittedFormBuilder.elements[index].isDelete = true
        } else {
            this.submittedFormBuilder.elements.splice(index, 1)
        }
    }

    /**
     * Show Properties box of control
     * @param {*} index 
     */
    showElementProperties(index) {
        this.selectedElementIndex = index
    }

    /**
     * 
     * @param {*} key :choices
     * @param {*} value : itemx 
     */
    updateElementProperties(key, value) {
        const elementIndex = this.selectedElementIndex
        const currentElement = this.submittedFormBuilder.elements[elementIndex]
        currentElement[key] = value
    }


    setFeedbackEditorList = (data) => {
        this.feedbackEditorList = data
    }

    clearFeedbackEditorList = () => {
        this.feedbackEditorList = []
    }

    /**
     * Auto create Generic feedback
     * @param {*} data type object
     */
    createAutoGenericFeedback = async (data) => {
        return FeedbackRequest.autoGeneralFeedbackGeneric(data).then((res) => {
            this.feedbackEditorList = [res.data]
        }).catch((err) => {
            console.log(err);
        })
    }

    /**
     * Create Feedback
     * @param {*} data 
     */
    createFeedbackEditor = (data) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.createFeedbackForm(data).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    /**
     * Delete Feedback Editor
     * @param {*} feedbackEditorId 
     */
    deleteFeedbackEditor(feedbackEditorId) {
        return new Promise((resolve, reject) => {
            FeedbackRequest.deleteFeedbackEditor(feedbackEditorId)
                .then((response) => {
                    const index = this.feedbackEditorList.findIndex(x => x.id === feedbackEditorId)
                    const temp = [...this.feedbackEditorList]
                    temp.splice(index, 1)
                    this.feedbackEditorList = temp
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    /**
     * Update feedback editor
     * @param {*} feedbackEditorId 
     * @param {*} data 
     */
    updateFeedbackEditor = (feedbackEditorId, data) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.updateFeedbackEditor(feedbackEditorId, data).then((res) => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    /**
     * show feedback answer drawer
     * @param {*} isShow boolean show drawer 
     * @param {*} isGenericFeedback boolean
     * @param {*} action add, edit, view
     * @param {*} data detail of feedback answer
     */
    setShowFeedbackAnswer = (isShow = false, isGenericFeedback = undefined, action = undefined, data = undefined) => {
        this.shownFeedbackAnswer = {
            show: isShow,
            isGenericFeedback: isGenericFeedback,
            action: action,
            data: data
        };
    }

    clearShowFeedbackAnswer = () => {
        this.shownFeedbackAnswer = false
    }

    /**
     * Get generic feedback form by project id
     * @param {*} projectId 
     */
    getGenericFeedback = async (projectId) => {
        return FeedbackRequest.getGenericFeedbackByProjectId(projectId)
            .then((response) => {
                this.setFeedbackFormDetail(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    setFeedbackFormDetail = (data) => {
        this.feedbackFormDetail = data
    }

    clearFeedbackFormDetail = () => {
        this.feedbackFormDetail = false
    }

    clearSelectedElementIndex = () => {
        this.selectedElementIndex = undefined
    }

    /**
     * Create Feedback answer
     * @param {*} data type object {}
     */
    createFeedbackAnswer = (data) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.createFeedbackAnswer(data).then((res) => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }
    /**
     * Update Feedback answer
     * @param {*} data type object {}
     */
    updateFeedbackAnswer = (id, data) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.updateFeedbackAnswer(id, data).then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    /**
     * Get feedback anwer list
     * @param {*} projectId 
     * @param {*} type generic, normal,
     */
    getFeedbackAnserList = (projectId, type) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.getFeedbackAnswerList(projectId, type).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    /**
     * set array feedback Visualization (array icon feed)
     * @param {*} arrdata
     */
    setFeedbackVisualization = arrdata => {
        this.feedbackVisualization = arrdata
    }

    /**
     * clear feedback Visualization    
     */
    clearFeedbackVisualization = () => {
        this.feedbackVisualization = []
    }

    /**
     * Get feedback answer detail by id
     * @param {*} id: id of feedback answer 
     */
    getFeedbackAnswerDetail = async (id) => {
        return FeedbackRequest.getFeedbackAnswerDetail(id)
            .then((response) => {
                this.feedbackFormDetail = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }

    /**
     * Delete feedback answer
     * @param {*} feedbackAnswerId 
     */
    deleteFeedbackAnswers = feedbackAnswerId => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.deleteFeedbackAnswers(feedbackAnswerId)
                .then((res) => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    /**
     * Set show feedback drawer
     * @param {*} state type boolean
     */
    setShowFeedbackEditorDrawer = state => {
        this.showFeedbackEditorDrawer = state
    }

    /**
     * set show add location for feedback editor
     * @param {*} f type boolean
     */
    setShowAddLocationControl = f => {
        this.showAddLocationControl = f
    }

    /**
     * Set setlected feedback editor
     * @param {*} obj feedback editor obj
     */
    setSelectedFeedbackEditor = obj => {
        this.selectedFeedbackEditor = obj
    }

    clearSelectedFeedbackEditor = () => {
        this.selectedFeedbackEditor = false
    }

    /**
     * Get feedback form list
     * @param {*} projectId 
     * @param {*} type : string 'generic' ; 'normal'; all
     */
    getFeedbackformsList = (projectId, type) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.getFeedbackformsList(projectId, type)
                .then((res) => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    setFeedbackformNormalVisualization = (arr) => {
        this.feedbackformNormalVisualization = arr
    }

    clearFeedbackformNormalVisualization = () => {
        this.feedbackformNormalVisualization = []
    }

    /**
     * Check exist generic feedback
     * @param {*} projectId     
     */
    checkExistGenericFeedback = (projectId) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.checkExistGenericFeedbackForm(projectId)
                .then((res) => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }


    /**
     * Decidim Login
     * @param {*} data {url, emai;password}
     * @returns 
     */    
    decidimConnect = (data) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.decidimConnector(data)
                .then((res) => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    /**
     * Add comment to decidim
     * @param {*} data (jwt_token:, url:, content:)
     * @returns 
     */
    decidimAddComment = (data) => {
        return new Promise((resolve, reject) => {
            FeedbackRequest.decidimAddComment(data)
                .then((res) => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

}


decorate(FeedbackStore, {
    isLoading: observable,
    setLoadingProgress: action,
    feedbackEditorList: observable,
    draggedItemFromBasicFields: observable,
    selectItemFromBasicFields: action,
    addItemToFormPage: action,
    submittedFormBuilder: observable,
    deleteElementFromPage: action,
    showElementProperties: action,
    selectedElementIndex: observable,
    updateElementProperties: action,
    setFeedbackEditorList: action,
    clearFeedbackEditorList: action,
    createAutoGenericFeedback: action,
    createFeedbackEditor: action,
    clearSubmittedFormBuilder: action,
    deleteFeedbackEditor: action,
    updateFeedbackEditor: action,
    setShowFeedbackAnswer: action,
    clearShowFeedbackAnswer: action,
    shownFeedbackAnswer: observable,
    getGenericFeedback: action,
    feedbackFormDetail: observable,
    setFeedbackFormDetail: action,
    clearFeedbackFormDetail: action,
    clearSelectedElementIndex: action,
    createFeedbackAnswer: action,
    getFeedbackAnserList: action,
    feedbackVisualization: observable,
    setFeedbackVisualization: action,
    clearFeedbackVisualization: action,
    getFeedbackAnswerDetail: action,
    setVisiblFeedback: action,
    clearVisiblFeedback: action,
    visibleFeedback: observable,
    deleteFeedbackAnswers: action,
    setShowFeedbackEditorDrawer: action,
    showFeedbackEditorDrawer: observable,
    isEditingLocationControl: observable,
    setIsEditingLocationControl: action,
    setShowAddLocationControl: action,
    showAddLocationControl: observable,
    selectedFeedbackEditor: observable,
    setSelectedFeedbackEditor: action,
    clearSelectedFeedbackEditor: action,
    getFeedbackformsList: action,
    feedbackformNormalVisualization: observable,
    setFeedbackformNormalVisualization: action,
    clearFeedbackformNormalVisualization: action,
    checkExistGenericFeedback: action,
    currentFeedbackName: observable,
    setCurrentFeedbackName: action,
    setShowDrawerFeedbackReport: action,
    showDrawerFeedbackReport: observable,
    setlocationControlData: action,
    locationControlData: observable,
    setHiddenTFeedback: action,
    hiddenTFeedback: observable,
    setVisiblFeedbackForm: action,
    visibleFeedbackForm: observable,
    decidimConnect: action,
    decidimAddComment: action,
    updateFeedbackAnswer: action,
    setPublicFeedbackAnswer :action,
    publicFeedbackAnswers : observable
})

export default new FeedbackStore()