import { observable, action, decorate } from 'mobx'
import moment from 'moment'
const TIME_STEPS = [
  { key: 'hour', value: 'Hour' },
  { key: 'day', value: 'Day' },
  { key: 'week', value: 'Week' },
  { key: 'month', value: 'Month' },
  { key: 'year', value: 'Year' }
]
const TIME_BEFORES = [
  { key: 'hour', value: 'Hour' },
  { key: 'day', value: 'Day' },
  { key: 'month', value: 'Month' },
  { key: 'year', value: 'Year' }
]

const TIME_AFTERS = [
  { key: 'hour', value: 'Hour' },
  { key: 'day', value: 'Day' },
  { key: 'month', value: 'Month' },
  { key: 'year', value: 'Year' }
]

const DEFAULT_TIMEBE = 'year'
const DEFAULT_TIMESTEP = 'year'
const DEFAULT_TIMEAF = 'year'

class SchedulingStore {
  timeSteps = TIME_STEPS
  timeBeforesStep = TIME_BEFORES
  timeAftersStep = TIME_AFTERS
  timeBeforeStep = DEFAULT_TIMEBE
  timeAfterStep = DEFAULT_TIMEAF
  timeSliderVisible = false
  isTargetTimeStep = false
  timeStep = DEFAULT_TIMESTEP
  originViewingTime = moment()
  currentViewingTime = moment(new Date())
  beforeTime = this.currentViewingTime
  afterTime = this.currentViewingTime
  beforeDateConvert = null
  afterDateConvert = null
  target4DIcon = false
  // originViewingTime = false
  // currentViewingTime = false
  isResetTileBeforeActive4D = false // for reset show/hide tile before active 4D

  toggleTimeSliderVisible = (f) => {
    if (f !== undefined) {
      this.timeSliderVisible = f
    } else {
      this.timeSliderVisible = !this.timeSliderVisible
    }
    if (this.timeSliderVisible === false) {
      this.setResetTileBeforeActive4D(true)
    }
  }

  setBeforeTime(time, number) {
    let beTime = this.beforeTime._d
    if (this.timeBeforeStep === 'hour') {
      beTime = beTime.setHours(beTime.getHours() - parseInt(number))
    } else if (this.timeBeforeStep === 'day') {
      beTime = beTime.setDate(beTime.getDate() - parseInt(number))
    } else if (this.timeBeforeStep === 'month') {
      beTime = beTime.setMonth(beTime.getMonth() - parseInt(number))
    } else if (this.timeBeforeStep === 'year') {
      beTime = beTime.setYear(beTime.getYear() - parseInt(number) + 1900)
    }

    if (time !== undefined) {
      this.beforeTime = moment(time)
    }
  }

  setAfterTime(time, number) {
    let afTime = this.afterTime._d
    if (this.timeAfterStep === 'hour') {
      afTime = afTime.setHours(afTime.getHours() + parseInt(number))
    } else if (this.timeAfterStep === 'day') {
      afTime = afTime.setDate(afTime.getDate() + parseInt(number))
    } else if (this.timeAfterStep === 'month') {
      afTime = afTime.setMonth(afTime.getMonth() + parseInt(number))
    } else if (this.timeAfterStep === 'year') {
      afTime = afTime.setYear(afTime.getYear() + parseInt(number) + 1900)
    }

    if (time !== undefined) {
      this.afterTime = moment(time)
    }
  }

  setBeforeDateConvert(value) {
    this.beforeDateConvert = value
  }

  setAfterDateConvert(value) {
    this.afterDateConvert = value
  }

  setTimeBeforeStep(timeStep) {
    this.timeBeforeStep = timeStep
  }

  setTargetTimeStep(timeStep) {
    this.isTargetTimeStep = timeStep
  }

  setTimeAfterStep(timeStep) {
    this.timeAfterStep = timeStep
  }

  setTimeStep(timeStep) {
    this.timeStep = timeStep
  }

  setCurrentViewingTime(value) {
    let date = moment(value)
    if (date.isValid()) {
      this.currentViewingTime = moment(value)
    } else {
      this.currentViewingTime = value
    }
  }

  setTarget4DIcon(stt) {
    this.target4DIcon = stt
  }


  /**
   * Set time origin
   * @param {*} value : 
   */
  setOriginViewingTime(value) {
    let date = moment(value)
    if (date.isValid()) {
      this.originViewingTime = moment(value)
    } else {
      this.originViewingTime = value
    }
  }

  /**
   * Action for reset visiable tile before active 4D timeline
   * @param {*} f boolean
   */
  setResetTileBeforeActive4D = (f) => {
    this.isResetTileBeforeActive4D = f
  }
}

decorate(SchedulingStore, {
  timeSliderVisible: observable,
  originViewingTime: observable,
  currentViewingTime: observable,
  afterTime: observable,
  beforeTime: observable,
  timeAfterStep: observable,
  beforeDateConvert: observable,
  afterDateConvert: observable,
  timeBeforeStep: observable,
  toggleTimeSliderVisible: action,
  setTimeBeforeStep: action,
  setTimeStep: action,
  setCurrentViewingTime: action,
  setOriginViewingTime: action,
  setBeforeTime: action,
  setTimeAfterStep: action,
  setAfterTime: action,
  setBeforeDateConvert: action,
  setAfterDateConvert: action,
  setResetTileBeforeActive4D: action,
  setResetTileBeforeActive4D: observable,
  setTargetTimeStep: action,
  isTargetTimeStep: observable,
  setTarget4DIcon: action,
  target4DIcon: observable
})

export default new SchedulingStore()
