import { Cartesian3, Cartographic, Math as CesiumMath } from 'cesium'
import { observable, action, decorate, computed } from 'mobx'
import { ObjectInforRequest } from '../requests'
class ModelStore {
  hpr = {
    h: 0,
    p: 0,
    r: 0
  }
  scale = 1
  fileOrigo = false // store fileOrigo after save edit model type {x:,y:, z:} Cartesian3
  updateFromViewer = false // when model change position will update input X Y Z
  xyzLocal = false // store xyzLocal
  projectRefPointOnProjectionPlane = false
  modelAttribute = {
    id: null,
    version: 0,
    GUID: null,
    modelType: null
  }
  geoHeight = false
  /**
   * Init modelStore when edit model called,  assign data for firstData
   * @param {*} refpoint 
   * @param {*} crs 
   */

  setModelAttribute = (data) => {
    this.modelAttribute = data
  }
  initFromMatrix = (refpoint, crs) => {
    let data = {
      x: refpoint.x,
      y: refpoint.y,
      z: refpoint.z,
      h: 0,
      p: 0,
      r: 0,
      LocalOrg: crs?.LocalOrg,
      ModelCenter: crs?.ModelCenter
    }
    if (data.x) {
      var pos = new Cartesian3.fromArray([data.x, data.y, data.z])
      var c = Cartographic.fromCartesian(pos)
      if (c) data.height = c.height
    }
    Object.assign(this.firstData, data)
  }

  setScale = (scale) => {
    if (!scale) scale = 1
    this.scale = scale
  }

  setHpr = (h, p, r) => {
    if (h === undefined) {
      h = 0
      p = 0
      r = 0
    }
    this.hpr.h = h
    this.hpr.p = p
    this.hpr.r = r
  }

  setFileOrigo = (v) => {
    this.fileOrigo = v
  }

  /**
   * 
   * @param {*} x: ref x
   * @param {*} y: ref y
   * @param {*} z: ref z
   */
  firstData = {
    height: 0,
    x: 0,
    y: 0,
    z: 0,
    h: 0,
    p: 0,
    r: 0,
    LocalOrg: [0, 0, 0],
    ModelCenter: [0, 0, 0]
  }

  get realHpr() {
    return {
      h: this.hpr.h + this.firstData.h,
      p: this.hpr.p + this.firstData.p,
      r: this.hpr.r + this.firstData.r,
    }
  }

  setUpdateFromViewer = v => {
    this.updateFromViewer = v
  }

  resetFromModel = (saveMatrix) => {
    this.updateFromViewer = true
    if (saveMatrix.xyzLocal && saveMatrix.xyzInput && saveMatrix.xyzLocal[0] === saveMatrix.xyzInput[0] && saveMatrix.xyzLocal[1] === saveMatrix?.xyzInput[1]) {
      this.setxyzLocal(saveMatrix.xyzInput)
    } else {
      this.setxyzLocal(saveMatrix.xyzLocal)
    }
    this.setFileOrigo(saveMatrix.fileOrigo)
    this.setScale(saveMatrix.scale)
    this.setHpr(saveMatrix.hpr.h, saveMatrix.hpr.p, saveMatrix.hpr.r)
  }

  resetAll = () => {
    this.firstData = {
      height: 0,
      x: 0,
      y: 0,
      z: 0,
      h: 0,
      p: 0,
      r: 0,
      LocalOrg: [0, 0, 0],
      ModelCenter: [0, 0, 0]
    }
    this.hpr = {
      h: 0,
      p: 0,
      r: 0
    }
    this.scale = 1
    this.fileOrigo = false
  }

  setxyzLocal = (v) => {
    this.xyzLocal = v
  }

  setProjectRefPointOnProjectionPlane = (v) => {
    this.projectRefPointOnProjectionPlane = v
  }
  getTreeObjectInfo = body => {
    return new Promise((resolve, reject) => {
      ObjectInforRequest.getTreeObjectInfo(body)
        .then((res) => {
          resolve(res)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  getTreeGuid = body => {
    return new Promise((resolve, reject) => {
      ObjectInforRequest.getTreeGuid(body)
        .then((res) => {
          resolve(res)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  setGeoHeight = (v) => {
    this.geoHeight = v
  } 

}

decorate(ModelStore, {
  hpr: observable,
  firstData: observable,

  scale: observable,
  setScale: action,
  setHpr: action,

  fileOrigo: observable,
  setFileOrigo: action,

  realHpr: computed,

  updateFromViewer: observable,
  setUpdateFromViewer: action,

  resetFromModel: action,
  initFromMatrix: action,

  resetAll: action,

  setxyzLocal: action,
  xyzLocal: observable,
  setProjectRefPointOnProjectionPlane: action,
  projectRefPointOnProjectionPlane: observable,

  getTreeObjectInfo: action,
  getTreeGuid: action,
  modelAttribute: observable,
  setModelAttribute: action,

  setGeoHeight: action,
  geoHeight: observable,
})

export default new ModelStore()
