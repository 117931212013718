import styled from 'styled-components'

export const ActionRow = styled.div`
  margin-top: 15px;
`

export const UploadWrapper = styled.div`
  max-height: 500px;
`

export const TilesetItemAction = styled.div`
  float: right;
  .anticon {
    margin-left: 5px;
  }
`
export const StepContainer = styled.div`
  margin-top: 20px;
  .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 30px);
    padding-right: calc(2em + 30px);    
  }
  .colorStyle {
    color: rgb(0 0 0 / 45%);
  }
`

export const EditPosLeftPanel = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px;
  z-index: 99999;
  width: 100%;
  max-width: 500px;
  height: 100%;
  background: white;
  overflow: auto;
  overflow-x: hidden;
  .readOnly
  {
    pointer-events: none;
    background-color: #f5f5f5;
    color:#bbb8b8
  }
  .inputDefault {
    background-color: #fff;
  }
  .wrapper-content{
    width: 100%;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
  .tile-info{
    font-weight: bold;
    text-decoration: underline;
  }
  .ant-row{
    width : 100%;
  }
  .help-btn-outer{
    margin-bottom: 10px;
    float: right;
    width: auto;
    border: none;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
  }
`
export const UpdateWmsPanel = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px;
  z-index: 99999;
  width: 361px;
  height: 100%;
  background: white;
  overflow: auto;
  .readOnly
  {
    pointer-events: none;
    background-color: #f5f5f5;
    color:#bbb8b8
  }
  .inputDefault {
    background-color: #fff;
  }
`

export const LocationPanelHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`
export const TilesetListTable = styled.div`
.ant-table-placeholder {
  display: none;
  }
`