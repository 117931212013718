import { Button, Form, Input, Modal, Row, Select, Spin, Tooltip, message } from 'antd'
import { inject, observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import validator from '../../../../validator';
import uuid from 'uuid';
import Utils from '../../../../utils'
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash'
import { isMobile, isTablet } from 'react-device-detect';
import { toJS } from 'mobx'
import { ModalAddAttribute, RuleContainer, RuleWrapper, SheetReportTool } from './style'
import {  MinusOutlined, PlusOutlined ,CloseCircleOutlined,SaveOutlined} from '@ant-design/icons';
import TreeUtils from '../../../../tree-utils'

var cancelToken

const CalculationEditor = ({ projectStore, calculationStore, updateByIndex, formSelect, defaultEmptySheet, saveLastSetting ,handleAddNewColumn ,addFileRowRule ,addObjectRowRule,defaultSheet ,folderList ,findOperator,findProjectCustomAttribute ,editColumnRules ,setObjectDatas }) => {
  const { t } = useTranslation();
  const { calculationEditor, setCalculationEditor, calculationsSettings, setCalculationsSettings, setSheetDatas } = calculationStore;
  const [form] = Form.useForm();
  const [formAddRowRule] = Form.useForm()
  const { store } = calculationStore?.calculationEditor;
  const filteredOptions = projectStore.prjCustomAttributeList ? projectStore.prjCustomAttributeList.filter(o => !o.isDeleted) : [];
  const { projectId } = useParams();
  const { Option } = Select

  const RenameCalculationForm = () => {
    useEffect(() => {
      if (calculationEditor?.open) {
        const element = calculationsSettings[calculationEditor.index || 0]
        form.setFieldsValue({ name: element?.name });
      }
      else {
        form.setFieldsValue({ name: '' });
      }
    }, [calculationEditor])

    return (
      <Form form={form} name="calculation-drawer" layout="vertical">
        <Form.Item label={t('name')} name="name" rules={[
          {
            required: true,
            message: t('please-input-the-name-of-calculation'),
          },
          { validator: validator.validateEmptyString },
        ]}>
          <Input
            placeholder={t('input-the-name-of-calculation')} />
        </Form.Item>
      </Form>
    )
  }

  const checkingTableLevel = (level) => {
    const element = calculationsSettings[calculationEditor.index || 0];
    return element?.level === level ? true : false
  }

  const splitTextObjectInfo = (text) => {
    const value = Utils.splitAtFirstSpecialCharacter(text, '=');
    return value ? value[0] : ''
  }

  const onCancelAddNewColumn = () => {
    handleCancel()
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
  }

  const DebounceObjectInfo = ({ }) => {
    const { t } = useTranslation();
    const [listProjectOption, setListProjectOption] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    let lastFetchId = 0;

    const handleSearchObjectInfo = value => {
      setListProjectOption([])
      if (value && value !== "") {
        lastFetchId += 1;
        const fetchId = lastFetchId;
        setListProjectOption([])
        setFetching(true)
        projectStore.searchObjectInfor(projectId, value)
          .then(res => {
            if (fetchId !== lastFetchId) {
              return;
            }
            setListProjectOption(res)
            setFetching(false)
          });
      }
    };

    const handleClick = () => {
      setDropdown(!dropdown);
    }

    return (
      <Form.Item
        label={t('attribute')}
        name="attribute"
        rules={[
          {
            required: true,
            message: t('cannot-be-emptied', { text: 'Attribute' }),
          },
        ]}>
        <Select
          allowClear
          labelInValue={true}
          showSearch
          optionFilterProp="children"
          placeholder={t('select-an-attribute')}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={debounce(handleSearchObjectInfo, 500)}
          style={{ width: '100%' }}
          open={dropdown}
          onFocus={() => setDropdown(true)}
          onBlur={() => setDropdown(false)}
          onDropdownVisibleChange={handleClick}
        >
          {listProjectOption &&
            listProjectOption.map(d => (
              <Option label={Utils.getObjectInfoV2Label(d.inf)} key={d._id} value={d._id}>
                <Tooltip
                  title={Utils.getObjectInfoV2Label(d.inf)}
                  placement="left"
                  overlayStyle={
                    isMobile || isTablet ? { display: 'none' } : undefined
                  }>
                  <div style={{ width: '100%' }}>
                    <div>{Utils.getObjectInfoV2Label(d.inf)}</div>
                  </div>
                </Tooltip>
              </Option>
            ))}
        </Select>
      </Form.Item>
    )
  }

  const {operator, setOperator} = calculationStore;

  const AddNewColumnForm = () => {

    const onChange = (e) => {
      setOperator(e)
    }

    useEffect(() => {
      if (store === 'editColumnRules' && !operator) {
        const data = calculationEditor?.data;
        const element = data?.value ;
        const position = calculationEditor?.position
        if(element){
          const columnRules = element?.columns || [];
          const crr =columnRules[position]
          form.setFieldsValue({ 
            name: crr?.name,
            operator : crr?.operator?.type 
          });
          if(crr?.operator?.type  ==='summary'){
            setOperator('summary')
            form.setFieldsValue({ 
              attribute: crr?.attribute?.id
            });
          }
        }
      }
    }, [calculationEditor])

    return (
      <Form
        form={form}
        name="add-new-report-form"
        layout="vertical">
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('column-name-cannot-be-emptied'),
            },
          ]}>
          <Input placeholder={t('please-input-column-name')} />
        </Form.Item>
        <Form.Item
          label={t('Operator')}
          name="operator"
          initialValue={operator}
          rules={[
            {
              required: true,
              message: t('cannot-be-emptied', { text: 'Operator' }),
            },
          ]}>
          <Select
            placeholder={t("select-an-operator")}
          onChange={onChange}
            options={[
              {
                value: 'summary',
                label: 'Summary',
              },
              {
                value: 'quantity',
                label: 'Quantity',
              }
            ]}
          />
        </Form.Item>
        {operator === 'summary' && (
          <div>
            {checkingTableLevel('file') && (
              <Form.Item
                label={t('attribute')}
                name="attribute"
                rules={[
                  {
                    required: true,
                    message: t('cannot-be-emptied', { text: 'Attribute' }),
                  },
                ]}>
                <Select
                  showSearch
                  placeholder={t("select-an-attribute")}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={filteredOptions.map(item => ({
                    value: item._id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            )}
            {checkingTableLevel('object') && <DebounceObjectInfo />}
          </div>
        )}
      </Form>
    )
  }

  const AddNewCalculationForm = () => {

    useEffect(() => {
      if (store === 'editCalculation') {
        const element = calculationsSettings[calculationEditor.index || 0]
        form.setFieldsValue({ name: element?.name, level: element?.level });
      }
      return () => {
        form.resetFields()
      }
    }, [calculationEditor])

    return (
      <Form form={form} name="calculation-drawer" layout="vertical">
        <Form.Item
          label={t('name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('report-name-cannot-be-emptied'),
            },
          ]}>
          <Input placeholder={t('please-input-report-name')} />
        </Form.Item>
        <Form.Item
          label={t('level')}
          name="level"
          rules={[
            {
              required: true,
              message: t('cannot-be-emptied', { text: 'Level' }),
            },
          ]}>
          <Select
            showSearch
            placeholder={t("select-report-level")}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={[
              {
                value: 'file',
                label: 'File',
              },
              {
                value: 'object',
                label: 'Object',
              },
            ]}
          />
        </Form.Item>
      </Form>
    )
  }

  const EditRowRuleForm = ({ sheet }) => {
    const [addRowRuleModal, setAddRowRuleModal] = useState(false)
    const [datas, setDatas] = useState(sheet)
    const [rowruletype, setRowRuleType] = useState(false)
    const [isLoading, setLoadingProgress] = useState(false)

    const openModalAddRowRule = () => {
      setAddRowRuleModal(true)
    }

    const handleRemoveRowRule = (ruleIndex) => {
      const sheetIndex = calculationStore?.calculationEditor.index || 0
      const element = calculationsSettings[sheetIndex]
      let rules = datas?.rules ? [...datas?.rules] : []
      let removeRule = rules[ruleIndex]
      if (removeRule && rules && rules.length === 2) {
        let newRules = rules.filter(c => c.type !== removeRule.type)
        const queryParams = {
          rowrules: newRules,
          columnrules: element?.columns || [],
          level: element?.level
        }
        rules.splice(ruleIndex, 1)
        if (element?.level === 'file') {
          addFileRowRule(queryParams, newRules, true)
        }
        if (element?.level === 'object') {
          addObjectRowRule(queryParams, newRules, true)
        }
        calculationStore.setSelectedCalculation({
          ...calculationStore.selectedCalculation,
          value : {
            ...calculationStore.selectedCalculation.value,
            rules : newRules
          }
        })
      } else {
        rules.splice(ruleIndex, 1)
        let { calculationEditor } = calculationStore
        const sheetIndex = calculationEditor.index || 0
        const temp = [...calculationsSettings]
        temp[sheetIndex].rules = []
        temp[sheetIndex].sheetdata = []
        setCalculationsSettings(temp)
        saveLastSetting(temp)
        setSheetDatas([defaultSheet({ ...element, rowrules: [] })])
        message.success(t("remove-column-successfully"))
        setCalculationEditor(false)
        calculationStore.setSelectedCalculation({
          ...calculationStore.selectedCalculation,
          value : {
            ...calculationStore.selectedCalculation.value,
            rules : []
          }
        })
      }
      setDatas({
        ...datas,
        rules
      })
    }

    const findIndexRule = (element,type) => {
      const isExist = element?.rules.findIndex(c => c.type === type)
      return isExist
    }

    const findProjectCustomAttribute = (id) => {
      const temp = [...projectStore.prjCustomAttributeList]
      let _attribute = temp.find(c => c._id === id)
      return toJS(_attribute)
    }

    const rootFolder = {
      key: "mainfolder",
      title: "All",
      isDeleted: false,
      parentKey: undefined,
      type: "FOLDER",
      children: []
    }

    const handleAddRowRule = () => {
      const sheetIndex = calculationStore?.calculationEditor.index || 0
      const element = calculationsSettings[sheetIndex]
      const columns = element?.columns || [];
      const treeData = projectStore.projectDetail?.treeData || [];

      formAddRowRule.validateFields().then(async response => {
        setLoadingProgress(true)
        const newRules = [...element.rules] || []
        const ruleIndex = findIndexRule(element,response.ruleType)
        if (response.attribute) {
          let attribute
          if (element?.level === 'file') {
            attribute = findProjectCustomAttribute(response.attribute)
          }
          if (element?.level === 'object') {
            attribute = {
              type: "attribute",
              name: response.attribute?.label?.props?.title,
              _id: response.attribute?.value
            }
          }
  
          let newRule = {
            type: "attribute",
            name: attribute?.name,
            attributeId: attribute._id
          }
          if (attribute && ruleIndex > -1) {
            newRules[ruleIndex] = newRule
          } else {
            newRules.push(newRule)
          }
          calculationStore.setSelectedCalculation({
            ...calculationStore.selectedCalculation,
            value : {
              ...calculationStore.selectedCalculation.value,
              rules : newRules
            }
          })
        }
        if (response.folder) {
          let folder = response.folder === 'mainfolder' ? rootFolder : toJS(TreeUtils.searchTreeNode(treeData, 'key', response.folder))
          let newRule = {
            type: "folder",
            name: folder?.title,
            folderKey: folder?.key
          }
          if (folder && ruleIndex > -1) {
            newRules[ruleIndex] = newRule
          } else {
            newRules.push(newRule)
          }
          calculationStore.setSelectedCalculation({
            ...calculationStore.selectedCalculation,
            value : {
              ...calculationStore.selectedCalculation.value,
              rules : newRules
            }
          })
        }
        const queryParams = {
          rowrules: newRules,
          columnrules: columns,
          level: element?.level
        }
  
        if (!columns || columns.length <= 0) {
          if (response.folder) {
            if (element?.level === 'file') {
              addFileRowRule(queryParams,newRules)
            }
            if (element?.level === 'object') {
              addObjectRowRule(queryParams,newRules)
            }
          }else if(response.attribute){
            if (element?.level === 'file') {
              addFileRowRule(queryParams,newRules)
            }
            if (element?.level === 'object') {
              addObjectRowRule(queryParams,newRules)
            }
          }else{
            const temp = [...calculationsSettings]
            temp[sheetIndex].rules = newRules
            setCalculationsSettings(temp)
            message.success(t("add-rule-successfully"))
            saveLastSetting()
          }
        } else {
          if (element?.level === 'file') {
            addFileRowRule(queryParams,newRules)
          }
          if (element?.level === 'object') {
            addObjectRowRule(queryParams,newRules)
          }
        }
        setLoadingProgress(false)
        onCancelAddRowRule()
      })
    }

    const renderRuleTitle = (rule) => {
      const title = rule?.type === 'folder' ? `Folder : ${rule.name}`
        : rule?.type === 'attribute' ? `Attribute : ${rule.name}`
          : ''
      return title
    }

    const validAddRowRule = (sheet) => {
      return sheet?.rules && sheet?.rules?.length < 2
    }

    const onCancelAddRowRule = () => {
      formAddRowRule.resetFields()
      setAddRowRuleModal(false)
      setRowRuleType(false)
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
    }

    const RenderRule = ({ icon, title, onClick, background = '#38a8d7', color = 'white', width = 18, height = 18 }) => {
      return (
        <RuleContainer className={datas?.isFetching ? 'readOnly' : ''} title={title} background={background} color={color} width={width} height={height}>
          {
            icon && <span onClick={onClick} className='rule-icon'>{icon}</span>
          }
          <span className='rule-title'>{title}</span>
        </RuleContainer>
      )
    }

    return (
      <>
        <Form
          form={form}
          name="add-new-report-form"
          layout="vertical">
          <SheetReportTool>
            <RuleWrapper>
              {datas?.rules &&
                datas?.rules.map((rule, ruleIndex) => {
                  return (
                    <RenderRule
                      onClick={() => handleRemoveRowRule(ruleIndex)}
                      key={uuid()}
                      icon={<MinusOutlined />}
                      title={`${ruleIndex + 1}. ${renderRuleTitle(rule)}`}
                    />
                  )
                })}
              {validAddRowRule(datas) && (
                <RenderRule
                  onClick={openModalAddRowRule}
                  icon={<PlusOutlined />}
                  title={t("add-row-rules")}
                />
              )}
            </RuleWrapper>
          </SheetReportTool>
        </Form>
        <ModalAddAttribute
          className="Model"
          title={t('add-row-rule')}
          visible={addRowRuleModal}
          zIndex={9999}
          onCancel={onCancelAddRowRule}
          maskClosable={false}
          footer={
            <Row justify="end">
              <Button
                key="back"
                icon={<CloseCircleOutlined />}
                onClick={onCancelAddRowRule}>
                {t('commons.cancel')}
              </Button>
              <Button
                loading={isLoading}
                form="add-column"
                type="primary"
                key="save"
                icon={<SaveOutlined />}
                htmlType="submit"
                onClick={handleAddRowRule}>
                {t('commons.save')}
              </Button>
            </Row>
          }>
          <Form
            form={formAddRowRule}
            name="add-new-report-form"
            layout="vertical">
            <Form.Item
              label={t('rule-type')}
              name="ruleType"
              rules={[
                {
                  required: true,
                  message: t('cannot-be-emptied', { text: 'Type' }),
                },
              ]}>
              <Select
                showSearch
                placeholder={t('select-rule-type')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: 'folder',
                    label: 'Folder',
                  },
                  {
                    value: 'attribute',
                    label: 'Attribute',
                  },
                ]}
                onChange={e => setRowRuleType(e)}
              />
            </Form.Item>
            {rowruletype === 'folder' && (
              <Form.Item
                label={t('folder')}
                name="folder"
                rules={[
                  {
                    required: true,
                    message: t('cannot-be-emptied', { text: 'Operator' }),
                  },
                ]}>
                <Select
                  showSearch
                  placeholder={t("select-an-operator")}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={folderList.map(item => ({
                    value: item.key,
                    label: item.title,
                  }))}
                />
              </Form.Item>
            )}
            {rowruletype === 'attribute' && (
              <>
                {checkingTableLevel('file') && (
                  <Form.Item
                    label={t('attribute')}
                    name="attribute"
                    rules={[
                      {
                        required: true,
                        message: t('cannot-be-emptied', { text: 'Attribute' }),
                      },
                    ]}>
                    <Select
                      showSearch
                      placeholder={t("select-an-attribute")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={filteredOptions.map(item => ({
                        value: item._id,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>
                )}
                {checkingTableLevel('object') && <DebounceObjectInfo />}
              </>
            )}
          </Form>
        </ModalAddAttribute>
      </>
    )
  }

  const checkUserConditions = (objects = {}) => {
    if(!objects) return {} ;
    const _objects = {...objects};
    for (const property in _objects) {
      const object = _objects[property];
      if(!object?.style && ! object?.value){
        delete _objects[property]
      }
    }
    return _objects;
  }

  const handleSave = () => {
    let index = calculationEditor?.index || 0
    if (store === 'renameCalculation' || store === "saveCurrentCalculation" ) {
      form.validateFields().then(values => {
        let u = [...calculationsSettings]
        u[index].name = values.name;
        if(store === "saveCurrentCalculation"){
          u[index].additionCells = checkUserConditions(calculationStore.additionCells)
        }
        saveLastSetting(u)
        formSelect.setFieldsValue({ calculation: u[index].key })
        updateByIndex(index, handleCancel,u)
      })
    }

    if (store === 'editCalculation') {
      form.validateFields().then(values => {
        let u = [...calculationsSettings]
        u[index].name = values.name;
        u[index].level = values.level;
        saveLastSetting(u)
        formSelect.setFieldsValue({ calculation: u[index].key })
        updateByIndex(index, handleCancel)
      })
    }

    if (store === 'addnewCalculation') {
      form.validateFields().then(response => {
        const newSheet = {
          name: response.name,
          level: response.level,
          isFetching: false,
          key: uuid(),
          rules: [],
          columns: [],
          sheetdata: []
        }
        setObjectDatas(newSheet)
        calculationStore.setAdditionCells({})
        let u = [...calculationsSettings]
        u.push(newSheet)
        setCalculationsSettings(u)
        saveLastSetting(u)
        calculationStore.setSelectedCalculation({
          value: newSheet,
          index : (u?.length - 1) || 0
        })
        message.success(t("add-report-successfully"))
        setSheetDatas([defaultEmptySheet(response)])
        formSelect.setFieldsValue({ calculation: newSheet.key })
        handleCancel()
      })
    }

    if (store === 'newTemplate') {
      form.validateFields().then(response => {
        let u = [...calculationsSettings]
        const element = u[index];
        const newSheet = {
          ...element,
          name: response.name,
          key: uuid(),
        }
        const newName = response.name.trim();
        const curName = calculationStore?.selectedCalculation?.value?.name ? calculationStore.selectedCalculation.value.name.trim() : '';
        const isDiff = newName ===curName
        if (isDiff) {
          //Saved it over existing calculation with existing name
          u[index].name = response.name;
          u[index].additionCells = checkUserConditions(calculationStore.additionCells)
          saveLastSetting(u)
          formSelect.setFieldsValue({ calculation: u[index].key })
          updateByIndex(index, handleCancel, u, false,t("save-calculation-successfully"))
        } else {
          //Saves it as a new calculaiton with new name
          u.push(newSheet)
          setCalculationsSettings(u)
          saveLastSetting(u)
          formSelect.setFieldsValue({ calculation: newSheet.key })
          calculationStore.setSelectedCalculation({
            value: newSheet,
            index: (u?.length - 1) || 0
          })
          updateByIndex((u?.length - 1) || 0, handleCancel, false, newSheet,t("add-report-successfully"))
        }
      })
    }

    if (store === 'addNewColumn') {
      handleAddNewColumn(form,onCancelAddNewColumn)
    }

    if(store ==='editColumnRules'){
      form.validateFields().then(values => {
        let u = [...calculationsSettings]
        const index = calculationEditor?.data?.index || 0;
        const element = calculationEditor?.data?.value ;
        const position =  calculationEditor.position || 0
        const newColumn = {
          name: values.name,
          operator: findOperator(values.operator),
        }
        if (values.attribute) {
          let attribute
          if (element?.level === 'file') {
            attribute = findProjectCustomAttribute(values.attribute)
          }
          if (element?.level === 'object') {
            attribute = {
              type: "attribute",
              name: values.attribute?.label?.props?.title,
              id: values.attribute?.value
            }
          }
          newColumn.attribute = {
            id: attribute?.id,
            name: attribute?.name
          }
        }
        const _columns = element.columns ? element.columns.map(c => toJS(c)) : []
        _columns[position] = {
          ..._columns[position],
          ...newColumn
        }
        const newElement = {
          ...element,
          columns : _columns
        }
        u[index] = newElement
        saveLastSetting(u)
        editColumnRules(newElement,u,index, handleCancel)
      })
    }
  };

  const handleCancel = () => {
    setCalculationEditor(false);
    setOperator(false)
    form.resetFields()
  };

  const renderTileModal = () => {
    let key = store
    switch (key) {
      case "renameCalculation":
        return t("rename-calculation")
      case "addnewCalculation":
        return t("commons.add")
      case "editCalculation":
        return t("edit-calculation")
      case "addNewColumn":
        return t("add-new-column")
      case "editRowRules":
        return t("edit-row-rule")
      case "saveCurrentCalculation":
        return t("save-calculation")
      case "newTemplate":
        return t("save-template")
      case "editColumnRules":
        return t("edit-column-rules")
      default:
        return t('commons.update')
    }
  }

  const renderTileButton = () => {
    let key = store
    switch (key) {
      case "renameCalculation":
        return t("rename")
      default:
        return t('commons.save')
    }
  }

  const renderFormEdit = () => {
    let key = store
    switch (key) {
      case "renameCalculation":
        return <RenameCalculationForm />
      case "addnewCalculation":
        return <AddNewCalculationForm />
      case "editCalculation":
        return <AddNewCalculationForm />
      case "addNewColumn":
        return <AddNewColumnForm />
      case "editColumnRules":
        return <AddNewColumnForm />
      case "editRowRules":
        return <EditRowRuleForm sheet={calculationEditor?.data || []} />
      case "saveCurrentCalculation":
        return <RenameCalculationForm />
      case "newTemplate":
        return <RenameCalculationForm />
      default:
        return < > </>
    }
  }

  return (
    <Modal
      zIndex={store !== "editRowRules" ?9999 : 9998}
      title={renderTileModal(store)}
      visible={calculationEditor}
      onCancel={handleCancel}
      footer={
        <Row justify='end'>
          <Button key="back" onClick={handleCancel}>
            {t('commons.cancel')}
          </Button>
          {
            store !== "editRowRules" && <Button
            key="submit"
            type="primary"
            loading={projectStore.isAppLoading}
            onClick={handleSave}>
            {renderTileButton(store)}
          </Button>
          }
        </Row>
      }>
      {renderFormEdit()}
    </Modal>
  )
}

export default withRouter(
  inject(
    'calculationStore',
    'projectStore'
  )(observer(CalculationEditor))
)
