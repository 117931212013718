import { observable, action, decorate, toJS } from 'mobx'
// import { message } from 'antd'
// import { reloadUrl, getModelUrBySrc, extractHostname } from '../components/helper/CesiumUtils'

class UIStore {
  showIFCSetting = false
  showAmbientOccSetting = false
  requestRender = true
  showObjectInfo = false
  showAttrPanel = false
  showUndergroundSetting = false
  showTransparentSetting = false
  autoSelectedNode = false
  modelIdFromAttribute = ''
  sketchIdFromAttribute = ''
  refIdFromAttribute = ''
  transparentSetting = {}
  showFogSetting = false
  showLightSetting = false
  clippingPlaneToolVisible = false
  refreshViewer = false
  showImporterSetting = false

  setRequestRender = b => {
    this.requestRender = b
  }

  setAutoSelectedNode = status => {
    this.autoSelectedNode = status
  }
  setRefIdFromAttribute = id => {
    this.refIdFromAttribute = id
  }

  setModelIdFromAttribute = id => {
    this.modelIdFromAttribute = id
  }

  setSketchIdFromAttribute = id => {
    this.sketchIdFromAttribute = id
  }


  setShowIFCSetting = b => {
    this.showIFCSetting = b
  }

  setShowAmbientOccSetting = b => {
    this.showAmbientOccSetting = b;
  }

  setShowObjectInfo = b => {
    this.showObjectInfo = b
  }

  setShowAttrPanel = b => {
    this.showAttrPanel = b
  }

  setShowUndergroundSetting = b => {
    this.showUndergroundSetting = b
  }

  setShowTransparentSetting = b => {
    this.showTransparentSetting = b
  }

  setTransparentSetting = v => {
    Object.assign(this.transparentSetting, v)
  }

  setShowLightSetting = b => {
    this.showLightSetting = b
  }

  setShowFogSetting = b => {
    this.showFogSetting = b
  }

  setShowImporterSetting = status => {
    this.showImporterSetting = status
  }

  setClippingPlaneToolVisible = (value) => {
    this.clippingPlaneToolVisible = !this.clippingPlaneToolVisible
    if (value !== undefined) {
      this.clippingPlaneToolVisible = value
    }
  }
  setRefreshViewer = b => {
    this.refreshViewer = b
  }
}

decorate(UIStore, {
  showIFCSetting: observable,
  setShowIFCSetting: action,
  requestRender: observable,
  setRequestRender: action,

  showObjectInfo: observable,
  setShowObjectInfo: action,
  showAmbientOccSetting: observable,
  setShowAmbientOccSetting: action,
  showAttrPanel: observable,
  setShowAttrPanel: action,
  setShowUndergroundSetting: action,
  showUndergroundSetting: observable,
  setAutoSelectedNode: action,
  autoSelectedNode: observable,
  setModelIdFromAttribute: action,
  modelIdFromAttribute: observable,
  setSketchIdFromAttribute: action,
  sketchIdFromAttribute: observable,
  refIdFromAttribute: observable,
  setRefIdFromAttribute: action,
  setShowTransparentSetting: action,
  showTransparentSetting: observable,
  setShowFogSetting: action,
  showFogSetting: observable,
  setClippingPlaneToolVisible: action,
  clippingPlaneToolVisible: observable,
  setRefreshViewer: action,
  refreshViewer: observable,
  setShowImporterSetting: action,
  showImporterSetting: observable,
  setShowLightSetting: action,
  showLightSetting: observable
})

export default new UIStore()
