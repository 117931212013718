import { Input } from 'antd'
import styled from 'styled-components'

const { Search } = Input

export const OrganizationWraper = styled.div`
  background: #FFFFFF;
  ${'' /* padding: 60px 15px 15px; */}
  ${'' /* margin-top: 55px; */}
  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  .ant-tabs-tabpane {
    padding: 5px 0;
  }
`


export const TableWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
`
export const TableHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  .title { 
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
  .action {
    display: flex;
    .ant-btn {
      height: 32px;
    }
  }
`
export const StyledSearch = styled(Search)`
  min-width: 240px;
  input {
    font-size: 12px;
  }
`
export const ModalLink = styled.div`
  &:hover {
    cursor: pointer;
    color: ${props => props.color};
  }
`
export const ProductDetailCardWrapper = styled.div`
  padding: 15px;
  .heading {
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #E3E5E5;
    .info {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      padding-right: 30px;
    }
    .action {
      border-radius: 50%;
      color: white;
      position: absolute;
      top: 0;
      right: 0;
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .body {
    padding-top: 15px;
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      dt {
        position: relative;
        padding-left: 20px;
        width: 120px;
        .color-svg {
          position: absolute;
          top: 5px;
          left: 0;
        }
      }
      dd {
        width: calc(100% - 120px);
      }
      dt, dd {
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`
export const FormWrapper = styled.div`
  padding: 15px;
  .heading {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E3E5E5;
  }
`


export const OrganizationEditWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 15px;
  margin-bottom: 10px;
`

export const OrganizationViewWrapper = styled.div``

export const CardHeader = styled.header`
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  align-items: center;
  padding: 20px 15px 23px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .avatar {
    width: 95px;
    img {
      width: 100%;
    }
  }
  .info {
    width: calc(100% - 95px);
    padding-left: 10px;
    .name {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      position: relative;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        width: calc(100% - 45px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .action {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        line-height: 22px;
        font-size: 10px;
        text-align: center;
        color: white;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`
export const CardFooter = styled.footer`
  padding: 15px 15px 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  &:before{ 
    display: block;
    content: '';
    height: 1px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    transform: translateY(-100%);
  }
  &:after {
    display: block;
    content: '';
    height: 1px;
    background-color: #E3E5E5;  
    position: absolute;
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
  }
`
export const List = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  dt {
    width: 130px;
    position: relative;
    padding-left: 0px;
    .color-svg {
      position: absolute;
      left: 0;
    }
  }
  dd {
    width: calc(100% - 130px);
    padding-left: 15px;
    span {
      overflow: hidden;
      display: block;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
  dt, dd {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`