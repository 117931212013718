import { Col, message, Modal, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import ProjectForm from './ProjectForm'
import ProjectUsers from './ProjectUsers'
import { OrganizationWraper } from './style'
import { Trans, useTranslation } from 'react-i18next';

const OrganizationProjectDetailPage = props => {
    const { t } = useTranslation();
    const { organizationStore, adminStore, projectTeamsStore } = props;
    const organizationId = organizationStore.paramsOrganizationDetail
    const projectId = organizationStore.statusProject

    useEffect(() => {
        if (organizationStore.modalCreateProject === true) {
            adminStore.getRoleCurrentUserInOrganization(organizationId)
            projectTeamsStore.getProjectRoles()
            const populateProjectData = async () => {
                if (projectId !== 'new') { //case edit
                    // get project detail
                    adminStore.setLoadingProgress(true)
                    await adminStore.getDetailProjectInOrganizationById(organizationId, projectId)
                        .then(async res => {
                            let _projectData = {
                                isEdit: true,
                                ...res.data
                            }
                            organizationStore.setProjectData(_projectData);
                            adminStore.getAllProjectTeamsInOrganization(organizationId, projectId)
                        })
                        .catch(error => {
                            adminStore.setLoadingProgress(false)
                            message.error(t('get-project-info-error'))
                            return;
                        })
                        .finally(() => adminStore.setLoadingProgress(false))

                } else { // case add new              
                    let _projectData = {
                        isEdit: false
                    }
                    organizationStore.setProjectData(_projectData)
                    adminStore.setLoadingProgress(false)
                }
            }


            populateProjectData()

            return () => {
                organizationStore.clearProjectData()
                adminStore.clearTeamList()
            }
        }
    }, [projectId])

    const onSaveProject = (values) => {
        if (projectId === 'new') {
            adminStore.setLoadingProgress(true)
            organizationStore.createProject(values).then(res => {
                if (res?.data?.error) {
                    adminStore.setLoadingProgress(false)
                    message.error(t(res.data.error))
                    return
                }
                message.success(t('project-created-successfully'))
                adminStore.setLoadingProgress(false)
                adminStore.getAllProjectTeamsInOrganization(organizationId, res.data.project.id)   //Get user of project
                let _projectData = {
                    isEdit: true,
                    ...res.data.project
                }
                organizationStore.setProjectData(_projectData)
                organizationStore.setForceReload(1)  // force reload component
                organizationStore.setModalCreateProject(false) // close modal
            }).catch(error => {
                message.error(t('an-error-occurred'))
                adminStore.setLoadingProgress(false)
            })
        } else {
            adminStore.setLoadingProgress(true)
            adminStore.updateProjectBelongOrganization(projectId, values).then((res) => {
                let _projectData = {
                    isEdit: true,
                    ...res.data
                }
                organizationStore.setProjectData(_projectData)
                message.success(t('project-updated-successfully'))
                adminStore.setLoadingProgress(false)
                organizationStore.setForceReload(1) // force reload component
                organizationStore.setModalCreateProject(false) // close modal
            }).catch(error => {
                message.error(t('an-error-occurred'))
                adminStore.setLoadingProgress(false)
            })
        }
    }

    const handleCancel = () => {
        organizationStore.setModalCreateProject(false)
    }

    return (
        <Modal
            className="editProject-modal"
            title={projectId === 'new' ? t('add-project') : t('project-info')}
            visible={organizationStore.modalCreateProject}
            onCancel={handleCancel}
            zIndex={9999}
            footer={false}
            maskClosable={false}
        >
            <OrganizationWraper className="organization-modal">
                <Row>
                    <Col span={24}>
                        <ProjectForm
                            projectId={projectId}
                            projectData={organizationStore.projectData}
                            organizationId={organizationId}
                            onSubmit={(values) => onSaveProject(values)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {projectId !== 'new' &&
                            <ProjectUsers ProjectUserDataList={adminStore.teamList} />
                        }
                    </Col>
                </Row>
            </OrganizationWraper>
        </Modal>
    )
}

export default inject(
    'organizationStore',
    'projectTeamsStore',
    "adminStore"
)(observer(OrganizationProjectDetailPage));