import { observable, action, decorate } from 'mobx'
import { message } from 'antd'
// import { apiUrl } from '../config'
import { UserRequest, TokenRequest, AuthRequest } from '../requests'
import commonStore from './commonStore'
import axios from 'axios'
import { t } from 'i18next';
import projectStore from './projectStore'

class UsersStore {

  isLoading = false
  currentUser = {}
  currentUserMe = null
  editMode = false
  userAvatar = undefined
  users = []
  currentUserViewDetail = {}
  userViewEditing = {}
  isShowListUser = false
  joinAPIRoom = false
  systemUserRole = {}
  cookie = true;

  getSystemUserRole = async () => {
    await UserRequest.getSystemUserRole()
      .then(res => {
        this.systemUserRole = res.data
      })
      .catch(err => console.log(err))
  }

  setSystemUserRole = role => {
    this.systemUserRole = role
  }

  createUser = async (data) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      UserRequest.createUser(data).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
        .finally(() => this.setLoadingProgress(false))
    })

  }
  setjoinAPIRoom() {
    this.joinAPIRoom = true
  }
  updateUser = async (id, body) => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      UserRequest.updateUser(id, body).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
        .finally(() => this.setLoadingProgress(false))
    })
  }

  updatelLastLoggin = async (id, body) => {
    return new Promise((resolve, reject) => {
      UserRequest.updateUser(id, body).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  setUserViewEditing = (data) => {
    this.userViewEditing = data
  }
  setCurrentUserViewDetail = (payload) => {
    this.currentUserViewDetail = payload;
  }

  getAllUsers = async () => {
    await UserRequest.getAllUsers()
      .then(res => {
        this.users = res.data
      })
      .catch(err => console.log(err))
  }
  getUserById = async (id) => {
    return new Promise((resolve, reject) => {
      UserRequest.getUserById(id).then(res => {
        resolve(res.data)
      })
        .catch(err => reject(err))

    })

  }

  verifyAccount = async (id) => {
    return new Promise((resolve, reject) => {
      UserRequest.verifyAccount(id).then(res => {
        resolve(res.data)
      })
        .catch(err => reject(err))

    })

  }


  setUserAvatar = (data) => {
    this.userAvatar = data
  }

  userLogout() {
    this.currentUser = {}
    commonStore.clearToken()
    return Promise.resolve()
  }

  logoutCognito() {
    fetch(`${process.env.REACT_APP_COGNITO_DOMAIN}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URL}`)
      .catch(rejected => {
        console.log(rejected);
      });
  }

  toggleEditMode(state) {
    this.editMode = state
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }

  async setCurrentUser(history) {
    this.setLoadingProgress(true)
    if (commonStore.checkToken()) {
      return new Promise((resolve, reject) => {
        UserRequest.getCurrent()
          .then(response => {
            resolve(response.data)
            this.currentUser = response.data
            this.setLoadingProgress(false)
            commonStore.setLanguage(response.data.language ? response.data.language : 'en')
          })
          .catch((err) => {
            reject(err)
            commonStore.clearToken()
            this.setLoadingProgress(false)
            return (history) ? history.push('/auth/login') : null
          })
      })
    } else {
      this.setLoadingProgress(false)
    }
  }

  async getCurrentUser(history) {
    const startTime = Date.now();
    // this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      UserRequest.getCurrent()
      .then(response => {
        this.currentUser = response.data
        commonStore.setLanguage(response.data.language ? response.data.language : 'en')
        this.setLoadingProgress(false)
        // commonStore.setLoadingProgress(false)
        commonStore.loggingFunction('get current user','success',startTime,response.data )
        resolve(response.data)

      })
      .catch((err) => {
        commonStore.clearToken()
        this.setLoadingProgress(false)
        commonStore.loggingFunction('get current user','failed',startTime, 'current' )
        if(err?.status ===401){
          // message.error(err?.data?.err || err?.statusText || "Unauthorized" )
        }
        projectStore.setLoadingProgress(false)
        commonStore.setLoadingProgress(false)
        reject(err)
        return (history) ? history.push('/auth/login') : null
      })
    })
  }

  updateUserMe(data) {
    return new Promise((resolve, reject) => {
      UserRequest.updateProfileMe(data).then(res => {
        this.currentUser = res.data
        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

  deleteUser(tokenid) {
    this.setLoadingProgress(true)
    return UserRequest.delete(tokenid)
      .then((response) => {
        this.setLoadingProgress(false)
        return response
      })
      .catch(error => {
        this.setLoadingProgress(false)
        console.log(error)
        return error
      })
  }

  getValidToken = (tokenid) => {
    this.setLoadingProgress(true)
    return TokenRequest.getValidToken(tokenid)
      .then(response => {
        this.setLoadingProgress(false)
        return response
      })
      .catch(error => {
        console.log(error)
        this.setLoadingProgress(false)
        return error
      })
  }

  getPublicValidToken = (tokenid,history) => {
    this.setLoadingProgress(true)
    return TokenRequest.getPublicValidToken(tokenid)
      .then(response => {
        this.setLoadingProgress(false)
        return response
      })
      .catch(error => {
        history.push(`/`)
        console.log(error)
        this.setLoadingProgress(false)
        message.error(t('an-error-occurred'))
        return error
      })
  }


  confirmDeleteMyAccount(data) {
    this.setLoadingProgress(true)
    return TokenRequest.sendMailConfirmDelete(data)
      .then((response) => {
        this.setLoadingProgress(false)
        if (response.data && response.data.error === "actuallysent") {
          message.success(t('a-confirmation-email-has-been-sent-to-you-please-check-your-inbox'))
        } else {
          message.success(t('please-check-your-email-to-confirm--thi-delete-your-account-request'))
        }
      })
      .catch(error => {
        this.setLoadingProgress(false)
        message.success(t('delete-failed'))
      })
  }

  sendForgotPasswordEmail = async (data) => {
    return new Promise((resolve, reject) => {
      TokenRequest.sendForgotPasswordEmail(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  confirmChangePassword = async (data) => {
    return new Promise((resolve, reject) => {
      TokenRequest.confirmChangePassword(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  changePasswordUser = async (data) => {
    return new Promise((resolve, reject) => {
      AuthRequest.changePasswordUser(data).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }



  updateUserAvatar = val => {
    this.setUserAvatar({ base64: val })
  }

  setCookie = (data) => {
    this.cookie = data
  }

  confirmChangeEmail = async (token,history) => {
    return new Promise((resolve, reject) => {
      UserRequest.confirmchangeemail(token).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

decorate(UsersStore, {
  isLoading: observable,
  currentUser: observable,
  editMode: observable,
  setLoadingProgress: action,
  userLogout: action,
  logoutCognito: action,
  toggleEditMode: action,
  setCurrentUser: action,
  confirmDeleteMyAccount: action,
  updateUserAvatar: action,
  setUserAvatar: action,
  userAvatar: observable,
  currentUserMe: observable,
  getCurrentUser: action,

  users: observable,
  getAllUsers: action,
  currentUserViewDetail: observable,
  setCurrentUserViewDetail: action,
  isShowListUser: observable,
  userViewEditing: observable,
  setUserViewEditing: action,
  getCurrentUser: action,
  getUserById: action,
  deleteUser: action,
  createUser: action,
  updateUser: action,
  sendForgotPasswordEmail: action,
  getPublicValidToken: action,
  confirmChangePassword: action,
  joinAPIRoom: observable,
  setjoinAPIRoom: action,
  systemUserRole: observable,
  getSystemUserRole: action,
  setSystemUserRole: action,
  updateUserMe: action,
  verifyAccount: action,
  cookie: observable,
  setCookie: action,
  confirmChangeEmail : action,
  updatelLastLoggin : action
})

export default new UsersStore()
