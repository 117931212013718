import { Row, Layout, Button } from 'antd'
import styled from 'styled-components'

export const LogoWraper = styled.div`
  ${'' /* width: 120px; */}
  margin: 16px 20px 0 20px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  .ant-avatar {
    backgroundcolor: #f16524;
    color: #fff;
    marginright: 5px;
  }
`

export const CustomLayout = styled.div`
  .ant-layout-header {
    background: #001529;
  }
`

export const UpdateButton = styled.div`
  button {
    background-color: #4cae4c;
    color: #fff;
    border: none;
  }
  button:hover {
    background-color: #449d44;
    color: #fff;
  }
`

export const CustomButtonBack = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  span {
    padding: 20px;
  }
`

export const CustomTopTitle = styled.div`
  float: left;
  .title {
    font-weght: 600;
    font-size: 20px;
    color: #000000;
  }
  .count {
    font-size: 13px;
    color: #787e8f;
  }
`

export const TopMenuWrapper = styled(Row)`
  background: #f0f2f5;
  padding: 0px;
  height: 6rem;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
  position: fixed;
  z-index: 8888;
  top: 0;
  right: 0;
  left: ${props => props.left}px;
  transition: 0.2s;
  //box-shadow: rgb(40 42 49 / 16%) 0px 1px 2px 0px;
  background-color: rgb(255, 255, 255);
`

export const MainContentWrapper = styled(Layout)`
  position : absolute ;
  z-index : 7777;
  right : 0;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  .edit-user .ant-form-item-label{
    font-weight : 500;
  }
}
`
export const LogoWraperMobile = styled.div`
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  .ant-avatar {
    backgroundcolor: #f16524;
    color: #fff;
  }
`
export const MenuIconMobile = styled.div`
  background: #f0f2f5;
  padding: 0px;
  height: 6rem;
  border-bottom: 1px solid #ddd;
  //border-right: 1px solid #ddd;
  text-align: center;
  font-size: 27px;
  z-index: 8888;
  top: 0;
  right: 0;
  transition: 0.2s;
  min-width: 80px;
  //box-shadow: rgb(40 42 49 / 16%) 0px 1px 2px 0px;
  background-color: rgb(255, 255, 255);
  &:hover {
    cursor: pointer;
  }
`
export const CustomMenuIconMobile = styled.div`
  background: #f0f2f5;
  padding: 0px;

  text-align: center;
  font-size: 20px;
  z-index: 8888;
  top: 0;
  right: 0;
  transition: 0.2s;

  background-color: rgb(255, 255, 255);
  &:hover {
    cursor: pointer;
  }
`
export const ButtonDownAdminHeader = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
`
