import { Col, Row } from 'antd';
import React from 'react';
import { CustomTopDashBoardItem, CustomCount } from './../style';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';

const TopDashBoardItem = (props) => {
    const { count, title, colorPercent, percent, colorIcon } = props;
    return (
        <>
            <CustomTopDashBoardItem>
                <Row>
                    <h1>{title}</h1>
                </Row>
                <Row align="bottom" justify="space-between">
                    <Col>
                        <CustomCount>
                            {count ? (title === "Speed" ? `${count} hours` : count) : 0}
                        </CustomCount>
                    </Col>
                    <Row align="bottom">
                        <img style={{ height: "60px" }} src="https://h5p.org/sites/default/files/styles/medium-logo/public/logos/chart-icon-color.png?itok=kpLTYHHJ" />
                    </Row>
                </Row>

            </CustomTopDashBoardItem>
        </>
    );
}

export default TopDashBoardItem;
