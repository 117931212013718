import React, { useEffect, useState, useCallback } from 'react'
import { Form, Modal, Button, Input, Select, message, Row, Col } from 'antd'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx';
import { useParams } from 'react-router-dom';
import './style.css'
import { List } from './style'
import { Trans, useTranslation } from 'react-i18next';
const { Option } = Select

const ChangeRoleUserInOrganization = props => {
    const { t } = useTranslation();
    const { projectStore, adminStore, organizationStore, history } = props
    const [form] = Form.useForm();
    const organizationRoles = organizationStore.organizationRoles
    const organizationId = organizationStore.paramsOrganizationDetail

    const { currentUserRoleDetailInOrganization } = adminStore

    // console.log("organizationStore : ", toJS(organizationStore))


    useEffect(() => {
        if (adminStore.showChangeRoleUserModal) {
            form.resetFields();
        }
    }, [adminStore.showChangeRoleUserModal])

    const handleChangeRoleUser = () => {
        form.validateFields().then(async values => {
            let submitValue = { organizationrole: values.role }
            organizationStore.setLoadingProgress(true)
            await organizationStore.updateOrganizationUserRole(currentUserRoleDetailInOrganization.id, submitValue)
                .then(async res => {
                    message.success(t('change-user-role-successfully'))
                    await getCurrentOrganizationDetail(organizationId)
                    organizationStore.setLoadingProgress(false)
                    adminStore.setShowChangeRoleUserModal(false)
                }).catch(err => {
                    organizationStore.setLoadingProgress(false)
                })
        })
    }

    const getCurrentOrganizationDetail = (id) => {
        adminStore.setLoadingProgress(true)
        adminStore.getCurrentOrganizationDetail(id).then((res) => {
            adminStore.setLoadingProgress(false)
        }).catch(error => {
            adminStore.setLoadingProgress(false)
            if (error.data && error.data.statusCode === 403) {
                message.error(t('you-do-not-have-permission'))
                history.push(`/`)
            } else {
                message.error(t('an-error-occurred'))
            }
        })
    }

    return (
        <Modal
            className="modal-addUser"
            title={t('change-user-role-in-organization')}
            onCancel={() => adminStore.setShowChangeRoleUserModal(false)}
            okText={t('send-organization-role')}
            cancelText={t('commons.cancel')}
            visible={adminStore.showChangeRoleUserModal}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={() => adminStore.setShowChangeRoleUserModal(false)}>
                    {t('commons.cancel')}
        </Button>,
                <Button key="submit" type="primary" onClick={e => handleChangeRoleUser()}>
                    {t('commons.save')}
        </Button>,
            ]}
            zIndex={9999}
        >
            <Form
                form={form}
                layout="vertical"
                id={'invite-member-form'}
            >
                <div style={{ padding: "20px 0" }} >
                    <List>
                        <dt>{t('username')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInOrganization.user && currentUserRoleDetailInOrganization.user.username ? currentUserRoleDetailInOrganization.user.username : currentUserRoleDetailInOrganization.email
                            }</span>
                        </dd>
                        <dt>{t('user-email')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInOrganization.user && currentUserRoleDetailInOrganization.user.email ? currentUserRoleDetailInOrganization.user.email : currentUserRoleDetailInOrganization.email
                            }</span>
                        </dd>
                        <dt>{t('current-role')}:</dt>
                        <dd>
                            <span>{
                                currentUserRoleDetailInOrganization.organizationrole && currentUserRoleDetailInOrganization.organizationrole.name ? currentUserRoleDetailInOrganization.organizationrole.name : ""
                            }</span>
                        </dd>
                    </List>
                </div>
                <Form.Item
                    label={t('project-roles')}
                    name="role"
                    rules={[
                        {
                            required: true,
                            message: t('please-select-role'),
                        },
                    ]}
                    initialValue={currentUserRoleDetailInOrganization.organizationrole ? currentUserRoleDetailInOrganization.organizationrole.id : ''}
                >
                    <Select
                        placeholder={t('select-role')}>
                        {
                            organizationRoles.map((r) => {
                                return (
                                    <Option key={r.id} value={r.id}>{r.name}</Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default inject('projectStore', 'adminStore', 'organizationStore')(observer(ChangeRoleUserInOrganization))