import { Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { toJS } from 'mobx'
import { Trans, useTranslation } from 'react-i18next';

const TopicTypeTable = ({ projectStore, topicStore, dashboardStore }) => {
    const { t } = useTranslation();
    const [dataTableTopicTypes, setDataTableTopicTypes] = useState(dashboardStore.listTopicTypeSelected)

    useEffect(() => {
        if (projectStore.showProjectDashBoard) {
            setDataTableTopicTypes(dashboardStore.listTopicTypeSelected)
            handlePopulateDataTable()
        }
    }, [dashboardStore.curentTypeTopicPie, dashboardStore.listTopicFilterByType, dashboardStore.listTopicTypeSelected])

    const handlePopulateDataTable = () => {
        let result = [];
        const listTopicFilterByType = dashboardStore.listTopicFilterByType
        const listTopicTypeSelected = dashboardStore.listTopicTypeSelected
        const { curentTypeTopicPie } = dashboardStore

        for (let v of listTopicTypeSelected) {
            result.push({
                done: listTopicFilterByType.filter(value => value[`${curentTypeTopicPie}`] === v.name && value.topicstatus === "Done").length,
                total: listTopicFilterByType.filter(value => value[`${curentTypeTopicPie}`] === v.name).length,
                name: v.name
            })
        }
        setDataTableTopicTypes(result)
    }

    const columns = [
        {
            title: t('type'),
            dataIndex: 'name',
            key: 'name',
            render: (record) => <a>{record}</a>,
        },
        {
            title: t('done'),
            dataIndex: 'done',
            key: 'done',
            sorter: (a, b) => {
                return (a.done || 0) - (b.done || 0)
              },
        },
        {
            title: t('done') + '%',
            key: 'donepercent',
            sorter: (a, b) => {
                return (Number((a.done || 0)/a.total) ? (a.done || 0)/a.total : 0) - (Number((b.done || 0)/b.total) ? (b.done || 0)/b.total : 0)
              },
            render: record => {
                let perc = record.total === 0 ? 0.00
                    : ((record.done / record.total) === 1) ? 100
                        : ((record.done / record.total) * 100).toFixed(2);
                let color = perc > 50 ? '#2ecc71' : '#c0392b';
                return (
                    <Tag color={color} key={record.name}>
                        { perc === 0 ? "0.00%" : `${perc}%`}
                    </Tag>
                );
            }
        },
        {
            title: t('total'),
            key: 'total',
            sorter: (a, b) => {
                return (a.total || 0) - (b.total || 0)
              },
            render: (text, record) => (
                <Space size="middle">
                    <a> {t(record.total)}</a>
                </Space>
            ),
        },
    ];
    return (
        <Table
             bordered
            style={{ width: "100%" }}
            rowKey={record => record.name}
            dataSource={dataTableTopicTypes}
            columns={columns}
            pagination={false}
            locale={{emptyText: t('no-data')}}
        />

    );
}

export default withRouter(inject(
    'topicStore', 'dashboardStore', 'projectStore'
)(observer(TopicTypeTable)))
