import { CloseOutlined, ColumnWidthOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Drawer, Table, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import LinkOrganizationModal from './LinkOrganizationModal';
import './style.css';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

function LicenseDrawer({ commonStore, usersStore, adminStore }) {
    const { t } = useTranslation();
    moment.locale(`${commonStore.language}`)
    const isTabletOrMobile = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [screenWidth, setScreenWidth] = useState(0)
    const [listLicenses, setListLicenses] = useState([])
    const [showOrganizationModal, setShowOrganizationModal] = useState(false)
    const [organizationData, setOrganizationData] = useState([])
    const [orgValue, setOrgValue] = useState()
    const [licenseID, setLicenseID] = useState()
    const [selectLoading, setSelectLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const handleCloseLicenseDrawer = () => {
        commonStore.setShowLicenseDrawer(false)
        setListLicenses([])
    }

    useEffect(() => {
        if (usersStore.currentUser.id && commonStore.showLicenseDrawer) {
            adminStore.findLicenseByUser(usersStore.currentUser.id)
        }
    }, [commonStore.showLicenseDrawer, reload])

    useEffect(() => {
        if (usersStore.currentUser && showOrganizationModal) {
            const populateData = async () => {
                setSelectLoading(true)
                await adminStore.getOrganizationBelongUser()
                let orgList = adminStore.orgBelongUserList
                let _orgList = orgList?.map(elm => {
                    return {
                        id: elm?.organization?.id,
                        name: elm?.organization?.name,
                        licenses: elm?.organization?.licenses
                    }
                })
                setOrganizationData(_orgList)
                setSelectLoading(false)
            }

            populateData()
        }
    }, [usersStore.currentUser, listLicenses, showOrganizationModal])

    const handleShowOrganizationModal = (obj) => {
        setShowOrganizationModal(true)
        setLicenseID(obj.id)
    }

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };


    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
        let minDrawerWidth = 50;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 200;
        if (offsetRight > minDrawerWidth) {
            setDrawerWidth(offsetRight);
        }
    }, []);
    //#endregion

    // (changeSize) change drawerWidth when drawerWidth > screenWidth
    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize) // force reload when screenWidth change

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])
    // end changeSize


    useEffect(() => {
        if (commonStore.showLicenseDrawer) {
            setDrawerWidth(isTabletOrMobile ? 320 : 600)
            // commonStore.setLoadingProgress(true)
        }
    }, [commonStore.showLicenseDrawer])


    useEffect(() => {
        if (adminStore.licenseByUser && commonStore.showLicenseDrawer) {
            let _value = adminStore.licenseByUser
            setListLicenses(_value)
        }
    }, [adminStore.licenseByUser, commonStore.showLicenseDrawer])

    const columns = [
        {
            title: t('licence-key'),
            key: "licenceKey",
            dataIndex: 'licenceKey',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.age - b.age,
        },
        {
            title: t('description'),
            key: "description",
            dataIndex: 'description',
            render: record => {
                return (
                    <div>{record ? record : ""}</div>
                )
            }
        },
        {
            title: t('user'),
            key: "user",
            dataIndex: 'user',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.age - b.age,
            render: record => {
                return (
                    <div>{record && record.username ? record.username : ""}</div>
                )
            }
        },
        {
            title: t('projects'),
            key: "projects",
            width: 100,
            dataIndex: 'projects',
            render: record => {
                return (
                    <div>{record ? record.length : 0}</div>
                )
            }
        },
        {
            title: t('storage'),
            key: "storageQuota",
            width: 100,
            render: record => {
                return (
                    <div>{record.storageQuota} MB</div>
                )
            }
        },
        {
            title: t('start-date'),
            key: "endDate",
            width: 200,
            render: record => {
                return (
                    <div>{moment(record?.createdAt).format('LLL')}</div>
                )
            }
        },
        {
            title: t('end-date'),
            key: "createdAt",
            width: 200,
            render: record => {
                let endDate = moment(record.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').add(record.timeLimit, 'days')
                return (
                    <div>{moment(endDate).format('LLL')}</div>
                )
            }
        },
        {
            title: t('organization'),
            key: "organization",
            dataIndex: 'organization',
            width: 150,
            render: record => {
                return (
                    <div>{record && record.name ? record.name : ""}</div>
                )
            }
        },
        {
            title: t('action'),
            dataIndex: 'id',
            key: 'event',
            fixed: 'right',
            width: 100,
            render: (record, obj) => {
                return (
                    !obj?.organization &&
                    <Tooltip title={t('assign-to-organization')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <EditOutlined className="assignLicense-action" onClick={() => handleShowOrganizationModal(obj)} />
                    </Tooltip>
                )
            }
        },
    ];

    return (
        <Drawer
            title={
                <>
                    <span>{t('licenses')}</span>
                    <CloseOutlined className="closeLicenceDrawer" onClick={handleCloseLicenseDrawer} />
                </>
            }
            placement="left"
            closable={false}
            width={drawerWidth}
            visible={commonStore.showLicenseDrawer}
            destroyOnClose={true}
        >
            <>
                {isTabletOrMobile ? (

                    <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnOrganizationPanel btnSplitPanel">
                        <ColumnWidthOutlined />
                    </Button>

                ) : (<div onMouseDown={e => handleMouseDown(e)} className="organizationPanel" />)
                }
                <h1>{t('your-licenses')}</h1>

                <Table
                    rowKey={record => record.id}
                    size='default'
                    bordered
                    maskCloseable={false}
                    scroll={{ x: 1500 }}
                    loading={adminStore.isLoading}
                    columns={columns}
                    dataSource={listLicenses}
                    pagination={false}
                    locale={{ emptyText: t('no-data') }}
                />
                <LinkOrganizationModal
                    licenseID={licenseID}
                    showOrganizationModal={showOrganizationModal}
                    setShowOrganizationModal={setShowOrganizationModal}
                    setOrganizationData={setOrganizationData}
                    organizationData={organizationData}
                    setOrgValue={setOrgValue}
                    orgValue={orgValue}
                    setReload={setReload}
                    reload={reload}
                    selectLoading={selectLoading}
                />
            </>
        </Drawer>
    )
}

export default inject('commonStore', 'usersStore', 'adminStore')(observer(LicenseDrawer))
