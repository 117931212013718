import {
  CallbackProperty,
  Cartesian2,
  Cartesian3,
  Cartographic,
  Math as CesiumMath,
  ClassificationType,
  Color,
  CornerType,
  PinBuilder,
  PolygonGraphics,
  PolygonHierarchy,
  PolylineGraphics,
  PolylineOutlineMaterialProperty,
  ScreenSpaceEventType,
  Transforms,
  VerticalOrigin
} from 'cesium'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Entity, Model } from 'resium'
import uuid from 'uuid'
import TreeUtils from '../../../../tree-utils'
import Utils from '../../../../utils'
import { calculateBearing, clickPoint, getHorizontalDistance, getPointClickAndModel, getSketchMatrix, getVerticalDistance, getVerticalPointClick, pointGeoPosition } from '../../../helper/CesiumUtils'

const DrawSketch = props => {
  const { viewer, handler, sketchingStore } = props

  let drawingMode = sketchingStore.sketchMode
  let [activeShape, setActiveShape] = useState()
  let [currentShape, setCurrentShape] = useState()
  let [currentLineHorizontalShape, setCurrentLineHorizontalShape] = useState()
  const [sketchViews, setSketchViews] = useState([])
  const [extrudedHeight, setExtrudedHeight] = useState(
    sketchingStore.sketch.extrudedHeight
  )
  function CustomLine() {
    this.positions = [];
    this.markers = [];
  }
  //Function add point click
  CustomLine.prototype.addPoint = function (resultClick) {
    var that = this;
    var pickedObject = resultClick.pickedObject
    var position = resultClick.position
    var n = this.positions.length;
    this.positions[n] = {
      pickedObject: pickedObject,
      position: position
    };

    var marker = viewer.current.cesiumElement.entities.add({
      position: new CallbackProperty(function () {
        if (that.positions && that.positions[n] && that.positions[n].position) {
          return that.positions[n].position;
        }
      }, false),
      point: {
        pixelSize: 5,
        color: Color.RED,
        outlineColor: Color.WHITE,
        outlineWidth: 2,
      },
    })
    this.markers.push(marker);
  };
  //function remove point and line
  CustomLine.prototype.removeAll = function () {
    if (this.markers && this.markers.length > 0) {
      for (var i = 0; i < this.markers.length; i++) {
        if (viewer && viewer.current && viewer.current.cesiumElement)
          viewer.current.cesiumElement.entities.remove(this.markers[i]);
      }
      this.markers = [];
      this.positions = [];
    }
  };
  const [activeShapePoints, setActiveShapePoints] = useState([])
  const [activeSketchPoints, setActiveSketchPoints] = useState([])
  const [cartographics, setCartographics] = useState([])
  const [previewPoint, setPreviewPoint] = useState([])
  const [makers, setMakers] = useState([])
  const [customLine] = useState(new CustomLine())

  const drawShape = (positionData, isEnd) => {
    let shape
    if (positionData.length === 0 || (positionData.positions && positionData.positions.length === 0)) return

    if (drawingMode === 'line') {
      if (positionData.length < 2) {
        let polyline = new PolylineGraphics({
          positions: positionData,
          clampToGround: false,
          width: 3,
          material: new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
          depthFailMaterial: new PolylineOutlineMaterialProperty({
            color: Color.WHITE,
          }),
          destroyPrimitives: false,
          classificationType: ClassificationType.BOTH,
        })
        shape = viewer.current.cesiumElement.entities.add({
          polyline: polyline,
        })
      }
      else {
        shape = drawLineHorizontal(positionData, sketchingStore.sketch.width, sketchingStore.sketch.extrudedHeight)
      }
    }
     if (drawingMode === 'label') {
      if (positionData.length == 1) {
        shape = viewer.current.cesiumElement.entities.add({
          position: positionData[0],
          label: {
            font: `${sketchingStore.sketch.sizeLabel}px ${sketchingStore.sketch.font}`,
            text: sketchingStore.sketch.name,
            fillColor: new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
          },
        })
      }
    }
     if (drawingMode === 'area') {
      if (positionData.length < 3) {
        let polyline = new PolylineGraphics({
          positions: positionData,
          clampToGround: true,
          width: 3,
          material: new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
          depthFailMaterial: new PolylineOutlineMaterialProperty({
            color: Color.WHITE,
          }),
          destroyPrimitives: false,
          classificationType: ClassificationType.BOTH,
        })
        shape = viewer.current.cesiumElement.entities.add({
          polyline: polyline,
        })
      }
      else {
        const extrudeHeight = sketchingStore.sketch.height + sketchingStore.sketch.extrudedHeight
        const readonlyHeight = sketchingStore.sketch.readonlyHeight
        let option = {
          clampToGround: sketchingStore.sketch.extrudedHeight > 0 ? false : true,
          hierarchy: positionData,
          material: new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
          classificationType: ClassificationType.BOTH,
          // perPositionHeight: sketchingStore.sketch.extrudedHeight > 0,
          // height: isEnd && (sketchingStore.sketch.extrudedHeight && !sketchingStore?.sketch?.readonlyHeight ) ? sketchingStore.sketch.height : undefined,
          // extrudedHeight: isEnd && sketchingStore.sketch.extrudedHeight ? extrudeHeight : undefined,
          outline: true,
          outlineColor: {
            rgba: [0, 0, 0, 255],
          },
        }
        if (extrudeHeight > 0 && !readonlyHeight) {
          option.extrudedHeight = extrudeHeight;
          option.perPositionHeight = true;
        } else {
          option.perPositionHeight = false;
        }
        let polygon = new PolygonGraphics(option)
        shape = viewer.current.cesiumElement.entities.add({
          polygon: polygon,
        })
      }

    }
     if (drawingMode === 'point') {
      if (activeShapePoints.length === 2 || isEnd) {
        let point1 = activeSketchPoints[0]
        let point2 = activeSketchPoints[1] || activeSketchPoints[0]
        let shapePoint1 = activeShapePoints[0]
        let shapePoint2 = activeShapePoints[1] || activeShapePoints[0]
        let point1Click = getPointClickAndModel(point1.position, point1.pickedObject)
        let point2Click = getPointClickAndModel(point2.position, point2.pickedObject)
        let activeShapePointsClone = false
        if (point1.position.z > point2.position.z) {
          let temp = point1
          point1 = point2
          point2 = temp
        }
        if (shapePoint1.z > shapePoint2.z) {
          let temp = shapePoint1
          shapePoint1 = shapePoint2
          shapePoint2 = temp
          activeShapePointsClone = [shapePoint1, shapePoint2]
        }
        let _pointElevation
        if (point1Click && point2Click) {
          let _p1 = point1Click.cartesian ? [point1Click.cartesian.y, point1Click.cartesian.x, point1Click.cartographic.height] : [point1Click.cartesian.y, point1Click.cartesian.x, point1Click.cartographic.height]
          let _p2 = point2Click.cartesian ? [point2Click.cartesian.y, point2Click.cartesian.x, point2Click.cartographic.height] : [point2Click.cartesian.y, point2Click.cartesian.x, point1Click.cartographic.height]

          _pointElevation = {
            Status: 'OK',
            Points: [..._p1, ..._p2]
          }
        }
        if (_pointElevation) {
          if (_pointElevation && _pointElevation.Status === 'OK') {
            point1.pointElevation = [_pointElevation.Points[0], _pointElevation.Points[1], _pointElevation.Points[2]]
            point2.pointElevation = [_pointElevation.Points[3], _pointElevation.Points[4], _pointElevation.Points[5]]
          }

          let horizontal;
          let vertical;
          let bearing;

          if (activeShapePoints.length === 1) {
            horizontal = sketchingStore.sketch.width / 2
            vertical = sketchingStore.sketch.extrudedHeight
            bearing = sketchingStore.sketch.rotation
          } else {
            horizontal = getHorizontalDistance(pointGeoPosition(point1), pointGeoPosition(point2), true)
            vertical = getVerticalDistance(pointGeoPosition(point1), pointGeoPosition(point2), true)
            bearing = calculateBearing(shapePoint1, shapePoint2)
          }
         
          // if (activeShapePoints.length === 1) {
          //   vertical = 0.5
          //   horizontal = 0.25
          //   bearing = 0
          // }
          // if (activeShapePoints.length === 1 && isEnd && sketchingStore.multipleMode) {
          //   vertical = sketchingStore.sketch.extrudedHeight || 0.5
          //   horizontal = (sketchingStore.sketch.width) / 2 || 0.25
          //   bearing = sketchingStore.sketch.rotation || 0
          // }


          sketchingStore.setSketchProps({ extrudedHeight: Number(vertical) })
          sketchingStore.setSketchProps({ width: Number(horizontal) * 2 })
          sketchingStore.setSketchProps({ rotation: Number(bearing.toFixed(1)) })
          // let activeShapePointsClone = Cartographic.fromCartesian(shapePoint1.clone())
          let activeShapePointsClone
          if (activeShapePoints.length == 2 && activeShapePoints[0].z > activeShapePoints[1].z) {
            activeShapePointsClone = Cartographic.fromCartesian(activeShapePoints[0].clone())
            activeShapePointsClone.height = (activeShapePointsClone.height - vertical)
          } else {
            activeShapePointsClone = Cartographic.fromCartesian(shapePoint1.clone())
            activeShapePointsClone.height += vertical / 2
          }
          let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)

          shape = viewer.current.cesiumElement.entities.add({
            name: "New cylinder",
            userData: { point: activeShapePointsClone || activeShapePoints },
            position: activeShapeClone,
            cylinder: {
              length: vertical,
              topRadius: horizontal,
              bottomRadius: horizontal,
              material: new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
            }
          })
        }
      }
    }
    return shape
  }

  function drawLineHorizontal(_points, _width, extrudedHeight) {
    let arr = []
    let arr2 = []
    for (let i = 0; i < _points.length; i++) {
      let point = _points[i];
      let cartographic = Cartographic.fromCartesian(point)
      let picklong = cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN
      let picklat = cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN
      let height = cartographic.height
      arr.push(picklong, picklat, height)
      arr2.push(picklong, picklat)
    }
    let lineShape = []
    lineShape.push(
      new Cartesian2(-_width / 2, -extrudedHeight / 2),
      new Cartesian2(_width / 2, -extrudedHeight / 2),
      new Cartesian2(_width / 2, extrudedHeight / 2),
      new Cartesian2(-_width / 2, extrudedHeight / 2))
    const corridor = {
      corridor: {
        positions: Cartesian3.fromDegreesArrayHeights(arr),
        height: sketchingStore.sketch.height,
        extrudedHeight: sketchingStore.sketch.height + extrudedHeight,
        width: _width,
        // width: _width / 2,
        cornerType: CornerType.MITERED,
        material: new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
      }
    }
    const cartesians = _points.map(position => new Cartesian3(position.x, position.y, position.z))
    const cartographics = cartesians.map(cartesian => new Cartographic.fromCartesian(cartesian))
    const polylineVolume = {
      polylineVolume: {
        positions: Cartesian3.fromDegreesArrayHeights(arr),
        shape: lineShape,
        cornerType: CornerType.MITERED,
        material: new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
        // outline: true, // height or extrudedHeight must be set for outlines to display
        // outlineColor: Color.WHITE
      }
    }
    const wallVolume = {
      wall: {
        positions: Cartesian3.fromDegreesArrayHeights(arr),
        shape: lineShape,
        cornerType: CornerType.MITERED,
        material: new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
        maximumHeights: cartographics.map(
          cat => cat.height + extrudedHeight
        ),
        minimumHeights: cartographics.map(cat => cat.height),
      }
    }
    let shape = undefined
    if (extrudedHeight && _width) shape = viewer.current.cesiumElement.entities.add(polylineVolume)
    else if (_width) shape = viewer.current.cesiumElement.entities.add(corridor)
    else if (extrudedHeight) shape = viewer.current.cesiumElement.entities.add(wallVolume)

    sketchingStore.setSketchProps({ lineHorizontalPositions: arr })
    return shape
  }

  // Redraw the shape so it's not dynamic and remove the dynamic shape.
  function terminateShape() {
    sketchingStore.setCurrentSketchId(false)
    if (cartographics.length > 1) {
      //set height shape flow model click
      let maxHeight = 0
      cartographics.forEach(cartographic => {
        maxHeight = cartographic.height > maxHeight ? cartographic.height : maxHeight
      })
      sketchingStore.setSketchProps({ height: maxHeight })

      // activeShapePoints.pop();

      // setActiveShapePoints([]);
    }
    setCurrentShape(drawShape(activeShapePoints, true))
    if (drawingMode === 'point') {
      if (!activeShapePoints || activeShapePoints.length === 0) {
        // sketchingStore.setSketchFormVisible(false)
        sketchingStore.setDrawMode(false)
        // sketchingStore.setEndDrawing(false)
        sketchingStore.setSketchMode('')
      }
      let shapePoint1 = activeShapePoints[0]
      let shapePoint2 = activeShapePoints[1] || activeShapePoints[0]
      let activeShapePointsClone = [shapePoint1, shapePoint2]
      sketchingStore.setSketchProps({ points: activeShapePointsClone })
    } else {
      sketchingStore.setSketchProps({ points: activeShapePoints })
    }
    viewer.current.cesiumElement.entities.remove(activeShape)
    setMakers([])
    activeShape = undefined
    customLine.removeAll()
  }

  useEffect(() => {
    if (drawingMode === 'point' && activeShapePoints.length === 2) {
      setPreviewPoint()
      sketchingStore.setEndDrawing(true)
    }
  }, [activeShapePoints])

  // drawing label
  useEffect(() => {
    if (drawingMode === 'label' && activeShapePoints.length === 1) {
      setPreviewPoint()
      sketchingStore.setEndDrawing(true)
    }
  }, [activeShapePoints])

  useEffect(() => {
    if (currentShape) {
      const lastSketch = currentShape
      if (typeof sketchingStore.sketch.width === 'number') {
        if (drawingMode === 'label') {
          if (sketchingStore.sketch.width > 0) { // remove polyline, add polygon          
            viewer.current.cesiumElement.entities.remove(currentShape)
            viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape)
            setCurrentLineHorizontalShape(drawLineHorizontal(activeShapePoints, sketchingStore.sketch.width, extrudedHeight))
          } else {
            viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape) //remove horizon shape if exist
            setCurrentLineHorizontalShape(undefined)

            if (extrudedHeight === 0) {
              setCurrentShape(drawShape(activeShapePoints))

            } else {

              const newSketch = viewer.current.cesiumElement.entities.add({
                position: activeShapePoints[0],
                label: {
                  font: `${sketchingStore.sketch.sizeLabel}px ${sketchingStore.sketch.font}`,
                  text: sketchingStore.sketch.name,
                  fillColor: Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
                },
              })
              setCurrentShape(newSketch)
            }
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.sketch.width])

  useEffect(() => {
    if (currentShape) {
      const lastSketch = currentShape
      if (typeof sketchingStore.sketch.width === 'number') {
        if (drawingMode === 'point') {
          if (lastSketch) {
            lastSketch.cylinder.topRadius = sketchingStore.sketch.width / 2
            lastSketch.cylinder.bottomRadius = sketchingStore.sketch.width / 2
          }
          let hasSketchTypeModel
          if (sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
            hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
          }
          if (hasSketchTypeModel) {
            hasSketchTypeModel.map(item => {
              let cmModel = getCurrentSketch(
                item.model.hash,
                sketchViews
              )
              if (cmModel.tile?.modelMatrix) {
                let xTran = setSketch3DMesh(item)
                cmModel.tile.modelMatrix = xTran
              }
            })
          }
        } else if (drawingMode === 'line') {
          if (sketchingStore.sketch.width === 0) {
            viewer.current.cesiumElement.entities.remove(lastSketch)
            if (extrudedHeight === 0) {
              let data = drawShape(activeShapePoints)
              if (data) setCurrentShape(data)
            } else {
              if (currentLineHorizontalShape) {
                viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape)
                setCurrentLineHorizontalShape(drawLineHorizontal(activeShapePoints, sketchingStore.sketch.width, extrudedHeight))
              }
              if (currentShape) {
                viewer.current.cesiumElement.entities.remove(currentShape)
              }
              const newSketch = viewer.current.cesiumElement.entities.add({
                wall: {
                  positions: activeShapePoints,
                  maximumHeights: cartographics.map(
                    cat => cat.height + extrudedHeight
                  ),
                  minimumHeights: cartographics.map(cat => cat.height),
                  material: Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
                },
              })
              setCurrentShape(newSketch)
            }
          }
          else {
            if (currentLineHorizontalShape) {
              if (currentShape) {
                viewer.current.cesiumElement.entities.remove(currentShape)
              }
              viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape)
              setCurrentLineHorizontalShape(drawLineHorizontal(activeShapePoints, sketchingStore.sketch.width, extrudedHeight))
            } else {
              if (currentShape) {
                viewer.current.cesiumElement.entities.remove(currentShape)
              }
              setCurrentLineHorizontalShape(drawLineHorizontal(activeShapePoints, sketchingStore.sketch.width, extrudedHeight))

            }
          }
        }
        else {
          if (sketchingStore.sketch.width > 0) { // remove polyline, add polygon          
            viewer.current.cesiumElement.entities.remove(currentShape)
            viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape)
            setCurrentLineHorizontalShape(drawLineHorizontal(activeShapePoints, sketchingStore.sketch.width, extrudedHeight))
          } else {
            viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape) //remove horizon shape if exist
            setCurrentLineHorizontalShape(undefined)

            if (extrudedHeight === 0) {
              setCurrentShape(drawShape(activeShapePoints))

            } else {
              const newSketch = viewer.current.cesiumElement.entities.add({
                wall: {
                  positions: activeShapePoints,
                  maximumHeights: cartographics.map(
                    cat => cat.height + extrudedHeight
                  ),
                  minimumHeights: cartographics.map(cat => cat.height),
                  material: Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
                },
              })
              setCurrentShape(newSketch)
            }
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.sketch.width])

  const ckeck3dMesh = (checkedKeys) => {
    let hasSketchTypeModel
    if (sketchingStore.sketchLibraryElements && checkedKeys && checkedKeys.length > 0) {
      if (sketchingStore.sketchLibraryElements.length > 0) {
        let data = sketchingStore.sketchLibraryElements
        let result = []
        let _checkedKey = checkedKeys
        _checkedKey.map(key => {
          let node = TreeUtils.searchTreeNode(data, 'key', key);
          if (node && node.model) {
            result.push(toJS(node))
          }
        })
        hasSketchTypeModel = result.length > 0 ? result : false
      }
    }
    return hasSketchTypeModel
  }

  const setSketch3DMesh = (item) => {
    const {width, extrudedHeight} = sketchingStore.sketch ;
    const cartesians = sketchingStore.sketch.points.map(
      position => new Cartesian3(position.x, position.y, position.z)
    )
    let point1 = cartesians[0]
    let point2 = cartesians[1] || cartesians[0]
    const point0 = Cartographic.fromCartesian(point1.clone())
    const point00 = point0.clone()
    point0.height += extrudedHeight / 2;
    if (cartesians.length > 1 && drawingMode === 'point') {
      if (cartesians[0].z > cartesians[1].z) {
        let vertical = getVerticalPointClick(point1, point2)
        point00.height = point00.height - vertical
        point0.height = point0.height - vertical
      }
    }

    let _positionModel = Cartesian3.fromDegrees(point00.longitude * CesiumMath.DEGREES_PER_RADIAN, point00.latitude * CesiumMath.DEGREES_PER_RADIAN, point00.height)
    var localFrame = Transforms.eastNorthUpToFixedFrame(_positionModel);
    const rotation = sketchingStore.sketch?.rotation || 1;
    const { Scale_X, Scale_Y, Scale_Z } = Utils.getSketchScale(item.defaultLength, item.defaultWidth, item.defaultHeight, width, extrudedHeight);
    var xTran = getSketchMatrix(Scale_X, Scale_Y, Scale_Z, rotation, localFrame);
    return xTran
  }

  const [checkedKeysModelOld, setCheckedKeysModelOld] = useState([])
  var count = 0
  const getCurrentSketch = (currentSketchId, tileViews) => {
    if (currentSketchId) {
      let fkey = currentSketchId
      let tile = tileViews.find(t => t.key == fkey)
      if (!tile) return false
      return { tile: tile.ref.current.cesiumElement }
    }
    return false
  }

  useEffect(() => {
    if (currentShape && sketchingStore.checkedKeysModel) {
      const lastSketch = currentShape
      if (drawingMode === 'point') {
        if (sketchingStore.checkedKeysModel.length > 0) {
          if (lastSketch) {
            const color = new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(0)
            if (lastSketch.cylinder) {
              lastSketch.cylinder.material = color
            }
            let hasSketchTypeModel
            let deleteSketch = []
            checkedKeysModelOld.map((item) => {
              if (!sketchingStore.checkedKeysModel.includes(item)) {
                deleteSketch.push(item)
              }
            })
            let hasSketch = ckeck3dMesh(deleteSketch)
            if (hasSketch) {
              hasSketch.map((item) => {
                setSketchViews(sketchViews.filter(x => x && x.key !== item.model.hash))
                if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
              })
            }
            if (sketchingStore.sketchLibraryElements && sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
            }
            if (hasSketchTypeModel) {
              setCheckedKeysModelOld(sketchingStore.checkedKeysModel)
              let _model = hasSketchTypeModel.map((item) => {
                let xTran = setSketch3DMesh(item)
                let refModel = React.createRef()
                let cmModel = getCurrentSketch(
                  item.model.hash,
                  sketchViews
                )
                if (!cmModel) {
                  return (<Model
                    type="sketch"
                    url={item.model.src}
                    id={item.model.hash}
                    key={item.model.hash}
                    modelMatrix={xTran}
                    ref={refModel}
                  // show={new CallbackProperty(function () {
                  //   console.log(ref.current?.cesiumElement?.show)
                  //   return ref.current?.cesiumElement?.show
                  // }, false)}
                  />)
                }
              })
              var _modelFilter = _model.filter(x => x)
              if (_modelFilter) {
                setSketchViews(sketchViews => [
                  ...sketchViews, ..._modelFilter
                ])
                if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
              }
              function requestRenderBug() {
                count += 1
                if (!viewer.current.cesiumElement.scene.isDestroyed()) {
                  viewer.current.cesiumElement.scene.requestRender();
                  count > 5 ? clearTimeout(timeout) : setTimeout(requestRenderBug, 1000);
                }
              }
              let timeout = setTimeout(requestRenderBug, 1000);
            }
          }
        }
        else {
          const color = new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
          if (lastSketch.cylinder) {
            lastSketch.cylinder.material = color
          }
          setSketchViews([])
          setCheckedKeysModelOld([])
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.checkedKeysModel])

  useEffect(() => {
    if (currentShape) {
      const lastSketch = currentShape
      if (lastSketch) {
        if (typeof sketchingStore.sketch.rotation === 'number') {
          if (drawingMode === 'point') {
            let hasSketchTypeModel
            if (sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
              hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
            }
            if (hasSketchTypeModel) {
              hasSketchTypeModel.map(item => {
                let cmModel = getCurrentSketch(
                  item.model.hash,
                  sketchViews
                )
                if (cmModel.tile?.modelMatrix) {
                  let xTran = setSketch3DMesh(item)
                  cmModel.tile.modelMatrix = xTran
                }
              })
            }
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.sketch.rotation])

  useEffect(() => {
    if (currentShape) {
      const lastSketch = currentShape
      if (lastSketch) {
        if (drawingMode === 'line') {
          if (sketchingStore.sketch.width === 0) {
            if (lastSketch.wall) {
              lastSketch.wall.material = new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
            } else if (lastSketch.polyline) {
              lastSketch.polyline.material = new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
            }
          } else {
            if (currentLineHorizontalShape) {
              if (currentLineHorizontalShape.polylineVolume)
                currentLineHorizontalShape.polylineVolume.material = new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
              if (currentLineHorizontalShape.corridor)
                currentLineHorizontalShape.corridor.material = new Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
            }
          }
        } else if (drawingMode === 'area') {
          lastSketch.polygon.material = new Color.fromCssColorString(
            sketchingStore.sketch.color.color
          ).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
        }
        else if (drawingMode === 'point') {
          if (sketchingStore.checkedKeysModel.length === 0) {
            lastSketch.cylinder.material = new Color.fromCssColorString(
              sketchingStore.sketch.color.color
            ).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
          }
        }
        else if (drawingMode === 'label') {
          lastSketch.label.fillColor = new Color.fromCssColorString(
            sketchingStore.sketch.color.color
          ).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha))
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.sketch.color])

  useEffect(() => {
    if (currentShape) {
      const lastSketch = currentShape
      if (lastSketch) {
        if (drawingMode === 'label') {
          lastSketch.label.font = `${sketchingStore.sketch.sizeLabel}px ${sketchingStore.sketch.font}`
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.sketch.sizeLabel])

  useEffect(() => {
    if (currentShape) {
      const lastSketch = currentShape
      if (lastSketch) {
        if (drawingMode === 'label') {
          lastSketch.label.font = `${sketchingStore.sketch.sizeLabel}px ${sketchingStore.sketch.font}`
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.sketch.font])

  useEffect(() => {
    setExtrudedHeight(sketchingStore.sketch.extrudedHeight)
    if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }, [sketchingStore.sketch.extrudedHeight])

  useEffect(() => {
    if ((sketchingStore.sketchFormVisible && !sketchingStore.multipleMode) || (!sketchingStore.sketchFormVisible && !sketchingStore.multipleMode && !sketchingStore.sketchMode)) {
      terminateShape()
      setPreviewPoint()
      sketchingStore.setRemovingShape(true)
      sketchingStore.setDrawMode(false)
      sketchingStore.setEndDrawing(false)
      sketchingStore.setSketchMode('')
      sketchingStore.setSketchFormVisible(false)
      // sketchingStore.setMultipleMode(false)
    }
  }, [sketchingStore.multipleMode])

  useEffect(() => {
    if (currentShape) {
      if (typeof extrudedHeight === 'number') {
        const lastSketch = currentShape
        if (drawingMode === 'area') {
          if (lastSketch) {
            if (lastSketch.polygon && !sketchingStore.sketch.readonlyHeight) {
              if (extrudedHeight) {
                lastSketch.polygon.height = sketchingStore.sketch.height
                lastSketch.polygon.extrudedHeight = lastSketch.polygon.height + extrudedHeight
              }
              else {
                //lastSketch.polygon.height = sketchingStore.sketch.height
                lastSketch.polygon.height = undefined
                lastSketch.polygon.extrudedHeight = undefined
              }

              //lastSketch.polygon.extrudedHeight = extrudedHeight
            }
          }
        } else if (drawingMode === 'line') {
          if (sketchingStore.sketch.width === 0) {
            viewer.current.cesiumElement.entities.remove(lastSketch)
            if (extrudedHeight === 0) {
              let data = drawShape(activeShapePoints)
              if (data) setCurrentShape(data)
            } else {
              if (currentLineHorizontalShape) {
                viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape)
                setCurrentLineHorizontalShape(drawLineHorizontal(activeShapePoints, sketchingStore.sketch.width, extrudedHeight))
              }
              if (currentShape) {
                viewer.current.cesiumElement.entities.remove(currentShape)
              }
              const newSketch = viewer.current.cesiumElement.entities.add({
                wall: {
                  positions: activeShapePoints,
                  maximumHeights: cartographics.map(
                    cat => cat.height + extrudedHeight
                  ),
                  minimumHeights: cartographics.map(cat => cat.height),
                  material: Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
                },
              })
              setCurrentShape(newSketch)
            }
          }
          else {
            if (currentLineHorizontalShape) {
              if (currentShape) {
                viewer.current.cesiumElement.entities.remove(currentShape)
              }
              viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape)
              setCurrentLineHorizontalShape(drawLineHorizontal(activeShapePoints, sketchingStore.sketch.width, extrudedHeight))
            } else {
              if (currentShape) {
                viewer.current.cesiumElement.entities.remove(currentShape)
              }
              setCurrentLineHorizontalShape(drawLineHorizontal(activeShapePoints, sketchingStore.sketch.width, extrudedHeight))
            }
          }
        }
        else if (drawingMode === 'label') {
          if (sketchingStore.sketch.width) {
            viewer.current.cesiumElement.entities.remove(lastSketch)
            if (extrudedHeight == 0) {
              setCurrentShape(drawShape(activeShapePoints))
            } else {
              if (currentShape) {
                viewer.current.cesiumElement.entities.remove(currentShape)
              }
              const newSketch = viewer.current.cesiumElement.entities.add({
                position: activeShapePoints[0],
                label: {
                  font: `${sketchingStore.sketch.sizeLabel}px ${sketchingStore.sketch.font}`,
                  text: sketchingStore.sketch.name,
                  fillColor: Color.fromCssColorString(sketchingStore.sketch.color.color).withAlpha(Utils.checkAlphaSketch(sketchingStore.sketch.color.alpha)),
                },
              })
              setCurrentShape(newSketch)
            }
          }
        }
        else if (drawingMode === 'point') {
          if (lastSketch) {
            if (lastSketch.userData && lastSketch.userData.point) {
              let activeShapePointsClone = lastSketch.userData.point.clone()
              activeShapePointsClone.height += sketchingStore.sketch.extrudedHeight / 2
              let activeShapeClone = Cartesian3.fromDegrees(activeShapePointsClone.longitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.latitude * CesiumMath.DEGREES_PER_RADIAN, activeShapePointsClone.height)
              lastSketch.position = activeShapeClone
            }
            lastSketch.cylinder.length = sketchingStore.sketch.extrudedHeight
          }
          let hasSketchTypeModel
          if (sketchingStore.checkedKeysModel && sketchingStore.checkedKeysModel.length > 0) {
            hasSketchTypeModel = ckeck3dMesh(sketchingStore.checkedKeysModel)
          }
          if (hasSketchTypeModel) {
            hasSketchTypeModel.map(item => {
              let cmModel = getCurrentSketch(
                item.model.hash,
                sketchViews
              )
              if (cmModel.tile?.modelMatrix) {
                let xTran = setSketch3DMesh(item)
                cmModel.tile.modelMatrix = xTran
              }
            })
          }
        }
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [extrudedHeight])

  useEffect(() => {
    if (sketchingStore.isEndDrawing) {
      if ((sketchingStore.sketchFormVisible && !sketchingStore.multipleMode) || !sketchingStore.drawMode) return
      terminateShape()
      setPreviewPoint()

      if (sketchingStore.sketch.points.length > 0) {
        sketchingStore.setSketchFormVisible(true)
        sketchingStore.setDrawMore(false)
      } else {
        sketchingStore.setRemovingShape(true)
        sketchingStore.setDrawMode(false)
        sketchingStore.setEndDrawing(false)
        sketchingStore.setSketchMode('')
        sketchingStore.setSketchFormVisible(false)
        sketchingStore.setDrawMore(false)
        sketchingStore.setDrawMoreSketchMode(false)
        sketchingStore.setMultipleMode(false)
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.isEndDrawing])

  useEffect(() => {
    if (sketchingStore.isRemoveShape) {
      sketchingStore.setSketchProps({
        // color: { color: '#ffffff', alpha: 1 },
        // height: 0,
        // extrudedHeight: 0,
        // name: '',
        points: [],
        // width: 0,
        lineHorizontalPositions: []
      })
      if (currentShape) {
        viewer.current.cesiumElement.entities.remove(currentShape)
      }
      if (currentLineHorizontalShape) {
        viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape)
      }
      sketchingStore.setRemovingShape(false)
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [sketchingStore.isRemoveShape])

  useEffect(() => {
  }, [activeSketchPoints])

  useEffect(() => {
    viewer.current.cesiumElement.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    )
    let previewPointSample = undefined
    // click to select draw points

    if (isMobile || isTablet) {
      handler.setInputAction(function (movement) {
        let resultClick = clickPoint(viewer.current.cesiumElement, movement.position)
        if (resultClick && resultClick.position) {
          var n = customLine.positions.length;
          if (n === 0) {
            customLine.addPoint(resultClick);
          } else {
            customLine.positions[n - 1].position = resultClick.position;
            customLine.positions[n - 1].pickedObject = resultClick.pickedObject
          }
          setPreviewPoint(resultClick.position)
          previewPointSample = resultClick.position
        }
        if (sketchingStore.sketchFormVisible && !sketchingStore.multipleMode) return
        if (customLine.positions && customLine.positions.length) {

          resultClick = customLine.positions[customLine.positions.length - 1]

          if (resultClick && resultClick.position) {
            const position = resultClick.position.clone();
            const pickedObject = resultClick.pickedObject
            const ellipsoid = viewer.current.cesiumElement.scene.globe.ellipsoid

            const cartographic = ellipsoid.cartesianToCartographic(position)
            setCartographics(c => [...c, cartographic])
            setPreviewPoint()
            setActiveSketchPoints(point => [...point, {
              position,
              pickedObject
            }])
            setActiveShapePoints(point => [...point, position])
          }
        }
      }, ScreenSpaceEventType.LEFT_CLICK);
    } else {
      handler.setInputAction(function (event) {
        try {
          if (sketchingStore.sketchFormVisible && !sketchingStore.multipleMode) return
          if (customLine.positions && customLine.positions.length) {

            let resultClick = customLine.positions[customLine.positions.length - 1]

            if (resultClick && resultClick.position) {
              const position = resultClick.position.clone();
              const pickedObject = resultClick.pickedObject
              const ellipsoid = viewer.current.cesiumElement.scene.globe.ellipsoid

              const cartographic = ellipsoid.cartesianToCartographic(position)
              setCartographics(c => [...c, cartographic])
              setPreviewPoint()
              setActiveSketchPoints(point => [...point, {
                position,
                pickedObject
              }])
              setActiveShapePoints(point => [...point, position])
            }
          }

        } catch (error) { }
      }, ScreenSpaceEventType.LEFT_CLICK)

      // click to select draw points
      handler.setInputAction(function (event) {
        try {
          if (sketchingStore.sketchFormVisible && !sketchingStore.multipleMode) return
          if (sketchingStore.isEndDrawing) return
          let resultClick = clickPoint(viewer.current.cesiumElement, event.endPosition)

          if (resultClick && resultClick.position) {
            var n = customLine.positions.length;
            if (n === 0) {
              customLine.addPoint(resultClick);
            } else {
              customLine.positions[n - 1].position = resultClick.position;
              customLine.positions[n - 1].pickedObject = resultClick.pickedObject
            }
            setPreviewPoint(resultClick.position)
            previewPointSample = resultClick.position
            //setActiveShapePoints(point => [...point, resultClick.position])
          }
        } catch (error) { }
      }, ScreenSpaceEventType.MOUSE_MOVE)
    }

    // Right click to end drawing shape
    handler.setInputAction(function (event) {
      setPreviewPoint()
      sketchingStore.setEndDrawing(true)
    }, ScreenSpaceEventType.RIGHT_CLICK)

    return () => {
      if (handler) {
        handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
        handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
        handler.removeInputAction(ScreenSpaceEventType.RIGHT_CLICK)
      }
      setMakers([])
      setActiveShapePoints([])
      setActiveSketchPoints([])
      if (activeShape) viewer.current.cesiumElement.entities.remove(activeShape)
      setActiveShape(undefined)
      setCurrentShape(undefined)
      setCurrentLineHorizontalShape(undefined)
      setCartographics([])
    }
  }, [])

  var pBuilder = new PinBuilder()

  useEffect(() => {
    setMakers(prevState => [
      ...prevState,
      {
        pin: (
          <Entity
            key={uuid()}
            name="Blank red pin"
            position={activeShapePoints[activeShapePoints.length - 1]}
            billboard={{
              image: pBuilder.fromColor(Color.SPRINGGREEN, 12).toDataURL(),
              verticalOrigin: VerticalOrigin.BOTTOM,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            }}
          />
        ),
      },
    ])
    const points = JSON.parse(JSON.stringify(activeShapePoints))

    if (previewPoint) {
      if (!sketchingStore.isEndDrawing) {
        points.push(previewPoint)
      }

      if (drawingMode === 'area' && points.length > 2) {
        var polygon = new PolygonHierarchy(points)
        viewer.current.cesiumElement.entities.remove(activeShape)
        setActiveShape(drawShape(polygon))
      } else {

        viewer.current.cesiumElement.entities.remove(activeShape)
        if (currentLineHorizontalShape) {
          viewer.current.cesiumElement.entities.remove(currentLineHorizontalShape)
        }
        setActiveShape(drawShape(points))
      }
      if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }
  }, [activeShapePoints.length, previewPoint])

  return <>{makers.map(p => p.pin)} {sketchViews.map(sketchView => sketchView)}</>
}

export default inject('projectStore', 'sketchingStore', 'projectStore')(observer(DrawSketch))
