import {
  ClusterOutlined,
  ProjectOutlined,
  SettingOutlined,
  SketchOutlined,
  GlobalOutlined,
  MenuOutlined,
  HomeOutlined,
  FileProtectOutlined,
  UserOutlined,
  DatabaseOutlined,
  NotificationOutlined,
  MailOutlined,
  DeploymentUnitOutlined,
  SafetyCertificateOutlined,
  TranslationOutlined
} from '@ant-design/icons'
import { Avatar, Image, Layout, Menu } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AppLogo from '../../../../assets/icons/6DPlanner - SQ - 75px.png'
import { CustomMenuIconMobile, LogoWraper, LogoWraperMobile, MenuIconMobile } from '../style'
import './../style.css'
import { useTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars'
import { useMediaQuery } from 'react-responsive'
import { HeaderSider} from './style'
import { isTablet } from 'react-device-detect'
const { Sider } = Layout

const SidebarNavigation = props => {
  const { t } = useTranslation()
  const { adminStore, triggerSider, commonStore } = props
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const history = useHistory()

  const clearStatus = () => {
    adminStore.clearStatus()
    adminStore.clearPaginationParams()
  }

  const handleChangeKey = e => {
    adminStore.setActiveTab(e.key)
  }

  const toggle = () => {
    let _collapsed = !adminStore.collapsed
    adminStore.setcollapsed(_collapsed)
    triggerSider(_collapsed)
  }
  const toggleMenuMobile = () => {
    adminStore.setIsToggleMenuMobile()
  }
  useEffect(() => {
    isMobile && adminStore.setIsToggleMenuMobileParam(false)
  }, [isMobile])

  return (
    <Sider
      collapsed={adminStore.collapsed}
      className="sider-menu-layout"
      theme="light"
      collapsible
      trigger={null}>
      {!isMobile ? (
        <HeaderSider collapsed ={adminStore.collapsed} > 
          <LogoWraper onClick={() => history.push('/')}>
            {adminStore.collapsed ? (
              <Avatar>6DPlanner</Avatar>
            ) : (
              <Image preview={false} width={75} src={AppLogo} />
            )}
          </LogoWraper>
          {!adminStore.collapsed && <CustomMenuIconMobile onClick={toggle}>
            <MenuOutlined style={{ margin: '16px 18px' }} />
          </CustomMenuIconMobile>}
        </HeaderSider>
      ) : (
        <MenuIconMobile onClick={toggleMenuMobile}>
          <MenuOutlined style={{ margin: '16px 18px' }} />
        </MenuIconMobile>
      )}
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{ height: '100%', width: '100%' }}>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['admin/organization']}
          selectedKeys={[commonStore.currentPage]}
          style={{ marginTop: 28 }}
          onClick={e => handleChangeKey(e)}>
          {isMobile ? (
            <Menu.Item className="menu-item-logo-mobile">
              <LogoWraperMobile onClick={() => history.push('/')}>
                {adminStore.collapsed ? (
                  <Avatar>6DPlanner</Avatar>
                ) : (
                  <Image width={75} src={AppLogo} />
                )}
              </LogoWraperMobile>
            </Menu.Item>
          ) : (
            ''
          )}
          <Menu.Item key="/" icon={<HomeOutlined />}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              {t('commons.home')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/organization"
            icon={<ClusterOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('organizations')}
            >
            <Link
              to={`/admin/organizations/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`}
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('organizations')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key={`admin/projects`}
            icon={<ProjectOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('projects')}
            >
            <Link
              to={`/admin/projects/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`}
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('projects')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key={`admin/licenses`}
            icon={<FileProtectOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('licenses')}
            >
            <Link
              to={`/admin/licenses/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`}
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('licenses')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/licensetypes"
            icon={<FileProtectOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('license-types')}
            >
            <Link
              to={`/admin/licensetypes/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`}
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('license-types')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/featuresetting"
            icon={<SettingOutlined/>}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('roles')}
            >
            <Link
              to="/admin/featuresetting"
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('roles')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/predefinedaccess"
            icon={<DatabaseOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('data-library')}
            >
            <Link
              to="/admin/predefinedaccess"
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('data-library')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key={`admin/users`}
            icon={<UserOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('users')}
            >
            <Link
              to={`/admin/users/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`}
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('users')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/notification"
            icon={<MailOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('email-notification')}
            >
            <Link
              to="/admin/notification"
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('email-notification')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/generic-email-notification"
            icon={<NotificationOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('generic-email-notification')}
            >
            <Link
              to="/admin/generic-email-notification"
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('generic-email-notification')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key={`admin/ifcengineusage`}
            icon={<DeploymentUnitOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('ifc-engine-usage')}
            >
            <Link
              to={`/admin/ifcengineusage`}
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('ifc-engine-usage')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/legal"
            icon={<SafetyCertificateOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('legal')}
            >
            <Link
              to="/admin/legal"
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('legal')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/sketch-library"
            icon={<SketchOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('sketch-library')}
            >
            <Link
              to="/admin/sketch-library"
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('sketch-library')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/language-editer"
            icon={<TranslationOutlined />}
            onClick={() => clearStatus()}
            title={(isMobile || isTablet)? null : t('language-editor')}
            >
            <Link
              to="/admin/language-editer"
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('language-editor')}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="admin/geoid-models"
            icon={<GlobalOutlined />}
            onClick={() => clearStatus()}
            style={{ marginBottom: '50px' }}
            title={(isMobile || isTablet)? null : t('geoid-models')}
            >
            <Link
              to="/admin/geoid-models"
              style={{ textDecoration: 'none' }}
              onClick={() => clearStatus()}>
              {t('geoid-models')}
            </Link>
          </Menu.Item>
        </Menu>
      </Scrollbars>
      {/* <button onClick={toggle} className="sidebar-minimizer mt-auto" type="button">
                {adminStore.collapsed ? <RightOutlined /> : <LeftOutlined />}
            </button> */}
    </Sider>
  )
}

export default inject('adminStore', 'commonStore')(observer(SidebarNavigation))
