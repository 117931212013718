import { Modal } from 'antd'
import styled from 'styled-components'

export const FormStyled = styled.div` 
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  .ant-form-item{
    margin-bottom: 8px;
  }
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
    padding: 0 13px;
  }
  .ant-btn.lastbtn {
    margin-right: 0px;
  }
  .ant-progress-custom {
    margin-bottom: 10px;
  }
  .label{    
      position: relative; 
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px; 
  }

  .ant-form-item-label {
    line-height: inherit;
  }
  .ant-form-item-label{
    font-weight: bold;
  }

  .query-builder {
    .ant-btn {
      margin-right: 0px;
    }
  }
`

export const CustomModal = styled(Modal)`
    .ant-form-item{
      margin-bottom: 8px;
    }
    .action-wrap{
      display: flex;
      justify-content: flex-end;
    }
`
export const Statistics = styled.div`
    .label{
      font-weight: bold;
    }
    .category{
      display: flex;
    }
    .label-category{
      width: 120px;
    }
`