import styled from 'styled-components'

export const CardViewWrapper = styled.div`
  .ant-card {
    background-color: transparent;
    border: none;
    .ant-card-body {
      padding: 10px;
    }
    .ant-card-cover {
      position: relative;
      .anticon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: auto;
        color: white;
        font-size: 16px;
        opacity: 0;
        transition: ease .3s;
      }
      &:hover {
        cursor: pointer;
        .anticon {
          transition: ease .3s;
          opacity: 1;
        }
      }
    }
    .ant-card-meta-title {
      color: white;
      font-size: 12px;
      text-align: center;
    }
  }
`