import styled from 'styled-components'

export const ProjectWraper = styled.div`
  background: #FFFFFF;
  padding: 60px 15px 15px;
  margin-top: 75px;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
  .project-card {
    margin-bottom: 20px;
    &.action-card {
      .ant-card-actions > li {
        margin: 0;
        span button {
          width: 100%;
          white-space: normal;
          word-break: break-all;
          border-radius: 0;
          border: none;
          line-height:45px;
          height:45px;
          &.ant-btn-primary {
            background-color: #fff;
            color: #666;
            .anticon {
              color: green;
            }
          }
          &.ant-btn-danger {
            background-color: #fff;
            color: #666;
            .anticon {
              color: #fc0000;
            }
          }
        }
      }
    }
    .ant-card-cover {
      img {
        width: 100%;
        height: 175px;
        object-fit: cover;
      }
    }
  }
  .ant-empty {
    width: 100%;
  }
`
export const ProjectHeading = styled.h2`
  font-size: 24px;
  color: #ff8d4f;
  text-align: center;
`

export const ContentProjectInfo = styled.p`
  font-weight: ${props => props.bold ? 'bold':'normal'};
  font-size: ${props => props.size || '10pt'};
  margin: 4px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: black;
  opacity: 75%;
`