import styled from 'styled-components'

export const DrawerHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 35px;
  .anticon:hover {
    cursor: pointer;
  }
`
export const ProjectInfoTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 25px;
`
export const FormLayout = styled.div`  
  .ant-form-item-label {  
    text-align: left;
  }  
  .ant-btn{
    margin-right:8px;
  }
  .ant-input-number{
    width: 100% !important;
  }

  .navigation-underground .ant-form-item-control-input-content{
    display: flex;
    justify-content: space-between;
  }
`

export const NavigationStyled = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  -webkit-transition: all .2s;
  transition: all .2s;
  border-radius: 5px;
  .code-box-title {
    position: absolute;
    top: -14px;
    margin-left: 16px;
    padding: 1px 8px;
    color: #777;
    background: #fff;
    border-radius: 2px 2px 0 0;
    -webkit-transition: background-color .4s;
    transition: background-color .4s;
  }
  .code-box-description{
    padding: 15px 15px 0px;
  }
`

