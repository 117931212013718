import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import Utils from '../../../utils'
import { message, Layout } from 'antd'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import LoadingSpinner from '../../elements/LoadingSpinner'
import { useTranslation } from 'react-i18next';
import axios from 'axios'

const PublicJsonLink = props => {
    const { t } = useTranslation();
    const { projectStore, commonStore, match } = props

    const checkLoading = () =>
        !!(
            projectStore.isLoading
        )

    useEffect(() => {
        function checkProjectDetail() {
            let params = {
                projectId: match.params.projectId,
                hash: match.params.hash,
                modelName: match.params.modelName,
            }
            projectStore.setLoadingProgress(true)
            projectStore.getPublicJson(params, commonStore.token).then(async response => {
                if (response) {
                    if (response.status === 200) {
                        let linkjson = await Utils.getTileSetUrlFromModel(response.data)
                        let headers = {
                            'Content-type': 'application/json',
                        }
                        if (linkjson.accessToken) {
                            headers.Authorization = `Bearer ${linkjson.accessToken}`
                        }
                        axios(linkjson.tileSetUrl, {
                            method: 'GET',
                            headers
                        })
                            .then((res) => res.data)
                            .then((data) => {
                                // Update json transform if follow model project
                                if (data.root && data.root.transform) {
                                    data.root.transform = response.data.data.transform
                                }
                                let jsontext = JSON.stringify(data);
                                let href = "data:text/json;charset=utf-8," + encodeURIComponent(jsontext)
                                let anchor = document.createElement('a')
                                anchor.setAttribute("href", href);
                                anchor.setAttribute("download", match.params.modelName);
                                document.body.appendChild(anchor);
                                anchor.click();
                                document.body.removeChild(anchor);
                            })
                    } else {
                        message.error(t(response.data.message))
                    }
                } else {
                    message.error(t(response.data.message))
                }
            }).catch((error) => console.log(error))
            .finally((x) => {
                projectStore.setLoadingProgress(false)
            })
        }
        checkProjectDetail()
    }, [])

    return (
        <HelmetProvider>
            <Layout>
                <Helmet>
                    <title>{t('public-json-file')} | 6DPlanner</title>
                </Helmet>
                {
                    checkLoading()
                        ? <LoadingSpinner type={'page'} theme={commonStore.appTheme} />
                        : null
                }
            </Layout>
        </HelmetProvider>
    )
}

export default inject(
    'projectStore', 'commonStore'
)(observer(PublicJsonLink))
