import { t } from 'i18next'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import AdminTemplate from '../../layout/AdminTemplate'
import { Trans, useTranslation } from 'react-i18next';

const AdminPage = ({ commonStore, history }) => {
    const { t } = useTranslation();
    useEffect(() => {
        commonStore.setCurrentPage('admin');
    }, [])

    return (
        <AdminTemplate title={t('admin-page')} >

        </AdminTemplate>
    );
}

export default inject("commonStore")(observer(AdminPage));

