import { CalendarOutlined, ClearOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  Alert, Button, Col,
  DatePicker, Input, Row, Select, Slider, Tooltip, Typography
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { toJS } from 'mobx';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Icon4DBack } from '../../../../assets/svgs/4DBack-s0.svg';
import { ReactComponent as Icon4DForward } from '../../../../assets/svgs/4DForward-s0.svg';
import { ReactComponent as Icon4DPause } from '../../../../assets/svgs/4DPause-s0.svg';
import { ReactComponent as Icon4DPlay } from '../../../../assets/svgs/4DPlay-s0.svg';
import SVGIcon from '../../../elements/SVGIcon';
import './custom.css';
import {
  ButtonCustom, CenteredContent, CenteredContentBigIcon, SliderContainer
} from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
import en from "antd/lib/date-picker/locale/en_US";
import fi from "antd/lib/date-picker/locale/fi_FI";
import sv from "antd/lib/date-picker/locale/sv_SE";
import es from "antd/lib/date-picker/locale/es_ES";
import vi from "antd/lib/date-picker/locale/vi_VN";
const CalendarIcon = <CalendarOutlined />
const { Option } = Select;

const TimeSlider = props => {
  const { t } = useTranslation();
  const [timeSearchModal, setTimeSearchModal] = useState(false)
  const { schedulingStore, projectStore, capturesStore, commonStore } = props
  const [sliderValue, setSliderValue] = useState(false)
  const [isPlayed, setPlayVideoState] = useState(false)
  const [getTimeBefore, setGetTimeBefore] = useState()
  const [getTimeAfter, setGetTimeAfter] = useState()
  const [alertTimeSearchError, setAlertTimeSearchError] = useState(false)
  const [firstAccess, setFirstAccess] = useState(true)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  moment.locale(`${commonStore.language}`)

  const handleChangeTimeStep = value => {
    schedulingStore.setTimeStep(value)
    schedulingStore.setTargetTimeStep(true)
    schedulingStore.setOriginViewingTime(schedulingStore.currentViewingTime)
    setSliderValue(50)
  }

  useEffect(() => {
    if (schedulingStore.currentViewingTime) {
      if (capturesStore.isDrawerCaptureVisible) {
        if (projectStore.currentViewpoint?.cameraData?.timeSlider) {
          let time = moment(projectStore.currentViewpoint.cameraData.timeSlider)
          schedulingStore.setCurrentViewingTime(time)
          schedulingStore.setOriginViewingTime(time)
          schedulingStore.setBeforeTime(time)
          schedulingStore.setAfterTime(time)
          setSliderValue(50)
          setFirstAccess(false)
        }
      }
    }
  }, [projectStore.currentViewpoint])


  const handleChangeTimeBefore = value => {
    schedulingStore.setTimeBeforeStep(value)
  }

  const handleChangeTimeAfter = value => {
    schedulingStore.setTimeAfterStep(value)
  }

  const handleGetTimeAfter = e => {
    if (e.target.value > 50) {
      setGetTimeAfter(50)
    } else {
      setGetTimeAfter(e.target.value)
    }
  }

  const handleGetTimeBefore = e => {
    if (e.target.value > 50) {
      setGetTimeBefore(50)
    } else {
      setGetTimeBefore(e.target.value)
    }

  }

  const showTimeSearchModal = () => {
    setTimeSearchModal(true)
    schedulingStore.setBeforeTime(schedulingStore.currentViewingTime)
    schedulingStore.setAfterTime(schedulingStore.currentViewingTime)
  }


  const handleCloseTimeSearchModal = () => {
    setTimeSearchModal(false)
  }

  const handleOkTimeSearchModal = () => {
    if (getTimeBefore && getTimeAfter) {
      setTimeSearchModal(false)

      schedulingStore.setBeforeTime(undefined, getTimeBefore)
      schedulingStore.setAfterTime(undefined, getTimeAfter)

      const localStartDate = moment.utc(schedulingStore.beforeTime).local().format()
      const localEndDate = moment.utc(schedulingStore.afterTime).local().format()

      schedulingStore.setBeforeDateConvert(moment(localStartDate, 'YYYY-MM-DD hh:mm:ss.SSSSZ'))
      schedulingStore.setAfterDateConvert(moment(localEndDate, 'YYYY-MM-DD hh:mm:ss.SSSSZ'))
      setSliderValue(49)
    } else if (!getTimeBefore && getTimeAfter) {
      setTimeSearchModal(false)

      schedulingStore.setBeforeTime(1432252800)
      schedulingStore.setAfterTime(undefined, getTimeAfter)

      const localStartDate = moment.utc(schedulingStore.beforeTime).local().format()
      const localEndDate = moment.utc(schedulingStore.afterTime).local().format()

      schedulingStore.setBeforeDateConvert(moment(localStartDate, 'YYYY-MM-DD hh:mm:ss.SSSSZ'))
      schedulingStore.setAfterDateConvert(moment(localEndDate, 'YYYY-MM-DD hh:mm:ss.SSSSZ'))
      setSliderValue(49)
    } else if (getTimeBefore && !getTimeAfter) {
      setTimeSearchModal(false)

      schedulingStore.setBeforeTime(undefined, getTimeBefore)
      schedulingStore.setAfterTime(4102448461000)

      const localStartDate = moment.utc(schedulingStore.beforeTime).local().format()
      const localEndDate = moment.utc(schedulingStore.afterTime).local().format()

      schedulingStore.setBeforeDateConvert(moment(localStartDate, 'YYYY-MM-DD hh:mm:ss.SSSSZ'))
      schedulingStore.setAfterDateConvert(moment(localEndDate, 'YYYY-MM-DD hh:mm:ss.SSSSZ'))
      setSliderValue(49)
    } else {
      setAlertTimeSearchError(true)
      setTimeout(() => setAlertTimeSearchError(false), 5000)
    }
  }

  const handleClearTimeSearch = () => {
    schedulingStore.setBeforeDateConvert(null)
    schedulingStore.setAfterDateConvert(null)
    setGetTimeBefore()
    setGetTimeAfter()
    setSliderValue(49 + 1)
  }

  const onClose = () => {
    setAlertTimeSearchError(false)
  }

  const handleSetTime = (value) => {
    schedulingStore.setCurrentViewingTime(value)
    schedulingStore.setOriginViewingTime(value)
    schedulingStore.setBeforeTime(value)
    schedulingStore.setAfterTime(value)
    setSliderValue(50)
    setFirstAccess(false)
  }

  const onChangeSlider = (value) => {
    setSliderValue(value);
    setFirstAccess(false)
  }

  const tipFormatter = () => {
    if (schedulingStore.currentViewingTime) {
      return schedulingStore.currentViewingTime.format("DD MM YYYY, HH:mm")
    }
  }

  const jumpForward = () => {
    setSliderValue(sliderValue + 1)
    setFirstAccess(false)
  }

  const jumpBackward = () => {
    setSliderValue(sliderValue - 1)
    setFirstAccess(false)
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  useInterval(
    () => {
      jumpForward()
    },
    isPlayed ? 2000 : null
  )

  const playVideo = () => {
    setPlayVideoState(true)
    setFirstAccess(false)
  }

  const pauseVideo = () => {
    setPlayVideoState(false)
  }

  useEffect(() => {
    if (schedulingStore.currentViewingTime) {
      const steps = sliderValue - 50
      const currentTimeStep = schedulingStore.timeStep
      const stepUnits = currentTimeStep + 's'
      const ogirinalTime = schedulingStore.originViewingTime.clone()
      if (!firstAccess) {
        schedulingStore.setCurrentViewingTime(steps > 0 ? ogirinalTime.add(steps, stepUnits) : ogirinalTime.subtract(Math.abs(steps), stepUnits))
      }
    }
  }, [sliderValue])

  useEffect(() => {
    if (schedulingStore.timeSliderVisible) {
      //Check if not currentViewingTime then 
      if (!schedulingStore.currentViewingTime) {
        var CurrentDate = moment();
        schedulingStore.setOriginViewingTime(CurrentDate)
        schedulingStore.setCurrentViewingTime(CurrentDate)
        setSliderValue(50)
      } else {
        setSliderValue(50)
      }
    }
    return () => {
      setSliderValue(false)
      setPlayVideoState(false)
      schedulingStore.setTargetTimeStep(false)
    }
  }, [])

  return (
    <SliderContainer>
      <div className={alertTimeSearchError ? "show default" : "hidden default"}>
        <CloseCircleOutlined className="close-alert" onClick={onClose} />
        <Alert
          message={t('error')}
          description={t('you-must-input-at-least-one-value')}
          type="error"
        />
      </div>


      <Modal title={t('time-search-dialog')} visible={timeSearchModal}
        onOk={handleOkTimeSearchModal}
        onCancel={handleCloseTimeSearchModal}>

        <div className="timeSearch">
          <Typography className="timeSearch-title">{t('current-time')}: </Typography>
          <div className="timeSearch-datePicker">
            <DatePicker
              locale={commonStore.language ? commonStore.language.replace(/["']/g, "") : en}
              inputReadOnly={true}
              defaultValue={schedulingStore.currentViewingTime}
              showTime
              placeholder={t('select-time')}
              onChange={handleSetTime}
              onOk={handleSetTime}
              style={{ minWidth: 0 }} allowClear={false}
            />
          </div>
        </div>

        <div className="timeSearch">
          <Typography className="timeSearch-title">{t('before')}: </Typography>

          <Select
            defaultValue={schedulingStore.timeBeforeStep}
            onChange={handleChangeTimeBefore}
            dropdownStyle={{ zIndex: 9999 }}>
            {schedulingStore.timeBeforesStep.map(step => {
              return <Option key={step.key} value={step.key}>{step.value}</Option>
            })}
          </Select>

          <Input
            className={alertTimeSearchError ? "timeSearch-inputError" : "timeSearch-input"}
            placeholder="0"
            type="number"
            max={50}
            min={0}
            value={getTimeBefore}
            onChange={handleGetTimeBefore}
          />
        </div>

        <div className="timeSearch">
          <Typography className="timeSearch-title">{t('after')}: </Typography>
          <Select
            defaultValue={schedulingStore.timeAfterStep}
            onChange={handleChangeTimeAfter}
            dropdownStyle={{ zIndex: 9999 }}>
            {schedulingStore.timeAftersStep.map(step => {
              return <Option key={step.key} value={step.key}>{step.value}</Option>
            })}
          </Select>

          <Input
            className={alertTimeSearchError ? "timeSearch-inputError" : "timeSearch-input"}
            placeholder="0"
            value={getTimeAfter}
            type="number"
            max={50}
            min={0}
            onChange={handleGetTimeAfter}
          />
        </div>


      </Modal>
      <Row type="flex" justify="center" align="middle">
        {
          !isMobileOnly &&
          <Col lg={4} md={4} >
            <CenteredContent>
              <DatePicker
                locale={{
                  ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en),
                  lang: {
                    ...(commonStore.language === "fi" ? fi : commonStore.language === "sv" ? sv : commonStore.language === "es" ? es : commonStore.language === "vi" ? vi : en).lang,
                    now: t('now'),
                    ok: t('commons.ok'),
                  }
                }}
                value={schedulingStore.currentViewingTime}
                showTime
                placeholder={t('select-time')}
                onChange={handleSetTime}
                onOk={handleSetTime}
                style={{ minWidth: 0 }}
                allowClear={false}
              />
            </ CenteredContent>
          </Col>
        }
        <Col lg={{ span: 1 }} md={2} xs={(isMobileOnly && isPortrait) && 3}>
          <CenteredContentBigIcon>
            <ButtonCustom icon={<SVGIcon content={<Icon4DBack />} />} onClick={jumpBackward} />
          </CenteredContentBigIcon>
        </Col>
        {/* Change lg={12} when show time search button */}
        <Col lg={14} md={12} xs={(isMobileOnly && isPortrait) && 18} style={(isMobileOnly && !isPortrait) && { width: '55%' }}>
          <Slider
            value={sliderValue}
            tooltipVisible
            tipFormatter={tipFormatter}
            onChange={onChangeSlider}
          // onAfterChange={onAfterChangeSlider}          
          />
        </Col>
        <Col lg={1} md={2} xs={(isMobileOnly && isPortrait) && 3}>
          <CenteredContentBigIcon>
            <ButtonCustom icon={<SVGIcon content={<Icon4DForward />} />} onClick={jumpForward} />
          </CenteredContentBigIcon>
        </Col>
        <Col lg={1} md={2} >
          <CenteredContentBigIcon>
            {!isPlayed ?
              <ButtonCustom icon={<SVGIcon content={<Icon4DPlay />} />} onClick={playVideo} />
              :
              <ButtonCustom icon={<SVGIcon content={<Icon4DPause />} />} onClick={pauseVideo} />
            }
          </CenteredContentBigIcon>
        </Col>
        <Col lg={3} md={2} style={(isMobileOnly && isPortrait) && { marginRight: 10, marginLeft: 10 }} >
          <CenteredContent>
            <Select defaultValue={schedulingStore.timeStep} onChange={handleChangeTimeStep} dropdownStyle={{ zIndex: 9999 }}>
              {schedulingStore.timeSteps.map(step => {
                return <Option key={step.key} value={step.key}>{t(step.value.toString().toLowerCase())}</Option>
              })}
            </Select>
          </CenteredContent>
        </Col>
        {
          isMobileOnly &&
          <Col >
            <CenteredContent>
              <DatePicker
                locale={commonStore.language ? commonStore.language.replace(/["']/g, "") : en}
                className={(isMobileOnly && isPortrait) ? "slider-datepicker-mobile portrait" : "slider-datepicker-mobile"}
                value={schedulingStore.currentViewingTime}
                showTime
                placeholder={t('select-time')}
                onChange={handleSetTime}
                onOk={handleSetTime}
                inputReadOnly={true}
                dropdownClassName="slider-timepicker"
                allowClear={false}
                suffixIcon={CalendarIcon}
              />
            </ CenteredContent>
          </Col>
        }
        {/* Hidden and Show time search here */}
        {
          timeSearchModal && // remove this line when we want to show Time-Search Button
          <Col lg={2} md={12} sm={12} xs={12}>
            {
              schedulingStore.beforeDateConvert === null && schedulingStore.afterDateConvert === null ?
                <Button onClick={showTimeSearchModal}>{t('time-search')}</Button> : <Tooltip placement="top" title={t('clear-time-search')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <Button className="clear-timeSearch" onClick={handleClearTimeSearch}><ClearOutlined /></Button>
                </Tooltip>
            }
          </Col>
        }
      </Row>
    </SliderContainer >
  )
}

export default inject('schedulingStore', 'projectStore', 'capturesStore', 'commonStore')(observer(TimeSlider))