import { CloseCircleOutlined, ColumnWidthOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, message, Popconfirm, Skeleton, Table, Tooltip, Tabs } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import AddTeamMemberModal from './AddTeamMemberModal'
import ChangeRoleUserInProject from './ChangeRoleUserInProject'
import { DrawerContentHeader } from './style'

const DrawerProjectTeams = ({ projectTeamsStore, projectStore, usersStore, adminStore, commonStore, loading }) =>{
    const { t } = useTranslation();
    moment.locale(`${commonStore.language}`)
    const isTabletOrMobile = isMobile || isTablet
    const [role, setRole] = useState('');
    const [userDataList, setUserDataList] = useState([])

    useEffect(() => {
        if (projectTeamsStore.showProjectTeamsDrawer) {
            let _currentUser = usersStore.currentUser
            let allUser = projectTeamsStore.teamList
            let k = allUser.find(elm => elm.user && elm.user._id === _currentUser.id)
            setRole(k?.projectrole?.role)
            setUserDataList(projectTeamsStore.teamList.filter(c => c.email !== "maintenance@xd-visuals.fi"))
        }

        return () => {
        }
    }, [projectTeamsStore.teamList])

    useEffect(() => {
        return () => {
            onCloseDrawerManagement()
        }
    }, [projectTeamsStore.showProjectTeamsDrawer])

    const onCloseDrawerManagement = () =>{
        setRole('')
        setUserDataList([])
    }

    const onClickHandlerAddNewMember = (e) => {
        projectTeamsStore.toggleShowAddTeamMemberModal(true)
    }

    const handleClickRemoveUserFromProjectInOrganization = (projectuserrolesId) => {
        adminStore.setLoadingProgress(true)
        adminStore.removeUserFromProject(projectuserrolesId).then(res => {
            projectTeamsStore.getProjectTeams(projectStore.projectDetail.id)
            const index = userDataList.findIndex(x => x._id === projectuserrolesId)
            const temp = [...userDataList]
            temp.splice(index, 1)
            setUserDataList(temp)
            adminStore.setLoadingProgress(false)
            message.success(t('remove-user-successfully'))
        }).catch(error => {
            adminStore.setLoadingProgress(false)
            message.error(t('remove-user-failed-Please-try-again'))
            return;
        })
    }

    const getJoined = (record) => {
        return record.inviteStatus === 'accepted' ? (
            record.acceptedDate ? record.acceptedDate : record.createdAt
        ) : ''
    }
    const getUserName = (record) => {
        return record && record.username ? record.username : t('user-not-found')
    }

    const tableColumns = [
        {
            title: t('user'),
            dataIndex: 'user',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a && b ? getUserName(a.user).localeCompare(getUserName(b.user)) : true,
            render: record => {
                return getUserName(record)
            }
        },
        {
            title: t('status'),
            dataIndex: 'inviteStatus',
            key: "status",
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.inviteStatus.localeCompare(b.inviteStatus),
            render: record => {
                return (record ? t(record) : t('not-existing'))
            }
        },
        {
            title: t('email'),
            dataIndex: 'email',
            key: "email",
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: t('role'),
            dataIndex: 'projectrole',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.projectrole.roleName.localeCompare(b.projectrole.roleName),
            render: record => {
                return (record?.roleName)
            }
        },
        {
            title: t('Joined'),
            width: 170,
            key: "joined",
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                const distantFuture = new Date(8640000000000000)
                let dateA = getJoined(a) ? new Date(getJoined(a)) : distantFuture
                let dateB = getJoined(b) ? new Date(getJoined(b)) : distantFuture
                return dateA.getTime() - dateB.getTime()
            },
            render: record => {
                return (
                    <div className={'tileset-name'}>
                        {getJoined(record) ? moment(getJoined(record)).format('MMMM DD, YYYY') : ''}
                    </div>
                )
            }
        },
        {
            title: t('action'),
            key: 'action',
            width: 120,
            align: 'center',
            render: (record) => {
                let showButton = false
                let prjRole = record?.projectrole?.role
                // showButton = (prjRole && record.user && prjRole === "project_owner" && record.project.user === record.user?._id) ? false : true
                return <>
                    {
                         projectStore.currentUserRoleInProject === "project_owner"  ? (
                            <>
                                <Button
                                    onClick={() => adminStore.setShowChangeRoleUserModal(true)}
                                    icon={<EditOutlined />} style={{ margin: '5px' }} />
                                <Popconfirm
                                    title={t('are-you-sure-remove-this-user-from-this-project')}
                                    onConfirm={() => handleClickRemoveUserFromProjectInOrganization(record._id)}
                                    okText={t('commons.yes')}
                                    cancelText={t('commons.no')}
                                >
                                    <Button danger icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </>
                        ) : ''
                    }
                </>
            },
        }
    ]

    return (
        <DrawerContentHeader visible={projectTeamsStore.showProjectTeamsDrawer ? true : false}>
            <Skeleton loading={loading} active>
                <div style={{ textAlign: 'right', paddingTop: 5, paddingBottom: 10 }}>
                    {
                        role === 'project_owner' && projectStore.isExistLicenses ? (
                            <>
                                <Button icon={<PlusOutlined />} type="primary" onClick={e => onClickHandlerAddNewMember(e)}>
                                    {t('add-new-user')}
                                </Button>
                            </>
                        ) : ''
                    }
                </div>
                <Table
                    scroll={{ x: 700 }}
                    pagination={false}
                    rowKey={record => record._id}
                    columns={tableColumns}
                    dataSource={userDataList}
                    onRow={(record) => ({
                        onClick: (event) => {
                            return adminStore.setCurrentUserRoleDetailInProject(record)
                        },
                    })}
                    locale={{ emptyText: t('no-data') }}
                />
                <ChangeRoleUserInProject />
                <AddTeamMemberModal />
                
            </Skeleton>
        </DrawerContentHeader>
    )
}

export default inject(
    'projectTeamsStore',
    'projectStore',
    'usersStore',
    'projectSettingStore',
    'adminStore',
    'licenseStore',
    'commonStore',
    'userGroupStore'
)(observer(DrawerProjectTeams))