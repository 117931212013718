import { ColumnHeightOutlined, FilterOutlined, FullscreenExitOutlined, FullscreenOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Empty, InputNumber, message, Popconfirm, Select, Space, Table, Tag, Tooltip, Typography, Col, Row } from 'antd';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { TopicPageHeading, TopicPageWrapper } from './style';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { useHistory } from 'react-router-dom'
import HelpButton from '../../elements/HelpButton';
const { CheckableTag } = Tag;
const { Text } = Typography;
const { Option } = Select;

const TopicListDrawer = ({ projectStore, topicStore, adminStore, location, projectTeamsStore, commonStore ,usersStore }) => {
    const { t } = useTranslation();
    moment.locale(`${commonStore.language}`)

    const [filterValue, setFilterValue] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    const [drawerHeight, setDrawerHeight] = useState(0);
    const { projectId } = useParams()
    const history = useHistory()

    useEffect(() => {
        if (topicStore.isShownListTopic) {
            let _filterParams = []
            if (filterValue) {
                Object.keys(filterValue).forEach(key => {
                    if (filterValue[key]) {
                        _filterParams.push(`&FilterBy=${key}&FilterValue=${filterValue[key]}`)
                    }
                })
            }
            var _param = _filterParams.join('&');
            topicStore.setLoadingProgress(true)
            // projectTeamsStore.getProjectTeams(projectStore.projectDetail.id)
            topicStore.getTopicList(projectStore.projectDetail.id, _param).then(() => {
                topicStore.setLoadingProgress(false)
            }).catch(error => {
                topicStore.setLoadingProgress(false)
                topicStore.clearTopicList();
                if (error && error.data) message.error(t(error.data.message))
            })
            setDrawerHeight(window.innerHeight / 2)
        }
    }, [topicStore.isShownListTopic])

    useEffect(() => {
        setSelectedRowKeys([])
    }, [topicStore.topicList])

    const checkingFeatureRole = (type) => {
        if (!type) return false
        return adminStore.checkingFeatureRole(projectStore, type)
    }

    // event for touche split panel
    const handleTouchStart = e => {
        if (e.touches.length !== 1) return;
        if (e.cancelable) {
            e.preventDefault();
        }

        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newHeight = (document.body.offsetHeight ? document.body.offsetHeight : 0) - (touch.clientY - (target && target.offsetLeft ? target.offsetLeft : 0));
        let minDrawerHeight = 50;
        if (newHeight < 0) {
            setDrawerHeight(10);
        }
        if (newHeight > minDrawerHeight) {
            setDrawerHeight(newHeight);
        }
        if (newHeight > window.innerHeight) {
            setDrawerHeight(window.innerHeight);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    // event mouse for split panel
    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newHeight = document.body.offsetHeight - (e.clientY - document.body.offsetLeft);
        let minDrawerHeight = 50;
        if (newHeight > minDrawerHeight) {
            setDrawerHeight(newHeight);
        }
        if (newHeight > window.innerHeight) {
            setDrawerHeight(window.innerHeight);
        }
    }, []);

    const handleClickFullScreen = () => {
        if (drawerHeight === window.innerHeight) {
            setDrawerHeight(window.innerHeight / 2);
        } else {
            setDrawerHeight(window.innerHeight);
        }
    }


    const handleClickEditTopic = (topic) => {
        let name = encodeURIComponent(topic.name);
        history.push(`${location.pathname}?topic=${name}`)
    }

    const handleClickAddNewTopic = () => {
        topicStore.setShowTopicEditor(true, false);
    }

    const handleChangeFilterByTag = (tag, checked) => {
        const nextSelectedTags = checked ? (filterValue && filterValue.tags ? [...filterValue.tags, tag] : [tag]) : filterValue.tags.filter(t => t !== tag);
        setFilterValue({
            ...filterValue,
            tags: nextSelectedTags,
        })
    }

    const handleClickHeaderColumn = (event, value) => {
        event.stopPropagation();
        if (event.shiftKey) {
            console.debug("shift +click");
        }
    }

    const handleClickTopicType = (e, value) => {
        e.stopPropagation()
        let _topictype = value
        if (filterValue && filterValue.topictype && filterValue.topictype === value) {
            _topictype = ''
        }
        setFilterValue({
            ...filterValue,
            topictype: _topictype,
        })
    }

    const handleClickTopicStatus = (e, value) => {
        e.stopPropagation()
        let _topicstatus = value
        if (filterValue && filterValue.topicstatus && filterValue.topicstatus === value) {
            _topicstatus = ''
        }
        setFilterValue({
            ...filterValue,
            topicstatus: _topicstatus,
        })
    }

    const handleClickTopicPriority = (e, value) => {
        e.stopPropagation()
        let _topicpriority = value
        if (filterValue && filterValue.topicpriority && filterValue.topicpriority === value) {
            _topicpriority = ''
        }
        setFilterValue({
            ...filterValue,
            topicpriority: _topicpriority,
        })
    }

    useEffect(() => {
        if (filterValue) {
            let _filterParams = []
            Object.keys(filterValue).forEach(key => {
                if (filterValue[key]) {
                    _filterParams.push(`&FilterBy=${key}&FilterValue=${filterValue[key]}`)
                }
            })
            var _param = _filterParams.join('&');

            topicStore.setLoadingProgress(true)
            topicStore.getTopicList(projectStore.projectDetail.id, _param).then(() => {
                topicStore.setLoadingProgress(false)
            }).catch(error => {
                topicStore.setLoadingProgress(false)
                if (error.data) message.error(t(error.data.message))
            })
        }
    }, [filterValue])

    const onCloseTopiclist = () => {
        topicStore.setShownListTopic(false);
        // topicStore.clearTopicList();
        setFilterValue(null)
        setSelectedRowKeys([])
        setDrawerHeight(0)
    };

    //Filter FilterCompleteness
    const handleFilterCompleteness = (selectedKeys, confirm) => {
        confirm();
        if (selectedKeys.length > 0) {
            const _filterCompleted = selectedKeys.join(",")
            setFilterValue({
                ...filterValue,
                percentCompleted: _filterCompleted,
            })
        }
    };

    //Clear filter Completeness
    const handleResetFilterCompleteness = clearFilters => {
        clearFilters();
        setFilterValue((prevData) => {
            const newData = { ...prevData }
            delete newData["percentCompleted"]
            return newData;
        })
    };

    //Filter Assign to
    const handleFilterAssignTo = (selectedKeys, confirm) => {
        confirm();
        if (selectedKeys.length > 0) {
            setFilterValue({
                ...filterValue,
                assignTo: selectedKeys,
            })
        }
    };

    //Clear filter Assign to
    const handleResetFilterAssignTo = clearFilters => {
        clearFilters();
        setFilterValue((prevData) => {
            const newData = { ...prevData }
            delete newData["assignTo"]
            return newData;
        })
    };


    const columns = [
        {
            title: t('name'),
            key: "name",
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: record => {
                return (
                    <a onClick={() => handleClickEditTopic(record)}>{record.name}</a>
                )
            }
        },
        {
            title: t('created'),
            key: "createdAt",
            width: 150,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => moment(a?.cloneData?.createdAt ? a.cloneData.createdAt : a.createdAt).unix() - moment(b?.cloneData?.createdAt ? b.cloneData.createdAt : b.createdAt).unix(),
            render: record => {
                const time = record?.cloneData?.createdAt ? record.cloneData.createdAt : record.createdAt
                return (
                    <div>{moment(time).format('MMM DD, YYYY')}</div>
                )
            }
        },
        {
            title: t('modified'),
            key: "updatedAt",
            width: 150,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => moment(a?.cloneData?.updatedAt ? a.cloneData.updatedAt : a.updatedAt).unix() - moment(b?.cloneData?.updatedAt ? b.cloneData.updatedAt : b.updatedAt).unix(),
            render: record => {
                const time = record?.cloneData?.updatedAt ? record.cloneData.updatedAt : record.updatedAt
                return (
                    <div>{moment(time).format('MMM DD, YYYY')}</div>
                )
            }
        },
        {
            title: <Text>{t('assigned-to')}</Text>,
            key: 'topicphases',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b, sortOrder) => {
                let a_phaseactive = a.topicphases.find(x => x.isActive === true)
                let b_phaseactive = b.topicphases.find(x => x.isActive === true)
                a = a_phaseactive && a_phaseactive.assigntopersons.length > 0 ? a_phaseactive.assigntopersons[0].username : (sortOrder === 'descend' ? '' : 'z');
                b = b_phaseactive && b_phaseactive.assigntopersons.length > 0 ? b_phaseactive.assigntopersons[0].username : (sortOrder === 'descend' ? '' : 'z');
                return a.localeCompare(b);
            },
            filterIcon: filtered => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <div style={{ display: 'flex', marginBottom: 10 }}>
                        <Checkbox.Group
                            onChange={value => {
                                setSelectedKeys(value)
                            }}
                            value={selectedKeys}
                        >
                            {
                                projectTeamsStore.teamList.length > 0
                                    ? projectTeamsStore.teamList.map(item => {
                                        if (item.user) {
                                            return (
                                                <Checkbox
                                                    key={item.user?._id}
                                                    value={item.user?._id}
                                                >
                                                    {item.user?.username}
                                                </Checkbox>
                                            )
                                        }
                                    })
                                    : <Empty description={<span>{t('no-data')}</span>} />
                            }
                        </Checkbox.Group>
                    </div>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleFilterAssignTo(selectedKeys, confirm)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            {t('filter')}
                        </Button>
                        <Button onClick={() => { handleResetFilterAssignTo(clearFilters); setSelectedKeys([]); }} size="small" style={{ width: 90 }}>{t('commons.reset')}</Button>
                    </Space>
                </div>
            ),
            render: record => {
                return (
                    record.topicphases && record.topicphases.length > 0 ? (
                        <>
                            {record.topicphases.map(item => {
                                if (item.isActive) {
                                    return (
                                        item.assigntopersons.map(user => {
                                            return (<span key={user.id} className="pr-5">
                                                <Tooltip title={(user?.firstName && user?.lastName ?
                                                    user.firstName + ' ' + user.lastName : !user?.firstName && user?.lastName ?
                                                        user.lastName : user?.firstName && !user?.lastName ?
                                                            user.firstName : user.username) + " (" + user.email + ")"}
                                                    placement="right"
                                                    overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                    <div style={{ width: "100%" }}>
                                                        <div>
                                                            {
                                                                user?.firstName && user?.lastName ?
                                                                    user.firstName + ' ' + user.lastName : !user?.firstName && user?.lastName ?
                                                                        user.lastName : user?.firstName && !user?.lastName ?
                                                                            user.firstName : user.username
                                                            }
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </span>);
                                        })
                                    )
                                }
                            })}
                        </>
                    ) : (
                        <>
                            {record.assigntopersons && record.assigntopersons.map(item => {
                                return (<span key={item.id} className="pr-5">
                                    <Tooltip title={(item?.firstName && item?.lastName ?
                                        item.firstName + ' ' + item.lastName : !item?.firstName && item?.lastName ?
                                            item.lastName : item?.firstName && !item?.lastName ?
                                                item.firstName : item.username) + " (" + item.email + ")"}
                                        placement="right"
                                        overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                        <div style={{ width: "100%" }}>
                                            <div>
                                                {
                                                    item?.firstName && item?.lastName ?
                                                        item.firstName + ' ' + item.lastName : !item?.firstName && item?.lastName ?
                                                            item.lastName : item?.firstName && !item?.lastName ?
                                                                item.firstName : item.username
                                                }
                                            </div>
                                        </div>
                                    </Tooltip>
                                </span>);

                            })}
                        </>
                    )

                )
            }
        },
        {
            title: t('type'),
            key: "topictype",
            width: 110,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b, sortOrder) => {
                a = a.topictype || (sortOrder === 'descend' ? '' : 'z');
                b = b.topictype || (sortOrder === 'descend' ? '' : 'z');
                return a.localeCompare(b);
            },
            render: record => {
                return (
                    <Text className={`${filterValue && filterValue.topictype && filterValue.topictype === record.topictype ? 'selected' : ''}`} onClick={e => handleClickTopicType(e, record.topictype)}>{record.topictype}</Text>
                )
            }
        },
        {
            title: t('status'),
            key: "topicstatus",
            width: 110,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b, sortOrder) => {
                a = a.topicstatus || (sortOrder === 'descend' ? '' : 'z');
                b = b.topicstatus || (sortOrder === 'descend' ? '' : 'z');
                return a.localeCompare(b);
            },
            render: record => {
                return (
                    <Text className={`${filterValue && filterValue.topicstatus && filterValue.topicstatus === record.topicstatus ? 'selected' : ''}`} onClick={e => handleClickTopicStatus(e, record.topicstatus)}>{record.topicstatus}</Text>
                )
            }
        },
        {
            title: t('priority'),
            key: "topicpriority",
            width: 110,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b, sortOrder) => {
                a = a.topicpriority || (sortOrder === 'descend' ? '' : 'z');
                b = b.topicpriority || (sortOrder === 'descend' ? '' : 'z');
                return a.localeCompare(b);
            },
            render: record => {
                return (
                    <Text className={`${filterValue && filterValue.topicpriority && filterValue.topicpriority === record.topicpriority ? 'selected' : ''}`} onClick={e => handleClickTopicPriority(e, record.topicpriority)}>{record.topicpriority}</Text>
                )
            }
        },
        {
            title: t('tags'),
            key: 'Tags',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b, sortOrder) => {
                a = a.topictags.length > 0 ? a.topictags[0].name : (sortOrder === 'descend' ? '' : 'z');
                b = b.topictags.length > 0 ? b.topictags[0].name : (sortOrder === 'descend' ? '' : 'z');
                return a.localeCompare(b);
            },
            render: record => {
                return (
                    <>
                        {record.topictags && record.topictags.map(tag => {
                            return (
                                <CheckableTag
                                    key={tag.id}
                                    checked={filterValue && filterValue.tags && filterValue.tags.indexOf(tag.id) > -1}
                                    onChange={checked => handleChangeFilterByTag(tag.id, checked)}
                                >
                                    {tag.name}
                                </CheckableTag>
                            );
                        })}
                    </>
                )
            }
        },
        {
            title: t('completeness'),
            key: "percentCompleted",
            align: 'center',
            width: 170,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                a = Number(a.percentCompleted) || 0;
                b = Number(b.percentCompleted) || 0;
                return a - b;
            },
            filterIcon: filtered => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            filterDropdown: ({ setSelectedKeys, selectedKeys = ['=', 0], confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <div style={{ display: 'flex' }}>
                        <Select className='selectdrp' style={{ width: 60 }}
                            onChange={value => {
                                setSelectedKeys([value, selectedKeys[1] ? selectedKeys[1] : 0])
                            }}
                            defaultValue='='
                            value={selectedKeys[0] ? selectedKeys[0] : '='}
                        >
                            <Option value="=">{'='}</Option>
                            <Option value=">=">{'>='}</Option>
                            <Option value="<=">{'<='}</Option>
                        </Select>
                        <InputNumber
                            placeholder={`% Done`}
                            value={selectedKeys[1]}
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                            onChange={value => {
                                if (Number(value)) {
                                    setSelectedKeys([selectedKeys[0] ? selectedKeys[0] : '=', value])
                                }
                            }}
                            style={{ width: 120, marginLeft: 7, marginBottom: 8, display: 'block' }}
                        />
                    </div>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleFilterCompleteness(selectedKeys, confirm)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            {t('filter')}
                        </Button>
                        <Button onClick={() => handleResetFilterCompleteness(clearFilters)} size="small" style={{ width: 90 }}>{t('commons.reset')}</Button>
                    </Space>
                </div>
            ),
            render: record => { return record.percentCompleted ? (Number((record.percentCompleted).toFixed(0)) + '%') : '0%' }
        }
    ];

    const handleClicDeleteTopic = (e) => {
        const startTime = Date.now();
        e.preventDefault();
        topicStore.setLoadingProgress(true)
        let _arrTopicId = {
            arrTopicId: selectedRowKeys
        }
        topicStore.deleteMultiTopic(_arrTopicId).then(async (res) => {
            selectedRowKeys.filter(function (ra) {
                topicStore.topicList = topicStore.topicList.filter(function (sa) {
                    return sa.id !== ra;
                });
            });
            await topicStore.getLocationTopicsByProject(projectId)
            message.success(t('topic-deleted-successfully'))
            topicStore.setLoadingProgress(false)
            setSelectedRowKeys([])
            commonStore.loggingFunction('delete topic','success',startTime,usersStore.currentUser  ,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
        }).catch(error => {
            topicStore.setLoadingProgress(false)
            message.error(t('something-went-wrong'))
            commonStore.loggingFunction('delete topic','failed',startTime,usersStore.currentUser ,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name )
        })
    }

    const handleSelect = (record, selected) => {
        if (selected) {
            setSelectedRowKeys((keys) => [...keys, record.id]);
        } else {
            setSelectedRowKeys((keys) => {
                const index = keys.indexOf(record.id);
                return [...keys.slice(0, index), ...keys.slice(index + 1)];
            });
        }
    };

    const toggleSelectAll = () => {
        setSelectedRowKeys((keys) =>
            keys.length === topicStore.topicList.length ? [] : topicStore.topicList.map((r) => r.id)
        );
    };

    const headerCheckbox = (
        <>
            <Checkbox
                checked={selectedRowKeys.length}
                indeterminate={
                    selectedRowKeys.length > 0 && selectedRowKeys.length < topicStore.topicList.length
                }
                onChange={toggleSelectAll}
            />
            {/* {selectedRowKeys.length > 0 ? (
                <Popconfirm
                    title={`Are you sure you want to delete ${selectedRowKeys.length} topics?`}
                    onConfirm={(e) => handleClicDeleteTopic(e)}
                    okText={t('commons.yes')}
                    cancelText={t('commons.no')}
                >
                    <a href="#" className="css-link-delete">Delete</a>
                </Popconfirm>
            ) : ''} */}
        </>
    );

    const rowSelection = {
        selectedRowKeys,
        type: "checkbox",
        fixed: true,
        columnWidth: 80,
        onSelect: handleSelect,
        columnTitle: headerCheckbox
    };

    const CloseIcon = () => {
        return (
          <Row gutter={[8]}>
            <Col className="">
              {drawerHeight === window.innerHeight ? (
                <Tooltip title={(isMobile || isTablet) ? '' : t('out-full-screen')} placement="bottom">
                  <div>
                    <FullscreenExitOutlined
                      onClick={() => handleClickFullScreen()}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={(isMobile || isTablet) ? '' : t('full-screen')} placement="bottom">
                  <FullscreenOutlined onClick={() => handleClickFullScreen()} />
                </Tooltip>
              )}
            </Col>
            <Col>
              <Tooltip title={(isMobile || isTablet) ? '' : t('commons.close')} placement="bottom">
                <div>
                  <CloseOutlined onClick={() => onCloseTopiclist()} />
                </div>
              </Tooltip>
            </Col>
          </Row>
        )
      }
      
    return (
        <Drawer
            title={
                <div className="help-btn-wrapper">
                    {t('topics')}
                    <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={'topic_list'} hovered={'black'}/>
                        </div>
                    </Tooltip>
                </div>
            }
            closeIcon={<CloseIcon />}
            placement="bottom"
            height={drawerHeight}
            closable={true}
            visible={topicStore.isShownListTopic}
            destroyOnClose={true}
            className="drawerTopicList custom-wraper-splitPanel"
            mask={false}
        >
            <TopicPageWrapper>
                {topicStore.isShownListTopic && isTabletOrMobileDevice ? (
                    <div className="splitpanel-horizontal-mobile">
                        <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                            <ColumnHeightOutlined />
                        </Button>
                    </div>
                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-horizontal" />)
                }
                <TopicPageHeading>
                    {
                        (checkingFeatureRole("feature_topic_list_edit") && checkingFeatureRole("feature_add_topic")) && projectStore.isExistLicenses && (
                            <Button htmlType={'submit'} onClick={handleClickAddNewTopic} type="primary">{t('commons.new')}</Button>
                        )
                    }
                    {/* <Button type="primary">
                        {isTabletOrMobileDevice ? (
                            <Icon type="import" />
                        ) : (
                                <>Import BCF</>
                            )}
                    </Button>
                    <Button type="primary">
                        {isTabletOrMobileDevice ? (
                            <Icon type="export" />
                        ) : (
                                <>Export BCF</>
                            )}
                    </Button> */}
                    {/* <Button className="filterbutton" type="primary">
                        {isTabletOrMobileDevice ? (
                            <Icon type="filter" />
                        ) : (
                                <>Advanced filtering</>
                            )}
                    </Button> */}
                    {
                        checkingFeatureRole("feature_topic_list_edit") && (
                            <Popconfirm
                                placement="topRight"
                                title={t('are-you-sure-you-want-to-delete-topics', { topiclength: selectedRowKeys.length })}
                                onConfirm={(e) => handleClicDeleteTopic(e)}
                                okText={t('commons.yes')}
                                cancelText={t('commons.no')}
                            >
                                <Button className="filterbutton" type="primary" disabled={selectedRowKeys.length === 0}>
                                    {isTabletOrMobileDevice ? (
                                        <FilterOutlined />
                                    ) : (
                                        <>{t('commons.delete')}</>
                                    )}
                                </Button>
                            </Popconfirm>
                        )
                    }

                </TopicPageHeading>
                <Table
                    rowSelection={rowSelection}
                    loading={topicStore.isLoading}
                    bordered
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={topicStore.topicList}
                    scroll={{ x: 1200, y: drawerHeight - 250 }}
                    pagination={{ pageSize: 50, locale: { items_per_page: "" } }}
                    locale={{ emptyText: t('no-data') }}
                />
            </TopicPageWrapper>
        </Drawer >
    )
}

export default inject(
    'topicStore',
    'projectStore',
    'adminStore',
    'projectTeamsStore',
    'commonStore',
    'usersStore'
)(observer(TopicListDrawer));