import {
    BasicConfig,
    BasicFuncs,
} from "react-query-builder-antd-input";
import AntdConfig from "react-query-builder-antd-input/lib/config/antd";

const skinToConfig = {
    vanilla: BasicConfig,
    antd: AntdConfig,
};

const getConfig = (skin) => {
    const InitialConfig = skinToConfig[skin];

    const conjunctions = {
        ...InitialConfig.conjunctions,
        NOR: {
            label: "Nor",
            mongoConj: "$nor",
            jsonLogicConj: "nor",
            sqlConj: "NOR",
            spelConj: "nor",
        },
        XOR: {
            label: "Xor",
            mongoConj: "$xor",
            jsonLogicConj: "xor",
            sqlConj: "XOR",
            spelConj: "xor",
        },
    };
    const operators = { ...InitialConfig.operators };

    const widgets = {
        ...InitialConfig.widgets,
        text: {
            ...InitialConfig.widgets.text,
        },
        textarea: {
            ...InitialConfig.widgets.textarea,
            maxRows: 3,
        },
    };

    const types = {
        ...InitialConfig.types,
        text: {
            ...InitialConfig.types.text,
            excludeOperators: ["proximity"],
        },
    };

    const localeSettings = {
        valueLabel: "Value",
        valuePlaceholder: "Value",
        fieldLabel: "Field",
        operatorLabel: "Operator",
        funcLabel: "Function",
        fieldPlaceholder: "Attribute",
        funcPlaceholder: "Select function",
        operatorPlaceholder: "Select operator",
        lockLabel: "Lock",
        lockedLabel: "Locked",
        deleteLabel: null,
        addGroupLabel: "Add group",
        addRuleLabel: "Add rule",
        addSubRuleLabel: "Add sub rule",
        delGroupLabel: null,
        notLabel: "Not",
        valueSourcesPopupTitle: "Select value source",
        removeRuleConfirmOptions: {
            title: "Are you sure delete this rule?",
            okText: "Yes",
            okType: "danger",
            cancelText: "Cancel",
        },
        removeGroupConfirmOptions: {
            title: "Are you sure delete this group?",
            okText: "Yes",
            okType: "danger",
            cancelText: "Cancel",
        },
    };

    const settings = {
        ...InitialConfig.settings,
        ...localeSettings,
        defaultSliderWidth: "200px",
        defaultSelectWidth: "200px",
        defaultSearchWidth: "100px",
        defaultMaxRows: 100,
        valueSourcesInfo: {
            value: {
                label: "Value",
            },
            field: {
                label: "Field",
                widget: "field",
            },
        },
        maxNesting: 100,
        canLeaveEmptyGroup: true,
        shouldCreateEmptyGroup: false,
        showErrorMessage: true,
        customFieldSelectProps: {
            showSearch: true,
        },
    };

    const fields = {};
    const funcs = { ...BasicFuncs };

    const config = {
        conjunctions,
        operators,
        widgets,
        types,
        settings,
        fields,
        funcs,
    };

    return config;
};

export default getConfig;
