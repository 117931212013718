import { Modal } from 'antd';
const { confirm } = Modal;

const confirmDelete = (title, text, ok, cancel) => {
    return (
        confirm({
            title: title,
            content: text,
            async onOk() {
                ok();
            },
            onCancel() {
                cancel()
            },
        })
    )
};

export default confirmDelete;

