import { observable, action, decorate, toJS } from 'mobx'
import GroupUtils from '../group-utils'
import { ProjectUserGroupRequest,ProjectUserGroupMemberRequest } from '../requests'

class UserGroupStore {
    isLoading = false
    groups = []
    members = []
    showEditAccessControlModal= false
    isAppLoading = false
    showAddNewGroupToTreeData = false
    nodeEdit = false
    selectedGroup = null
    listUserNotGroup = []
    nodeNoAccessRights = []
    rightsOptions = [
      {
        value: 'modify',
        label: 'Modify',
      },
      {
        value: 'view',
        label: 'View',
      },
      {
        value: 'none',
        label: 'None',
      }
    ]

    processedUserGroups= []

  checkCurrentUserGroup = (user, groups) => {
    let projectMembers = GroupUtils.getMemberGroups(groups)
    let isExist = projectMembers.filter(c => c.email === user.email && c.user === user._id)
    return isExist ?
      isExist.map(u => {
        let v = toJS(groups.find(c => c.id === u.group))
        return v;
      })
      : false
  }

   getMostPowerfulGroup = (rightsPriority,rules) => {
    let mostPowerfulGroup = rules[0];
  
    rules.forEach(rule => {
      if (rightsPriority[rule.rights] > rightsPriority[mostPowerfulGroup.rights]) {
        mostPowerfulGroup = rule;
      }
    });
  
    return mostPowerfulGroup;
  }

  findMostPowerfulGroup = (element = [], groups = []) => {
    let rightsPriority = {
      'none': 0,
      'view': 1,
      'modify': 2
    };
    let avaiable = []
    element.map(elm => {
      let isExist = groups.find(c => c.id === elm.id)
      if (!isExist) return;
      avaiable.push(isExist)
    })
    return avaiable?.length > 0 ? this.getMostPowerfulGroup(rightsPriority, avaiable) : undefined
  }

    setProcessedUserGroups = data => {
      this.processedUserGroups = data
    }

    setNodeNoAccessRights = data => {
      this.nodeNoAccessRights = data
    }

    setSelectedGroup = data => {
      this.selectedGroup = data
    }

    setTreeNodeEdit = data => {
      this.nodeEdit = data
    }

    setModalLoading = state => {
      this.isAppLoading = state
    }

    setEditAccessControl = state => {
      this.showEditAccessControlModal = state
    }

    setShowAddNewGroupToTreeData = state => {
      this.showAddNewGroupToTreeData = state
    }

    setLoadingProgress = state => {
        this.isLoading = state
    }

    setProjectGroups = data => {
        this.groups = data
    }

    setProjectMemberGroups = data => {
        this.members = data
    }

    /**
     * get all usergroup in project
     * @param {*}
     * @returns 
     */
    async getAllProjectUserGroup(projectId) {
        return new Promise((resolve, reject) => {
          ProjectUserGroupRequest.getAll(projectId).then((response) => {
            this.groups = response.data
            resolve(response.data);
          }).catch(err => {
            console.log(err)
            reject(err)
          })
        })
      }

      /**
     * create new usergroup in project
     * @param {*} data {name: name}
     * @returns  
     */
    async createProjectUserGroup(data) {
        return new Promise((resolve, reject) => {
          ProjectUserGroupRequest.create(data).then((response) => {
            this.groups = [...this.groups, response.data]
            resolve(response.data);
          }).catch(err => {
            console.log(err)
            reject(err)
          })
        })
      }

      async createGroupDefault(projectId) {
        return new Promise((resolve, reject) => {
          ProjectUserGroupRequest.createGroupDefault(projectId).then((response) => {
            this.groups = [...response.data]
            resolve(response.data);
          }).catch(err => {
            console.log(err)
            reject(err)
          })
        })
      }

      async getProjectUserGroupById(id) {
        return new Promise((resolve, reject) => {
          ProjectUserGroupRequest.getById(id).then((response) => {
            resolve(response.data);
          }).catch(err => {
            console.log(err)
            reject(err)
          })
        })
      }
    
      async updateProjectUserGroup(id, body) {
        return new Promise((resolve, reject) => {
          ProjectUserGroupRequest.update(id, body).then((response) => {
            if(this.groups?.length){
              this.groups = this.groups.map(el => el?.id === id ? response.data : el)
            }
            resolve(response.data);
          }).catch(err => {
            console.log(err)
            reject(err)
          })
        })
      }
    
      async deleteProjectUserGroup(id) {
        return new Promise((resolve, reject) => {
          ProjectUserGroupRequest.delete(id).then((response) => {
            if(this.groups?.length){
              this.groups = this.groups.filter(el => el.id !== id)
            }
            resolve(response.data);
          }).catch(err => {
            console.log(err)
            reject(err)
          })
        })
      }


      /**
     * get all usergroupmember in project
     * @param {*}
     * @returns 
     */
    async getAllProjectUserGroupMember() {
      return new Promise((resolve, reject) => {
        ProjectUserGroupMemberRequest.getAll().then((response) => {
          this.members = response.data
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }

      /**
     * get all usergroupmember in project
     * @param {*}
     * @returns 
     */
    async getAllMemberInGroup(groupId) {
      return new Promise((resolve, reject) => {
        ProjectUserGroupMemberRequest.getAllMemberInGroup(groupId).then((response) => {
          this.members = response.data
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }

    async getListUserNotGroup(projectId) {
      return new Promise((resolve, reject) => {
        ProjectUserGroupMemberRequest.getListUserNotGroup(projectId).then((response) => {
          this.listUserNotGroup = response.data
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }

    /**
   * create new usergroupmember in project
   * @param {*} data {name: name}
   * @returns  
   */
  async createProjectUserGroupMember(data) {
      return new Promise((resolve, reject) => {
        ProjectUserGroupMemberRequest.create(data).then((response) => {
          if(this.selectedGroup?.id && !response.data?.error){
            this.members = [...this.members, response.data]
          }
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }

    async getProjectUserGroupMemberById(id) {
      return new Promise((resolve, reject) => {
        ProjectUserGroupMemberRequest.getById(id).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }

    async getAllGroupByUserId(userId) {
      return new Promise((resolve, reject) => {
        ProjectUserGroupMemberRequest.getAllGroupByUserId(userId).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }
  
    async updateProjectUserGroupMember(id, body) {
      return new Promise((resolve, reject) => {
        ProjectUserGroupMemberRequest.update(id, body).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }
  
    async deleteProjectUserGroupMember(id) {
      return new Promise((resolve, reject) => {
        ProjectUserGroupMemberRequest.delete(id).then((response) => {
          if(this.selectedGroup?.id){
            this.members = this.members?.filter(el => el?.id !== id)
          }
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }

}

decorate(UserGroupStore, {
    isLoading: observable,
    setLoadingProgress: action,
    setProjectGroups : action,
    setProjectMemberGroups: action,
    getAllProjectUserGroup: action,
    getProjectUserGroupById: action,
    createProjectUserGroup: action,
    updateProjectUserGroup: action,
    deleteProjectUserGroup: action,

    getAllProjectUserGroupMember: action,
    createProjectUserGroupMember: action,
    getProjectUserGroupMemberById: action,
    updateProjectUserGroupMember: action,
    deleteProjectUserGroupMember: action,
    groups: observable,
    members: observable,
    setEditAccessControl : action,
    showEditAccessControlModal : observable,
    setModalLoading : action,
    isAppLoading : observable,
    nodeEdit : observable,
    setTreeNodeEdit :action,
    setShowAddNewGroupToTreeData :action,
    showAddNewGroupToTreeData :observable,
    selectedGroup: observable,
    setSelectedGroup: action,
    getAllGroupByUserId: action,
    getListUserNotGroup: action,
    listUserNotGroup: observable,
    createGroupDefault: action,
    getAllMemberInGroup: action,
    setNodeNoAccessRights : action,
    nodeNoAccessRights : observable,
    checkCurrentUserGroup : action,
    findMostPowerfulGroup : action,
    rightsOptions : observable,
    setProcessedUserGroups : action,
    processedUserGroups :observable
})

export default new UserGroupStore()
