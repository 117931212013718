import React, { useEffect, useState, useCallback, useRef } from "react";
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Button } from 'antd';
import {
    Query,
    Builder,
    Utils
} from "react-query-builder-antd-input";
import throttle from "lodash/throttle";
import loadConfig from "./config";
import "react-query-builder-antd-input/lib/css/styles.css";
import { useTranslation } from "react-i18next";
const { getTree, loadTree } = Utils;
const initialSkin = window._initialSkin || "antd";
const loadedConfig = loadConfig(initialSkin);
let initValue = {};
let initTree = loadTree({});

const updateEvent = new CustomEvent("update", {
    detail: {
        config: loadedConfig,
        _initTree: initTree,
        _initValue: initValue,
    },
});
window.dispatchEvent(updateEvent);

const QueryBuilder = ({ selectedQuery, setTreeJs, treeInit, setSrcQuery, projectStore, projectId, treeJS }) => {
    const {t} = useTranslation()
    const memo = useRef({});

    const [state, setState] = useState({
        tree: initTree,
        config: loadedConfig,
        skin: initialSkin,
        spelStr: "",
        spelErrors: []
    });
    useEffect(() => {
        if (Object.keys(treeInit).length > 0) {
            setState({
                ...state,
                tree: loadTree(treeInit),
            });
        } else {
            clearValue()
        }
    }, [treeInit])
    useEffect(() => {
        window.addEventListener("update", onConfigChanged);
        return () => {
            window.removeEventListener("update", onConfigChanged);
        };
    }, []);

    const onConfigChanged = (e) => {
        const {
            detail: { config },
        } = e;
        setState({
            ...state,
            config,
        });
    };

    const resetValue = () => {
        if (Object.keys(treeInit).length > 0) {
            setState({
                ...state,
                tree: loadTree(treeInit),
            });
        } else {
            clearValue()
        }
    };

    const clearValue = () => {
        setState({
            ...state,
            tree: loadTree({}),
        });
    };
    const searchObject = async value => {
        const data = await projectStore.searchObjectInfor(projectId, value)
                .then(res => res).catch(() => [])
        return data
    };
    const renderBuilder = useCallback((bprops) => {
        memo.current._actions = bprops.actions;
        return (
            <div className="query-builder-container">
                <div className="query-builder qb-lite">
                    <Builder {...bprops} searchObject={searchObject} treeProject={toJS(projectStore.projectDetail.treeData) || []}/>
                </div>
            </div>
        );
    }, []);

    const onChange = useCallback(
        (immutableTree, config, actionMeta) => {
            if (actionMeta) {
                if (actionMeta.type === 'SET_FIELD' || actionMeta.type === 'SET_OPERATOR' || actionMeta.type === 'SET_VALUE') {
                    setSrcQuery(false)
                }
            };
            memo.current.immutableTree = immutableTree;
            memo.current.config = config;
            updateResult();
        },
        [],
    );

    const updateResult = throttle(() => {
        setState((prevState) => ({
            ...prevState,
            tree: memo.current.immutableTree,
            config: memo.current.config,
        }));
    }, 100);
    useEffect(() => {
        const tree = getTree(state.tree);
        if (JSON.stringify(treeJS) !== JSON.stringify(tree)) {
            setTreeJs(tree)
        }
    }, [state.tree])

    return (
        <div>
            {
                selectedQuery ? <div>
                    <Button className="button-tool"
                        type="primary" onClick={resetValue}>{t('reset-tree')}</Button>
                </div> : null
            }

            <Query
                {...state.config}
                value={state.tree}
                onChange={onChange}
                renderBuilder={renderBuilder}
            />
        </div>
    );
};
export default inject(
    'projectStore',
)(observer(QueryBuilder))
