import styled from 'styled-components'
import { Layout} from 'antd'
const { Sider } = Layout
export const SiderWrapper = styled(Sider)`
    background: #fff;
    min-height: 100vh;
    position: fixed !important;
    border-right: 1px solid rgb(221, 221, 221);
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;

    .ant-menu-inline{
        border: none;
    }

   .ant-layout-sider-trigger {
      top: 7rem;
      right: -13px;
      border-radius: 50%;
      position: absolute;
      width: 25px;
      height: 25px;
      line-height: 25px;
      background-color: #d3dbe3;
  }
  
 .ant-layout-sider-trigger:hover {
      background-color: #ff8d4f;
  }
  
.ant-layout-sider-has-trigger {
      padding-bottom: 0;
  }
`
export const HeaderSider = styled.div`
  display: flex;
  justify-content: ${props => props.collapsed? 'center':'space-between'};
`