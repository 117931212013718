import React, { useEffect, useState, useRef, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, InputNumber, Row, Col, Modal, Switch, Input } from 'antd'
import uuid from 'uuid'
import { func } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next';

const AddCalibrationModal = ({ arStore, viewer }) => {
  const { t } = useTranslation();
  const defaultOcv = {
    hessianThreshold: 400,
    nOctaves: 4,
    nOctaveLayers: 4,
    extended: 0,
    upright: 1,
    ratio_thresh: 0.8,
    angle_filter: 0,
    distance_filter: 80,
    pixel_size: 16,
    key_rotation: 80,
    pixel_diff: 50,
    fov: 60,
    fovy: 33.5,
    image_filter: 4
  }

  const defaultCName = 'Calibration ' + uuid()
  const [PresetName, setPresetName] = useState(defaultCName)
  const [Ocv, setOcv] = useState(defaultOcv)

  useEffect(() => {
    updateName(Ocv)
  }, [])

  function updateName(x) {
    let newName = `PS${x.pixel_size}`
    if (x.angle_filter !== 0) newName += `-AS${x.angle_filter}`
    if (x.distance_filter !== 0) newName += `-DS${x.distance_filter}`
    if (x.pixel_diff !== 0) newName += `-PD${x.pixel_diff}`
    newName += `-TS${x.ratio_thresh}`
    if (x.hessianThreshold !== 400) newName += `-H${x.hessianThreshold}`
    if (x.nOctaves !== 16) newName += `-O${x.nOctaves}`
    if (x.nOctaveLayers !== 3) newName += `-OL${x.nOctaveLayers}`
    if (x.key_rotation !== 0) newName += `-KPR${x.key_rotation}`

    // newName = `PS${x.pixel_size}-A${x.angle_filter}-D${x.distance_filter}-PD${x.pixel_diff}-T${x.ratio_thresh}-H${x.hessianThreshold}-O${x.nOctaves}-OL${x.nOctaveLayers}-KPR${x.key_rotation}`
    setPresetName(newName)
  }

  function updateKey(data) {
    // setOcv(Object.assign(Ocv, data))
    var x = Object.assign(Ocv, data)
    setOcv(x)
    updateName(x)
  }

  const onCancel = () => {
    arStore.setOpenCVModel(false)
    arStore.setOpenCV(defaultOcv)
    setOcv(defaultOcv)
  }

  const onCreate = () => {
    arStore.setOpenCVModel(false)
    var newItem = arStore.createCalibration({
      name: PresetName,
      OpenCV: Ocv,
      data: defaultOcv.data
    }).then(() => {
      arStore.setOpenCVModel(false)
    })
    // arStore.setOpenCV(defaultOcv)
    // setOcv(defaultOcv)
  }

  return (
    <Modal
      visible={arStore.openCVModal}
      title={t('create-a-new-calibration-preset')}
      okText={t('commons.create')}
      cancelText={t('commons.cancel')}
      onCancel={onCancel}
      onOk={onCreate}
      footer={
        <Fragment>
          <Button key="back" onClick={onCancel}>
          {t('commons.cancel')}
        </Button>
          <Button
            key="submit"
            type="primary"
            loading={arStore.isLoading}
            onClick={onCreate}>
            {t('create-preset')}
        </Button>
        </Fragment>
      }>
      <div id="opencv2-control">
        {t('name-param-preset')}:
      <br />
        <Input
          size="small"
          placeholder={t('enter-name-of-param-preset')}
          value={PresetName}
          onChange={(e) => {
            const { value } = e.target;
            setPresetName(value)
          }}
        />
        <br />
        <br />

        <Row gutter={16}>
          <Col className="gutter-row" span={12}>

            {t('hessian-threshold')}:
      <br />
            <InputNumber
              size="small"
              placeholder={t('threshold-for-hessian-keypoint-detector-used-in-surf')}
              defaultValue={Ocv.hessianThreshold}
              onChange={(v) => {
                updateKey({ hessianThreshold: v })
              }}
            />
            <br />
      {t('octaves')}:
      <br />
            <InputNumber
              size="small"
              placeholder={t('number-of-pyramid-octaves-the-keypoint-detector-will-use')}
              defaultValue={Ocv.nOctaves}
              onChange={(v) => {
                updateKey({ nOctaves: v })
              }}
            />
            <br />
      {t('octave-layers')}:
      <br />
            <InputNumber
              size="small"
              placeholder={t('number-of-octave-layers-within-each-octave')}
              defaultValue={Ocv.nOctaveLayers}
              onChange={(v) => {
                updateKey({ nOctaveLayers: v })
              }}
            />
            <br />
            <Switch
              checkedChildren={t('extended')} unCheckedChildren={t('no-extended')}
              // size="small"
              defaultChecked={Ocv.extended}
              onChange={(v) => {
                let ret = v ? 1 : 0
                updateKey({ extended: ret })
              }}
            />
            <br />
            <Switch
              checkedChildren={t('upright')} unCheckedChildren={t('no-upright')}
              // size="small"
              defaultChecked={Ocv.upright}
              onChange={(v) => {
                let ret = v ? 1 : 0
                updateKey({ upright: ret })
              }}
            />
            <br />
      {t('ratio-thresh')}:
      <br />
            <InputNumber
              size="small"
              defaultValue={Ocv.ratio_thresh}
              onChange={(v) => {
                updateKey({ ratio_thresh: v })
              }}

            />
            <br />
          </Col>
          <Col className="gutter-row" span={12}>
            {t('angle-filter')} (%):
            <br />
            <InputNumber
              size="small"
              defaultValue={Ocv.angle_filter}
              onChange={(v) => {
                updateKey({ angle_filter: v })
              }}

            />
            <br />
            {t('distance-filter')} (%):
            <br />
            <InputNumber
              size="small"
              defaultValue={Ocv.distance_filter}
              onChange={(v) => {
                updateKey({ distance_filter: v })
              }}
            />
            <br />
            {t('keypoint-minimum-size')}:
            <br />
            <InputNumber
              size="small"
              placeholder={t('pixel-size')}
              defaultValue={Ocv.pixel_size}
              onChange={(v) => {
                updateKey({ pixel_size: v })
              }}
            />
            <br />
            {t('keypoint-pair-rotation-accuracy')}:
            <br />
            <InputNumber
              size="small"
              placeholder={t('pair-rotation')}
              defaultValue={Ocv.key_rotation}
              onChange={(v) => {
                updateKey({ key_rotation: v })
              }}
            />
            <br />
            {t('pixel-diff')}:
            <br />
            <InputNumber
              size="small"
              placeholder={t('pixel-diff')}
              defaultValue={Ocv.pixel_diff}
              onChange={(v) => {
                updateKey({ pixel_diff: v })
              }}
            />
            <br />
          </Col>
        </Row>
      </div>
    </Modal>

  )
}
export default inject('arStore')(observer(AddCalibrationModal))
