
import styled from 'styled-components'
import { Avatar, Button } from 'antd';

export const SettingRenderResolutionContainer = styled.div`
  background-color: rgba(222, 222, 222, 0.75);
  padding: 10px;
  width: 320px;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 2px;
  @media screen and (max-width: 768px) {
    padding: 8px;
  }
  .ant-slider {
    margin: 8px 6px 10px;
  }
  .ant-btn-icon-only {
    margin-left: 5px;
}
`
export const SettingControlContainer = styled.div`
  background-color: rgba(222, 222, 222, 0.75);
  padding: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 2px;
  // overflow: auto;
  height: 99%;
  @media screen and (max-width: 768px) {
    padding: 8px;
  }
  .ant-btn-icon-only {
    margin-left: 5px;
  }
  .ant-cascader-picker,
  .ant-btn-icon-only {
    margin-bottom: 10px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number {
    width: 105px
  }
  .hw {
    width: 137px;
  }
  .hw-full {
    width: 100%;
  }
  .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.5);
    background-color: #dddddd;
  }
  .ant-form-item{
    margin-bottom : 5px;
  }
  .ant-btn-icon-only{
    margin-bottom : 5px;
  }
  .ant-form-item-label{
    padding: 0 !important;
  }
  .inlinelayout {
    display: flex !important;
    flex-flow: row wrap !important;

    label{
      height: 32px !important;
   }
  }
  .fogsetting .ant-form-item-label > label {   
    min-width: 100px;
  }
  .fogsetting .ant-input-number-handler-wrap {
    display: block;
  }
`
export const RecordDataControlStyle = styled.div`
  background-color: rgba(222, 222, 222, 0.75);
  padding: 10px;
  position: absolute;
  top: 45px;
  left: 45px;
  border-radius: 2px;
  @media screen and (max-width: 768px) {
    padding: 8px;
  }
`
export const ClipPlaneItem = styled.span`
  margin: 0px 1px;
  .ant-btn {
    padding : 1px 0 ;
  }
`

export const AvatarCustom = styled(Avatar)`
  border-radius: 5px !important;
  margin-right: 5px !important;
  cursor: pointer; 
  background: #fff !important;
  line-height: inherit !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  &:not(:first-child) {
    margin-left: 0px !important;
  }
  &:hover, &:focus {     
      border: 1px solid #ff8d4f !important;
  }
  color: ${props => props.activated === 1 ? '#ff8d4f' : '#191919'} !important;
  transition: ease .3s;
  path {
    fill: ${props => props.activated === 1 ? '#ff8d4f' : ''} !important;
    transition: ease .3s;
  }
`
// Follow Alignment Tool
export const SliderContainer = styled.div`
    position: fixed;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    z-index: 9998;    
    padding: 40px;    
    .close {
      position: absolute;
      top: 5px;
      right: 5px;
    }
`

export const CenteredContent = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  vertical-align: middle;
`

export const CenteredContentBigIcon = styled(CenteredContent)`
  font-size: 32px;
  > .anticon {
    color: white;
    &:hover {
      cursor: pointer;
      color: #1da57a;
    }
  }
`

export const ButtonCustom = styled(Button)`
  padding: 0 !important;
  margin: 0;
  border-radius: 5px !important;
`
export const MeasurementItem = styled.span`
  margin: 0px 1px;
  .ant-btn {
    padding : 1px 0 ;
  }
`