import { inject, observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useParams } from 'react-router'
import LoadingSpinner from '../../elements/LoadingSpinner'
import {
  PasswordPageWrapper, FormSubtitle, FormTitle, FormWrapper
} from './CustomStyled'
import ForgotPassword from './ForgotPassword'
import { message } from 'antd'
import { Trans, useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text'
import { Link } from 'react-router-dom'
import { toJS } from 'mobx'

const PasswordPage = ({ authStore, commonStore, usersStore, history }) => {
  const { t } = useTranslation();
  const { tokenid, passwordType } = useParams()
  const [isValid, setIsValid] = useState(null)

  useEffect(() => {
    commonStore.setCurrentPage('forgot-password')
    if (tokenid) {
      if (passwordType === 'forgot-password') {
        usersStore.getPublicValidToken(tokenid, history).then((res) => {
          if (res.status === 200) {
            let _validUntil = new Date(res.data.validUntil);
            let today = new Date()
            if (((today > _validUntil) || res.data.activatedDate)) {
              message.error(t('token-is-not-valid-or-has-expired'))
              history.push(`/`)
            } else {
              if (res?.data?.issueType === 'ForgotPassword') {
                setIsValid(true)
              }
            }
          } else {
            history.push(`/`)
          }
        })
      }
      if (passwordType === 'change-email') {
        usersStore.setLoadingProgress(true)
        usersStore.confirmChangeEmail(tokenid, history).then((res) => {
          usersStore.setLoadingProgress(false)
          if (res.status === 200) {
            message.success(t(res?.message))
            setIsValid(true)
          } else if (res.error === 'expired') {
            message.error(t('token-is-not-valid-or-has-expired'))
            history.push(`/`)
          }
          else {
            message.error(t('an-error-occurred'))
            history.push(`/`)
          }
        }).catch(error => {
          usersStore.setLoadingProgress(false)
          if (error?.data && error?.data?.message && Array.isArray(error?.data?.message)) {
            message.error(t(error?.data?.message[0]?.messages[0]?.id))
          } else if (error?.data && error?.data?.message) {
            message.error(t(error?.data?.message))
          } else {
            message.error(t('an-error-occurred'))
          }
          history.push(`/`)
        })
      }
    }
  }, [])

  const checkAppLoading = () => {
    return !!(
      commonStore.isLoading ||
      authStore.isLoading ||
      usersStore.isLoading
    )
  }

  const handleChangeNewPassword = values => {
    let _value = {
      token: tokenid,
      password: values.password
    }
    usersStore.setLoadingProgress(true)
    usersStore.confirmChangePassword(_value).then(res => {
      if (!res.data.status) {
        usersStore.setLoadingProgress(false)
        message.success(t('your-account-password-change-successfully'))
        usersStore.userLogout()
          .then(() => {
            history.push(`/`)
          })
      } else {
        usersStore.setLoadingProgress(false)
        message.error(t(`${res.data.message}`))
        history.push(`/auth/forgot-password`)
      }
    })
      .catch(error => {
        usersStore.setLoadingProgress(false)
        console.log(error)
      })
  }

  const authFormInput = () => {
    switch (passwordType) {
      case 'forgot-password':
        return (
          <Fragment>
            <FormTitle>{t('reset-password')}</FormTitle>
            <FormSubtitle>{t('please-input-to-change-new-password-here')}</FormSubtitle>
            <ForgotPassword handleChangeNewPassword={v => handleChangeNewPassword(v)} ></ForgotPassword>
          </Fragment>
        )
      case 'change-email':
        return (
          <Fragment>
            <FormTitle> <Text type="success">{t('success')}</Text></FormTitle>
            <FormSubtitle>{t('email-changed')}</FormSubtitle>
            <Link to={'/'} style={{ color: '#ff8d4f' }}>
              {t('back-to-home')}
            </Link>
          </Fragment>
        )
      case "reset-password":
        return (
          <Fragment>
            {/* UI for reset password */}
          </Fragment>
        )
      default:
        break
    }
  }

  const passwordPageTitle = () => {
    switch (passwordType) {
      case 'reset-password':
        return t('reset-password')
      case 'forgot-password':
        return t('reset-password')
      case 'change-email':
        return t('change-email')
      default:
        return 'Undefined'
    }
  }

  return (
    <HelmetProvider>
      <PasswordPageWrapper>
        <Helmet>
          <title>{passwordPageTitle()}</title>
        </Helmet>
        {
          isValid && (
            <FormWrapper>
              {authFormInput()}
            </FormWrapper>
          )}
        {
          checkAppLoading()
            ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
            : null
        }
      </PasswordPageWrapper>
    </HelmetProvider>
  )
}

export default inject('authStore', 'commonStore', 'usersStore')(observer(PasswordPage))