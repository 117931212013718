import styled from 'styled-components'
export const RedirectPageWrapper = styled.div`
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .middle {
        display: table-cell;
        vertical-align: middle;
    }  
    .inner {
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        text-align: center;
    }
`