
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
const Cesium = require('cesium')
const I3SDataProvider = ({ projectStore, viewer, modelListI3S, i3sViewLink }) => {
    const [loadData, setLoadData] = useState(false)
    useEffect(() => {
        if (!i3sViewLink && projectStore.i3sViews?.length > 0) {
            if (projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0) {
                const visibleTileViewIds = projectStore.visibleTilesets.map(item => {
                    if (item.isVisible) {
                        return item.modelId + '-tile'
                    }
                })
                projectStore.i3sViews.forEach(i3sView => {
                    const splitKey = i3sView?.key?.split('-') || [];
                    if (!(visibleTileViewIds.indexOf(splitKey[0] + '-' + splitKey[1]) > -1)) {
                        i3sView.show = false;
                    } else {
                        i3sView.show = true;
                    }
                })
            }
        }
        if (viewer?.scene?.requestRenderMode) { viewer.scene.requestRender(); }
    }, [projectStore.visibleTilesets, loadData])

    useEffect(() => {
        if (i3sViewLink && projectStore.i3sViewsLink?.length > 0) {
            let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds(true);
            projectStore.i3sViewsLink.map(i3sView => {
                const key = i3sView?.key;
                if (visibleTileViewIds.includes(key)) {
                    i3sView.show = true;
                } else {
                    i3sView.show = false;
                }
            })
        }
        if (viewer?.scene?.requestRenderMode) { viewer.scene.requestRender(); }
    }, [projectStore.listProjectLink, projectStore.i3sViewsLink.length, loadData])

    useEffect(() => {
        const loadI3sViews = async () => {
            setLoadData(false)
            const i3sOptions = {
                traceFetches: false,
                cesium3dTilesetOptions: {
                    // skipLevelOfDetail: true,
                    debugShowBoundingVolume: false,
                },
            };
            if (viewer && modelListI3S && modelListI3S.length > 0) {
                for (const model of modelListI3S) {
                    try {
                        const ref = React.createRef()
                        const i3sProvider = await Cesium.I3SDataProvider.fromUrl(
                            model.src + `?modelId=${model.id}`,
                            i3sOptions
                        );
                        i3sProvider.ref = ref
                        i3sProvider.id = model.id
                        i3sProvider.key = i3sViewLink ? `${model.id}-tile-link` : `${model.id}-tile`
                        viewer.scene.primitives.add(i3sProvider);
                        if (i3sViewLink) {
                            projectStore.setI3sViewsLink([...projectStore.i3sViewsLink, i3sProvider])
                        } else {
                            projectStore.setI3sViews([...projectStore.i3sViews, i3sProvider])
                        }
                    } catch (error) {
                        console.log(
                            `There was an error creating the I3S Data Provider: ${error}`
                        );
                    }
                }
            }
            setLoadData(true)
        }
        loadI3sViews();

        return () => {
            projectStore.setI3sViews([]);
        }
    }, [modelListI3S])

    return <></>
}

export default inject('projectStore', 'uiStore', 'modelStore', 'userGroupStore')(observer(I3SDataProvider))
