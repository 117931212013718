import {
  RedoOutlined,
  SaveOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Layout,
  message,
  Popconfirm,
  Row,
  Spin,
  Select,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ItemLabel } from './CustomStyled'
import LoadingSpinner from '../../../elements/LoadingSpinner/LoadingSpinner'
import AdminTemplate from '../../../layout/AdminTemplate'
import { ContentAdmin, CustomContentAdminWrapper, HeaderAdmin } from '../style'
import '../style.css'
import { Trans, useTranslation } from 'react-i18next'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/ext-language_tools'
import { assetUrl } from '../../../../config'
const { Content } = Layout
const { Option } = Select

const LanguageEditor = ({ commonStore, adminStore }) => {
  const { t } = useTranslation()
  const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false)
  const [langjson, setLanguageJson] = useState('')
  const [lang, setLang] = useState(null)
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  useEffect(() => {
    commonStore.setCurrentPage('admin/language-editer')
  }, [])

  const handleChangeLanguage = value => {
    if (value) {
      setLang(value)
      setisLoadingFormEdit(true)
      fetch(`${assetUrl}locales/${value}/translations.json`, {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=0',
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (myJson) {
          setisLoadingFormEdit(false)
          setLanguageJson(JSON.stringify(myJson, null, '\t'))
        })
        .catch(error => {
          message.error(t('file-not-found'))
          setLanguageJson('')
          setisLoadingFormEdit(false)
          console.log(error)
        })
    }
  }

  const onChange = newValue => {
    console.log('change', newValue)
    setLanguageJson(newValue)
  }

  const confirmReset = () => {
    if (lang) {
      setisLoadingFormEdit(true)
      fetch(`${assetUrl}locales/${lang}/translations.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (myJson) {
          setisLoadingFormEdit(false)
          setLanguageJson(JSON.stringify(myJson, null, '\t'))
        })
        .catch(error => {
          setisLoadingFormEdit(false)
          console.log(error)
        })
    }
  }

  const isValidJSON = function (json) {
    let is_json = true
    let message = ''
    try {
      let object = JSON.parse(json)
    } catch (error) {
      is_json = false
      message = t('might-be-a-problem-in-key-or-value-data-type')
    }

    if (!is_json) {
      let countCharacter = function (string, character) {
        let count = 0
        for (var i = 0; i < string.length; i++) {
          if (string.charAt(i) == character) {
            count++
          }
        }
        return count
      }

      json = json.trim()

      if (json.charAt(0) != '{' || json.charAt(json.length - 1) != '}') {
        message = t('brackets-are-not-balanced')
      } else if (
        !(countCharacter(json, ':') - 1 == countCharacter(json, ','))
      ) {
        message = t('comma-or-colon-are-not-balanced')
      } else {
        json = json.substring(1, json.length - 1)
        json = json.split(',')

        for (var i = 0; i < json.length; i++) {
          let pairs = json[i]
          if (pairs.indexOf(':') == -1) {
            message = t('no-colon-key-and-value')
          }
        }
      }
    }
    return { is_json: is_json, message: message }
  }

  const updateLangJson = async () => {
    if (langjson && lang) {
      let resultIsJSON = isValidJSON(langjson)
      if (!resultIsJSON.is_json) {
        message.error(t(resultIsJSON.message || 'an-error-occurred'))
        return
      }

      let data = {
        language: lang,
        jsoncontent: langjson,
      }

      setisLoadingFormEdit(true)
      await adminStore
        .updateLanguageEditor(data)
        .then(res => {
          setisLoadingFormEdit(false)
          message.success(t('update-successfully'), 5)
        })
        .catch(err => {
          message.error(t(err?.data?.message || 'an-error-occurred'))
          setisLoadingFormEdit(false)
        })
    } else {
      message.error(t('update-failed'), 5)
    }
  }

  const customSpin = (
    <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
  )
  return (
    <AdminTemplate title={t('language-editor')}>
      <Spin spinning={isLoadingFormEdit} indicator={customSpin}>
        <CustomContentAdminWrapper className="edit-user">
          <div style={{ background: '#fff', textAlign: 'center' }}>
            <Layout>
              <HeaderAdmin style={{ backgroundColor: '#f0f2f5' }}>
                <Content>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    style={{ padding: '10px ' }}>
                    <Col xs={24} sm={12}>
                      <div style={{ float: 'left', fontSize: 20 }}>
                        {t('language-editor')}
                      </div>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Col span={24} className="control-btn-group">
                        <Button
                          style={{
                            float: isMobile ? 'left' : 'right',
                            marginRight: 10,
                          }}
                          type="primary"
                          icon={<SaveOutlined />}
                          onClick={updateLangJson}
                          disabled={lang ? false : true}>
                          {t('commons.save')}
                        </Button>
                        <Popconfirm
                          title={t('are-you-sure-you-want-to-reset-language')}
                          icon={
                            <QuestionCircleOutlined style={{ color: 'red' }} />
                          }
                          onConfirm={confirmReset}
                          okText={t('commons.yes')}
                          cancelText={t('commons.no')}>
                          <Button
                            style={{
                              float: isMobile ? 'left' : 'right',
                              marginRight: 10,
                            }}
                            disabled={lang ? false : true}>
                            <RedoOutlined />
                            {t('commons.reset')}
                          </Button>
                        </Popconfirm>
                      </Col>
                    </Col>
                  </Row>
                </Content>
              </HeaderAdmin>
              <Content>
                <div style={{ padding: '20px 0', background: '#fff' }}>
                  <Row type="flex">
                    <Col span={24}>
                      <Row gutter={[24, 8]}>
                        <Col
                          span={24}
                          style={{ textAlign: 'left', marginBottom: '20px' }}>
                          <ItemLabel>{t('select-language')}:</ItemLabel>
                          <Select
                            // defaultValue="englist"
                            style={{ width: 200 }}
                            onChange={handleChangeLanguage}
                            placeholder={t('select-language')}>
                            <Option value="en">English</Option>
                            <Option value="fi">Finnish</Option>
                            <Option value="fr">French</Option>
                            <Option value="de">German</Option>
                            <Option value="it">Italian</Option>
                            <Option value="es">Spanish</Option>
                            <Option value="sv">Swedish</Option>
                            <Option value="vi">Vietnamese</Option>
                          </Select>
                        </Col>
                        <Col span={24} style={{ textAlign: 'left' }}>
                          <ItemLabel>{t('content-language-json')}:</ItemLabel>
                          <AceEditor
                            placeholder={t('language-json')}
                            mode="json"
                            theme="github"
                            name="json-langeditor"
                            onChange={onChange}
                            showPrintMargin={false}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={langjson}
                            setOptions={{
                              enableBasicAutocompletion: true,
                              enableLiveAutocompletion: true,
                              enableSnippets: true,
                              showLineNumbers: true,
                              tabSize: 2,
                              useWorker: false,
                            }}
                            editorProps={{
                              $blockScrolling: true,
                            }}
                            width="100%"
                            style={{ border: '1px solid #ddd' }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
          </div>
        </CustomContentAdminWrapper>
      </Spin>
    </AdminTemplate>
  )
}

export default inject('commonStore', 'adminStore')(observer(LanguageEditor))
