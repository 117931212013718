import styled from 'styled-components'
import {Button} from 'antd'

export const ToolbarWrapper = styled.div`
  background: #ededed;
  line-height: 40px;
  padding: 5px 10px;
  //display: flex;
  //flex-wrap: wrap;
  //flex-direction: column;
  align-items: center;
  height: fit-content;
  position: relative;
  gap: 15px;
  overflow: hidden;

  .disable-undo {
    cursor: not-allowed !important;
    opacity: 50%;
  }

  .toolbar-component {
    display: flex;
    align-items: center;
    height: fit-content;
    position: relative;
    gap: 15px;
    overflow-x: auto;
    padding: 5px 0;
  }

  /*
 *  STYLE 1
 */

  .toolbar-component::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .toolbar-component::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  .toolbar-component::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c1c1c1;
  }

  .max-content {
    text-wrap: nowrap;
  }

  .toolbar-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`

export const MoreOptionWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 99999;

  .popover{
    margin-right: 10px;
  }
`

export const OptionWrapper = styled.div`
  padding-top: 10px;
  .option {
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 2px;
    font-size: 14px;
  }
  .option:hover {
    background-color: rgb(245 245 245);
  }
`

export const PlayerWrapper = styled.div`
  cursor: pointer;
  ${({ active }) => (active ? `color: #ff8d4f;` : '')}
`
export const ButtonCustom = styled(Button)`
  padding: 0 !important;
  margin: 0;
  border-radius: 5px !important;
`
export const HeaderMenu = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;

  .add-column-btn {
    cursor: pointer;
  }
`
