import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { assetUrl } from '../config';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    // lng: 'en',
    fallbackLng: 'en',
    // resources: {
    //   en: {
    //     translations: require('./locales/en/translations.json')
    //   },
    //   fi: {
    //     translations: require('./locales/fi/translations.json')
    //   }
    // },
    backend: {
      loadPath: `${assetUrl}locales/{{lng}}/{{ns}}.json`,
      crossDomain: true
    },
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    load: "languageOnly",
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    },
    react: {    
      useSuspense: false
    }
  });

export default i18n;