import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Slider, Switch } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpButton from '../elements/HelpButton';
import { SettingControlContainer } from './CustomStyled';
const Cesium = require('cesium')


const TransparentSetting = ({ projectStore, uiStore, viewer, commonStore, projectSettingStore, usersStore }) => {
    const { t } = useTranslation();
    const [nearValue, setNearValue] = useState(0)
    const [nearDistance, setNearDistance] = useState()
    const [farDistance, setFarDistance] = useState()
    const [TransparentParam, setTransparentParam] = useState({})
    const [projectSetting, setProjectSetting] = useState()
    const [globeBehind, setGlobeBehind] = useState()
    const [loading, setLoading] = useState(false);

    var scene = viewer.current.cesiumElement.scene;
    var globe = scene.globe

    function updateKey(data) {
        var x = Object.assign(TransparentParam, data)
        setTransparentParam(x)
    }

    function onChangeGlobeBehind(checked) {
        projectStore.setGlobeBehind(checked)
        projectSettingStore.setAssignObjSystemProjectSetting("globeBehind", checked)
    }


    useEffect(() => {
        let data = projectSettingStore.systemProjectSetting
        let _value = {
            nearValue: data?.nearValue,
            nearDistance: data?.nearDistance || 0,
            farDistance: data?.farDistance || 1000,
        }
        setTransparentParam(_value)
        setProjectSetting(_value)
        projectStore.setLoadingProgress(false)

    }, [projectSettingStore.systemProjectSetting])

    useEffect(() => {
        let data = projectSettingStore.systemProjectSetting
        if (data) {
            let _value = {
                nearValue: data?.nearValue,
                nearDistance: data?.nearDistance || 0,
                farDistance: data?.farDistance || 1000,
            }
            setProjectSetting(_value)
        }
    }, [])

    useEffect(() => {
        if (projectStore.globeBehind) {
            scene.requestRender()
        }
    }, [projectStore.globeBehind])

    const setTranslucencyEnabled = function (nearValue, nearDistance, farDistance) {
        if (farDistance && nearValue) {
            globe.translucency.enabled = true
            globe.translucency.frontFaceAlphaByDistance = new Cesium.NearFarScalar(
                nearDistance || 0,
                1 - nearValue,
                farDistance,
                1.0
            );
            if (scene.requestRenderMode) { scene.requestRender(); }
        }
        else {
            globe.translucency.enabled = false
            if (scene.requestRenderMode) { scene.requestRender(); }
        }
    }

    useEffect(() => {
        setTranslucencyEnabled(commonStore.nearValue, commonStore.nearDistance, commonStore.farDistance)
    }, [commonStore.nearValue, commonStore.farDistance, commonStore.nearDistance])

    useEffect(() => {
        setNearValue(commonStore.nearValue)
        setFarDistance(commonStore.farDistance)
        setNearDistance(commonStore.nearDistance)
        setGlobeBehind(projectStore.globeBehind)
        setTranslucencyEnabled(commonStore.nearValue, commonStore.nearDistance, commonStore.farDistance)
    }, [])

    const onChangeNearvalue = (value) => {
        commonStore.setNearValue(value)
        projectSettingStore.setAssignObjSystemProjectSetting('nearValue', value)
        updateKey({ nearValue: value })
    }

    const onChangeFading = (value) => {
        if (value[0] <= TransparentParam.farDistance) {
            commonStore.setNearDistance(value[0])
            projectSettingStore.setAssignObjSystemProjectSetting('nearDistance', value[0])
            updateKey({ nearDistance: value[0] })
        }
        if (value[1] >= TransparentParam.nearDistance) {
            commonStore.setFarDistance(value[1])
            projectSettingStore.setAssignObjSystemProjectSetting('farDistance', value[1])
            updateKey({ farDistance: value[1] })
        }
    }


    const onSubmitTranparency = () => {
        if (usersStore.currentUser?.id) {
            let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
            setLoading(true)
            projectStore.setLoadingProgress(true)
            projectStore.updateProjectMetadata({ metadata}).then(() => {
                commonStore.setFarDistance(commonStore.farDistance)
                commonStore.setNearDistance(commonStore.nearDistance)
                commonStore.setNearValue(commonStore.nearValue)
                uiStore.setShowTransparentSetting(false)
                projectStore.setCleanMode(false)
                setLoading(false)
                projectStore.setLoadingProgress(false)

                message.success(t('save-terrain-transparency-successfully'))
            }).catch(() => {
                uiStore.setShowTransparentSetting(false)
                projectStore.setCleanMode(false)
                message.error(t('update-transparency-failed'))
            })
        } else {
            projectStore.setCleanMode(false)
            message.success(t('save-terrain-transparency-successfully'))
            uiStore.setShowTransparentSetting(false)
        }
    }

    const onCancelTranparency = () => {
        commonStore.setNearDistance(nearDistance)
        commonStore.setFarDistance(farDistance)
        commonStore.setNearValue(nearValue)
        projectStore.setGlobeBehind(globeBehind)
        setTranslucencyEnabled(commonStore.nearValue, commonStore.nearDistance, commonStore.farDistance)
        uiStore.setShowTransparentSetting(false)
        projectStore.setCleanMode(false)
        projectSettingStore.assignSystemProjectSetting(projectSetting)
    }
    return (
        <SettingControlContainer>
            <div style={{ padding: 20 }}>
                <div className='help-btn-wrap'>
                    <HelpButton helppage={"system_settings_globe"}/>
                </div>
                <p><strong>{t('globe-settings')}</strong></p>
                <div>{t('transparency')}</div>
                <br />
                <Row>
                    <Col span={24}>
                        <Slider
                            min={0}
                            max={1}
                            defaultValue={projectSettingStore.systemProjectSetting?.nearValue || 0}
                            step={0.1}
                            onChange={onChangeNearvalue}
                        />
                    </Col>

                </Row>

                <div>{t('fading')}</div>
                <br />
                <Row>
                    <Col span={24}>
                        <Slider
                            min={0}
                            max={5000}
                            range
                            step={100}
                            defaultValue={[projectSettingStore.systemProjectSetting?.nearDistance || 0, projectSettingStore.systemProjectSetting?.farDistance || 2000]}
                            onChange={onChangeFading}
                        />
                    </Col>

                </Row>

                <Row>
                    <Col span={24}>
                        <span>{t('globe-always-behind')}</span>
                    </Col>

                    <Col span={24}>
                        <Switch

                            style={{ width: 'auto' }}
                            checkedChildren={t('commons.on')}
                            unCheckedChildren={t('commons.off')}
                            size={'small'}
                            checked={projectStore.globeBehind}
                            defaultChecked={projectStore.globeBehind}
                            onChange={onChangeGlobeBehind}
                        />
                    </Col>
                </Row>

            </div>

            <br />
            <Button style={{ marginLeft: 5 }}
                type="default"
                icon={<CloseOutlined />}
                onClick={onCancelTranparency}>
                {t('commons.cancel')}
            </Button>
            <Button
                loading={loading}
                style={{ marginLeft: 5 }}
                type="primary"
                onClick={onSubmitTranparency}
                icon={<SaveOutlined />}

            >
                {t('commons.save')}
            </Button>
        </SettingControlContainer >
    )
}
export default inject('projectStore', 'uiStore', 'commonStore', 'projectSettingStore', 'usersStore')(observer(TransparentSetting))
