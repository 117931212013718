import styled from 'styled-components'

export const Photosphere = styled.div`
  .pnlm-about-msg {
    visibility: hidden;
    a {
      visibility: hidden;
    }
  }
  .photo-view-mode {
    position: absolute;
    z-index: 2;
    right: 24px;
    margin: 4px;
    border: 1px solid #999;
    border-color: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    cursor: pointer;
  }
`
export const PhotoWrapper = styled.div`
  position: relative;
  cursor: -webkit-grab; cursor: grab;
  &:active{
    cursor: -webkit-grabbing; cursor: grabbing;
  }

  .photo-tools{
    position: absolute;
    top: 0;
    left: 4px;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
  .btn-wapper {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    background-color: #d9d9d9;
    border: 1px solid #999;
    border-color: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    cursor: pointer;
    z-index: 2;
    transform: translateZ(9999px);
  }
  img {
    width: 100%;
    ${props => (props.fullscreen ? `height: 100vh;` : `height: 500px;`)}
  }

  ${props => (props.fullscreen ? `display: flex; justify-content: center;` : ``)}

  .photo-info {
    position: absolute;
    bottom: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 3px 3px 0;
    padding-right: 10px;
    color: #fff;
    text-align: left;
    z-index: 2;
    transform: translateZ(9999px);
  }
  .photo-title-box {
    position: relative;
    font-size: 20px;
    display: table;
    padding-left: 5px;
    margin-bottom: 3px;
  }
`