import styled from 'styled-components'
import {Input} from 'antd'
const {TextArea} = Input

export const FeedbackAnserTextarea = styled(TextArea)`
    ${props => props.readOnly && 
        `cursor: default;
        &:focus, &:hover
        {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        }`}
`

export const FeedbackAnswerWrapper = styled.div`
  
`
export const FeedbackHeader = styled.div`
    font-size: 24px;
    margin-bottom: 30px;
    .title {
        display: flex;
    }
    .datetime {
        font-size: 14px;
        color: #9E9E9E;
    }
    .ant-descriptions-header{
        margin-bottom : 0px;
    }
    .description-tile {
        font-size: 14px;
        font-weight: 500;
    }
    .description {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        max-height: 100px;
        overflow: auto;
    }

`
export const FeedbackContent = styled.div`
    .smiley-button {
        .ant-radio-button-wrapper{
            padding: 0;
            border: 0;
            margin: 5px;
            border-radius: 50%;
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
            width: auto;
        }
        .ant-radio-button-wrapper-checked {
            z-index: 1;
            color: #F26524;
            background: #944e4e;
            background: #949090;
            border-color: transparent;
        }
    } 
    .description-explain {
        clear: both;
        min-height: 24px;
        padding-top: 0px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        line-height: 1.5715;
        -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
`
export const FeedbackLabel = styled.div`
    margin-top : 10px;
    .label {
        flex: auto;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis;
    }
    a{
        font-size : 16px;
        color: #F26524;
        background-color: transparent;
        text-decoration : underline;
    }
`
