import { Col, Row } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CardFooter, TableHeading, TableWrapper } from './style';
import { useTranslation } from 'react-i18next';
import Utils from '../../../../../utils';

const OrganizationQuotaTab = props => {
    const { t } = useTranslation();
    const { adminStore } = props;

    return (
        <TableWrapper>
            <TableHeading>
                <div className="title">{t('total-quota-total-usage')}</div>
            </TableHeading>
            <CardFooter>
                <Row >
                    <Col span={12} sm={8}>
                        <b>{t('next-license-expiry')}:</b>
                    </Col>
                    <Col span={12} sm={16}>
                        <span>{adminStore.expireTime}</span>
                    </Col>
                </Row>
                <Row >
                    <Col span={12} sm={8}>
                        <b>{t('storage')}:</b>
                    </Col>
                    <Col span={12} sm={16}>
                        <span>{Utils.kbytesToSize(adminStore.storageQuotaUsage)} / {t(adminStore.licenseMaxQuota)}{t(adminStore.licenseMaxQuota) === t('UNLIMITTED') ? '' : 'GB'}</span>

                    </Col>
                </Row>
                <Row >
                    <Col span={12} sm={8}>
                        <b>{t('projects')}:</b>
                    </Col>
                    <Col span={12} sm={16}>
                        <span>{`${adminStore?.listProjectUsageLicense}/${t(adminStore.licenseMaxProject)}`}</span>

                    </Col>
                </Row>
                <Row >
                    <Col span={12} sm={8}>
                        <b>{t('users')}:</b>
                    </Col>
                    <Col span={12} sm={16}>
                        <span>{`${adminStore?.listUserProjectUsageLicense}/${t(adminStore.licenseMaxUser)}`}</span>
                    </Col>
                </Row>
            </CardFooter>
        </TableWrapper>
    )
}
export default inject('organizationStore', 'adminStore')(observer(OrganizationQuotaTab))