import React, { useEffect, useState, useRef } from 'react'
// import { Helmet, HelmetProvider } from 'react-helmet-async'
// import DefaultTemplate from '../../layout/DefaultTemplate'
import {
  Color,
  PinBuilder,
  Cartesian3,
  VerticalOrigin
} from "cesium";
import { Viewer, CameraFlyTo } from 'resium'
import { Trans, useTranslation } from 'react-i18next';

const GpsPage = () => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(0);
  const [flyToPos, setFly] = useState(null);
  const [markerPos, setMarkerPos] = useState(null);
  const [lastPos, setLastPos] = useState(null);
  const [pins, setPins] = useState([]);
  const viewerRef = React.createRef();
  var pinBuilder = new PinBuilder();

  const bluePin = {
    image: pinBuilder.fromColor(Color.YELLOW, 16).toDataURL(),
    verticalOrigin: VerticalOrigin.BOTTOM
  };
  const redPin = {
    image: pinBuilder.fromColor(Color.RED, 48).toDataURL(),
    verticalOrigin: VerticalOrigin.BOTTOM
  };
  const greenPin = {
    image: pinBuilder.fromColor(Color.GREEN, 48).toDataURL(),
    verticalOrigin: VerticalOrigin.BOTTOM
  };

  function geoFindMe() {
    function success(position) {
      // console.log('position.coords', position.coords)
      // if (flyToPos == null)
      setFly(
        Cartesian3.fromDegrees(
          position.coords.longitude,
          position.coords.latitude,
          200
        )
      );

      setMarkerPos(position.coords);

      // if (lastPos!=null) {
      //   setMarkerPos({
      //     longitude:lastPos.longitude+0.0001,
      //     latitude:lastPos.latitude
      //   })
      //   setFly(Cartesian3.fromDegrees(
      //     lastPos.longitude,
      //     lastPos.latitude,
      //     200
      //   ));
      // } else {
      //   setMarkerPos(position.coords)
      // }

      //   status.textContent = '';
      //   mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}`;
      //   mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
    }

    function error() {
      alert(t('unable-to-retrieve-your-location'));
    }

    if (!navigator.geolocation) {
      alert(t('geolocation-is-not-supported-by-your-browser'));
    } else {
      //   status.textContent = 'Locating…';
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    // Your custom logic here
    // setCount(count + 1);
    geoFindMe();
  }, 5000);

  useEffect(() => {
    if (viewerRef.current && markerPos != null) {
      const viewer = viewerRef.current.cesiumElement;
      // var newLong = markerPos.longitude;
      // var newLat = markerPos.latitude;

      // var startTime = JulianDate.now();
      if (pins.length > 0) {
        if (
          markerPos.longitude === lastPos.longitude &&
          lastPos.latitude === markerPos.latitude
        ) {
          return;
        }
        if (pins.length > 1) pins[pins.length - 1].billboard = bluePin;
        else pins[pins.length - 1].billboard = redPin;
      }
      var x = pins;
      var y = viewer.entities.add({
        name: "Blank blue pin",
        position: Cartesian3.fromDegrees(
          markerPos.longitude,
          markerPos.latitude
        ),
        billboard: greenPin
      });
      x.push(y);
      setPins(x);
      setLastPos({
        longitude: markerPos.longitude,
        latitude: markerPos.latitude
      });
      viewer.zoomTo(x);
      // Cesium.when.all([bluePin], function (pins) {
      //   viewer.zoomTo(pins);
      // });
    }
    return () => {};
  }, [markerPos]);

  useEffect(() => {
    if (loaded === 0) {
      if (viewerRef.current.cesiumElement) {
        setLoaded(1);
        const viewer = viewerRef.current.cesiumElement;
        viewer.scene.globe.baseColor = Color.DARKBLUE;

        geoFindMe();
        // setFly(flyToPos1)
        // if (mixModel) {
        //   setTileUrl(apiUrl + "/mix/" + mixModel.mixHash + "/tileset" + ".json")
        // }
      }
    }
    return () => {
      if (viewerRef.current.cesiumElement) {
        const viewer = viewerRef.current.cesiumElement;
        viewer.entities.removeAll();
        viewer.scene.primitives.removeAll();
        // setTileUrl(null)
        setLoaded(0);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Viewer
        timeline={false}
        homeButton={false}
        navigationInstructionsInitiallyVisible={false}
        navigationHelpButton={false}
        selectionIndicator={false}
        infoBox={false}
        style={{ height: 'calc(100vh - 65px)' }}
        ref={viewerRef}
        creditViewport={'<div>abc</div>'}>
        {flyToPos != null ? (
          <CameraFlyTo duration={2} destination={flyToPos} />
        ) : (
          <p
            style={{
              display: 'flex',
              width: '100vw',
              height: 'calc(100vh - 100px)',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
            }}>
            {t('checking-gps')}
          </p>
        )}
      </Viewer>
    </React.Fragment>
    
  )
}

export default GpsPage
