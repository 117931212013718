import { Tooltip } from 'antd'
import { inject, observer } from "mobx-react"
import React, {  } from 'react';
import { useMediaQuery } from 'react-responsive'
import { MeasurementItem, AvatarCustom } from './CustomStyled'
import SVGIcon from '../elements/SVGIcon';
import { ReactComponent as MeasureDistance } from '../../assets/svgs/measure-distance-b0.svg';
import { ReactComponent as MeasurePoint } from '../../assets/svgs/measure-point-b0.svg';
import { ReactComponent as MeasureArea } from '../../assets/svgs/measure-area.svg'
import { ReactComponent as MeasurePolyline } from '../../assets/svgs/draw-polyline.svg'
import { useTranslation } from 'react-i18next';

const MeasureTool = props => {
    const { projectStore, uiStore, adminStore, measureStore } = props
    const { t } = useTranslation();
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    const checkingFeatureRole = (type) => {
        if (!type) return false
        return adminStore.checkingFeatureRole(projectStore, type)
    }

    return (
        <>
            {checkingFeatureRole("feature_measure_point") && (
                <MeasurementItem>
                    <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('measure-point')}>
                        <AvatarCustom
                            activated={measureStore.measureMode === 'point' ? 1 : 0}
                            shape="square"
                            icon={<SVGIcon content={<MeasurePoint />} width={32} height={32} />}
                            onClick={() => {
                                projectStore.setCurrentHelpfeature('measurement')
                                projectStore.setViewMode('default mode') // set default mode
                                measureStore.setMeasureMode('point')
                                uiStore.setShowAttrPanel(false) // hide window properties
                                measureStore.setMeasureToolVisible(false); // hide menu toolbar measurement
                            }}
                        />
                    </Tooltip>
                </MeasurementItem>
            )}
            {checkingFeatureRole("feature_measure_distance") && (
                <MeasurementItem>
                    <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('measure-distance')}>
                        <AvatarCustom
                            activated={measureStore.measureMode === 'distance' ? 1 : 0}
                            shape="square"
                            icon={<SVGIcon content={<MeasureDistance />} width={32} height={32} />}
                            onClick={() => {
                                projectStore.setCurrentHelpfeature('measurement')
                                projectStore.setViewMode('default mode') // set default mode
                                measureStore.setMeasureMode('distance')
                                uiStore.setShowAttrPanel(false) // hide window properties
                                measureStore.setMeasureToolVisible(false); // hide menu toolbar measurement
                            }}
                        />
                    </Tooltip>
                </MeasurementItem>
            )}
            {checkingFeatureRole("feature_measure_area") && (
                <MeasurementItem>
                    <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('measure-area')}>
                        <AvatarCustom
                            activated={measureStore.measureMode === 'area' ? 1 : 0}
                            shape="square"
                            icon={<SVGIcon content={<MeasureArea />} width={32} height={32} />}
                            onClick={() => {
                                projectStore.setCurrentHelpfeature('measurement')
                                projectStore.setViewMode('default mode') // set default mode
                                measureStore.setMeasureMode('area')
                                uiStore.setShowAttrPanel(false) // hide window properties
                                measureStore.setMeasureToolVisible(false); // hide menu toolbar measurement
                            }}
                        />
                    </Tooltip>
                </MeasurementItem>
            )}
            {checkingFeatureRole("feature_measure_polyline") && (
                <MeasurementItem>
                    <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('measure-polyline')}>
                        <AvatarCustom
                            activated={measureStore.clippingMode === 'polyline' ? 1 : 0}
                            shape="square"
                            icon={<SVGIcon content={<MeasurePolyline />} width={32} height={32} />}
                            onClick={() => {
                                projectStore.setCurrentHelpfeature('measurement')
                                projectStore.setViewMode('default mode') // set default mode
                                measureStore.setMeasureMode('polyline')
                                uiStore.setShowAttrPanel(false) // hide window properties
                                measureStore.setMeasureToolVisible(false); // hide menu toolbar measurement
                            }}
                        />
                    </Tooltip>
                </MeasurementItem>
            )}
        </>
    )
}

export default inject('projectStore', 'uiStore', 'adminStore', 'measureStore')(observer(MeasureTool))