import React, { useEffect, useState, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Cartesian3,
  Cartographic,
  Transforms,
  Matrix4,
  Matrix3,
  Color,
  PinBuilder,
  ScreenSpaceEventType,
  HorizontalOrigin,
  VerticalOrigin,
  Math as CesiumMath,
  TranslationRotationScale,
  Quaternion,
} from 'cesium'
import Proj4 from 'proj4'
import { Label, Entity, LabelCollection } from 'resium'
import { toJS } from 'mobx'
import { transformFromInput } from '../helper/CesiumUtils'
import { Trans, useTranslation } from 'react-i18next';

const PickLocation = ({ projectStore, viewer, handler, model, tile, mPos}) => {
  const [currentPoint, setCurrentPoint] = useState(null)
  const { t } = useTranslation();
  function annotate(viewer, movement, feature) {
    if (viewer.scene.pickPositionSupported) {
      var cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid
      )
      // var cartesian = viewer.scene.pickPosition(movement.startPosition)
      // orginalPos = viewer.camera.pickEllipsoid(
      //   click.position,
      //   viewer.scene.globe.ellipsoid
      // )
      if (cartesian) {
        var cartographic = Cartographic.fromCartesian(cartesian)
        var long = cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN
        var lat = cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN
        let wktxy = ''
        if (projectStore.projectDetail.tilesetData && projectStore.projectDetail.tilesetData.coordinateSystem && projectStore.projectDetail.tilesetData.coordinateSystem.wkt) {
          wktxy = Proj4(projectStore.projectDetail.tilesetData.coordinateSystem.wkt, [long, lat])
        }        
        var content =
          `${t('longitude')} : ` +
          long.toFixed(3) +
          ' °\n' +
          `${t('latitude')}    : ` +
          lat.toFixed(3) +
          ' °\n' +
          `${t('height')}     : ` +
          cartographic.height.toFixed(2) +
          ' m'
        if (wktxy) {
          content =
            content +
            ' °\n' +
            'XY: ' +
            wktxy[0].toFixed(3) +
            ', ' +
            wktxy[1].toFixed(3)
        }

        var pBuilder = new PinBuilder()
        setCurrentPoint({
          label: (
            <Label
              key={'lp'}
              position={cartesian}
              text={content}
              showBackground={true}
              font="12px sans-serif"
              horizontalOrigin={HorizontalOrigin.CENTER}
              verticalOrigin={VerticalOrigin.TOP}
              disableDepthTestDistance={Number.POSITIVE_INFINITY}
            />
          ),
          pin: (
            <Entity
              key={'pp'}
              name="Blank red pin"
              position={cartesian}
              billboard={{
                image: pBuilder.fromColor(Color.RED, 24).toDataURL(),
                verticalOrigin: VerticalOrigin.BOTTOM,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              }}
            />
          ),
        })
      }
    }
  }
  useEffect(() => {
    if (!viewer.current.cesiumElement.scene) return
    handler.setInputAction(function(movement) {
      if (!viewer.current.cesiumElement.scene) return
      // var feature = viewer.current.cesiumElement.scene.pick(movement.position)
      // if (!feature) return
      // var newPos = viewer.current.cesiumElement.camera.pickEllipsoid(
      //   movement.endPosition,
      //   viewer.current.cesiumElement.scene.globe.ellipsoid
      // )
      annotate(viewer.current.cesiumElement, movement)
    }, ScreenSpaceEventType.MOUSE_MOVE)

    handler.setInputAction(function(click) {
      var pickedObject = viewer.current.cesiumElement.scene.pick(click.position)
      if (!pickedObject) return
      var newPos = viewer.current.cesiumElement.camera.pickEllipsoid(
        click.position,
        viewer.current.cesiumElement.scene.globe.ellipsoid
      )
      if (!newPos) return
      // tile.root.transform = calcMoveNewPos(tile.root.transform,newPos)
      // var m = getLocFromTile(tile)

      // var m = model.crs.saveLoc
      var m = mPos
      var np = Cartographic.fromCartesian(newPos)
      var lat = np.latitude * CesiumMath.DEGREES_PER_RADIAN
      var lng = np.longitude * CesiumMath.DEGREES_PER_RADIAN
      m.lat = lat
      m.lng = lng
      // m.height = np.height
      
      var trans = transformFromInput(m, model.crs.firstLoc)
      tile.modelMatrix  = trans
      projectStore.setModelEditPos(m, true)
      // let p = new Promise(resolve => {
      //   projectStore.setAddressSelectionMode(false)
      //   resolve()
      // })
      projectStore.setAddressSelectionMode(false)
      // p.then(() => projectStore.displayEditLocation()).then(() => {
      //   projectStore.setModelEditPos(m, true)
      //   // tile.root.transform = calcTransform(tile.root.transform, tile.modelMatrix)
      //   // tile.modelMatrix = Matrix4.IDENTITY
      // })
    }, ScreenSpaceEventType.LEFT_CLICK)

    return () => {
      if (handler) {
        handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
        handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
      }
    }
  }, [])

  return (
    <>
      <LabelCollection>
        {currentPoint ? currentPoint.label : ''}
      </LabelCollection>
      {currentPoint ? currentPoint.pin : ''}
    </>
  )
}
export default inject('projectStore')(observer(PickLocation))
