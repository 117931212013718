import styled from 'styled-components'

export const OrganizationWraper = styled.div`
  background: #FFFFFF;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  .ant-tabs-tabpane {
    padding: 5px 0;
  }
  .ant-table-body {
    overflow-x: auto !important;
  }
`
export const HeaderOrganizationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 30px;
    }
    .btn-organization-outer {
      padding-right: 30px;
    }
`