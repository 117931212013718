import {
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Row,
  Skeleton,
  Switch,
  Table,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import AdminTemplate from '../../../layout/AdminTemplate'
import {
  CustomTopTitle,
  CustomContentAdminWrapper,
  HeaderAdmin,
  PaginationAdmin
} from '../style'
import ChangeRoleName from './ChangeRoleName'
import { TableWrapper } from './style'
import { useTranslation } from 'react-i18next'
import settings from '../../../../siteConfig'

const FeatureSetting = ({ commonStore, adminStore, projectTeamsStore }) => {
  const { t } = useTranslation()
  const [reLoad, setReload] = useState(false)
  const [loadingtable, setLoadingtable] = useState(false)
  const [display, setDisplay] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [form] = Form.useForm()
  const history = useHistory()
  const [customData, setCustomData] = useState([])
  const [dataColumn, setDataColumn] = useState([])
  const [visibleModal, setVisibleModal] = useState(false)
  const  {featureBlocked} = settings;
  
  const { clearStatus } = adminStore
  const isMobile = useMediaQuery({query: '(max-width: 600px)'})
  useEffect(() => {
    commonStore.setCurrentPage('admin/featuresetting')
    adminStore.setActiveTab('2')
    clearStatus()
    setReload(false)
    setLoadingtable(true)
    setDisplay(true)
    populateData()
    return () => {
      setDisplay(false)
    }
  }, [reLoad])

  const hanldeReloadFromModal = async () => {
    await projectTeamsStore.getProjectRoles()
  }

  const resetFormFeatureSetting = () => {
    form.resetFields()
    history.push('/admin/featuresetting')
  }

  const populateData = async () => {
    await adminStore.getFeatures()
    await projectTeamsStore.getProjectRoles()
    adminStore
      .getSystemFeatureSetting('system')
      .then(res => {
        let v = adminStore.features
        let s = res.data.data
        if (s) {
          let objkey = Object.keys(s)
          v.map((feature, index) => {
            objkey.map(k => {
              if (feature.Type === k) {
                v[index].value = s[k]
              }
            })
          })
          setDataTable(v)
        }
        setLoadingtable(false)
        setDisplay(false)
      })
      .catch(() => {
        setLoadingtable(false)
        form.resetFields()
        setDataTable(adminStore.features)

        setDisplay(false)
      })
  }

  useEffect(() => {
    let roles = projectTeamsStore.projectRoles
    let publicProject = {
      roleName: 'Public',
      roleType: 'public_project',
    }
    let data = Object.keys(roles).map(key => {
      return {
        id: key,
        roleName: roles[key].roleName,
        roleType: roles[key].role,
        roleDescription: roles[key].description,
      }
    })
    data.push(publicProject)
    setCustomData(data)
    return () => {}
  }, [projectTeamsStore.projectRoles])

  useEffect(() => {
    if (customData.length > 0) {
      let _column
      let defaultRoleColumn = customData.map(elm => {
        return {
          title: elm.roleName,
          key: `${elm.id}`,
          fixed: 'right',
          render: record => {
            let isPrivate = featureBlocked.find(
              element => element === record.Type
            )
            if (elm.roleType === 'public_project') {
              if (isPrivate) {
                return null
              } else {
                return (
                  <Form.Item
                    name={`${elm.roleType}-${record.Type}`}
                    initialValue={
                      adminStore.systemFeatureSetting.data
                        ? adminStore.systemFeatureSetting.data[
                            `${elm.roleType}-${record.Type}`
                          ]
                        : false
                    }
                    valuePropName="checked">
                    <Switch
                      defaultChecked={
                        adminStore.systemFeatureSetting.data
                          ? adminStore.systemFeatureSetting.data[
                              `${elm.roleType}-${record.Type}`
                            ]
                          : false
                      }
                    />
                  </Form.Item>
                )
              }
            } else if (elm.roleType === 'project_owner') {
              if (record.Type === 'feature_project_setting') {
                return null
              } else {
                return (
                  <Form.Item
                    name={`${elm.roleType}-${record.Type}`}
                    initialValue={
                      adminStore.systemFeatureSetting.data
                        ? adminStore.systemFeatureSetting.data[
                            `${elm.roleType}-${record.Type}`
                          ]
                        : false
                    }
                    valuePropName="checked">
                    <Switch
                      defaultChecked={
                        adminStore.systemFeatureSetting.data
                          ? adminStore.systemFeatureSetting.data[
                              `${elm.roleType}-${record.Type}`
                            ]
                          : false
                      }
                    />
                  </Form.Item>
                )
              }
            } else {
              return (
                <Form.Item
                  name={`${elm.roleType}-${record.Type}`}
                  initialValue={
                    adminStore.systemFeatureSetting.data
                      ? adminStore.systemFeatureSetting.data[
                          `${elm.roleType}-${record.Type}`
                        ]
                      : false
                  }
                  valuePropName="checked">
                  <Switch
                    defaultChecked={
                      adminStore.systemFeatureSetting.data
                        ? adminStore.systemFeatureSetting.data[
                            `${elm.roleType}-${record.Type}`
                          ]
                        : false
                    }
                  />
                </Form.Item>
              )
            }
          },
          filterIcon: filtered => {
            if (elm.id) {
              return (
                <EditOutlined
                  onClick={() => setVisibleModal(true)}
                  style={{ color: filtered ? '#1890ff' : undefined }}
                />
              )
            }
          },
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => <div></div>,
          onFilterDropdownVisibleChange: state => {
            handleCLickFilterDropdown(state, elm)
          },
        }
      })
      _column = [
        {
          title: t('feature'),
          key: 'Name',
          dataIndex: 'Name',
          defaultSortOrder: 'ascend',
          width: 200,
          sorter: (a,b) => a.Name.localeCompare(b.Name),
          sortDirections: ['ascend', 'descend', 'ascend'],
          render: record => {
            return <span>{record}</span>
          },
        },
        ...defaultRoleColumn,
      ]
      setDataColumn(_column)
    }
    return () => {}
  }, [customData])

  const handleButtonSubmit = v => {
    form.validateFields().then(value => {
      if (adminStore.systemFeatureSetting.id) {
        setLoadingtable(true)
        adminStore
          .updateFeatureSetting(adminStore.systemFeatureSetting.id, {
            data: { ...adminStore.systemFeatureSetting.data, ...value },
          })
          .then(res => {
            message.success(t('system-setting-updated-successfully'))
            setLoadingtable(false)
          })
          .catch(error => {
            console.log(error)
            setLoadingtable(false)
            message.error(t('an-error-occurred'))
          })
      } else {
        let param = {
          type: 'system_setting',
          data: value,
        }
        setLoadingtable(true)
        adminStore
          .createFeatureSetting(param)
          .then(resp => {
            message.success(t('system-setting-updated-successfully'))
            setLoadingtable(false)
          })
          .catch(error => {
            console.log(error)
            setLoadingtable(false)
            message.error(t('an-error-occurred'))
          })
      }
    })
  }

  const handleCLickFilterDropdown = (state, element) => {
    if (state) {
      setVisibleModal(true)
      adminStore.setCurrentProjectRole(element)
    }
  }

  return (
    <AdminTemplate title={t('roles')}>
      <CustomContentAdminWrapper>
        
          <Form
            form={form}
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={value => handleButtonSubmit(value)}>
            <HeaderAdmin>

            {/* <Row style={{ padding: '24px' }}> */}
              <Row
                style={{ width: '100%' }}
                justify="space-between"
                align="middle">
                <Col xs={24} sm={12}>
                  <CustomTopTitle>
                    <div className="title">{t('roles')}</div>
                  </CustomTopTitle>
                </Col>
                <Col
                  xs={24} sm={12}
                  className="control-btn-group"
                  style={{ marginBottom: 20 }}>
                  <Button
                    style={{ float: isMobile?'left':'right', marginRight: isMobile?10:0 }}
                    htmlType="submit"
                    type="primary"
                    icon={<SaveOutlined />}
                    disabled={loadingtable}>
                    {t('commons.save')}
                  </Button>
                  <Button
                    style={{
                      float: isMobile?'left':'right',
                      marginRight: 10,
                    }}
                    icon={<CloseCircleOutlined />}
                    onClick={() => resetFormFeatureSetting()}>
                    {t('commons.cancel')}
                  </Button>
                </Col>
              </Row>
            {/* </Row> */}
            </HeaderAdmin>
            <PaginationAdmin>
            <TableWrapper >
              <Skeleton loading={display} active>
                  <Table
                    rowKey={record => record.id}
                    size="default"
                    bordered
                    scroll={{ x: 400 }}
                    columns={dataColumn}
                    loading={loadingtable}
                    dataSource={dataTable}
                    pagination={false}
                    onRow={record => ({
                      onClick: event => {},
                    })}
                    locale={{ emptyText: t('no-data') }}
                  />
              </Skeleton>
            </TableWrapper>
            </PaginationAdmin>
          </Form>
          <Modal
            className="modal-change-project-role"
            title={t('change-project-role')}
            zIndex={9998}
            style={{ top: 20 }}
            visible={visibleModal}
            onOk={() => setVisibleModal(false)}
            onCancel={() => setVisibleModal(false)}
            width={600}
            maskClosable={false}
            footer={false}>
            <ChangeRoleName
              hanldeReloadFromModal={status => hanldeReloadFromModal(status)}
              handleCloseModal={stt => {
                setVisibleModal(stt)
                adminStore.setCurrentProjectRole({})
              }}
            />
          </Modal>
        
      </CustomContentAdminWrapper>
    </AdminTemplate>
  )
}

export default inject(
  'commonStore',
  'adminStore',
  'projectTeamsStore'
)(observer(FeatureSetting))
