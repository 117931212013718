import styled from "styled-components";

export const HelpButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    //color: ${props => props?.hovered ? 'rgba(0,0,0,0.45)': 'black'};
    transition: color 0.3s;

    .question-icon{
        border-radius: 50%;
    }
    
    ${props => props?.hovered && `&:hover{
        color: ${props?.hovered};
    }`}

    ${props => props?.styles}

`